export default Object.freeze({
  DOCUMENTS: {
    CATEGORIES: {
      // Category for documents
      POST_DISBURSEMENT: "post_disbursement",
      PRE_DISBURSEMENT: "pre_disbursement",
      FIELD_INVESTIGATION: "field_investigation",
      CUSTOMER: "customer",
      CO_CUSTOMER: "co_customer",
      BANK: "bank",
    },
    CUSTOMERS: {
      CUSTOMER_PHOTO: "customer_photo",
      PAN: "customer_pan",
      AADHAAR_FRONT: "aadhaar_front",
      AADHAAR_BACK: "aadhaar_back",
      APPLICANT_PAN: "applicant_pan",
      DRIVING_LICENSE: "driving_license",
      VOTER_ID_FRONT: "voter_id_front",
      VOTER_ID_BACK: "voter_id_back",
      CUSTOMER_OTHER_KYC: "customer_other_kyc",
      APPLICANT_CRIF: "applicant_crif",
    },
    CO_CUSTOMERS: {
      PAN: "co_customer_pan",
      AADHAAR_FRONT: "co_customer_aadhaar_front",
      AADHAAR_BACK: "co_customer_aadhaar_back",
      DRIVING_LICENSE: "co_customer_dl",
      VOTER_ID_FRONT: "co_customer_voter_id_front",
      VOTER_ID_BACK: "co_customer_voter_id_back",
      ELECTRICITY_BILL: "co_customer_electricity_bill",
      OWNERSHIP_PROOF: "co_customer_ownership_proof",
      PASSPORT: "co_customer_passport",
      PHOTO: "co_customer_photo",
      CRIF: "co_customer_crif",
      OTHER_KYC: "co_customer_other_kyc",
    },
    APPLICATION: {
      CUSTOMER_HOUSE_INSIDE: "customer_house_inside",
      CUSTOMER_HOUSE_OUTSIDE: "customer_house_outside",
      CUSTOMER_PHOTO_WITH_ELECTRICITY_METER:
        "customer_photo_with_electricity_meter",
      GST_CERTIFICATE: "gst_certificate",
      FSSAI_LICENSE: "fssai_license",
      MOA: "moa",
      MSME_CERTIFICATE: "msme_certificate",
      SHOP_AND_ESTABLISHMENT_CERTIFICATE: "shop_and_establishment_certificate",
      BYE_LAWS: "bye_laws",
      AOA: "aoa",
      OTHER_OWNERSHIP_PROOF: "other_ownership_proof",
      LOAN_AGREEMENT: "loan_agreement",
      ELECTRICITY_BILL: "electricity_bill",
      STAMP_PAPER: "stamp_paper",
      DUAL_FORM: "dual_form",
      FORM_26_35: "form_26_35",
      SALES_INVOICE: "sales_invoice",
      FORM_22: "form_22",
      ERICK_DELIVERY_PHOTO: "erick_delivery_photo",
      CHASIS_NUMBER_PHOTO: "chasis_number_photo",
      BATTERY_WARRANTY_CARD: "battery_warranty_card",
      INSURANCE: "insurance",
      FORM_20: "form_20",
      FORM_21: "form_21",
      TRC: "trc",
      CHEQUES: "cheques",
      BSV: "bsv",
      SANCTION_LETTER: "sanction_letter",
      HYPOTHECATION_AGREEMENT: "hypothecation_agreement",
      DELIVERY_ORDER: "delivery_order",
      BANK_SIGNATURE_VERIFICATION: "bank_signature_verification",
      NACH_FORM: "nach_form",
      RTO_FORM: "rto_form",
      REGISTRATION_CERTIFICATE: "registration_certificate",
      QUOTATION: "quotation",
      OTHER_DOCS: "other_docs",
    },
  },
  DOCS: {
    APPLICATIONS: {},
    CO_CUSTOMER: {
      PAN: "co_customer_pan",
      AADHAAR_FRONT: "co_customer_aadhaar_front",
      AADHAAR_BACK: "co_customer_aadhaar_back",
    },
    CUSTOMER: {
      CUSTOMER_PHOTO: "customer_photo",
      APPLICANT_PAN: "applicant_pan",
      DRIVING_LICENSE: "driving_license",
      AADHAAR_FRONT: "aadhaar_front",
      AADHAAR_BACK: "aadhaar_back",
    },
    BANK: {
      BANK_STATEMENT: "bank_statement",
    },
    PRE_DISBURSEMENT: {
      ELECTRICITY_BILL: "electricity_bill",
      LOAN_AGREEMENT: "loan_agreement",
    },
    CATEGORIES: {
      // Category for documents
      POST_DISBURSEMENT: "post_disbursement",
      PRE_DISBURSEMENT: "pre_disbursement",
      FIELD_INVESTIGATION: "field_investigation",
      CUSTOMER: "customer",
      CO_CUSTOMER: "co_customer",
      BANK: "bank",
    },
  },
});
