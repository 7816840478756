import { CircularProgress, Dialog } from "@mui/material";
import React, { useContext } from "react";
import "./Loading.css";

const LoadingOver = ({ text, extraBlur }) => {
  return (
    <Dialog
      fullScreen
      open={true}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      // onClose={handleClose}
    >
      <div className={`kyc-dialog ${extraBlur && "extra-blur"}`}>
        <div className="dialog" style={{ width: "auto" }}>
          <p className="welcome text-center">
            <CircularProgress />
          </p>
          <p className="welcome mt-2">{text ? text : "Loading"}</p>
        </div>
      </div>
    </Dialog>
  );
};

export default LoadingOver;
