import { async } from "q";
import { useEffect, useState } from "react";

const mapping = {
  true: { label: "Approved", color: "#2D8925" },
  null: { label: "Not yet Approved", color: "#EC6E12" },
  false: { label: "Not yet Approved", color: "#EC6E12" },
};

export default function Dropdown(props) {
  const [DdValMap, setDdValueMap] = useState(
    props?.map ? props?.map : mapping
  );
  const [ddValue, setDdValue] = useState({ label: "", value: "" });

  useEffect(() => {
    if (!props?.isNormal) {
      setDdValue({ label: DdValMap[props?.value]?.label, value: props?.value });
    }
  }, [props]);

  const handleChange = async (val) => {
    await props?.onChange(val);
    if (props?.isNormal) {
      setDdValue({ label: val, value: val });
    } else {
      setDdValue({ label: DdValMap[val]?.label, value: val });
    }
  };

  return (
    <>
      <div className="dropdown">
        <button
          className="btn dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{
            backgroundColor: "#fff",
            border: "1px solid grey",
            fontSize: "0.8em",
            color: DdValMap[ddValue?.value]?.color,
          }}
        >
          {props?.isNormal ? props?.value : ddValue?.label}
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          {props?.ddList?.map((val, ind) => {
            return (
              <li key={ind}>
                <span
                  className="dropdown-item pointer"
                  style={{ fontSize: "0.8em", color: "#000" }}
                  onClick={() => handleChange(val)}
                >
                  {props?.isNormal ? val : DdValMap[val]?.label}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
