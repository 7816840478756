import { Close } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import OTP from "./OTP";
import { GlobalContext } from "../../Context/GlobalContext";
import { isBlank } from "../../utility/validations";
import { addContactInfo, updateContactInfo } from "../../api";
import { toast } from "react-toastify";
import useApplication from "../../hooks/useApplication";

const callPicker = [
  "Self",
  "Husband",
  "Wife",
  "Mother",
  "Father",
  "Son",
  "Doughter",
  "Sister",
  "Brother",
  "Relative",
  "Friend",
  "Other",
];

const inputStyles = {
  color: "rgba(0, 0, 0, 0.6)",
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
};

function CustomerCocustomerEdit({ open, handleClose, Relations, editData }) {
  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails] = updateDetailState;
  const [openOtp, setOpenOtp] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isVarified, setIsVarified] = useState(false);

  const { getApplicationDetails } = useApplication();

  const handleChange = (key, inputType) => (event, checked) => {
    if (key === "number") {
      if (isNaN(Number(event.target.value)) || event.target.value.length > 10) {
        return false;
      }
      setIsVarified(false);
    }

    if (inputType === "checkbox") {
      setUpdateData({
        ...updateData,
        [key]: checked,
      });
      setErrors({});
      return;
    }

    setUpdateData({
      ...updateData,
      [key]: event.target.value,
    });
    setErrors({});
  };

  const handleUpdateContact = async () => {
    setLoading(true);
    try {
      let errs = {
        name: isBlank(updateData?.name || ""),
        relation: isBlank(updateData?.relation || ""),
        call_picked_by: isBlank(updateData?.call_picked_by || ""),
      };

      if (!!updateData?.number) {
        let errs = {
          number:
            !isNaN(Number(updateData?.number)) &&
            (updateData?.number + "").length === 10
              ? ""
              : "Invalid mobile number",
          reason: isBlank(updateData?.reason || ""),
        };
      }

      let valid = true;
      for (let i in errs) {
        if (errs[i].length > 0) {
          valid = false;
        }
      }

      setErrors(errs);
      if (!valid) {
        return false;
      }

      let payload = {};
      payload = {
        ...payload,
        ...updateData,
        old_number: Number(updateData?.old_number),
      };

      if(!!updateData?.number ){
        payload = {
          ...payload ,
          verified:isVarified,
        }
      }else{
        payload = {
          ...payload ,
          number:updateData?.old_number ,
          verified:false ,
        }
      }

      delete payload.isNameDisabled;
      delete payload.isEdit;
      delete payload.open;
      payload.number = Number(payload.number);

      let { data } = await updateContactInfo({
        application_id: updateDetails?.application_id,
        contact_details: payload,
      });

      await getApplicationDetails(updateDetails?.application_id);
      handleClose();
      toast.success(data?.message || "Operation successfull");
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Something wrong"
      );
    } finally {
      setLoading(false);
    }
  };

  function handleOTP() {
    if (isVarified) return;
    let isValid =
      !isNaN(Number(updateData?.number)) &&
      (updateData?.number + "").length === 10;
    if (!isValid) {
      setErrors((prev) => ({
        ...prev,
        number: isValid ? "" : "Invalid mobile number",
      }));
      return;
    }
    setOpenOtp(true);
  }

  function isSaveDisabled() {
    if (!!!updateData?.number) {
      return false;
    }
    if (!isVarified) {
      return true;
    }
    if (!!!updateData?.reason) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    setUpdateData(editData);
  }, [editData]);

  function callPickedBy(relation) {
    if (relation === "Customer") {
      return updateDetails?.customer_call_picker;
    }
    if (["Co-customer", "Guarantor"].includes(relation)) {
      return updateDetails?.co_app_call_picker;
    }
    return "";
  }

  useEffect(() => {
    if (editData?.open) {
      setUpdateData({
        name: editData?.name,
        call_first: editData?.call_first,
        relation: editData?.relation || "",
        old_number: editData?.old_number,
        collection: editData?.collection,
        call_picked_by: callPickedBy(editData?.relation),
        number: "",
      });
    }
    setErrors({});
  }, [editData?.open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>Contact information</DialogTitle>
        <div style={{ position: "absolute", top: 10, right: 5 }}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        <DialogContent>
          <Grid spacing={3} container width={600}>
            <Grid item xs={12} sm={5}>
              <TextField
                variant="standard"
                fullWidth
                label="Name"
                disabled
                error={errors?.name}
                helperText={errors?.name}
                value={updateData?.name || ""}
                onChange={handleChange("name")}
              />
            </Grid>
            <Grid item xs={12} sm={7}></Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                variant="standard"
                fullWidth
                label="Current Number"
                type="number"
                disabled
                inputProps={{
                  maxLength: 10,
                  inputMode: "numeric",
                }}
                error={errors?.old_number}
                helperText={errors?.old_number}
                value={updateData?.old_number || ""}
                onChange={handleChange("old_number")}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                variant="standard"
                fullWidth
                label="New Number"
                type="number"
                inputProps={{
                  maxLength: 10,
                  inputMode: "numeric",
                }}
                error={errors?.number}
                helperText={errors?.number}
                value={updateData?.number || ""}
                onChange={handleChange("number")}
              />
            </Grid>
            <Grid xs={12} sm={2}>
              <div
                className="position-relative"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <Button
                  className="position-absolute"
                  variant="contained"
                  style={{ bottom: 5, right: 0, fontWeight: 600 }}
                  size="small"
                  disableElevation
                  color={isVarified ? "success" : "primary"}
                  onClick={handleOTP}
                >
                  {isVarified ? "Verified" : "send OTP"}
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={10} sx={inputStyles}>
              <p>
                Reason for changing the number{" "}
                <span style={{ color: "red" }}>*</span>
              </p>
              <textarea
                style={{ width: "100%" }}
                rows={3}
                error={errors?.reason}
                helperText={errors?.reason}
                value={updateData?.reason || ""}
                onChange={handleChange("reason")}
                disabled={!isVarified}
              />
              {!!errors?.reason && (
                <p style={{ color: "#d32f2f" }}>{errors?.reason}</p>
              )}
            </Grid>
            {/* <Grid item xs={12} sm={12} ></Grid> */}
            <Grid item xs={12} sm={5}>
              <FormControl
                variant="standard"
                fullWidth
                error={errors?.call_picked_by}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Who will pick the call
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={updateData?.call_picked_by || ""}
                  onChange={handleChange("call_picked_by")}
                  label="Who will pick the call"
                >
                  {callPicker.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors?.relation}</FormHelperText>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={7}></Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="filled-select-currency"
                select
                fullWidth
                label="Relation"
                // helperText="Please select your currency"
                value={updateData?.relation}
                onChange={handleChange("relation")}
                variant="standard"
              >
                {(editData?.isEdit && editData?.collection === "reference"
                  ? Relations.slice(3)
                  : Relations
                )?.map((reln) => (
                  <MenuItem value={reln}>{reln}</MenuItem>
                ))}
              </TextField>
            </Grid> */}
            <Grid item xs={12} sm={7}></Grid>
            <Grid xs={12} sm={5} item>
              <FormControl
                variant="standard"
                fullWidth
                error={errors?.relation}
                disabled={editData?.isEdit ? editData?.isNameDisabled : false}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Relation
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={updateData?.relation || ""}
                  onChange={handleChange("relation")}
                  label="Relation"
                >
                  {(editData?.isEdit && editData?.collection === "reference"
                    ? Relations.slice(3)
                    : Relations
                  )?.map((relation) => (
                    <MenuItem value={relation}>{relation}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors?.relation}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                sx={inputStyles}
                control={
                  <Checkbox
                    error={errors?.call_first}
                    helperText={errors?.call_first}
                    checked={updateData?.call_first || false}
                    onChange={handleChange("call_first", "checkbox")}
                  />
                }
                label="Mark Call First"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            disableElevation
            disabled={isSaveDisabled()}
            style={{ width: 100, fontWeight: "bold" }}
            onClick={handleUpdateContact}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <OTP
        open={openOtp}
        handleClose={() => {
          setOpenOtp(false);
        }}
        handleSubmit={() => {}}
        data={{ mobile_no: updateData?.number }}
        setVarified={setIsVarified}
      />
    </>
  );
}

export default CustomerCocustomerEdit;
