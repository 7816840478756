import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import { useEffect } from "react";
import { fetchAllNbfc, fetchNachdashData } from "../api";
import { Checkbox, ListItemText, OutlinedInput, Select } from "@mui/material";
import { toast } from "react-toastify";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const getmenuProp = (width) => {
  return {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
        width,
      },
    },
  };
};

const nachOptions = {
  REGISTERED: "Successfully Registered",
  TRANSACTION: "Collection & Transaction",
};

export default function RCNachFilter({
  active,
  setMonths,
  months,
  setNBFC,
  nbfc,
  onApply,
  fetchNachData,
  setShowTable,
  nachDashType,
  setNachDashType,
}) {
  const [nbfcList, setNbfcList] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNbfc, setAnchorElNbfc] = useState(null);
  const open = Boolean(anchorEl);
  const openNbfc = Boolean(anchorElNbfc);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchNBFCs = async () => {
    try {
      const { data } = await fetchNachdashData(`?nbfc_list=true`);

      let list = [];
      let selected = [];
      data?.map((nbfc) => {
        if (
          nbfc?._id !== "" &&
          nbfc?._id !== "EM00004" &&
          nbfc?._id !== "VA00001"
        ) {
          list.push(nbfc);
          selected.push(nbfc?._id);
        }
      });
      setNbfcList(list);
      setNBFC(selected);
    } catch (error) {
      toast.error("Failed to load nbfc list");
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setNBFC(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    if (active === "NACH") fetchNBFCs();
  }, [active]);

  return (
    <div className="mt-2 d-flex">
      {active === "NACH" && (
        <>
          <FormControl sx={{ width: 200, mr: 1 }} size="small">
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={nachDashType}
              onChange={(e) => {
                setShowTable(false);
                setNachDashType(e.target.value);
              }}
              input={<OutlinedInput />}
              renderValue={(selected) => nachOptions?.[selected]}
              sx={{
                color: "rgb(110, 54, 255)",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgb(110, 54, 255)",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgb(110, 54, 255)",
                  border: 1,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgb(110, 54, 255)",
                },
                ".MuiSvgIcon-root ": {
                  fill: "rgb(110, 54, 255)",
                },
              }}
            >
              {Object.keys(nachOptions)?.map((key) => (
                <MenuItem value={key}>{nachOptions?.[key]}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      <Button
        className="d-flex"
        id="demo-positioned-button"
        variant="outlined"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        style={{
          background: "white",
          color: "#6E36FF",
          fontWeight: 400,
          borderColor: "#6E36FF",
        }}
        onClick={handleClick}
      >
        <TuneIcon sx={{ fontSize: 18, mr: 1 }} /> {months} Months
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="px-4 py-2">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={months}
              name="radio-buttons-group"
              onChange={(e) => {
                setMonths(e.target.value);
                if (active === "RC") {
                  onApply(e.target.value);
                }
                handleClose();
              }}
            >
              <FormControlLabel
                value={6}
                control={<Radio />}
                label="6 Months"
              />
              <FormControlLabel
                value={12}
                control={<Radio />}
                label="12 Months"
              />
              <FormControlLabel
                value={18}
                control={<Radio />}
                label="18 Months"
              />
              <FormControlLabel
                value={24}
                control={<Radio />}
                label="24 Months"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </Menu>
      {active === "NACH" && (
        <>
          <FormControl
            sx={{ width: nbfc?.length === nbfcList?.length ? 100 : 200, ml: 1 }}
            size="small"
          >
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={nbfc}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected?.length === nbfcList?.length) return "All";
                else return selected?.join(", ");
              }}
              sx={{
                color: "rgb(110, 54, 255)",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgb(110, 54, 255)",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgb(110, 54, 255)",
                  border: 1,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgb(110, 54, 255)",
                },
                ".MuiSvgIcon-root ": {
                  fill: "rgb(110, 54, 255)",
                },
              }}
            >
              {nbfcList?.map((val) => (
                <MenuItem key={val?._id} value={val?._id}>
                  <Checkbox checked={nbfc.indexOf(val?._id) > -1} />
                  <ListItemText primary={val?.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}

      <Button
        variant="contained"
        style={{ background: "#6E36FF", boxShadow: "none" }}
        className="ms-2"
        onClick={() => {
          onApply();
          setShowTable(true);
        }}
      >
        Show
      </Button>
    </div>
  );
}
