import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "./index.css";
import App from "./App";
import Global from "./assets/styles/Global.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import UserContextProvider from "./Context/UserContext";
import GlobalContextProvider from "./Context/GlobalContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <UserContextProvider>
      <GlobalContextProvider>
        <App />
      </GlobalContextProvider>
    </UserContextProvider>
  </MuiPickersUtilsProvider>
  //</React.StrictMode>
);
