import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import DatePickerComponent from "../../Components/DatePicker";
import { FormLabel } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalContext";
import style from "../CreateAnchorSteps/AnchorDetails.css";
import moment from "moment/moment";
import { toast } from "react-toastify";
import useOem from "../../hooks/useOem";

const initAddress = [{ address_type: "", pincode: "", address: "" }];

export default function BasicDetails({ isDisabled }) {
  const { oemState, emailState } = React.useContext(GlobalContext);
  const [oemDetails, setOemDetails] = oemState;
  const [isEmailVerified, setIsEmailVerified] = emailState;
  const [falseEmail, setFalseEmail] = useState(false);
  // const [emailValidated, setEmailValidated] = useState(false);

  const emailValidation = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (regex.test(oemDetails?.email) === false) {
      setFalseEmail(true);
      setIsEmailVerified(false);
    } else {
      setFalseEmail(false);
      setIsEmailVerified(true);
    }
  };

  const handleAddrAddClick = (e) => {
    setOemDetails((prev) => ({
      ...prev,
      addresses: [...prev?.addresses, { ...initAddress }],
    }));
  };

  const handleAddrDelClick = (index) => (e) => {
    setOemDetails((prev) => {
      return {
        ...prev,
        addresses: prev?.addresses?.toSpliced(index, 1),
      };
    });
  };

  const handleChange = (prop) => (event) => {
    setOemDetails((prev)=>({
      ...prev,
      [prop]: event?.target?.value,
    }));
  };
  
  useEffect(() => {
    if (oemDetails?.email !== "") {
      emailValidation();
    }
  }, [oemDetails?.email]);


  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="brand_name"
            name="brand_name"
            label="Brand Name"
            fullWidth
            disabled={isDisabled}
            variant="standard"
            value={oemDetails?.brand_name}
            onChange={handleChange("brand_name")}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="company_name"
            name="company_name"
            // type={"number"}
            label="Company Name"
            disabled={isDisabled}
            fullWidth
            // autoComplete="email"
            variant="standard"
            value={oemDetails?.company_name}
            onChange={handleChange("company_name")}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="email"
            name="email"
            label={falseEmail ? "Email is not valid" : "Email"}
            error={falseEmail ? true : false}
            // helperText="email is not valid"
            fullWidth
            autoComplete="email"
            disabled={isDisabled}
            variant="standard"
            value={oemDetails?.email}
            onChange={handleChange("email")}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            // required
            id="website_link"
            name="website_link"
            // type={"number"}
            label="Website Link"
            fullWidth
            // autoComplete="email"
            variant="standard"
            disabled={isDisabled}
            value={oemDetails?.website_link}
            onChange={handleChange("website_link")}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel
              required
              id="demo-simple-select-standard-label"
              disabled={isDisabled}
            >
              Firm Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={oemDetails?.firm_type}
              disabled={isDisabled}
              onChange={handleChange("firm_type")}
            >
              <MenuItem value={"PROPRIETORSHIP"}>Proprietorship</MenuItem>
              <MenuItem value={"PARTNERSHIP"}>Partnership</MenuItem>
              <MenuItem value={"LIMITED LIABILITY PARTNERSHIP"}>
                Limited Liability Partnership (LLP)
              </MenuItem>
              <MenuItem value={"PRIVATE LIMITED"}>
                Private Limited (Pvt Ltd)
              </MenuItem>
              <MenuItem value={"HUF"}>HUF</MenuItem>
              <MenuItem value={"PUBLIC LIMITED"}>Public Limited</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="gstin"
            name="gstin"
            label="GSTIN"
            fullWidth
            disabled={isDisabled}
            variant="standard"
            value={oemDetails?.gstin}
            onChange={handleChange("gstin")}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          {oemDetails?.addresses?.map((c, index) => {
            return (
              <div
                key={index}
                style={{
                  backgroundColor: "#F7F7F7",
                  marginTop: 20,
                  padding: 15,
                  paddingBottom: 40,
                  position: "relative",
                  // width: "100%",
                }}
              >
                {index !== 0 && !isDisabled && (
                  <Button
                    className="remove-oem-btn"
                    onClick={handleAddrDelClick(index)}
                  >
                    <CloseIcon sx={{ fontSize: 17, mr: 0.5 }} /> Remove
                  </Button>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <InputLabel
                        id="demo-simple-select-standard-label"
                        required={index === 0}
                      >
                        Address Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={c?.address_type}
                        defaultValue={`${c?.address_type}`}
                        disabled={isDisabled}
                        onChange={(event) => {
                          let { value } = event.target;
                          setOemDetails((prev) => {
                            let temp = [...prev?.addresses];
                            temp[index].address_type = value;
                            return { ...prev, addresses: temp };
                          });
                        }}
                      >
                        <MenuItem value={"FACTORY"}>Factory Address</MenuItem>
                        <MenuItem value={"OFFICE"}>Office Address</MenuItem>
                        <MenuItem value={"WAREHOUSE"}>
                          Warehouse Address
                        </MenuItem>
                        <MenuItem value={"OTHER"}>Other Address</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="address"
                      name="address"
                      label="Address"
                      fullWidth
                      disabled={isDisabled}
                      required={index === 0}
                      variant="standard"
                      value={c?.address}
                      onChange={(event) => {
                        let { value } = event.target;
                        setOemDetails((prev) => {
                          let temp = [...prev?.addresses];
                          temp[index].address = value;
                          return { ...prev, addresses: temp };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      // required
                      id="pincode"
                      name="pincode"
                      type={"number"}
                      label="Pincode"
                      fullWidth
                      // autoComplete="email"
                      required={index === 0}
                      variant="standard"
                      disabled={isDisabled}
                      value={c?.pincode}
                      onChange={(event) => {
                        if (event?.target?.value?.length > 6) return;
                        let { value } = event.target;
                        let temp = [...oemDetails?.addresses];
                        temp[index].pincode = value;
                        setOemDetails((prev) => ({ ...prev, addresses: temp }));
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          })}
          {/* </div> */}
        </Grid>
        {!isDisabled && (
          <Grid item xs={12} sm={12}>
            <Button
              onClick={handleAddrAddClick}
              // disabled={address.length <= 1 ? true : false}
              sx={{ mt: 3, ml: 1, border: "1px solid" }}
            >
              <AddIcon /> ADD ADDRESS
            </Button>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}
