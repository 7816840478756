import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

function CreditDatePopup({ open, handleClose, handleSubmit }) {
  const [creditDate, setCreditDate] = useState(null);

  function handlePayload() {
    if(!creditDate){
        toast?.error("Date is Mandatory");
        return;
    }
    let payload = {
        credit_done_date: dayjs(creditDate).toISOString(),
    };
    handleSubmit(payload);
  }

  useEffect(()=>{
    return ()=>{
        setCreditDate(null);
    }
  },[])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Enter Credit Date:</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box>
            <DemoItem>
              <DatePicker
                format="DD/MM/YYYY"
                fullWidth
                value={creditDate}
                inputVariant="outlined"
                onChange={(date) => {
                  setCreditDate(date);
                }}
                animateYearScrolling
              />
            </DemoItem>
          </Box>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button disabled={!creditDate} onClick={handlePayload}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreditDatePopup;
