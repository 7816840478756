import { React, forwardRef, useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { GlobalContext } from "./../Context/GlobalContext";
import runningGif from "./../assets/running.gif";
import { copyToDemo, copyToDev } from "./../api";
import ReportIcon from "@mui/icons-material/Report";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CopyToDemo({ open, handleClose, demo, dev }) {
  const [status, setStatus] = useState("pending");
  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const [response, setResponse] = useState({});

  const handleCopyToDev = async () => {
    setStatus("running");
    try {
      let { data } = await copyToDev(updateDetails?.application_id);
      setResponse(data);
      setStatus("success");
    } catch (error) {
      setResponse(error?.response?.data);
      setStatus("failed");
    }
  };

  const handleCopyToDemo = async () => {
    setStatus("running");
    try {
      let { data } = await copyToDemo(updateDetails?.application_id);
      setResponse(data);
      setStatus("success");
    } catch (error) {
      setResponse(error?.response?.data);
      setStatus("failed");
    }
  };

  useEffect(() => {
    if (!open) {
      setResponse({});
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMountedrefresh
        // onClose={handleClose}
      >
        {demo.demo === "valueDemo" ? (
          <DialogTitle>
            <p className="text-center mb-0">Copy To Demo</p>
          </DialogTitle>
        ) : dev.dev === "dev" ? (
          <DialogTitle>
            <p className="text-center mb-0">Copy To Dev</p>
          </DialogTitle>
        ) : null}

        {status === "pending" && (
          <>
            {demo.demo === "valueDemo" ? (
              <DialogContent>
                Are you sure to Copy this application to Demo <br />
              </DialogContent>
            ) : dev.dev === "dev" ? (
              <DialogContent>
                Are you sure to Copy this application to Dev <br />
              </DialogContent>
            ) : null}

            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {demo.demo === "valueDemo" ? (
                <Button
                  onClick={() => {
                    handleCopyToDemo();
                    // window.location.reload();
                  }}
                >
                  Copy
                </Button>
              ) : dev.dev === 'dev' ? (
                <Button
                  onClick={() => {
                    handleCopyToDev();
                  }}
                >
                  Copy
                </Button>
              ) : null}
            </DialogActions>
          </>
        )}
        {status === "running" && (
          <DialogContent>
            <div style={{ width: "300px", marginTop: 10 }}>
              <p className="text-center">
                <img src={runningGif} width="150px" alt="" />
              </p>
              <p className="text-center">Copying Application</p>
            </div>
          </DialogContent>
        )}
        {status === "failed" && (
          <>
            <DialogContent>
              <div style={{ width: "300px", marginTop: 10 }}>
                <p className="text-center">
                  {/* <img src={runningGif} width="150px" alt="" /> */}
                  <ReportIcon style={{ fontSize: 100, color: "red" }} />
                </p>
                <p className="text-center">{response?.message}</p>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleCopyToDemo}>Retry</Button>
            </DialogActions>
          </>
        )}
        {status === "success" && (
          <>
            <DialogContent>
              <div style={{ width: "300px", marginTop: 10 }}>
                <p className="text-center">
                  <CheckCircleIcon style={{ fontSize: 100, color: "green" }} />
                </p>
                <p className="text-center">{response?.message}</p>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
