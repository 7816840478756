import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GoogleMapReact from "google-map-react";
import React, { useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import {
  addComment,
  approveAll,
  baseURL,
  editAnswer,
  fetchKYCDetails,
  updateStatus,
  verifyAuth,
} from "../../api";
import { useState } from "react";
import "./kyc.css";
import axios from "axios";
import { UserContext } from "../../Context/UserContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingOver from "../../Components/Loading";
import { toast } from "react-toastify";
import { capitalize, getDocKey } from "../../utility/Formater";
import loadingGif from "../../assets/loading-black.gif";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import useImageWithLocation, {
  RenderCanvas,
} from "../../hooks/useImageWithLocation";
import Dropdown from "../../Components/Dropdown";
import CommentsDialog from "./CommentsDailog";

const KYCDashboard = () => {
  const navigate = useNavigate();
  const audioRefs = useRef([]);
  const videoRefs = useRef([]);
  const canvasRef = useRef(null);
  // const videoRefs = {
  //   house_inside_front: useRef(null),
  //   house_inside_back: useRef(null),
  //   house_outside_front: useRef(null),
  //   house_outside_back: useRef(null),
  // };
  const { kycId } = useParams();
  const [panImage, setPanImage] = useState({
    loading: false,
    image: "",
  });
  const [kycData, setKYCData] = useState({ stateSet: false });
  const [audioArray, setAudioArray] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [addresses, setAddress] = useState({
    outside: "",
    inside: "",
  });
  const { user, setUser } = useContext(UserContext);
  const [isLoading, setLoading] = useState(false);
  const [audioLoading, setAudioLoading] = useState(false);
  const [isEdit, setEdit] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [updateLoader, setUpdateLoader] = useState(null);
  const [openComments, setOpenComments] = useState({ open: false, add: false });

  useEffect(() => {
    getKYCDetails();
  }, [kycId]);

  const getKYCDetails = async () => {
    try {
      setLoading(true);
      let { data } = await fetchKYCDetails(kycId);
      console.log(data);
      let pan_image = null;
      if (data?.data?.documents) {
        if ((data?.data?.documents?.pan || [])?.length > 0) {
          pan_image = data?.data?.documents?.pan[0]?.key;
        }
      }
      setKYCData({
        stateSet: true,
        ...(data?.data || {}),
        pan_image,
      });
      getPANFile(pan_image);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something wrong");
    }
    setLoading(false);
  };

  const getPANFile = async (pan_image) => {
    try {
      setPanImage({
        loading: true,
      });
      let image = await getSignedFile(pan_image);
      setPanImage({
        loading: false,
        image,
      });
    } catch (error) {
      setPanImage({
        loading: false,
      });
    }
  };

  const getSignedFile = async (key) => {
    let token = user?.token;
    if (!token) {
      let { data } = await verifyAuth();
      token = data?.token;
      setUser({
        ...data.data,
        token,
      });
    }

    try {
      let { data } = await axios.get(
        `https://sen319hxy8.execute-api.ap-south-1.amazonaws.com/dev/view?environment=development&signedUrl=true&key=${key}`,
        {
          method: "get",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            application: "EMBEDDED-TOOLS",
            token,
          },
        }
      );

      let { signedUrl } = data;

      const response = await axios.get(signedUrl, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const blobURL = URL.createObjectURL(blob);

      return blobURL;
    } catch (error) {
      console.error("Error fetching the file:", error);
      return "";
    }
  };

  useEffect(() => {
    let result = [];

    let audio_kyc = kycData?.audio_kyc || {};
    for (const key in audio_kyc) {
      if (audio_kyc.hasOwnProperty(key)) {
        const lastIndex = audio_kyc[key].length - 1;
        if (lastIndex >= 0) {
          result.push({ qnId: key, ...audio_kyc[key][lastIndex] });
        }
      }
    }
    setAudioArray(result);
  }, [kycData?.audio_kyc]);

  useEffect(() => {
    let result = [];
    let video_kyc = kycData?.video_kyc || {};
    for (const key in video_kyc) {
      if (video_kyc?.hasOwnProperty(key)) {
        const lastIndex = video_kyc[key].length - 1;
        if (lastIndex >= 0) {
          result.push({ name: key, ...video_kyc[key][lastIndex] });
        }
      }
    }
    setVideoData(result);
  }, [kycData?.video_kyc]);

  useEffect(() => {
    const setAudioUrls = async () => {
      setAudioLoading(true);
      if (audioArray?.length > 0) {
        const audioPromises = audioArray.map(async (audio, index) => {
          const audioUrl = await getSignedFile(audio?.key);
          if (audioUrl) {
            audioRefs.current[index].src = audioUrl;
          }
        });

        await Promise.all(audioPromises);
        setAudioLoading(false);
      }
    };

    setAudioUrls();
  }, [audioArray]);

  const handleLocation = async (lat, long) => {
    try {
      let { data: response } = await axios.get(
        `https://api.common-wrapper.embifi.in/v1/map/geocode?lat=${lat}&long=${long}`
      );
      return response?.data?.results?.[0]?.formatted_address;
    } catch (error) {
      return "";
    }
  };

  useEffect(() => {
    console.log(videoData);
    const setVideoUrls = async () => {
      // setAudioLoading(true);
      if (videoData?.length > 0) {
        const videoPromises = videoData?.map(async (video, index) => {
          const videoUrl = await getSignedFile(video?.key);
          if (videoUrl) {
            videoRefs.current[index].src = videoUrl;
          }
        });

        await Promise.all(videoPromises);
        // setAudioLoading(false);
      }
    };

    setVideoUrls();
  }, [videoData]);

  // useEffect(() => {
  //   console.log(videoData);
  //   const setVideoUrls = async () => {
  //     const houseInsideKeyFront = videoData?.customer_house_inside_front?.key;
  //     const houseInsideKeyBack = videoData?.customer_house_inside_back?.key;
  //     const houseOutsideKeyBack = videoData?.customer_house_outside_back?.key;
  //     const houseOutsideKeyFront = videoData?.customer_house_outside_front?.key;

  //     if (houseInsideKeyFront && houseInsideKeyBack) {
  //       const [insideFrontUrl, insideBackUrl] = await Promise.all([
  //         getSignedFile(houseInsideKeyFront),
  //         getSignedFile(houseInsideKeyBack),
  //       ]);
  //       videoRefs.house_inside_front.current.src = insideFrontUrl;
  //       videoRefs.house_inside_back.current.src = insideBackUrl;
  //     }

  //     if (houseOutsideKeyFront && houseOutsideKeyBack) {
  //       const [outsideFrontUrl, outsideBackUrl] = await Promise.all([
  //         getSignedFile(houseOutsideKeyFront),
  //         getSignedFile(houseOutsideKeyBack),
  //       ]);

  //       videoRefs.house_outside_front.current.src = outsideFrontUrl;
  //       videoRefs.house_outside_back.current.src = outsideBackUrl;
  //     }
  //     const addressesTemp = {};
  //     setAddress({ ...addressesTemp });
  //   };

  //   if (Object.keys(videoData)?.length > 0) {
  //     setVideoUrls();
  //   }
  // }, [videoData]);

  const handleEdit = async (value, index, qnId) => {
    try {
      setUpdateLoader(index);
      let { data } = await editAnswer({
        kycId: kycId,
        question_id: qnId,
        answer: value,
      });
      if (data?.status) {
        toast.success(data?.message);
        setTextFieldValue("");
        let updatedState = [...audioArray];
        updatedState[index].answer = value;
        setAudioArray([...updatedState]);
        setEdit(null);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Something wrong"
      );
      console.log(error);
    }
    setUpdateLoader(null);
  };

  const { getImageWithLocation } = useImageWithLocation();

  const captureFrame = async (video, data) => {
    try {
      setLoading(true);
      const canvas = canvasRef.current;

      if (video && canvas) {
        video.pause();
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const capturedDataURL = canvas.toDataURL("image/png");
        let file = await getImageWithLocation(
          {
            lat: data?.location?.lat || data?.location?.latitude,
            long: data?.location?.long || data?.location?.longitude,
          },
          capturedDataURL
        );
        const downloadLink = document.createElement("a");
        downloadLink.href = file;
        downloadLink.download = `${data?.name}.png`;
        downloadLink.click();
      }
    } catch (error) {
      toast?.error(error?.message);
    }
    setLoading(false);
  };

  const handleStatus = async (value, key, is_video = false) => {
    try {
      let { data } = await updateStatus({
        kycId,
        question_id: key,
        is_approved: value,
        is_video,
      });
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Failed to update the status"
      );
    }
  };

  const handleAddComment = async (comment) => {
    setLoading(true);
    try {
      let { data } = await addComment({
        kycId,
        comment,
      });
      if (data?.status) {
        toast.success("Comment added successfully!");
        setKYCData({
          ...kycData,
          comments: [
            ...(kycData?.comments || []),
            {
              comment,
              DateMS: Number(new Date()),
            },
          ],
        });
        setOpenComments({
          open: false,
          add: false,
        });
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Failed to add comment"
      );
    }
    setLoading(false);
  };

  const handleApproveAll = async () => {
    setLoading(true)
    try {
      let { data } = await approveAll({
        kycId,
      });
      if(data?.status){
        toast.success("All Docs approved successfully!");
        getKYCDetails()
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Failed to add comment"
      );
    }
    setLoading(false)
  };

  return (
    <>
      <div className="p-5 pt-3">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              onClick={() => navigate("/kyc")}
              className="pointer"
              style={{
                display: "inline-block",
              }}
            >
              <ArrowBackIcon /> Go back
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="kyc-section-card">
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <p className="section-heading">Pan Details</p>
                  <ListItemComp
                    heading={"Name"}
                    text={kycData?.pan_details?.name}
                  />
                  <ListItemComp
                    heading={"PAN"}
                    text={kycData?.pan_details?.number}
                  />
                  <ListItemComp
                    heading={"Father name"}
                    text={kycData?.pan_details?.father_name}
                  />
                  <ListItemComp
                    heading={"Masked Aadhaar"}
                    text={kycData?.pan_details?.masked_aadhaar}
                  />
                  <ListItemComp
                    heading={"PAN Aadhaar linked"}
                    text={
                      kycData?.pan_details?.pan_aadhaar_linked ? "Yes" : "No"
                    }
                  />
                  <br></br>
                  <ListItemComp
                    heading={"Married"}
                    text={kycData?.other_details?.is_married ? "Yes" : "No"}
                  />
                  {kycData?.other_details?.is_married && (
                    <ListItemComp
                      heading={"Has Children"}
                      text={kycData?.other_details?.has_child ? "Yes" : "No"}
                    />
                  )}
                  <ListItemComp
                    heading={"Who drives Riksha ?"}
                    text={kycData?.other_details?.vehicle_driver}
                  />
                  <ListItemComp
                    heading={"Comments"}
                    text={
                      <>
                        {[...(kycData?.comments || [])]?.reverse()?.[0]
                          ?.comment || (
                          <Button
                            className="comment-btns"
                            onClick={() => {
                              setOpenComments({
                                open: true,
                                add: true,
                              });
                            }}
                          >
                            Add Comment
                          </Button>
                        )}
                        {kycData?.comments?.length > 0 && (
                          <Button
                            className="comment-btns"
                            onClick={() => {
                              setOpenComments({
                                open: true,
                                add: false,
                              });
                            }}
                          >
                            View all
                          </Button>
                        )}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={7}>
                  <img
                    src={panImage?.loading ? loadingGif : panImage?.image}
                    width={"100%"}
                    height={300}
                    style={{ objectFit: "contain", background: "black" }}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="kyc-section-card">
              <Button className="approve-all-btn" onClick={handleApproveAll}>
                Approve All Docs
              </Button>
              <p>Q & A</p>
              {audioArray?.map((data, i) => {
                return (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{data?.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div style={{ display: "flex" }}>
                              <audio
                                key={i}
                                ref={(element) =>
                                  (audioRefs.current[i] = element)
                                }
                                controls
                                className="mb-3"
                              >
                                Your browser does not support the audio element.
                              </audio>
                              {!audioRefs?.current[i]?.src && audioLoading && (
                                <CircularProgress sx={{ mt: 1, ml: 2 }} />
                              )}
                            </div>

                            <ListItemComp
                              heading={"Audio Status:"}
                              loan={false}
                              text={
                                <Dropdown
                                  value={data?.is_approved}
                                  ddList={[true, false]}
                                  map={{
                                    true: {
                                      label: "Approved",
                                      color: "#2D8925",
                                    },
                                    null: {
                                      label: "Not yet Approved",
                                      color: "#5c5742",
                                    },
                                    false: {
                                      label: "Rejected",
                                      color: "#eb4034",
                                    },
                                  }}
                                  onChange={async (value) => {
                                    await handleStatus(
                                      value,
                                      data?.qnId,
                                      false
                                    );
                                  }}
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <div
                              style={{
                                border: "1px solid #cccccc",
                                marginRight: 10,
                              }}
                            >
                              <Accordion elevation={0}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  {isEdit === i ? (
                                    <>
                                      <TextField
                                        defaultValue={data?.answer}
                                        variant="standard"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                        onChange={(e) =>
                                          setTextFieldValue(e.target.value)
                                        }
                                      />
                                      <Button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleEdit(
                                            textFieldValue,
                                            i,
                                            data?.qnId
                                          );
                                        }}
                                      >
                                        {updateLoader === i ? (
                                          <>
                                            <CircularProgress
                                              size={15}
                                              sx={{ mr: 0.5 }}
                                            />{" "}
                                            Loading
                                          </>
                                        ) : (
                                          "Update"
                                        )}
                                      </Button>
                                      <Button
                                        className="audio-btns"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setTextFieldValue("");
                                          setEdit(null);
                                        }}
                                      >
                                        <CloseIcon sx={{ fontSize: 18 }} />
                                      </Button>
                                    </>
                                  ) : (
                                    <div className="d-flex align-items-center">
                                      <span>{data?.answer}</span>
                                      <Button
                                        className="audio-btns"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setTextFieldValue("");
                                          setEdit(i);
                                        }}
                                      >
                                        {data?.answer?.length > 0 ? (
                                          <EditIcon
                                            sx={{ fontSize: 15, ml: 2 }}
                                          />
                                        ) : (
                                          <AddIcon sx={{ fontSize: 18 }} />
                                        )}
                                      </Button>
                                    </div>
                                  )}
                                </AccordionSummary>
                                <AccordionDetails>
                                  <ListItemComp
                                    heading={"Google Transciption"}
                                    text={data?.transcription?.google_answer}
                                  />
                                  <ListItemComp
                                    heading={"OpenAi Cleanup"}
                                    text={
                                      data?.transcription?.google_cleanup_answer
                                    }
                                  />
                                  <ListItemComp
                                    heading={"Google Chirp"}
                                    text={
                                      data?.transcription?.google_chirp_answer
                                    }
                                  />
                                  <ListItemComp
                                    heading={"Google Chirp Cleanup"}
                                    text={
                                      data?.transcription
                                        ?.google_chirp_cleanup_answer
                                    }
                                  />
                                  <ListItemComp
                                    heading={"Whisper Answer"}
                                    text={data?.transcription?.whisper_answer}
                                  />
                                  <ListItemComp
                                    heading={"Whisper Cleanup"}
                                    text={
                                      data?.transcription
                                        ?.whisper_cleanup_answer
                                    }
                                  />
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="kyc-section-card">
              <Grid container spacing={2}>
                {videoData?.map((video, i) => {
                  return (
                    <Grid item xs={4} className="mb-5">
                      <p style={{ fontWeight: 500 }}>
                        {capitalize(video?.name?.replaceAll("_", " "))}
                      </p>

                      <video
                        width="100%"
                        style={{ background: "gray" }}
                        height={350}
                        controls
                        ref={(element) => (videoRefs.current[i] = element)}
                      >
                        Your browser does not support HTML video.
                      </video>

                      <div className="d-flex justify-content-end">
                        <Button
                          className="kyc-video-capture-btn"
                          onClick={() => {
                            captureFrame(videoRefs.current[i], video);
                          }}
                        >
                          <PhotoCameraIcon sx={{ fontSize: 15, mr: 0.5 }} />{" "}
                          Capture Image
                        </Button>
                      </div>

                      <div
                        className="mt-3"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <ListItemComp
                            heading={"Location"}
                            text={`${video?.location?.lat}, ${video?.location?.long}`}
                          />
                          <ListItemComp
                            heading={"Address"}
                            text={video?.location?.address || "Not Available"}
                          />

                          <ListItemComp
                            heading={"Video Status:"}
                            text={
                              <Dropdown
                                value={video?.is_approved}
                                ddList={[true, false]}
                                map={{
                                  true: {
                                    label: "Approved",
                                    color: "#2D8925",
                                  },
                                  null: {
                                    label: "Not yet Approved",
                                    color: "#5c5742",
                                  },
                                  false: {
                                    label: "Rejected",
                                    color: "#eb4034",
                                  },
                                }}
                                onChange={async (value) => {
                                  await handleStatus(value, video?.name, true);
                                }}
                              />
                            }
                          />
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      {isLoading && <LoadingOver />}
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      <RenderCanvas dimension={{ maxWidth: 600, fontSize: 50 }} />
      <CommentsDialog
        openComments={openComments}
        setOpenComments={setOpenComments}
        comments={[...(kycData?.comments || [])]?.reverse()}
        handleAddComment={handleAddComment}
      />
    </>
  );
};

export default KYCDashboard;

const ListItemComp = ({ icon, heading, text, loan = true }) => {
  return (
    <ListItem disablePadding>
      <ListItemIcon className={`list-key ${loan ? "loan" : ""}`}>
        {
          <span>
            {icon && icon}
            {heading}
          </span>
        }
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ fontSize: "15px" }}
        className="list-value"
        primary={text}
      />
    </ListItem>
  );
};

const AnyReactComponent = () => (
  <div className="pin-map">
    <div class="pin bounce"></div>
    <div class="pulse"></div>
  </div>
);

const questionsMap = {
  1: "Bank Name",
  2: "Mobile Number",
  3: "Place",
  4: "Total No.of Rooms in house",
  5: "Total Family members",
  6: "Owner of the House",
};
