import { Box, Paper, Grid, CircularProgress, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CountUp from "react-countup";
import useAnchor from "../hooks/useAnchor";
import { GlobalContext } from "../Context/GlobalContext";
import moment from "moment/moment";
import CachedIcon from "@mui/icons-material/Cached";
import { fetchDealerScore } from "../api";

const DealerProfile = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [isUpdating, setUpdating] = useState(false);
  const [scoreData, setScoreData] = useState([]);
  const { dealerDetailState } = useContext(GlobalContext);
  const [dealerDetails, setDealerDetails] = dealerDetailState;
  const { state } = location;
  const { anchorId } = useParams();

  const { getAnchorDetails } = useAnchor();

  const handleRefreshScore = async () => {
    setUpdating(true);
    try {
      let { data } = await fetchDealerScore(
        {
          anchor_ids: [anchorId],
        },
        "?update_db=true"
      );
      toast.success("Dealers score data updated successfully!");
      setScoreData({
        ...(data?.data?.final_data?.[0] || {}),
        last_updated: new Date().getTime(),
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
      );
    }
    setUpdating(false);
  };

  useEffect(() => {
    getAnchorDetails(anchorId);
  }, [anchorId]);

  useEffect(() => {
    setScoreData({
      ...(dealerDetails?.score_data || {}),
    });
  }, [dealerDetails]);

  return (
    <>
      <div
        style={{
          display: "flex",
          position: "fixed",
          alignItems: "center",
          background: "white",
          top: 0,
          width: "100%",
          zIndex: 5000,
          padding: "5px 0",
        }}
      >
        <IconButton
          style={{
            marginLeft: 10,
          }}
          onClick={() => {
            navigate("/partners");
          }}
        >
          <ArrowBackIcon sx={{ fontSize: "26px", cursor: "pointer" }} />
        </IconButton>

        <span
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "gray",
          }}
        >
          Dealer Profile
        </span>
      </div>

      <Grid
        container
        spacing={3}
        className="px-4"
        style={{
          paddingTop: "65px",
        }}
      >
        <Grid item xs={12}>
          <div
            className="w-100"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h5>
              {dealerDetails?.dealership_name}:{dealerDetails?.anchor_id}
            </h5>
            {scoreData?.last_updated && (
              <div>
                <span style={{ color: "gray", fontSize: 13 }}>
                  Score updated :{" "}
                  <span style={{ fontWeight: 500 }}>
                    {moment(scoreData?.last_updated).format(
                      "DD/MM/YYYY, hh:mm a"
                    )}
                  </span>
                </span>
                <IconButton
                  className={isUpdating ? "rotate-class" : ""}
                  disabled={isUpdating}
                  onClick={handleRefreshScore}
                >
                  <CachedIcon />
                </IconButton>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={2.5}>
          <Paper
            elevation={3}
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "10px",
              height: "150px",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
            className="overview-card"
          >
            <div
              style={{
                position: "absolute",
                width: "180px",
                height: "90px",
                overflow: "hidden",
                backgroundColor:state?.colors?.background?.slice(0,7),
                boxShadow:` 0 4px 3px 0  ${state?.colors?.background},  0 6px 20px 0 ${state?.colors?.background}`,
                borderRadius:'8px'
              }}
            >
              <div
                style={{
                  clipPath:
                    "polygon( 90.483% 0%,90.483% 0%,92.135% 0.446%,93.643% 1.713%,94.975% 3.694%,96.097% 6.28%,96.975% 9.364%,97.576% 12.838%,97.866% 16.595%,97.812% 20.528%,97.379% 24.528%,96.534% 28.488%,96.534% 28.488%,94.74% 34.59%,92.603% 40.837%,90.097% 47.144%,87.195% 53.427%,83.87% 59.6%,80.095% 65.58%,75.843% 71.282%,71.088% 76.621%,65.802% 81.512%,59.958% 85.872%,59.958% 85.872%,52.678% 90.32%,45.809% 93.797%,39.359% 96.388%,33.332% 98.177%,27.738% 99.25%,22.582% 99.692%,17.871% 99.587%,13.612% 99.02%,9.813% 98.076%,6.478% 96.841%,6.478% 96.841%,5.35% 96.085%,4.309% 94.864%,3.361% 93.223%,2.515% 91.203%,1.778% 88.847%,1.159% 86.199%,0.663% 83.3%,0.3% 80.195%,0.076% 76.925%,0% 73.534%,0% 64.87%,0% 64.87%,0.292% 54.348%,1.137% 44.366%,2.489% 35.058%,4.302% 26.559%,6.531% 19%,9.129% 12.516%,12.051% 7.241%,15.251% 3.307%,18.682% 0.849%,22.299% 0%,90.483% 0% )",
                  background: state?.colors?.layers,
                  opacity: state?.colors?.opacity,
                  height: "30px",
                  width: "30%",
                  position: "absolute",
                }}
              ></div>
              <div
                style={{
                  clipPath:
                    " polygon( 99.997% 3.834%,99.997% 65.957%,99.997% 65.957%,99.821% 70.989%,99.311% 75.763%,98.494% 80.214%,97.399% 84.28%,96.054% 87.895%,94.485% 90.995%,92.721% 93.518%,90.789% 95.4%,88.717% 96.575%,86.533% 96.981%,5.776% 96.981%,5.776% 96.981%,4.612% 96.706%,3.579% 95.932%,2.698% 94.736%,1.989% 93.196%,1.473% 91.39%,1.17% 89.395%,1.1% 87.289%,1.285% 85.149%,1.744% 83.053%,2.498% 81.079%,2.498% 81.079%,5.219% 75.879%,8.436% 70.671%,12.162% 65.587%,16.41% 60.756%,21.195% 56.31%,26.53% 52.379%,32.429% 49.094%,38.906% 46.586%,45.975% 44.985%,53.648% 44.423%,53.648% 44.423%,63.535% 43.657%,72.003% 41.519%,79.146% 38.25%,85.059% 34.089%,89.837% 29.276%,93.573% 24.051%,96.363% 18.654%,98.301% 13.326%,99.481% 8.306%,99.997% 3.834% )",
                  background: state?.colors?.layers,
                  opacity: state?.colors?.opacity,
                  height: "40px",
                  width: "50%",
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                }}
              ></div>
            </div>
            <p style={{ fontSize: "20px", fontWeight: "500", zIndex: "2" , position:'absolute' , top:'5px' }}>
              Dealer Score
            </p>
            <div style={{ width: "180px", height: "110px" }} />
            <div
              style={{
                position: "absolute",
                width: "180px",
                height: "100px",
                display: "flex",
                zIndex: "2",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <h2
                  style={{
                    zIndex: "2",
                    color: "#fff",
                    // fontSize:"48px"
                  }}
                >
                  {<CountUp end={scoreData?.total ? scoreData?.total : 0} />}
                </h2>
              )}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={0.6}></Grid>
        <Grid item xs={8}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Paper
              elevation={3}
              style={{
                height: "150px",
                maxWidth: "100%",
              }}
              className="overview-card"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "5px 20px",
                  minWidth: "450px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <p style={{ fontSize: "20px", fontWeight: "500" }}>Status</p>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : scoreData?.status ? (
                    scoreData?.status?.split("|").map((item) => (
                      <Box
                        component="span"
                        sx={{
                          p: 2,
                          marginRight: "20px",
                          border: "1px solid #181818",
                          borderRadius: "8px",
                          maxWidth: "120px",
                          maxHeight: "60px",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        <h6>{item.trim()}</h6>
                      </Box>
                    ))
                  ) : (
                    <h1 style={{ fontSize: "60px" }}>NA</h1>
                  )}
                </div>
              </div>
            </Paper>
          </div>
        </Grid>
        <div style={{ width: "100%" }} className="card-content mb-4 p-4">
          <div
            style={{
              display: "flex",
              gap: "40px",
              marginTop: "25px",
            }}
          >
            <ListItemComp
              value={
                scoreData?.application_level_score
                  ? scoreData?.application_level_score
                  : 0
              }
              text="Application Level Score"
              color={"#6F32BE"}
              fadeColor={"#CEBEFC"}
              circleOutline="#A07DEF"
              isLoading={isLoading}
            />
            <ListItemComp
              value={
                scoreData?.month_wise_score ? scoreData?.month_wise_score : 0
              }
              text={"Age Factor"}
              color={"#B258C2"}
              fadeColor={"#F5BDFF"}
              circleOutline="#F5BDFF"
              isLoading={isLoading}
            />
            <ListItemComp
              value={
                scoreData?.collection_level_score
                  ? scoreData?.collection_level_score
                  : 0
              }
              text={"Collection Level Score"}
              color={"#573398"}
              fadeColor={"#CEBEFC"}
              circleOutline="#A07DEF"
              isLoading={isLoading}
            />
            <ListItemComp
              value={
                scoreData?.number_of_disbursed
                  ? scoreData?.number_of_disbursed
                  : 0
              }
              text={"Number of Disbursed"}
              color={"#573398"}
              fadeColor={"#CEBEFC"}
              circleOutline="#A07DEF"
              isLoading={isLoading}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "40px",
              marginBottom: "40px",
              marginTop: "25px",
            }}
          >
            <ListItemComp
              value={dealerDetails?.anchor_aggregate_percentage}
              text={"Borrower Score"}
              color={"#573398"}
              fadeColor={"#CEBEFC"}
              circleOutline="#A07DEF"
              percent={true}
              isLoading={isLoading}
            />
          </div>
        </div>
      </Grid>
    </>
  );
};

export default DealerProfile;

const ListItemComp = ({
  fadeColor,
  color,
  value,
  circleOutline,
  text,
  percent,
  maxValue,
  isLoading,
}) => {
  return (
    <Paper
      elevation={3}
      style={{
        width: "200px",
        height: "70px",
        padding: "5px 20px",
        borderRadius: "8px",
      }}
      className="overview-card"
    >
      <h6
        style={{ fontSize: "14px", lineHeight: "18.75px", margin: "4px 0px" }}
      >
        {text}
      </h6>
      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <p
          style={{ fontWeight: "600", fontSize: "18px", lineHeight: "28.13px" }}
        >
          {[null, undefined].includes(value) ? (
            "NA"
          ) : (
            <>
              {" "}
              <CountUp end={value} /> {percent && <span> &#x25;</span>}{" "}
            </>
          )}
        </p>
      )}
    </Paper>
  );
};
