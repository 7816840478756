import React, { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { Avatar } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../Context/GlobalContext";

const NotificationPopup = () => {
  const { notificationState, notiCountState } = useContext(GlobalContext);
  const [notificationData, setNotification] = notificationState;
  const navigate = useNavigate();

  const handleClose = () => {
    setNotification({
      open: false,
      title: "",
      body: "",
      image: "",
    });
  };
  useEffect(() => {
    if (notificationData?.open) {
      setTimeout(() => {
        handleClose();
      }, 6000);
    }
  }, [notificationData?.open]);

  return (
    <div
      className={`notification ${notificationData?.open && "noti-popup-open"}`}
    >
      <div className="notification-header">
        <h3 className="notification-title">New notification</h3>
        <span
          className="fa fa-times notification-close"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </span>
      </div>
      <div
        className="notification-container pointer"
        onClick={() => {
          navigate("/notifications");
        }}
      >
        <div className="notification-media">
          <Avatar
            src={notificationData?.image}
            sx={{ width: "60px", height: "60px" }}
          >
            <NotificationsNoneIcon />
          </Avatar>
          {/* <i className="fa fa-thumbs-up notification-reaction"></i> */}
        </div>
        <div className="notification-content">
          <p className="notification-text">
            <strong>{notificationData?.title}</strong>
          </p>
          <p>{notificationData?.body}</p>
          <span className="notification-timer">a few seconds ago</span>
        </div>
        <span className="notification-status"></span>
      </div>
    </div>
  );
};

export default NotificationPopup;
