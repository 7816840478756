import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { fetchCashReciepts } from "../../api";
import DateRangePicker from "../DateRangePicker";
import { LoadingSkeleton } from "./LoadingSkeleton";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CashReceiptFilter = forwardRef(
  ({ open, handleClose, onApply, options, name }, ref) => {
    const columns = {
      field: "",
      value: [],
      options,
    };

    const initialState = [{ ...columns }];

    const [filterDataValue, setFilterDataValue] = useState([]);
    const [filterData, setFilterData] = useState(initialState);
    const [selectData, setSelectData] = useState([]);
    const [reduceData, setReduceData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getFilterValue = async () => {
      try {
        setLoading(true);
        let { data } = await fetchCashReciepts();
        setFilterDataValue(data?.result || []);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }finally{
        setLoading(false);
      }
    };

    useEffect(() => {
      getFilterValue();
      if (localStorage.getItem(`filterData${name}`)) {
        setFilterData([
          ...JSON.parse(localStorage.getItem(`filterData${name}`)),
        ]);
        handleReduceFilterValue(true);
      } else {
        setFilterData([...initialState]);
        setFilterDataValue([]);
        setSelectData([]);
        setReduceData([]);
      }
    }, [open]);

    const isPrevCompleted = () => {
      let finalObj = filterData[filterData?.length - 1] ?? {};
      for (let value of Object.values(finalObj)) {
        if (["", null, undefined]?.includes(value) || value.length === 0) {
          return false;
        }
      }
      return true;
    };

    const addMore = () => {
      if (!isPrevCompleted()) {
        return;
      }

      setFilterData([
        ...filterData,
        {
          ...columns,
          options: options.filter((item) => {
            return !filterData.some((e) => e.field === item);
          }),
        },
      ]);
    };

    const handleFormatArray = () => {
      setSelectData(
        filterDataValue?.map((item) => {
          return {
            dealer_name: {
              name: item?.dealer_name,
              value: item?.dealer_name,
            },
            agent_name: {
              name: item?.agent_name,
              value: item?.agent_name,
            },
            agent_code: {
              name: item?.agent_code,
              value: item?.agent_code,
            },
            partner_type: {
              name: item?.partner_type,
              value: item?.partner_type,
            },
          };
        })
      );
    };

    useEffect(() => {
      handleFormatArray();
    }, [filterDataValue]);

    const handleApply = (data) => {
      let finalFilter = {};
      (data ? data : filterData)?.map((item, index) => {
        if (item.field === "customer_payment_date") {
          finalFilter["customer_payment_date"] = item.value;
        } else if (item.field === "partner_type") {
          finalFilter[item.field] = item?.value?.[0];
        } else {
          finalFilter[item.field] = item.value?.map(
            (val) => val[item.field]?.value
          );
        }
      });
      onApply(finalFilter);
    };

    const removeItem = (i) => {
      setFilterData([...filterData.filter((item, index) => index !== i)]);
    };

    const handleChange = (key, value, i) => {
      let obj = { ...filterData[i] };
      obj = {
        ...obj,
        [key]: value,
      };

      if (key === "field") {
        obj = {
          ...obj,
          value: ["customer_payment_date"].includes(value)
            ? {
                from: Number(new Date()),
                to: Number(new Date()),
              }
            : [],
        };
      }

      filterData[i] = obj;
      if (key === "field") {
        setFilterData([...filterData.splice(0, i + 1)]);
        return;
      }

      setFilterData([...filterData]);
    };

    const handleReduceFilterValue = (init) => {
      let dropData = {};
      let finalArray = [...selectData];
      let filtered = init
        ? JSON.parse(localStorage.getItem(`filterData${name}`))
        : filterData;
      filtered?.map((item, index) => {
        if (
          item.field !== "" &&
          item.field !== "customer_payment_date" &&
          item?.value?.length > 0
        ) {
          finalArray = finalArray.filter((obj1) => {
            return item?.value?.some((obj2) => {
              if (item?.field === "partner_type") {
                return obj1?.[`${item?.field}`]?.value === obj2;
              } else {
                return (
                  obj1?.[`${item?.field}`]?.value ===
                  obj2?.[`${item?.field}`]?.value
                );
              }
            });
          });
        }
        dropData = {
          ...dropData,
          [index + 1]: [...finalArray],
        };
      });
      setReduceData(dropData);
    };

    useEffect(() => {
      if (filterData?.length && selectData?.length) {
        handleReduceFilterValue();
      }
    }, [selectData, filterData]);

    useEffect(() => {
      localStorage.setItem(`filterData${name}`, JSON.stringify(filterData));
    }, [filterData]);

    const handleStageData = (field, data) => {
      const mapData = [
        ...new Set(
          data?.map((item) => {
            return item?.[field]?.value;
          })
        ),
      ]?.map((item) => {
        return (
          <MenuItem value={item}>
            {(item || "")?.replaceAll("_", " ")?.toUpperCase()}
          </MenuItem>
        );
      });
      return mapData;
    };

    useImperativeHandle(
      ref,
      () => ({
        handeAgentNameFilter(agent_name,date) {
          let data = [
            {
              field: "agent_name",
              value: [
                {
                  agent_name: {
                    name: agent_name,
                    value: agent_name,
                  },
                },
              ],
              options,
            },
            (date && {
              field:"customer_payment_date",
              value:date,
              options:options.filter(itm=>itm!=="agent_name")
            })
          ].filter(Boolean);
          setFilterData(data);
          handleApply(data);
        },
        handeAgentsFilter(date) {
          let data = [
            {
              field: "partner_type",
              value: ["AGENT"],
              options,
            },
            (date && {
              field:"customer_payment_date",
              value:date,
              options:options.filter(itm=>itm!=="partner_type")
            })
          ].filter(Boolean);
          setFilterData(data);
          handleApply(data);
        },
      }),
      []
    );

    return (
      <>
        <div>
          <Modal
            show={open}
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
            centered
          >
            <Modal.Body>
              {loading && <LoadingSkeleton/>}
              {!loading && <div className="sort-dialog">
                <div className="sort-table-wrapper">
                  <table
                    className="sort-table app-sort-table"
                    id="app-sort-table"
                  >
                    <thead className="cf">
                      <tr>
                        <th style={{ width: 500 }}>Column</th>
                        <th style={{ width: "70%" }}>Value</th>
                      </tr>
                    </thead>

                    <tbody>
                      {filterData?.map((col, i) => {
                        return (
                          <>
                            <tr>
                              <td data-title="Data Point">
                                <Select
                                  className="w-100"
                                  value={col.field}
                                  sx={{
                                    boxShadow: "none",
                                    ".MuiOutlinedInput-notchedOutline": {
                                      border: 0,
                                    },
                                  }}
                                  onChange={(e) => {
                                    handleChange("field", e.target.value, i);
                                  }}
                                >
                                  {col?.options?.map((item) => {
                                    return (
                                      <MenuItem value={item}>
                                        {(item || "")
                                          ?.replaceAll("_", " ")
                                          ?.toUpperCase()}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </td>
                              <td data-title="Operator">
                                {col?.field !== "" &&
                                  (() => {
                                    if (
                                      col?.field === "customer_payment_date"
                                    ) {
                                      return (
                                        <DateRangePicker
                                          startDate={
                                            new Date(
                                              col?.value?.from || new Date()
                                            )
                                          }
                                          endDate={
                                            new Date(
                                              col?.value?.to || new Date()
                                            )
                                          }
                                          onChange={(start, end) => {
                                            handleChange(
                                              "value",
                                              {
                                                from: Number(start),
                                                to: Number(end),
                                              },
                                              i
                                            );
                                          }}
                                        />
                                      );
                                    } else if (col?.field === "partner_type") {
                                      return (
                                        <FormControl fullWidth>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label=""
                                            value={col?.value?.[0]}
                                            onChange={(x) => {
                                              handleChange(
                                                "value",
                                                [x.target.value],
                                                i
                                              );
                                            }}
                                          >
                                            {i === 0
                                              ? handleStageData(
                                                  col?.field,
                                                  selectData
                                                )
                                              : handleStageData(
                                                  col?.field,
                                                  reduceData?.[i]
                                                )}
                                          </Select>
                                        </FormControl>
                                      );
                                    } else {
                                      return (
                                        <Autocomplete
                                          multiple
                                          id="checkboxes-tags-demo"
                                          options={(() => {
                                            return [
                                              ...new Map(
                                                i === 0 &&
                                                !["application_date"].includes(
                                                  col.field
                                                )
                                                  ? selectData?.map((item) => [
                                                      item?.[col?.field]?.value,
                                                      item,
                                                    ])
                                                  : reduceData?.[i]?.map(
                                                      (item) => [
                                                        item?.[col?.field]
                                                          ?.value,
                                                        item,
                                                      ]
                                                    )
                                              ).values(),
                                            ];
                                          })()}
                                          value={col?.value}
                                          disableCloseOnSelect
                                          getOptionLabel={(option) =>
                                            option?.[col?.field]?.name
                                          }
                                          renderOption={(
                                            props,
                                            option,
                                            { selected }
                                          ) => (
                                            <li {...props}>
                                              <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                              />
                                              {option?.[col?.field]?.name}
                                            </li>
                                          )}
                                          fullWidth
                                          onChange={(e, value) => {
                                            handleChange(
                                              "value",
                                              [...value],
                                              i
                                            );
                                          }}
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                        />
                                      );
                                    }
                                  })()}
                              </td>
                              <td
                                className="sort-close-td ps-1"
                                style={{ width: "30px" }}
                              >
                                {i !== 0 && (
                                  <CloseIcon
                                    onClick={() => removeItem(i)}
                                    sx={{ fontSize: 18 }}
                                  />
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>

                    <Button className="mb-3" onClick={addMore}>
                      Add More
                    </Button>
                  </table>
                </div>

                <div className="sort-bottom-holder">
                  <Button
                    className="me-2 clear-btn"
                    onClick={() => handleClose()}
                  >
                    Close
                  </Button>

                  <Button
                    variant="contained"
                    className="apply-btn"
                    onClick={() => handleApply()}
                  >
                    Apply Filters
                  </Button>
                </div>
              </div>}
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
);

export default CashReceiptFilter;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
