import React, { useState } from "react";
import NewMapView from "../../Components/Dialogs/NewMapView";
import { Button } from "@mui/material";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";

const MapViewSatellite = () => {
  const [openMapView, setOpenMapView] = useState(true);

  return (
    <div>
      {/* <div className="ms-3">
        <Button
          variant="contained"
          onClick={() => {
            setOpenMapView(true);
          }}
          size="medium"
          sx={{ bgcolor: "#4D19D2" }}
          disableElevation
          startIcon={<PinDropOutlinedIcon fontSize="small" />}
        >
          View Map
        </Button>
      </div> */}

      {openMapView && (
        <NewMapView
          open={openMapView}
          handleClose={() => {
            setOpenMapView(false);
          }}
        />
      )}
    </div>
  );
};

export default MapViewSatellite;
