import { Skeleton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { fetchSchedule } from "../../../api";
import noDataIco from "../../../assets/nodata.gif";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const ScheduleViewer = ({ applicationId, scheduleType, loanSchedule, setSchedule }) => {
  const [isLoader, setLoader] = useState(false);
  const scrollRef = useRef(null);
  const [scroll, setScroll] = useState("Bottom");

  const handleScroll = (event) => {
    // console.log("scrollTop: ", event.currentTarget.scrollTop);
    // console.log("offsetHeight: ", event.currentTarget.offsetHeight);
    if (
      event.currentTarget.scrollTop >
      event.currentTarget.offsetHeight - event.currentTarget.offsetHeight / 2
    ) {
      setScroll("Top");
    }

    if (event.currentTarget.scrollTop < event.currentTarget.scrollHeight / 2) {
      setScroll("Bottom");
    }
  };

  // useEffect(() => {
  //   getSchedule();
  // }, []);
  return (
    <>
      <div
        className="loan-table-container"
        ref={scrollRef}
        onScroll={handleScroll}
      >
        {!isLoader && loanSchedule?.[scheduleType + "Sheet"]?.length === 0 ? (
          <>
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: "60vh" }}
            >
              <div>
                <img src={noDataIco} style={{ width: "300px", opacity: 0.3 }} />
                <p
                  className="w-100 text-center mb-1"
                  style={{ fontSize: 25, fontWeight: 500 }}
                >
                  Schedule not available
                </p>
              </div>
            </div>
          </>
        ) : (
          <table className="loan-table">
            <thead>
              <tr className="loan-tr">
                <th>Installment No</th>
                <th>Due Date</th>
                <th>Installment Amount</th>
                <th>Principal Component</th>
                <th>Interest Component</th>
                <th>Principal OS</th>
              </tr>
            </thead>
            {!isLoader ? (
              <tbody>
                {loanSchedule?.[scheduleType + "Sheet"]?.map((val, i) => {
                  return (
                    <tr className="loan-tr loan-body-tr" key={i}>
                      <td data-label="Installment No">{val?.[`SL_NO`]}</td>
                      <td data-label="Due Date">{val?.[`Due_Date`]}</td>
                      <td data-label="Installment Amount">
                        {val?.[scheduleType.toUpperCase()]}
                      </td>
                      <td data-label="Principal Component">{val?.Principal}</td>
                      <td data-label="Interest Component">{val?.Interest}</td>
                      <td data-label="Principal OS">
                        {val?.["O/s_Principal"]}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                {[...Array(10)].map((_) => {
                  return (
                    <tr className="loan-tr loan-body-tr">
                      <td data-label="Installment No">
                        <Skeleton />
                      </td>
                      <td data-label="Date">
                        <Skeleton />
                      </td>
                      <td data-label="Installment Amount">
                        <Skeleton />
                      </td>
                      <td data-label="Collected Amount">
                        <Skeleton />
                      </td>
                      <td data-label="Account Collected Amount">
                        <Skeleton />
                      </td>
                      <td data-label="Account Collected Amount">
                        <Skeleton />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        )}
      </div>
      <Fab
        variant="extended"
        className="scroll-top-btn2"
        onClick={() => {
          if (scroll === "Top") {
            scrollRef.current.scrollTop = 0;
          } else if (scroll === "Bottom") {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
          }
        }}
      >
        {scroll === "Top" && <ArrowDropUpIcon />}
        {scroll === "Bottom" && <ArrowDropDownIcon />}
        {/* {scroll} */}
      </Fab>
    </>
  );
};

export default ScheduleViewer;
