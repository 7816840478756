import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useEffect } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DownloadIcon from "@mui/icons-material/Download";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ViewConsficDoc({
  openView,
  setOpenView,
  docs,
  handleDownload,
}) {
  const handleClose = () => {
    setOpenView({
      open: false,
      current: null,
    });
  };

  function isEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  const handleNextClick = () => {
    const keys = Object.keys(docs);
    const currentIndex = keys.indexOf(openView?.current);

    let nextIndex = (currentIndex + 1) % keys.length;
    let nextKey = keys[nextIndex];

    while (isEmpty(docs[nextKey])) {
      nextIndex = (nextIndex + 1) % keys.length;
      nextKey = keys[nextIndex];

      if (nextIndex === currentIndex) {
        break;
      }
    }

    setOpenView({ ...openView, current: nextKey });
  };

  const handlePreviousClick = () => {
    const keys = Object.keys(docs);
    const currentIndex = keys.indexOf(openView?.current);

    let previousIndex = (currentIndex - 1 + keys.length) % keys.length;
    let previousKey = keys[previousIndex];

    while (isEmpty(docs[previousKey])) {
      previousIndex = (previousIndex - 1 + keys.length) % keys.length;
      previousKey = keys[previousIndex];

      if (previousIndex === currentIndex) {
        break;
      }
    }

    setOpenView({ ...openView, current: previousKey });
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openView?.open}
        fullWidth
        maxWidth={"sm"}
        PaperProps={{
          style: {
            backgroundColor: "#262626",
            boxShadow: "none",
          },
        }}
      >
        <div className="confisc-dailog-content">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ backgroundColor: "black" }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              zIndex: 5000,
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            className="conf-view-arrow left"
            onClick={handlePreviousClick}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <IconButton
            className="conf-view-arrow right"
            onClick={handleNextClick}
          >
            <ArrowForwardIosIcon />
          </IconButton>
          <div className="conf-preview">
            {openView?.current === "vehicle_confiscation_video" ? (
              <>
                <video
                  src={docs?.[openView?.current]?.base64}
                  width={"100%"}
                  height={"90%"}
                  controls={true}
                />
              </>
            ) : (
              <>
                <embed src={docs?.[openView?.current]?.fileLink}></embed>
              </>
            )}
            <Button
              className="conf-prev-download"
              onClick={() => {
                handleDownload(docs?.[openView?.current]);
              }}
            >
              <DownloadIcon sx={{mr:1, fontSize:14}}/> Download
            </Button>
          </div>
        </div>
      </BootstrapDialog>
    </div>
  );
}
