import React, { Component } from "react";
import PropTypes from "prop-types";

export class Autocomplete extends Component {
  static propTypes = {
    options: PropTypes.instanceOf(Array).isRequired,
  };
  state = {
    activeOption: 0,
    filteredOptions: [],
    showOptions: false,
    userInput: "",
  };

  onChange = (e) => {
    const { options } = this.props;
    const userInput = e.currentTarget.value;

    {
      this.props.clearError && this.props.clearError();
    }

    const filteredOptions = options.filter(
      (optionName) =>
        optionName?.name?.toLowerCase().indexOf(userInput?.toLowerCase()) > -1
    );

    this.setState({
      activeOption: 0,
      filteredOptions,
      showOptions: true,
      userInput: e.currentTarget.value,
    });
  };

  onClick = (e,val) => {
    this.setState(
      {
        activeOption: 0,
        filteredOptions: [],
        showOptions: false,
        userInput: e.currentTarget.innerText,
      },
      () => {
        const { onSubmit } = this.props;
        onSubmit(val);
        this.setState({
          activeOption: 0,
          filteredOptions: [],
          showOptions: false,
          userInput: "",
        });
      }
    );
  };
  onKeyDown = (e) => {
    const { activeOption, filteredOptions } = this.state;

    if (e.keyCode === 13) {
      this.setState({
        activeOption: 0,
        showOptions: false,
        userInput: filteredOptions[activeOption],
      });
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      this.setState({ activeOption: activeOption - 1 });
    } else if (e.keyCode === 40) {
      if (activeOption === filteredOptions.length - 1) {
        return;
      }
      this.setState({ activeOption: activeOption + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,

      state: { activeOption, filteredOptions, showOptions, userInput },
    } = this;
    let optionList;
    if (showOptions && userInput) {
      if (filteredOptions.length) {
        optionList = (
          <ul className="suggest-options">
            {filteredOptions.map((optionName, index) => {
              let className;
              if (index === activeOption) {
                className = "option-active";
              }
              return (
                <li className={className} key={optionName?.name} onClick={(e)=>onClick(e,optionName)}>
                  {optionName?.name}
                </li>
              );
            })}
          </ul>
        );
      } else {
        optionList = (
          <div className="no-options">{/* <em>No Option!</em> */}</div>
        );
      }
    }
    return (
      <React.Fragment>
        <div className="search">
          <input
            // type="password"
            autoComplete="new-off"
            className="cat-input w-100"
            onChange={onChange}
            // onKeyDown={onKeyDown}
            value={userInput}
            placeholder="Search..."
            // onBlur={(e) => {
            //   this.setState({
            //     activeOption: 0,
            //     showOptions: false,
            //     userInput: "",
            //   });
            // }}
          />
        </div>
        {optionList}
      </React.Fragment>
    );
  }
}

export default Autocomplete;
