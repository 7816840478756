export const statusColors = {
  pending: "#dbb418",
  rejected: "#c90a27",
  disbursed: "#429e0d",
  closed: "#5f0d85",
  approved: "#0b5ebd",
};

// export const backgroundColor = `body {
//   background: #5c258d;
//   background: -webkit-linear-gradient(
//     to right,
//     #4389a2,
//     #5c258d
//   );
//   background: linear-gradient(
//     to right,
//     #4389a2,
//     #5c258d
//   );
// }`

export const backgroundColor = `body {
  background-color: #dfd0ef;
  background-image: linear-gradient(180deg, #dfd0ef 0%, #f4d8ef 13%, #ffffff 100%);
}`;


export const LoginBackground = `html, body{
  display: grid;
  height: 100vh;
  width: 100%;
  place-items: center;
  background: linear-gradient(to right, #99004d 0%, #ff0080 100%);
}
::selection{
  background: #ff80bf;

}`;

