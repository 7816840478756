import React from "react";
import { Button } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const TableCsvDownload = ({ tableId, filename, topRow }) => {
  const downloadTableCsv = () => {
    const table = document.getElementById(tableId);
    if (!table) {
      console.error(`Table with id ${tableId} not found`);
      return;
    }

    const rows = table.querySelectorAll("tr");
    const csvData = [];

    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("th, td").forEach((cell) => {
        // Get computed styles to consider padding and other styles
        const computedStyle = window.getComputedStyle(cell);
        const cellContent = cell.innerText;
        const cellValue =
          cell.tagName === "TH" ? cellContent.toUpperCase() : cellContent.replace("₹","");
        rowData.push(`"${cellValue}"`);
      });
      csvData.push(rowData.join(","));
    });

    const csvContent = (topRow ? topRow : "") + csvData.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  return (
    <div className={"mt-2"}>
      <Button
        onClick={() => {
          downloadTableCsv();
        }}
      >
        <FileDownloadIcon/> Download
      </Button>
    </div>
  );
};

export default TableCsvDownload;
