import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  MenuItem,
  TextField,
  createFilterOptions,
} from "@mui/material";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import React, { forwardRef, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { getGpsInventoryV2, gpsCallingDashboard } from "../../api";
import DateRangePicker from "../DateRangePicker";
import { LoadingSkeleton } from "./LoadingSkeleton";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const selectors = {
  procurer: [
    {
      name: "MMI",
      value: "mmi",
    },
    {
      name: "MUFIN",
      value: "mufin",
    },
    {
      name: "BOTH",
      value: "both",
    },
  ],
  application_status: [
    {
      name: "NBFC ASSIGNED",
      value: "NBFC_ASSIGNED",
    },
    {
      name: "DISBURSED",
      value: "DISBURSED",
    },
  ],
  gps_status: [
    {
      name: "NOT ACTIVATED",
      value: "NOT_ACTIVATED",
    },
    {
      name: "NOT INSTALLED",
      value: "NOT_INSTALLED",
    },
  ],
};

const GPSInventoryFilter = forwardRef(
  ({ open, handleClose, onApply, options, name }, ref) => {
    const columns = {
      field: "",
      value: [],
      options,
    };

    const initialState = [{ ...columns }];

    const [filterDataValue, setFilterDataValue] = useState([]);
    const [filterData, setFilterData] = useState(initialState);
    const [selectData, setSelectData] = useState([]);
    const [reduceData, setReduceData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getFilterValue = async () => {
      try {
        setLoading(true);
        const API =
          name === "GpsDistribution" ? getGpsInventoryV2 : gpsCallingDashboard;
        let { data } = await API("");
        setFilterDataValue(data?.data?.data || data?.data || []);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };

    const isPrevCompleted = () => {
      let finalObj = filterData[filterData?.length - 1] ?? {};
      for (let value of Object.values(finalObj)) {
        if (["", null, undefined]?.includes(value)) {
          return false;
        }
      }
      return true;
    };

    const addMore = () => {
      if (!isPrevCompleted()) {
        return;
      }

      setFilterData([
        ...filterData,
        {
          ...columns,
          options: options.filter((item) => {
            return !filterData.some((e) => e.field === item);
          }),
        },
      ]);
    };

    const handleFormatArray = () => {
      setSelectData(
        filterDataValue?.reduce((acc,item) => {
          // const distribution = {
          //   status: {
          //     name: item?.status,
          //     value: item?.status,
          //   },
          //   agents : {
          //     name: item?.agents_assigned?.name,
          //     agent_code: item?.agents_assigned?.agent_code,
          //   }
          // };
          const distribution = item?.assigned_agents?.map(itm=>({
            status: {
              name: item?.status,
              value: item?.status,
            },
            agents : {
              name: itm?.name,
              value: itm?.agent_code,
            }
          }))
          const installationChase = {
            gps_status: {
              name: item?.gps_status,
              value: item?.gps_status,
            },
            dealers: {
              name: item?.dealer_name,
              value: item?.anchor_id,
            },
          };
          return name === "GpsDistribution" ? [...acc,...(distribution || [])] : [...acc,installationChase];
        },[])
      );
    };

    const handleApply = (data) => {
      let finalFilter = {};
      (data ? data : filterData)?.map((item, index) => {
        if (item.field === "disbursed_date") {
          finalFilter["disbursed_start_date"] = item?.value?.from;
          finalFilter["disbursed_to_date"] = item?.value?.to;
        } else if (selectors?.[item.field]) {
          finalFilter[item.field] = item?.value?.[0];
        } else {
          finalFilter[item.field] = item.value?.map(
            (val) => val[item.field]?.value
          );
        }
      });
      onApply(finalFilter);
    };

    const removeItem = (i) => {
      if (filterData[i].field === "current_stage") {
        setFilterData([...filterData.filter((item, index) => index === i)]);
      } else
        setFilterData([...filterData.filter((item, index) => index !== i)]);
    };

    const handleChange = (key, value, i) => {
      let obj = { ...filterData[i] };
      obj = {
        ...obj,
        [key]: value,
      };

      if (key === "field") {
        obj = {
          ...obj,
          value: ["disbursed_date"].includes(value)
            ? {
                from: Number(new Date()),
                to: Number(new Date()),
              }
            : [],
        };
      }

      filterData[i] = obj;
      if (key === "field") {
        setFilterData([...filterData.splice(0, i + 1)]);
        return;
      }

      setFilterData([...filterData]);
    };

    const handleReduceFilterValue = (init) => {
      let dropData = {};
      let finalArray = [...selectData];
      let filtered = init
        ? JSON.parse(localStorage.getItem(`filterData${name}`))
        : filterData;
      filtered?.map((item, index) => {
        if (
          item.field !== "" &&
          item.field !== "disbursed_date" &&
          item?.value?.length > 0
        ) {
          finalArray = finalArray.filter((obj1) => {
            return item?.value?.some(
              (obj2) =>
                obj1?.[`${item?.field}`]?.value ===
                obj2?.[`${item?.field}`]?.value
            );
          });
        }
        dropData = {
          ...dropData,
          [index + 1]: [...finalArray],
        };
      });
      setReduceData(dropData);
    };

    useEffect(() => {
      handleFormatArray();
    }, [filterDataValue]);

    useEffect(() => {
      handleReduceFilterValue();
    }, [selectData, filterData]);

    useEffect(() => {
      if (open && Boolean(filterData?.[0]?.value)) {
        localStorage.setItem(`filterData${name}`, JSON.stringify(filterData));
      }
    }, [filterData]);

    useEffect(() => {
      getFilterValue();
      let storageData = localStorage.getItem(`filterData${name}`);
      if (open && storageData) {
        setFilterData([...JSON.parse(storageData)]);
        handleReduceFilterValue(true);
      } else if (open && !storageData) {
        setFilterData([...initialState]);
      } else if (!open) {
        setFilterDataValue([]);
        setSelectData([]);
        setReduceData([]);
      }
    }, [open]);

    // useImperativeHandle(ref, () => ({}));

    return (
      <>
        <div>
          <Modal
            show={open}
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
            centered
          >
            <Modal.Body>
              {loading && <LoadingSkeleton />}
              {!loading && (
                <div className="sort-dialog">
                  <div className="sort-table-wrapper">
                    <table
                      className="sort-table app-sort-table"
                      id="app-sort-table"
                    >
                      <thead className="cf">
                        <tr>
                          <th style={{ width: 500 }}>Column</th>
                          <th style={{ width: "70%" }}>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterData?.map((col, i) => {
                          return (
                            <>
                              <tr>
                                <td data-title="Data Point">
                                  <Select
                                    className="w-100"
                                    value={col.field}
                                    sx={{
                                      boxShadow: "none",
                                      ".MuiOutlinedInput-notchedOutline": {
                                        border: 0,
                                      },
                                    }}
                                    onChange={(e) => {
                                      handleChange("field", e.target.value, i);
                                    }}
                                  >
                                    {col?.options?.map((item) => {
                                      return (
                                        <MenuItem value={item}>
                                          {(item || "")
                                            ?.replaceAll("_", " ")
                                            ?.toUpperCase()}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </td>
                                <td data-title="Operator">
                                  {col?.field !== "" &&
                                    (() => {
                                      if (col?.field === "disbursed_date") {
                                        return (
                                          <DateRangePicker
                                            startDate={
                                              new Date(
                                                col?.value?.from || new Date()
                                              )
                                            }
                                            endDate={
                                              new Date(
                                                col?.value?.to || new Date()
                                              )
                                            }
                                            onChange={(start, end) => {
                                              handleChange(
                                                "value",
                                                {
                                                  from: Number(start),
                                                  to: Number(end),
                                                },
                                                i
                                              );
                                            }}
                                          />
                                        );
                                      } else if (selectors?.[col?.field]) {
                                        return (
                                          <FormControl fullWidth>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              label=""
                                              value={col.value?.[0]}
                                              onChange={(x) => {
                                                handleChange(
                                                  "value",
                                                  [x.target.value],
                                                  i
                                                );
                                              }}
                                            >
                                              {selectors?.[col?.field]?.map(
                                                (itm) => (
                                                  <MenuItem value={itm?.value}>
                                                    {itm?.name}
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                          </FormControl>
                                        );
                                      } else {
                                        return (
                                          <Autocomplete
                                            multiple
                                            id="checkboxes-tags-demo"
                                            options={(() => {
                                              const allOptions = [
                                                ...new Map(
                                                  i === 0
                                                    ? selectData?.map(
                                                        (item) => [
                                                          item?.[col?.field]
                                                            ?.value,
                                                          item,
                                                        ]
                                                      )
                                                    : reduceData?.[i]?.map(
                                                        (item) => [
                                                          item?.[col?.field]
                                                            ?.value,
                                                          item,
                                                        ]
                                                      )
                                                ).values(),
                                              ].filter((itm) =>
                                                Boolean(
                                                  itm?.[col?.field]?.value
                                                )
                                              );
                                              return allOptions;
                                            })()}
                                            value={col?.value}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) =>
                                              option?.[col?.field]?.name
                                            }
                                            filterOptions={createFilterOptions({
                                              matchFrom: "any",
                                              stringify: (option) =>
                                                option?.[col?.field]?.name ||
                                                "",
                                            })}
                                            renderOption={(
                                              props,
                                              option,
                                              { selected }
                                            ) => (
                                              <li
                                                {...props}
                                                key={
                                                  option?.[col?.field]?.value
                                                }
                                              >
                                                <Checkbox
                                                  icon={icon}
                                                  checkedIcon={checkedIcon}
                                                  style={{ marginRight: 8 }}
                                                  checked={selected}
                                                />
                                                {option?.[col?.field]?.name +
                                                  (col?.field === "anchor"
                                                    ? " - " +
                                                      option?.[col?.field]
                                                        ?.value
                                                    : "")}
                                              </li>
                                            )}
                                            fullWidth
                                            onChange={(e, value) => {
                                              handleChange(
                                                "value",
                                                [...value],
                                                i
                                              );
                                            }}
                                            renderInput={(params) => (
                                              <TextField {...params} />
                                            )}
                                          />
                                        );
                                      }
                                    })()}
                                </td>
                                <td
                                  className="sort-close-td ps-1"
                                  style={{ width: "30px" }}
                                >
                                  {i !== 0 && (
                                    <CloseIcon
                                      onClick={() => removeItem(i)}
                                      sx={{ fontSize: 18 }}
                                    />
                                  )}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>

                      {filterData?.length !== options?.length && (
                        <Button className="mb-3" onClick={addMore}>
                          Add More
                        </Button>
                      )}
                    </table>
                  </div>

                  <div className="sort-bottom-holder">
                    <Button
                      className="me-2 clear-btn"
                      onClick={() => handleClose()}
                    >
                      Close
                    </Button>

                    <Button
                      variant="contained"
                      className="apply-btn"
                      onClick={() => handleApply()}
                    >
                      Apply Filters
                    </Button>
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default GPSInventoryFilter;
