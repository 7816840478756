import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useState } from "react";
import { useEffect } from "react";
import { CircularProgress, TextField } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";

const dppMenu = {
  "0%": { eq: 0 },
  "0% to <100%": { from: 0, to: 100 },
  "100% to <200%": { from: 100, to: 200 },
  ">=100%": { from: 200 },
};
export default function ProfileFilter({
  setProfileFilter,
  profileFilter,
  onApply,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElCredit, setAnchorElCredit] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBPPClick = (event) => {
    setAnchorElCredit(event.currentTarget);
  };
  const handleBPPClose = () => {
    setAnchorElCredit(null);
  };

  const handleFormatParam = (value, mapValue) => {
    let params = "?";
    if (["Disbursed", "Approved", "Rejected"]?.includes(value)) {
      params += `filter=status&value=${value?.toLowerCase()}`;
    } else if (value === "dpd") {
      params += `filter=dpd`;
    } else if (value === "bpp" && mapValue) {
      params += `filter=bpp&value=${encodeURIComponent(
        JSON.stringify(dppMenu?.[mapValue]).toString()
      )}`;
    }

    console.log(params);
    onApply(params);
    handleClose();
  };

  return (
    <div className="mt-2">
      <Button
        id="demo-positioned-button"
        variant="outlined"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        style={{
          background: "white",
          color: "#6E36FF",
          fontWeight: 400,
          borderColor: "#6E36FF",
        }}
        onClick={handleClick}
      >
        <TuneIcon sx={{ fontSize: 18, mr: 1 }} /> {profileFilter}{" "}
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="px-4 py-2">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={profileFilter}
              name="radio-buttons-group"
              onChange={(e) => {
                if (e.target.value !== "bpp") {
                  setProfileFilter(e.target.value);
                  handleFormatParam(e.target.value);
                }
              }}
            >
              <FormControlLabel value={"All"} control={<Radio />} label="All" />
              <FormControlLabel
                value={"Disbursed"}
                control={<Radio />}
                label="Disbursed"
              />
              <FormControlLabel
                value={"Approved"}
                control={<Radio />}
                label="Approved"
              />
              <FormControlLabel
                value={"Rejected"}
                control={<Radio />}
                label="Rejected"
              />

              <FormControlLabel
                value={"bpp"}
                // checked
                control={<Radio />}
                label={
                  <>
                    <div>
                      Borrower profile percentage <ArrowRightIcon />
                    </div>
                  </>
                }
                onClick={handleBPPClick}
              />
              <Menu
                anchorEl={anchorElCredit}
                open={Boolean(anchorElCredit)}
                // defaultValue={creditChecker}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  horizontal: "top",
                }}
                onClose={() => {
                  handleBPPClose();
                  handleClose();
                }}
              >
                {Object.keys(dppMenu)?.map((item) => (
                  <MenuItem
                    sx={{ color: "blue" }}
                    onClick={() => {
                      setProfileFilter("bpp");
                      handleFormatParam("bpp", item);
                      handleBPPClose();
                      handleClose();
                    }}
                    // value={mail}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Menu>

              <FormControlLabel
                value={"dpd"}
                control={<Radio />}
                label="DPD 30+ (after the threshold of 3500)"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </Menu>
    </div>
  );
}
