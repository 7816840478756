import {
  Close,
  Download,
  FileDownload,
  InsertDriveFile,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";
import uploadAnimation from "../../assets/cloud-upload.gif";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { ProgressBar } from "react-bootstrap";
import DragDrop from "../DocumentUploader";
import moment from "moment";
import BulkUploadStatus from "../BulkUploadStatus";
import { gpsBulkUplaod } from "../../api";
import GPSBulkUploadStatus from "./GPSBulkUploadStatus";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialStateBulkResponse = {
  successArray: [],
  failedArray: [],
  successCount: 0,
  failedCount: 0,
  open: false,
};

function GPSBulkUplaod({
  open,
  sampleHeader = [],
  collectionName = "",
  handleClose,
}) {
  const [collectionCSV, setCollectionCSV] = useState(null);
  const [isUploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [bulkUploadResponse, setBulkUploadResponse] = useState(
    initialStateBulkResponse
  );

  const handleUploadExit = () =>{
    setUploading(false);
    setProgress(0);
    setCollectionCSV(null);
    handleClose();
  }

  const handleUploadCollectionData = async () => {
    setUploading(true);
    try {
      let payload = {
        csv: collectionCSV,
      };
      let { data } = await gpsBulkUplaod(payload, setProgress);
      setBulkUploadResponse({
        successArray: data?.successArr,
        failedArray: data?.failedArr,
        successCount: data?.successArr?.length,
        failedCount: data?.failedArr?.length,
        failedCsv: data?.failedCsvBase64,
        open: true,
      });
      handleClose();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setCollectionCSV(null);
      setProgress(0);
      setUploading(false);
    }
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleSampleCsv = () => {
    downloadFile({
      data: [...sampleHeader, []].join("\n"),
      fileName: `Bulk-upload-sample-(${moment(new Date()).format(
        "DD-MM-YYYY"
      )})`,
      fileType: "text/csv",
    });
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        {!isUploading ? (
          <DialogContent>
            {collectionCSV === null ? (
              <div className="mb-5">
                <div className="d-flex justify-content-end mb-4">
                  <Button
                    onClick={() => {
                      handleSampleCsv();
                    }}
                  >
                    <FileDownload /> Sample
                  </Button>
                </div>
                <DragDrop
                  customType={["CSV"]}
                  setFile={(val, name) => {
                    setCollectionCSV(val);
                  }}
                />
              </div>
            ) : (
              <>
                <div
                  className="mt-2 d-flex"
                  style={{ width: "300px", justifyContent: "space-between" }}
                >
                  <div>
                    <InsertDriveFile /> {collectionName}
                  </div>
                  <Close
                    className="pointer"
                    onClick={() => {
                      setCollectionCSV(null);
                    }}
                  />
                </div>
              </>
            )}
          </DialogContent>
        ) : (
          <DialogContent>
            <div>
              <p className="text-center">
                <img
                  className="mt-5"
                  src={uploadAnimation}
                  width={200}
                  alt="uploading..."
                />
              </p>
              <ProgressBar
                className="mt-4"
                striped
                variant="success"
                label={`${progress.toFixed(2)}%`}
                now={progress}
              />
            </div>
          </DialogContent>
        )}
        {!isUploading && (
          <DialogActions>
            <Button onClick={handleUploadExit}>Cancel</Button>
            <Button onClick={handleUploadCollectionData}>Submit</Button>
          </DialogActions>
        )}
      </Dialog>
      <GPSBulkUploadStatus
        open={bulkUploadResponse?.open}
        successArray={bulkUploadResponse?.successArray}
        failedArray={bulkUploadResponse?.failedArray}
        successCount={bulkUploadResponse?.successCount}
        failedCount={bulkUploadResponse?.failedCount}
        failedCsv={bulkUploadResponse?.failedCsv}
        handleClose={() => {
          setBulkUploadResponse(initialStateBulkResponse);
        }} 
      />
    </>
  );
}

export default GPSBulkUplaod;
