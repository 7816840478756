import MouseOverPopover from "../Popover";
import { Avatar, Chip, styled } from "@mui/material";
import moment from "moment";
import { capitalize } from "../../utility/Formater";

export const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 35,
  height: 35,
  fontSize: "12px",
  border: `2px solid ${theme.palette.background.paper}`,
}));

function CreditCheckerBox({
  value,
  boxData,
  handleOpenOptions,
  businessExeNameVisible,
  onClick
}) {

  let { credit_checker } = boxData?.additional_stages || {};
  credit_checker = credit_checker?.length ? credit_checker : [{}]

  const replacedEmail = boxData?.additional_stages?.sales_agent?.[0]?.email?.replace(/\./g, " ");
  const sanitizedEmail = replacedEmail?.replace(/@.*$/, "");
  const sales_agent_name = capitalize(sanitizedEmail);
  const credit_check_email = credit_checker?.[0]?.email?.replace(/\./g, " ");
  const credit_checker_name = credit_check_email?.replace(/@.*$/, "");
  const avatar_name = credit_checker_name?.split(' ')?.map(itm=>itm?.[0]?.toUpperCase());

  return (
    <div className={`position-relative`}>
      <div className="position-absolute w-100 h-100 z-1" onClick={()=>{
        if(onClick) onClick(boxData)
      }} />
      <div
        className="w-100 d-flex flex-column align-items-start fw-semibold rounded"
        style={{ minHeight: 100 , fontSize: "12px", backgroundColor:"transparent !important", border:"2px solid #333333", padding:"5px" }}
      >
        {businessExeNameVisible && (
          <span>{sales_agent_name}</span>
        )}
        <div className="border p-1 px-2 h-100 w-100 rounded d-flex flex-column align-items-start">
          <div className="d-flex flex-row align-items-center flex-wrap">
            <span>{boxData?.customer_name}</span>
            <div className="d-flex flex-row align-items-center flex-wrap mx-2">
              {credit_checker?.map((_, index) => {
                  if (index === 0) {
                    return (
                      <Chip
                        key={index}
                        label="N"
                        size="small"
                        color="success"
                        className="px-1 m-1"
                        style={{ height: 15, fontSize: "10px" }}
                      />
                    );
                  }
                  return (
                    <Chip
                      key={index}
                      label="O"
                      size="small"
                      color="error"
                      className="px-1 m-1"
                      style={{ height: 15, fontSize: "10px" }}
                    />
                  );
                })}
            </div>
          </div>
          <div style={{ color: "#6E36FF" }}>
            {moment(boxData?.additional_stages?.[value]?.[0]?.dateMS).format(
              "DD-MM-YYYY hh:mm:ss A"
            )}
          </div>
          <div className="d-flex w-100 flex-row align-items-center justify-content-between">
            <span className="fw-normal">{boxData?.application_id}</span>
            <div className="z-2" >
              <MouseOverPopover className="ms-1" renderUI={<span className="fw-medium fs-6">{credit_checker_name ?? "Not Assigned"}</span>} head={
                <SmallAvatar
                className="pointer fw-semibold"
                sx={{ bgcolor: "#1976D2" }}
                alt="Remy Sharp"
                onClick={handleOpenOptions}
              >
                {avatar_name}
              </SmallAvatar>
              } />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditCheckerBox;
