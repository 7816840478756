import { useState, useEffect } from 'react';

const useCountdown = ({ timerCount }) => {
  const [remainingTime, setRemainingTime] = useState(null);
  const [timerStarted, setTimerStarted] = useState(false);

  const startTimer = () => {
    setRemainingTime(timerCount);
    setTimerStarted(true);
  };

  const restartTimer = () => {
    setRemainingTime(timerCount);
  };

  useEffect(() => {
    let timer;

    if (timerStarted && remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timerStarted, remainingTime]);

  return { remainingTime, startTimer, restartTimer };
};

export default useCountdown;
