import react, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { isBlank } from "../utility/validations";
import { updateNachId } from "../api";
import useApplication from "../hooks/useApplication";
import { useEffect } from "react";

const initalState = {
  mandateId: "",
  type: "physical",
};

export default function NachStatusUpdate({ open, handleClose, applicationId }) {
  const [nachData, setNachData] = useState(initalState);
  const [loading, setLoading] = useState(false);

  const { getApplicationDetails } = useApplication();

  const handleSubmit = async () => {
    try {
      const { mandateId, type } = nachData;
      if (isBlank(mandateId)?.length > 0) {
        toast.info("Enter Mandate ID");
        return;
      }

      setLoading(true);
      let payload = {
        mandateId,
        applicationId,
        isPhysicalNach: type === "physical",
      };

      let { data } = await updateNachId(payload);
      await getApplicationDetails(applicationId);
      handleClose();
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      setNachData(initalState);
    }
  }, [open]);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Nach Status update"}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              marginTop: 10,
              width: 350,
            }}
          >
            <TextField
              fullWidth
              label={"Mandate Id"}
              onChange={(e) => {
                setNachData({
                  ...nachData,
                  mandateId: e.target.value,
                });
              }}
            />
            <FormControl className="mt-3">
              <FormLabel id="demo-row-radio-buttons-group-label">
                Type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={nachData?.type}
                onChange={(e) => {
                  setNachData({
                    ...nachData,
                    type: e.target.value,
                  });
                }}
              >
                <FormControlLabel
                  value="physical"
                  control={<Radio />}
                  label="Physical"
                />
                <FormControlLabel
                  value="enach"
                  control={<Radio />}
                  label="Enach"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={loading} onClick={handleSubmit}>
            {loading ? "Loading..." : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
