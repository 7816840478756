import moment from "moment";
import * as dateFns from "date-fns";
import { irr } from "node-irr";

const generateEmiAndEdiSchemeV2 = (
  disbursedDate,
  tenure,
  loanAmount,
  interest
  //   tenureType,
) => {
  const { emiInstallment, efiInstallment } = calculateInstallment({
    loanAmount,
    interest,
    tenure,
    // tenureType,
  });

  const { emiStartDate, efiStartDate, emiBPDays } =
    calculateStartDate(disbursedDate);

  const irrEmi = calculateEmiIrr(loanAmount, emiInstallment, tenure);
  // console.log(loanAmount);
  // console.log(irrEmi);
  // console.log(emiBPDays);
  let emiBPRate = (Number(loanAmount) * irrEmi * 12 * emiBPDays) / 365;
  // console.log(emiBPRate);
  let adjustedEmi = Math.ceil(Number(emiInstallment) + Number(emiBPRate));
  // console.log(adjustedEmi);
  let adjustedEfi = Math.ceil(Number(emiInstallment / 2) + Number(emiBPRate));

  let { emiSheet } = generateEMIScheme(
    loanAmount,
    irrEmi * 12 * 100,
    Math.ceil(emiInstallment),
    tenure,
    [],
    adjustedEmi,
    loanAmount,
    emiStartDate
  );

  let { efiSheet } = generateEFIScheme(
    emiSheet,
    efiStartDate,
    loanAmount,
    Math.ceil(efiInstallment),
    adjustedEfi
  );

  return {
    emiSheet,
    efiSheet,
  };
};

function calculateEmiIrr(amount, emiInstallment, n) {
  const data = [Number(-amount)];
  for (let i = 0; i < n; i++) {
    if (i === n - 1) {
      data.push(emiInstallment);
    } else {
      data.push(emiInstallment);
    }
  }

  return irr(data);
}

// HERE IT GOEEEEEEEEESSSSSSSSSSSSSSSSSSS

function generateEMIScheme(
  loanAmount,
  emiIrr,
  emiInstallment,
  tenure,
  emiSheet,
  adjustedEmi,
  oldOs,
  emiStartDate
) {
  // const interest = loanAmount * emiIrr;

  const principal = calculatePPMT(
    emiSheet.length + 1,
    loanAmount,
    emiIrr.toFixed(2),
    tenure
  ).toFixed(2);

  let interest;
  if (emiSheet.length == Number(tenure) - 1) {
    interest = (adjustedEmi - principal).toFixed(2);
  } else {
    interest = (emiInstallment - principal).toFixed(2);
  }

  let OsPrinciple = Number(oldOs - principal)?.toFixed(2);
  let date = moment(emiStartDate, "DD/MM/YYYY").add(emiSheet.length, "months");

  if ([29, 28].includes(date.date())) {
    date.add(1, "days");
  }

  if (emiSheet.length < Number(tenure)) {
    let row = {
      SL_NO: emiSheet.length + 1,
      Due_Date: date.format("DD/MM/YYYY"),
      EMI:
        Number(emiSheet.length + 1) !== Number(tenure)
          ? emiInstallment
          : adjustedEmi,
      Principal: principal,
      Interest: Number(interest).toFixed(2),
      "O/s_Principal": Number(OsPrinciple) < 1 ? 0 : OsPrinciple,
    };
    emiSheet.push(row);

    return generateEMIScheme(
      loanAmount,
      emiIrr,
      emiInstallment,
      tenure,
      emiSheet,
      adjustedEmi,
      OsPrinciple,
      emiStartDate
    );
  } else {
    return {
      emiSheet,
    };
  }
}

const generateEFIScheme = (
  emiSheet,
  startDate,
  loanAmount,
  installment,
  adjustedEfi
) => {
  let efiSheet = [];
  let OsPrinciple = loanAmount;
  let count = 0;
  let date = moment(startDate, "DD/MM/YYYY").format("DD/MM/YYYY");

  emiSheet?.forEach((row, i) => {
    // let efi = row?.EMI / 2;
    let principal = row?.Principal / 2;
    // let interest = row?.Interest / 2;
    let interest = installment - principal;
    OsPrinciple = OsPrinciple - principal;

    efiSheet.push({
      SL_NO: ++count,
      Due_Date: date,
      EFI: installment,
      Principal: principal,
      Interest: Number(interest).toFixed(2),
      "O/s_Principal": Number(OsPrinciple) < 1 ? 0 : OsPrinciple.toFixed(2),
    });

    OsPrinciple = OsPrinciple - principal;
    date = nextDate(date);

    efiSheet.push({
      SL_NO: ++count,
      Due_Date: date,
      EFI: i === emiSheet.length - 1 ? adjustedEfi : installment,
      Principal: principal,
      Interest:
        i === emiSheet.length - 1
          ? Number(adjustedEfi - principal).toFixed(2)
          : Number(interest).toFixed(2),
      "O/s_Principal": Number(OsPrinciple) < 1 ? 0 : OsPrinciple.toFixed(2),
    });
    date = nextDate(date);
  });

  return {
    efiSheet,
  };
};

function calculateInstallment({
  loanAmount,
  interest,
  tenure /* tenureType */,
}) {
  // tenure = 160;
  let emi =
    (Number(loanAmount) + loanAmount * (interest / 100) * (tenure / 12)) /
    tenure;

  return {
    emiInstallment: Number(emi),
    efiInstallment: Number(emi) / 2,
  };
}

function calculatePPMT(period, loanAmount, interestRate, tenure, adjustedEmi) {
  const monthlyInterestRate = interestRate / 100 / 12;

  let monthlyInstallment;
  if (true) {
    monthlyInstallment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -tenure));
  } else {
    monthlyInstallment = adjustedEmi;
  }

  let remainingBalance = loanAmount;
  let principalPayment = 0;

  for (let i = 1; i <= period; i++) {
    const interestPayment = remainingBalance * monthlyInterestRate;
    principalPayment = monthlyInstallment - interestPayment;
    remainingBalance -= principalPayment;
  }

  return principalPayment;
}

function calculateStartDate(disbursalDate) {
  const parsedDate = dateFns.parse(disbursalDate, "dd/MM/yyyy", new Date());
  let date = dateFns.getDate(parsedDate);
  let month = dateFns.getMonth(parsedDate) + 1;
  let year = dateFns.getYear(parsedDate);

  if (date <= 15) {
    let emiStartDate = `15/${month + 1}/${year}`;
    let efiStartDate = `${month === 2 ? `01` : "30"}/${
      month === 2 ? month + 1 : month
    }/${year}`;
    let emiBPDays = calculateBPDays(disbursalDate, `15/${month}/${year}`);
    let efiBPDays = calculateBPDays(disbursalDate, `30/${month}/${year}`);

    return {
      emiStartDate,
      efiStartDate,
      emiBPDays,
      efiBPDays,
    };
  } else {
    let emiStartDate = `30/${month + 1}/${year}`;
    let efiStartDate = `15/${month + 1}/${year}`;
    let end;
    if (month === 2) {
      end = moment(
        dateFns.endOfMonth(
          dateFns.parse(disbursalDate, "dd/MM/yyyy", new Date())
        )
      ).format("DD/MM/YYYY");
    } else {
      end = `30/${month}/${year}`;
    }

    let emiBPDays = calculateBPDays(disbursalDate, end);
    let efiBPDays = calculateBPDays(disbursalDate, `15/${month}/${year}`);

    return {
      emiStartDate,
      efiStartDate,
      emiBPDays,
      efiBPDays,
    };
  }
}

function calculateBPDays(disbursalDate, adjustedDate) {


  let startDate = moment(disbursalDate, "DD/MM/YYYY");
  let endDate = moment(adjustedDate, "DD/MM/YYYY"); 

  // console.log(endDate.diff(startDate, "days") + 1);
  return endDate.diff(startDate, "days") + 1;
}

function nextDate(startDate) {
  let currentDate = moment(startDate, "DD/MM/YYYY");

  if (currentDate.date() === 1) {
    currentDate.add(14, "days");
  } else {
    currentDate.add(15, "days");
  }

  if ([14].includes(currentDate.date())) {
    currentDate.add(1, "days");
  }
  if ([29, 28].includes(currentDate.date())) {
    currentDate.add(2, "days");
  }

  return currentDate.format("DD/MM/YYYY");
}

export { generateEmiAndEdiSchemeV2 };
export default {
  generateEmiAndEdiSchemeV2,
};
