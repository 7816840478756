import axios from "axios";
export const baseURL = `https://api.products.embifi.in`;
// export const baseURL = `https://api.lms.embifi.in/api/v1`;

const API = axios.create({ baseURL, withCredentials: true });
API.interceptors.request.use(
  (config: any) => {
    config.headers["application"] = "EMBEDDED-TOOLS";
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

// export const checkNachStatus = (payload: { [key: string]: any }) =>
//   API.post(`/common/update-physical-nach-data`, payload);

export const getAllProducts = (params) => API.get(`/ev/v1/get-products?${params}`);
export const getAllBrands = () => API.get(`/api/brand/v1/brands`);
export const addProducts = (payload) => API.post(`/ev/v1/add-product`, payload);
export const addBrand = (payload) => API.post(`/api/brand/v1/create`, payload);
export const updateProductDetails = (id,payload) => API.put(`/ev/v1/product/${id}`,payload)
export const deleteProduct = (id) => API.delete(`/ev/v1/product/${id}`)
export const deleteProductImage = (payload) =>API.put(`/ev/v1/product-image-alter`,payload);
export const addProductImage = (payload) => API.post(`/ev/v1/add-image`,payload)
export const getProductData = (id) => API.get(`/ev/v1/product/${id}`)
