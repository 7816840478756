import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Grid, IconButton, Paper } from "@mui/material";
import otherdoc from "../assets/otherdoc.svg";
import AddIcon from "@mui/icons-material/Add";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { capitalize } from "../utility/Formater";
import folder from "../assets/folder.svg";
import { useEffect } from "react";
import { useRef } from "react";
import { compressImage, getBase64 } from "../utility/convertFileBase";
import { useState } from "react";
import { toast } from "react-toastify";
import CropScreen from "./CropScreen";
import useApplication from "../hooks/useApplication";
import LoadingOver from "./Loading";
import { deleteCheck, uploadCheck } from "../api";
import { useContext } from "react";
import { GlobalContext } from "../Context/GlobalContext";
import DocView from "./Anchor/DocView";
import { handleDownload } from "../utility/download";
import { UserContext } from "../Context/UserContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DocumentPopup({
  open,
  handleClose,
  title = "documents",
  documents = [],
  docIcon,
  application_id,
  firstDocName,
}) {
  const [image, setImage] = useState("");
  const [cropImg, setCropImg] = useState("");
  const [loading, setLoading] = useState(null);
  const [docsArray, setDocsArray] = useState(documents || []);
  const [viewDoc, setViewDoc] = useState({open:false});
  const fileRef = useRef(null);
  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails] = updateDetailState;
  const { user, setUser } = useContext(UserContext);
  const { getApplicationDetails } = useApplication();

  if (docsArray?.length === 0) {
    docsArray.push({
      name: firstDocName || "upload",
    });
  }

  let isAddMoreDisabled = !Boolean(docsArray?.[0]?.key);

  function handleFileUpload() {
    fileRef.current.value = "";
    fileRef.current?.click();
  }

  function handleRecapture() {
    setImage("");
    setCropImg("");
  }

  async function handleCheckUpload(base64, isReupload, docs) {
    try {
      setLoading(`Uploading Document...`);
      let params = `type=${title?.toLowerCase()}`;
      let payload = {
        doc: {
          file: base64,
        },
      };
      if (isReupload) {
        params += `override=${isReupload}`;
        payload.doc.fileName = docs?.name;
      }
      const { data } = await uploadCheck(application_id, params, payload);
      toast(data?.message);
      await getApplicationDetails(application_id);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(null);
    }
  }

  async function handleCheckReupload(base64, docType ,currDoc,closePopUps,setLoader){
    try {
        setLoader(`Replacing Document...`);
        let params = `type=${title?.toLowerCase()}&override=${true}`;

        let payload = {
          doc: {
            fileName:currDoc?.name,
            file: base64,
          },
        };

        const { data } = await uploadCheck(application_id, params, payload);
        toast(data?.message);
        await getApplicationDetails(application_id);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setLoader(null);
        closePopUps();
      }
  }

  async function handleCheckDelete(currDoc,closePopUps,setLoader) {
    try {
      setLoader(`deleting document - ${currDoc?.name}...`);

      let params = `${application_id}/${currDoc?.name}`;
      let query = `type=${title?.toLowerCase()}`;
      
      const { data } = await deleteCheck(params,query);
      toast(data?.message);
      await getApplicationDetails(application_id);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(null);
      closePopUps();
    }
  }

  useEffect(() => {
    if (cropImg.length) {
      handleCheckUpload(cropImg);
    }
  }, [cropImg]);

  useEffect(() => {
    setDocsArray(
      updateDetails?.bank_cheques_received?.[
        `cheque_with_${title?.toLowerCase()}`
      ] || []
    );
  }, [updateDetails,open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="d-flex flex-row align-items-center">
          <IconButton onClick={handleClose}>
            <KeyboardBackspaceIcon fontSize="medium" />
          </IconButton>
          <span>{title}</span>
        </DialogTitle>
        <DialogContent>
          <Grid container >
            {docsArray.map((doc, i) => {
                let checkListNo = doc?.name?.match(/\d+$/)[0];
              return (
                <Grid item xs={12} sm={2}>
                  <div
                    style={{
                      width: 120,
                      height: 140,
                      position: "relative",
                      cursor: "pointer",
                      display: "grid",
                      placeItems: "center",
                      paddingTop: 10,
                    }}
                    onClick={() => {
                        if(doc?.key) setViewDoc({open:true,...(doc || {})});
                        else handleFileUpload();
                    }}
                  >
                    <Paper
                      elevation={3}
                      sx={{
                        width: 100,
                        height: 80,
                        display: "grid",
                        placeItems: "center",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                          overflow: "hidden",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "60px",
                            zIndex: 1,
                            position: "absolute",
                            cursor: "pointer",
                          }}
                        ></div>
                        <img
                          src={doc?.key ? docIcon || otherdoc : folder}
                          width={60}
                        />
                      </div>
                    </Paper>
                    <div
                      style={{
                        width: 100,
                        height: 80,
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <p
                        className="text-center font-rocknroll"
                        style={{ fontSize: "13px", fontWeight: 500 }}
                      >
                        {capitalize(`cheque_${checkListNo}` || "", "_")}
                      </p>
                    </div>
                  </div>
                </Grid>
              );
            })}
            <Grid item xs={12} sm={1.5}>
              <div
                style={{
                  width: 120,
                  height: 140,
                  position: "relative",
                  cursor: isAddMoreDisabled ? "default" : "pointer",
                  display: "grid",
                  placeItems: "center",
                  paddingTop: 10,
                  opacity: isAddMoreDisabled ? 0.6 : 1,
                }}
                onClick={() => {
                  if (!isAddMoreDisabled) handleFileUpload();
                }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    width: 100,
                    height: 80,
                    display: "grid",
                    placeItems: "center",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                      overflow: "hidden",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <div className="p-1 rounded-circle bg-primary text-white d-flex flex-column align-items-center">
                      <AddIcon />
                    </div>
                  </div>
                </Paper>
                <div
                  style={{
                    width: 100,
                    height: 80,
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <p
                    className="text-center"
                    style={{ fontSize: "13px", fontWeight: 500 }}
                  >
                    {""}
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <CropScreen
        open={image !== ""}
        image={image}
        onClose={() => {
          setImage("");
        }}
        onCrop={(file) => {
          setCropImg(file);
          setImage("");
        }}
        onRecapture={() => {
          handleRecapture();
          fileRef.current.click();
        }}
      />
      <input
        ref={fileRef}
        type="file"
        class="custom-file-input"
        accept={`image/png, image/jpg, image/jpeg`}
        onChange={async (e) => {
          setCropImg("");
          if (e.target.files[0]["type"].split("/")[0] == "image") {
            if (e.target.files[0].type.match("image/svg")) {
              toast.error("Not supported file");
              return false;
            }
            setImage(await getBase64(await compressImage(e.target.files[0])));
          } else {
            alert("Pdf/Image only");
          }
        }}
        hidden
      />
      {loading && <LoadingOver text={loading} />}
      {viewDoc?.open && (
        <DocView
          docs={viewDoc}
          handleClose={() => {
            setViewDoc((prev) => ({ ...prev, open: false }));
          }}
          handleDownload={ async (docs, setLoading,returnBlob) => {
             return await handleDownload(
              docs?.fileLink,
              `${application_id}_${docs?.name}`,
              docs,
              setLoading,
              {
                user,
                setUser,
              },
              returnBlob
            );
          }}
          category={viewDoc?.category}
          isAadhaar={false}
          swipeDocs={docsArray}
          getSwipeDocs={()=>docsArray}
          reuploadDoc={handleCheckReupload}
          deleteDocFun={handleCheckDelete}
        />
      )}
    </React.Fragment>
  );
}
