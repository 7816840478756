import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton } from "@mui/material";

const buttonStyle = {
  fontSize: "14px",
  color: "1px solid skyblue",
  padding: "15px",
  borderRadius: 2,
};

function OtherCollectionPopup({ open, header, handleClose, handleDownload }) {
  return (
    <div>
      <Dialog
        sx={{
          filter:
            "drop-shadow(6px 6px 30px rgba(125, 153, 180, 0.2)) drop-shadow(2px 2px 4px rgba(86, 144, 198, 0.11))",
          border: "none",
          outline: "none",
          borderRadius: "10px",
        }}
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <p className="text-center mb-0" style={{ fontSize: 15 }}>
            {header}
          </p>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box>
            <Grid container spacing={2}>
              <Grid xs={12} item className="px-4">
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ ...buttonStyle }}
                  onClick={() => handleDownload("download_all")}
                  color="inherit"
                >
                  <FileDownloadOutlinedIcon />{" "}
                  <span className="ms-2">Download All</span>
                </Button>
              </Grid>
              <Grid xs={12} item className="px-4">
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ ...buttonStyle }}
                  onClick={() => handleDownload("download_with_current_filter")}
                  color="inherit"
                >
                  <FilterAltOutlinedIcon />{" "}
                  <span className="ms-2">Download with filter</span>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default OtherCollectionPopup;
