import { CircularProgress, Grid, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { monthlyDisbursedData } from "../../api";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const monthsMap = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

function stringToColor(str, opacity = 0.2) {
  const hash = Array.from(str).reduce(
    (hash, char) => (char.charCodeAt(0) + (hash << 5) - hash) & 0xffffff,
    0
  );

  const hexColor = `#${(hash & 0xffffff).toString(16).padStart(6, "0")}`;
  const rgbaColor = `rgba(${(hash & 0xff0000) >> 16}, ${
    (hash & 0x00ff00) >> 8
  }, ${hash & 0x0000ff}, ${opacity})`;

  return rgbaColor;
}

export default function NachDashboardV2({ nachData, loader }) {
  const [monthlyData, setMonthlyData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [months, setMonths] = useState([]);

  useEffect(() => {
    if (Array?.isArray(nachData) && nachData?.length > 0) {
      let data = nachData;
      let total = data.pop();
      setMonthlyData(data);
      setTotalData(total);
      setMonths(data[0]?.monthlyData);
    } else {
      setMonthlyData([]);
    }
  }, [nachData]);

  const handleDownloadCsv = () => {
    let csvContent = `,,Total,,`;

    let columns = "";
    monthlyData.map((edx) => {
      csvContent += `,${(edx?.nbfc_name || "NA")?.split(" ")?.[0]},,`;
      columns += "Disbursed,Nach Success,%,";
    });

    csvContent += "\nMonth,Disbursed,Nach Success,%," + columns;

    let totalsRow = "";
    months?.map((m, i) => {
      csvContent += `\n${m?.month}/${m?.year},`;
      csvContent += `${totalData?.monthlyData?.[i]?.disbursedCount},${totalData?.monthlyData?.[i]?.nachSuccessCount},${totalData?.monthlyData?.[i]?.percentage},`;
      if (i === months?.length - 1) {
        totalsRow = `Grand Total,${totalData?.disbursedCount},${totalData?.nachSuccessCount},${totalData?.percentage},`;
      }
      monthlyData.map((d) => {
        let edx = d?.monthlyData?.[i];
        csvContent += `${edx?.disbursedCount},${edx?.nachSuccessCount},${edx?.percentage},`;
        totalsRow += `${d?.disbursedCount},${d?.nachSuccessCount},${d?.percentage},`;
      });
    });

    csvContent += `\n${totalsRow}`;

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, "nach-success-registered");
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "nach-success-registered");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  return (
    <>
      <div
        style={{ overflow: "scroll", maxHeight: "80vh", maxWidth: "100%" }}
        // className="custom-table"
      >
        {loader === "NACH" ? (
          <div style={styles?.loading_container}>
            <CircularProgress color="inherit" />
          </div>
        ) : monthlyData?.length > 0 ? (
          <table style={styles?.table}>
            <thead style={styles?.thead}>
              <tr>
                <th style={styles?.th_top_months}> </th>
                <th
                  style={{
                    ...styles?.th_top_nbfcs,
                    background: "#adacac",
                  }}
                >
                  Total
                </th>
                {monthlyData.map((edx) => {
                  // console.log(edx);
                  return (
                    <th
                      style={{
                        ...styles?.th_top_nbfcs,
                        background: stringToColor(edx?.nbfc_name, 0.5),
                      }}
                    >
                      {(edx?.nbfc_name || "NA")?.split(" ")?.[0]}
                    </th>
                  );
                })}
              </tr>
              <tr>
                <th style={styles?.th_secondary}>Month </th>
                <th
                  style={{
                    background: "#adacac",
                  }}
                >
                  <th
                    style={{
                      ...styles?.th_secondary,
                    }}
                  >
                    Disbursed{" "}
                  </th>
                  <th
                    style={{
                      width: "125px",
                      textAlign: "center",
                      fontSize: "12px",
                      borderRight: "1px solid",
                      color: "#0000008c",
                    }}
                  >
                    Nach Success
                  </th>
                  <th
                    style={{
                      width: "125px",
                      textAlign: "center",
                      fontSize: "12px",
                      color: "#0000008c",
                    }}
                  >
                    %
                  </th>
                </th>

                {monthlyData.map((edx) => {
                  return (
                    <th
                      style={{
                        ...styles?.th_top_nbfcs,
                        background: stringToColor(edx?.nbfc_name),
                      }}
                    >
                      <th
                        style={{
                          width: "125px",
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#0000008c",
                          borderRight: "1px solid",
                        }}
                      >
                        Disbursed
                      </th>
                      <th
                        style={{
                          width: "125px",
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#0000008c",
                          borderRight: "1px solid",
                        }}
                      >
                        Nach success
                      </th>
                      <th
                        style={{
                          width: "125px",
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#0000008c",
                        }}
                      >
                        %
                      </th>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ ...styles?.th_top_months }}>
                  {months?.map((edx, index) => {
                    return (
                      <tr>
                        <td align="center" style={{ minWidth: "125px" }}>
                          {monthsMap[edx?.month]},
                          {edx?.year.toString().slice(2, 4)}
                        </td>
                      </tr>
                    );
                  })}
                </td>
                <td
                  style={{
                    ...styles?.th_top_nbfcs,
                    background: "#e8e8e8",
                  }}
                >
                  <td
                    align="center"
                    style={{
                      borderRight: "1px solid",
                      width: "125px",
                    }}
                  >
                    {totalData?.monthlyData?.map((val) => {
                      return (
                        <tr>
                          <td align="center">{val["disbursedCount"]}</td>
                        </tr>
                      );
                    })}
                  </td>
                  <td
                    align="center"
                    style={{
                      borderRight: "1px solid",
                      width: "125px",
                    }}
                  >
                    {totalData?.monthlyData?.map((val) => {
                      return (
                        <tr>
                          <td align="center">{val["nachSuccessCount"]}</td>
                        </tr>
                      );
                    })}
                  </td>

                  <td
                    align="center"
                    style={{
                      width: "125px",
                    }}
                  >
                    {totalData?.monthlyData?.map((val) => {
                      return (
                        <tr>
                          <td align="center">{val["percentage"]}</td>
                        </tr>
                      );
                    })}{" "}
                  </td>
                </td>
                {monthlyData.map((edx, i) => {
                  return (
                    <td
                      style={{
                        ...styles?.th_top_nbfcs,
                        background: stringToColor(edx?.nbfc_name),
                      }}
                    >
                      <td
                        align="center"
                        style={{
                          borderRight: "1px solid",
                          width: "125px",
                        }}
                      >
                        {edx?.monthlyData?.map((val) => {
                          return (
                            <tr>
                              <td align="center">{val["disbursedCount"]}</td>
                            </tr>
                          );
                        })}
                      </td>

                      <td
                        align="center"
                        style={{
                          width: "125px",
                          borderRight: "1px solid",
                        }}
                      >
                        {edx?.monthlyData?.map((val) => {
                          return (
                            <tr>
                              <td align="center">{val["nachSuccessCount"]}</td>
                            </tr>
                          );
                        })}{" "}
                      </td>

                      <td
                        align="center"
                        style={{
                          width: "125px",
                        }}
                      >
                        {edx?.monthlyData?.map((val) => {
                          return (
                            <tr>
                              <td align="center">{val["percentage"]}</td>
                            </tr>
                          );
                        })}{" "}
                      </td>
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th style={{ fontSize: "14px", textAlign: "center" }}>
                  Grand Total
                </th>
                <th
                  style={{
                    ...styles?.th_top_nbfcs,
                    background: "#e8e8e8",
                  }}
                >
                  <th
                    style={{
                      width: "125px",
                      textAlign: "center",
                      fontSize: "14px",
                      borderRight: "1px solid",
                    }}
                  >
                    {totalData?.disbursedCount}{" "}
                  </th>
                  <th
                    style={{
                      width: "125px",
                      textAlign: "center",
                      fontSize: "14px",
                      borderRight: "1px solid",
                    }}
                  >
                    {totalData?.nachSuccessCount}{" "}
                  </th>
                  <th
                    style={{
                      width: "125px",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    {totalData?.percentage}{" "}
                  </th>
                </th>
                {monthlyData.map((edx) => {
                  return (
                    <th
                      style={{
                        ...styles?.th_top_nbfcs,
                        background: stringToColor(edx?.nbfc_name),
                      }}
                    >
                      <th
                        style={{
                          width: "125px",
                          textAlign: "center",
                          fontSize: "14px",
                          color: "rgb(16 16 16 / 89%)",
                          borderRight: "1px solid",
                        }}
                      >
                        {edx?.disbursedCount}{" "}
                      </th>
                      <th
                        style={{
                          width: "125px",
                          textAlign: "center",
                          fontSize: "14px",
                          color: "rgb(16 16 16 / 89%)",
                          borderRight: "1px solid",
                        }}
                      >
                        {edx?.nachSuccessCount}{" "}
                      </th>
                      <th
                        style={{
                          width: "125px",
                          textAlign: "center",
                          fontSize: "14px",
                          color: "rgb(16 16 16 / 89%)",
                        }}
                      >
                        {edx?.percentage}{" "}
                      </th>
                    </th>
                  );
                })}
              </tr>
            </tbody>
          </table>
        ) : (
          <div style={styles?.loading_container}>No data</div>
        )}
      </div>

      {loader === null && monthlyData?.length > 0 && (
        <Button
          className="mt-2"
          onClick={() => {
            handleDownloadCsv();
          }}
        >
          <FileDownloadIcon /> Download
        </Button>
      )}
    </>
  );
}

const styles = {
  table: {
    borderCollapse: "collapse",
    border: "1px solid",
  },
  thead: {
    border: "1px solid",
  },

  th_top_months: {
    border: "1px solid",
    width: "125px",
  },
  th_top_nbfcs: {
    minWidth: "375px",
    textAlign: "center",
    border: "1px solid",
  },
  th_secondary: {
    width: "125px",
    textAlign: "center",
    fontSize: "12px",
    color: "#0000008c",
    borderRight: "1px solid",
    borderCollapse: "collapse",
  },
  loading_container: {
    height: "20vh",
    width: "70vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
