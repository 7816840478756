import React, { useContext, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FileDownloadSharpIcon from "@mui/icons-material/FileDownloadSharp";
import DownloadingSharpIcon from "@mui/icons-material/DownloadingSharp";
import "../Anchor/style.css";
import { getBase64 } from "../../utility/convertFileBase";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalContext";
import folder from "../../assets/folder.svg";
import { UserContext } from "../../Context/UserContext";
import { verifyAuth } from "../../api";
import { getDocKey } from "../../utility/Formater";
import axios from "axios";
import DocView from "../Anchor/DocView";
import { handleDownload } from "../../utility/download";
import moment from "moment";

const tempState = {
  open: false,
  index: null,
  base64: null,
  text: "",
};

function UploadOtherDoc({
  docs = [],
  heading,
  mt,
  onChange,
  onDelete,
  aadhaar_front = "aadhaar_front",
  aadhaar_back = "aadhaar_back",
  category = "aadhar_image",
  error,
  onAddMore,
  isEditable = true,
  isDownloadAble = false,
  anchor_id = null,
  swipeArr,
  getSwipeArr,
  handleSingleDocReupload,
  handleSingleDocDelete,
}) {
  const fileInputFront = useRef(null);
  const fileInputBack = useRef(null);
  const [currentUpload, setCurrent] = useState(tempState);

  const { dealerDetailState, updateDocsState } = useContext(GlobalContext);
  const { user, setUser } = useContext(UserContext);
  const [isDownloading, setIsDownloading] = useState(null);
  const [doc, setDoc] = useState({});
  const [dealerDetails, setDealerDetails] = dealerDetailState;
  const [updateDocs, setUpdateDocs] = updateDocsState;

  const handleClose = () => {
    setCurrent(tempState);
  };

  return (
    <>
      <div style={{ marginTop: mt ? mt : 0 }}>
        <Grid container spacing={2} style={{ marginTop: 1 }}>
          {heading && (
            <Grid item xs={12} style={{ marginBottom: -10 }}>
              <span className="upload-section-heading">{heading}</span>
            </Grid>
          )}
          {docs?.length === 0 && !isEditable && (
            <Grid item xs={2.5}>
              <div
                className="anchor-doc-preview"
                style={{
                  backgroundColor: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img src={folder} height={"70px"} alt="document"></img>
              </div>
            </Grid>
          )}
        </Grid>
        {docs?.length === 0 && isEditable && (
          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs={3}>
              <Button
                variant={"contained"}
                className="upload-btn-anchor"
                onClick={(e) => {
                  setCurrent({
                    ...currentUpload,
                    open: true,
                    index: 0,
                  });
                }}
              >
                <CloudUploadOutlinedIcon sx={{ mr: 1, fontSize: 18 }} /> Upload
              </Button>
            </Grid>
          </Grid>
        )}

        {docs?.map((doc, i) => {
          return (
            <Grid
              container
              spacing={2}
              key={doc?.uploadedDateMS || ""}
              sx={{ mt: 0.2 }}
            >
              <Grid item xs={2.5} sx={{ position: "relative" }}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setDoc({
                      ...(doc ?? {}),
                      open: true,
                    });
                  }}
                ></div>
                {isDownloadAble && doc?.name && (
                  <Button
                    className="other-doc-download-btn"
                    onClick={() => {
                      handleDownload(
                        doc?.fileLink,
                        `${anchor_id}-${doc?.name}`,
                        doc,
                        setIsDownloading,
                        {
                          user,
                          setUser,
                        }
                      );
                    }}
                    disabled={isDownloading?.name === doc?.name}
                  >
                    {isDownloading?.name === doc?.name ? (
                      <DownloadingSharpIcon style={{ fontSize: 16 }} />
                    ) : (
                      <FileDownloadSharpIcon style={{ fontSize: 16 }} />
                    )}
                  </Button>
                )}

                <div className="anchor-doc-preview">
                  {doc?.key ? (
                    <embed
                      key={doc?.key}
                      src={doc?.fileLink}
                      width={"100%"}
                      height={"100%"}
                    ></embed>
                  ) : doc?.dataType !== "pdf" ? (
                    <img
                      src={doc?.fileLink}
                      width={"100%"}
                      height={"100%"}
                      style={{
                        objectFit: "contain",
                      }}
                      alt="uploaded document"
                    />
                  ) : (
                    <iframe
                      src={doc?.fileLink}
                      width={"100%"}
                      height={"100%"}
                      style={{
                        objectFit: "contain",
                      }}
                      alt="uploaded document"
                    ></iframe>
                  )}
                </div>
                <div>
                  <TextField variant="standard" fullWidth multiline  value={doc?.name} disabled />
                </div>
                <span className="anchor-doc-error">{doc?.error}</span>
              </Grid>

              {isEditable && (
                <Grid item xs={2.5} sx={{ position: "relative" }}>
                  <Button
                    className="file-remove-btn remove-aadhaar"
                    onClick={() => {
                      if (onDelete) {
                        onDelete(doc, i);
                        if (dealerDetails?.update) {
                          if (doc?.isUploaded) {
                            setUpdateDocs([
                              ...updateDocs?.filter(
                                (item) => item?.id === doc?.id
                              ),
                            ]);
                          } else {
                            setUpdateDocs([
                              ...updateDocs,
                              {
                                new: false,
                                category: "aadhar_image",
                                ...doc,
                              },
                            ]);
                          }
                        }
                      }
                    }}
                  >
                    <CloseIcon />
                  </Button>

                  {i === docs?.length - 1 && (
                    <Button
                      variant={"outlined"}
                      className={`upload-btn-anchor add-more-aadhaar ${
                        docs?.length === 0 ? "add-more-disabled" : ""
                      }`}
                      disabled={docs?.length === 0}
                      onClick={(e) => {
                        setCurrent({
                          ...currentUpload,
                          open: true,
                          index: i,
                        });
                      }}
                    >
                      <AddCircleOutlineOutlinedIcon
                        sx={{ mr: 1, fontSize: 18 }}
                      />{" "}
                      Add more
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          );
        })}
      </div>

      <input
        ref={fileInputFront}
        type="file"
        class="custom-file-input"
        accept="application/pdf , image/png, image/jpg, image/jpeg"
        onChange={async (e) => {
          setCurrent({
            ...currentUpload,
            base64: await getBase64(e.target.files[0]),
            frontDocType: e.target.files[0].type.includes("image")
              ? "image"
              : "pdf",
          });
          e.target.value = "";
        }}
        onClick={(e) => {
          e.target.value = "";
        }}
        hidden
      />

      <Dialog
        open={currentUpload?.open}
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6.1}>
              <TextField
                variant="standard"
                label="Document Name"
                value={currentUpload?.name}
                onChange={(e) => {
                  setCurrent((prev) => {
                    return {
                      ...prev,
                      text: e.target.value,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {typeof currentUpload?.base64 === "string" ? (
                <>
                  <div className="anchor-doc-preview">
                    <embed src={currentUpload?.base64}></embed>
                  </div>
                  <span className="anchor-doc-error">{doc?.error}</span>
                </>
              ) : (
                <Button
                  variant={"contained"}
                  className="upload-btn-anchor"
                  onClick={(e) => {
                    fileInputFront.current.click();
                  }}
                >
                  <CloudUploadOutlinedIcon sx={{ mr: 1, fontSize: 18 }} />{" "}
                  other document
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={
              !(
                typeof currentUpload?.base64 === "string" &&
                currentUpload?.text?.length
              )
            }
            onClick={(e) => { 
              onChange({
                id: moment().valueOf(),
                isUpload: true,
                fileLink: currentUpload?.base64,
                name: currentUpload?.text,
              });
              handleClose();
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
      {doc?.open && (
        <DocView
          docs={doc}
          handleClose={() => {
            setDoc((prev) => ({ ...prev, open: false }));
          }}
          handleDownload={(docs, setLoading) => {
            handleDownload(
              docs?.fileLink,
              `${anchor_id}-${docs?.name}`,
              docs,
              setLoading,
              {
                user,
                setUser,
              }
            );
          }}
          category={category}
          swipeDocs={swipeArr}
          getSwipeDocs={getSwipeArr}
          reuploadDoc={handleSingleDocReupload}
          deleteDocFun={handleSingleDocDelete}
        />
      )}
    </>
  );
}

export default UploadOtherDoc;
