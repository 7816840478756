import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  enabledButton,
} from "@mui/material";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import "./style.css";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { saveAs } from "file-saver";
import { getBase64 } from "../../utility/convertFileBase";
import {
  addVehicleConfiscate,
  deleteDocument,
  getVehicleConfiscate,
  uploadSingleConfistDoc,
  verifyAuth,
} from "../../api";
import { toast } from "react-toastify";
import { getDocKey } from "../../utility/Formater";
import { UserContext } from "../../Context/UserContext";
import { useContext } from "react";
import axios from "axios";
import { FileMimeType } from "../../Constants/memeTypes";
import ViewConsficDoc from "./ViewConfiscDocs";
import LoadingOver from "../Loading";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import DownloadIcon from "@mui/icons-material/Download";
import JSZip from "jszip";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DocumentUpload = ({
  title,
  style,
  doc,
  onChange,
  onDelete,
  isVideo = false,
  handleOpenView,
  handleDownload,
  isVehicleConfiscated,
}) => {
  const fileInput = useRef(null);
  return doc?.fileLink ? (
    <div
      style={{ position: "relative", cursor: "pointer", overflow: "hidden" }}
    >
      <Button
        className="confisc-file-remove-btn"
        onClick={() => {
          onDelete();
        }}
      >
        <CloseIcon />
      </Button>
      <div
        className="confisc-doc-preview"
        onClick={() => {
          handleOpenView(doc?.name);
        }}
      >
        {doc?.name === "vehicle_confiscation_video" ? (
          <>
            <div className="hover-div-btn">&nbsp;</div>
            <div className="play-btn">
              <SmartDisplayIcon sx={{ fontSize: 40 }} />
            </div>
            <video
              style={{
                width: "100%",
              }}
              src={doc?.base64}
            />
          </>
        ) : (
          <>
            <div className="hover-div-btn">&nbsp;</div>

            <object
              className={
                doc?.fileLink?.includes("application/pdf")
                  ? "object-pdf"
                  : "object-normal"
              }
              data={isVideo ? doc?.base64 : doc?.fileLink}
            ></object>
          </>
        )}
      </div>
      {isVehicleConfiscated && (
        <div className="confisc-button-holder">
          <div
            className="download-btn"
            onClick={() => {
              handleDownload(doc);
            }}
          >
            <DownloadIcon />
          </div>
        </div>
      )}
    </div>
  ) : (
    <>
      <div
        className="vehicle-doc-uploader"
        style={{ ...style }}
        onClick={() => {
          fileInput?.current?.click();
        }}
      >
        <div>
          <p className="text-center mb-1">
            <BackupOutlinedIcon />
          </p>
          <p className="doc-title">{title}</p>
        </div>
      </div>
      <input
        ref={fileInput}
        type="file"
        class="custom-file-input"
        accept={
          isVideo
            ? "video/mp4,video/x-m4v,video/*"
            : "application/pdf , image/png, image/jpg, image/jpeg"
        }
        onChange={async (e) => {
          let base64 = await getBase64(e.target.files[0]);
          onChange(base64);
          e.target.value = "";
        }}
        onClick={(e) => {
          e.target.value = "";
        }}
        hidden
      />
    </>
  );
};

export default function VehicleConfisication({
  open,
  handleClose,
  applicationData,
  getVehicleStatus,
  setGetVehicleStatus,
}) {
  const [vehicleStatus, setVehicleStatus] = useState(getVehicleStatus || null);
  const [remark, setRemark] = useState("");
  const [date, setDate] = useState(null);
  const [enabledButton, setEnableButton] = useState(false);
  const [docs, setDocs] = useState({});
  const { user, setUser } = useContext(UserContext);
  const [isLoading, setLoading] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [progress, setProgress] = useState(null);
  const [vehicleData, setVehicleData] = useState({});
  const [isConfiscated, setIsConfiscated] = useState(false);

  const [openView, setOpenView] = useState({
    open: false,
    current: null,
  });

  const handleStateUpdate = async () => {
    if (Boolean(getVehicleStatus)) {
      handleFetchData();
    } else {
      setDate(null);
      setRemark("");
      setDocs({
        vehicle_photo_from_front: {},
        vehicle_photo_from_back: {},
        vehicle_photo_from_inside: {},
        vehicle_photo_from_odometer: {},
        vehicle_confiscation_video: {},
      });
    }
  };

  useEffect(() => {
    if (open) {
      handleStateUpdate();
    }
  }, [open]);

  useEffect(() => {
    setIsConfiscated(getVehicleStatus === "CONFISCATED");
  }, [getVehicleStatus]);
  

  const fetchVideo = async (doc) => {
    return await handleDownload(doc, false);
  };

  const handleFetchData = async () => {
    setLoading(true);
    try {
      let { data: dt } = await getVehicleConfiscate(
        applicationData?.application_id
      );
      const { data } = dt;

      let docObj = {};
      const confiscatedDocs = data?.documents || [];
      if (data?.date) {
        setDate(dayjs(data?.date));
      }
      if (data?.comment) {
        setRemark(data?.comment);
      }
      for (const curr of confiscatedDocs) {
        let isVideo = curr?.name === "vehicle_confiscation_video";
        let base64 = "";

        if (isVideo) {
          try {
            base64 = await fetchVideo(curr);
          } catch (error) {
            console.error(`Error fetching video for ${curr?.name}:`, error);
          }
        }

        docObj[curr?.name] = { ...curr, ...(isVideo ? { base64 } : {}) };
      }
      setDocs(docObj);
      setVehicleData({ ...data, documents: docObj });
      setVehicleStatus(data?.vehicle_status ?? null);
      setGetVehicleStatus(data?.vehicle_status);
      setIsConfiscated(data?.vehicle_status === "CONFISCATED");
    } catch (error) {
      toast?.error(
        error?.response?.data?.message || error?.message || "Some error"
      );
      console.log(error);
    }
    setLoading(false);
  };

  function handleStatusChange(status) {
    if (getVehicleStatus === status) {
      setRemark(vehicleData?.comment);
      setDate(dayjs(vehicleData?.date));
      if (status === "CONFISCATED") setDocs(vehicleData?.documents);
    } else {
      setRemark("");
      setDate(null);
      setDocs([]);
    }
    setVehicleStatus(status);
  }

  const handleUpload = async () => {
    setLoading(true);
    try {
      let documents = [];
      Object.keys(docs)?.forEach((key) => {
        if (Object?.keys(docs[key])?.length > 0) {
          documents?.push({
            name: key,
            category: "vehicle_confiscated",
            file: docs?.[key]?.fileLink,
          });
        }
      });

      let payload = {
        application_id: applicationData?.application_id,
        vehicle_status: vehicleStatus,
        date: dayjs(date).valueOf(),
      };

      if (documents?.length !== 0 && !isConfiscated) {
        payload = {
          ...payload,
          document_included_g: true,
          documents,
        };
      }

      if (Boolean(remark)) {
        payload = {
          ...payload,
          comment: remark,
        };
      }

      let { data } = await addVehicleConfiscate(payload, setProgress);
      setProgress(null);
      toast?.success(data?.message);
      setGetVehicleStatus(vehicleStatus);
      setVehicleData(prev=>({...prev,date:dayjs(date).valueOf()}));
      // fetchAllComments(applicationData?.application_id);
    } catch (error) {
      toast?.error(
        error?.response?.data?.message || error?.message || "Some error"
      );
      console.log(error);
    }
    setLoading(false);
  };

  const handleSingleUpload = async (file, name) => {
    setLoading(true);
    try {
      let payload = {
        application_id: applicationData?.application_id,
        document_included_g: true,
        document: {
          name,
          category: "vehicle_confiscated",
          file,
        },
      };
      let { data } = await uploadSingleConfistDoc(payload, setProgress);
      await handleFetchData();
      setProgress(null);
      toast?.success(data?.message);
    } catch (error) {
      toast?.error(
        error?.response?.data?.message || error?.message || "Some error"
      );
      console.log(error);
    }
    setLoading(false);
  };

  const handleDelete = async (fileLink, name) => {
    setLoading(true);
    let payload = {
      applicationId: applicationData?.application_id,
      fieldName: "vehicle_confiscated_docs",
      fileLink,
      collection: "loanapplications",
    };

    // console.log(payload);
    try {
      let { data } = await deleteDocument(payload);
      setDocs({
        ...docs,
        [name]: {},
      });
    } catch (error) {
      toast?.error(
        error?.response?.data?.message || error?.message || "Some error"
      );
      console.log(error);
    }
    setLoading(false);
  };

  const handleDownload = async (doc, isDownload = true) => {
    let token = user?.token;
    if (!token) {
      let { data } = await verifyAuth();
      token = data?.token;
      setUser({
        ...data.data,
        token,
      });
    }

    try {
      let { key, dataType } = getDocKey(doc?.fileLink);
      let { data } = await axios.get(
        `https://sen319hxy8.execute-api.ap-south-1.amazonaws.com/dev/view?environment=development&signedUrl=true&key=${key}`,
        {
          method: "get",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            application: "EMBEDDED-TOOLS",
            token,
          },
        }
      );

      let { signedUrl } = data;

      const response = await axios.get(signedUrl, {
        responseType: "blob",
      });

      if (isDownload) {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const blobURL = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobURL;
        link.download = `${doc?.name}.${FileMimeType?.[dataType]}`;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = () => {
            const base64Data = reader.result.split(",")[1]; // Extract the base64 data portion
            resolve(`data:${dataType};base64,${base64Data}`);
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(blob);
        });
      }
    } catch (error) {
      console.error("Error fetching the file:", error);
      return "";
    }
  };

  // const handleDownloadAll = () => {
  //   console.log(docs);
  //   // Object;
  // };

  const download = async (item, zip, progressTxt) => {
    let token = user?.token;
    if (!token) {
      let { data } = await verifyAuth();
      token = data?.token;
      setUser({
        ...data.data,
        token,
      });
    }
    let key = item?.key ? item?.key : getDocKey(item?.fileLink)?.key;

    let { data } = await axios.get(
      `https://sen319hxy8.execute-api.ap-south-1.amazonaws.com/dev/view?environment=development&signedUrl=true&key=${key}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          application: "EMBEDDED-TOOLS",
          token,
        },
      }
    );

    let { signedUrl } = data;

    // console.log(key + ' - ' + signedUrl);

    try {
      const resp = await axios.get(signedUrl, {
        responseType: "blob",
      });

      zip.file(
        `${item?.name}.${FileMimeType[getDocKey(item?.fileLink)?.dataType]}`,
        resp.data
      );
    } catch (err) {
      console.log(err);
      toast.warn(`Error with file ${item?.name}, will be skipped`);
    }

    setProgress(progressTxt);
  };

  // const downloadAll = (documents, filename, multiData = false) => {
  //   setIsDownloadingAll(true);
  //   const arrOfFiles = documents?.map((item) => download(item, multiData)); //create array of promises
  //   Promise.all(arrOfFiles)
  //     .then(() => {
  //       zip.generateAsync({ type: "blob" }).then(function (blob) {
  //         saveAs(blob, `${filename}.zip`);
  //       });
  //       setIsDownloadingAll(false);
  //     })
  //     .catch((err) => {
  //       setIsDownloadingAll(false);
  //       console.log(err);
  //     });
  // };

  // const downloadAll = async (documents, filename, multiData = false) => {
  //   if (multiData) setMultiLength(documents?.length);
  //   setIsDownloadingAll(true);
  //   let promises = [];
  //   for (const item of documents) {
  //     if (item?.isApproved || multiData) {
  //       promises.push(download(item, multiData));
  //       if (promises.length == 5) {
  //         await Promise.allSettled(promises);
  //         promises = [];
  //       }
  //     }
  //   }

  //   await Promise.allSettled(promises);
  //   zip.generateAsync({ type: "blob" }).then(function (blob) {
  //     saveAs(blob, `${filename}.zip`);
  //   });
  //   if (multiData) setMultiLength(null);
  //   setDownloadProgress(0);
  //   setIsDownloadingAll(false);
  // };

  const handleDownloadAll = async () => {
    setDownloading(true);
    const zip = new JSZip();

    let promises = [];
    let docArray = [];

    docArray = Object.keys(docs || []).map((key) => {
      return docs[key];
    });

    let count = 1;
    for (const item of docArray) {
      promises.push(await download(item, zip, `${count}/${docArray?.length}`));
      if (promises.length == 5) {
        await Promise.allSettled(promises);
        promises = [];
      }
      count++;
    }

    await Promise.allSettled(promises);
    zip.generateAsync({ type: "blob" }).then(function (blob) {
      saveAs(
        blob,
        `vehicle_confisticated_docs-${applicationData?.application_id}.zip`
      );
    });
    // setDownloadProgress(0);
    // setIsDownloadingAll(false);
    setProgress(null);
    setDownloading(false);
  };

  function isSubmitDisabled() {
    if (vehicleStatus === "RETURNED_TO_CUSTOMER" && !Boolean(date)) {
      return true;
    }
    if (
      ["RESELL", "RENTED","REFINANCE"].includes(vehicleStatus) &&
      (!Boolean(date) || !Boolean(remark))
    ) {
      return true;
    }
    return false;
  }

  const handleOpenView = (file) => {
    setOpenView({
      open: true,
      current: file,
    });
  };

  function getSubmitBtn() {

    if(vehicleStatus !== "CONFISCATED"){
      return <DialogActions>
        <Button disabled={isSubmitDisabled()} onClick={handleUpload}>
          {vehicleStatus === getVehicleStatus ? "Update" : "Submit"}
        </Button>
      </DialogActions>
    }

    if (vehicleStatus !== "CONFISCATED") return <></>;

    if (
      dayjs(date).isSame(vehicleData?.date) &&
      isConfiscated
    ) {
      return (
        <DialogActions>
          <Button disabled={!enabledButton} onClick={handleDownloadAll}>
            Download All
          </Button>
        </DialogActions>
      );
    }else{
      return (
        <DialogActions>
          <Button
            disabled={!enabledButton || !Boolean(date)}
            onClick={handleUpload}
          >
            Upload
          </Button>
        </DialogActions>
      );
    }

  }

  useEffect(() => {
    let status = false;
    for (let file in docs) {
      if (Object?.keys(docs[file])?.length > 0) {
        setEnableButton(true);
        status = true;
        break;
      }
    }
    setEnableButton(status);
  }, [docs]);

  useEffect(() => {
    console.log(progress);
  }, [progress]);

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Vehicle Status
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="pt-2">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel required htmlFor="demo-simple-select-label" id="demo-simple-select-label">
                  Vehicle Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={vehicleStatus}
                  label="Vehicle Status"
                  required
                  onChange={(e) => {
                    const { value } = e.target;
                    handleStatusChange(value);
                  }}
                >
                  <MenuItem value={"CONFISCATED"}>Vehicle confiscated</MenuItem>
                  <MenuItem value={"RETURNED_TO_CUSTOMER"}>
                    Return to customer
                  </MenuItem>
                  <MenuItem value={"RESELL"}>Resell</MenuItem>
                  <MenuItem value={"RENTED"}>Running on rent</MenuItem>
                  <MenuItem value={"SOLD"}>Sold</MenuItem>
                  <MenuItem value={"REFINANCE"} >Re-Finance</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box>
                  <DemoItem>
                    <DatePicker
                      slotProps={
                        {
                          // textField: {
                          //   helperText: error.settlementDateMs,
                          // },
                          // field: {
                          //   clearable: true,
                          //   onClear: () => setCleared(true),
                          // },
                        }
                      }
                      label="date *"
                      format="DD/MM/YYYY"
                      disableFuture
                      value={date}
                      inputVariant="outlined"
                      onChange={(date) => {
                        setDate(date);
                      }}
                      animateYearScrolling
                    />
                  </DemoItem>
                </Box>
              </LocalizationProvider>
            </Grid>
            <Grid xs={12}></Grid>

            {!["CONFISCATED", "RETURNED_TO_CUSTOMER"].includes(
              vehicleStatus
            ) && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Remark"
                  required
                  multiline
                  rows={4}
                  value={remark}
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                />
              </Grid>
            )}
            {vehicleStatus === "CONFISCATED" && (
              <>
                <Grid item xs={12}>
                  <p className="confis-head">Images</p>
                </Grid>
                <Grid item xs={6}>
                  <DocumentUpload
                    title={"vehicle photo from front"}
                    doc={docs?.vehicle_photo_from_front}
                    isVehicleConfiscated={isConfiscated}
                    onChange={(file) => {
                      if (isConfiscated) {
                        handleSingleUpload(file, "vehicle_photo_from_front");
                        return false;
                      }
                      setDocs({
                        ...docs,
                        vehicle_photo_from_front: {
                          name: "vehicle_photo_from_front",
                          fileLink: file,
                          isUpload: true,
                        },
                      });
                    }}
                    handleDownload={handleDownload}
                    handleOpenView={handleOpenView}
                    onDelete={() => {
                      if (docs?.vehicle_photo_from_front?.isUpload) {
                        setDocs({
                          ...docs,
                          vehicle_photo_from_front: {},
                        });
                      } else {
                        handleDelete(
                          docs?.vehicle_photo_from_front?.fileLink,
                          "vehicle_photo_from_front"
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DocumentUpload
                    title={"Vehicle photo from back"}
                    doc={docs?.vehicle_photo_from_back}
                    isVehicleConfiscated={isConfiscated}
                    handleOpenView={handleOpenView}
                    handleDownload={handleDownload}
                    onChange={(file) => {
                      if (isConfiscated) {
                        handleSingleUpload(file, "vehicle_photo_from_back");
                        return false;
                      }
                      setDocs({
                        ...docs,
                        vehicle_photo_from_back: {
                          name: "vehicle_photo_from_back",
                          fileLink: file,
                          isUpload: true,
                        },
                      });
                    }}
                    onDelete={() => {
                      if (docs?.vehicle_photo_from_back?.isUpload) {
                        setDocs({
                          ...docs,
                          vehicle_photo_from_back: {},
                        });
                      } else {
                        handleDelete(
                          docs?.vehicle_photo_from_back?.fileLink,
                          "vehicle_photo_from_back"
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DocumentUpload
                    title={"Vehicle photo from inside"}
                    handleOpenView={handleOpenView}
                    handleDownload={handleDownload}
                    isVehicleConfiscated={isConfiscated}
                    doc={docs?.vehicle_photo_from_inside}
                    onChange={(file) => {
                      if (isConfiscated) {
                        handleSingleUpload(file, "vehicle_photo_from_inside");
                        return false;
                      }
                      setDocs({
                        ...docs,
                        vehicle_photo_from_inside: {
                          name: "vehicle_photo_from_inside",
                          fileLink: file,
                          isUpload: true,
                        },
                      });
                    }}
                    onDelete={() => {
                      if (docs?.vehicle_photo_from_inside?.isUpload) {
                        setDocs({
                          ...docs,
                          vehicle_photo_from_inside: {},
                        });
                      } else {
                        handleDelete(
                          docs?.vehicle_photo_from_inside?.fileLink,
                          "vehicle_photo_from_inside"
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DocumentUpload
                    title={"Vehicle photo from odometer"}
                    handleOpenView={handleOpenView}
                    handleDownload={handleDownload}
                    isVehicleConfiscated={isConfiscated}
                    doc={docs?.vehicle_photo_from_odometer}
                    onChange={(file) => {
                      if (isConfiscated) {
                        handleSingleUpload(file, "vehicle_photo_from_odometer");
                        return false;
                      }
                      setDocs({
                        ...docs,
                        vehicle_photo_from_odometer: {
                          name: "vehicle_photo_from_odometer",
                          fileLink: file,
                          isUpload: true,
                        },
                      });
                    }}
                    onDelete={() => {
                      if (docs?.vehicle_photo_from_odometer?.isUpload) {
                        setDocs({
                          ...docs,
                          vehicle_photo_from_odometer: {},
                        });
                      } else {
                        handleDelete(
                          docs?.vehicle_photo_from_odometer?.fileLink,
                          "vehicle_photo_from_odometer"
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p className="confis-head">Videos</p>
                </Grid>
                <Grid item xs={6}>
                  <DocumentUpload
                    style={{
                      border: "1px solid black",
                      height: "100px !important",
                    }}
                    handleOpenView={handleOpenView}
                    handleDownload={handleDownload}
                    isVehicleConfiscated={isConfiscated}
                    isVideo={true}
                    title={"Add Video"}
                    doc={docs?.vehicle_confiscation_video}
                    onChange={(file) => {
                      if (isConfiscated) {
                        handleSingleUpload(file, "vehicle_confiscation_video");
                        return false;
                      }
                      setDocs({
                        ...docs,
                        vehicle_confiscation_video: {
                          name: "vehicle_confiscation_video",
                          fileLink: file,
                          isUpload: true,
                        },
                      });
                    }}
                    onDelete={() => {
                      if (docs?.vehicle_confiscation_video?.isUpload) {
                        setDocs({
                          ...docs,
                          vehicle_confiscation_video: {},
                        });
                      } else {
                        handleDelete(
                          docs?.vehicle_confiscation_video?.fileLink,
                          "vehicle_confiscation_video"
                        );
                      }
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        {getSubmitBtn()}

      </BootstrapDialog>
      {openView?.open && (
        <ViewConsficDoc
          openView={openView}
          setOpenView={setOpenView}
          docs={docs}
          handleDownload={handleDownload}
        />
      )}
      {isLoading && (
        <LoadingOver text={progress !== null && `Progress ${progress}%`} />
      )}
      {isDownloading && (
        <LoadingOver text={progress !== null && `Downloading ${progress}`} />
      )}
    </>
  );
}
