import {
  Drawer,
  Grid,
  ListItem,
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from "@mui/material";
import React from "react";
import { UserContext } from "../Context/UserContext";
import { GlobalContext } from "../Context/GlobalContext";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import IosShareIcon from "@mui/icons-material/IosShare";
import ListIcon from "@mui/icons-material/List";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import PersonIcon from "@mui/icons-material/Person";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import RuleIcon from "@mui/icons-material/Rule";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import VideoChatIcon from "@mui/icons-material/VideoChat";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GPSInvoicePdf from "../Components/Dialogs/GPSInvoicePdf";
import GPSBulkUplaod from "../Components/QuickTools/GPSBulkUplaod";
import aadhaarOcrIco from "../assets/aadhaar.svg";
import gps from "../assets/gps.svg";
import InvoiceIco from "../assets/invoiceIco.svg";
import moneyReceivedIco from "../assets/money_receive.png";
import other_collections from "../assets/other_collections.svg";
import scheduleIco from "../assets/schedule.svg";
// import voice_clone_icon from "../assets/voice_clone_icon.svg";
import "./HomeScreen.css";
import ScheduleViewer from "./Scheduler/ScheduleViewer";
import { IoIosCall } from "react-icons/io";
import { MdOutlineMessage } from "react-icons/md";
import BulkCall from "../Components/BulkCall";
import { fetchAllTemplates } from "../api";
import {open, setOpen} from "./Tables/CollectionTableV2";
import moment from "moment/moment";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DragDrop from "../Components/DocumentUploader";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";
import uploadAnimation from ".././assets/cloud-upload.gif";
import { ProgressBar } from "react-bootstrap";
import { uploadAssignCsvV2 } from "../api";
import { uploadBulkMessage } from "../api";
import { toast } from "react-toastify";
import BulkMessaging from "../Components/Dialogs/BulkMessaging";

const styles = {
  moreBtn: {
    float: "right",
    cursor: "pointer",
    color: "gray",
    "&:hover": {
      color: "red",
    },
  },
};

const initialStateBulkResponse = {
  successArray: [],
  failedArray: [],
  successCount: 0,
  failedCount: 0,
  open: false,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuickTools = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [admin, setAdmin] = useState(false);
  const [role, setRole] = useState(false);
  const [embifiUser, setEmbifiUser] = useState(false);
  const [openGPSBulkUpload, setOpenGPSBulkUpload] = useState(false);
  const [openGPSInvoice, setOpenGPSInvoice] = useState(false);
  const [ isBulkCall, setIsBulkCall] = useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [templates, setTemplates] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const [assignCSV, setAssignCSV] = useState(null);
  const [progress, setProgress] = useState(0);
  const [bulkUploadResponse, setBulkUploadResponse] = useState(
    initialStateBulkResponse
  );
  const [bulkMessage, setBulkMessage] = useState({
    open: false,
  });

  const [upload, setUpload] = useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleBulkCallOpen = () => {
    setIsBulkCall(true);
  };

  const handleUploadExecutiveData = async () => {
    setUploading(true);
    try {
      let payload = {
        csv: assignCSV,
      };
      //

      let { data } = await uploadAssignCsvV2(payload, setProgress);

      let tmpSuccessArray = data?.successArr?.map((c) => ({
        index: c?.row_number,
        application_id: c?.message.split(" ").slice(-1)[0],
      }));
      let tmpFailedArr = data?.failedArr?.map((c) => ({
        ...c,
        errMessage: c?.message,
      }));

      setBulkUploadResponse({
        successArray: data?.successArr,
        failedArray: tmpFailedArr,
        successCount: data?.successArr.length,
        failedCount: data?.failedArr.length,
        failedCsv: data?.failedCsvBase64,
        open: true,
      });
      upload ? setUpload(false) : setUpload(true);
      setProgress(0);
      setOpen(false);
      setAssignCSV(null);
    } catch (error) {
      setProgress(0);
      setAssignCSV(null);
      toast.error(error?.response?.data?.message);
    }
    setUploading(false);
  };

  const handleSampleCsv = (sample_type) => {
    let headers =
      sample_type == "BulkUploadAssignee"
        ? [`application_id,email_id`]
        : [
            "Application ID,Customer Name,Contact number,EMI Amount,EMI Date,Overall Status,Dealer's name",
          ];

    downloadFile({
      data: [...headers, []].join("\n"),
      fileName: `Upload-sample-(${moment(new Date()).format("DD-MM-YYYY")})`,
      fileType: "text/csv",
    });
  };

  const fetchTemplates = async () => {
    const { data } = await fetchAllTemplates();
    setTemplates(data);
  };

  useEffect(() => {
    if (user?.role == 1 || user?.role == 2) {
      setEmbifiUser(true);
    } else {
      setEmbifiUser(false);
    }
  }, [user]);

  useEffect(() => {
    if (user.name === "Admin") {
      setAdmin(true);
    } else {
      setAdmin(false);
    }
  }, [user]);

  useEffect(() => {
    if (user.role === "1") {
      setRole(true);
    } else {
      setRole(false);
    }
  }, [user]);

  
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  

  return (
    <>
    {/* <style>{backgroundColor}</style> */}
    <div className="wrapper-center-home">
      <div className="icon-holder">
        <p className="home-heading"> Quick Tools</p>
        <Grid container spacing={2}>
          {/* {[1,5].includes(Number(user?.role)) && <Grid item xs={4}>
            <div
              className="home-card"
              onClick={() => {
                navigate("/create-app");
              }}
            >
              <div>
                <p className="text-center">
                  <img src={AddIco} className="button-ico" />
                </p>
                <p className="ico-tag">Create Application</p>
              </div>
            </div>
          </Grid>} */}
          <Grid item xs={4}>
            <div
              className="home-card"
              // style={{opacity:0.3}}
              onClick={() => {
                // navigate("/update-stage-v2");
                setOpenGPSBulkUpload(true);
              }}
            >
              <div>
                <p className="text-center">
                  <img src={gps} className="button-ico" />
                </p>
                <p className="ico-tag mb-0">GPS Bulk Upload</p>
              </div>
            </div>
          </Grid>

          {/* <Grid item xs={4}>
            <div
              className="home-card"
              onClick={() => {
                // window.open("https://www.parser.embifi.in/upload-docs");
                if (
                  environment === "development" ||
                  [1, 5].includes(Number(user?.role))
                ) {
                  navigate("/upload-documents");
                } else {
                  toast.error("Only admin can upload documents", {
                    style: {
                      width: "320px", // Set the desired width here
                    },
                  });
                }
              }}
            >
              <div>
                <p className="text-center">
                  <img src={documentIco} className="button-ico" />
                </p>
                <p className="ico-tag">Upload Documents</p>
              </div>
            </div>
          </Grid> */}

          <Grid item xs={4}>
            <div
              className="home-card"
              onClick={() => {
                navigate("/generate-schedule");
              }}
            >
              <div>
                <p className="text-center">
                  <img src={scheduleIco} className="button-ico" />
                </p>
                <p className="ico-tag">Generate Schedule</p>
              </div>
            </div>
          </Grid>

          <Grid item xs={4}>
            <div
              className="home-card"
              onClick={() => {
                navigate("/drf-sheet");
              }}
            >
              <div>
                <p className="text-center">
                  <img src={aadhaarOcrIco} className="button-ico" />
                </p>
                <p className="ico-tag">Generate DRF</p>
              </div>
            </div>
          </Grid>

          {[1,5].includes(Number(user?.role)) && (
            <Grid item xs={4}>
              <div
                className="home-card"
                onClick={() => {
                  navigate("/other-collections");
                }}
              >
                <div>
                  <p className="text-center">
                    <img src={other_collections} className="button-ico" />
                  </p>
                  <p className={"ico-tag"}>Other Collections</p>
                </div>
              </div>
            </Grid>
          )}

          {[1,5].includes(Number(user?.role)) && (
            <Grid item xs={4}>
              <div
                className="home-card"
                onClick={() => {
                  navigate("/cash-receipt-data");
                }}
              >
                <div>
                  <p className="text-center">
                    <img src={moneyReceivedIco} className="button-ico" />
                  </p>
                  <p className={"ico-tag text-center"}>
                    Money collected by Partner
                  </p>
                </div>
              </div>
            </Grid>
          )}


          <Grid item xs={4}>
            <div
              className="home-card"
              onClick={() => {
                setOpenGPSInvoice(true);
              }}
            >
              <div>
                <p className="text-center">
                  {/* <img src={parserIco} className="button-ico" /> */}
                  <img src={InvoiceIco} className="button-ico" />
                </p>
                <p className={"ico-tag text-center"}>Generate Invoices (GPS/DF)</p>
              </div>
            </div>
          </Grid>
          {Number(user?.role) !== 3 && (
            <Grid item xs={4}>
            <div
              className="home-card"
              onClick={() => {
                handleBulkCallOpen();
              }}
            >
              <div>
                <p className="text-center">
                  {/* <img src={parserIco} className="button-ico" /> */}
                  <MdOutlineMessage size={43}/>
                </p>
                <p className={"ico-tag text-center"}>Bulk Calling</p>
              </div>
            </div>
          </Grid>)}
          {Number(user?.role) !== 3 && (
           <Grid item xs={4}>
            <div
              className="home-card"
              onClick={() => {
                setBulkMessage({ open: true });
              }}
            >
              <div>
                <p className="text-center">
                  {/* <img src={parserIco} className="button-ico" /> */}
                  <IoIosCall size={43}/>
                </p>
                <p className={"ico-tag text-center"}>Bulk Message</p>
              </div>
            </div>
          </Grid>)}

          {Number(user?.role) === 1 && (
            <Grid item xs={12}>
              <div style={styles?.moreBtn} onClick={handleClickOpen}>
                <ListIcon /> more tools
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
    <ScheduleViewer />

    <>
      <Drawer anchor={"right"} open={open} onClose={handleClose}>
        <div
          style={{
            width: "500px",
          }}
        >
          <p className="more-tools-heading">More Tools</p>
          {false && (
            <div
              className="more-list-item"
              onClick={() => {
                navigate("/update-collection");
              }}
            >
              <LocalAtmIcon className="me-2" /> Collection Update
            </div>
          )}

          {Number(user?.role) === 1 && (
            <>
              <div
                className="more-list-item"
                onClick={() => {
                  window.open("https://lendermissingdatadb.flutterflow.app/");
                }}
              >
                <PlagiarismIcon className="me-2" /> Missing Data
              </div>
              <div
                className="more-list-item"
                onClick={() => {
                  navigate("/update-anchor");
                }}
              >
                <UpgradeIcon className="me-2" /> Update Anchor
              </div>

              <div
                className="more-list-item"
                onClick={() => {
                  navigate("/b2c-validations");
                }}
              >
                <RuleIcon className="me-2" /> B2C Validations
              </div>

              <div
                className="more-list-item"
                onClick={() => {
                  window.open("https://www.parser.embifi.in/parser");
                }}
              >
                <IntegrationInstructionsIcon className="me-2" /> Document
                Parser
              </div>
              <div
                className="more-list-item"
                onClick={() => {
                  navigate("/shared-dealer-data");
                }}
              >
                <IosShareIcon className="me-2" /> Share Dealers Data
              </div>

              <div
                className="more-list-item"
                onClick={() => {
                  navigate("/evproducts");
                }}
              >
                <IosShareIcon className="me-2" /> EV product
              </div>

              <div
                className="more-list-item"
                onClick={() => {
                  navigate("/kyc");
                }}
              >
                <VideoChatIcon className="me-2" /> KYC
              </div>
            </>
          )}
          {embifiUser && (
            <Accordion className="accord-user">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={{ color: "gray" }}>
                  <AccountCircleIcon className="me-2" /> Create New Accounts
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="column">
                  {user?.role == 1 && (
                    <ListItem
                      style={{ borderBottom: "1px solid lightgrey" }}
                      button
                      onClick={() => {
                        navigate("/create-user");
                      }}
                      key={1}
                    >
                      <Typography style={{ color: "grey" }}>
                        <AccountBoxIcon className="me-2" /> Create User
                      </Typography>
                    </ListItem>
                  )}
                  <ListItem
                    style={{ borderBottom: "1px solid lightgrey" }}
                    button
                    onClick={() => {
                      navigate("/create-agent");
                    }}
                    key={2}
                  >
                    <Typography style={{ color: "grey" }}>
                      <PersonIcon className="me-2" /> Create Agent
                    </Typography>
                  </ListItem>
                  <ListItem
                    button
                    style={{ borderBottom: "1px solid lightgrey" }}
                    onClick={() => {
                      navigate("/create-anchor-v2");
                    }}
                    key={3}
                  >
                    <Typography style={{ color: "grey" }}>
                      <AccountCircleIcon className="me-2" /> Create Anchor
                    </Typography>
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      navigate("/create-oem");
                    }}
                    key={4}
                  >
                    <Typography style={{ color: "grey" }}>
                      <AccountCircleIcon className="me-2" /> Create Oem
                    </Typography>
                  </ListItem>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      </Drawer>
    </>
    {/* <GPSBulkUplaod open={GPSPopup} handleClose={()=>{
      setGPSPopup(false);
    }} /> */}
    <GPSBulkUplaod
      open={openGPSBulkUpload}
      sampleHeader={[
        `anchor_id,gps_procurer,gps_lot,gps_unique_number,gps_sim_number,money_received`,
      ]}
      collectionName="GPS Data"
      handleClose={() => {
        setOpenGPSBulkUpload(false);
      }}
    />

    {openGPSInvoice && (
      <GPSInvoicePdf
        open={openGPSInvoice}
        handleClose={() => {
          setOpenGPSInvoice(false);
        }}
      />
    )}
    <BulkCall
      open={isBulkCall}
      close={() => setIsBulkCall(false)}
      templates={templates}
      Transition={Transition}
      downloadFile={downloadFile}
    />

  
    {bulkMessage?.open && (
        <BulkMessaging
          open={true}
          handleClose={() => {
            setBulkMessage({ open: false });
          }}
        />
      )}
  </>
  );
};


export default QuickTools;
