import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { toast } from "react-toastify";
import CreditCheckerBox, {
  SmallAvatar,
} from "../../Components/PendancyTracker/CreditCheckerBox";
import KanbanBoard from "../../Components/KanbanBoard/KanbanBoard";
import useSocket from "../../hooks/useSocket";
import { getUserByDepartment } from "../../api";
import { useContext } from "react";
import { OptionsPopper } from "../../Components/Popover";
import { capitalize } from "../../utility/Formater";
import { arrayMove } from "@dnd-kit/sortable";
import { UserContext } from "../../Context/UserContext";
import ApplicationDetailsDialog from "../../Components/PendancyTracker/ApplicationDetailsDialog";
import SearchIcon from "@mui/icons-material/Search";
import { Close } from "@mui/icons-material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initQuickFilters = {
  file_type: "all",
  sales_agent: "all",
  credit_checker: "all",
};

function PendencyTracker() {
  const [quickFilter, setQuickFilter] = useState(initQuickFilters);
  const [usersList, setUsersList] = useState({
    CREDIT: [],
    SALES: [],
  });
  const [boardData, setBoardData] = useState({});
  const [boardArray, setBoardArray] = useState([]);
  const [openOptions, setOpenOptions] = useState(null);
  const [optionsEl, setOptionsEl] = useState(null);
  const [openApplicationDetails, setOpenApplicationDetails] = useState(null);
  const [totalCounts, setTotalCounts] = useState({
    sales_agent: 0,
    credit_checker: 0,
    ready_to_credit_check: 0,
    ready_for_disbursal: 0,
  });
  const [page, setPage] = useState({
    sales_agent: 1,
    credit_checker: 1,
    ready_to_credit_check: 1,
    ready_for_disbursal: 1,
  });
  const [search, setSearch] = useState({
    sales_agent: "",
    credit_checker: "",
    ready_to_credit_check: "",
    ready_for_disbursal: "",
  });
  const { user } = useContext(UserContext);

  const socket = useSocket();

  const handleToggleFilter = (_, newAlignment) => {
    setQuickFilter((prev) => ({ ...prev, file_type: newAlignment || "all" }));
  };

  function handleBusinessExeChange(_, child) {
    setQuickFilter((prev) => ({
      ...prev,
      sales_agent: child?.props?.value || "all",
    }));
  }

  function handleCreditCkrChange(_, child) {
    setQuickFilter((prev) => ({
      ...prev,
      credit_checker: child?.props?.value || "all",
    }));
  }

  let columns = [
    {
      label: ()=>
        <SearchBar
          label={`Login Files (${totalCounts?.sales_agent ?? 0})`}
          handleSearch={(text) => {
            involkEvent("sales_agent", page?.["sales_agent"], text);
          }}
        />
      ,
      columnName: "sales_agent",
      render: (boxData) => {
        return (
          <CreditCheckerBox
            value={"sales_agent"}
            boxData={boxData}
            handleOpenOptions={(event) => {
              setOpenOptions(boxData);
              setOptionsEl(event?.currentTarget);
            }}
            businessExeNameVisible={true}
            onClick={(boxData) => {
              setOpenApplicationDetails(boxData);
            }}
          />
        );
      },
      filterFun: (boxData) => {
        return boxData?.stage === "sales_agent";
      },
      nextPage: (clmData) => involkEvent(clmData?.columnName, page?.sales_agent+1),
      totalCount:totalCounts?.sales_agent ?? 0,
      getId: (boxData) => boxData?.application_id,
    },
    {
      label:()=> (
        <SearchBar
          label={`Ready To Credit Check (${
            totalCounts?.ready_to_credit_check ?? 0
          })`}
          handleSearch={(text)=>{
            involkEvent("ready_to_credit_check",page?.["ready_to_credit_check"],text);
          }}
        />
      ),
      columnName: "ready_to_credit_check",
      render: (boxData) => {
        return (
          <CreditCheckerBox
            value={"ready_to_credit_check"}
            boxData={boxData}
            handleOpenOptions={(event) => {
              setOpenOptions(boxData);
              setOptionsEl(event?.currentTarget);
            }}
            businessExeNameVisible={true}
            onClick={(boxData) => {
              setOpenApplicationDetails(boxData);
            }}
          />
        );
      },
      filterFun: (boxData) => boxData?.stage === "ready_to_credit_check",
      nextPage: (clmData) => involkEvent(clmData?.columnName, page?.ready_to_credit_check+1),
      totalCount:totalCounts?.ready_to_credit_check ?? 0,
      getId: (boxData) => boxData?.application_id,
      // isDisabled:!user?.departments?.includes("SALES")
    },
    {
      label:()=> (
        <SearchBar
          label={`Credit checker (${totalCounts?.credit_checker ?? 0})`}
          handleSearch={(text)=>{
            involkEvent("credit_checker",page?.["credit_checker"],text);
          }}
        />
      ),
      columnName: "credit_checker",
      render: (boxData) => {
        return (
          <CreditCheckerBox
            value={"credit_checker"}
            boxData={boxData}
            handleOpenOptions={(event) => {
              setOpenOptions(boxData);
              setOptionsEl(event?.currentTarget);
            }}
            businessExeNameVisible={true}
            onClick={(boxData) => {
              setOpenApplicationDetails(boxData);
            }}
          />
        );
      },
      filterFun: (boxData) => boxData?.stage === "credit_checker",
      nextPage: (clmData) => involkEvent(clmData?.columnName, page?.credit_checker+1),
      totalCount:totalCounts?.credit_checker ?? 0,
      getId: (boxData) => boxData?.application_id,
      // isDisabled:!user?.departments?.includes("CREDIT_CHECK")
    },
    {
      label:()=> (
        <SearchBar
          label={`RFD (${totalCounts?.ready_for_disbursal ?? 0})`} 
          handleSearch={(text)=>{
            involkEvent("ready_for_disbursal",page?.["ready_for_disbursal"],text);
          }}
        />
      ),
      columnName: "ready_for_disbursal",
      render: (boxData) => {
        return (
          <CreditCheckerBox
            value={"ready_for_disbursal"}
            boxData={boxData}
            handleOpenOptions={(event) => {
              setOpenOptions(boxData);
              setOptionsEl(event?.currentTarget);
            }}
            businessExeNameVisible={false}
            onClick={(boxData) => {
              setOpenApplicationDetails(boxData);
            }}
          />
        );
      },
      filterFun: (boxData) => boxData?.stage === "ready_for_disbursal",
      nextPage: (clmData) => involkEvent(clmData?.columnName, page?.ready_for_disbursal+1),
      totalCount:totalCounts?.ready_for_disbursal ?? 0,
      // getId: (boxData) => boxData?.application_id,
      getId: (boxData) => boxData?._id,
      // isDisabled:!user?.departments?.includes("CREDIT_CHECK")
    },
  ];

  async function fetchUserAgents() {
    try {
      let { data } = await getUserByDepartment(
        JSON.stringify(["CREDIT_CHECK", "SALES"])
      );
      const users = data?.users || [];
      const salesUsers = users
        ?.filter((user) => user.departments.includes("SALES"))
        ?.map((user) => ({ name: user?.name, value: user?.email }));
      const creditCheckUsers = users
        .filter((user) => user.departments.includes("CREDIT_CHECK"))
        ?.map((user) => ({ name: user?.name, email: user?.email }));
      const usrLst = {
        SALES: salesUsers,
        CREDIT: creditCheckUsers,
      };
      setUsersList(usrLst);
    } catch (error) {
      toast?.error(error?.response?.data?.message);
    } finally {
    }
  }

  function involkAllEvents() {
    involkEvent("sales_agent");
    involkEvent("ready_to_credit_check");
    involkEvent("credit_checker");
    involkEvent("ready_for_disbursal");
  }

  function involkEvent(stage, pg, srch) {
    if (socket) {
      const filter = {
        stage,
        ...quickFilter,
        page: pg || page?.[stage] || 1,
        search: srch || search?.[stage] || "",
        limit: 10,
      };
      socket.emit("pendancy_track_filter", filter);
    }
    if (pg){
      setPage((prev) => ({ ...prev, [stage]: pg }));
    }
    if (srch) setSearch(srch);
  }

  function handleEvents() {
    if (socket) {
      socket.on("pendancy_track_filter", (data) => {
        setBoardData((prev) => ({ ...prev, [data?.stage]: data?.data }));
        setTotalCounts((prev) => ({
          ...prev,
          [data?.stage]: data?.totalCount?.[0]?.count ?? 0,
        }));
      });
      socket.on("assign_credit_checker", handleAssignCreditChecker);
      socket.on("update_add_stage", handleUpdateAddStage);
    }
  }

  function handleDragOver(event) {
    const { active, over } = event;
    // if (!over) return;

    const activeTaskData = active?.data?.current?.taskData;
    const overTaskData = over?.data?.current?.taskData;

    if (
      !activeTaskData ||
      (!over?.data?.current?.columnData && !over?.data?.current?.columnName)
    )
      return;

    if (!activeTaskData && !overTaskData) return;

    setBoardArray((prev) => {
      const activeTaskIdx = prev?.findIndex(
        (itm) => itm._id === activeTaskData._id
      );
      let overTaskIdx = 0;
      if (!overTaskData) {
        overTaskIdx = boardData.length;
        prev[activeTaskIdx].stage = over?.data?.current?.columnData?.columnName;
      } else {
        overTaskIdx = prev.findIndex((itm) => itm._id === overTaskData._id);
        prev[activeTaskIdx].stage = prev[overTaskIdx].stage;
      }
      return arrayMove(prev, activeTaskIdx, overTaskIdx);
    });
  }

  function handleBoxSwap(activeBoxData, overBoxData) {
    // box swap logic
    try {
      let applicationData = activeBoxData?.taskData;
      if (
        !applicationData ||
        activeBoxData?.columnName === overBoxData?.columnName ||
        !overBoxData?.columnName
      ) {
        const overBoxIdx = boardArray.findIndex(
          (itm) => itm?.application_id === applicationData?.application_id
        );
        return arrayMove(
          boardArray,
          activeBoxData?.idx,
          overBoxIdx || activeBoxData?.idx
        );
      }

      let stage_name = overBoxData?.columnName;

      const stage_value = {
        ready_to_credit_check: true,
        ready_for_disbursal: true,
        sales_agent: {
          email:
            applicationData?.additional_stages?.sales_agent?.[0]?.email ??
            user?.email,
        },
        credit_checker: {
          email:
            applicationData?.additional_stages?.credit_checker?.[0]?.email ??
            user?.email,
        },
      };

      let updateData = {
        application_id: applicationData?.application_id,
        [stage_name]: stage_value[stage_name],
      };

      applicationData["moved_stage"] = stage_name;

      socket.emit("update_add_stage", {
        applicationData,
        updateData,
      });
    } catch (error) {
      toast.error(error?.message);
    } finally {
    }
  }

  function handleAssignCC(boxData, item) {
    //emit
    socket.emit("assign_credit_checker", {
      application_id: boxData?.application_id,
      userData: { email: item?.value },
    });
  }

  function handleAssignCreditChecker(data) {
    //on
    const {
      data: {
        application_id,
        userData: { email },
      },
      additional_stages,
    } = data; // getting values

    let appData = null;

    setBoardData((prev) => {
      if (appData?.stage) {
        if (Array.isArray(prev?.[appData?.stage])) {
          let idx = prev[appData.stage].findIndex(
            (item) => item.application_id === application_id
          );
          if (idx !== -1) prev[appData.stage][idx] = appData;
        }
      }
      return { ...prev };
    });
  }

  function handleUpdateAddStage(data) {
    const { stage, moved_stage } = data;
    setBoardData(prev=>{
      Object.keys(prev || {}).map(key=>{
        prev[key] = prev[key]?.filter(app=>data?.application_id!==app?.application_id);
      });
      return {...prev};
    })
    let isSingle =
      stage && !moved_stage
        ? stage
        : !stage && moved_stage
        ? moved_stage
        : stage === moved_stage
        ? stage
        : false;
    if (isSingle) {
      isSingle = stage;
      involkEvent(isSingle);
    } else {
      involkEvent(stage);
      involkEvent(moved_stage);
    }

    if (
      openApplicationDetails &&
      openApplicationDetails?.application_id === data?.application_id
    ) {
      setOpenApplicationDetails({
        ...openApplicationDetails,
        ...data,
        stage: moved_stage,
      });
    }
  }

  useEffect(() => {
    let arr = Object.values(boardData).reduce(
      (acc, cur) => [...acc, ...cur],
      []
    );
    setBoardArray(arr);
  }, [boardData]);

  useEffect(() => {
    involkAllEvents();
  }, [quickFilter]);

  useEffect(() => {
    handleEvents();
    involkAllEvents();
  }, [socket]);

  useEffect(() => {
    fetchUserAgents();
  }, []);

  return (
    <div className="w-100">
      {/* <Button onClick={fetchUserAgents}>getusers</Button> */}
      <Grid container rowSpacing={2}>
        <Grid item xs={12} sm={12} m={3} my={1} width={"100%"}>
          <div className="d-flex flex-row align-items-center">
            <ToggleButtonGroup
              size="small"
              color="primary"
              K
              className="me-5"
              value={quickFilter?.file_type}
              exclusive
              onChange={handleToggleFilter}
              aria-label="Platform"
            >
              <ToggleButton value="all">All</ToggleButton>
              <ToggleButton value="new">New</ToggleButton>
              <ToggleButton value="old">Old</ToggleButton>
            </ToggleButtonGroup>
            <div className="d-flex flex-row align-items-center me-5">
              <FormControl size="small" sx={{ m: 1, width: 250 }}>
                <InputLabel
                  color="info"
                  aria-label="business-executive-select"
                  id="business-executive-select"
                >
                  Business Executive
                </InputLabel>
                <Select
                  labelId="business-executive-select"
                  label="Business Executive"
                  id="business-executive-select"
                  size="small"
                  value={quickFilter?.sales_agent || ""}
                  onChange={handleBusinessExeChange}
                  input={
                    <OutlinedInput color="info" label="Business Executive" />
                  }
                  // renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {usersList?.SALES?.length !== 0 && (
                    <MenuItem key={12323} value={"all"}>
                      All
                    </MenuItem>
                  )}
                  {usersList?.SALES?.map((user) => (
                    <MenuItem key={user?.value} value={user?.value}>
                      {user?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="d-flex flex-row align-items-center me-5">
              <FormControl color="info" size="small" sx={{ m: 1, width: 250 }}>
                <InputLabel id="credit-checker-select">
                  Credit Checker
                </InputLabel>
                <Select
                  labelId="credit-checker-select"
                  label="Credit Checker"
                  id="credit-checker-select"
                  size="small"
                  value={quickFilter?.credit_checker || ""}
                  onChange={handleCreditCkrChange}
                  input={<OutlinedInput label="Credit Checker" />}
                  // renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {usersList?.SALES?.length !== 0 && (
                    <MenuItem key={12323} value={"all"}>
                      All
                    </MenuItem>
                  )}
                  {usersList?.CREDIT?.map((user) => {
                    return (
                      <MenuItem key={user?.email} value={user?.email}>
                        {user?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </Grid>
        <Grid
          xs={12}
          sm={12}
          height={"88.5vh"}
          width={"100%"}
          boxSizing={"border-box"}
          paddingX={3}
          marginTop={2}
        >
          {/* <TaskBoard boardBodyHeight="80vh" columns={columns} /> */}
          <KanbanBoard
            columnHieght="80vh"
            columnsData={columns}
            boardData={boardArray}
            setBoardData={setBoardArray}
            handleDragOver={handleDragOver}
            handleDragEndFun={handleBoxSwap}
          />
        </Grid>
      </Grid>
      <OptionsPopper
        handleClose={() => {
          setOpenOptions(null);
          setOptionsEl(null);
        }}
        options={usersList?.CREDIT?.map((user) => {
          const avatar_name_arr = user?.name?.split(" ");
          const avatar_name =
            avatar_name_arr?.[0].charAt(0)?.toUpperCase() +
            (avatar_name_arr?.[1]?.charAt(0)?.toUpperCase() ?? "");

          const name = (
            <span className="d-flex flex-row align-items-center">
              <SmallAvatar sx={{ width: 30, height: 30 }}>
                {avatar_name}
              </SmallAvatar>
              <span className="ms-2">{capitalize(user?.name)}</span>
            </span>
          );
          return {
            name,
            value: user?.email,
          };
        })}
        open={Boolean(openOptions)}
        anchorEl={optionsEl}
        handleSelect={(item) => {
          handleAssignCC(openOptions, item);
        }}
      />
      <ApplicationDetailsDialog
        open={Boolean(openApplicationDetails)}
        handleClose={() => {
          setOpenApplicationDetails(null);
        }}
        applicationDetails={openApplicationDetails}
        usersList={usersList}
      />
    </div>
  );
}

const SearchBar = ({ handleSearch, label = "" }) => {
  const [isInputVisible, setInputVisible] = useState(false);
  const [text, setText] = useState("");

  const toggleInputVisibility = () => {
    if (isInputVisible) {
      handleSearch("");
      setText("");
    }
    setInputVisible(!isInputVisible);
  };

  return (
    <div
      className={`d-flex flex-row align-items-center justify-content-between p-2 w-100`}
      style={{
        height: 50,
      }}
    >
      {isInputVisible ? (
        <TextField
          placeholder={"search in " + label?.substring(0, label?.indexOf("("))}
          size="small"
          className="bg-white"
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch(text);
            }
          }}
        />
      ) : (
        <h6 className="mx-2 fw-semibold">{label}</h6>
      )}
      <IconButton
        className="icon-btn"
        size="small"
        onClick={toggleInputVisibility}
      >
        {isInputVisible ? <Close /> : <SearchIcon />}
      </IconButton>
    </div>
  );
};

export default PendencyTracker;
