import { Button, Grid, Menu, MenuItem } from "@mui/material";
import React from "react";
import CustomTableV2 from "../CustomTable/CustomTableV2";
import { useEffect } from "react";
import MovementGraph from "./MovementGraph";
import LoadingDiv from "./LoadingDiv";
import TuneIcon from "@mui/icons-material/Tune";
import DoneIcon from "@mui/icons-material/Done";

const MovementTableGraph = ({
  tableData,
  columns,
  graphData,
  isloading,
  header,
  onFilterChange,
  currentFilter,
}) => {
  const HeaderComp = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (val) => {
      setAnchorEl(null);
      if (onFilterChange && val) {
        onFilterChange(val);
      }
    };

    const checkActive = (val) => {
      return val === currentFilter ? (
        <div>
          <DoneIcon />
        </div>
      ) : null;
    };

    return (
      <div className="movement-table-header">
        {header}
        <div className="movement-filter-btn">
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            color="inherit"
            onClick={handleClick}
          >
            <TuneIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => handleClose("VEHICLE_MAX")}>
              VEHICLE MAX {checkActive("VEHICLE_MAX")}
            </MenuItem>
            <MenuItem onClick={() => handleClose("VEHICLE_MIN")}>
              VEHICLE MIN {checkActive("VEHICLE_MIN")}
            </MenuItem>
            <MenuItem onClick={() => handleClose("AVERAGE_KILOMETER_MAX")}>
              AVERAGE KILOMETER MAX {checkActive("AVERAGE_KILOMETER_MAX")}
            </MenuItem>
            <MenuItem onClick={() => handleClose("AVERAGE_KILOMETER_MIN")}>
              AVERAGE KILOMETER MIN {checkActive("AVERAGE_KILOMETER_MIN")}
            </MenuItem>
          </Menu>
        </div>
      </div>
    );
  };
  return (
    <>
      <Grid item xs={5}>
        {isloading ? (
          <LoadingDiv />
        ) : (
          <CustomTableV2
            tableData={tableData || []}
            columns={columns}
            tableHeight={"100%"}
            limit={0}
            page={1}
            //   isLoading={loadingTrend}
            maxHeight={440}
            borderBottom
            headerComp={<HeaderComp />}
          />
        )}
      </Grid>
      <Grid item xs={7}>
        {isloading ? (
          <LoadingDiv />
        ) : (
          <MovementGraph
            graphData={graphData}
            heading={`${header} Avg Km Per day`}
          />
        )}
      </Grid>
    </>
  );
};

export default MovementTableGraph;
