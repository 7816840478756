import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Box,
  Grid,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useState } from "react";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { Table } from "react-bootstrap";
import { useEffect } from "react";
import noDataIco from "../../assets/nodata.gif";
import GetAppIcon from "@mui/icons-material/GetApp";
import "./style.css";

const DPDCollectionDialog = ({ handleClose, open, collectionData }) => {
  const [isLoading, setLoading] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    height: "632px",
    bgcolor: "background.paper",
    borderRadius: "0.5rem",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  function downloadCSV(csv, filename) {
    const csvFile = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(csvFile);
    link.download = filename;
    link.click();
  }

  const handleDownload = () => {
    try {
      const header = Object.keys(collectionData?.[0]).join(",");
      const rows = collectionData.map((obj) => Object.values(obj).join(","));
      let csvData = `${header}\n${rows.join("\n")}`;
      const csvFile = new Blob([csvData], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(csvFile);
      link.download = "dpd_collections.csv";
      link.click();
    } catch (error) {
      toast.error("Error while exporting");
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <Grid
          container
          style={{ background: "#EFF5FF" }}
          sx={{ padding: 1, paddingBottom: 3 }}
        >
          <Grid xs={6} className="p-3">
            <p
              style={{
                fontSize: 20,
              }}
            >
              Movement
            </p>
          </Grid>
          <Grid xs={6}>
            <div className="d-flex justify-content-end p-3 pe-5">
              <Button
                variant="outlined"
                sx={{ mr: 1, fontSize: 12, borderRadius: 10 }}
                onClick={() => {
                  handleDownload();
                }}
              >
                <GetAppIcon sx={{ fontSize: 18, mr: 0.5 }} /> Export
              </Button>
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            overflow: "auto",
            height: "100%",
            padding: 10,
            paddingTop: 0,
            marginTop: -35,
          }}
        >
          <div
            style={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {false ? (
            <CircularProgress
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                translate: "-50%",
                // color: (theme) => theme.palette.grey[500],
              }}
            />
          ) : (
            <>
              {false ? (
                <div className="content-center w-100">
                  <img src={noDataIco} width={300} />
                </div>
              ) : (
                <Table
                  className="contacts-table"
                  width={"100%"}
                  style={{ fontSize: 13, marginBottom: 0 }}
                >
                  <thead>
                    <tr style={{ background: "#EFF5FF" }}>
                      <th style={{ width: "30px" }}>SL</th>
                      <th style={{ width: "200px" }}>Application Id:</th>
                      <th style={{ width: "230px" }}>Name</th>
                      <th style={{ width: "100px" }}>NBFC</th>
                      <th>OLD DPD</th>
                      <th>NEW DPD</th>
                      <th>MOD</th>
                    </tr>
                  </thead>
                  {(collectionData || []).map((collection, index) => {
                    return (
                      <tbody>
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            <b>{collection?.application_id}</b>
                          </td>
                          <td>{collection?.customer_name}</td>
                          <td>
                            {(collection?.nbfc_name || "")?.split(" ")?.[0]}
                          </td>
                          <td>{collection?.old_dpd}</td>
                          <td>{collection?.new_dpd}</td>
                          <td>{(collection?.mod || 0)?.toFixed(2)}</td>
                        </tr>
                      </tbody>
                    );
                  })}
                </Table>
              )}
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default DPDCollectionDialog;
