import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useContext } from "react";
import UploadSection from "../../../Components/Anchor/UploadSection";
import { GlobalContext } from "../../../Context/GlobalContext";
import { useEffect } from "react";
import { fetchAllOems } from "../../../api";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { IndiaStateCity, stateIso } from "../../../Constants/IndianStateCity";
import { City, State } from "country-state-city";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import useAnchor from "../../../hooks/useAnchor";

const ContactDetails = ({ isDisabled = false, isDownloadAble }) => {
  const { dealerDetailState, dealerShopImageState } = useContext(GlobalContext);
  const [dealerDetails, setDealerDetails] = dealerDetailState;
  const [dealerShopImages,setDealerShopImages] = dealerShopImageState;
  const { getDocArr, handleSingleShopImage } = useAnchor();

  const handleChange = (key) => (event) => {
    setDealerDetails({
      ...dealerDetails,
      [key]: event.target.value,
    });
  };

  const handleArrayDetailChange = (array_name, i, key, value) => {
    let array = [...dealerDetails?.[array_name]];
    array[i] = {
      ...array[i],
      [key]: value,
    };
    setDealerDetails({
      ...dealerDetails,
      [array_name]: array,
    });
  };

  const getDocsArray = () => getDocArr(2)

  function getDocNumber(docs, isAadhaar) {
    if (docs?.length === 0) return 1;
    let index = -1;
    let number = 0;
    if (isAadhaar) {
      index =
        docs?.[docs?.length - 1]?.[`aadhaar_front`]?.name?.lastIndexOf("_");
      number = docs?.[docs?.length - 1]?.[`aadhaar_front`]?.name?.slice(
        index + 1
      );
    } else {
      index = docs?.[docs?.length - 1]?.name?.lastIndexOf("_");
      number = docs?.[docs?.length - 1]?.name?.slice(index + 1);
    }
    return Number(number) + 1;
  }

  useEffect(() => {
    if (
      !dealerDetails?.outlet_details ||
      dealerDetails?.outlet_details?.length === 0
    ) {
      setDealerDetails({
        ...dealerDetails,
        outlet_details: [
          {
            relation_manager_details: [
              {
                name: "",
                mobile: "",
                email: "",
              },
            ],
          },
        ],
      });
    }
  }, [dealerDetails?.outlet_details]);

  return (
    <>
      <Grid container spacing={1}>
        {/* { ! isDownloadAble && <p
          className="mb-0 mt-3"
          style={{ color: "gray", fontSize: 20, background: "#48484" }}
        >
          Dealer Outlet Address
        </p>} */}
        {dealerDetails?.outlet_details?.map((address, i) => {
          return (
            <div
              style={{
                backgroundColor: "#F7F7F7",
                padding: 15,
                paddingBottom: 40,
                width: "100%",
                marginTop: 20,
                position: "relative",
              }}
            >
              {i !== 0 && !isDisabled && (
                <Button
                  className="remove-oem-btn"
                  sx={{ zIndex: 1500 }}
                  onClick={(e) => {
                    let state = { ...dealerDetails };
                    state.outlet_details = state.outlet_details.filter(
                      (item, index) => index !== i
                    );

                    const filtered_arr = state?.showroom_address_images?.[i] || [];

                    state.showroom_address_images = state?.showroom_address_images?.filter((doc,idx) => idx!==i)
                    setDealerDetails(state);
                    setDealerShopImages((prev)=>{
                      for(let doc of filtered_arr){
                        if(doc?.key){
                          prev?.deleteDocs?.push(doc?.name);
                        }else{
                          prev.documents = prev?.documents?.filter((file)=>file?.fieldname!==doc?.name);
                        }
                      }
                      return { ...prev }
                    });
                  }}
                >
                  <CloseIcon sx={{ fontSize: 17, mr: 0.5 }} /> Remove
                </Button>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    //   required
                    className={i !== 0 ? "mt-3" : ""}
                    id="address"
                    name="address"
                    label="Address"
                    autoComplete="family-name"
                    variant="standard"
                    fullWidth
                    value={
                      address?.address
                        ? address?.address
                        : isDisabled
                        ? " "
                        : ""
                    }
                    onChange={(e) => {
                      handleArrayDetailChange(
                        "outlet_details",
                        i,
                        "address",
                        e.target.value
                      );
                    }}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    //   required
                    id="pincode"
                    name="pincode"
                    label="Pincode"
                    autoComplete="family-name"
                    variant="standard"
                    fullWidth
                    value={address?.pincode || ""}
                    onChange={(e) => {
                      if (
                        e.target.value !== "" &&
                        !/^\d+$/.test(e.target.value)
                      ) {
                        return false;
                      }
                      if (e.target.value.length > 6) return false;
                      handleArrayDetailChange(
                        "outlet_details",
                        i,
                        "pincode",
                        e.target.value !== "" ? Number(e.target.value) : ""
                      );
                    }}
                    disabled={isDisabled}
                  />
                </Grid>

                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    renderInput={(params) => (
                      <TextField {...params} label="Country" />
                    )}
                    options={["India"]}
                    value={address?.country}
                    onChange={(e, value) => {
                      handleArrayDetailChange(
                        "outlet_details",
                        i,
                        "country",
                        value
                      );
                    }}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={State?.getStatesOfCountry("IN")?.map(
                      (val) => val?.name
                    )}
                    value={address?.state}
                    onChange={(e, value) => {
                      // console.log(value);
                      handleArrayDetailChange(
                        "outlet_details",
                        i,
                        "state",
                        value
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="State" />
                    )}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={(
                      City.getCitiesOfState("IN", stateIso?.[address?.state]) ||
                      []
                    ).map((val) => val?.name)}
                    value={address?.city}
                    onChange={(e, value) => {
                      handleArrayDetailChange(
                        "outlet_details",
                        i,
                        "city",
                        value
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="City" />
                    )}
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} >
                  <UploadSection
                    mt={10}
                    heading={"Shop image"}
                    category={"showroom_address_docs"}
                    docs={dealerDetails?.showroom_address_images?.[i] || []}
                    multiFileInput={true}
                    onChange={(files,base64,dataType) => {
                      const filename = `showroom_address_docs_${i}_${getDocNumber(dealerDetails?.showroom_address_images?.[i] || [])}`;
                      if(!dealerDetails?.showroom_address_images?.[i]){ 
                        dealerDetails.showroom_address_images = dealerDetails?.showroom_address_images ?? [];
                        dealerDetails.showroom_address_images[i] = [];
                      }
                      
                      dealerDetails.showroom_address_images[i].push({
                        isUploaded: true,
                        fileLink: base64,
                        name: filename,
                        dataType,
                      });

                      setDealerDetails({...dealerDetails});
                      let file = files.item(0);
                      file.fieldname = filename;
                      setDealerShopImages((prev)=>({...prev,documents:[ ...prev.documents, file]}));
                    }}
                    onDelete={(file, idx) => {
                      dealerDetails.showroom_address_images[i] = dealerDetails?.showroom_address_images?.[i]?.filter(doc => doc?.name !==file?.name)
                      setDealerDetails({...dealerDetails});

                      setDealerShopImages((prev)=>{
                        if(file?.key){
                          prev?.deleteDocs?.push(file?.name);
                        }else{
                          prev.documents = prev?.documents?.filter((doc)=>doc?.fieldname!==file?.name);
                        }
                        return { ...prev }
                      });
                    }}
                    isEditable={!isDisabled}
                    isDownloadAble={isDownloadAble}
                    anchor_id={dealerDetails?.anchor_id}
                    // swipeArr={swipeDocs}
                    getSwipeArr={getDocsArray}
                    handleSingleDocReupload={(base64, dataType,currDoc,closePopUps,setLoading,files)=>{
                      let file = files.item(0);
                      file.fieldname = currDoc?.name;
                      handleSingleShopImage(file,null,setLoading,closePopUps);
                    }}
                    handleSingleDocDelete={(currDoc,closePopUps,setLoading)=>{
                      handleSingleShopImage(null,currDoc?.name,setLoading,closePopUps);
                    }}
                  />
                </Grid>
              </Grid>
              {address?.relation_manager_details?.map((rm, j) => {
                return (
                  <Grid container spacing={2} className="mt-3">
                    <Grid item xs={3}>
                      <TextField
                        //   required
                        id="rm_name"
                        name="rm_name"
                        label="RM Name"
                        autoComplete="family-name"
                        variant="standard"
                        fullWidth
                        value={rm?.name}
                        onChange={(e) => {
                          let state = { ...dealerDetails };
                          state.outlet_details[i].relation_manager_details[
                            j
                          ].name = e.target.value;
                          setDealerDetails(state);
                        }}
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        //   required
                        id="rm_mobile"
                        name="rm_mobile"
                        label="RM Mobile Number"
                        autoComplete="family-name"
                        variant="standard"
                        fullWidth
                        value={rm?.mobile || ""}
                        onChange={(e) => {
                          if (
                            e.target.value !== "" &&
                            !/^\d+$/.test(e.target.value)
                          ) {
                            return false;
                          }
                          if (e.target.value.length > 10) return false;
                          let state = { ...dealerDetails };
                          state.outlet_details[i].relation_manager_details[
                            j
                          ].mobile =
                            e.target.value !== "" ? Number(e.target.value) : "";
                          setDealerDetails(state);
                        }}
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        //   required
                        id="rm_email"
                        name="rm_email"
                        label="Email"
                        autoComplete="family-name"
                        variant="standard"
                        fullWidth
                        value={rm?.email}
                        onChange={(e) => {
                          let state = { ...dealerDetails };
                          state.outlet_details[i].relation_manager_details[
                            j
                          ].email = e.target.value;
                          setDealerDetails(state);
                        }}
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item xs={2} className="mt-3">
                      {j !== 0 && !isDisabled && (
                        <Button
                          onClick={(e) => {
                            let state = { ...dealerDetails };
                            let temp = [
                              ...address?.relation_manager_details,
                            ].filter((item, index) => index !== j);

                            state.outlet_details[i].relation_manager_details =
                              temp;
                            setDealerDetails(state);
                          }}
                        >
                          <CloseIcon className="close-btn-rm" />
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
              {!isDisabled && (
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Button
                      className="add-rm-btn"
                      onClick={() => {
                        let state = { ...dealerDetails };
                        state?.outlet_details[
                          i
                        ]?.relation_manager_details?.push({
                          name: "",
                          email: "",
                          mobile: "",
                        });
                        setDealerDetails(state);
                      }}
                    >
                      <AddIcon /> Add New RM
                    </Button>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
        {!isDisabled && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Button
                className="mt-2"
                onClick={(e) => {
                  setDealerDetails({
                    ...dealerDetails,
                    outlet_details: [
                      ...dealerDetails?.outlet_details,
                      {
                        relation_manager_details: [
                          {
                            name: "",
                            mobile: "",
                            email: "",
                          },
                        ],
                      },
                    ],
                  });
                }}
              >
                <AddIcon /> Add more address
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ContactDetails;
