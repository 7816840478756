import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";

// import "./Scheduler.css";
import { DatePicker } from "@material-ui/pickers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchCollectionOverview,
  fetchCollections,
  updateCollectionV2
} from "../../api";
import info from "../../assets/info.svg";
import removeIcon from "../../assets/remove.png";
import LoadingOver from "../../Components/Loading";
import { f, formatDate } from "../../utility/Formater";
import { isBlank } from "../../utility/validations";
import "./Collection.css";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddTransacation = () => {
  const initalState = {
    is_colletion: false,
    payment_amount: "",
    transaction_date: moment().format("DD/MM/YYYY"),
    payment_mode: "Cash",
    transaction_id: "",
    approval_status: "not_yet_approved",
    amount_required_to_settle: "0"
  };

  const errorsIntial = {
    amount: "",
    date: "",
  };

  const navigate = useNavigate();
  const { app_id } = useParams();
  const { state } = useLocation();

  const [applications, setApplications] = useState([]);
  const [application_id, setApplicationId] = useState(app_id || "");
  const [isLoading, setLoading] = useState(false);
  const [isOverLoading, setOverLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [collectionData, setCollectionData] = useState(initalState);
  const [transactionData, setTransactionData] = useState([]);
  const [errors, setError] = useState(errorsIntial);
  const [fileData, setFileData] = useState("");
  const [transactionText, setTransactionText] = useState(null);
  const [holdBackVisibility, setHoldBackVisibility] = useState(false);

  const handleClickOpen = () => {
    let enteredAmount = collectionData?.payment_amount || "";
    let enteredDate = collectionData?.transaction_date || "";

    let filteredTransactionData = transactionData?.filter((obj) => {
      let updatedDate = moment(obj?.transaction_dateMS).format("DD/MM/YYYY");
      return (
        obj?.transaction_amount == enteredAmount && updatedDate == enteredDate
      );
      // if (
      //   obj.transaction_amount === enteredAmount &&
      //   updatedDate === enteredDate
      // ) {
      //   return obj;
      // }
    });
    if (filteredTransactionData && filteredTransactionData.length) {
      setTransactionText(true);
    } else if (filteredTransactionData.length === 0) {
      setTransactionText(false);
    }
    if (isValid()) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setTransactionText(null);
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    handleClose();

    if (isValid()) {
      try {
        let payload = {
          transaction_dateMS: dateConvertor(collectionData?.transaction_date),
          transaction_amount: Number(collectionData?.payment_amount),
          transaction_id: collectionData?.transaction_id,
          transaction_utr: collectionData?.transaction_utr,
          payment_mode: collectionData?.payment_mode,
          txn_image: fileData,
          is_approved:
            collectionData?.approval_status == "approved" ? true : false,
          amount_required_to_settle: Number(collectionData?.amount_required_to_settle),
        };

        // console.log(payload);

        let { data } = await updateCollectionV2(payload, application_id);

        toast.success("Updated");
        // console.log(application_id);
        getCollectionData(application_id);
      } catch (error) {
        toast.error("Server error");
      }
    } else {
      toast.error("Field Validation issue");
    }
    setLoading(false);
  };

  const isValid = () => {
    let valid = true;
    let errorObj = {
      amount: collectionData?.payment_amount === "" ? "Required field" : "",
      date: isNaN(dateConvertor(collectionData?.transaction_date))
        ? "Required field"
        : "",
      txn_number: [
        "UPI",
        "PAYMENT_LINK",
        "NACH",
        "PDC/Cheques",
        "NEFT",
        "IMPS"
      ].includes(collectionData?.payment_mode)
        ? isBlank(collectionData?.transaction_id)
          ? "Transaction number is mandatory"
          : ""
        : "",
      fileData:[undefined,null,""].includes(fileData) ? "image is mandatory" : ""
    };

    // console.log(errorObj);

    setError({
      ...errors,
      ...errorObj
    });

    for (let key in errorObj) {
      if (errorObj[key].length > 0) {
        return false;
      }
    }
    for (let key in errors) {
      if (errors[key].length > 0) {
        return false;
      }
    }

    return valid;
  };

  const getApplications = async () => {
    setOverLoading(true);
    try {
      let { data } = await fetchCollections(500);
      // console.log(data);
      let list = data?.data?.[0]?.result?.data.map((val) => {
        return {
          application_id: val?.application_id,
          name: !val?.customerData?.is_corporate
            ? val?.customerData?.pan_details?.name
            : val?.customerData?.corporate_pan_details?.name,
        };
      });
      setApplications(list);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
    setOverLoading(false);
  };

  useEffect(() => {
    if (!app_id) {
      getApplications();
    }
  }, []);

  useEffect(() => {
    setCollectionData(initalState);
  }, [application_id]);

  const dateConvertor = (date) => {
    let splitDate = date.split("/");
    let formatted = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`;
    return Number(new Date(formatted));
  };

  const handleChange = (prop) => (event) => {
    setError({
      ...errors,
      txn_number: "",
    });
    setCollectionData({
      ...collectionData,
      [prop]: event.target.value,
    });
    if (prop == "amount_required_to_settle") {
      setError({
        ...errors,
        settlement_target: Number(collectionData?.payment_amount) >
        Number(event.target.value) ? 
        "Payment amount cannot be greater than settlement target" : ""
      })
    }
    if (prop == "payment_amount") {
      if (holdBackVisibility) {
        setError({
          ...errors,
          amount: Number(event.target.value) >
          Number(collectionData?.collectedHoldBackLeftToSettle) ?
          "Payment amount cannot be greater than collected hold back" : "",
          settlement_target: Number(event.target.value) >
          Number(collectionData?.amount_required_to_settle) ? 
          "Payment amount cannot be greater than settlement target" : ""
        })
      }
    }
    if (prop == "payment_mode" && event.target.value == "HOLD BACK") {
      setHoldBackVisibility(true);
      setError({
        ...errors,
        amount: Number(collectionData?.payment_amount) >
        Number(collectionData?.collectedHoldBackLeftToSettle) ?
        "Payment amount cannot be greater than collected hold back" : "",
        settlement_target: Number(collectionData?.payment_amount) >
        Number(collectionData?.amount_required_to_settle) ? 
        "Payment amount cannot be greater than settlement target" : ""
      })
    } else if (prop == "payment_mode" && event.target.value != "HOLD BACK") {
      setHoldBackVisibility(false);
      setError({
        ...errors,
        amount: "",
        settlement_target: ""
      })
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        // const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
        const base64String = reader.result;
        setFileData(base64String);
        setError(prev=>({...prev,fileData:""}));
        // console.log(`Selected file: ${file.name}`);
        // console.log(`Base64 string: ${base64String}`);
      };
    }
  };

  const getCollectionData = async (id) => {
    try {
      setOverLoading(true);
      let { data: response } = await fetchCollectionOverview(id);
      // console.log(response);
      const { data } = response;
      setCollectionData({
        ...collectionData,
        is_colletion: true,
        customer_name: data?.customer_name,
        installmentAmount: data?.application?.installment_amount,
        nextDueAmount: data?.dueAmount,
        nextDueDate: data?.dueDate
          ? moment(data?.dueDate).format("DD/MM/YYYY")
          : "",
        totalCollected: data?.totalCollected,
        collectedHoldBackLeftToSettle: data?.collectedHoldBackLeftToSettle,
      });
      setTransactionData(data?.transactions);
    } catch (error) {
      setCollectionData({
        ...initalState,
        is_colletion: false,
      });
    }
    setOverLoading(false);
  };

  useEffect(() => {
    if (application_id !== "") {
      setCollectionData(initalState);
      getCollectionData(application_id);
    }
  }, [application_id]);

  useEffect(() => {
    let amount;
    if (collectionData?.nextDueAmount > 0) {
      amount = collectionData?.nextDueAmount;
    } else {
      amount = 0;
    }
    setCollectionData((st) => ({
      ...st,
      payment_amount: amount,
    }));
  }, [collectionData?.nextDueAmount]);

  // useEffect(() => {
  //   console.log(transactionData);
  // }, [setTransactionData]);

  return (
    <>
      <p
        className="go-back-btn-fixed"
        onClick={() => {
          if (state?.path) {
            navigate(state?.path);
          } else {
            navigate("/tools");
          }
        }}
      >
        <ArrowBackIcon /> Go back Home
      </p>
      <div className="wrapper-center">
        <div className="payment-form">
          <Grid item xs={12} sm={12}>
            {!app_id ? (
              <Autocomplete
                options={applications}
                getOptionLabel={(option) =>
                  `${option?.name} - ${option?.application_id}`
                }
                id="ApplicationId"
                clearOnBlur
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Application Id"
                    variant="standard"
                  />
                )}
                onChange={(event, value) => {
                  setApplicationId(value?.application_id || "");
                  navigate(`/update-collection/${value?.application_id}`);
                }}
              />
            ) : (
              <div>
                <p className="mb-0">
                  <span style={{ color: "gray" }}>Application ID : </span>
                  {application_id}
                  <CloseIcon
                    className="ms-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/update-collection");
                      setApplicationId("");
                      getApplications();
                    }}
                  />
                </p>
              </div>
            )}
          </Grid>

          {application_id !== "" && collectionData?.is_colletion && (
            <Grid item xs={12} sm={12} className={"mt-4"}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <p>
                    <span className="list-key">Customer name:</span>{" "}
                    {f(collectionData?.customer_name)}
                  </p>
                  <p>
                    <span className="list-key">Installment amount:</span>{" "}
                    {f(collectionData?.installmentAmount, "cur")}
                  </p>
                  <p>
                    <span className="list-key">Due amount:</span>{" "}
                    {f(collectionData?.nextDueAmount, "cur")}
                  </p>
                  <p>
                    <span className="list-key">Due Date:</span>{" "}
                    {f(collectionData?.nextDueDate)}
                  </p>
                  <p>
                    <span className="list-key">Total Collected Amount:</span>{" "}
                    {f(collectionData?.totalCollected, "cur")}
                  </p>
                  {holdBackVisibility && (
                    <p>
                      <span className="list-key">
                        Total Collected Hold Back:
                      </span>{" "}
                      {f(collectionData?.collectedHoldBackLeftToSettle, "cur")}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="paycard">
                    <TextField
                      required
                      id="amount"
                      name="amount"
                      label="Payment Amount"
                      fullWidth
                      autoComplete="given-name"
                      variant="standard"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      // inputProps={{ maxLength: 10 }}
                      value={collectionData?.payment_amount || ""}
                      onChange={handleChange("payment_amount")}
                      error={errors?.amount}
                      helperText={errors?.amount}
                    />
                  </div>
                  <FormControl
                    variant="standard"
                    sx={{ width: "100%", marginTop: "15px" }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Payment mode
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      defaultValue={collectionData?.payment_mode}
                      onChange={handleChange("payment_mode")}
                    >
                      <MenuItem value={"Cash"}>Cash</MenuItem>
                      <MenuItem value={"UPI"}>UPI</MenuItem>
                      <MenuItem value={"NACH"}>NACH</MenuItem>
                      <MenuItem value={"PAYMENT_LINK"}>Payment Link</MenuItem>
                      <MenuItem value={"PDC/Cheques"}>PDC/Cheques</MenuItem>
                      <MenuItem value={"NEFT"}>NEFT</MenuItem>
                      <MenuItem value={"HOLD BACK"}>HOLD BACK</MenuItem>
                      <MenuItem value={"IMPS"}>IMPS</MenuItem>
                    </Select>
                  </FormControl>
                  {holdBackVisibility && (
                    <TextField
                      required
                      id="settlement_target"
                      name="settlement_target"
                      label="Settlement Target"
                      fullWidth
                      autoComplete="given-name"
                      variant="standard"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      // inputProps={{ maxLength: 10 }}
                      value={collectionData?.amount_required_to_settle || ""}
                      onChange={handleChange("amount_required_to_settle")}
                      error={errors?.settlement_target}
                      helperText={errors?.settlement_target}
                    />
                  )}
                  <TextField
                    className="mt-2"
                    id="id_ref"
                    name="id_ref"
                    label={`Transaction ID/ Ref Number${
                      ["Cash","HOLD BACK"].includes(collectionData?.payment_mode) ? "" : "*"
                    }`}
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    value={collectionData?.transaction_id || ""}
                    onChange={handleChange("transaction_id")}
                    error={errors?.txn_number}
                    helperText={errors?.txn_number}
                  />
                  <TextField
                    // require
                    className="mt-2"
                    id="utr"
                    name="utr"
                    label="Transaction UTR"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    value={collectionData?.transaction_utr || ""}
                    onChange={handleChange("transaction_utr")}
                  />
                  <div className="mt-2">
                    {/* <DatePickerComponent
                      text={"Transaction date"}
                    
                      required={true}
                      defaultValue={collectionData?.transaction_date}
                      onChange={(date) => {
                        setCollectionData({
                          ...collectionData,
                          transaction_date: moment(date).format("DD/MM/YYYY"),
                        });
                      }}
                      error={errors?.date}
                    /> */}

                    <DatePicker
                      className="w-100 mt-2"
                      label="Transaction date"
                      format="dd/MM/yyyy"
                      // maxDate={new Date()}
                      disableFuture
                      value={formatDate(collectionData?.transaction_date)}
                      onChange={(date) => {
                        // console.log(moment(date).format("DD/MM/YYYY"));
                        setCollectionData({
                          ...collectionData,
                          transaction_date: moment(date).format("DD/MM/YYYY"),
                        });
                      }}
                      animateYearScrolling
                      error={errors?.date}
                      helperText={errors?.date}
                    />
                  </div>
                  <div className="mt-2">
                    {/* <span className="file-upload-label">Approval Status : </span> */}
                    <FormControl
                      variant="standard"
                      sx={{ width: "100%", marginTop: "15px" }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Approval Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        defaultValue={collectionData?.approval_status}
                        onChange={handleChange("approval_status")}
                      >
                        <MenuItem value={"approved"}>Approved</MenuItem>
                        <MenuItem value={"not_yet_approved"}>
                          Not yet Approved
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="mt-2">
                    <span className="file-upload-label">Add Image : </span>
                    {!fileData ? (
                      <>
                        <input
                          type="file"
                          id="file-upload"
                          hidden
                          accept=".jpg,.jpeg,.png"
                          onChange={handleFileUpload}
                        />
                        <label
                          for="file-upload"
                          className="file-upload file-upload-label"
                        >
                          +
                        </label>
                      </>
                    ) : (
                      <div className="file-upload-container">
                        <img className="file-upload" src={fileData} />
                        <img
                          className="removeIcon"
                          src={removeIcon}
                          onClick={() => setFileData("")}
                        />
                      </div>
                    )}
                    {Boolean(errors?.fileData) && <span className="text-danger" style={{fontSize:12}}>Image is mandatory</span> }
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}

          {application_id !== "" && !collectionData?.is_colletion && (
            <p className="text-center mt-5">No collection Data</p>
          )}

          {application_id !== "" && collectionData?.is_colletion && (
            <Button
              sx={{ color: "white" }}
              className="calculate-btn mt-4"
              onClick={() => {
                handleClickOpen();
              }}
              disabled={isLoading || collectionData?.payment_amount == 0}
            >
              {isLoading
                ? "Loading..."
                : `Pay ${
                    collectionData?.payment_amount === 0
                      ? ""
                      : "  ₹" + collectionData?.payment_amount
                  }`}
            </Button>
          )}
        </div>
      </div>
      {isOverLoading && <LoadingOver />}

      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          {transactionText ? (
            <>
              <DialogContent>
                <p
                  className="text-center"
                  style={{ display: "flex", padding: "0px", margin: "0px" }}
                >
                  <img
                    src={info}
                    alt=""
                    srcset=""
                    style={{ marginRight: "0.5rem" }}
                  />
                  Transaction already added for same amount and <span></span>
                </p>
                <p style={{ padding: "0px", margin: "0px 0px 0px 1.7rem" }}>
                  date for this application
                </p>
              </DialogContent>
              <DialogTitle>{"Confirm Amount"}</DialogTitle>
              <DialogContent>
                <p className="text-center">
                  You are about to make a payment of{" "}
                  <span
                    className="text-center mb-0"
                    style={{ fontSize: 30, fontWeight: 600, color: "green" }}
                  >
                    Rs.{collectionData?.payment_amount}
                  </span>
                </p>
                {/* <p
                  className="text-center mb-0"
                  style={{ fontSize: 30, fontWeight: 600, color: "green" }}
                >
                  Rs.{collectionData?.payment_amount}
                </p> */}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Confirm</Button>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogTitle>{"Confirm Amount"}</DialogTitle>
              <DialogContent>
                <p className="text-center">
                  You are about to make a payment of
                </p>
                <p
                  className="text-center mb-0"
                  style={{ fontSize: 30, fontWeight: 600, color: "green" }}
                >
                  Rs.{collectionData?.payment_amount}
                </p>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Confirm</Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </div>
    </>
  );
};

export default AddTransacation;
