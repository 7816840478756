export const OzonetelAgents =  [
    {
      "AgentId": "amir.khan",
      "SkillName": "Collections"
    },
    {
      "AgentId": "jeetu.yadav",
      "SkillName": "Collections"
    },
    {
      "AgentId": "ajay.prajapati",
      "SkillName": "Collections"
    },
    {
      "AgentId": "amit.kumar",
      "SkillName": "Collections"
    },
    {
      "AgentId": "anjali.pathak",
      "SkillName": "Collections"
    },
    {
      "AgentId": "vikas.gupta",
      "SkillName": "Sales"
    },
    {
      "AgentId": "sanjay.rana",
      "SkillName": "Sales"
    },
    {
      "AgentId": "shiraj.khan",
      "SkillName": "Sales"
    },
    {
      "AgentId": "arwaj.khan",
      "SkillName": "Collections"
    },
    {
      "AgentId": "shivani.kumari",
      "SkillName": "Sales"
    },
    {
      "AgentId": "aaryan.chaudhary",
      "SkillName": "Collections"
    },
    {
      "AgentId": "rupali.bhatt",
      "SkillName": "Collections"
    },
  ]
