import { CircularProgress } from "@mui/material";
import React from "react";

const LoadingDiv = () => {
  return (
    <div className="gps-loader-div">
      <div>
        <CircularProgress
          size={100}
          color="inherit"
          sx={{
            color: "#cccccc",
          }}
        />
      </div>
    </div>
  );
};

export default LoadingDiv;
