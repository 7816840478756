import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  Skeleton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { getOutstandingAmount } from "../api";
import { DatePicker } from "@material-ui/pickers";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: '#cccccc',
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: "left",
  boxShadow: "none",
  border: "1px solid #cccccc",
  margin: 10,
  padding: 5,
  // color: theme.palette.text.secondary,
}));
function OutstandingAmount({ open, handleClose, applicationId }) {
  const [outstandingTotal, setOutstandingTotal] = useState(0);
  const [outstandingAmount, setOutstandingAmount] = useState({});
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  async function fetchOutstandingAmount(date) {
    try {
      setLoading(true);
      const { data } = await getOutstandingAmount(
        applicationId,
        moment(date).format("yyyy-MM-DD")
      );
      setOutstandingAmount(data?.outstandingAmount || {});
      setOutstandingTotal(
        Object.values(data?.outstandingAmount)?.reduce((sum, ele) => {
          return sum + Number(ele);
        }, 0)
      );
    } catch (error) {}
    setLoading(false);
  }

  function toCapitalize(item) {
    return item
      ?.split("_")
      ?.map((wrd) => wrd?.[0]?.toUpperCase() + wrd?.slice(1)?.toLowerCase())
      ?.join(" ");
  }

  useEffect(() => {
    if( applicationId && open ){
      fetchOutstandingAmount(new Date());
      setDate(new Date());
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{}}>
          <div
            style={{
              width: 600,
              position: "relative",
            }}
          >
            <div
              style={{
                width: 600,
                height: 75.5,
                clipPath: "polygon(82% 0, 100% 0, 100% 100%, 23% 100%)",
                position: "absolute",
                backgroundColor: "rgba(73, 72, 94, 0.5)",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "rgba(33, 34, 47, 1)",
                padding: 20,
                color: "#fff",
              }}
            >
              <h5>Outstanding Amount</h5>
              <Button onClick={handleClose}>
                <CloseIcon
                  sx={{
                    color: "#fff",
                  }}
                />
              </Button>
            </div>
            <div
              style={{
                paddingTop: 30,
                paddingLeft: 30,
              }}
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                // paddingLeft={3}
                // paddingTop={3}
              >
                {loading
                  ? [...new Array(6)]?.map((item, i) => {
                      // console.log("hello ", i);
                      return (
                        <Grid item xs={5} key={i}>
                          <Item>
                            <Skeleton
                              animation={"wave"}
                              variant="rectangular"
                              height={50}
                            />
                          </Item>
                        </Grid>
                      );
                    })
                  : Object.keys(outstandingAmount)?.map((item, i) => {
                      return (
                        <Grid item xs={5} key={i}>
                          <Item>
                            <h6>{toCapitalize(item)}</h6>
                            <h5>₹ {outstandingAmount?.[item] || 0}</h5>
                          </Item>
                        </Grid>
                      );
                    })}
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                width={"80%"}
              >
                <Grid item xs={7}>
                  {loading ? (
                    <Item>
                      <Skeleton
                        animation={"wave"}
                        variant="rectangular"
                        height={50}
                      />
                    </Item>
                  ) : (
                    <Item>
                      <h6>Total Outstanding Amount</h6>
                      <h5>₹ {Number(outstandingTotal)?.toFixed(2)}</h5>
                    </Item>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              padding: 10,
            }}
          >
            <div
              style={{
                width: 120,
              }}
              // className="date-picker-show"
            >
              <DatePicker
                label="As On"
                format="dd/MM/yyyy"
                value={date}
                onChange={(val)=>{
                  fetchOutstandingAmount(val);
                  setDate(val);
                }}
                animateYearScrolling
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default OutstandingAmount;
