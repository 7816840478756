// import * as React from "react";
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button, InputLabel } from "@mui/material";
import { GlobalContext } from "../../Context/GlobalContext";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import UploadSection from "../../Components/Anchor/UploadSection";
import useOem from "../../hooks/useOem";
import { useParams } from "react-router-dom";

const initVehicleDetails = [
  {
    ex_showroom_price: "",
    vehicle_model: "",
  },
];

function VehicleAndDealer({ isDisabled, isDownloadAble }) {
  const { oemState } = React.useContext(GlobalContext);
  const [oemDetails, setOemDetails] = oemState;
  const {
    addDocument,
    removeDocument,
    handleSingleDocDelete,
    handleSingleDocReupload,
    getDocArr,
  } = useOem();
  const { oemId } = useParams();
  const [swipeArr, setSwipeArr] = useState(null);

  const getDocumentsArray = () => getDocArr(3);

  const handleVehicleDtlAddClick = (e) => {
    e.preventDefault();
    setOemDetails((prev) => {
      return {
        ...prev,
        vehicle_details: [...prev?.vehicle_details, { ...initVehicleDetails }],
      };
    });
  };

  const handleVehicleDtlRemoveClick = (index) => (e) => {
    e.preventDefault();
    setOemDetails((prev) => {
      return {
        ...prev,
        vehicle_details: prev?.vehicle_details?.toSpliced(index, 1),
      };
    });
  };

  // useEffect(()=>{
  //   setSwipeArr(getDocArr(3));
  // },[oemDetails?.documents])

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <>
          <Grid item xs={12} sm={12}>
            <UploadSection
              // mt={20}
              heading={"Dealer Dump"}
              category={"oem_dealer_dump"}
              docs={oemDetails?.["documents"]?.["oem_dealer_dump"] || []}
              onChange={(file) => {
                addDocument("oem_dealer_dump", file);
              }}
              onDelete={(file, i) => {
                removeDocument("oem_dealer_dump", i);
              }}
              isEditable={!isDisabled}
              isDownloadAble={isDownloadAble}
              anchor_id={oemId}
              handleSingleDocReupload={handleSingleDocReupload}
              handleSingleDocDelete={handleSingleDocDelete}
              swipeArr={swipeArr}
              getSwipeArr={getDocumentsArray}
            />
          </Grid>
        </>

        {oemDetails?.vehicle_details?.length !== 0 && (
          <Grid item xs={12} style={{ marginBottom: -40 , marginTop:24 }}>
            <span className="upload-section-heading">{"Vehicle Details"}</span>
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          {oemDetails?.vehicle_details?.map((c, index) => {
            return (
              <div
                style={{
                  backgroundColor: "#F7F7F7",
                  marginTop: 20,
                  padding: 15,
                  paddingBottom: 40,
                  position: "relative",
                  // width: "100%",
                }}
              >
                {index !== 0 && !isDisabled && (
                  <Button
                    className="remove-oem-btn"
                    onClick={handleVehicleDtlRemoveClick(index)}
                  >
                    <CloseIcon sx={{ fontSize: 17, mr: 0.5 }} /> Remove
                  </Button>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      id="vehicle_model"
                      disabled={isDisabled}
                      name="vehicle_model"
                      label="vehicle_model"
                      fullWidth
                      variant="standard"
                      value={c?.vehicle_model}
                      onChange={(event) => {
                        let { value } = event.target;
                        setOemDetails((prev) => {
                          let temp = [...prev?.vehicle_details];
                          temp[index].vehicle_model = value;
                          return {
                            ...prev,
                            vehicle_details: temp,
                          };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      // required
                      id="ex_showroom_price"
                      name="ex_showroom_price"
                      disabled={isDisabled}
                      type={"number"}
                      label="Ex- Showroom Price"
                      fullWidth
                      // autoComplete="email"
                      variant="standard"
                      value={c?.ex_showroom_price}
                      onChange={(event) => {
                        let { value } = event.target;
                        setOemDetails((prev) => {
                          let temp = [...prev?.vehicle_details];
                          temp[index].ex_showroom_price = value;
                          return {
                            ...prev,
                            vehicle_details: temp,
                          };
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </Grid>
        {!isDisabled && oemDetails?.vehicle_details?.length !== 0 && (
          <Grid item xs={12} sm={12}>
            <Button
              onClick={handleVehicleDtlAddClick}
              sx={{ mt: 3, ml: 1, border: "1px solid" }}
            >
              <AddIcon /> ADD VEHICLE DETAILS
            </Button>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default VehicleAndDealer;
