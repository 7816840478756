import moment from "moment";
import * as dateFns from "date-fns";
import { irr } from "node-irr";
const generateEmiAndEdiScheme = (
  disbursedDate,
  tenure,
  loanAmount,
  interest,
  tenureType,
  skipEmi = true
) => {
  const {
    emiStartDate,
    ediStartDate,
    // loanEndDate,
    ediDaysDifference,
  } = findDateDifferences({ disbursedDate, tenure, tenureType });
  // console.log("emiStart",emiStartDate);
  // console.log("ediStart",ediStartDate);
  const { emiInstallment, ediInstallment } = calculateInstallment({
    ediDaysDifference,
    loanAmount,
    interest,
    tenure,
    tenureType,
    emiStartDate,
  });
  const { ediExcessAmount, emiExcessAmount } = calculateExcessAmount(
    loanAmount,
    ediInstallment,
    emiInstallment,
    tenure,
    interest,
    ediDaysDifference,
    tenureType
  );
  let ediIrr;
  let ediTable = [],
      emiTable = [];
  let adjustedEdi = 0;
  // let adjustedEmi = 0;
  // console.log("here, before if else first ediExcessAmount",ediExcessAmount);
  // console.log("ediInstallment",ediInstallment);
  if (ediExcessAmount >= 0 && ediExcessAmount <= ediInstallment) {
    adjustedEdi = Math.ceil(ediInstallment - ediExcessAmount);
    ediIrr = calculateIrr(
      loanAmount,
      ediInstallment,
      ediDaysDifference,
      adjustedEdi
    );
    // if (tenureType == "DAYS") {
    const { ediSheet } = generateEDIScheme(
      loanAmount,
      ediIrr,
      ediInstallment,
      ediDaysDifference,
      ediStartDate,
      [],
      adjustedEdi
    );
    ediTable = ediSheet;
    if (tenureType == "DAYS" && skipEmi) {
      return {
        ediSheet,
        emiSheet : [],
      };
    } else if (tenureType == "DAYS" && !skipEmi) {
      let adjustedEmi = Math.ceil(emiInstallment - emiExcessAmount);
      let emiIrr = calculateAdjustedEmiIrr(
        loanAmount,
        emiInstallment,
        adjustedEmi,
        tenure
      );
      const { emiSheet } = generateEMIScheme(
        loanAmount,
        emiIrr,
        emiInstallment,
        tenure / 30,
        emiStartDate,
        [],
        adjustedEmi
      );

      return {
        ediSheet,
        emiSheet,
      };
    }
    // }
    // else {
    // const emiIrr = calculateIrr(loanAmount, emiInstallment, tenure);
    // const { emiSheet } = generateEMIScheme(
    //   loanAmount,
    //   emiIrr,
    //   emiInstallment,
    //   tenure,
    //   emiStartDate,
    //   []
    // );
    //   const { ediSheet } = generateEDIScheme(
    //     loanAmount,
    //     ediIrr,
    //     ediInstallment,
    //     ediDaysDifference,
    //     ediStartDate,
    //     [],
    //     adjustedEdi
    //   );
    //   ediTable = ediSheet;
    // }
  } else {
    adjustedEdi = Math.ceil(ediInstallment - ediExcessAmount);
    let secondLast = Math.ceil(ediInstallment + adjustedEdi);
    let ediIrr = calculateAdjustedIrr2(
      loanAmount,
      ediInstallment,
      secondLast,
      ediDaysDifference
    );
    let { ediSheet } = generateEDISchemeCase2(
      loanAmount,
      ediIrr,
      ediInstallment,
      ediDaysDifference,
      ediStartDate,
      [],
      secondLast
    );
    ediTable = ediSheet;
    if (tenureType == "DAYS" && skipEmi) {
      return {
        ediSheet,
        emiSheet : [],
      };
    } else if (tenureType == "DAYS" && !skipEmi) {
      let adjustedEmi = Math.ceil(emiInstallment - emiExcessAmount);
      let emiIrr = calculateAdjustedEmiIrr(
        loanAmount,
        emiInstallment,
        adjustedEmi,
        tenure
      );
      const { emiSheet } = generateEMIScheme(
        loanAmount,
        emiIrr,
        emiInstallment,
        tenure / 30,
        emiStartDate,
        [],
        adjustedEmi
      );

      return {
        ediSheet,
        emiSheet,
      };
    }
  }
  // console.log("emiInstallment",emiInstallment);
  // console.log("emiExcess",emiExcessAmount);
  if (emiExcessAmount >= 0 && emiExcessAmount <= emiInstallment) {
    let adjustedEmi = Math.ceil(emiInstallment - emiExcessAmount);
    let emiIrr = calculateAdjustedEmiIrr(
      loanAmount,
      emiInstallment,
      adjustedEmi,
      tenure
    );
    // console.log("under emiSheet calc");
    if (tenureType !== "DAYS") {
      let { emiSheet } = generateEMIScheme(
        loanAmount,
        emiIrr,
        emiInstallment,
        tenure,
        emiStartDate,
        [],
        adjustedEmi
      );
      // console.log("emiSheet length",emiSheet.length);
      emiTable = emiSheet;
    }
  }

  return {
    emiSheet : emiTable,
    ediSheet : ediTable,
  };
  // const ediIrr = calculateIrr(loanAmount, ediInstallment, ediDaysDifference);
};
function calculateAdjustedEmiIrr(amount, emiInstallment, lastValue, n) {
  const data = [Number(-amount)];
  for (let i = 0; i < n; i++) {
    if (i === n - 1) {
      data.push(lastValue);
    } else {
      data.push(emiInstallment);
    }
  }

  return irr(data);
}
// function calculateAdjustedIrr(loanAmount, emiInstallment, secondLast, n) {
//   const data = [Number(-loanAmount)];
//   for (let i = 0; i < n; i++) {
//     if (i === n - 1) {
//       data.push(0);
//     } else if (i === n - 2) {
//       data.push(secondLast);
//     } else {
//       data.push(emiInstallment);
//     }
//   }
//   return irr(data);
// }
function generateEDISchemeCase2(
  loanAmount,
  ediIrr,
  ediInstallment,
  ediDaysDifference,
  ediStartDate,
  ediSheet,
  secondLast
) {
  let edi;
  if (ediSheet.length == ediDaysDifference - 1) {
    edi = 0;
  } else if (ediSheet.length == ediDaysDifference - 2) {
    edi = secondLast;
  } else {
    edi = ediInstallment;
  }
  let interest = loanAmount * ediIrr;
  let principle = edi - interest;
  let osPrinciple = loanAmount - principle;
  if (osPrinciple < 0) {
    osPrinciple = osPrinciple * 0;
  }
  if (ediSheet.length < ediDaysDifference) {
    let row = {
      SL_NO    : ediSheet.length + 1,
      Due_Date : moment(new Date(ediStartDate))
        .add(ediSheet.length, "days")
        .format("DD/MM/YYYY"),
      EDI             : `${edi}`,
      Principal       : principle.toFixed(2),
      Interest        : interest.toFixed(2),
      "O/s_Principal" : osPrinciple.toFixed(2),
    };
    ediSheet.push(row);

    return generateEDISchemeCase2(
      osPrinciple,
      ediIrr,
      ediInstallment,
      ediDaysDifference,
      ediStartDate,
      ediSheet,
      secondLast
    );
  } else {
    return {
      ediSheet,
    };
  }
}
function calculateAdjustedIrr2(loanAmount, edi, secondLast, ediDaysDifference) {
  const data = [Number(-loanAmount)];
  for (let i = 0; i < ediDaysDifference; i++) {
    if (i === ediDaysDifference - 1) {
      data.push(0);
    } else if (i === ediDaysDifference - 2) {
      data.push(secondLast);
    } else {
      data.push(edi);
    }
  }

  return irr(data);
}
function calculateExcessAmount(
  loanAmount,
  edi,
  emi,
  tenure,
  interest,
  ediDaysDifference,
  tenureType
) {
  //  console.log("calculateExcessAmount function ediDaysDifference",ediDaysDifference);
  let ediExcessAmount = 0;
  let emiExcessAmount = 0;
  if (tenureType == "DAYS") {
    ediExcessAmount =
      edi * tenure -
      (loanAmount * (interest / 100) * (tenure / 365) + loanAmount);
  } else {
    ediExcessAmount =
      edi * ediDaysDifference -
      (loanAmount * (interest / 100) * (tenure / 12) + loanAmount);
    emiExcessAmount =
      emi * tenure -
      (loanAmount * (interest / 100) * (tenure / 12) + loanAmount);
  }

  return {
    ediExcessAmount,
    emiExcessAmount,
  };
}
function generateEDIScheme(
  loanAmount,
  ediIrr,
  ediInstallment,
  ediDaysDiffernce,
  ediStartDate,
  ediSheet,
  adjustedEdi
) {
  const interest = loanAmount * ediIrr;
  let principal;
  if (ediSheet.length + 1 == ediDaysDiffernce) {
    principal = adjustedEdi - interest;
  } else {
    principal = ediInstallment - interest;
  }
  let OsPrinciple = loanAmount - principal;
  if (OsPrinciple < 0) {
    OsPrinciple = OsPrinciple * 0;
  }
  if (ediSheet.length < ediDaysDiffernce) {
    let row = {
      SL_NO    : ediSheet.length + 1,
      Due_Date : moment(new Date(ediStartDate))
        .add(ediSheet.length, "days")
        .format("DD/MM/YYYY"),
      EDI :
        Number(ediSheet.length + 1) != Number(ediDaysDiffernce)
          ? ediInstallment
          : adjustedEdi,
      Principal       : principal.toFixed(2),
      Interest        : interest.toFixed(2),
      "O/s_Principal" : OsPrinciple.toFixed(2),
    };
    ediSheet.push(row);

    return generateEDIScheme(
      OsPrinciple,
      ediIrr,
      ediInstallment,
      ediDaysDiffernce,
      ediStartDate,
      ediSheet,
      adjustedEdi
    );
  } else {
    return {
      ediSheet,
    };
  }
}

function generateEMIScheme(
  loanAmount,
  emiIrr,
  emiInstallment,
  tenure,
  emiStartDate,
  emiSheet,
  adjustedEmi
) {
  const interest = loanAmount * emiIrr;
  const principal = emiInstallment - interest;
  let OsPrinciple = loanAmount - principal;
  if (OsPrinciple < 0) {
    OsPrinciple = OsPrinciple * 0;
  }
  if (emiSheet.length < Number(tenure)) {
    let row = {
      SL_NO    : emiSheet.length + 1,
      Due_Date : moment(new Date(emiStartDate))
        .add(emiSheet.length, "months")
        .format("DD/MM/YYYY"),
      EMI :
        Number(emiSheet.length + 1) !== Number(tenure)
          ? emiInstallment
          : adjustedEmi,
      Principal :
        Number(emiSheet.length + 1) !== Number(tenure)
          ? principal.toFixed(2)
          : loanAmount.toFixed(2),
      Interest        : interest.toFixed(2),
      "O/s_Principal" : OsPrinciple.toFixed(2),
    };
    emiSheet.push(row);

    return generateEMIScheme(
      OsPrinciple,
      emiIrr,
      emiInstallment,
      tenure,
      emiStartDate,
      emiSheet,
      adjustedEmi
    );
  } else {
    return {
      emiSheet,
    };
  }
}


const calculateIrr = (amount, installment, n, adjustedValue) => {
  const data = [Number(-amount)];
  for (let i = 0; i < n; i++) {
    if (i === n - 1) {
      data.push(adjustedValue);
    } else {
      data.push(installment);
    }
  }

  return irr(data);
};
function calculateInstallment({
  ediDaysDifference,
  loanAmount,
  interest,
  tenure,
  tenureType,
  emiStartDate,
}) {

  // console.log(interest);
  // tenure = 160;
  let emi = Math.ceil(
    (Number(loanAmount) +
      loanAmount *
        (interest / 100) *
        (tenure / (tenureType === "DAYS" ? 365 : 12))) /
      (tenureType === "DAYS"
        ? dateFns.differenceInCalendarMonths(
          dateFns.addDays(emiStartDate, tenure),
          emiStartDate
        )
        : tenure)
  );
  let edi = Math.ceil(
    (Number(loanAmount) +
      loanAmount *
        (interest / 100) *
        (tenure / (tenureType === "DAYS" ? 365 : 12))) /
      ediDaysDifference
  );

  return {
    emiInstallment : Math.ceil(Number(emi)),
    ediInstallment : Math.ceil(Number(edi)),
  };
}
function findDateDifferences({ disbursedDate, tenure, tenureType }) {
  const emiStartDate = moment(disbursedDate).add(1, "months");
  const ediStartDate = moment(new Date(disbursedDate)).add(1, "days");
  let loanEndDate;
  let ediDaysDifference;
  if (tenureType === "MONTHS") {
    loanEndDate = moment(new Date(disbursedDate)).add(Number(tenure), "months");
    ediDaysDifference =
      moment(loanEndDate).diff(moment(ediStartDate), "days") + 1;
  } else if (tenureType == "DAYS") {
    loanEndDate = moment(new Date(disbursedDate)).add(
      Number(tenure + 1),
      "days"
    );
    ediDaysDifference = tenure;
  }
  // console.log("edidaydiff",ediDaysDifference)
  //   console.log("loanEndDate",new Date(loanEndDate));
  return {
    emiStartDate : new Date(emiStartDate),
    ediStartDate : new Date(ediStartDate),
    loanEndDate  : new Date(loanEndDate),
    ediDaysDifference,
  };
}
export { generateEmiAndEdiScheme };
