import { Button, Dialog, FormControl, MenuItem, Select } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { fCollectionStatus } from "../../utility/Formater";
import { statgeMap } from "../../utility/Stages";
import DateRangePicker from "../DateRangePicker";

const CollectionScheduleExport = ({
  open,
  handleClose,
  collectionData: initalState,
  applicationId,
  onApply
}) => {

  const [collectionData, setCollectionData] = useState(initalState);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [option, setOption] = useState(1);

  const handleDownload = (e) => {
    e.preventDefault();

    // Headers for each column
    let headers = [
      `SL NO,Installment Amount,Due Date,Payment Date,Status,Paid via, Transaction Id, UTR`,
    ];

    // Convert users data to a csv
    let usersCsv = collectionData.reduce((acc, row) => {
      acc.push(
        [
          row["installment_number"],
          row["installment_amount"],
          moment(row?.due_date).format("DD/MM/YYYY"),
          row?.settlement_data?.length == 0 || row?.settlement_data == null
            ? "-"
            : moment(
                new Date(
                  row?.settlement_data[
                    row?.settlement_data?.length - 1
                  ]?.payment_date
                )
              ).format("DD/MM/YYYY"),
          statgeMap.COL_STATUS?.[row?.current_status] !== "NOT_UPDATED"
            ? statgeMap.COL_STATUS?.[row?.current_status]
            : "-",
          row?.settlement_data?.slice(-1)[0]?.payment_mode,
          row?.settlement_data?.slice(-1)[0]?.txn_number,
          row?.settlement_data?.slice(-1)[0]?.utr_number,
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName:
        option === 1
          ? `Collection Schedule - ${applicationId}`
          : `Collection Schedule - ${applicationId} - ${moment(
              startDate
            ).format("DD/MM/YYYY")} - ${moment(endDate).format("DD/MM/YYYY")}`,
      fileType: "text/csv",
    });
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleRangeChange = () => {
    let Filtered = initalState?.filter((item) => {
      return (
        moment(item?.due_date).isBetween(startDate, endDate) ||
        moment(item?.due_date).isSame(startDate) ||
        moment(item?.due_date).isSame(endDate)
      );
    });
    setCollectionData(Filtered);
  };

  useEffect(() => {
    // console.log(new Date(initalState?.[0]?.due_date));
    // console.log(new Date(initalState?.slice(-1)[0]?.due_date));
    setStartDate(new Date(initalState?.[0]?.due_date));
    setEndDate(new Date(initalState?.[0]?.due_date));
    setCollectionData(initalState);
  }, [initalState]);

  useEffect(() => {
    handleRangeChange();
  }, [startDate, endDate]);

  useEffect(() => {
    if (!open) {
      setCollectionData(initalState);
      setOption(1);
    }
  }, [open]);

  return (
    // <Dialog
    //   open={open || true}
    //   onClose={handleClose}
    //   PaperProps={{
    //     style: {
    //       maxHeight: 'none',
    //       maxWidth: 'none',
    //     },
    //   }}
    // >
    //   <div style={{width:'auto'}}>

    //   <DateRangePicker />
    //   </div>
    // </Dialog>

    <Modal
      show={open}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <FormControl fullWidth className="mb-2">
          <Select
            value={option}
            // onChange={handleCh
            onChange={(e) => {
              if (e.target.value === 1) {
                setCollectionData(initalState);
              }
              setOption(e.target.value);
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {/* <MenuItem value={1}>Full Data</MenuItem> */}
            <MenuItem value={1}>Date Range</MenuItem>
          </Select>
        </FormControl>

        {option === 1 && (
          <DateRangePicker
            minDate={new Date(initalState?.[0]?.due_date)}
            maxDate={new Date(initalState?.slice(-1)[0]?.due_date)}
            startDate={startDate}
            endDate={endDate}
            onChange={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={()=>{
          onApply(collectionData)
          handleClose()
        }}>Apply</Button>
        {/* <Button
          onClick={handleDownload}
          disabled={
            option === 2
              ? [null, "", undefined].includes(startDate) ||
                [null, "", undefined].includes(endDate)
              : false
          }
        >
          Export
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default CollectionScheduleExport;
