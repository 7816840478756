import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  MenuItem,
  TextField,
  createFilterOptions,
} from "@mui/material";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { getAgentFilesStatus } from "../../api";
import { LoadingSkeleton } from "./LoadingSkeleton";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AgentFilesStatusFilter = ({
  open,
  handleClose,
  onApply,
  options,
  agent_code,
  filteredData
}) => {
  const columns = {
    field: "",
    value: [],
    options,
  };

  const initialState = [{ ...columns }];

  const [filterDataValue, setFilterDataValue] = useState([]);
  const [filterData, setFilterData] = useState(initialState);
  const [selectData, setSelectData] = useState([]);
  const [reduceData, setReduceData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getFilterValue = async () => {
    try {
      setLoading(true);
      let { data } = await getAgentFilesStatus(agent_code);
      setFilterDataValue(data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getFilterValue();
      if (filteredData) {
        setFilterData(filteredData);
        handleReduceFilterValue(true);
      } else {
        setFilterData([...initialState]);
      }
    } else {
      setFilterDataValue([]);
      setFilterData(initialState);
      setSelectData([]);
      setReduceData([]);
    }
  }, [open]);

  const isPrevCompleted = () => {
    let finalObj = filterData[filterData?.length - 1] ?? {};
    for (let value of Object.values(finalObj)) {
      if (["", null, undefined]?.includes(value)) {
        return false;
      }
    }
    return true;
  };

  const addMore = () => {
    if (!isPrevCompleted()) {
      return;
    }

    setFilterData([
      ...filterData,
      {
        ...columns,
        options: options.filter((item) => {
          return !filterData.some((e) => e.field === item);
        }),
      },
    ]);
  };

  const handleFormatArray = () => {
    setSelectData(
      filterDataValue?.map((item) => {
        const obj = {
          application_id: {
            name: item.application_id,
            value: item.application_id,
          },
          nbfc: {
            name: item?.nbfcData?.name,
            value: item?.nbfc_id,
          },
          customer_name: {
            name: item?.customerData?.pan_details?.name,
            value: item?.customerData?.customer_id,
          },
          dealer_name: {
            name: item?.dealerData?.dealership_name,
            value: item?.dealerData?.anchor_id,
          },
          overall_status: {
            name: item?.status,
            value: item?.status,
          },
        };
        return obj;
      })
    );
  };

  useEffect(() => {
    handleFormatArray();
  }, [filterDataValue]);

  const handleApply = (data) => {
    let finalFilter = {};
    (data ? data : filterData)?.map((item, index) => {
        if(item.field==="dealer_name"){
            finalFilter["anchor_id"] = item.value?.map((val) => val[item.field]?.value);
        }else if(item.field==="nbfc"){
            finalFilter["nbfc_id"] = item.value?.map((val) => val[item.field]?.value);
        }else if(item.field==="customer_name"){
            finalFilter["customer_id"] = item.value?.map((val) => val[item.field]?.value);
        }else{
            finalFilter[item.field] = item.value?.map(
              (val) => val[item.field]?.value
            );
        }
      });
    onApply(finalFilter,filterData);
  };

  const removeItem = (i) => {
    setFilterData([...filterData.filter((item, index) => index !== i)]);
  };

  const handleChange = (key, value, i) => {
    let obj = { ...filterData[i] };
    obj = {
      ...obj,
      [key]: value,
    };
    if (key === "field") {
      obj = {
        ...obj,
        value: [],
      };
    }
    filterData[i] = obj;
    if (key === "field") {
      setFilterData([...filterData.splice(0, i + 1)]);
      return;
    }
    setFilterData([...filterData]);
  };

  const handleReduceFilterValue = (init) => {
    let dropData = {};
    let finalArray = [...selectData];
    let filtered = init
      ? filteredData
      : filterData;
    filtered?.map((item, index) => {
      if (item.field !== "" && item?.value?.length > 0) {
        finalArray = finalArray.filter((obj1) => {
          return item?.value?.some(
            (obj2) =>
              obj1?.[`${item?.field}`]?.value ===
              obj2?.[`${item?.field}`]?.value
          );
        });
      }
      dropData = {
        ...dropData,
        [index + 1]: [...finalArray],
      };
    });
    setReduceData(dropData);
  };

  useEffect(() => {
    handleReduceFilterValue();
  }, [selectData, filterData]);

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
        >
          <DialogContent>
            {loading && <LoadingSkeleton />}
            {!loading && (
              <div className="sort-dialog">
                <div className="sort-table-wrapper">
                  <table
                    className="sort-table app-sort-table"
                    id="app-sort-table"
                  >
                    <thead className="cf">
                      <tr>
                        <th style={{ width: 500 }}>Column</th>
                        <th style={{ width: "70%" }}>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterData?.map((col, i) => {
                        return (
                          <>
                            <tr>
                              <td data-title="Data Point">
                                <Select
                                  className="w-100"
                                  value={col.field}
                                  sx={{
                                    boxShadow: "none",
                                    ".MuiOutlinedInput-notchedOutline": {
                                      border: 0,
                                    },
                                  }}
                                  onChange={(e) => {
                                    handleChange("field", e.target.value, i);
                                  }}
                                >
                                  {col?.options?.map((item) => {
                                    return (
                                      <MenuItem value={item}>
                                        {(item || "")
                                          ?.replaceAll("_", " ")
                                          ?.toUpperCase()}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </td>
                              <td data-title="Operator">
                                {col?.field !== "" &&
                                  (() => {
                                    return (
                                      <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={(() => {
                                          const allOptions = [
                                            ...new Map(
                                              i === 0 &&
                                              !["application_date"].includes(
                                                col.field
                                              )
                                                ? selectData?.map((item) => [
                                                    item?.[col?.field]?.value,
                                                    item,
                                                  ])
                                                : reduceData?.[i]?.map(
                                                    (item) => [
                                                      item?.[col?.field]?.value,
                                                      item,
                                                    ]
                                                  )
                                            ).values(),
                                          ].filter((itm) =>
                                            Boolean(itm?.[col?.field]?.value)
                                          );
                                          return allOptions;
                                        })()}
                                        value={col?.value}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) =>
                                          option?.[col?.field]?.name
                                        }
                                        filterOptions={createFilterOptions({
                                          matchFrom: "start",
                                          stringify: (option) => {
                                            if (col?.field === "anchor") {
                                              return `${
                                                option?.[col?.field]?.name ||
                                                "" +
                                                  option?.[col?.field]?.value ||
                                                ""
                                              }`;
                                            }
                                            return (
                                              option?.[col?.field]?.name || ""
                                            );
                                          },
                                        })}
                                        renderOption={(
                                          props,
                                          option,
                                          { selected }
                                        ) => (
                                          <li
                                            {...props}
                                            key={option?.[col?.field]?.value}
                                          >
                                            <Checkbox
                                              icon={icon}
                                              checkedIcon={checkedIcon}
                                              style={{ marginRight: 8 }}
                                              checked={selected}
                                            />
                                            {option?.[col?.field]?.name +
                                              (col?.field === "anchor"
                                                ? " - " +
                                                  option?.[col?.field]?.value
                                                : "")}
                                          </li>
                                        )}
                                        fullWidth
                                        onChange={(e, value) => {
                                          handleChange("value", [...value], i);
                                        }}
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                      />
                                    );
                                  })()}
                              </td>
                              <td
                                className="sort-close-td ps-1"
                                style={{ width: "30px" }}
                              >
                                {i !== 0 && (
                                  <CloseIcon
                                    onClick={() => removeItem(i)}
                                    sx={{ fontSize: 18 }}
                                  />
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>

                    <Button className="mb-3" onClick={addMore}>
                      Add More
                    </Button>
                  </table>
                </div>

                <div className="sort-bottom-holder">
                  <Button
                    className="me-2 clear-btn"
                    onClick={() => handleClose()}
                  >
                    Close
                  </Button>

                  <Button
                    variant="contained"
                    className="apply-btn"
                    onClick={() => handleApply()}
                  >
                    Apply Filters
                  </Button>
                </div>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default AgentFilesStatusFilter;
