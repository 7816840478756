import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Slide,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import React, { useContext, useState } from "react";
import DragDrop from "../DocumentUploader";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import uploadAnimation from "../../assets/cloud-upload.gif";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloseIcon from "@mui/icons-material/Close";
import { ProgressBar } from "react-bootstrap";
import BulkUploadStatus from "../BulkUploadStatus";
import { toast } from "react-toastify";
import {
  generateDFInvoice,
  generateSingleDFinvoice,
  generateSingleGPSinvoice,
  uploadGPSCsv,
  verifyAuth,
} from "../../api";
import { getDocKey } from "../../utility/Formater";
import { UserContext } from "../../Context/UserContext";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import LoadingOver from "../Loading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialStateBulkResponse = {
  successArray: [],
  failedArray: [],
  successCount: 0,
  failedCount: 0,
  open: false,
};

const GPSInvoicePdf = ({ open, handleClose }) => {
  const { user, setUser } = useContext(UserContext);
  const [progress, setProgress] = useState(0);
  const [tab, setTab] = useState("bulk");
  const [isUploading, setUploading] = useState(false);
  const [gpsCSV, setGpsCsv] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [bulkUploadResponse, setBulkUploadResponse] = useState(
    initialStateBulkResponse
  );
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);
  const [applicationId, setApplicationId] = useState(null);
  const [isGenerating, setGenerating] = useState(false);

  const [type, setType] = useState("GPS");

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const handleSubmit = async () => {
    setUploading(true);
    try {
      let payload = {
        csv: gpsCSV,
      };

      let { data } =
        type === "GPS"
          ? await uploadGPSCsv(payload, setProgress)
          : await generateDFInvoice(payload, setProgress);
      setBulkUploadResponse({
        successArray: data?.data?.successArr,
        failedArray: data?.data?.failedArr,
        successCount: (data?.data?.successArr || []).length,
        failedCount: (data?.data?.failedArr || []).length,
        open: true,
      });
      setProgress(0);
    } catch (error) {
      setProgress(0);
      toast.error(error?.response?.data?.message);
    }
    setUploading(false);
  };

  const handleGenerate = async () => {
    setGenerating(true);
    try {
      let { data } =
        type === "GPS"
          ? await generateSingleGPSinvoice(applicationId)
          : await generateSingleDFinvoice(applicationId);
      let pdfUrl =
        type === "GPS"
          ? data?.gps_invoice?.fileLink
          : data?.dealer_fees_invoice?.fileLink;
      if (pdfUrl) {
        pdfUrl = pdfUrl + "&file=true";
        const response = await axios.get(pdfUrl, {
          responseType: "blob",
        });

        const blobUrl = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `${type}-invoice_${applicationId}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      } else {
        toast.error("No invoice available");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setGenerating(false);
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleSampleCsv = () => {
    let headers =
      type === "GPS"
        ? [`application_id,gps_invoice_number,invoice_date`]
        : [
            `application_id,gst_number,invoice_number,invoice_date,due_month,taxable_amount,i_gst_amount,roundoff_amount,total_invoice_amount`,
          ];

    downloadFile({
      data: [...headers, []].join("\n"),
      fileName: `${type}-sample-sheet`,
      fileType: "text/csv",
    });
  };

  const handleDownloadAll = async (files = []) => {
    setIsDownloadingAll(true);

    try {
      let token = user?.token;
      if (!token) {
        let { data } = await verifyAuth();
        token = data?.token;
        setUser({
          ...data.data,
          token,
        });
      }

      const zip = new JSZip();
      const batchSize = 10;

      const downloadBatch = async (batchFiles) => {
        const batchPromises = batchFiles.map(async (file) => {
          const key = getDocKey(file?.fileLink)?.key;

          try {
            // Download the file from S3
            const { data } = await axios.get(
              `https://sen319hxy8.execute-api.ap-south-1.amazonaws.com/dev/view?environment=development&signedUrl=true&key=${key}`,
              {
                method: "get",
                withCredentials: true,
                headers: {
                  "Content-Type": "application/json",
                  application: "EMBEDDED-TOOLS",
                  token,
                },
              }
            );

            const { signedUrl } = data;
            const resp = await axios.get(signedUrl, {
              responseType: "blob",
            });

            zip.file(`${key?.replaceAll("/", "_")}.pdf`, resp.data);
          } catch (error) {
            toast.error(`Failed Download of ${key}`);
          }
          setDownloadProgress((prev) => prev + 1);
        });

        return Promise.all(batchPromises);
      };

      // Divide files into batches
      for (let i = 0; i < files.length; i += batchSize) {
        const batchFiles = files.slice(i, i + batchSize);
        await downloadBatch(batchFiles);
      }

      // Generate the combined ZIP file
      zip.generateAsync({ type: "blob" }).then(function (blob) {
        saveAs(blob, `${type}_invoices.zip`);
      });
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || "Error");
    } finally {
      setIsDownloadingAll(false);
    }
    setDownloadProgress(0);
    toast.info("Operation Completed!");
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={"sm"}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        {!isUploading ? (
          <DialogContent>
            <Grid container>
              <Grid item xs={tab === "bulk" ? 10 : 12}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <ToggleButtonGroup
                    color="primary"
                    value={tab}
                    exclusive
                    onChange={(e, val) => {
                      if (val !== null) {
                        setTab(val);
                      }
                    }}
                    aria-label="Platform"
                    size="small"
                  >
                    <ToggleButton value="bulk">Bulk Generate</ToggleButton>
                    <ToggleButton value="single">Single Download</ToggleButton>
                  </ToggleButtonGroup>
                  <FormControl>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={type}
                      onChange={handleChangeType}
                      size="small"
                    >
                      <MenuItem value={"GPS"}>GPS</MenuItem>
                      <MenuItem value={"DF"}>Dealer Fees</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              {tab === "bulk" && (
                <Grid item xs={2}>
                  <div className="d-flex justify-content-end mb-4">
                    <Button
                      onClick={() => {
                        handleSampleCsv();
                      }}
                      size="small"
                    >
                      <FileDownloadIcon /> Sample
                    </Button>
                  </div>
                </Grid>
              )}
            </Grid>

            {tab === "bulk" ? (
              <>
                {gpsCSV === null ? (
                  <div className="mb-5 mt-5">
                    <DragDrop
                      customType={["CSV"]}
                      setFile={(val, name) => {
                        setFileName(name);
                        setGpsCsv(val);
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <div
                      className="mt-2 d-flex"
                      style={{
                        width: "300px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <InsertDriveFileIcon /> {fileName}
                      </div>
                      <CloseIcon
                        className="pointer"
                        onClick={() => {
                          setFileName(null);
                          setGpsCsv(null);
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <TextField
                  className="mt-5"
                  fullWidth
                  value={applicationId}
                  label="Enter Application ID"
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    setApplicationId(e.target.value);
                  }}
                />
              </>
            )}
          </DialogContent>
        ) : (
          <DialogContent>
            <div>
              <p className="text-center">
                <img
                  className="mt-5"
                  src={uploadAnimation}
                  width={200}
                  alt="uploading..."
                />
              </p>
              <ProgressBar
                className="mt-4"
                striped
                variant="success"
                label={`${progress.toFixed(2)}%`}
                now={progress}
              />
            </div>
          </DialogContent>
        )}
        {!isUploading && (
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            {tab === "bulk" && (
              <Button onClick={handleSubmit} disabled={gpsCSV === null}>
                Submit
              </Button>
            )}
            {tab === "single" && (
              <Button
                onClick={handleGenerate}
                disabled={
                  applicationId === null || applicationId === "" || isGenerating
                }
              >
                {isGenerating ? "Generating..." : "Generate"}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>

      <BulkUploadStatus
        type="gps"
        open={bulkUploadResponse?.open}
        successArray={bulkUploadResponse?.successArray}
        failedArray={bulkUploadResponse?.failedArray}
        successCount={bulkUploadResponse?.successCount}
        failedCount={bulkUploadResponse?.failedCount}
        failedCsv={bulkUploadResponse?.failedCsv}
        handleDownloadAll={handleDownloadAll}
        handleClose={() => {
          setBulkUploadResponse(initialStateBulkResponse);
          handleClose();
        }}
      />

      {isDownloadingAll && (
        <LoadingOver
          text={`Downloading ${downloadProgress}/${bulkUploadResponse?.successCount}`}
        />
      )}
    </>
  );
};

export default GPSInvoicePdf;
