import React, { useState, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { fetchAllNBFCs } from "../api";
import { toast } from "react-toastify";

const typeList = ["WHATSAPP_REPLY", "TWILIO_TRANSCRIPTION"];
const repsonseList = [
  { id: 0, name: "Negative" },
  { id: 1, name: "Positive" },
];

const responseMap = {
  0: "Negative",
  1: "Positive",
};

const NotiFilter = ({ handleApply }) => {
  const [nbfc, setNBFC] = useState([]);
  const [type, setType] = useState(typeList);
  const [response, setResponse] = useState([1, 0]);
  const [nbfcList, setNbfcList] = useState(null);

  const fetchNBFCs = async () => {
    try {
      const { data } = await fetchAllNBFCs();

      let list = [];
      let selected = [];
      data?.data?.map((nbfc) => {
        if (
          nbfc?._id !== "" &&
          nbfc?._id !== "EM00004" &&
          nbfc?._id !== "PL00003"
        ) {
          list.push(nbfc);
          selected.push(nbfc?._id);
        }
      });
      setNbfcList(list);
      setNBFC(selected);
    } catch (error) {
      toast.error("Failed to load nbfc list");
    }
  };

  const handleChangeNBFC = (event) => {
    const {
      target: { value },
    } = event;
    setNBFC(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    fetchNBFCs();
  }, []);

  return (
    <div className="position-absolute top-0 w-100 d-flex align-items-center justify-content-center">
      <div className="d-flex flex-row align-items-center" style={{marginLeft:"-20%"}}>
        <FormControl size="small" sx={{ width: 200 }}>
          <InputLabel id="demo-simple-select-label">NBFC</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            label="NBFC"
            value={nbfc}
            onChange={handleChangeNBFC}
            // sx={style.selectStyle}
            renderValue={(selected) => {
              if (selected?.length === nbfcList?.length) return "All";
              else return selected?.join(", ");
            }}
          >
            {nbfcList?.map((val) => (
              <MenuItem key={val?._id} value={val?._id}>
                <Checkbox checked={nbfc.indexOf(val?._id) > -1} />
                <ListItemText primary={val?.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 200 }} size="small">
          <InputLabel id="demo-multiple-checkbox-label">Type</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={type}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              setType(typeof value === "string" ? value.split(",") : value);
            }}
            input={<OutlinedInput label="Type" />}
            renderValue={(selected) => {
              if (selected?.length === typeList?.length) return "All";
              else return selected?.join(", ");
            }}
            // MenuProps={MenuProps}
          >
            {typeList.map((val) => (
              <MenuItem key={val} value={val}>
                <Checkbox checked={type.indexOf(val) > -1} />
                <ListItemText primary={val} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ width: 150 }}>
          <InputLabel id="response-simple-select-label">
            Response type
          </InputLabel>
          <Select
            labelId="response-multiple-checkbox-label"
            id="response-multiple-checkbox"
            multiple
            label="Response type"
            value={response}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              setResponse(typeof value === "string" ? value.split(",") : value);
            }}
            // sx={style.selectStyle}
            renderValue={(selected) => {
              if (selected?.length === repsonseList?.length) return "All";
              else
                return selected
                  ?.map((val) => {
                    return responseMap?.[val];
                  })
                  ?.join(", ");
            }}
          >
            {repsonseList?.map((val) => (
              <MenuItem key={val?.id} value={val?.id}>
                <Checkbox checked={response.indexOf(val?.id) > -1} />
                <ListItemText primary={val?.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          sx={{ ml: 1 }}
          onClick={() => {
            handleApply(nbfc, type, response);
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default NotiFilter;
