export const sampleDisbursalData = [
    {
      "Month": "Apr'23",
      "Count": 49,
      "DisbursedAmount": 5200000,
      "AUM": 24815797
    },
    {
      "Month": "May'23",
      "Count": 69,
      "DisbursedAmount": 7845000,
      "AUM": 30744257
    },
    {
      "Month": "Jun'23",
      "Count": 75,
      "DisbursedAmount": 8550000,
      "AUM": 36017333
    },
    {
      "Month": "Jul'23",
      "Count": 77,
      "DisbursedAmount": 8830000,
      "AUM": 41883338
    },
    {
      "Month": "Aug'23",
      "Count": 70,
      "DisbursedAmount": 8030000,
      "AUM": 45494219
    },
    {
      "Month": "Sep'23",
      "Count": 84,
      "DisbursedAmount": 9410000,
      "AUM": 50172341
    },
    {
      "Month": "Oct'23",
      "Count": 87,
      "DisbursedAmount": 9775000,
      "AUM": 56999627
    },
    {
      "Month": "Nov'23",
      "Count": 101,
      "DisbursedAmount": 11300000,
      "AUM": 65111066
    },
    {
      "Month": "Dec'23",
      "Count": 112,
      "DisbursedAmount": 12350000,
      "AUM": 74158354
    },
    {
      "Month": "Jan'24",
      "Count": 120,
      "DisbursedAmount": 13410000,
      "AUM": 84095013
    },
    {
      "Month": "Feb'24",
      "Count": 144,
      "DisbursedAmount": 16223000,
      "AUM": 96832443
    },
    {
      "Month": "Mar'24",
      "Count": 164,
      "DisbursedAmount": 18642250,
      "AUM": 111649253
    },
    {
      "Month": "Apr'24",
      "Count": 155,
      "DisbursedAmount": 17651000,
      "AUM": 121459732
    },
    {
      "Month": "May'24",
      "Count": 160,
      "DisbursedAmount": 18942000,
      "AUM": 132898925
    },
    {
      "Month": "Jun'24",
      "Count": 178,
      "DisbursedAmount": 20809400,
      "AUM": 145532744
    },
    {
      "Month": "Jul'24",
      "Count": 205,
      "DisbursedAmount": 24563000,
      "AUM": 159097798
    },
    {
      "Month": "Aug'24",
      "Count": 231,
      "DisbursedAmount": 27350756,
      "AUM": 176448554
    }
  ];

export const sampleCollectionApplications = [
"EMAPL31102300002426",
"EMAPL31082300001874",
"EMAPL31072300001650",
"EMAPL31072300001648",
"EMAPL31052400004816",
"EMAPL31052400004809",
"EMAPL31052400004806",
"EMAPL31032400004023",
"EMAPL31032400004017",
"EMAPL30102300002418",
"EMAPL30092300002121",
"EMAPL30082300001870",
"EMAPL30072400005834",
"EMAPL30072400005825",
"EMAPL30072300001642",
"EMAPL30052400004803",
"EMAPL30052400004802",
"EMAPL30052400004797",
"EMAPL30052400004791",
"EMAPL30052400004784",
"EMAPL30042400004287",
"EMAPL30042400004286",
"EMAPL29082300001868",
"EMAPL29082300001860",
"EMAPL29072400005799",
"EMAPL29072400005797",
"EMAPL29062400005323",
"EMAPL29062400005321",
"EMAPL29062400005320",
"EMAPL29052400004781",
"EMAPL29052400004772",
"EMAPL29052400004768",
"EMAPL29052400004765",
"EMAPL29052400004761",
"EMAPL29042400004273",
"EMAPL29042300001022",
"EMAPL29032400004009",
"EMAPL29032400004003",
"EMAPL29032400004000",
"EMAPL29022400003671",
"EMAPL29022400003664",
"EMAPL29022400003657",
"EMAPL28092300002106",
"EMAPL28082300001857",
"EMAPL28082300001856",
"EMAPL28082300001855",
"EMAPL28072400005794",
"EMAPL28062400005301",
"EMAPL28062400005294",
"EMAPL28062400005288",
"EMAPL28052400004750",
"EMAPL28052400004749",
"EMAPL28052400004743",
"EMAPL28032400003998",
"EMAPL28022400003643",
"EMAPL27102300002395",
"EMAPL27092300002101",
"EMAPL27092300002089",
"EMAPL27072400005783",
"EMAPL27072400005776",
"EMAPL27072300001622",
"EMAPL27052400004737",
"EMAPL27052400004734",
"EMAPL27052400004729",
"EMAPL27052400004727",
"EMAPL27052400004726",
"EMAPL27052400004725",
"EMAPL27052400004719",
"EMAPL27052400004714",
"EMAPL27052400004713",
"EMAPL27042400004262",
"EMAPL27042400004261",
"EMAPL27042300001011",
"EMAPL27032400003976",
"EMAPL27032400003974",
"EMAPL27032400003973",
"EMAPL27022400003628",
"EMAPL27022400003627",
"EMAPL26102300002386",
"EMAPL26102300002385",
"EMAPL26102300002384",
"EMAPL26092300002085",
"EMAPL26092300002070",
"EMAPL26072400005767",
"EMAPL26072400005762",
"EMAPL26072300001619",
"EMAPL26072300001618",
"EMAPL26062400005267",
"EMAPL26062400005266",
"EMAPL26062400005263",
"EMAPL26062400005257",
"EMAPL26052400004705",
"EMAPL26032400003958",
"EMAPL26032400003955",
"EMAPL25092300002048",
"EMAPL25062400005253",
"EMAPL25062400005252",
"EMAPL25062400005244",
"EMAPL25062400005236",
"EMAPL25062400005233",
]