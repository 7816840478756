import CustomTableV2 from "../../Components/CustomTable/CustomTableV2";
import { f } from "../../utility/Formater";
import { sampleDisbursalData } from "../../utility/sampleData";

const SampleDisbursalAndOSTable = ({type}) => {
 
    const tableType = type === "OS" ? "AUM" : "DisbursedAmount"
    const tableData = sampleDisbursalData;
    let columns = [
        {
            label:"Month",
            render:(i)=>{
                return tableData[i]["Month"]
            }
        },
        {
            label:"Count",
            render:(i) => {
                return tableData[i]["Count"]
            }
        },
        {
            label:"Amount",
            render:(i) => {
                return f(tableData[i][tableType],"cur")
            }
        }
    ];

    return (
        <div>
            <CustomTableV2
                maxHeight={"60dvh"}
                tableData={sampleDisbursalData}
                columns={columns}
                borderBottom
            />
        </div>
    )
}

export default SampleDisbursalAndOSTable