import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilledInput,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditableAutoComplete from "../AutoComplete/EditableAutoComplete";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalContext";
import EditIcon from "@mui/icons-material/Edit";
import { compareObjects } from "../../utility/validations";
import { telepdTrigger, updateTelepd, updateTelepdCo } from "../../api";
import { toast } from "react-toastify";
import moment from "moment/moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { WhatsappIco } from "../../Constants/icons";

const labelStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: 13,
};

const initialState = {
  marital_status: "",
  family_count: "",
  earning_member_count: "",
  children_count: "",
  occupation: "",
  customer_monthly_income: "",
  monthly_household_income: "",
  preferred_repayment_mode: "",
  phone_type: "",
  phone_age: "",
  whatsapp_number: "",
  relation_with_coapplicant: "",
  education: "",
  vehicle_delivery_date: "",
  down_payment: "",
  down_payment_source: "",
  erickshaw_driver: "",
};

const initialStateCo = {
  telepd_number: "",
  qn_know: "",
  qn_aware: "",
  relationship: "",
  qn_responsible: "",
  alternative_number: "",
  phone_age: "",
};

const TelePDModal = ({ handleClose, open, getApplicationDetails }) => {
  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const [teleData, setTeleData] = useState(initialState);
  const [teleDataCo, setTeleDataCo] = useState(initialStateCo);
  const [editData, setEditData] = useState(initialState);
  const [editDataCo, setEditDataCo] = useState(initialStateCo);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isUpdateCo, setIsUpdateCo] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [tab, setTab] = useState("CUSTOMER");
  const [openTrigger, setOpenTrigger] = useState(false);
  const [triggerList, setTriggerList] = useState([]);
  const [loadTrigger, setLoadTrigger] = useState(null);
  const [triggerData, setTriggerData] = useState({});

  const handleChange = (key, value) => {
    setEditData((prev) => {
      let state = {
        ...prev,
        [key]: value,
      };
      sessionStorage.setItem(
        `${updateDetails?.application_id}_telepd_customer`,
        JSON.stringify(state)
      );
      return state;
    });
  };

  const handleChangeCo = (key, value) => {
    setEditDataCo((prev) => {
      let state = {
        ...prev,
        [key]: value,
      };
      sessionStorage.setItem(
        `${updateDetails?.application_id}_telepd_cocustomer`,
        JSON.stringify(state)
      );
      return state;
    });
  };

  const getArrVal = (key) => {
    if (Array.isArray(updateDetails?.video_kyc_data?.audio_kyc?.[key])) {
      return (
        (updateDetails?.video_kyc_data?.audio_kyc?.[key] || [])?.slice(-1)?.[0]
          ?.answer || ""
      );
    }
    return "";
  };

  const handleStateSet = () => {
    setLoading(true);
    const {
      other_details = {},
      co_customer_data,
      customer_trigger = null,
      co_customer_trigger = null,
    } = updateDetails?.video_kyc_data || {};
    let state = {
      telepd_number: other_details?.telepd_number || "",
      call_picked_by: other_details?.call_picked_by || "",
      marital_status: other_details?.marital_status || "",
      family_count: getArrVal("QPCOUNT"),
      earning_member_count: getArrVal("QEMCOUNT"),
      children_count: getArrVal("QKIDSCOUNT"),
      occupation: getArrVal("QWORK"),
      customer_monthly_income: getArrVal("QINCOME"),
      monthly_household_income: getArrVal("QFMICOME"),
      preferred_repayment_mode: other_details?.preferred_repayment_mode || "",
      phone_type: other_details?.phone_type || "",
      phone_age: other_details?.phone_age || "",
      whatsapp_number: other_details?.whatsapp_number || "",
      relation_with_coapplicant: other_details?.relation_with_coapplicant || "",
      education: getArrVal("QEDUCATION"),
      vehicle_delivery_date: other_details?.vehicle_delivery_date
        ? new Date(other_details?.vehicle_delivery_date)
        : "",
      down_payment: getArrVal("QDOWNPAY"),
      down_payment_source: getArrVal("QDPBORROW"),
      erickshaw_driver:
        other_details?.erickshaw_driver !== null &&
        typeof other_details?.erickshaw_driver === "object"
          ? "Other"
          : other_details?.erickshaw_driver,
      ...(other_details?.erickshaw_driver !== null &&
      typeof other_details?.erickshaw_driver === "object"
        ? {
            other_name: other_details?.erickshaw_driver?.name || "",
            other_relation: other_details?.erickshaw_driver?.relation || "",
            other_number: other_details?.erickshaw_driver?.number || "",
            other_dl: other_details?.erickshaw_driver?.driving_licence || "",
          }
        : {}),
    };

    setTriggerData({
      customer: customer_trigger,
      co_customer: co_customer_trigger,
    });

    if (
      sessionStorage.getItem(`${updateDetails?.application_id}_telepd_customer`)
    ) {
      let localData = JSON.parse(
        sessionStorage.getItem(
          `${updateDetails?.application_id}_telepd_customer`
        )
      );
      state = {
        ...state,
        ...(localData || {}),
      };
    }

    let stateCo = {
      telepd_number: co_customer_data?.telepd_number || "",
      call_picked_by: co_customer_data?.call_picked_by || "",
      qn_know: co_customer_data?.qn_know || "",
      qn_aware: co_customer_data?.qn_aware || "",
      relationship: co_customer_data?.relationship || "",
      qn_responsible: co_customer_data?.qn_responsible || "",
      alternative_number: co_customer_data?.alternative_number || "",
      phone_age: co_customer_data?.phone_age || "",
    };

    if (
      sessionStorage.getItem(
        `${updateDetails?.application_id}_telepd_cocustomer`
      )
    ) {
      let localData = JSON.parse(
        sessionStorage.getItem(
          `${updateDetails?.application_id}_telepd_cocustomer`
        )
      );
      stateCo = {
        ...stateCo,
        ...(localData || {}),
      };
    }

    setTeleData({ ...state });
    setEditData({ ...state });

    setTeleDataCo({ ...stateCo });
    setEditDataCo({ ...stateCo });
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        application_id: updateDetails?.application_id,
        ...(isUpdate ? compareObjects(editData, teleData) : editData),
      };

      if (
        editData?.hasOwnProperty("other_name") ||
        editData?.hasOwnProperty("other_relation") ||
        editData?.hasOwnProperty("other_number") ||
        editData?.hasOwnProperty("other_dl") ||
        editData?.erickshaw_driver === "Other"
      ) {
        payload.erickshaw_driver = {
          name: editData?.other_name,
          relation: editData?.other_relation,
          number: editData?.other_number,
          driving_licence: editData?.other_dl,
        };
      } else {
        payload.erickshaw_driver = editData?.erickshaw_driver;
      }
      delete payload?.other_name;
      delete payload?.other_number;
      delete payload?.other_relation;
      delete payload?.other_dl;

      let { data } = await updateTelepd(payload);
      toast.success(data?.message || "Operation successfull!");
      if (data?.status) {
        sessionStorage.removeItem(
          `${updateDetails?.application_id}_telepd_customer`
        );
      }
      await getApplicationDetails();
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Something wrong"
      );
    }
    setLoading(false);
    handleClose();
  };

  const handleSubmitCo = async () => {
    setLoading(true);
    try {
      const payload = {
        application_id: updateDetails?.application_id,
        ...(isUpdate ? compareObjects(editDataCo, teleDataCo) : editDataCo),
      };
      let { data } = await updateTelepdCo(payload);
      toast.success(data?.message || "Operation successfull!");
      if (data?.status)
        sessionStorage.removeItem(
          `${updateDetails?.application_id}_telepd_cocustomer`
        );
      await getApplicationDetails();
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Something wrong"
      );
    }
    setLoading(false);
    handleClose();
  };

  const handleTelepdTrigger = async (list = true) => {
    setLoadTrigger(list ? "LIST" : "TRIGGER");
    try {
      const { data } = await telepdTrigger(
        `${updateDetails?.application_id}?type=${
          tab === "CUSTOMER" ? "customer" : "co_customer"
        }${list ? "&list=true" : ""}`
      );

      if (list) {
        setTriggerList(data?.numbers || []);
      } else {
        toast.success("Trigger initiated successfully!");
        setOpenTrigger(false);
        setTriggerList([]);
        await getApplicationDetails();
      }
    } catch (error) {
      if (list) setOpenTrigger(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
    setLoadTrigger(null);
  };

  useEffect(() => {
    if (open) {
      if (
        Object.keys(updateDetails?.video_kyc_data?.audio_kyc || {}).length >
          0 ||
        Object.keys(updateDetails?.video_kyc_data?.other_details || {}).length >
          0
      ) {
        setIsUpdate(true);
      }

      if (
        Object.keys(updateDetails?.video_kyc_data?.co_customer_data || {})
          .length > 0
      ) {
        setIsUpdateCo(true);
      }
    }
    handleStateSet();
  }, [updateDetails?.video_kyc_data, open]);

  useEffect(() => {
    if (open) {
      setTab("CUSTOMER");
    }
  }, [open]);

  useEffect(() => {
    setEditData(teleData);
    setEditDataCo(teleDataCo);
    if (tab === "CUSTOMER" && isUpdate) {
      setIsEdit(false);
    } else if (tab === "COCUSTOMER" && isUpdateCo) {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  }, [tab, isUpdate, isUpdateCo, open]);

  useEffect(() => {
    if (editData?.erickshaw_driver !== "Other") {
      let state = editData;
      delete state?.other_name;
      delete state?.other_number;
      delete state?.other_relation;
      delete state?.other_dl;
      setEditData(state);
    }
  }, [editData?.erickshaw_driver]);

  useEffect(() => {
    if (openTrigger) {
      handleTelepdTrigger();
    }
  }, [openTrigger]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <DialogTitle className="p-0">
          <Grid container style={{ background: "#EFF5FF" }} sx={{ padding: 0 }}>
            <Grid xs={6} className="p-3">
              <p style={{ fontSize: 15, marginBottom: 0 }}>
                TELE PD :{" "}
                {tab === "CUSTOMER"
                  ? updateDetails?.customer_name
                  : updateDetails?.co_app_customer_name}
              </p>
              <p style={{ fontSize: 11, marginBottom: 0 }}>
                {tab === "CUSTOMER" ? (
                  <>
                    {updateDetails?.video_kyc_data?.updatedAt && isUpdate
                      ? moment(updateDetails?.video_kyc_data?.updatedAt).format(
                          "DD/MM/YYYY - hh:mm a"
                        )
                      : ""}
                  </>
                ) : (
                  <>
                    {updateDetails?.video_kyc_data?.co_customer_data
                      ?.telepd_dateMS
                      ? moment(
                          updateDetails?.video_kyc_data?.telepd_dateMS
                        ).format("DD/MM/YYYY - hh:mm a")
                      : ""}
                  </>
                )}
              </p>
            </Grid>
            {((tab === "CUSTOMER" && isUpdate) ||
              (tab === "COCUSTOMER" && isUpdateCo)) && (
              <Grid xs={6}>
                <div className="d-flex justify-content-end p-3 pe-5">
                  <Button
                    variant="contained"
                    sx={{
                      mr: 1,
                      fontSize: 10,
                      borderRadius: 2,
                      padding: "5px 10px",
                    }}
                    onClick={() => {
                      setIsEdit(!isEdit);
                      setEditData({ ...teleData });
                    }}
                  >
                    {isEdit ? (
                      <>
                        <CloseIcon sx={{ fontSize: 12, mr: 0.5 }} />
                        Cancel
                      </>
                    ) : (
                      <>
                        <EditIcon sx={{ fontSize: 12, mr: 0.5 }} />
                        Edit
                      </>
                    )}
                  </Button>
                </div>
              </Grid>
            )}
          </Grid>
          <div
            style={{
              overflow: "auto",
              height: "100%",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: 8,
                top: 8,
                zIndex: 5000,
              }}
            >
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="p-0 py-2">
          <div className="py-2 px-4" style={{ minHeight: "70vh" }}>
            {updateDetails?.coApplicantExist && (
              <div className="telepd-switch-container mb-3">
                <Button
                  color={
                    updateDetails?.customerTelePdDone ? "success" : "primary"
                  }
                  variant={tab === "CUSTOMER" ? "contained" : "outlined"}
                  onClick={() => setTab("CUSTOMER")}
                >
                  {updateDetails?.customerTelePdDone && (
                    <CheckCircleIcon sx={{ fontSize: 14, mr: 0.5 }} />
                  )}
                  Customer
                </Button>
                <Button
                  color={
                    updateDetails?.coCustomerTelePdDone ? "success" : "primary"
                  }
                  variant={tab === "COCUSTOMER" ? "contained" : "outlined"}
                  onClick={() => setTab("COCUSTOMER")}
                >
                  {updateDetails?.coCustomerTelePdDone && (
                    <CheckCircleIcon sx={{ fontSize: 14, mr: 0.5 }} />
                  )}
                  Co-customer
                </Button>
              </div>
            )}

            {tab === "CUSTOMER" ? (
              <Grid container spacing={2}>
                <Grid xs={7} item sx={labelStyle}>
                  Tele PD Number
                </Grid>
                <Grid xs={5} item>
                  <FilledInput
                    disabled={!isEdit}
                    variant="standard"
                    disableUnderline
                    fullWidth
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="editable-select-input input-style2"
                    placeholder=""
                    value={editData?.telepd_number}
                    onKeyDown={(e) => {
                      if (e.key === "e" || e.key === "E") {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue.length <= 10) {
                        handleChange("telepd_number", inputValue);
                      }
                    }}
                  />
                </Grid>

                {/* ////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  Who will pick the call
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    // writtable
                    options={[
                      "Self",
                      "Husband",
                      "Wife",
                      "Mother",
                      "Father",
                      "Son",
                      "Doughter",
                      "Sister",
                      "Brother",
                      "Relative",
                      "Friend",
                      "Other",
                    ]}
                    value={editData?.call_picked_by}
                    handleChange={(value) => {
                      handleChange("call_picked_by", value);
                    }}
                  />
                </Grid>

                {/* ////////////// */}
                <Grid xs={7} item sx={labelStyle}>
                  Occupation
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    writtable
                    options={["Erickshaw driver"]}
                    value={editData?.occupation}
                    handleChange={(value) => {
                      setEditData({
                        ...editData,
                        occupation: value,
                        ...(value === "Erickshaw driver"
                          ? { erickshaw_driver: "Customer" }
                          : {}),
                      });
                    }}
                  />
                </Grid>
                {/* ///////////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  Erickshaw Driver
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    options={[
                      "Customer",
                      ...(updateDetails?.coApplicantExist
                        ? ["CO-Customer"]
                        : []),
                      "Other",
                    ]}
                    value={editData?.erickshaw_driver}
                    handleChange={(value) => {
                      setEditData({
                        ...editData,
                        erickshaw_driver: value,
                        ...(value === "Customer"
                          ? { occupation: "Erickshaw driver" }
                          : {}),
                        ...(value !== "Customer" &&
                        editData?.occupation === "Erickshaw driver"
                          ? { occupation: "" }
                          : {}),
                      });
                    }}
                  />
                </Grid>

                {/* OTHER */}

                {editData?.erickshaw_driver === "Other" && (
                  <>
                    <Grid xs={7} item sx={labelStyle}>
                      Name (Other)
                    </Grid>

                    <Grid xs={5} item>
                      <FilledInput
                        disabled={!isEdit}
                        variant="standard"
                        disableUnderline
                        fullWidth
                        className="editable-select-input input-style2"
                        placeholder=""
                        value={editData?.other_name}
                        onChange={(e) => {
                          handleChange("other_name", e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid xs={7} item sx={labelStyle}>
                      Relation (Other)
                    </Grid>
                    <Grid xs={5} item>
                      <EditableAutoComplete
                        disabled={!isEdit}
                        writtable
                        options={[
                          "Husband",
                          "Wife",
                          "Mother",
                          "Father",
                          "Son",
                          "Daughter",
                          "Sister",
                          "Brother",
                          "Relative",
                          "Friend",
                        ]}
                        value={editData?.other_relation}
                        handleChange={(value) => {
                          handleChange("other_relation", value);
                        }}
                      />
                    </Grid>

                    <Grid xs={7} item sx={labelStyle}>
                      Number (Other)
                    </Grid>
                    <Grid xs={5} item>
                      <FilledInput
                        disabled={!isEdit}
                        variant="standard"
                        disableUnderline
                        fullWidth
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className="editable-select-input input-style2"
                        placeholder=""
                        value={editData?.other_number}
                        onKeyDown={(e) => {
                          if (e.key === "e" || e.key === "E") {
                            e.preventDefault();
                            return false;
                          }
                        }}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue.length <= 10) {
                            handleChange("other_number", inputValue);
                          }
                        }}
                      />
                    </Grid>

                    <Grid xs={7} item sx={labelStyle}>
                      Driving Licence (Other)
                    </Grid>

                    <Grid xs={5} item>
                      <FilledInput
                        disabled={!isEdit}
                        variant="standard"
                        disableUnderline
                        fullWidth
                        className="editable-select-input input-style2"
                        placeholder=""
                        value={editData?.other_dl}
                        onChange={(e) => {
                          handleChange("other_dl", e.target.value);
                        }}
                      />
                    </Grid>
                  </>
                )}

                {/* /////////  end of erick Driver section ///// */}

                <Grid xs={7} item sx={labelStyle}>
                  Vehicle Delivery Date
                </Grid>
                <Grid xs={5} item>
                  <FilledInput
                    disabled={!isEdit}
                    variant="standard"
                    disableUnderline
                    fullWidth
                    type="date"
                    className="editable-select-input input-style2"
                    value={
                      editData?.vehicle_delivery_date
                        ? moment(editData?.vehicle_delivery_date).format(
                            "YYYY-MM-DD"
                          )
                        : ""
                    }
                    onChange={(e) => {
                      handleChange(
                        "vehicle_delivery_date",
                        e.target.value === ""
                          ? ""
                          : new Date(e.target.value).getTime()
                      );
                    }}
                  />
                </Grid>

                {/* ////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  How much down payment have you paid?
                </Grid>
                <Grid xs={5} item>
                  <FilledInput
                    disabled={!isEdit}
                    variant="standard"
                    disableUnderline
                    fullWidth
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="editable-select-input input-style2"
                    placeholder=""
                    value={editData?.down_payment}
                    onKeyDown={(e) => {
                      if (e.key === "e" || e.key === "E") {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onChange={(e) => {
                      handleChange("down_payment", e.target.value);
                    }}
                  />
                </Grid>

                {/* ////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  Source of Down payment
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    options={[
                      "Full amount paid by me",
                      "Dealer helped me with downpayment",
                      "I have taken separate loan for down payment",
                      "Friend/Relative helped me",
                    ]}
                    value={editData?.down_payment_source}
                    handleChange={(value) => {
                      handleChange("down_payment_source", value);
                    }}
                  />
                </Grid>

                {/* ////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  Number of Family members
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    options={[
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "10",
                      "More than 10",
                    ]}
                    value={editData?.family_count}
                    handleChange={(value) => {
                      handleChange("family_count", value);
                    }}
                  />
                </Grid>

                {/* ////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  Marital Status
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    options={["Married", "Single", "Widow", "Divorced"]}
                    value={editData?.marital_status}
                    handleChange={(value) => {
                      setEditData({
                        ...editData,
                        marital_status: value,
                        children_count: value === "Single" ? "NA" : "",
                      });
                    }}
                  />
                </Grid>

                {/* ////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  Number of Earning members in the Family
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    options={[
                      "Only Self",
                      "1 (other than self)",
                      "2",
                      "3",
                      "More than 3",
                    ]}
                    value={editData?.earning_member_count}
                    handleChange={(value) => {
                      setEditData({
                        ...editData,
                        earning_member_count: value,
                        monthly_household_income:
                          value === "Only Self" ? "Same as customer" : "",
                      });
                    }}
                  />
                </Grid>

                {/* ////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  Number of children (if married)
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit || editData?.marital_status === "Single"}
                    options={["0", "1", "2", "3", "4", "More than 4"]}
                    value={editData?.children_count}
                    handleChange={(value) => {
                      handleChange("children_count", value);
                    }}
                  />
                </Grid>

                <Grid xs={7} item sx={labelStyle}>
                  Customer's monthly income
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    options={[
                      "Currently not earning",
                      "less than 10K",
                      "10K-15K",
                      "15K-25K",
                      "More than 25K",
                    ]}
                    value={editData?.customer_monthly_income}
                    handleChange={(value) => {
                      handleChange("customer_monthly_income", value);
                    }}
                  />
                </Grid>

                {/* ////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  Monthly Household income
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={
                      !isEdit || editData?.earning_member_count === "Only Self"
                    }
                    options={[
                      "Same as customer",
                      "less than 15K",
                      "15K-25K",
                      "25K-40K",
                      "More than 40K",
                    ]}
                    value={editData?.monthly_household_income}
                    handleChange={(value) => {
                      handleChange("monthly_household_income", value);
                    }}
                  />
                </Grid>

                {/* ////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  Preferred Repayment Mode
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    writtable
                    options={[
                      "Nach",
                      "Cash to Dealer",
                      "UPI to NBFC",
                      "UPI to dealer",
                      // "Other",
                    ]}
                    value={editData?.preferred_repayment_mode}
                    handleChange={(value) => {
                      handleChange("preferred_repayment_mode", value);
                    }}
                  />
                </Grid>

                {/* ////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  Phone number age
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    writtable
                    writeNumber
                    placeholder={"in months"}
                    options={[
                      "2017",
                      "2018",
                      "2019",
                      "2020",
                      "2021",
                      "2022",
                      "2024",
                    ]}
                    writeLabel={"Less that 1 year"}
                    value={editData?.phone_age}
                    handleChange={(value) => {
                      handleChange("phone_age", value);
                    }}
                  />
                </Grid>

                {/* ////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  Phone Type
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    options={["Smart phone", "Keypad phone"]}
                    value={editData?.phone_type}
                    handleChange={(value) => {
                      setEditData({
                        ...editData,
                        phone_type: value,
                        whatsapp_number: value === "Keypad phone" ? "No" : "",
                      });
                    }}
                  />
                </Grid>

                {/* ////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  Whatsapp Number
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={
                      !isEdit || editData?.phone_type === "Keypad phone"
                    }
                    writtable
                    writeNumber
                    options={["No", "Yes, Same Number"]}
                    writeLabel={"Yes, with other number"}
                    value={editData?.whatsapp_number}
                    handleChange={(value) => {
                      handleChange("whatsapp_number", value);
                    }}
                  />
                </Grid>

                {/* ////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  Education
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    options={[
                      "Did not study",
                      "Below 10th",
                      "10th pass",
                      "12th pass",
                      "Graduate",
                    ]}
                    value={editData?.education}
                    handleChange={(value) => {
                      handleChange("education", value);
                    }}
                  />
                </Grid>

                {/* ////////////// */}

                {updateDetails?.coApplicantExist && (
                  <>
                    <Grid xs={7} item sx={labelStyle}>
                      Relation with CO-App
                    </Grid>
                    <Grid xs={5} item>
                      <EditableAutoComplete
                        disabled={!isEdit}
                        writtable
                        options={[
                          "Husband",
                          "Wife",
                          "Mother",
                          "Father",
                          "Son",
                          "Daughter",
                          "Sister",
                          "Brother",
                          "Relative",
                          "Friend",
                          // "Other",
                        ]}
                        value={editData?.relation_with_coapplicant}
                        handleChange={(value) => {
                          handleChange("relation_with_coapplicant", value);
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            ) : (
              <Grid container spacing={2}>
                {/* ////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  Tele PD Number
                </Grid>
                <Grid xs={5} item>
                  <FilledInput
                    disabled={!isEdit}
                    variant="standard"
                    disableUnderline
                    fullWidth
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="editable-select-input input-style2"
                    placeholder=""
                    value={editDataCo?.telepd_number}
                    onKeyDown={(e) => {
                      if (e.key === "e" || e.key === "E") {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue.length <= 10) {
                        handleChangeCo("telepd_number", inputValue);
                      }
                    }}
                  />
                </Grid>

                <Grid xs={7} item sx={labelStyle}>
                  Who will pick the call
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    // writtable
                    options={[
                      "Self",
                      "Husband",
                      "Wife",
                      "Mother",
                      "Father",
                      "Son",
                      "Doughter",
                      "Sister",
                      "Brother",
                      "Relative",
                      "Friend",
                      "Other",
                    ]}
                    value={editDataCo?.call_picked_by}
                    handleChange={(value) => {
                      handleChangeCo("call_picked_by", value);
                    }}
                  />
                </Grid>

                <Grid xs={7} item sx={labelStyle}>
                  Do you know the Applicant
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    options={["Yes", "No", "Yes, But Doubtful"]}
                    value={editDataCo?.qn_know}
                    handleChange={(value) => {
                      handleChangeCo("qn_know", value);
                    }}
                  />
                </Grid>

                <Grid xs={7} item sx={labelStyle}>
                  Are you aware that you are the co-applicant
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    options={["Yes", "No"]}
                    value={editDataCo?.qn_aware}
                    handleChange={(value) => {
                      handleChangeCo("qn_aware", value);
                    }}
                  />
                </Grid>

                <Grid xs={7} item sx={labelStyle}>
                  Relationship with Applicant
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    writtable
                    options={[
                      "Husband",
                      "Wife",
                      "Mother",
                      "Father",
                      "Son",
                      "Daughter",
                      "Sister",
                      "Brother",
                      "Relative",
                      "Friend",
                      // "Other",
                    ]}
                    value={editDataCo?.relationship}
                    handleChange={(value) => {
                      handleChangeCo("relationship", value);
                    }}
                  />
                </Grid>

                <Grid xs={7} item sx={labelStyle}>
                  Are you aware that if applicant defaults, we'll contact you &
                  you are responsible
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    options={["Yes", "No", "Yes, But Doubtful"]}
                    value={editDataCo?.qn_responsible}
                    handleChange={(value) => {
                      handleChangeCo("qn_responsible", value);
                    }}
                  />
                </Grid>

                <Grid xs={7} item sx={labelStyle}>
                  Alternative Number
                </Grid>
                <Grid xs={5} item>
                  <FilledInput
                    disabled={!isEdit}
                    variant="standard"
                    disableUnderline
                    fullWidth
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="editable-select-input input-style2"
                    placeholder=""
                    value={editDataCo?.alternative_number}
                    onKeyDown={(e) => {
                      if (e.key === "e" || e.key === "E") {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue.length <= 10) {
                        handleChangeCo("alternative_number", inputValue);
                      }
                    }}
                  />
                </Grid>

                {/* ////////////// */}

                <Grid xs={7} item sx={labelStyle}>
                  Phone number age
                </Grid>
                <Grid xs={5} item>
                  <EditableAutoComplete
                    disabled={!isEdit}
                    writtable
                    writeNumber
                    placeholder={"in months"}
                    options={[
                      "2017",
                      "2018",
                      "2019",
                      "2020",
                      "2021",
                      "2022",
                      "2024",
                    ]}
                    writeLabel={"Less that 1 year"}
                    value={editDataCo?.phone_age}
                    handleChange={(value) => {
                      handleChangeCo("phone_age", value);
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <div className="d-flex justify-content-end mt-3">
              <Button
                style={{
                  backgroundColor:
                    triggerData?.[
                      tab === "CUSTOMER" ? "customer" : "co_customer"
                    ] === null ||
                    (
                      triggerData?.[
                        tab === "CUSTOMER" ? "customer" : "co_customer"
                      ] || {}
                    ).length === 0
                      ? "#6E36FF"
                      : "green",
                  color: "white",
                  padding: "5px 20px",
                  textTransform: "none",
                }}
                size="small"
                onClick={() => {
                  if (
                    triggerData?.[
                      tab === "CUSTOMER" ? "customer" : "co_customer"
                    ] === null ||
                    (
                      triggerData?.[
                        tab === "CUSTOMER" ? "customer" : "co_customer"
                      ] || {}
                    ).length === 0
                  ) {
                    setOpenTrigger(true);
                  }
                }}
              >
                {triggerData?.[
                  tab === "CUSTOMER" ? "customer" : "co_customer"
                ] === null ||
                (
                  triggerData?.[
                    tab === "CUSTOMER" ? "customer" : "co_customer"
                  ] || {}
                ).length === 0
                  ? "SEND"
                  : "SENT"}
                <WhatsappIco className={"ms-2"} size={20} />
              </Button>
            </div>
          </div>
        </DialogContent>
        {isEdit && (
          <DialogActions className="justify-content-center">
            <Button
              variant="contained"
              sx={{ fontSize: 12, padding: "5px 15px" }}
              onClick={() => {
                if (tab === "CUSTOMER") {
                  handleSubmit();
                } else {
                  handleSubmitCo();
                }
              }}
            >
              {isUpdate ? "Update" : "submit"}{" "}
              <ArrowForwardIosIcon sx={{ fontSize: 13, ml: 1 }} />
            </Button>
          </DialogActions>
        )}

        {isLoading && (
          <div className="tele-over-loader">
            <CircularProgress />
          </div>
        )}
      </Dialog>

      <Dialog
        open={openTrigger}
        onClose={() => {
          setOpenTrigger(false);
          setTriggerList([]);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div style={{ fontSize: 15 }}>
            This will trigger a WhatsApp message to all the phone numbers of
            this application. Are you sure?
          </div>
        </DialogTitle>
        <DialogContent>
          {loadTrigger === "LIST" && (
            <div className="content-center">
              <CircularProgress />
            </div>
          )}
          {triggerList.length > 0 && loadTrigger === null && (
            <table
              className="table"
              style={{
                width: "100%",
                fontSize: "12px",
                border: "none",
                borderColor: "white",
                marginBottom: "0px",
              }}
            >
              <tbody>
                {triggerList?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {tab === "CUSTOMER"
                        ? updateDetails?.customer_name
                        : updateDetails?.co_app_customer_name}
                    </td>
                    <td>
                      <span
                        style={{
                          background: "#E4E6FF",
                          color: "#4F5AED",
                          padding: "3px 5px",
                          borderRadius: "10px",
                        }}
                      >
                        {item?.type}
                      </span>
                    </td>
                    <td>{item?.number}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loadTrigger === "TRIGGER"}
            onClick={() => {
              setOpenTrigger(false);
              setTriggerList([]);
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              handleTelepdTrigger(false);
            }}
            variant="contained"
            size="small"
            disabled={loadTrigger === "TRIGGER"}
          >
            {loadTrigger === "TRIGGER" ? "Sending..." : "Send"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TelePDModal;
