import React, { forwardRef, useImperativeHandle } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { useState } from "react";
import { useEffect } from "react";
import { dpdDashboardMap } from "../../Constants/dpdDashboard";
import { fetchAllNBFCs, fetchDpdData, fetchNachdashData } from "../../api";
import { toast } from "react-toastify";
import { useContext } from "react";
import { GlobalContext } from "../../Context/GlobalContext";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { cf } from "../../utility/Formater";
import TableCsvDownload from "../../Components/Download/TableCsvDownload";

const DPDDashboard = forwardRef((props, ref) => {
  const { loader, setLoader, style } = props;
  const [level, setLevel] = useState("top");
  const [tillDate, setTillDate] = useState(moment().format("YYYY-MM-DD"));
  const [threshold, setThreshold] = useState("0");
  const [nbfc, setNBFC] = useState([]);
  const [type, setType] = useState("");
  const [tableType, setTableType] = useState("count");
  const [dpdData, setDPDData] = useState({});
  const [totalData, setTotalData] = useState({});
  const [bucketKeys, setBucketKeys] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [nbfcList, setNbfcList] = useState(null);

  async function handleSubmit(threshold, tillDate, level, nbfc) {
    setShowTable(true);
    setLoader("DPDM");
    try {
      setType(level);
      setDPDData({});
      setTotalData([]);

      // Use moment to check if the date is today
      const momentTillDate = moment(tillDate);
      const isToday = momentTillDate.isSame(moment(), "day");

      // If it's today, add the current time
      if (isToday) {
        momentTillDate.set({
          hour: moment().hours(),
          minute: moment().minutes(),
          second: moment().seconds(),
        });
      } else {
        // If it's not today, set the time to 8:00 PM
        momentTillDate.set({
          hour: 20,
          minute: 0,
          second: 0,
        });
      }

      let { data } = await fetchDpdData({
        minimum_due_amount: threshold,
        level,
        nbfc_list: nbfc,
        dynamic_date_ms: momentTillDate.valueOf(),
        ...(tableType === "outstanding_principle"
          ? {
              type: "outstanding_principle",
            }
          : {}),
      });

      const { disbursed_month_array, total } = data?.bucket_data;
      setDPDData(disbursed_month_array || {});
      setTotalData(total || {});
      setBucketKeys(Object.keys(dpdDashboardMap?.[level] || {}));
    } catch (error) {
      toast.error("Error occurred while loading DPD data");
    }
    setLoader(null);
  }

  const fetchNBFCs = async () => {
    try {
      const { data } = await fetchAllNBFCs();

      let list = [];
      let selected = [];
      data?.data?.map((nbfc) => {
        if (
          nbfc?._id !== "" &&
          nbfc?._id !== "EM00004" &&
          nbfc?._id !== "PL00003"
        ) {
          list.push(nbfc);
          selected.push(nbfc?._id);
        }
      });
      setNbfcList(list);
      setNBFC(selected);
    } catch (error) {
      toast.error("Failed to load nbfc list");
    }
  };

  const handleChangeNBFC = (event) => {
    const {
      target: { value },
    } = event;
    setNBFC(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    fetchNBFCs();
  }, []);

  return (
    <>
      <Grid item xs={2}>
        <TextField
          fullWidth
          label="Threshold amount"
          type="text"
          value={threshold}
          variant="outlined"
          InputProps={{
            sx: {
              height: 40,
            },
          }}
          sx={style.selectStyle}
          onChange={(e) => {
            setThreshold(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          label="Till"
          type="date"
          value={tillDate}
          variant="outlined"
          InputProps={{
            sx: {
              height: 40,
            },
          }}
          sx={style.selectStyle}
          onChange={(e) => {
            setTillDate(e.target.value);
          }}
          inputProps={{
            max: new Date().toISOString().split("T")[0],
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-simple-select-label">Level</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={level}
            label="Level"
            sx={style.selectStyle}
            onChange={(event) => {
              setLevel(event.target.value);
            }}
          >
            <MenuItem value={"top"}>Top</MenuItem>
            <MenuItem value={"middle"}>Middle</MenuItem>
            <MenuItem value={"granular"}>Granular</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={2}>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">NBFC</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            label="NBFC"
            value={nbfc}
            onChange={handleChangeNBFC}
            sx={style.selectStyle}
            renderValue={(selected) => {
              if (selected?.length === nbfcList?.length) return "All";
              else return selected?.join(", ");
            }}
          >
            {nbfcList?.map((val) => (
              <MenuItem key={val?._id} value={val?._id}>
                <Checkbox checked={nbfc.indexOf(val?._id) > -1} />
                <ListItemText primary={val?.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={2}>
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tableType}
            label="Type"
            sx={style.selectStyle}
            onChange={(event) => {
              setShowTable(false);
              setTableType(event.target.value);
            }}
          >
            <MenuItem value={"count"}>Count</MenuItem>
            <MenuItem value={"outstanding_principle"}>
              Outstanding principle
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={2}>
        <Button
          style={{
            backgroundColor: "#000",
            color: "#fff",
            paddingLeft: "30px",
            paddingRight: "30px",
            borderRadius: 30,
          }}
          disabled={tillDate === "" || threshold === "" || level === ""}
          onClick={() => {
            handleSubmit(threshold, tillDate, level, nbfc);
            // navigate(
            //   `/dashboard?type=DPD&amount=${threshold}&till=${new Date(
            //     tillDate
            //   ).getTime()}&level=${level}`
            // );
          }}
        >
          Show
        </Button>
      </Grid>

      {showTable && (
        <Grid item xs={12}>
          {loader === null && Object?.keys(dpdData || {})?.length !== 0 && (
            <>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  id={"dpd-dash-table"}
                >
                  <TableHead sx={{ backgroundColor: "#D8E0FF" }}>
                    <TableRow>
                      <TableCell
                        style={{
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                          backgroundColor: "#D8E0FF",
                          maxWidth: 100,
                        }}
                      >
                        Disbursed Month
                      </TableCell>
                      <TableCell align="center">
                        {tableType === "outstanding_principle"
                          ? "Total Principal"
                          : "Total Disbursed"}
                      </TableCell>
                      <TableCell align="center">
                        {tableType === "outstanding_principle"
                          ? "Closed Principal"
                          : "Total Closed"}
                      </TableCell>
                      {bucketKeys.map((key) => (
                        <TableCell
                          key={key}
                          align="center"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {dpdDashboardMap?.[type]?.[key]}
                        </TableCell>
                      ))}
                      <TableCell align="center">
                        <b>Total</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(dpdData || {})?.map((dateKey) => {
                      let total = dpdData?.[dateKey]?.total || "";
                      return (
                        <>
                          <TableRow key={dateKey}>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                minWidth: 80,
                                position: "sticky",
                                left: 0,
                                zIndex: 1,
                                backgroundColor: "white",
                                maxWidth: 100,
                              }}
                            >
                              {dateKey}
                            </TableCell>

                            <TableCell align="center">
                              {tableType === "outstanding_principle"
                                ? cf(dpdData?.[dateKey]?.disbursed_count, true)
                                : dpdData?.[dateKey]?.disbursed_count}
                            </TableCell>
                            <TableCell align="center">
                              {tableType === "outstanding_principle"
                                ? cf(dpdData?.[dateKey]?.closed_count, true)
                                : dpdData?.[dateKey]?.closed_count}
                            </TableCell>

                            {bucketKeys.map((bucketKey) => {
                              let value = dpdData?.[dateKey]?.[bucketKey] || "";
                              value =
                                tableType === "outstanding_principle" &&
                                value !== ""
                                  ? cf(value, true)
                                  : value;
                              return (
                                <TableCell
                                  key={bucketKey}
                                  align="center"
                                  style={{
                                    ...(value > 0 ? { cursor: "pointer" } : {}),
                                  }}
                                >
                                  {value}
                                </TableCell>
                              );
                            })}
                            <TableCell align="center">
                              <b>
                                {tableType === "outstanding_principle" &&
                                total !== ""
                                  ? cf(total, true)
                                  : total}
                              </b>
                              <span style={{ color: "gray", marginLeft: 10 }}>
                                ({dpdData?.[dateKey]?.total_percentage})
                              </span>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Total</b>
                      </TableCell>

                      <TableCell align="center">
                        <b>
                          {tableType === "outstanding_principle"
                            ? cf(totalData?.data_total_disbursed, true)
                            : totalData?.data_total_disbursed}
                        </b>
                      </TableCell>

                      <TableCell align="center">
                        <b>
                          {tableType === "outstanding_principle"
                            ? cf(totalData?.data_total_closed, true)
                            : totalData?.data_total_closed}
                        </b>
                      </TableCell>

                      {bucketKeys.map((bucketKey) => {
                        let value =
                          totalData?.[`data_${bucketKey}_total`] || "";
                        value =
                          tableType === "outstanding_principle" && value !== ""
                            ? cf(value, true)
                            : value;
                        return (
                          <TableCell key={"total_" + bucketKey} align="center">
                            <b>{value}</b>
                          </TableCell>
                        );
                      })}

                      <TableCell align="center">
                        <b>
                          {tableType === "outstanding_principle" &&
                          totalData?.data_total
                            ? cf(totalData?.data_total, true)
                            : totalData?.data_total}
                        </b>
                        <span style={{ color: "gray", marginLeft: 10 }}>
                          ({totalData?.data_total_disbursed_percentage})
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableCsvDownload
                tableId={"dpd-dash-table"}
                filename={"dpd-dash-data"}
                topRow={
                  tillDate
                    ? `As of,${moment(new Date(tillDate)).format("DD/MM/YYYY")}\n\n`
                    : ""
                }
              />
            </>
          )}

          {loader === "DPDM" && (
            <p
              className="text-center w-100 mt-5"
              style={{
                fontSize: "15px",
                color: "black",
              }}
            >
              <CircularProgress size={50} />
            </p>
          )}
          {loader === null && Object?.keys(dpdData)?.length === 0 && (
            <p
              className="text-center w-100 mt-5"
              style={{
                fontSize: "15px",
                color: "black",
              }}
            >
              <DoNotDisturbIcon sx={{ fontSize: 15, mr: 0.3 }} /> No Data to
              display
            </p>
          )}
        </Grid>
      )}
    </>
  );
});

export default DPDDashboard;
