import {
  Table,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  Button,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getStaticPoolDashboardData } from "../../api";
import CustomTable from "../../Components/CustomTable/CustomTable";
import TableCsvDownload from "../../Components/Download/TableCsvDownload";
import moment from "moment";

const StaticPoolDashboard = ({ active, type }) => {
  const [loading, setLoading] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  const [timestamps, setTimestamps] = React.useState([]);
  const [dashboard, setDashboard] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [updateTime, setUpdateTime] = useState(null);
  const [totalDataByTimestamp, setTotalDataByTimestamp] = useState({
    totalCountDataByTimestamp: null,
    totalOSDataByTimestamp: null,
  });

  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      const { data } = await getStaticPoolDashboardData(
        "json=true&dashboard_type=count"
      );
      setTimestamps(data?.timestamps);
      setTotalDataByTimestamp({
        totalCountDataByTimestamp: data?.totalCountByTimestamp,
        totalOSDataByTimestamp: data?.totalOSByTimestamp,
      });
      data?.data.push({
        disbursed_month: "",
        disbursed_application: null,
        disbursed_amount: null,
        application_count_timestamp: {},
        total_outstanding_timestamps: {},
        totalCountDataByTimestamp: data?.totalCountByTimestamp,
        totalOSDataByTimestamp: data?.totalOSByTimestamp,
      });
      setUpdateTime(
        moment(data?.updatedAt).format("DD-MM-YYYY") ||
          moment().format("DD-MM-YYYY")
      );
      setTableData(data?.data);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
    if (type != null) {
      setShowTable(true);
    } else {
      setShowTable(false);
    }
    setDashboard(type);
  }, []);

  let columns = [
    {
      label: "Disbursed Month",
      value: "disbursed_month",
      render: (i) => {
        return tableData?.[i]?.disbursed_month;
        // return statusChipFunc(tableData[i]);
      },
      width: 120,
      left: 0,
      freeze: true,
    },
    {
      label: "Disbursed Cases",
      value: "disbursed_cases",
      render: (i) => {
        return tableData?.[i]?.disbursed_application || " ";
        // return statusChipFunc(tableData[i]);
      },
      width: 120,
      left: 140,
      freeze: true,
    },
    {
      label: "Loan Amount",
      value: "loan_amount",
      render: (i) => {
        return tableData?.[i]?.disbursed_amount ? (
          <>₹{tableData?.[i]?.disbursed_amount?.toLocaleString("en-IN")}</>
        ) : (
          <>
            <b>Total</b>
          </>
        );
        // return statusChipFunc(tableData[i]);
      },
      width: 120,
      left: 280,
      freeze: true,
    },
  ];

  timestamps?.map((ele) => {
    // data?.final_raw_data?
    let obj = {};
    if (dashboard == "Count") {
      obj = {
        label: `${new Date(ele).toLocaleDateString("en-US")}`,
        value: "loan_amount",
        render: (i) => {
          return (
            <>
              {i == tableData.length - 1 ? (
                <>{totalDataByTimestamp.totalCountDataByTimestamp[ele]}</>
              ) : (
                <>
                  <br></br>
                  {tableData?.[i]?.application_count_timestamp[ele] || ""}
                </>
              )}
            </>
          );
        },
        width: 100,
        left: 65,
      };
    } else {
      obj = {
        label: `${new Date(ele).toLocaleDateString("en-US")}`,
        value: "loan_amount",
        render: (i) => (
          <>
            {i == tableData.length - 1 ? (
              <>
                ₹
                {totalDataByTimestamp.totalOSDataByTimestamp[
                  ele
                ].toLocaleString("en-IN")}
              </>
            ) : (
              <>
                {tableData?.[i]?.total_outstanding_timestamps?.[ele]
                  ? `₹${tableData?.[i]?.total_outstanding_timestamps?.[
                      ele
                    ].toLocaleString("en-IN")}`
                  : ""}
              </>
            )}
          </>
        ),
        width: 120,
        left: 65,
      };
    }

    columns.push(obj);
  });

  const styles = {
    loading_container: {
      height: "20vh",
      width: "70vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <>
      {!loading && tableData && showTable ? (
        <>
          <CustomTable
            id={"static-pool-table"}
            tableData={tableData}
            columns={columns}
            table={"StaticPool"}
            total={totalDataByTimestamp}
            timestamp={timestamps}
            dashboard_type={dashboard}
            noPagination
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <TableCsvDownload
              tableId={"static-pool-table"}
              filename={"static-pool-data"}
            />
            <p style={{ fontSize: "13px", marginTop: "14px" }}>
              last updated At:<strong>{updateTime}</strong>
            </p>
          </div>
        </>
      ) : (
        <div style={styles?.loading_container}>
          <CircularProgress color="inherit" />
        </div>
      )}
    </>
  );
};

export default StaticPoolDashboard;
