import {
  Button,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import "./style.css";

const EditableAutoComplete = ({
  autoStyle,
  inputStyle,
  writtable,
  options,
  handleChange,
  value,
  writeLabel,
  disabled,
  writeNumber,
  placeholder
}) => {
  const [isType, setType] = useState(false);
  const [selected, setSelected] = useState(null);
  const [openSelect, setOpenSelect] = useState(false);

  const handleValueChange = (e) => {
    setSelected(e.target.value);
    if (handleChange) {
      handleChange(e.target.value);
    }
  };

  const handleNumberChange = (e) => {
    
    let newValue = e.target.value.replace(/[^0-9]/g, "");
    
    // Limit the length to 10 characters
    if (newValue.length > 10) {
      newValue = newValue.substring(0, 10);
    }
    
    setSelected(newValue);
    if (handleChange) {
      handleChange(newValue);
    }
  };

  const handleDefaultType = () => {
    if (
      !["", null, undefined]?.includes(value) &&
      !(options || [])?.includes(value)
    ) {
      setType(true);
    }
  };

  useEffect(() => {
    setSelected(value);
    if (writtable) {
      handleDefaultType();
    }
  }, [value, writtable]);

  return (
    <div>
      <FormControl fullWidth>
        {!isType ? (
          <Select
            variant="standard"
            disabled={disabled}
            disableUnderline
            value={selected}
            onChange={handleValueChange}
            onClick={() => {
              if (!disabled) {
                setOpenSelect(!openSelect);
              }
            }}
            inputProps={{ "aria-label": "Without label" }}
            open={openSelect}
            classes={{
              select: "editable-drop-select",
            }}
            inputProps={{
              className: "editable-drop-input",
            }}
          >
            {options?.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
            {writtable && (
              <MenuItem
                value=""
                onClick={() => {
                  setType(true);
                }}
              >
                {writeLabel ? (
                  writeLabel
                ) : (
                  <>
                    Write <CreateOutlinedIcon sx={{ fontSize: 15, ml: 1 }} />
                  </>
                )}
              </MenuItem>
            )}
          </Select>
        ) : (
          <FilledInput
            disableUnderline
            id="outlined-adornment-weight"
            sx={{
              paddingRight: "4px",
            }}
            value={selected}
            disabled={disabled}
            placeholder={placeholder ? placeholder : ""}
            autoFocus={true}
            onChange={writeNumber ? handleNumberChange : handleValueChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => {
                    setSelected(null);
                    setType(false);
                    setOpenSelect(true);
                  }}
                  disabled={disabled}
                >
                  <CloseIcon sx={{ fontSize: 18 }} />
                </IconButton>
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            className="editable-select-input"
          />
        )}
      </FormControl>
    </div>
  );
};

export default EditableAutoComplete;
