import { createOemV2, deleteOem, updateOemDetails } from "../api";

import React, { useState, useEffect, useContext } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, CssBaseline, Grid, Box, StepContent } from "@mui/material";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import useAuth from "../hooks/useAuth";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import { GlobalContext } from "../Context/GlobalContext";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BasicDetails from "./CreateOemSteps/BasicDetails";
import Documents from "./CreateOemSteps/Document";
import ContactDetails from "./CreateOemSteps/Contact";
import VehicleAndDealer from "./CreateOemSteps/VehicleAndDealer";
import DeletePopup from "../Components/DeletePopup";
import useOem from "../hooks/useOem";
import LoadingOver from "../Components/Loading";
import { UserContext } from "../Context/UserContext";
import { downloadAll } from "../utility/download";

// const theme = createTheme();
const theme = createTheme();
const steps = [
  "Basic Details",
  "Documents",
  "Contact Details",
  "Vehicle and Dealer Details",
];
function getStepContent(
  step,
  setActiveStep,
  handleLoading,
  isDisabled,
  isUpdate
) {
  switch (step) {
    case 0:
      return (
        <BasicDetails setActiveStep={setActiveStep} isDisabled={isDisabled} />
      );
    case 1:
      return <Documents isDisabled={isDisabled} isDownloadAble={isUpdate} />;
    case 2:
      return <ContactDetails isDisabled={isDisabled} />;
    case 3:
      return (
        <VehicleAndDealer isDisabled={isDisabled} isDownloadAble={isUpdate} />
      );
    default:
      throw new Error("Unknown step");
  }
}

function OemDetailsV2({ isUpdate }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  // const { createAnchor } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const {
    fetchOemDetails,
    buildCreateOemPayload,
    buildUpdateOemPayload,
    getDocArr,
  } = useOem();

  const { oemState, emailState, panData } = useContext(GlobalContext);
  const { user, setUser } = useContext(UserContext);

  const [oemDetails, setOemDetails] = oemState;
  const [isEmailVerified, setIsEmailVerified] = emailState;
  const [apiLoading, setApiLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [confDltPopup, setConfDltPopup] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);
  const [initOemDetails, setInitOemDetails] = useState({});

  const { oemId } = useParams();

  const handleNext = () => {
    if (activeStep === 3) {
      handleCreateOem();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  async function handleDelete() {
    try {
      setConfDltPopup(false);
      setLoading(true);
      const data = await deleteOem(oemId);
      toast(data?.message || "deleted successfully !!!");
      navigate(`/partners`);
    } catch (error) {
      toast?.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  function handleLoading(open, text = "") {
    setLoading(open);
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleCreateOem = async () => {
    try {
      setLoading(true);
      let payload = buildCreateOemPayload(initOemDetails);
      let response = await createOemV2(payload);
      if (!response?.status) {
        toast.error(
          response?.response?.data?.message || "Something went wrong"
        );
        return;
      }
      toast.success(response?.data?.message || "Oem Created Successfully");
      navigate(`/partners`);
    } catch (error) {
      toast?.error(error?.response?.data?.message || "failed to create OEM");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateOem = async () => {
    try {
      setLoading(true);
      let payload = { oem_id: oemId, ...buildUpdateOemPayload(initOemDetails) };
      let response = await updateOemDetails(payload);
      if (!response?.status) {
        toast.error(
          response?.data?.message || "failed to update OEM"
        );
        return;
      }
      await fetchOemDetails(oemId,setLoading,setInitOemDetails);
      toast.success(response?.data?.message || "Oem Created Successfully");
    } catch (error) {
      toast?.error(error?.response?.data?.message || "failed to update oem");
    } finally {
      setLoading(false);
      setIsDisabled(true);
    }
  };

  function handleSubmit() {
    if (isUpdate) {
      handleUpdateOem();
    } else {
      handleCreateOem();
    }
  }

  function getDocName() {
    let lastName = activeStep === 1 ? "documents" : "dealer_dump";
    return `${oemId}_${lastName}`;
  }

  useEffect(() => {
    if (isUpdate) {
      setIsDisabled(true);
      fetchOemDetails(oemId, setLoading, setInitOemDetails);
    }
  }, [isUpdate]);

  useEffect(() => {
    return () => {
      setOemDetails({
        brand_name: "",
        company_name: "",
        email: "",
        website_link: "",
        firm_type: "",
        gstin: "",
        addresses: [{ address_type: "", pincode: "", address: "" }],
        contact: [{ name: "", mobile_number: "", email: "", position: "" }],
        documents: [],
        vehicle_details: [
          {
            ex_showroom_price: 0,
            vehicle_model: "",
          },
        ],
      });
    };
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="update-anchor-wrapper">
          <div className="update-anchor-sidebar">
            <div
              style={{
                cursor: "pointer",
                marginBottom: 30,
              }}
              onClick={() => {
                if (state?.path) {
                  navigate(state?.path);
                } else {
                  navigate("/partners");
                }
              }}
            >
              <ArrowBackIcon /> Go back Home
            </div>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step}>
                  <StepLabel>{step}</StepLabel>
                  <StepContent>
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                        {index !== steps.length - 1 && (
                          <Button
                            variant="outlined"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1, borderRadius: 30 }}
                          >
                            Next
                          </Button>
                        )}
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            <Button
              className="mb-5 anchor-update-btn"
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              disabled={(() => {
                if (isLoading) return true;
                if (isDisabled) return true;
                if (
                  oemDetails?.brand_name === "" ||
                  oemDetails?.company_name === "" ||
                  oemDetails?.firm_type === "" ||
                  oemDetails?.addresses[0]?.address_type === "" ||
                  oemDetails?.addresses[0]?.address === "" ||
                  oemDetails?.addresses[0]?.pincode === ""
                )
                  return true;
                return false;
              })()}
            >
              {isLoading ? "Loading" : isUpdate ? "Update" : "Submit"}
            </Button>
          </div>

          <div className="section">
            {!apiLoading && (
              <div className="steps-holder mb-5">
                <div className="">
                  <Grid
                    container
                    spacing={2}
                    className="pb-3"
                    style={{
                      width: "100%",
                      backgroundColor: "#fff",
                      zIndex: 5,
                      position: "sticky",
                      top: -25,
                    }}
                  >
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h5
                          style={{
                            color: "#2C71C2",
                          }}
                        >
                          {`${activeStep !== 3 ? "OEM " : ""}${
                            steps[activeStep]
                          }`}
                        </h5>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3,1fr)",
                            width: "500px",
                          }}
                        >
                          <div>
                            {[1, 3].includes(activeStep) &&
                              isUpdate &&
                              isDisabled && (
                                <Button
                                  style={{
                                    textTransform: "capitalize",
                                    margin: "10px 10px",
                                  }}
                                  onClick={() => {
                                    let arr = getDocArr(activeStep);
                                    if (arr.length === 0) {
                                      toast.error("no documents exist !!!");
                                      return;
                                    }
                                    setTotalPages(arr?.length || 0);
                                    downloadAll(
                                      arr,
                                      getDocName(),
                                      {
                                        user,
                                        setUser,
                                      },
                                      setDownloadProgress,
                                      setIsDownloadingAll
                                    );
                                  }}
                                >
                                  <FileDownloadIcon
                                    style={{
                                      fontSize: 20,
                                      marginRight: 5,
                                    }}
                                  />{" "}
                                  Download All
                                </Button>
                              )}
                          </div>
                          <div>
                            {isUpdate && (
                              <Button
                                style={{
                                  textTransform: "capitalize",
                                  margin: "10px 10px",
                                }}
                                onClick={() => {
                                  if (!isDisabled) {
                                    fetchOemDetails(oemId, setLoading,setInitOemDetails);
                                  }
                                  setIsDisabled((prev) => !prev);
                                }}
                                color={isDisabled ? "primary" : "error"}
                              >
                                {isDisabled ? (
                                  <EditSharpIcon
                                    style={{
                                      fontSize: 18,
                                      marginRight: 5,
                                    }}
                                  />
                                ) : (
                                  <CloseSharpIcon
                                    style={{
                                      fontSize: 18,
                                      marginRight: 5,
                                    }}
                                  />
                                )}{" "}
                                Edit OEM
                              </Button>
                            )}
                          </div>
                          <div>
                            {isDisabled && isUpdate && (
                              <Button
                                style={{
                                  textTransform: "capitalize",
                                  margin: "10px 10px",
                                }}
                                onClick={() => {
                                  setConfDltPopup(true);
                                }}
                                color={"error"}
                              >
                                <DeleteIcon
                                  style={{
                                    fontSize: 18,
                                    marginRight: 5,
                                  }}
                                />
                                Delete OEM
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      {getStepContent(
                        activeStep,
                        setActiveStep,
                        handleLoading,
                        isDisabled,
                        isUpdate
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div style={{ marginBottom: 50 }}>&nbsp;</div>
              </div>
            )}
          </div>
        </div>
      </ThemeProvider>
      <DeletePopup
        confDltPopup={confDltPopup}
        setConfDltPopup={setConfDltPopup}
        handleDeleteDoc={handleDelete}
        text={"are you sure , you want to delete this OEM ?"}
      />
      {isLoading && (
        <LoadingOver text={`${isUpdate ? "updating" : "Creating"} OEM...`} />
      )}
      {isDownloadingAll && (
        <LoadingOver
          text={`Downloading ${downloadProgress + 1}/${totalPages}`}
        />
      )}
    </>
  );
}

export default OemDetailsV2;
