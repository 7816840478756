import {
  Button,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CustomTableV2 from "../../Components/CustomTable/CustomTableV2";
import { capitalize } from "../../utility/Formater";
import {
  useLocation,
  useSearchParams,
} from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  fetchWACountCollection,
} from "../../api";
import CommunicationHistory from "../../Components/Dialogs/CommunicationHistory";
import { UserContext } from "../../Context/UserContext";

const CallingDashboardByEvent = ({ messagingData, onClose }) => {
    const [tableData, setTableData] = useState(messagingData);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { state } = useLocation();
    const [communicationDialog, setCommunicationDialog] = useState(false);
    const [tab, setTab] = useState(0);
    const [callCount, setCallCount] = useState({});
    const [waMsgCount, setWaMsgCount] = useState({});
    const [communicationData, setCommunicationData] = useState({
      tab: 0,
      callingData: {},
      chatData: {},
    });
    const { user } = useContext(UserContext);

    const formatTimeDifference = (seconds) => {
      const days = Math.floor(seconds / (60 * 60 * 24));
      const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
      const mins = Math.floor((seconds % (60 * 60)) / 60);
      const secs = Math.floor(seconds % 60);
    
      let result = "";
      if (days > 0) {
        result += `${days}d `;
      }
      if (hours > 0) {
        result += `${hours}h `;
      }
      if (mins > 0) {
        result += `${mins}m `;
      }
      if (secs > 0 || result === "") {
        result += `${secs}s`;
      }
    
      return result.trim();
    };

    function getDifferenceInMinutes(fromTime, tillTime) {
      const fromTimeDate = new Date(fromTime);
      const tillTimeDate = new Date(tillTime);
      const diffSentDelivered = (fromTimeDate - tillTimeDate) / (1000);    
      return formatTimeDifference(diffSentDelivered);
    };

    const handleGetMessageCount = async (mobile_number) => {
      try {
        const { data } = await fetchWACountCollection();
        setWaMsgCount(data?.data || {});
        setCallCount(data?.call_data || {});
        let mobile = mobile_number;
        if (mobile) {
          const mobile_number = mobile;
          const sent_count =
            (data?.call_data || {})?.[mobile_number]?.completed_call_count || 0;
          const not_answered =
            (data?.call_data || {})?.[mobile_number]?.no_answer_call_count || 0;
          const total_call_count = sent_count + not_answered;
  
          setCommunicationData({
            ...communicationData,
            callingData: {
              ...communicationData?.callingData,
              open: true,
              application_id: state?.application_id,
              picked_call_count: sent_count,
              declined_busy_count: not_answered,
              total_count: total_call_count,
            },
            chatData: {
              open: true,
              mobile_number,
              customer_name: "Loading",
            },
          });
        }
      } catch (error) {
        // toast.error(error?.response?.data?.message);
      }
    };

    useEffect(() => {
      localStorage.removeItem("filterDataCollectionV2");
      handleGetMessageCount();
    }, []);

    useEffect(() => {
      let mobile = searchParams.get("mobile");
      if (mobile) {
        setCommunicationData({
          tab: state?.application_id,
          callingData: {
            open: true,
            application_id: state?.application_id,
            picked_call_count: "Loading",
            declined_busy_count: "Loading",
            total_count: "Loading",
          },
          chatData: {
            open: true,
            mobile_number: mobile,
            customer_name: "Loading",
          },
        });
        setCommunicationDialog(true);
        setTab(state?.tab);
      }
    }, [searchParams]);
    const columns_messaging = [
        {
          label: <span className="text-nowrap" >Template Name</span>,
          render: (index) => {
            let temp_name = tableData[index]?.event_name;
            return <span key={temp_name}>{capitalize(temp_name, "_")}</span>;
          },
          width: 200,
        },
        {
          label: <span className="text-nowrap">Recipient Name</span>,
          render: (index) => {
            let cust_name = tableData[index]?.customer_name;
            return <span>{cust_name}</span>;
          },
          width: 200,
        },
        {
          label: <span className="text-nowrap" >Recipient Id</span>,
          render: (index) => {
            return tableData[index]?.application_id;
          },
          width: 200,
        },
        {
          label: <span className="text-nowrap" >Mobile Number</span>,
          render: (index) => {
            return tableData[index]?.mobile_number;
          },
          width: 200,
        },
        !user?.isUserPresenter && {
          label: <span className="text-nowrap" >Undelivered Time</span>,
          render: (index) => {
            return tableData[index]?.undeleredTime;
          },
          width: 350,
        },
        {
          label: <span className="text-nowrap" >Sent Time</span>,
          render: (index) => {
            return tableData[index]?.sentTime;
          },
          width: 350,
        },
        {
          label: <span className="text-nowrap" >Delivered Time</span>,
          render: (index) => {
            let timeDiff = getDifferenceInMinutes(tableData[index]?.deliveredTime, tableData[index]?.sentTime);
            let time =  tableData[index]?.deliveredTime;
            const style = {
              color: "white",
              fontSize: 10,
              borderRadius: 30,
              padding: "2px 5px",
            };
            return (
              <div className="d-flex-column justify-content-center align-items-center">
                <span style={{ marginBottom: "10px", display: "block" }}>{time}</span>
                {(time !== "-") ? (
                  <span className="text-nowrap"
                    style={{
                      ...style,
                      background: "green",
                      display: "block",
                    }}
                  >
                    <ArrowUpwardIcon sx={{ fontSize: 10, alignContent: "justify" }} />
                    {timeDiff}
                  </span>
                ) : (
                  <span style={{ display: "block" }}></span>
                )}
              </div>
            );
          },
          width: 350,
        },
        {
          label: <span className="text-nowrap" >Read Time</span>,
          render: (index) => {
            let timeDiff = getDifferenceInMinutes(tableData[index]?.readTime, tableData[index]?.deliveredTime);
            let time =  tableData[index]?.readTime;
            const style = {
              color: "white",
              fontSize: 10,
              borderRadius: 30,
              padding: "2px 5px",
            };
            return (
              <div className="d-flex-column justify-content-center align-items-center">
                <span style={{ marginBottom: "10px", display: "block" }}>{time}</span>
                {(time !== "-") ? (
                  <span className="text-nowrap"
                    style={{
                      ...style,
                      background: "green",
                      display: "block",
                    }}
                  >
                    <ArrowUpwardIcon sx={{ fontSize: 10, alignContent: "justify" }} />
                    {timeDiff}
                  </span>
                ) : (
                  <span style={{ display: "block" }}></span>
                )}
              </div>
            );  
          },
          width: 350,
        },
        {
          label: <span className="text-nowrap" >Reply Time</span>,
          render: (index) => {
            let timeDiff = getDifferenceInMinutes(tableData[index]?.replyTime, tableData[index]?.readTime);
            let time =  tableData[index]?.replyTime;
            const style = {
              color: "white",
              fontSize: 10,
              borderRadius: 30,
              padding: "2px 5px",
            };
            return (
              <div className="d-flex-column justify-content-center align-items-center">
                <span style={{ marginBottom: "10px", display: "block" }}>{time}</span>
                {(time !== "-") ? (
                  <span className="text-nowrap"
                    style={{
                      ...style,
                      background: "green",
                      display: "block",
                    }}
                  >
                    <ArrowUpwardIcon sx={{ fontSize: 10, alignContent: "justify" }} />
                    {timeDiff}
                  </span>
                ) : (
                  <span style={{ display: "block" }}></span>
                )}
              </div>
            );
          },
          width: 350,
        },
        {
          label: <span className="text-nowrap" >Reply Message</span>,
          render: (index) => {
            return tableData[index]?.replyMessage;
          },
          width: 200,
        },
        {
            label: <span className="text-nowrap" >Message Type</span>,
            render: (index) => {
              return tableData[index]?.replyMessageType;
            },
            width: 200,
          },
          {
            label: <span className="text-nowrap" >Reply Type</span>,
            render: (index) => {
              return tableData[index]?.replyType;
            },
            width: 200,
          },
          {
            label: <span className="text-nowrap" >Message History</span>,
            render: (i) => {
              const mobile_number = tableData[i]?.mobile_number.slice(2);
              const customer_name =  tableData[i]?.customer_name;
              const sent_call_count =
              callCount?.[mobile_number]?.completed_call_count || 0;
            const not_answered =
              callCount?.[mobile_number]?.no_answer_call_count || 0;
            const total_call_count = sent_call_count + not_answered;
    
            const sent_count = waMsgCount?.[mobile_number]?.sent_count || 0;
            const receive_count = waMsgCount?.[mobile_number]?.receive_count || 0;
            const style = {
              color: "white",
              fontSize: 10,
              borderRadius: 30,
              padding: "2px 5px",
            };
              return (
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    setCommunicationData({
                      callingData: {
                        open: true,
                        application_id: tableData[i]?.application_id,
                        picked_call_count: sent_call_count,
                        declined_busy_count: not_answered,
                        total_count: total_call_count,
                      },
                      chatData: {
                        open: true,
                        mobile_number,
                        customer_name,
                      },
                    });
                    setCommunicationDialog(true);
                    setTab(1);
                  }}
                >
                  <WhatsAppIcon fontSize="small" className="me-2" />
                  <span
                    style={{
                      ...style,
                      marginRight: "2px",
                      background: sent_count === 0 ? "#bdbdbd" : "green",
                    }}
                  >
                    <ArrowUpwardIcon sx={{ fontSize: 10 }} />
                    {sent_count}
                  </span>
                  <span
                    style={{
                      ...style,
                      background: receive_count === 0 ? "#bdbdbd" : "green",
                    }}
                  >
                    <ArrowDownwardIcon sx={{ fontSize: 10 }} />
                    {receive_count}
                  </span>
                </Button>
              );
            },
            width: 140,
          },
          !user?.isUserPresenter && {
            label: <span className="text-nowrap" >Over All Status</span>,
            render: (index) => {
              return tableData[index]?.overAllStatus;
            },
            width: 200,
          },
          !user?.isUserPresenter && {
            label: "Days",
            render: (index) => {
              return tableData[index]?.days;
            },
            width: 200,
          },
          !user?.isUserPresenter && {
            label: "Message",
            render: (index) => {
              return tableData[index]?.message;
            },
            width: 200,
          },
      ].filter(Boolean);

      const columns = columns_messaging;
      const data = tableData;
    return (
      <div>
        <div style={{ width: "100%" }}>
          <CustomTableV2
            tableData={data}
            maxHeight={"60dvh"}
            columns={columns}
            loadingRows={8}
            isLoading={loading}
            borderBottom={true}
          />
        </div>
        <Button onClick={onClose}>Close</Button>
        {/* Render the detailed table */}

            <CommunicationHistory
        modal={communicationDialog}
        handleClose={() => setCommunicationDialog(false)}
        tab={tab}
        changeTab={() => {
          setTab(tab === 1 ? 2 : 1);
        }}
        chatData={communicationData.chatData}
        callData={communicationData.callingData}
        commData={communicationData}
      />
      </div>
    );
  };

  export default CallingDashboardByEvent;