import { DownloadOutlined } from "@mui/icons-material";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  ListItemText,
  OutlinedInput,
  Slide,
  TextField,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchFileStatus,
  fetchNachdashData,
  fetchNachdashTransData,
  fetchProfileData,
  fetchRCdashData,
  fetchSanctionLoanCount,
  getHoldBackDashData,
} from "../api";
import ProfileFilter from "../Components/ProfileFilter";
import RCNachFilter from "../Components/RCFilter";
import CallingDashboard from "../Pages/Dashboard/CallingDashboard";
import DisbursedDataTable from "../Pages/Dashboard/DisbursedDataTable";
import DPDDashboard from "../Pages/Dashboard/DPDDashboard";
import DPDDashboardMoment from "../Pages/Dashboard/DPDDashboardMoment";
import FileStatusTable from "../Pages/Dashboard/FileStatusTable";
import HoldbackDashboard from "../Pages/Dashboard/HoldbackDashboard";
import NachDashboardTrans from "../Pages/Dashboard/NachDashboardTrans";
import NachDashboardV2 from "../Pages/Dashboard/NachDashboardV2";
import OSDashboard from "../Pages/Dashboard/OSDashboard";
import ProfilingDashboard from "../Pages/Dashboard/ProfilingDashboard";
import RCDashboard from "../Pages/Dashboard/RCDashboard";
import SanctionLoanTable from "../Pages/Dashboard/SanctionLoanTable";
import StaticPoolDashboard from "../Pages/Dashboard/StaticPoolDashboard";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const dashboardType = {
  Count: "Count",
  "Outstanding Amount": "Outstanding Amount",
};

const StatsDashboardPopup = ({ open, handleClose }) => {
  const [loader, setLoader] = useState(null);
  const navigate = useNavigate();
  const [loanType, setLoanType] = useState("ERICKLN");
  const [months, setMonths] = useState(6);
  const [sourceBy, setSourceBy] = useState("Embifi");
  const [showDisbursedTable, setShowDisbursedTable] = useState(false);
  const [activeDetails, setActiveDetails] = useState("DISBURSAL");
  const [fileData, setFileData] = useState(null);
  const [sanctionData, setSanctionData] = useState(null);
  const [loanAgeementData, setLoanAgeementData] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [profileFilter, setProfileFilter] = useState("All");
  const [rcData, setRCData] = useState(null);
  const [nachData, setNachData] = useState(null);
  const [osDate, setOsDate] = useState(moment().format("YYYY-MM-DD"));
  const [level, setLevel] = useState("top");
  const [tillDate, setTillDate] = useState(moment().format("YYYY-MM-DD"));
  const [threshold, setThreshold] = useState("0");
  const [nbfc, setNBFC] = useState([]);
  const [nachDashType, setNachDashType] = useState("REGISTERED");
  const [dashboard, setDashboard] = useState("Count");
  const [showTable, setShowTable] = useState(false);
  const [nbfcData, setNbfcData] = useState([]);
  const [anchorData, setAnchorData] = useState([]);
  const [anchor, setAnchor] = useState([]);
  const [nbfcName, setNbfcName] = useState([]);
  const [holdBackType, setHoldBackType] = useState(null);
  const holdBackData = ["DEALER", "OEM"];
  const [tableData, setTableData] = useState(null);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [tableType, setTableType] = useState();
  const [callingTime, setCallingTime] = useState(null);

  const dpdDashboardRef = useRef(null);

  const style = {
    dashCardHolder1: {
      postion: "relative",
      boxShadow:
        "6px 6px 30px rgba(125, 153, 180, 0.2), 2px 2px 4px rgba(86, 144, 198, 0.11)",
      borderRadius: "10px",
      marginTop: "20px",
      padding: "20px",
      cursor: "pointer",
    },
    dashCardHolder2: {
      postion: "relative",
      boxShadow:
        "6px 6px 30px rgba(125, 153, 180, 0.2), 2px 2px 4px rgba(86, 144, 198, 0.11)",
      borderRadius: "10px",
      marginTop: "20px",
      padding: "20px",
      cursor: "pointer",
      maxWidth: "1000px",
    },
    card: {
      padding: "20px",
      postion: "relative !important",
      borderRight: {
        xs: "none",
        sm: "none",
        md: "2px solid #E6EDFF",
        lg: "2px solid #E6EDFF",
      },
    },
    rightArrow: {
      textAlign: "center",
      backgroundColor: "#fff",
      borderRadius: "12px",
      height: "2em",
      width: "2em",
      boxShadow: "0px 2px 12px rgba(124, 141, 181, 0.12)",
      float: "right",
      top: 0,
    },
    arrowImg: {
      objectFit: "contain",
      width: "30%",
      height: "30%",
    },
    amountTxtsm: { fontSize: "2vh", fontWeight: "500" },
    amountTxt: {
      fontSize: "30px",
      fontWeight: "500",
      "@media(maxWidth: 450px)": {
        fontSize: "",
      },
    },
    desc: {
      color: "gray",
    },

    gridStyle: {
      padding: "20px",
      postion: "relative !important",
      marginLeft: "0px",
      borderRight: {
        xs: "none",
        sm: "2px solid #E6EDFF",
        md: "2px solid #E6EDFF",
        lg: "2px solid #E6EDFF",
      },
      borderBottom: {
        lg: "none",
        md: "none",
        xs: "2px solid #E6EDFF",
        sm: "2px solid #E6EDFF",
      },
    },

    gridStyleLast: {
      borderRight: "none",
      borderBottom: "none",
    },

    dashIco: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
      borderRadius: "18px",
      color: "white",
      marginRight: 15,
      height: 50,
      width: 50,
    },
    selectStyle: {
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "black",
        borderRadius: 4,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "black",
      },
    },
  };

  const fetchFileStatusData = async (money = false) => {
    try {
      setLoader("FILE");
      const { data } = await fetchFileStatus();
      setFileData(data);
    } catch (error) {
      toast.error(error?.response?.data?.message || "File status API Failed");
    }
    setLoader(null);
  };

  const fetchRCData = async (months = 6) => {
    try {
      setLoader("RC");
      const { data } = await fetchRCdashData(`?months=${months}`);
      setRCData(data?.data || []);
    } catch (error) {
      toast.error(error?.response?.data?.message || "RC Data API Failed");
    }
    setLoader(null);
  };

  const fetchNachData = async () => {
    try {
      setLoader("NACH");
      let params = `?months=${months}&nbfc_ids=${encodeURIComponent(
        JSON.stringify(nbfc).toString()
      )}`;

      const { data } =
        nachDashType === "REGISTERED"
          ? await fetchNachdashData(params)
          : await fetchNachdashTransData(params);

      setNachData(data?.finalData || []);
    } catch (error) {
      toast.error(error?.response?.data?.message || "NACH Data API Failed");
    }
    setLoader(null);
  };

  const handleDownloadRCSheet = async () => {
    try {
      setLoader("RCDOWNLOAD");
      const { data } = await fetchRCdashData(`?download=true`);

      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `RC-dashboard-data(${moment().format("DD-MM-YYYY")}).csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(error?.response?.data?.message || "RC Data download Failed");
    }
    setLoader(null);
  };

  const fetchSmsData = async (type, download = false) => {
    try {
      setLoader(download ? "DOWNLOAD_CSV" : type);
      let params = "?";
      if (type === "LOAN_AGREEMENT") params = params + `&type=loan_agreement`;
      if (download) params = params + "&download=true";

      const { data } = await fetchSanctionLoanCount(params);

      if (download) {
        const blob = new Blob([data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${type}_SEND_PENDING.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
      } else if (type === "SANCTION") setSanctionData(data?.data);
      else if (type === "LOAN_AGREEMENT") setLoanAgeementData(data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || "SMS status api Failed");
    }
    setLoader(null);
  };

  const fetchHoldBackDashboard = async (
    page,
    limit,
    nbfc = [],
    anchor = [],
    hold_back_type = ""
  ) => {
    let param = "";
    let filter = {};
    if (page) {
      param += `&page=${page}`;
    }
    if (limit) {
      param += `&limit=${limit}`;
    }
    if (nbfc.length) {
      filter["nbfcs"] = nbfc;
    }
    if (anchor.length) {
      filter["dealers"] = anchor;
    }
    if (hold_back_type) {
      filter["hold_back_type"] = hold_back_type;
    }
    if (Object.keys(filter).length != 0) {
      filter = encodeURIComponent(JSON.stringify(filter).toString());
      param += `&filters=${filter}`;
    }
    const { data } = await getHoldBackDashData(param);
    setTableData(data?.data);
    setTotalCount(data?.count);
  };

  const fetchAnchorNbfcHoldBack = async () => {
    const { data } = await getHoldBackDashData("");

    let tmp_nbfc = [];
    let tmp_anchor = [];
    data?.data.map((ele) => {
      tmp_nbfc.push(ele?.nbfc_name);
      tmp_anchor.push(ele.dealership_name);
    });
    setNbfcData([...new Set(tmp_nbfc)]);
    setAnchorData([...new Set(tmp_anchor)]);
  };

  const fetchProfileDashboard = async (params) => {
    try {
      setLoader("PROFILE");
      const { data } = await fetchProfileData(params || "");
      setProfileData(data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Profile data API Failed");
    }
    setLoader(null);
  };

  const handleChangeNbfc = (event) => {
    setShowTable(false);

    const {
      target: { value },
    } = event;
    setNbfcName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeAnchor = (event) => {
    setShowTable(false);

    const {
      target: { value },
    } = event;
    setAnchor(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeTab = async (type) => {
    setShowDisbursedTable(false);
    setLoanType("ERICKLN");
    setMonths(6);
    setSourceBy("Embifi");
    setActiveDetails(type);
  };

  const handleDPDData = async (threshold, level) => {
    dpdDashboardRef.current.handleSubmit(threshold, level);
  };

  //Use Effect to fetch the holdback data
  useEffect(() => {
    if (activeDetails == "HOLDBACK") {
      fetchAnchorNbfcHoldBack();

      fetchHoldBackDashboard(page, limit);
    }
  }, [activeDetails]);

  useEffect(() => {
    setMonths(6);
  }, [activeDetails]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth="lg"
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Statistics"}</DialogTitle>
        <DialogContent dividers>
          <div style={{ width: 900 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={activeDetails}
                    sx={{
                      height: 50,
                      color: "#6E36FF",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6E36FF",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6E36FF",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6E36FF",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "#6E36FF !important",
                      },
                    }}
                    onChange={(e) => {
                      navigate("/");
                      handleChangeTab(e.target.value);
                      if (e.target.value === "FILE") {
                        if (fileData === null) {
                          fetchFileStatusData();
                        }
                      } else if (e.target.value === "LOAN_AGREEMENT") {
                        fetchSmsData("LOAN_AGREEMENT");
                      } else if (e.target.value === "SANCTION") {
                        fetchSmsData("SANCTION");
                      } else if (e.target.value === "PROFILE") {
                        fetchProfileDashboard();
                      } else if (e.target.value === "RC") {
                        fetchRCData();
                      }
                    }}
                  >
                    <MenuItem value={"DISBURSAL"}>
                      <EqualizerIcon sx={{ mr: 1 }} /> Disbursed Dashboard
                    </MenuItem>

                    {/* <MenuItem value={"MONEY"}>
                        <PaymentsOutlinedIcon sx={{ mr: 1 }} /> Money received
                      </MenuItem>
                    
                    
                      <MenuItem value={"FILE"}>
                        <InsertDriveFileOutlinedIcon sx={{ mr: 1 }} /> File
                        status
                      </MenuItem>
                    
                    
                      <MenuItem value={"LOAN_AGREEMENT"}>
                        <HandshakeOutlinedIcon sx={{ mr: 1 }} /> Loan agreement
                        sms
                      </MenuItem>
                    
                    
                      <MenuItem value={"SANCTION"}>
                        <SmsOutlined sx={{ mr: 1 }} /> Sanction letter sms
                      </MenuItem>
                    
                    <MenuItem value={"PROFILE"}>
                      <ManageAccountsOutlinedIcon sx={{ mr: 1 }} /> Profiling of
                      users
                    </MenuItem>
                    
                      <MenuItem value={"RC"}>
                        <TaskOutlinedIcon sx={{ mr: 1 }} /> RC Dashboard
                      </MenuItem>
                    
                    
                      <MenuItem value={"OS"}>
                        <CurrencyRupeeIcon sx={{ mr: 1 }} /> OS Dashboard
                      </MenuItem>
                    
                    {Number(user?.role) === 1 && (
                      <MenuItem value="DPDM">
                        <ImportExportIcon sx={{ mr: 1 }} /> DPD Movement
                        Dashboard
                      </MenuItem>
                    )}

                    {Number(user?.role) === 1 && (
                      <MenuItem value="DPD">
                        <HourglassBottomOutlinedIcon sx={{ mr: 1 }} /> DPD
                        Dashboard
                      </MenuItem>
                    )}

                    <MenuItem value="NACH">
                      <HiCash style={{ marginRight: 10, fontSize: 22 }} /> Nach
                      Dashboard
                    </MenuItem>
                    <MenuItem value="STATICPOOL">
                      <HiCash style={{ marginRight: 10, fontSize: 22 }} />{" "}
                      Static Pool Dashboard
                    </MenuItem>
                    <MenuItem value="HOLDBACK">
                      <TaskOutlinedIcon
                        style={{ marginRight: 10, fontSize: 22 }}
                      />{" "}
                      Hold Back Dashboard
                    </MenuItem>
                    <MenuItem value="CALLING">
                      <ImportExportIcon sx={{ mr: 1 }} /> Calling / Messaging
                      Dashboard
                    </MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
              {activeDetails === "PROFILE" && (
                <Grid item xs={4}>
                  <ProfileFilter
                    setProfileFilter={setProfileFilter}
                    profileFilter={profileFilter}
                    onApply={(params) => {
                      fetchProfileDashboard(params);
                    }}
                  />
                </Grid>
              )}
              {activeDetails === "STATICPOOL" && (
                <>
                  {/* <Grid item xs={10}> */}
                  <>
                    <FormControl
                      sx={{ width: 200, marginLeft: 5, marginTop: 2.5 }}
                      size="small"
                    >
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        value={dashboard}
                        onChange={(e) => {
                          setShowTable(false);
                          setDashboard(e.target.value);
                        }}
                        input={<OutlinedInput />}
                        renderValue={(selected) => dashboardType?.[selected]}
                        sx={{
                          color: "rgb(110, 54, 255)",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(110, 54, 255)",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(110, 54, 255)",
                            border: 1,
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(110, 54, 255)",
                          },
                          ".MuiSvgIcon-root ": {
                            fill: "rgb(110, 54, 255)",
                          },
                        }}
                      >
                        {Object.keys(dashboardType)?.map((key) => (
                          <MenuItem value={key}>
                            {dashboardType?.[key]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      variant="contained"
                      style={{
                        background: "#6E36FF",
                        boxShadow: "none",
                        maxHeight: "40px",
                        marginTop: 20,
                      }}
                      className="ms-2"
                      onClick={() => {
                        setShowTable(true);
                      }}
                    >
                      Show
                    </Button>
                  </>
                  {/* </Grid> */}
                  {showTable && (
                    <Grid item xs={12}>
                      {/* <div className="d-flex justify-content-between"> */}
                      <StaticPoolDashboard
                        active={activeDetails}
                        type={dashboard}
                      />
                      {/* </div> */}
                    </Grid>
                  )}
                </>
              )}
              {activeDetails === "HOLDBACK" && (
                <>
                  <div>
                    <FormControl
                      sx={{ width: 200, marginLeft: 5, marginTop: 2.5 }}
                      size="small"
                    >
                      <InputLabel id="demo-simple-select-helper-label">
                        NBFC
                      </InputLabel>

                      <Select
                        labelId="demo-customized-name-label"
                        id="demo-multiple-checkbox"
                        label="NBFC"
                        multiple
                        value={nbfcName}
                        onChange={handleChangeNbfc}
                        renderValue={(selected) => selected.join(", ")}
                        sx={{
                          color: "rgb(110, 54, 255)",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(110, 54, 255)",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(110, 54, 255)",
                            border: 1,
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(110, 54, 255)",
                          },
                          ".MuiSvgIcon-root ": {
                            fill: "rgb(110, 54, 255)",
                          },
                        }}
                      >
                        {nbfcData.map((key) => (
                          <MenuItem value={key}>
                            <Checkbox checked={nbfcName.indexOf(key) > -1} />
                            <ListItemText primary={key} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      sx={{ width: 200, marginLeft: 3, marginTop: 2.5 }}
                      size="small"
                    >
                      <InputLabel id="demo-simple-select-helper-label">
                        Dealer
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Dealer"
                        multiple
                        value={anchor}
                        onChange={handleChangeAnchor}
                        renderValue={(selected) => selected.join(", ")}
                        sx={{
                          color: "rgb(110, 54, 255)",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(110, 54, 255)",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(110, 54, 255)",
                            border: 1,
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(110, 54, 255)",
                          },
                          ".MuiSvgIcon-root ": {
                            fill: "rgb(110, 54, 255)",
                          },
                        }}
                      >
                        {anchorData.map((key) => (
                          <MenuItem value={key}>
                            <Checkbox checked={anchor.indexOf(key) > -1} />
                            <ListItemText primary={key} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      sx={{ width: 200, marginLeft: 3, marginTop: 2.5 }}
                      size="small"
                    >
                      <InputLabel id="demo-simple-select-helper-label">
                        Hold Back Type
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        label="Hold Back Type"
                        value={holdBackType}
                        onChange={(e) => {
                          setShowTable(false);
                          // setDashboard(e.target.value);
                          setHoldBackType(e.target.value);
                        }}
                        renderValue={(selected) => [selected]}
                        sx={{
                          maxHeight: "100px",
                          color: "rgb(110, 54, 255)",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(110, 54, 255)",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(110, 54, 255)",
                            border: 1,
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(110, 54, 255)",
                          },
                          ".MuiSvgIcon-root ": {
                            fill: "rgb(110, 54, 255)",
                          },
                        }}
                      >
                        {holdBackData.map((key) => (
                          <MenuItem value={key}>{key}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      variant="contained"
                      style={{
                        background: "#6E36FF",
                        boxShadow: "none",
                        maxHeight: "40px",
                        marginTop: 20,
                      }}
                      className="ms-2"
                      onClick={() => {
                        fetchHoldBackDashboard(
                          page,
                          limit,
                          nbfcName ? nbfcName : null,
                          anchor ? anchor : null,
                          holdBackType ? holdBackType : null
                        );
                        setShowTable(true);
                      }}
                    >
                      Show
                    </Button>
                  </div>
                  {showTable && (
                    <Grid item xs={12}>
                      <div className="d-flex justify-content-between">
                        <HoldbackDashboard
                          active={activeDetails}
                          tableData={tableData}
                          total={totalCount}
                          defaultLimit={limit}
                          onPageChange={(page, limit) => {
                            setPage(page);
                            setLimit(limit);
                            fetchHoldBackDashboard(
                              page,
                              limit,
                              nbfcName ? nbfcName : null,
                              anchor ? anchor : null,
                              holdBackType ? holdBackType : null
                            );
                          }}
                          onLimitChange={(page, limit) => {
                            setPage(page);
                            setLimit(limit);
                            fetchHoldBackDashboard(
                              page,
                              limit,
                              nbfcName ? nbfcName : null,
                              anchor ? anchor : null,
                              holdBackType ? holdBackType : null
                            );
                          }}
                          // type={dashboard}
                        />
                      </div>
                    </Grid>
                  )}
                </>
              )}
              {(activeDetails === "RC" || activeDetails === "NACH") && (
                <>
                  <Grid item xs={8}>
                    <div className="d-flex justify-content-between">
                      <RCNachFilter
                        active={activeDetails}
                        setMonths={setMonths}
                        months={months}
                        nbfc={nbfc}
                        nachDashType={nachDashType}
                        setNachDashType={setNachDashType}
                        setNBFC={setNBFC}
                        onApply={(filter) => {
                          if (activeDetails === "RC") {
                            fetchRCData(filter);
                          } else if (activeDetails === "NACH") {
                            fetchNachData();
                          }
                        }}
                        fetchNachData={fetchNachData}
                        setShowTable={setShowDisbursedTable}
                      />
                      {activeDetails !== "NACH" && (
                        <Button
                          style={{
                            float: "right",
                            marginTop: "10px",
                          }}
                          onClick={() => {
                            handleDownloadRCSheet();
                          }}
                        >
                          {loader === "RCDOWNLOAD" ? (
                            <>Downloading...</>
                          ) : (
                            <>
                              <DownloadOutlined /> Download
                            </>
                          )}
                        </Button>
                      )}
                    </div>
                  </Grid>
                </>
              )}
              {activeDetails === "CALLING" && (
                <>
                  <FormControl
                    sx={{ width: 200, marginLeft: 5, marginTop: 2.5 }}
                    size="small"
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      Type
                    </InputLabel>

                    <Select
                      labelId="demo-customized-name-label"
                      id="demo-multiple-checkbox"
                      label="NBFC"
                      value={tableType}
                      onChange={(event) => {
                        setTableType(event.target.value);
                      }}
                      sx={{
                        color: "rgb(110, 54, 255)",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgb(110, 54, 255)",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgb(110, 54, 255)",
                          border: 1,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgb(110, 54, 255)",
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "rgb(110, 54, 255)",
                        },
                      }}
                    >
                      <MenuItem value={"calling"}>Calling</MenuItem>
                      <MenuItem value={"messaging"}>Messaging</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: 200, marginLeft: 5, marginTop: 2.5 }}
                    size="small"
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      Date
                    </InputLabel>

                    <Select
                      labelId="demo-customized-name-label"
                      id="demo-multiple-checkbox"
                      label="Date"
                      value={callingTime}
                      onChange={(event) => {
                        setCallingTime(event.target.value);
                      }}
                      sx={{
                        color: "rgb(110, 54, 255)",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgb(110, 54, 255)",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgb(110, 54, 255)",
                          border: 1,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgb(110, 54, 255)",
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "rgb(110, 54, 255)",
                        },
                      }}
                    >
                      <MenuItem value={"today"}>Today</MenuItem>
                      <MenuItem value={"last_seven_days"}>
                        Last Seven Days
                      </MenuItem>
                      <MenuItem value={"current_month"}>Current Month</MenuItem>
                      <MenuItem value={"last_month"}>Last Month</MenuItem>
                    </Select>
                  </FormControl>
                  {tableType && callingTime && (
                    <Grid item xs={18}>
                      <div className="d-flex justify-content-between">
                        <CallingDashboard
                          type={tableType}
                          filter={callingTime}
                        />
                      </div>
                    </Grid>
                  )}
                </>
              )}
              <Grid item xs={8}>
                <Grid container spacing={2} sx={{ marginTop: -1 }}>
                  {["DISBURSAL", "MONEY", "OS"]?.includes(activeDetails) && (
                    <>
                      <Grid item xs={3}>
                        <FormControl size="small" fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Loan Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={loanType}
                            label="Loan Type"
                            sx={style.selectStyle}
                            onChange={(event) => {
                              setLoanType(event.target.value);
                            }}
                          >
                            <MenuItem value={"ERICKLN"}>
                              Erickshaw Loan
                            </MenuItem>
                            <MenuItem value={"NOT-ERICKLN"}>
                              Other Than Erickshaw Loan
                            </MenuItem>
                            <MenuItem value={"ALL"}>All Loan</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {activeDetails !== "OS" && (
                        <Grid item xs={3}>
                          <FormControl size="small" fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Months
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={months}
                              label="Months"
                              sx={style.selectStyle}
                              onChange={(event) => {
                                setMonths(event.target.value);
                              }}
                            >
                              <MenuItem value={1}>One</MenuItem>
                              <MenuItem value={3}>Three</MenuItem>
                              <MenuItem value={6}>Six</MenuItem>
                              <MenuItem value={12}>Twelve</MenuItem>
                              <MenuItem value={18}>Eighteen</MenuItem>
                              <MenuItem value={24}>Twenty Four</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      {activeDetails === "OS" && (
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            label="As of"
                            type="date"
                            value={osDate}
                            variant="outlined"
                            InputProps={{
                              sx: {
                                height: 40,
                              },
                            }}
                            sx={style.selectStyle}
                            onChange={(e) => {
                              setOsDate(e.target.value);
                            }}
                          />
                        </Grid>
                      )}

                      <Grid item xs={3}>
                        <FormControl size="small" fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Source By
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={sourceBy}
                            sx={style.selectStyle}
                            label="Source By"
                            onChange={(event) => {
                              setSourceBy(event.target.value);
                            }}
                          >
                            <MenuItem value={"all"}>All</MenuItem>
                            <MenuItem value={"Embifi"}>Embifi</MenuItem>
                            <MenuItem value={"Non Embifi"}>Non Embifi</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          style={{
                            backgroundColor: "#000",
                            color: "#fff",
                            paddingLeft: "30px",
                            paddingRight: "30px",
                            borderRadius: 30,
                          }}
                          disabled={
                            loanType === "" || months === "" || sourceBy === ""
                          }
                          onClick={() => {
                            setShowDisbursedTable(!showDisbursedTable);
                          }}
                        >
                          {showDisbursedTable ? "Hide" : "Show"}
                        </Button>
                      </Grid>
                    </>
                  )}

                  {/* DPD Filter */}
                  {activeDetails === "DPDM" && (
                    <>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          label="Threshold amount"
                          type="text"
                          value={threshold}
                          variant="outlined"
                          InputProps={{
                            sx: {
                              height: 40,
                            },
                          }}
                          sx={style.selectStyle}
                          onChange={(e) => {
                            setThreshold(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl size="small" fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Level
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={level}
                            label="Level"
                            sx={style.selectStyle}
                            onChange={(event) => {
                              setLevel(event.target.value);
                            }}
                          >
                            <MenuItem value={"top"}>Top</MenuItem>
                            <MenuItem value={"middle"}>Middle</MenuItem>
                            <MenuItem value={"granular"}>Granular</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={3}>
                        <Button
                          style={{
                            backgroundColor: "#000",
                            color: "#fff",
                            paddingLeft: "30px",
                            paddingRight: "30px",
                            borderRadius: 30,
                          }}
                          disabled={threshold === "" || level === ""}
                          onClick={() => {
                            handleDPDData(threshold, level);
                            navigate(
                              `/dashboard?type=DPDM&amount=${threshold}&level=${level}`
                            );
                          }}
                        >
                          Show
                        </Button>
                      </Grid>
                    </>
                  )}
                  {activeDetails === "FILE" && (
                    <Grid
                      item
                      sx={{
                        fontSize: "25px",
                        fontWeight: 500,
                      }}
                      xs={12}
                    >
                      {activeDetails === "FILE" && (
                        <Button
                          style={{
                            float: "right",
                          }}
                          onClick={() => {
                            setFileData(null);
                            fetchFileStatusData();
                          }}
                        >
                          <ReplayIcon /> Reload
                        </Button>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              sx={{
                marginTop: "20px",
              }}
            >
              <Grid item xs={12} className="pt-0">
                {showDisbursedTable &&
                  ["DISBURSAL", "MONEY"]?.includes(activeDetails) && (
                    <DisbursedDataTable
                      loanType={loanType}
                      months={months}
                      sourceBy={sourceBy}
                      tab={activeDetails}
                    />
                  )}

                {activeDetails === "FILE" && (
                  <FileStatusTable fileData={fileData} loader={loader} />
                )}

                {activeDetails === "RC" && (
                  <RCDashboard rcData={rcData} loader={loader} />
                )}

                {activeDetails === "NACH" &&
                  showDisbursedTable &&
                  (nachDashType === "REGISTERED" ? (
                    <NachDashboardV2 nachData={nachData} loader={loader} />
                  ) : (
                    <NachDashboardTrans nachData={nachData} loader={loader} />
                  ))}

                {activeDetails === "OS" && showDisbursedTable && (
                  <OSDashboard
                    loader={loader}
                    setLoader={setLoader}
                    loanType={loanType}
                    sourceBy={sourceBy}
                    osDate={osDate}
                  />
                )}

                {["LOAN_AGREEMENT", "SANCTION"].includes(activeDetails) && (
                  <SanctionLoanTable
                    tableData={
                      activeDetails === "LOAN_AGREEMENT"
                        ? loanAgeementData
                        : sanctionData
                    }
                    loader={loader}
                    active={activeDetails}
                  />
                )}

                {activeDetails === "PROFILE" && (
                  <ProfilingDashboard
                    loader={loader}
                    profileData={profileData}
                  />
                )}

                {activeDetails === "DPDM" && (
                  <DPDDashboardMoment
                    ref={dpdDashboardRef}
                    loader={loader}
                    setLoader={setLoader}
                    threshold={threshold}
                    level={level}
                    tillDate={tillDate}
                  />
                )}
              </Grid>

              {activeDetails === "DPD" && (
                <DPDDashboard
                  ref={dpdDashboardRef}
                  loader={loader}
                  setLoader={setLoader}
                  style={style}
                />
              )}

              {["SANCTION", "LOAN_AGREEMENT"]?.includes(activeDetails) && (
                <Grid item xs={12}>
                  <Button
                    style={{
                      float: "right",
                    }}
                    onClick={() => {
                      fetchSmsData(activeDetails, true);
                    }}
                    disabled={[
                      "SANCTION",
                      "LOAN_AGREEMENT",
                      "DOWNLOAD_CSV",
                    ]?.includes(loader)}
                  >
                    {loader === "DOWNLOAD_CSV" ? (
                      <>Downloading...</>
                    ) : (
                      <>
                        <DownloadOutlined /> Download CSV
                      </>
                    )}
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default StatsDashboardPopup;
