import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DownloadIcon from "@mui/icons-material/Download";
import Slide from "@mui/material/Slide";
import ScheduleTable from "../../../Components/Table";
import { Fab, Grid, Menu, MenuItem, useRadioGroup } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import "../ScheduleViewer.css";
import { useRef } from "react";
import generateTablePDF from "../../../utility/pdfGenerate";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ScheduleViewer({
  open,
  schedules,
  handleClose,
  basicData,
}) {
  const [tableData, setTableData] = useState([]);
  const [active, setActive] = useState("EDI");
  const [scroll, setScroll] = useState("Bottom");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickDownload = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDownload = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    // setOpen(true);
  };

  useEffect(() => {
    // console.log(schedules);
    if (schedules?.v2) {
      setActive("EMI");
      setTableData(schedules["EMI"]);
    } else {
      setActive("EDI");
      setTableData(schedules["EDI"]);
    }
  }, [schedules]);

  useEffect(() => {
    setTableData(schedules[active]);
  }, [active]);

  // useEffect(() => {
  //   console.log(tableData);
  // }, [tableData]);

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleDownload = (e) => {
    e.preventDefault();

    // Headers for each column
    let headers = [`SL NO,Due Date,${active},Principal,Interest,O/s Principal`];

    // Convert users data to a csv
    let usersCsv = schedules[active].reduce((acc, row) => {
      acc.push(
        [
          row["SL_NO"],
          row["Due_Date"],
          row[active],
          row["Principal"],
          row["Interest"],
          row["O/s_Principal"],
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: `Schedule(${active})-${schedules?.[active][0]?.["Due_Date"]}`,
      fileType: "text/csv",
    });
    handleCloseDownload();
  };

  const handlePdfDownload = (e) => {
    let data = {
      repayment_amount: 0,
      interest_amount: 0,
    };

    schedules[active].forEach((val) => {
      data.repayment_amount += Number(val[active.toUpperCase()]);
      data.interest_amount += Number(val["Interest"]);
    });

    generateTablePDF(
      schedules[active],
      {
        applicationId: "NA",
        name: basicData?.name,
        nbfc: basicData?.nbfc,
        loanAmount: basicData?.loanAmt,
        repayment_amount: data?.repayment_amount + "",
        interest_amount: data?.interest_amount + "",
        interest: basicData?.interest,
        tenure: basicData?.tenure,
        disbursalDate: moment(basicData?.date).format("DD/MM/YYYY"),
      },
      `Schedule(${active})-${schedules?.[active][0]?.["Due_Date"]}.pdf`,
      active
    );
    handleCloseDownload();
  };

  const handleScroll = (event) => {
    // console.log("scrollTop: ", event.currentTarget.scrollTop);
    // console.log("offsetHeight: ", event.currentTarget.offsetHeight);
    if (
      event.currentTarget.scrollTop >
      event.currentTarget.offsetHeight - event.currentTarget.offsetHeight / 2
    ) {
      setScroll("Top");
    }

    if (event.currentTarget.scrollTop < event.currentTarget.scrollHeight / 2) {
      setScroll("Bottom");
    }
  };

  const scrollRef = useRef(null);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "fixed", backgroundColor: "white", color: "black" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Schedule ({active})
            </Typography>

            <Button
              id="basic-button"
              color="inherit"
              aria-controls={openMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              onClick={handleClickDownload}
            >
              Download <DownloadIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseDownload}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleDownload}>CSV</MenuItem>
              <MenuItem onClick={handlePdfDownload}>PDF</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <div>
          <Grid container spacing={2} style={{ position: "fixed" }}>
            <Grid item xs={2}>
              <div style={{ marginTop: "80px", padding: "10px" }}>
                {!schedules?.v2 && (
                  <p
                    className={`sidenav-btn ${
                      active === "EDI" && "side-active"
                    }`}
                    onClick={() => {
                      setTableData(schedules?.EDI);
                      setActive("EDI");
                    }}
                  >
                    Daily installment
                  </p>
                )}
                {schedules?.v2 && (
                  <p
                    className={`sidenav-btn ${
                      active === "EFI" && "side-active"
                    }`}
                    onClick={() => {
                      setTableData(schedules?.EFI);
                      setActive("EFI");
                    }}
                  >
                    EFI
                  </p>
                )}
                {schedules?.EMI?.length > 0 && (
                  <p
                    className={`sidenav-btn ${
                      active === "EMI" && "side-active"
                    }`}
                    onClick={() => {
                      setTableData(schedules?.EMI);
                      setActive("EMI");
                    }}
                  >
                    Monthly installment
                  </p>
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={10}
              style={{ height: "100vh", overflowY: "scroll" }}
              onScroll={handleScroll}
              ref={scrollRef}
            >
              <ScheduleTable data={tableData} active={active} />
            </Grid>
          </Grid>
        </div>

        <Fab
          variant="extended"
          className="scroll-top-btn"
          onClick={() => {
            if (scroll === "Top") {
              scrollRef.current.scrollTop = 0;
            } else if (scroll === "Bottom") {
              scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
            }
          }}
        >
          {scroll === "Top" && <ArrowUpwardIcon sx={{ mr: 1 }} />}
          {scroll === "Bottom" && <ArrowDownwardIcon sx={{ mr: 1 }} />}
          {scroll}
        </Fab>
      </Dialog>
    </div>
  );
}
