import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState, useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { agentTargetAnchors, getAllAgents, setAgentTarget } from "../../api";
import CircularProgress from "@mui/material/CircularProgress";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function SetTargetModal({ open, handleClose }) {
  const [agent, setAgent] = useState("");
  const [dateOpen, setDateOpen] = useState(false);
  const [allAgents, setAllAgents] = useState([]);
  const [date, setDate] = useState();
  const [anchors, setAnchors] = useState([]);
  const [loading, setLoading] = useState(null);
  const [updated, setUpdated] = useState(false);

  const handleChange = (event) => {
    setAgent(event.target.value);
  };

  const fetchAgentAnchors = async () => {
    if (!date || !agent || dateOpen) {
      return;
    }
    setLoading("FETCH");
    try {
      const { data } = await agentTargetAnchors(
        agent,
        new Date(date).getTime()
      );
      setAnchors(
        (data?.anchors || [])?.map((val) => {
          return {
            ...val,
            file_login_target: val?.file_login_target || 0,
            disbursement_target: val?.disbursement_target || 0,
          };
        })
      );
    } catch (error) {
      toast.error("Error fetching target data");
    }
    setLoading(null);
  };

  const fetchAgents = async () => {
    setLoading("AGENTS");
    try {
      const { data } = await getAllAgents();
      setAllAgents(data?.agents || []);
    } catch (error) {
      toast.error("Error fetching traget data");
    }
    setLoading(null);
  };

  const handleSetAgentTarget = async () => {
    try {
      setLoading("UPDATE");
      const { data } = await setAgentTarget({
        agent_code: agent,
        month_date: date,
        anchor_wise_target: anchors,
      });
      toast.success(data?.message || "Operation Successfull!");
      setUpdated(true);
    } catch (error) {
      toast.error("Error fetching traget data");
    }
    setLoading(null);
  };

  useEffect(() => {
    fetchAgentAnchors();
  }, [agent, date]);

  useEffect(() => {
    fetchAgents();
  }, []);

  return (
    <>
      <BootstrapDialog
        onClose={() => {
          handleClose(updated);
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Set Target
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {loading === "AGENTS" ? (
                <div className={"d-flex mt-3"} style={{ alignItems: "center" }}>
                  <CircularProgress className={"me-2"} size={15} /> Loading
                  Agents
                </div>
              ) : (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Choose Agent*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={agent}
                    label="Choose Agent"
                    onChange={handleChange}
                  >
                    {allAgents?.map((ag) => (
                      <MenuItem value={ag?.agent_code}>{ag?.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DatePicker
                    fullWidth
                    views={["month"]}
                    slotProps={
                      {
                        // textField: {
                        //   helperText: error.settlementDateMs,
                        // },
                        // field: {
                        //   clearable: true,
                        //   onClear: () => setCleared(true),
                        // },
                      }
                    }
                    label="Select month *"
                    format="MM/YYYY"
                    value={date}
                    inputVariant="outlined"
                    onChange={(date) => {
                      setDate(date);
                    }}
                    onOpen={() => {
                      setDateOpen(true);
                    }}
                    onClose={() => {
                      setDateOpen(false);
                    }}
                    animateYearScrolling
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid>
          </Grid>
          {loading === "FETCH" && (
            <div className="content-center mt-5">
              <CircularProgress />
            </div>
          )}
          {loading === null && agent && date && anchors?.length === 0 && (
            <div className="content-center mt-5">No Anchors</div>
          )}
          {(loading === null || loading === "UPDATE") &&
            anchors?.length > 0 && (
              <div
                style={{
                  padding: "10px",
                  border: "1px solid #0000001F",
                  marginTop: "20px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={3}>
                    <span style={{ fontSize: "12px" }}>
                      File Login <span style={{ color: "red" }}>*</span>
                    </span>
                  </Grid>
                  <Grid item xs={3}>
                    <span style={{ fontSize: "12px" }}>
                      Disbursement <span style={{ color: "red" }}>*</span>
                    </span>
                  </Grid>
                  {(anchors || [])?.map((anchor, i) => (
                    <>
                      <Grid
                        item
                        xs={6}
                        className={"d-flex"}
                        style={{ alignItems: "center", fontWeight: 500 }}
                      >
                        {anchor?.dealership_name}
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          value={anchor?.file_login_target}
                          onChange={(e) => {
                            if (
                              e.target.value !== "" &&
                              !/^\d+$/.test(e.target.value)
                            ) {
                              return false;
                            }
                            let state = [...anchors];
                            state[i]["file_login_target"] = Number(
                              e.target.value
                            );
                            setAnchors(state);
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          value={anchor?.disbursement_target}
                          onChange={(e) => {
                            if (
                              e.target.value !== "" &&
                              !/^\d+$/.test(e.target.value)
                            ) {
                              return false;
                            }
                            let state = [...anchors];
                            state[i]["disbursement_target"] = Number(
                              e.target.value
                            );
                            setAnchors(state);
                          }}
                        />
                      </Grid>
                    </>
                  ))}
                </Grid>
              </div>
            )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose(updated);
            }}
          >
            Cancel
          </Button>
          {loading === null && anchors?.length > 0 && (
            <Button
              onClick={() => {
                handleSetAgentTarget();
              }}
              disabled={loading === "UPDATE"}
            >
              {loading === "UPDATE" ? "Updating..." : "Confirm"}
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
