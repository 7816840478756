import documents from './documentConstants'

export let documentList = {
  applicant_pan: {
    collection: "customer",
    category: "customer",
  },

  customer_photo: {
    collection: "customer",
    category: "customer",
  },

  aadhaar_front: {
    collection: "customer",
    category: "customer",
  },
  aadhaar_back: {
    collection: "customer",
    category: "customer",
  },
  electricity_bill: {
    collection: "loan",
    category: "customer",
  },
  other_ownership_proof: {
    collection: "loan",
    category: "customer",
  },

  driving_license: {
    collection: "customer",
    category: "customer",
  },
  
  voter_id_front: {
    collection: "customer",
    category: "customer",
  },
  voter_id_back: {
    collection: "customer",
    category: "customer",
  },
  customer_other_kyc: {
    collection: "customer",
    category: "customer",
  },
  bank_passbook: {
    collection: "loan",
    category: "customer",
  },
  applicant_crif: {
    collection: "customer",
    category: "customer",
  },
  applicant_other_kyc: {
    collection: "customer",
    category: "customer",
  },

  partner_pan: {
    collection: "co-customer",
    category: "co_customer",
  },
  director_pan: {
    collection: "co-customer",
    category: "co_customer",
  },
  business_electricity_bill: {
    collection: "co-customer",
    category: "co_customer",
  },

  co_customer_voter_id_front: {
    collection: "co-customer",
    category: "co_customer",
  },
  co_customer_voter_id_back: {
    collection: "co-customer",
    category: "co_customer",
  },
  co_customer_dl: {
    collection: "co-customer",
    category: "co_customer",
  },
  co_customer_passport: {
    collection: "co-customer",
    category: "co_customer",
  },
  co_customer_photo: {
    collection: "co-customer",
    category: "co_customer",
  },
  co_customer_aadhaar_front: {
    collection: "co-customer",
    category: "co_customer",
  },
  co_customer_aadhaar_back: {
    collection: "co-customer",
    category: "co_customer",
  },
  co_customer_pan: {
    collection: "co-customer",
    category: "co_customer",
  },
  co_customer_crif: {
    collection: "co-customer",
    category: "co_customer",
  },
  co_customer_other_kyc: {
    collection: "co-customer",
    category: "co_customer",
  },
  co_customer_electricity_bill: {
    collection: "co-customer",
    category: "co_customer",
  },
  partner_voter_id: {
    collection: "co-customer",
    category: "co_customer",
  },
  partner_dl: {
    collection: "co-customer",
    category: "co_customer",
  },
  partner_passport: {
    collection: "co-customer",
    category: "co_customer",
  },
  partner_photo: {
    collection: "co-customer",
    category: "co_customer",
  },
  partner_aadhaar_front: {
    collection: "co-customer",
    category: "co_customer",
  },
  partner_adhaar_back: {
    collection: "co-customer",
    category: "co_customer",
  },
  co_customer_ownership_proof: {
    collection: "co-customer",
    category: "co_customer",
  },

  director_passport: {
    collection: "co-customer",
    category: "co_customer",
  },
  director_dl: {
    collection: "co-customer",
    category: "co_customer",
  },
  director_voter_id: {
    collection: "co-customer",
    category: "co_customer",
  },
  director_photo: {
    collection: "co-customer",
    category: "co_customer",
  },
  director_aadhaar_front: {
    collection: "co-customer",
    category: "co_customer",
  },
  director_aadhaar_back: {
    collection: "co-customer",
    category: "co_customer",
  },
  "d/o": {
    collection: "loan",
    category: "pre_disbursement",
  },

  gst_certificate: {
    collection: "loan",
    category: "pre_disbursement",
  },
  fssai_certificate: {
    collection: "loan",
    category: "pre_disbursement",
  },
  shop_and_establishment_certificate: {
    collection: "loan",
    category: "pre_disbursement",
  },
  msme_certificate: {
    collection: "loan",
    category: "pre_disbursement",
  },
  bye_laws: {
    collection: "loan",
    category: "pre_disbursement",
  },
  moa: {
    collection: "loan",
    category: "pre_disbursement",
  },
  aoa: {
    collection: "loan",
    category: "pre_disbursement",
  },
  loan_agreement: {
    collection: "loan",
    category: "pre_disbursement",
  },
  trc: {
    collection: "loan",
    category: "pre_disbursement",
  },
  insurance: {
    collection: "loan",
    category: "pre_disbursement",
  },
  form_20: {
    collection: "loan",
    category: "pre_disbursement",
  },
  form_21: {
    collection: "loan",
    category: "pre_disbursement",
  },
  form_22: {
    collection: "loan",
    category: "pre_disbursement",
  },
  cheques: {
    collection: "loan",
    category: "pre_disbursement",
  },
  bsv: {
    collection: "loan",
    category: "pre_disbursement",
  },

  sanction_letter: {
    collection: "loan",
    category: "pre_disbursement",
  },
  hypothecation_agreement: {
    collection: "loan",
    category: "pre_disbursement",
  },
  bank_statement: {
    collection: "loan",
    category: "pre_disbursement",
  },
  bank_details_end: {
    collection: "loan",
    category: "pre_disbursement",
  },
  crif_report: {
    collection: "loan",
    category: "pre_disbursement",
  },
  stamp_paper: {
    collection: "loan",
    category: "pre_disbursement",
  },
  dual_form: {
    collection: "loan",
    category: "pre_disbursement",
  },
  delivery_order: {
    collection: "loan",
    category: "pre_disbursement",
  },
  post_disbursement: {
    collection: "loan",
    category: "post_disbursement",
  },
  erick_delivery_photo: {
    collection: "loan",
    category: "pre_disbursement",
  },
  
  battery_warranty: {
    collection: "loan",
    category: "pre_disbursement",
  },
  

  bank_signature_verification: {
    collection: "loan",
    category: "pre_disbursement",
  },
  nach_form: {
    collection: "loan",
    category: "pre_disbursement",
  },
  rto_form: {
    collection: "loan",
    category: "pre_disbursement",
  },
  form_26_35: {
    collection: "loan",
    category: "pre_disbursement",
  },
  registration_certificate: {
    collection: "loan",
    category: "post_disbursement",
  },
  sales_invoice: {
    collection: "loan",
    category: "pre_disbursement",
  },
  quotation: {
    collection: "loan",
    category: "pre_disbursement",
  },

  field_investigation: {
    collection: "loan",
    category: "field_investigation",
  },
  other_documents: {
    collection: "loan",
    category: "other_documents",
  },
  courier_receipt: {
    collection: "loan",
    category: "post_disbursement",
  },
  customer_house_inside:{
    collection: "loan",
    category: "field_investigation",
  },
  customer_house_outside:{
    collection: "loan",
    category: "field_investigation",
  },
  customer_photo_with_electricity_meter:{
    collection: "loan",
    category: "field_investigation",
  },
  cibil_report:{
    collection: "loan",
    category: "pre_disbursement",
  }
};


export const setDocumentsArray = (isCocustomer) => {
  let docArray = [];
  for (let key in documentList) {
    if (isCocustomer) {
      docArray.push(key);
    } else {
      if (documentList[key].category !== "co_customer") {
        docArray.push(key);
      }
    }
  }
  return docArray;
};


export const erickMandatoryDocs =  Object.freeze({
  DOCS: [
    documents.DOCUMENTS.CUSTOMERS.CUSTOMER_PHOTO,
    documents.DOCUMENTS.APPLICATION.ERICK_DELIVERY_PHOTO,
    documents.DOCUMENTS.APPLICATION.FORM_21,
    documents.DOCUMENTS.APPLICATION.SALES_INVOICE,
    documents.DOCUMENTS.APPLICATION.OTHER_OWNERSHIP_PROOF,
    documents.DOCUMENTS.APPLICATION.LOAN_AGREEMENT,
    documents.DOCUMENTS.APPLICATION.DUAL_FORM,
    documents.DOCUMENTS.APPLICATION.STAMP_PAPER,
    documents.DOCUMENTS.APPLICATION.FORM_26_35,
    documents.DOCUMENTS.APPLICATION.INSURANCE,
    documents.DOCUMENTS.APPLICATION.TRC,
    documents.DOCUMENTS.APPLICATION.BSV,
    documents.DOCUMENTS.CO_CUSTOMERS.PHOTO,
    // !co_customer_electricity_bill[0]?.uploadStatus &&
    // documents.DOCUMENTS.CO_CUSTOMERS.ELECTRICITY_BILL,
    documents.DOCUMENTS.CO_CUSTOMERS.OWNERSHIP_PROOF,
    documents.DOCUMENTS.APPLICATION.CHEQUES,
  ],
});