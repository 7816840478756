import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const InstallationGraph = ({ graphData }) => {
  const data = {
    labels: graphData?.labels || [],
    datasets: [
      {
        label: "Count",
        data: graphData?.values || [],
        backgroundColor: "rgba(158, 156, 255, 0.6)",
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: (context) => context.dataset.data[context.dataIndex] !== 0,
        anchor: "end",
        align: "end",
        color: "#B469FF",
        offset: -20,
        font: { size: 12 },
        formatter: (value, context) => {
          return context.dataset.data[context.dataIndex] !== 0 ? value : ""; // Return empty string if value is 0
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
        //   display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div style={{ width: "100%", margin: "auto", padding: "10px" }}>
      <Bar data={data} options={options} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default InstallationGraph;
