import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { f } from "../../utility/Formater";
import CustomTableV2 from "../CustomTable/CustomTableV2";
import CloseIcon from "@mui/icons-material/Close";

function CommercialsHistoryPopUp({ isOpen, onClose, data, currData }) {
  let columns_filing_status_current = [
    {
      label: "OEM Name",
      render: (index) => {
        return f(currData?.[index]?.oem_name);
      },
      width: 150,
    },
    {
      label: "Interest Rate",
      render: (index) => {
        return f(currData?.[index]?.old_commercials?.interest_rate, "rate");
      },
      width: 150,
    },
    {
      label: "Processing Fee",
      render: (index) => {
        return f(
          currData?.[index]?.old_commercials?.processing_fees
            ? currData?.[index]?.old_commercials?.processing_fees
            : currData?.[index]?.old_commercials?.processing_fees_percent,
          currData?.[index]?.old_commercials?.processing_fees ? "cur" : "rate"
        );
      },
      width: 150,
    },
    {
      label: "Dealer Fee",
      render: (index) => {
        return f(
          currData?.[index]?.old_commercials?.subvention_amount
            ? currData?.[index]?.old_commercials?.subvention_amount
            : currData?.[index]?.old_commercials?.subvention_percent,
          currData?.[index]?.old_commercials?.subvention_amount ? "cur" : "rate"
        );
      },
      width: 150,
    },
    {
      label: "GPS Charge",
      render: (index) => {
        return f(currData?.[index]?.old_commercials?.gps_charges);
      },
      width: 150,
    },
    {
      label: "Total HB Amount",
      render: (index) => {
        return f(
          currData?.[index]?.old_commercials?.total_hold_back_amount,
          "cur"
        );
      },
      width: 150,
    },
    {
      label: "HB per file",
      render: (index) => {
        return f(currData?.[index]?.old_commercials?.hold_back_per_file, "cur");
      },
      width: 150,
    },
  ];
  let columns_filing_status = [
    {
      label: "Date (before)",
      render: (index) => {
        return data?.[index]?.date == "Current"
          ? "Current"
          : f(data?.[index]?.date, "date/time");
      },
      width: 200,
    },
    {
      label: "User",
      render: (index) => {
        return f(data?.[index]?.user);
      },
      width: 200,
    },
    {
      label: "OEM Name",
      render: (index) => {
        return f(data?.[index]?.oem_name);
      },
      width: 150,
    },
    {
      label: "Interest Rate",
      render: (index) => {
        return f(data?.[index]?.old_commercials?.interest_rate, "rate");
      },
      width: 150,
    },
    {
      label: "Processing Fee",
      render: (index) => {
        return f(
          data?.[index]?.old_commercials?.processing_fees
            ? data?.[index]?.old_commercials?.processing_fees
            : data?.[index]?.old_commercials?.processing_fees_percent,
          data?.[index]?.old_commercials?.processing_fees ? "cur" : "rate"
        );
      },
      width: 150,
    },
    {
      label: "Dealer Fee",
      render: (index) => {
        return f(
          data?.[index]?.old_commercials?.subvention_amount
            ? data?.[index]?.old_commercials?.subvention_amount
            : data?.[index]?.old_commercials?.subvention_percent,
          data?.[index]?.old_commercials?.subvention_amount ? "cur" : "rate"
        );
      },
      width: 150,
    },
    {
      label: "GPS Charge",
      render: (index) => {
        return f(data?.[index]?.old_commercials?.gps_charges);
      },
      width: 150,
    },
    {
      label: "Total HB Amount",
      render: (index) => {
        return f(data?.[index]?.old_commercials?.total_hold_back_amount, "cur");
      },
      width: 150,
    },
    {
      label: "HB per file",
      render: (index) => {
        return f(data?.[index]?.old_commercials?.hold_back_per_file, "cur");
      },
      width: 150,
    },
  ];
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth
      sx={{ width: "100%", px: 0, pb: 1 }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "gray",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div>
          <h4>Current Commercials</h4>
          <CustomTableV2
            tableData={currData}
            tableHeight={200}
            columns={columns_filing_status_current}
            limit={0}
            page={1}
          />
        </div>
        <div style={{
            marginTop: "10px"
        }}>
          <h4>Commercials History</h4>
          <CustomTableV2
            tableData={data}
            tableHeight={500}
            columns={columns_filing_status}
            limit={0}
            page={1}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CommercialsHistoryPopUp;
