import { CircularProgress, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { monthlyDisbursedData } from '../../api';

const cf = (val) => {
    val = Number(val);
    if (!isNaN(val)) {
        return `₹ ${val?.toLocaleString('en-IN')}`;
    } else {
        return 'NA';
    }
};

const monthsMap = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
}

const nbfcMap = {
    FI00005: "Fintree",
    // Remove this PL00003
    NY00002: "NY Leasing",
    VA00001: "Vani",
    PL00003: "Prest Loan",
    MF00006: "Mufin",
    NAM0007:"Namdev"
}

export default function DisbursedDataTable(props) {
    const [monthlyData, setMonthlyData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [months, setMonths] = useState([]);
    const [isloading, setLoading] = useState(false);

    const getMonthlyData = async (loanType = "ERICKLN", months = 12, sourceBy = "") => {
        setLoading(true);
        try {
            console.log(props?.tab==='MONEY');
            const { data } = await monthlyDisbursedData(loanType, months, sourceBy, props?.tab==='MONEY');
            if (data?.status) {
                // console.log(data?.data);
                const totalData = data?.data.pop();
                // const filteredData = data?.data.filter((val) => {
                //     return val._id !== "PL00003"
                // })
                // console.log(data?.data)
                // console.log(totalData);
                setMonthlyData(data?.data);
                setTotalData(totalData);
                const monthsData = data?.data[0].monthlyAmounts.map(edx => {
                    return { year: edx.year, month: edx.month }
                })
                setMonths(monthsData);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    useEffect(() => {
        getMonthlyData(props?.loanType, props?.months, props?.sourceBy);
    }, [props?.loanType, props?.months , props?.sourceBy])

    // useEffect(() => {
    //     getMonthlyData(props?.loanType, props?.months, props?.sourceBy);
    // }, [])
    return (
        <div
            style={{ overflow: "scroll", maxHeight: "80vh", maxWidth: "100%" }}
        // className="custom-table"
        >
            {isloading ? (
                <div style={styles?.loading_container}>
                    <CircularProgress color="inherit" />
                </div>
            ) : monthlyData.length > 0 ? (
                <table style={styles?.table}>
                    <thead style={styles?.thead}>
                        <tr >
                            <th style={styles?.th_top_months}> </th>
                            <th style={styles?.th_top_nbfcs}></th>
                            {monthlyData.map((edx) => {
                                return (
                                    <th style={styles?.th_top_nbfcs}>{nbfcMap[edx?._id] || "N/A"}</th>
                                )
                            })}
                        </tr>
                        <tr>
                            <th style={styles?.th_secondary}>Month </th>
                            <th>
                                <th style={styles?.th_secondary}>Count </th>
                                <th style={{
                                    width: "125px",
                                    textAlign: "center",
                                    fontSize: "14px",
                                    color: "#0000008c",
                                }}>Amount </th>
                            </th>

                            {monthlyData.map((edx) => {
                                return (
                                    <th style={styles?.th_top_nbfcs}>
                                        <th style={{
                                            width: "125px",
                                            textAlign: "center",
                                            fontSize: "14px",
                                            color: "#0000008c",
                                            borderRight: "1px solid",
                                        }}>Count </th>
                                        <th style={{
                                            width: "125px",
                                            textAlign: "center",
                                            fontSize: "14px",
                                            color: "#0000008c",
                                        }}>Amount </th>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody >
                        <tr>
                            <td style={styles?.th_top_months}>
                                
                                {months?.map((edx, index) => {
                                    return (
                                        <tr><td align='center' style={{minWidth: "125px"}}>{monthsMap[edx?.month]},{edx?.year.toString().slice(2, 4)}</td></tr>
                                    )
                                })}
                            </td>
                            <td style={styles?.th_top_nbfcs}>
                                <td align="center" style={{
                                    borderRight: "1px solid",
                                    width: "125px",

                                }}>
                                    {totalData?.monthlyAmounts?.map((val) => {
                                        return (
                                            <tr><td align='center'>{val["Count"]}</td></tr>
                                        )
                                    })
                                    }
                                </td>

                                <td align="center" style={{
                                    width: "125px",
                                }}>{totalData?.monthlyAmounts?.map((val) => {
                                    return (
                                        <tr><td align='center'>{cf(Math.round(val["TotalAmount"]))}</td></tr>
                                    )
                                })
                                    } </td>
                            </td>
                            {monthlyData.map((edx, i) => {
                                return (
                                    <td style={styles?.th_top_nbfcs}>
                                        <td align="center" style={{
                                            borderRight: "1px solid",
                                            width: "125px",
                                        }}>
                                            {edx?.monthlyAmounts?.map((val) => {
                                                return (
                                                    <tr><td align='center'>{val["Count"]}</td></tr>
                                                )
                                            })
                                            }
                                        </td>

                                        <td align="center" style={{
                                            width: "125px",
                                        }}>{edx?.monthlyAmounts?.map((val) => {
                                            return (
                                                <tr><td align='center'>{cf(Math.round(val["TotalAmount"]))}</td></tr>
                                            )
                                        })
                                            } </td>
                                    </td>
                                )
                            })}
                        </tr>
                        <tr>
                            <th style={{ fontSize: "14px",textAlign: "center", }}>Grand Total</th>
                            <th style={styles?.th_top_nbfcs}>
                                <th style={{
                                    width: "125px",
                                    textAlign: "center",
                                    fontSize: "14px",
                                    color: "rgb(16 16 16 / 89%)",
                                    borderRight: "1px solid",
                                }}>{totalData?.TotalCount} </th>
                                <th style={{
                                    width: "125px",
                                    textAlign: "center",
                                    fontSize: "14px",
                                    color: "rgb(16 16 16 / 89%)",
                                }}>{cf(Math.round(totalData?.TotalAmount))} </th>
                            </th>
                            {monthlyData.map((edx) => {
                                return (
                                    <th style={styles?.th_top_nbfcs}>
                                        <th style={{
                                            width: "125px",
                                            textAlign: "center",
                                            fontSize: "14px",
                                            color: "rgb(16 16 16 / 89%)",
                                            borderRight: "1px solid",
                                        }}>{edx?.TotalCount} </th>
                                        <th style={{
                                            width: "125px",
                                            textAlign: "center",
                                            fontSize: "14px",
                                            color: "rgb(16 16 16 / 89%)",
                                        }}>{cf(Math.round(edx?.TotalAmount))} </th>
                                    </th>
                                )
                            })}
                        </tr>
                    </tbody>
                </table>
            ) : (
                <div style={styles?.loading_container}>
                    No data
                </div>
            )
            }
        </div>
    )
}

const styles = {
    table: {
        borderCollapse: "collapse",
        border: "1px solid"
    },
    thead: {
        border: "1px solid"
    },

    th_top_months: {
        border: "1px solid",
        width: "125px",
    },
    th_top_nbfcs: {
        minWidth: "250px",
        textAlign: "center",
        border: "1px solid"
    },
    th_secondary: {
        width: "125px",
        textAlign: "center",
        fontSize: "14px",
        color: "#0000008c",
        borderRight: "1px solid",
        borderCollapse: "collapse",
    },
    loading_container: {
        height: "20vh",
        width: "70vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}