import { Close, Delete, EditOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { deleteAnchorGPS, updateAnchorGPS } from "../../api";
import LoadingOver from "../Loading";
import DeletePopup from "../DeletePopup";

const initGPSData = {
  gps_procurer: "",
  gps_lot: "",
  gps_unique_number: "",
  gps_sim_number: "",
  money_received: "",
};

function GPSPopup({
  open,
  handleClose,
  gpsData,
  isEdit,
  getGPSData,
  firmName,
}) {
  const [editData, setEditData] = useState(initGPSData);
  const [loading, setLoading] = useState(null);
  const [confDltPopup, setConfDltPopup] = useState(false);

  const isSubmitDisabled = () => {
    const {
      gps_procurer,
      gps_lot,
      gps_unique_number,
      gps_sim_number,
      money_received,
    } = editData;
    if(!Boolean(gps_procurer)) return true;
    let check = !Boolean(gps_lot) || !Boolean(gps_unique_number) || !Boolean(money_received)
    if(gps_procurer==="mmi"){
      return check;
    }
    return check || !Boolean(gps_sim_number)
  };

  async function handleSubmit(e) {
    try {
      setLoading("updating ...");
      let payload = {
        gps_procurer: editData?.gps_procurer,
        gps_lot: editData?.gps_lot,
        gps_unique_number: editData?.gps_unique_number,
        ...(editData?.gps_procurer !== "mmi"
          ? { gps_sim_number: editData?.gps_sim_number }
          : {}),
        money_received: editData?.money_received,
        anchor_id: gpsData?.anchor_id,
        objectId: gpsData?._id,
      };
      const { data } = await updateAnchorGPS(payload);
      await getGPSData();
      toast(data?.message);
      handleClose();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(null);
    }
  }

  async function handleDelete() {
    try {
      setLoading("deleting ...");
      setConfDltPopup(false);
      const { data } = await deleteAnchorGPS(gpsData?._id);
      await getGPSData();
      toast(data?.message);
      handleClose();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(null);
    }
  }

  useEffect(() => {
    if (open && isEdit) {
      setEditData({
        gps_procurer: gpsData?.gps_procurer,
        gps_lot: gpsData?.gps_lot,
        gps_unique_number: gpsData?.gps_unique_number,
        gps_sim_number: gpsData?.gps_sim_number,
        money_received: gpsData?.money_received,
      });
    } else {
      setEditData(initGPSData);
    }
  }, [open]);
  return (
    <>
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>{firmName}</DialogTitle>
        <IconButton
          className="position-absolute"
          sx={{ top: 10, right: 10 }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
        <DialogContent sx={{ width: 550 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ width: "60%" }}>
                <h6
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                  className="font-inter"
                >
                  Procurer Name{" "}
                  <span
                    style={{
                      color: "rgba(255, 34, 34, 1)",
                    }}
                  >
                    *
                  </span>
                </h6>
                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="outlined"
                    value={editData?.gps_procurer}
                    // disabled={isDisabled}
                    onChange={(e) => {
                      setEditData((prev) => ({
                        ...prev,
                        gps_procurer: e.target.value,
                      }));
                    }}
                  >
                    <MenuItem value={"mmi"}>MMI</MenuItem>
                    <MenuItem value={"mufin"}>Mufin</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ width: "60%" }}>
                <h6
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                  className="font-inter"
                >
                  Lot{" "}
                  <span
                    style={{
                      color: "rgba(255, 34, 34, 1)",
                    }}
                  >
                    *
                  </span>
                </h6>
                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <TextField
                    variant="outlined"
                    value={editData?.gps_lot}
                    onChange={(e) => {
                      setEditData((prev) => ({
                        ...prev,
                        gps_lot: e.target.value,
                      }));
                    }}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ width: "60%" }}>
                <h6
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                  className="font-inter"
                >
                  GPS Number{" "}
                  <span
                    style={{
                      color: "rgba(255, 34, 34, 1)",
                    }}
                  >
                    *
                  </span>
                </h6>
                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <TextField
                    variant="outlined"
                    value={editData?.gps_unique_number}
                    onChange={(e) => {
                      setEditData((prev) => ({
                        ...prev,
                        gps_unique_number: e.target.value,
                      }));
                    }}
                  />
                </FormControl>
              </div>
            </Grid>
            {editData?.gps_procurer !== "mmi" && (
              <Grid item xs={12}>
                <div style={{ width: "60%" }}>
                  <h6
                    style={{
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                    className="font-inter"
                  >
                    GPS SIM Number{" "}
                    <span
                      style={{
                        color: "rgba(255, 34, 34, 1)",
                      }}
                    >
                      *
                    </span>
                  </h6>
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                    <TextField
                      variant="outlined"
                      value={editData?.gps_sim_number || ""}
                      onChange={(e) => {
                        setEditData((prev) => ({
                          ...prev,
                          gps_sim_number: e.target.value,
                        }));
                      }}
                    />
                  </FormControl>
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <div style={{ width: "60%" }}>
                <h6
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                  className="font-inter"
                >
                  Money Received{" "}
                  <span
                    style={{
                      color: "rgba(255, 34, 34, 1)",
                    }}
                  >
                    *
                  </span>
                </h6>
                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="outlined"
                    value={editData?.money_received}
                    // disabled={isDisabled}
                    onChange={(e) => {
                      setEditData((prev) => ({
                        ...prev,
                        money_received: e.target.value,
                      }));
                    }}
                  >
                    <MenuItem value={"Advance"}>Advance</MenuItem>
                    <MenuItem value={"Deducted from Loan Amount"}>
                      Deducted from Loan Amount
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="justify-content-between">
          <div>
            <Button
              color="error"
              variant="text"
              onClick={() => {
                setConfDltPopup(true);
              }}
            >
              <Delete />
            </Button>
          </div>
          <div>
            <Button color="error" variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleSubmit}
              variant="contained"
              disabled={isSubmitDisabled()}
              disableElevation
            >
              {isEdit ? "Save" : "Add"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {loading && <LoadingOver text={loading} />}
      <DeletePopup
        confDltPopup={confDltPopup}
        text={"Are you sure ?"}
        setConfDltPopup={setConfDltPopup}
        handleDeleteDoc={handleDelete}
      />
    </>
  );
}

export default GPSPopup;
