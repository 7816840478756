import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  data,
} from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./style.css";
import useCountdown from "../../hooks/useCountDown";
import { toast } from "react-toastify";
import { sendOTP , verifyOTP } from "../../api";
import DeletePopup from "../DeletePopup";
// const classes = {

// };

const useclasses = makeStyles((theme) => ({
  color: "#000",
  otpInput: {
    width: 5,
    marginRight: 2,
  },
  grid: {
    backgroundColor: "grey",
    height: "50vh",
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const mykey = "0123456789".split("");

function OTP({ open, handleClose, handleSubmit, data, setVarified }) {
  const { remainingTime, startTimer, restartTimer } = useCountdown({
    timerCount: 120,
  });
  const classes = useclasses();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpDetails, setOtpDetails] = useState({});
  const [openCancelOTP, setOpenCancelOTP] = useState(false);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(false);

  const inputs = {
    input0: useRef(null),
    input1: useRef(null),
    input2: useRef(null),
    input3: useRef(null),
  };

  const handle_next_input = (idx) => (event) => {
    event.preventDefault();

    if (event.keyCode == 8) {
      otp[idx] = "";
      setOtp([...otp]);
      if (idx > 0) inputs?.[`input${idx - 1}`]?.current?.focus();
    }
    if (idx < 4 && mykey.indexOf("" + event.key + "") != -1) {
      otp[idx] = event.key;
      setOtp([...otp]);
      inputs?.[`input${idx + 1}`]?.current?.focus();
    }

    if(error){
      setError(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `in ${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  function setToInit(){
    setOtpDetails({});
    setOtp(["","","",""]);
    setError(false);
  }

  async function sendOTPFun() {
    try {
      setLoading("sending OTP...");
      let payload = {
        mobile_number: data?.mobile_no,
      };
      const { data:dt } = await sendOTP(payload);
      if(dt?.status){
        setOtpDetails({
          verificationId:dt?.verificationId
        })
        startTimer();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(null);
    }
  }

  async function verifyOTPFun(){
    try {
      setLoading("verifying OTP...");
      let payload = {
        otp: otp.join(""),
        ...otpDetails
      };
      const { data:dt } = await verifyOTP(payload);
      if(dt?.status){
        setToInit();
        setVarified(true);
        handleClose();
      }else{
        setError(true);
      }
    } catch (error) {
      setError(true);
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(null);
    }
  }

  async function resendOTPFun() {
    try {
      setLoading("Resending OTP...");
      let payload = {
        mobile_number: data?.mobile_no,
      };
      const { data:dt } = await sendOTP(payload);
      if(dt?.status){
        setOtpDetails({
          verificationId:dt?.verificationId
        });
        restartTimer();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "failed to send otp");
    } finally {
      setLoading(null);
    }
  }

  function handleChange(index) {
    return function (event) {
      otp[index] = event.target.value;
      setOtp(otp);
    };
  }

  function handleClearAll() {
    setOpenCancelOTP(false);
    handleClose();
  }

  useEffect(() => {
    if (open) {
      sendOTPFun();
    }else{
      setToInit();
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} maxWidth="sm">
        <DialogTitle sx={{ justifyContent: "flex-start" }}>
          <IconButton
            color="inherit"
            onClick={() => {
              setOpenCancelOTP(true);
            }}
          >
            {" "}
            <ArrowBackIcon />{" "}
          </IconButton>{" "}
          OTP Verification
        </DialogTitle>
        <DialogContent sx={{ mx: 5, width: 400 }}>
          {loading ? (
            <Grid container>
              <Grid
                item
                sx={{
                  display: "grid",
                  placeItems: "center",
                }}
                xs={12}
              >
                <div class="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
              </Grid>
              <Grid item sx={{
                  display: "grid",
                  placeItems: "center",
                }} xs={12} mt={3} >
              <p style={{color:"black" , fontFamily:'Roboto'}} >{loading}</p>
              </Grid>
            </Grid>
          ) : (
            <Grid spacing={2} container>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold" }}
                  component="h3"
                >
                  Enter the code sent to your number
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="p" component={"p"}>
                  we sent it to the number (+91) {data?.mobile_no}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div class="otp-input-fields">
                  {otp?.map((item, idx) => {
                    return (
                      <input
                        type="number"
                        onKeyUp={handle_next_input(idx)}
                        value={otp?.[idx]}
                        ref={inputs?.[`input${idx}`]}
                        maxLength={1}
                      />
                    );
                  })}
                </div>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                className="d-flex align-items-center justify-content-center p-2"
              >
                {error && <p style={{color:"red" , fontFamily:'Roboto'}} >Wrong OTP</p>}
              </Grid>
              <Grid
                xs={12}
                // item
                sm={12}
                className="d-flex align-items-center justify-content-center"
              >
                <Button
                  variant="outlined"
                  disabled={remainingTime}
                  onClick={resendOTPFun}
                >
                  Resend OTP {remainingTime !== 0 && formatTime(remainingTime)}
                </Button>
              </Grid>
              <Grid
                xs={12}
                item
                sm={12}
                className="d-flex align-items-center justify-content-center"
              >
                <Button
                  variant="contained"
                  disableElevation
                  onClick={verifyOTPFun}
                  color="success"
                >
                  Verify
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <DeletePopup
        confDltPopup={openCancelOTP}
        setConfDltPopup={setOpenCancelOTP}
        text={"Are You sure want to cancel mobile verification !!!"}
        handleDeleteDoc={handleClearAll}
      />
    </>
  );
}

export default OTP;
