import axios from "axios";
import { verifyAuth } from "../api";
import { getDocKey } from "./Formater";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { FileMimeType } from "../Constants/memeTypes";
import { toast } from "react-toastify";

const download = async (
  item,
  zip,
  stopCount = false,
  returnFile = false,
  userDetails,
  setDownloadProgress
) => {
  let { user, setUser } = userDetails;
  let token = user?.token;
  if (!token) {
    let { data } = await verifyAuth();
    token = data?.token;
    setUser({
      ...data.data,
      token,
    });
  }

  let key = item?.key ? item?.key : getDocKey(item?.fileLink)?.key;

  let { data } = await axios.get(
    `https://sen319hxy8.execute-api.ap-south-1.amazonaws.com/dev/view?environment=development&signedUrl=true&key=${key}`,
    {
      method: "get",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        application: "EMBEDDED-TOOLS",
        token,
      },
    }
  );
  let { signedUrl } = data;

  return axios
    .get(signedUrl, {
      responseType: "blob",
    })
    .then(async (resp) => {
      if (returnFile) {
        return resp?.data;
      }

      if (!stopCount) {
        setDownloadProgress((prev) => prev + 1);
      }

      zip.file(
        `${item?.name}.${
          item?.dataType
            ? item?.dataType
            : FileMimeType[getDocKey(item?.fileLink)?.dataType]
        }`,
        resp.data
      );
    })
    .catch((err) => {
      console.log(err);
      if (!stopCount) {
        setDownloadProgress((prev) => prev + 1);
      }
      toast.warn(`Error with file ${item?.name}, will be skipped`);
    });
};

export const downloadAll = async (
  documents,
  filename,
  userDetails,
  setDownloadProgress,
  setLoading
) => {
  console.log("documents -> ", documents);
  setLoading(true);
  try{
    const zip = new JSZip();
    let docArray = documents;
    let promises = [];
    for (const item of docArray) {
      if (item?.fileLink) {
        promises.push(
          await download(item, zip, false, false, userDetails,setDownloadProgress)
        );
        if (promises.length == 5) {
          await Promise.allSettled(promises);
          promises = [];
        }
      }
    }
  
    await Promise.allSettled(promises);
    zip.generateAsync({ type: "blob" }).then(function (blob) {
      saveAs(blob, `${filename}.zip`);
    });
  }catch(error){
    toast.error(error?.response?.data?.message);
  }
  
  setDownloadProgress(0);
  // setIsDownloadingAll(false);
  setLoading(false);
  //   setProgress("");
};

export const handleDownload = async (
  fileLink,
  fileName,
  elem = null,
  setLoading,
  userDetails,
  returnBlob
) => {
  console.log("link => ",fileLink,"name => ",fileName,"ele => ",elem,"loading => ",setLoading,"user => ",userDetails,"return ",returnBlob);
  if(setLoading)
    setLoading(elem);
  if(!elem?.key){
    const aElement = document.createElement("a");
    aElement.setAttribute("download", fileName);
    // const href = URL.createObjectURL(fileLink);
    aElement.href = fileLink;
    // aElement.setAttribute('href', href);
    aElement.setAttribute("target", "_blank");
    aElement.click();
    URL.revokeObjectURL(fileLink);
    if(setLoading)
      setLoading(null);
    return;
  }
  let { user, setUser } = userDetails;
  let token = user?.token;
  if (!token) {
    let { data } = await verifyAuth();
    token = data?.token;
    setUser({
      ...data.data,
      token,
    });
  }
  const { key, dataType } = await getDocKey(fileLink);

  fileName += `.${dataType?.split("/")[1]}`;

  let { data } = await axios.get(
    `https://sen319hxy8.execute-api.ap-south-1.amazonaws.com/dev/view?environment=development&signedUrl=true&key=${key}`,
    {
      method: "get",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        application: "EMBEDDED-TOOLS",
        token,
      },
    }
  );
  let { signedUrl } = data;

  const response = await fetch(signedUrl, {
    method: "get",
  });
  
  const blob = await response.blob();
  
  if(returnBlob) return blob;

  const aElement = document.createElement("a");
  aElement.setAttribute("download", fileName);
  const href = URL.createObjectURL(blob);
  aElement.href = href;
  aElement.setAttribute("target", "_blank");
  aElement.click();
  URL.revokeObjectURL(href);
  if(setLoading)
    setLoading(null);
};



export const dataURItoBlob=(dataURI)=>{
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;

  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  let blob = new Blob([ia], { type: mimeString });

  let url = URL.createObjectURL(blob);

  return url;
}