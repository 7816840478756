import {
  Avatar,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import HomeIcon from "@mui/icons-material/Home";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import useAuth from "../hooks/useAuth";
import { UserContext } from "../Context/UserContext";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { updatePassword } from "../api";
import { toast } from "react-toastify";

const initialData = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const initialErrors = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const Settings = () => {
  const { user } = useContext(UserContext);
  const { logoutUser } = useAuth();

  const [changePassword, setChangePassword] = useState(false);
  const [passwordData, setPasswordData] = useState(initialData);
  const [passwordErrors, setPasswordErrors] = useState(initialErrors);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const isValid = async () => {
    let isValid = true;
    let errors = {};
    if (!passwordData.oldPassword || passwordData.oldPassword === "") {
      isValid = false;
      errors.oldPassword = "Please enter old password";
    }
    if (!passwordData.newPassword || passwordData.newPassword === "") {
      isValid = false;
      errors.newPassword = "Please enter new password";
    }
    if (!passwordData.confirmPassword || passwordData.confirmPassword === "") {
      isValid = false;
      errors.confirmPassword = "Please enter confirm password";
    }
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      isValid = false;
      errors.confirmPassword = "Password does not match";
    }
    if (passwordData.newPassword === passwordData.oldPassword) {
      isValid = false;
      errors.newPassword = "New password should not be same as old password";
    }
    setPasswordErrors(errors);
    return isValid;
  };

  const handleSubmit = async () => {
    setPasswordErrors(initialErrors);
    try {
      if (await isValid()) {
        let { oldPassword, newPassword, confirmPassword } = passwordData;
        let payload = { oldPassword, newPassword, confirmPassword };

        let { data } = await updatePassword(payload);
        if (data?.status) {
          toast.success("Password changed successfully");
          setPasswordData(initialData);
          setChangePassword(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  // useEffect(() => {
  //   console.log(passwordData);
  // }, [passwordData]);

  return (
    <div>
      <div className="back-banner"></div>
      <div className="settings-wrapper">
        <Tabs>
          <TabList>
            <div className="profile-sec">
              <div className="content-center mt-4 mb-2">
                <Avatar
                  alt={user?.name}
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: 120, height: 120 }}
                />
              </div>
              <p className="name">{user?.name}</p>
            </div>
            <Tab>
              <p className="mb-0">
                <HomeIcon sx={{ fontSize: 19 }} /> Account
              </p>
            </Tab>
            <Tab>
              <p className="mb-0">
                <VpnKeyIcon sx={{ fontSize: 19 }} /> Password
              </p>
            </Tab>
            {/* <Tab>
              <p className="mb-0">Title 3</p>
            </Tab>
            <Tab>
              <p className="mb-0">Title 4</p>
            </Tab>
            <Tab>
              <p className="mb-0">Title 5</p>
            </Tab> */}
          </TabList>

          <TabPanel>
            <div className="panel-content">
              <h3>Account Details</h3>
              <p className="pannel-list mt-4">
                User Id: <span>{user?.user_id}</span>
              </p>
              <p className="pannel-list">
                User type: <span>{user?.user_type}</span>
              </p>
              <p className="pannel-list">
                Email: <span>{user?.email}</span>
              </p>
              <p className="pannel-list">
                Role: <span>{user?.role}</span>
              </p>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="panel-content">
              <h3>Password</h3>
              <p className="pannel-list mt-4">
                {!changePassword && (
                  <>
                    Password: <span>* * * * * * * * *</span>{" "}
                    <Button onClick={() => setChangePassword(true)}>
                      Change
                    </Button>
                  </>
                )}
                {changePassword && (
                  <div>
                    <Grid container spacing={2}>
                      <input
                        type="hidden"
                        name="email"
                      />
                      <Grid item xs={12} sm={12}>
                        <TextField
                          fullWidth
                          autoComplete="off"
                          type="password"
                          error={passwordErrors.oldPassword}
                          helperText={passwordErrors.oldPassword}
                          id="outlined-basic"
                          label="Old Password"
                          variant="outlined"
                          value={passwordData.oldPassword}
                          onChange={(e) => {
                            setPasswordData({
                              ...passwordData,
                              oldPassword: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl sx={{ width: "100%" }} variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-password">
                            New Password
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            autoComplete="off"
                            type={showPassword ? "text" : "password"}
                            error={passwordErrors.newPassword}
                            value={passwordData.newPassword}
                            onChange={(e) => {
                              setPasswordData({
                                ...passwordData,
                                newPassword: e.target.value,
                              });
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                          {passwordErrors?.newPassword && (
                            <FormHelperText error id="accountId-error">
                              {passwordErrors?.newPassword}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          type="password"
                          error={passwordErrors.confirmPassword}
                          helperText={passwordErrors.confirmPassword}
                          id="outlined-basic"
                          label="Confirm Password"
                          variant="outlined"
                          value={passwordData.confirmPassword}
                          onChange={(e) => {
                            setPasswordData({
                              ...passwordData,
                              confirmPassword: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <p className="mb-0 text-end" sx={{ float: "right" }}>
                          <Button
                            color="primary"
                            className="mr-2"
                            onClick={() => setChangePassword(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            className="mr-2"
                            onClick={handleSubmit}
                          >
                            Change
                          </Button>
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </p>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default Settings;
