import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import moment from "moment/moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CommentsDialog({
  openComments,
  setOpenComments,
  comments = [],
  handleAddComment,
}) {
  const [commentText, setTextFieldValue] = useState("");
  const handleClose = () => {
    setOpenComments({
      open: false,
      add: false,
    });
  };

  const switchView = () => {
    setOpenComments({
      ...openComments,
      add: !openComments?.add,
    });
  };

  return (
    <div>
      <BootstrapDialog
        maxWidth={"sm"}
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openComments?.open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Comments
          <Button onClick={() => switchView()}>
            {!openComments?.add ? "Add" : "View"}
          </Button>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {!openComments?.add && (
          <DialogContent dividers>
            {comments?.map((val) => {
              return (
                <>
                  <Typography gutterBottom>
                    {val?.comment}
                    <p style={{ textAlign: "end", fontSize: 12 }}>
                      {moment(val?.DateMS).format("DD/MM/YYYY")}
                    </p>
                  </Typography>
                  <hr />
                </>
              );
            })}
          </DialogContent>
        )}
        {openComments?.add && (
          <DialogContent dividers>
            <TextField
              fullWidth
              placeholder="Enter your comment"
              onChange={(e) => {
                setTextFieldValue(e.target.value);
              }}
            />
            <Button
              variant="contained"
              className="mt-3"
              onClick={() => {
                handleAddComment(commentText);
              }}
            >
              Add Comment
            </Button>
          </DialogContent>
        )}
      </BootstrapDialog>
    </div>
  );
}
