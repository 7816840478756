import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import CustomTableV2 from "./CustomTable/CustomTableV2";


const TransactionHistory = ({ open, handleClose, data }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  //   const [data, setData] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [dropDownData, setDropDownData] = React.useState(data);
  const [tableData, setTableData] = React.useState([]);
  const [jsonData, setJsonData] = React.useState(null);
  const [buttonState, setButtonState] = React.useState(1);

  const convertCsvToJson = (csvText) => {
    const lines = csvText.split("\n");
    const headers = lines[0].split(",").map((header) => header.trim());
    const data = [];

    for (let i = 1; i < lines.length; i++) {
      const values = lines[i].split(",");
      const obj = {};

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = values[j] ? values[j].trim() : "";
      }

      data.push(obj);
    }
    setJsonData(data);
  };

  let columns = [
    {
      label: "Batch ID",
      render: (index) => {
        return data?.[index]?.name;
      },
      width: 200,
    },
    {
      label: "Total Transactions",
      render: (index) => {
        return (
          data?.[index]?.result?.success?.length +
            data?.[index]?.result?.failed?.length || 0
        );
      },
      width: 160,
    },
    {
      label: "Success transactions",
      render: (index) => {
        return data?.[index]?.result?.success?.length || 0;
      },
      width: 120,
    },
    {
      label: "Failed transactions",
      render: (index) => {
        return data?.[index]?.result?.failed?.length || 0;
      },
      width: 120,
    },
  ];

  const fetchTableData = async (link) => {
    const url = `${link}&file=true`;
    const { data } = await axios.get(url);
    convertCsvToJson(data);
  };
  React.useEffect(() => {
    setDropDownData(data);
  }, [data]);

  return (
    <>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={() => {
          handleClose();
          setJsonData(null);
          setSelectedValue({});
        }}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            marginBottom: "5px",
          }}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          Transaction History
          <span
            onClick={() => {
              handleClose();
              setJsonData(null);
              setSelectedValue({});
            }}
            style={{ pointerEvents: "auto", cursor: "pointer" }}
          >
            close
          </span>
        </DialogTitle>
        <DialogContent className="d-flex" sx={{ flexDirection: "column" }}>
          {buttonState == 2 ? (
            <Box
              sx={{
                minWidth: 120,
                maxWidth: 200,
                marginTop: "10px",
                marginLeft: "5px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">History</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedValue.name}
                  label="History"
                  // onChange={}
                  onChange={(event) => {
                    setSelectedValue(event.target.value);
                    fetchTableData(event.target.value.file);
                  }}
                >
                  {data.map((ele) => {
                    return <MenuItem value={ele}>{ele.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <>
              <CustomTableV2
                tableData={dropDownData}
                // tableHeight={600}
                columns={columns}
              />
            </>
          )}

          {jsonData && buttonState == 2 && (
            <Grid
              container
              spacing={3}
              sx={{ marginTop: "10px", marginLeft: "5px", width: "95%" }}
            >
              <Grid xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ width: "100%", height: 120 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Application_id</TableCell>
                        <TableCell align="center">payment_mode</TableCell>
                        <TableCell align="center">txn_amount</TableCell>
                        <TableCell align="center">txn_date</TableCell>
                        <TableCell align="center">txn_id</TableCell>
                        <TableCell align="center">txn_utr</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Reason</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {jsonData.map(
                        (row) =>
                          row.application_id && (
                            <TableRow
                              key={row.application_id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                backgroundColor:
                                  selectedValue.result.success.some(
                                    (item) =>
                                      item.application_id === row.application_id
                                  )
                                    ? "#74b874"
                                    : "#f08b8b",
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.application_id}
                              </TableCell>
                              <TableCell align="center">
                                {row.payment_mode}
                              </TableCell>
                              <TableCell align="center">
                                {row.txn_amount}
                              </TableCell>
                              <TableCell align="center">
                                {row.txn_date}
                              </TableCell>
                              <TableCell align="center">{row.txn_id}</TableCell>
                              <TableCell align="center">
                                {row.txn_utr}
                              </TableCell>
                              <TableCell align="center">
                                {selectedValue.result.success.some(
                                  (item) =>
                                    item.application_id === row.application_id
                                )
                                  ? "SUCCESS"
                                  : "FAILED"}
                              </TableCell>
                              <TableCell align="center">
                                {selectedValue.result.failed.some(
                                  (item) =>
                                    item.application_id === row.application_id
                                )
                                  ? selectedValue.result.failed.find(
                                      (item) =>
                                        item.application_id ===
                                        row.application_id
                                    ).errMessage
                                  : ""}
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {/* <Grid xs={2} sx={{ marginLeft: "5px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignContent: "center",
                    height: "80%",
                    width: "300px",
                  }}
                >
                  <Box
                    // component="section"
                    sx={{ p: 2, border: "1px dashed grey" }}
                  >
                    success : {selectedValue?.result?.success?.length}
                  </Box>
                  <Box
                    // component="section"
                    sx={{ p: 2, border: "1px dashed grey" }}
                  >
                    Failed: {selectedValue?.result?.failed?.length}
                  </Box>
                </div>
              </Grid> */}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setButtonState(1);
              setJsonData(null);
              setSelectedValue({});
            }}
          >
            Overview
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setButtonState(2);
              setSelectedValue({});
              setJsonData(null);
            }}
          >
            Detailed View
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TransactionHistory;
