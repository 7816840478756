import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  createApplication,
  fetchAllApplications,
  updateApplication,
  updateStage,
  updateStageV2,
} from "../../api";
import { GlobalContext } from "../../Context/GlobalContext";
import { toast } from "react-toastify";
import useApplication from "../../hooks/useApplication";
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Slide,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { statusColors } from "../../Constants/colors";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import EditIco from "../../assets/edit.svg";
import { f, formatDate, fStatus } from "../../utility/Formater";
import { useState } from "react";
import { stageList } from "../../utility/StagesV2";
import uploadIco from "../../assets/edit-stage.png";
import { useEffect } from "react";
import moment from "moment";
import DatePickerComponent from "../../Components/DatePicker";
import { DatePicker } from "@material-ui/pickers";
import CustomColorPick from "../../Components/ColorPicker/ColorPicker";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getBase64 } from "../../utility/convertFileBase";
import ImageComponent from "../../Components/ImageUpload/ProductImage";
import { isAmountValid, isBlank } from "../../utility/validations";
import { addBrand,
   addProducts,
   getAllBrands ,
   updateProductDetails ,
   deleteProduct,
   deleteProductImage,
   getProductData,
   addProductImage } from "../../api/products";
const theme = createTheme();
const initialProductState = {
  name: "",
  category: "",
  brand: "",
  ex_price: "",
  range: "",
  battery: "",
  battery_type: "",
  charging_time: "",
  charging_volt: "",
  speed: "",
  average: "",
  on_price: "",
  max_load: "",
  seat_capacity: "",
  body_material: "",
  motor: "",
  colors: [],
  dealers: [],
  waiting_period: "",
  images: [],
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const initialParams = {
  deleteCollection: true,
  deleteCoCustomer: true,
  deleteCollectionSchedule: true,
  deleteCustomer: true,
  deleteBusiness: true,
  deleteBankDetails: true,
};


export default function AddProduct() {
  const { updateDetailState, customersList, panData, clearUpdateState } =
    React.useContext(GlobalContext);

  const navigate = useNavigate();
  const { app_id } = useParams();
  const { state } = useLocation();
  const [updateDetails, setUpdateDetails] = updateDetailState;

  const [doneUpdate, setDoneUpdate] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [brands, setBrands] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [productData, setProductData] = useState(initialProductState);
  const [brandData, setBrandData] = useState({
    open: false,
    name: "",
    oem: "",
  });

  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteParams, setDeleteParams] = useState(initialParams);
  const [confirmText, setConfirmText] = useState("");
  const [isDeleteImage,setIsDeleteImage] = useState(false);
  const [selectImage,setSelectImage] = useState(null);
  const [images, setImages] = useState([])

  const handleDeleteApplication = async () => {
    try {
      let { data } = await deleteProduct(state?.productId);
      navigate("/evproducts");
      toast.success(data?.message);
      // toast.success("Product deleted successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setOpenDelete(false);
  };

  const handleImageDeletion = async ()=>{
    try{
      let { data } = await deleteProductImage({
        "productid": productData?._id,
        "isupload": selectImage?.isUpload,
        "imgkey": selectImage.key || selectImage.fileLink
      });
      await fetchProductData();
      toast.success("Image deleted successfully");
    }catch(error){
      toast.success(error.message);
    }
    setSelectImage(null);
    setIsDeleteImage(false);
    setOpenDelete(false);
  }

  const handleImageAdd=async (val)=>{
    try{
      let { data } = await addProductImage({
          isupload:val.isUpload,
          productid:state.productId,
          imgkey:val.file || val.link
        })
        // console.log(data);
        setImages([...images, data?.data]);
    }catch(error){
      toast.error(error.message);
    }
  }

  const fetchProductData=async ()=>{
    try{
      const {data} = await getProductData(state.productId);
      setProductData({
        ...data?.data,
      })
      if(data?.data?.images)
        setImages( [ ... data?.data?.images])
    }catch(error){}
  }

  const handleClickOpen = () => {
    // if (
    //   ["DISBURSAL", "DISBURSEMENT"]?.includes(stageData?.stage) &&
    //   stageData?.status === "APPROVED" &&
    //   stageData?.sub_status === "DISBURSED"
    // ) {
    //   setOpenDisbursal(true);
    // } else {
    //   setOpen(true);
    // }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Disbursal Modal States

  const [openDisbursal, setOpenDisbursal] = React.useState(false);

  const handleOpenDisbursal = () => {
    setOpenDisbursal(true);
  };

  const handleCloseDisbursal = () => {
    setOpenDisbursal(false);
  };

  
  const handleDeleteParams = (e, name) => {
    setDeleteParams({
      ...deleteParams,
      [name]: e.target.checked,
    });
  };

  const handleChange =
    (prop, type = "text") =>
    (event) => {
      setErrors({
        ...errors,
        [prop]: "",
      });
      setProductData({
        ...productData,
        [prop]: event.target.value,
      });
    };

  const isValid = async () => {
    let valid = true;
    try {
      let errors = {
        name: isBlank(productData?.name),
        category: isBlank(productData?.category),
        // ex_price: isAmountValid(productData?.ex_price),
        brand: isBlank(productData?.brand),
      };

      setErrors(errors);

      for (let i in errors) {
        if (errors[i].length > 0) {
          //   if (!first) {
          //     first = true;
          //     refs[i]?.current?.focus();
          //     window.scrollTo(0, refs[i]?.current?.offsetTop - 100);
          //   }
          valid = false;
        }
      }

      return valid;
    } catch (error) {}
  };

  const handleSubmit = async () => {
    setLoading(true)
    try {
      if (await isValid()) {
        //Note :- productId is to check whether the product is edited
        state?.productId ? await updateProductDetails(productData._id,productData) : await addProducts(productData);
        state?.productId ? toast.success("Product updated successfully!") :toast.success("Product Added successfully!");
        navigate("/evproducts");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
    setLoading(false);
  };

  const handleAddBrand = async () => {
    if (brandData?.name === "") {
      toast.error("Please provide a valid brand Name!");
      return false;
    }
    try {
      let { data } = await addBrand({
        name: brandData?.name,
        oem_id: "",
      });
      toast.success("Brand Added successfully!");
      setBrandData({
        ...brandData,
        name: "",
        oem: "",
      });
      fetchBrands();
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      // console.log(error?.response?.data?.message);
    }
  };

  const fetchBrands = async () => {
    try {
      let { data } = await getAllBrands();
      setBrands(data?.data?.brands || []);
    } catch (error) {
      console.log(error);
    }
  };


  const handleCloseBrand = () => {
    setBrandData({
      open: false,
      name: "",
      oem: "",
    });
  };

  useEffect(() => {
    fetchBrands();
  }, []);
  
  useEffect(()=>{
    fetchProductData();
  },[state]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <p
          className="go-back-btn"
          onClick={() => {
            if (state?.path) {
              navigate(state?.path);
            } else {
              navigate("/tools");
            }
          }}
        >
          <ArrowBackIcon /> Go back Home
        </p>
        <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <div style={{display:"flex",justifyContent:"space-between"}} >
                  <p style={{ color: "gray" }}>{ state?.productId ? "Edit Product" : "Add Product"}</p>
                  {state?.productId? <Button 
                    variant="contained" 
                    color="error"
                    onClick={()=>{
                      setOpenDelete(true);
                    }}
                    >DELETE PRODUCT</Button> : ''}
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="name"
                  name="name"
                  label="Product Name"
                  fullWidth
                  required
                  value={productData?.name}
                  onChange={handleChange("name")}
                  error={errors?.name}
                  helperText={errors?.name}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={errors?.category}
                  helperText={errors?.category}
                  required
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={productData?.category}
                    onChange={handleChange("category")}
                    label="Category"
                  >
                    <MenuItem value={"ERICK"}>Erick (L3)</MenuItem>
                    <MenuItem value={"EAUTO"}>EAuto</MenuItem>
                    <MenuItem value={"ELOADER"}>E-Loader (L5)</MenuItem>
                    <MenuItem value={"ESCOOTY"}>E-Scooty LS</MenuItem>
                    <MenuItem value={"ESCOOTYHS"}>E-Scooty HS</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="ex_price"
                  name="ex_price"
                  label="Ex-showroom price"
                  fullWidth
                  type={"number"}
                  required
                  onWheel={(e) => e.target.blur()}
                  value={productData?.ex_price}
                  onChange={handleChange("ex_price")}
                  error={errors?.ex_price}
                  helperText={errors?.ex_price}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="on_price"
                  name="on_price"
                  label="On Road Price"
                  fullWidth
                  type={"number"}
                  onWheel={(e) => e.target.blur()}
                  value={productData?.on_price}
                  onChange={handleChange("on_price")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="range"
                  name="range"
                  label="Range"
                  fullWidth
                  type={"number"}
                  onWheel={(e) => e.target.blur()}
                  value={productData?.range}
                  onChange={handleChange("range")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={errors?.category}
                  helperText={errors?.category}
                  required
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Brand
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={productData?.brand}
                    onChange={(e) => {
                      if (e.target.value === "button") {
                        setBrandData({
                          ...brandData,
                          open: true,
                        });
                        return false;
                      }
                      setErrors({
                        ...errors,
                        brand: "",
                      });
                      setProductData({
                        ...productData,
                        brand: e.target.value,
                      });
                    }}
                    label="Brand"
                  >
                    {brands?.map((val) => {
                      return (
                        <MenuItem value={val?.brand_id}>{val?.name}</MenuItem>
                      );
                    })}
                    <MenuItem value={"button"}>
                      <Button className="w-100">ADD NEW BRAND</Button>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="battery"
                  name="battery"
                  label="Battery AH"
                  fullWidth
                  type={"number"}
                  onWheel={(e) => e.target.blur()}
                  value={productData?.battery}
                  onChange={handleChange("battery")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="battery_type"
                  name="battery_type"
                  label="Battery Type"
                  fullWidth
                  value={productData?.battery_type}
                  onChange={handleChange("battery_type")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="charging_time"
                  name="charging_time"
                  label="Charging Time"
                  fullWidth
                  value={productData?.charging_time}
                  onChange={handleChange("charging_time")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="charging_volt"
                  name="charging_volt"
                  label="Charging Volt"
                  fullWidth
                  value={productData?.charging_volt}
                  onChange={handleChange("charging_volt")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="speed"
                  name="speed"
                  label="Speed"
                  fullWidth
                  value={productData?.speed}
                  onChange={handleChange("speed")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="average"
                  name="average"
                  label="Average"
                  fullWidth
                  value={productData?.average}
                  onChange={handleChange("average")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="max_load"
                  name="max_load"
                  label="Maximum Load"
                  fullWidth
                  value={productData?.max_load}
                  onChange={handleChange("max_load")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="seat_capacity"
                  name="seat_capacity"
                  label="Seat Capacity"
                  fullWidth
                  type={"number"}
                  onWheel={(e) => e.target.blur()}
                  value={productData?.seat_capacity}
                  onChange={handleChange("seat_capacity")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="body_material"
                  name="body_material"
                  label="Body material"
                  fullWidth
                  value={productData?.body_material}
                  onChange={handleChange("body_material")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="motor"
                  name="motor"
                  label="Motor"
                  fullWidth
                  value={productData?.motor}
                  onChange={handleChange("motor")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="waiting_period"
                  name="waiting_period"
                  label="Waiting Period"
                  fullWidth
                  value={productData?.waiting_period}
                  onChange={handleChange("waiting_period")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <p style={{ color: "gray" }}>Vehicle Colors</p>
                <div className="d-flex">
                  {productData?.colors?.map((val, i) => {
                    return (
                      <div className="me-2">
                        <CustomColorPick
                          color={val}
                          onChange={(val) => {
                            let colors = productData?.colors;
                            colors[i] = val;
                            setProductData({
                              ...productData,
                              colors,
                            });
                          }}
                        />
                      </div>
                    );
                  })}

                  <div>
                    <AddCircleOutlineIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        let colors = productData?.colors;
                        setProductData({
                          ...productData,
                          colors: [...colors, "#ffffff"],
                        });
                      }}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12}>
                <p style={{ color: "gray" }}>Vehicle Images</p>
                <div className="d-flex">
                  { images?.map((val) => {
                    return !(val?.file || val?.fileLink || val?.link ) ? "" :(
                      <div
                        style={{
                          position:"relative",
                          background: "gray",
                          borderRadius: 10,
                          marginRight: 10,
                        }}
                      >
                        <embed
                          src={val?.file || val?.fileLink || val?.link}  
                          width={100}
                          height={100}
                          style={{
                            objectFit: "contain",
                          }}
                        >
                        </embed>
                        {val.fileLink && <CloseIcon sx={{
                          cursor:"pointer",
                          position:"absolute",
                          top:5,
                          right:5,
                          fontSize:"20px",
                          color:"red",
                          backgroundColor:"black"}}
                            onClick={()=>{
                              setIsDeleteImage(true);
                              setOpenDelete(true);
                              setSelectImage(val);
                            }}
                        />}
                      </div>
                    );
                  })}
                  <ImageComponent
                    onChange={(val) => {
                      let images = productData?.images;
                      setProductData({
                        ...productData,
                      });
                      handleImageAdd(val);
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  {isLoading ? (
                    <>
                      <CircularProgress size={20} className="me-2" />
                      Loading
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        className="me-2"
                        onClick={handleSubmit}
                      >
                        {state.productId ? "Save" :"Add Product" }
                      </Button>
                      {/* <Button
                        onClick={() => {
                          //   setStageData(intialStageData);
                          clearUpdateState();
                          //   setApplicationId("");
                        }}
                      >
                        Cancel
                      </Button> */}
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </ThemeProvider>

      <div>
        <Dialog
          open={brandData?.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseBrand}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Add Brand"}</DialogTitle>
          <DialogContent>
            <div
              style={{
                width: 500,
              }}
            >
              <Grid container spacing={3} className="mt-1">
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="brand_name"
                    name="brand_name"
                    label="Brand name"
                    fullWidth
                    required
                    value={brandData?.name}
                    onChange={(e) => {
                      setBrandData({
                        ...brandData,
                        name: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseBrand}>Cancel</Button>
            <Button onClick={handleAddBrand}>Add</Button>
          </DialogActions>
        </Dialog>
      <Dialog
        onClose={() => {
          setOpenDelete(false);
          setIsDeleteImage(false);
        }}
        open={openDelete}
      >
        <DialogContent>
          <div className="p-3">
            <p
              style={{
                marginBottom: 0,
                fontWeight: 500,
                color: "red",
                fontSize: 20,
              }}
            >
              Are you sure to DELETE this {isDeleteImage?"Image":"product"} ?
            </p>
            {isDeleteImage===false && <div className="mt-3">
                Confirm by typing "DELETE" in the box below
                <TextField
                  fullWidth
                  value={confirmText}
                  placeholder="DELETE"
                  onChange={(e) => {
                    setConfirmText(e.target.value);
                  }}
                />
              </div>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDelete(false);
              if(isDeleteImage){
                setSelectImage(null);
                setIsDeleteImage(false);
              }
            }}
          >
            Cancel
          </Button>
            <Button
              onClick={() => {
                if(isDeleteImage){
                  handleImageDeletion() 
                }else{
                  setIsDeleteImage(false);
                  handleDeleteApplication()
                };
              }}
              disabled={isDeleteImage ? false : confirmText !== "DELETE"}
            >
              DELETE
            </Button>
        </DialogActions>
      </Dialog>
      </div>
    </>
  );
}

const ListItemComp = ({ icon, heading, text }) => {
  return (
    <ListItem disablePadding>
      <ListItemIcon className="list-key">
        {
          <span>
            {icon && icon}
            {heading}
          </span>
        }
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ fontSize: "15px" }}
        className="list-value"
        primary={text}
      />
    </ListItem>
    
  );
};