import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Pagination,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./CustomTable.css";
import Loader from "../Loader/Loader";
import noDataIco from "../../assets/nodata.gif";
import { toast } from "react-toastify";
import { useRef } from "react";

const CustomTable = ({
  tableData,
  tableHeight,
  columns,
  defaultLimit,
  tablePage,
  tableLimit,
  totalCount,
  onPageChange,
  onLimitChange,
  onSortChange,
  isLoading,
  onDoubleClick,
  serialNo,
  stickSL,
  allowAll,
  pageLoading,
  sortIcon,
  table = "",
  noPagination = false,
  blueTheme = false,
  id
}) => {
  const [limit, setLimit] = useState(defaultLimit);
  const [count, setCount] = useState(totalCount);
  const [page, setPage] = useState(1);
  const [sortData, setSortData] = useState({
    column: "",
    value: 1,
  });
  const [isSort, setIsSort] = useState(true);

  const customTableRef = useRef(null);
  useEffect(() => {
    setCount(Math.ceil(totalCount / limit));
  }, [tableData]);

  useEffect(() => {
    if (tablePage && tableLimit) {
      setPage(tablePage);
      setLimit(tableLimit);
      console.log("custome table change ->", tablePage, tableLimit);
    }
  }, [tablePage, tableLimit]);

  useEffect(() => {
    if (pageLoading && customTableRef.current) {
      customTableRef.current.scrollTop = 0;
      customTableRef.current.scrollLeft = 0;
    }
  }, [pageLoading]);

  return !isLoading && tableData?.length === 0 ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center"
      style={{ height: "60vh" }}
    >
      <div>
        <img src={noDataIco} style={{ width: "300px" }} />
        <p className="w-100 text-center">Nothing to display</p>
      </div>
    </div>
  ) : (
    <>
      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          <div
            ref={customTableRef}
            className="custom-table"
            style={{
              overflow: pageLoading ? "hidden" : "scroll",
              height: tableHeight || "80vh",
              width: "100%",
              position: "relative",
            }}
          >
            {pageLoading && (
              <div className="page-loader">
                <CircularProgress />
              </div>
            )}
            <table id={id}>
              <thead>
                <tr className="custom-table-heading">
                  {serialNo && (
                    <th
                      className={`${stickSL ? "col-sticky-head" : ""} ${
                        blueTheme ? "th-blue" : ""
                      }`}
                    >
                      <div className="table-head-holder pe-2">SL.No</div>
                    </th>
                  )}
                  {columns.map((column, i) => (
                    <th
                      className={`${
                        column?.isSortable
                          ? `pointer ${column?.freeze ? "col-sticky-head" : ""}`
                          : `${column?.freeze ? "col-sticky-head" : ""}`
                      } ${blueTheme ? "th-blue" : ""}`}
                      style={{
                        left: column?.left ? column?.left : "none",
                        right: column?.right ? column?.right : "none",
                      }}
                      onClick={() => {
                        if (column?.isSortable) {
                          onSortChange(
                            page,
                            null,
                            sortData.value === 1 ? -1 : 1,
                            column.value
                          );
                          setSortData({
                            value: sortData.value === 1 ? -1 : 1,
                            column: column.value,
                          });
                          setIsSort(sortIcon || true);
                        }
                      }}
                    >
                      <div
                        className="table-head-holder"
                        style={{
                          textAlign: column?.align ? column?.align : "left",
                          paddingRight: column?.align === "right" ? "10px" : "",
                        }}
                      >
                        {column.label}{" "}
                        {sortData.column === column.value && (
                          <span>
                            {isSort ? (
                              sortData?.value === 1 ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownIcon />
                              )
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.map((data, i) => (
                  <tr
                    onDoubleClick={() => {
                      onDoubleClick && onDoubleClick(data);
                    }}
                  >
                    {serialNo && (
                      <td
                        className={stickSL ? `col-sticky` : ""}
                        style={{
                          background: i % 2 !== 0 ? "#f8f7ff" : "",
                        }}
                      >
                        {limit * (page - 1) + i + 1}
                      </td>
                    )}
                    {[...Array(columns.length)].map((_, index) => (
                      <td
                        className={
                          columns[index]?.copy
                            ? `pointer ${
                                columns[index]?.freeze ? "col-sticky" : ""
                              }`
                            : `${columns[index]?.freeze ? "col-sticky" : ""}`
                        }
                        style={{
                          ...(columns[index]?.freeze
                            ? {
                                background: i % 2 !== 0 ? "#f8f7ff" : "",
                              }
                            : {}),
                          left: columns[index]?.left
                            ? columns[index]?.left
                            : "none",
                          right: columns[index]?.right
                            ? columns[index]?.right
                            : "none",
                        }}
                        onClick={() => {
                          if (columns[index]?.copy) {
                            navigator.clipboard.writeText(
                              tableData[i]?.[columns[index]?.value]
                            );
                            toast.success("Copied to Clipboard", {
                              position: "top-center",
                              autoClose: 500,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "dark",
                            });
                          }
                        }}
                      >
                        <Tooltip
                          title={columns[index]?.copy ? "Click to copy" : ""}
                        >
                          <p
                            className="mb-0"
                            style={{
                              width: columns?.[index]?.width,
                              textAlign: columns[index]?.align
                                ? columns[index]?.align
                                : "left",
                            }}
                          >
                            {columns[index].render
                              ? columns[index].render(i)
                              : (() => {
                                  let value = data;
                                  columns[index].value
                                    .split(".")
                                    .forEach((key) => {
                                      value = value?.[key];
                                    });
                                  return value;
                                })()}
                          </p>
                        </Tooltip>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {!noPagination && (
        <div className="d-flex" style={{ opacity: isLoading ? 0 : 1 }}>
          <div className="w-100 d-flex justify-content-center pt-3">
            <FormControl>
              <NativeSelect
                defaultValue={defaultLimit}
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
                onChange={(e) => {
                  setPage(1);
                  setLimit(e.target.value);
                  onLimitChange(1, e.target.value);
                }}
              >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={500}>500</option>
                <option value={1000}>1000</option>
                {false && <option value={totalCount}>All</option>}
              </NativeSelect>
            </FormControl>

            <Pagination
              count={count}
              defaultPage={page}
              page={page}
              variant="outlined"
              color="primary"
              onChange={(e, page) => {
                if (sortData?.column === "") {
                  setPage(page);
                  onPageChange(page, limit);
                } else {
                  setPage(page);
                  onSortChange(page, limit, sortData.value, sortData.column);
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomTable;
