import CommentIcon from "@mui/icons-material/Comment";
import { Button } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { toast } from "react-toastify";
import CustomTableV2 from "../../../Components/CustomTable/CustomTableV2";
import { gpsCallingDashboard } from "../../../api";
import { f } from "../../../utility/Formater";
import CommentsModal from "../../../Components/Comments/Comments";

const GPSInstallationChase = forwardRef(({fetchTableData},ref) => {
  const [tableLoading, setTableLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(100);
  const [totalCount, setTotalCount] = useState(0);
  const [openView, setopenView] = useState(false);
  const [applicationData, setApplicationData] = useState({});

  let columns = [
    {
      label: <span className="text-nowrap">Application ID</span>,
      render: (idx) =>f(tableData?.[idx]?.application_id),
    },
    {
      label: <span className="text-nowrap">Customer Name</span>,
      render: (idx) =>f(tableData?.[idx]?.customer_name),
    },
    {
      label: <span className="text-nowrap">Customer Number</span>,
      render: (idx) =>f(tableData?.[idx]?.customer_number),
    },
    {
      label: <span className="text-nowrap">Follow Up Date</span>,
      render: (idx) =>f(tableData?.[idx]?.follow_up_date,"date"),
    },
    {
      label: <span className="text-nowrap">Comments</span>,
      render: (idx) =><Button
      onClick={() => {
        setApplicationData({
          index:idx,
          application_id: tableData?.[idx]?.application_id,
          customer_name: tableData?.[idx]?.customer_name,
        });
        setopenView(true);
      }}
    >
      <CommentIcon />
    </Button>,
    },
    {
      label: <span className="text-nowrap">GPS Status</span>,
      render: (idx) =><span className="text-nowrap">{f(tableData?.[idx]?.gps_status)}</span>,
    },
    {
      label: <span className="text-nowrap">NBFC</span>,
      render: (idx) =><span className="text-nowrap">{f(tableData?.[idx]?.nbfc_name)}</span>,
    },
    {
      label: <span className="text-nowrap">DPD</span>,
      render: (idx) =><span className="text-nowrap" >{f(tableData?.[idx]?.dpd_days)}</span>,
    },
    {
      label: <span className="text-nowrap">Dealer Name</span>,
      render: (idx) =><span className="text-nowrap" >{f(tableData?.[idx]?.dealer_name)}</span>,
    },
    {
      label: <span className="text-nowrap">Dealer Number</span>,
      render: (idx) =>f(tableData?.[idx]?.dealer_number),
    },
  ];

  async function handleFetchData(search,filter,pg,setLoader){
    try{
        if(setLoader) setLoader(true);
        setTableLoading(true);
        if(pg) setPage(pg);
        let params = `page=${pg ?? page}&limit=${limit}`;
        if(search) params+=`&search=${search}`;
        if(filter) params+=`&filters=${JSON.stringify(filter)}`;
        const { data } = await gpsCallingDashboard(params);
        setTableData(data?.data || []);
        setTotalCount(data?.count || 0);
    }catch(error){
        toast.error(error?.response?.data?.message || error?.message || 'failed to fetch data');
    }finally{
        setTableLoading(false);
        if(setLoader) setLoader(false);
    }
  }

  useImperativeHandle(ref, () => ({
    handleFilterData(search,filter,page,setLoader){
      handleFetchData(search,filter,page,setLoader);
    },
  }));

  return (
    <div className="w-100 mt-1">
      <CustomTableV2
        serialNo={true}
        tableHeight={"58vh"}
        columns={columns}
        tableData={tableData}
        loadingRows={20}
        isLoading={tableLoading}
        isPaginated={true}
        totalCount={totalCount}
        page={page}
        limit={limit}
        onPageChange={(pg) => {
          setPage(pg);
          fetchTableData(pg);
        }}
      />
      <CommentsModal
        openView={openView}
        applicationData={applicationData}
        handleCloseView={()=>{setopenView(false)}}
        handleTableUpdate={()=>{
          fetchTableData();
        }}
        defaultType={"GPS INSTALLATION"}
      />
    </div>
  );
});

export default GPSInstallationChase;
