import { Add, Close, Edit } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Zoom,
  styled,
  tableCellClasses,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import GetAppIcon from "@mui/icons-material/GetApp";
import "./GPStable.css";
import React, { useEffect, useState } from "react";
import GPSPopup from "./GPSPopup";
import { toast } from "react-toastify";
import {
  exportGpsInventory,
  fetchAnchorGPSInventory,
  getGpsInventory,
} from "../../api";
import CountUp from "react-countup";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E6EDFF",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const initFilters = {
  procurer: "both",
  money_received: "both",
};

function GPSOverviewTable({ open, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [titlesLoading, setTitlesLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [inventoryDetails, setInventoryDetails] = useState({});
  const [filters, setFilters] = useState(initFilters);
  const [exportLoading, setExportLoading] = useState(false);

  async function getGPSOverviewData() {
    try {
      setLoading(true);
      setTitlesLoading(true);
      let params = `filters=${encodeURIComponent(JSON.stringify(filters))}`;
      const { data } = await getGpsInventory(params);
      setInventoryDetails({
        gps_installed: data?.total_gps_installed || 0,
        gps_remaining: data?.total_gps_remaining || 0,
        installation_target: data?.total_installation_target || 0,
        sent_to_dealer: data?.total_sent_to_dealer || 0,
      });
      setTableData(data?.data || []);
      setAllTableData(data?.data || []);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
      setTitlesLoading(false);
    }
  }

  async function handleSearch(value) {
    try {
      setLoading(true);
      let filteredData = allTableData?.filter((item) =>
        item?.anchor_name?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setTableData(filteredData);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleExport() {
    try {
      setExportLoading(true);

      const { data } = await exportGpsInventory();
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Settled-collection-data.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setExportLoading(false);
    }
  }

  useEffect(() => {
    if (open) getGPSOverviewData();
  }, [filters]);

  useEffect(() => {
    if (open) {
      setFilters({ ...initFilters });
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
        <DialogTitle sx={{ backgroundColor: "#EFF5FF" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>GPS Details</span>
            <span>
              <Button
                variant="outlined"
                size="small"
                disabled={exportLoading}
                onClick={handleExport}
              >
                <GetAppIcon fontSize="small" className="me-1" />
                Export
              </Button>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </span>
          </div>
          <div
            className="my-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <TextField
              className="search-bar-table me-2"
              id="input-with-icon-textfield"
              placeholder="Search Dealer Name"
              variant="standard"
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: "gray", mr: 1 }} />,
                disableUnderline: true,
                style: { color: "black", fontWeight: 400, height: 25 },
              }}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
            />
            <FormControl className="mx-3" size="small" sx={{ width: 180 }}>
              <InputLabel id="demo-simple-select-label">
                Procurer Name
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filters.procurer}
                label="Procurer Name"
                onChange={(e) => {
                  setFilters((prev) => ({ ...prev, procurer: e.target.value }));
                }}
              >
                <MenuItem value={"mufin"}>Mufin</MenuItem>
                <MenuItem value={"mmi"}>MMI</MenuItem>
                <MenuItem value={"both"}>Both</MenuItem>
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ width: 250 }}>
              <InputLabel id="demo-simple-select-label">
                Money Received
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filters.money_received}
                label="Money Received"
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    money_received: e.target.value,
                  }));
                }}
              >
                <MenuItem value={"Deducted from Loan Amount"}>
                  Deducted from Loan Amount
                </MenuItem>
                <MenuItem value={"Advance"}>Advance</MenuItem>
                <MenuItem value={"both"}>Both</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{ marginTop: 5 }}>
            <Grid item container spacing={2}>
              <OverViewCard
                isLoading={titlesLoading}
                heading={"Installation Target"}
                number={inventoryDetails?.installation_target}
              />
              <OverViewCard
                isLoading={titlesLoading}
                heading={"Recieved by Dealer"}
                number={Number(inventoryDetails?.sent_to_dealer)}
              />
              <OverViewCard
                isLoading={titlesLoading}
                heading={"No. Of GPS Installed"}
                number={inventoryDetails?.gps_installed}
              />
              <OverViewCard
                isLoading={titlesLoading}
                heading={"Inventory Remaining"}
                number={inventoryDetails?.gps_remaining}
              />
            </Grid>
          </div>
        </DialogTitle>
        <DialogContent sx={{ width: "100%", px: 0 }}>
          <Zoom in={true}>
            <TableContainer
              // component={Paper}
              sx={{
                height: 400,
                width: "100%",
                "::-webkit-progress-bar": { display: "none" },
              }}
            >
              <Table
                stickyHeader
                sx={{ width: "100%" }}
                aria-label="customized table"
              >
                <TableHead>
                  <Grow
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...{ timeout: 1000 }}
                  >
                    <TableRow>
                      <StyledTableCell align="left" width={20}>
                        SL.&nbsp;No.
                      </StyledTableCell>
                      <StyledTableCell align="left" width={150}>
                        Dealer&nbsp;Name
                      </StyledTableCell>
                      <StyledTableCell align="left" width={150}>
                        Installation target
                      </StyledTableCell>
                      <StyledTableCell align="left" width={150}>
                        Recieved by Dealer
                      </StyledTableCell>
                      <StyledTableCell align="left" width={150}>
                        No. of GPS intalled
                      </StyledTableCell>
                      <StyledTableCell align="left" width={150}>
                        Inventory Remaining
                      </StyledTableCell>
                    </TableRow>
                  </Grow>
                </TableHead>
                <TableBody>
                  {loading
                    ? new Array(6).fill(null).map((item, index) => {
                        return (
                          <Grow
                            in={true}
                            style={{ transformOrigin: "0 0 0" }}
                            {...{ timeout: 1000 }}
                          >
                            <StyledTableRow>
                              <StyledTableCell align="left">
                                <Skeleton />
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Skeleton />
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Skeleton />
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Skeleton />
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Skeleton />
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Skeleton />
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Skeleton />
                              </StyledTableCell>
                            </StyledTableRow>
                          </Grow>
                        );
                      })
                    : tableData?.map((item, index) => (
                        <Grow
                          in={true}
                          style={{ transformOrigin: "0 0 0" }}
                          {...{ timeout: 1000 }}
                        >
                          <StyledTableRow>
                            <StyledTableCell align="left">
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item?.anchor_name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item?.installation_target}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item?.sent_to_dealer}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item?.gps_installed}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item?.gps_remaining}
                            </StyledTableCell>
                          </StyledTableRow>
                        </Grow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Zoom>
        </DialogContent>
      </Dialog>
    </>
  );
}

const OverViewCard = ({ heading, number, isLoading }) => {
  return (
    <Grid item xs={12} sm={2.5}>
      <TextField
        disabled
        id="filled-basic"
        label={heading}
        variant="filled"
        // value={isLoading ? "Loading..." :[null, undefined].includes(number) ? "NA" : number}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                backgroundColor: "#fff",
                height: 56,
                width: 50,
                marginLeft: -12,
                padding: "auto",
                border: "0px solid green",
              }}
            >
              <InputAdornment
                sx={{
                  // backgroundColor: (theme) => theme.palette.divider,
                  marginLeft: 1.5,
                  color: "#535353",
                }}
                position="start"
              >
                <>
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : [null, undefined].includes(number) ? (
                    "NA"
                  ) : (
                    <>
                      {" "}
                      <CountUp
                        style={{
                          flex: 1,
                          // backgroundColor: "pink",
                          padding: 0,
                        }}
                        end={number}
                      />
                    </>
                  )}
                </>
              </InputAdornment>
            </div>
          ),
        }}
        sx={{
          border: "1px solid rgba(68, 68, 68, 0.31)",
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
            backgroundColor: "#fff",
          },
        }}
      />
    </Grid>
  );
};

export default GPSOverviewTable;
