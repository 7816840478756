import { Skeleton, Button } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { statgeMap } from "../../../utility/Stages";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Fab from "@mui/material/Fab";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import noDataIco from "../../../assets/nodata.gif";
import { base64ToPdf, f, fCollectionStatus } from "../../../utility/Formater";
import {
  downloadTxnReciept,
  downloadTxnRecieptV2,
  editTransactionV2,
  fetchTransactionHistory,
  fetchTransactionHistoryV2,
} from "../../../api";
import { toast } from "react-toastify";
import UpdateCollection from "./UpdateCollection";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LoadingOver from "../../../Components/Loading";
import FileViewer from "../../../Components/FileViewer/FileViewer";

const docViewInitial = {
  open: false,
};


const TransationHistoryV2 = ({
  applicationId,
  transactionData,
  setTransactionData,
  customerName,
  setCollectionUpdate,
  isLoader
}) => {
  const scrollRef = useRef(null);
  const [scroll, setScroll] = useState("Bottom");
  const [overLoading, setOverLoading] = useState(false);
  const [docViewer, setDocViewer] = useState(docViewInitial);

  function capitalizeFirstLetter(string) {
    if (string?.length > 0) {
      return (string?.charAt(0)?.toUpperCase() + string?.slice(1))?.replaceAll(
        "_",
        " "
      );
    }
  }

  const handleScroll = (event) => {
    if (
      event.currentTarget.scrollTop >
      event.currentTarget.offsetHeight - event.currentTarget.offsetHeight / 2
    ) {
      setScroll("Top");
    }

    if (event.currentTarget.scrollTop < event.currentTarget.scrollHeight / 2) {
      setScroll("Bottom");
    }
  };

  function capitalizeFirstLetter(string) {
    if (string?.length > 0) {
      return (string?.charAt(0)?.toUpperCase() + string?.slice(1))?.replaceAll(
        "_",
        " "
      );
    }
  }

  const handleDownload = async (id, fileName) => {
    try {
      setOverLoading(true);
      let { data } = await downloadTxnRecieptV2({
        transaction_id: id,
      });

      await base64ToPdf(data?.base64, fileName);
    } catch (error) {}
    setOverLoading(false);
  };

  return (
    <>
      <div>
        <div
          className="loan-table-container"
          ref={scrollRef}
          onScroll={handleScroll}
        >
          {!isLoader && transactionData?.length === 0 ? (
            <>
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "60vh" }}
              >
                <div>
                  <img
                    src={noDataIco}
                    style={{ width: "300px", opacity: 0.3 }}
                  />
                  <p
                    className="w-100 text-center mb-1"
                    style={{ fontSize: 25, fontWeight: 500 }}
                  >
                    No Transaction Data
                  </p>
                  {/* {!isDisbursed && (
                    <p className="w-100 text-center" style={{ color: "gray" }}>
                      Note : The application is not yet disbursed
                    </p>
                  )} */}
                </div>
              </div>
            </>
          ) : (
            <>
              <table className="loan-table-simple">
                <thead>
                  <tr className="loan-tr-simple">
                    <th>SL No.</th>
                    <th>Transaction Amount</th>
                    <th>Transaction Date</th>
                    <th>Payment Mode</th>
                    <th>Transaction UTR</th>
                    <th>Transaction number</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {!isLoader ? (
                  <tbody>
                    {transactionData?.map((val, index) => {
                      return (
                        <>
                          <tr
                            className="loan-tr-simple loan-body-tr-simple pointer"
                            key={val?._id}
                          >
                            <td data-label="Installment No">{index + 1}</td>
                            <td data-label="Installment Amount">
                              {f(val?.transaction_amount, "cur")}
                            </td>

                            <td data-label="Settlement Date">
                              {moment(new Date(val?.transaction_dateMS)).format(
                                "DD/MM/YYYY"
                              )}
                            </td>

                            <td data-label="pay-mode">{val?.payment_mode}</td>
                            <td data-label="tnx-id">{val?.transaction_utr}</td>
                            <td data-label="tnx-id">{val?.transaction_id}</td>
                            <td data-label="tnx-id">
                              <div className="d-flex">
                                <Button
                                  type="button"
                                  disabled={!val?.documents?.length>0}
                                  onClick={() => {
                                    setDocViewer({
                                      open: true,
                                      url: val?.documents?.[0]?.fileLink,
                                      title: capitalizeFirstLetter(val?.documents?.[0]?.name),
                                      data:val ,
                                      isMulti: false,
                                      application_id:val.application_id,
                                    });
                                  }}
                                >
                                  <VisibilityIcon sx={{ fontSize: 20, color:"#667085" }}  />
                                </Button>
                                <Button
                                  className="ms-0"
                                  type="button"
                                  disabled={!val?.is_approved }
                                  onClick={() => {
                                    handleDownload(
                                      val?._id,
                                      `${customerName}-${moment(
                                        new Date(val?.transaction_dateMS)
                                      ).format("DD/MM/YYYY")}-${val?.transaction_amount}.pdf`
                                    );
                                  }}
                                >
                                  <FileDownloadIcon sx={{ fontSize: 20, color:"#667085"  }} />
                                </Button>
                                
                                  <Button
                                    type="button"
                                    disabled={!val?.editAllowed}
                                    className="ms-0"
                                    onClick={() => {
                                      setCollectionUpdate({
                                        open: true,
                                        is_colletion: false,
                                        payment_amount: val?.transaction_amount,
                                        transaction_id: val?.transaction_id,
                                        transaction_utr: val?.transaction_utr,
                                        transaction_date: moment(
                                          new Date(
                                            Number(val?.transaction_dateMS)
                                          ).toString()
                                        ).format("DD/MM/YYYY"),
                                        payment_mode: val?.payment_mode,
                                        isTransaction: true,
                                        defaultDate: moment(
                                          new Date(
                                            Number(val?.transaction_dateMS)
                                          ).toString()
                                        ).format("DD/MM/YYYY"),
                                        scheduleId: val?.schedule_id,
                                        transactionId: val?._id,
                                        isEditabled: val?.editAllowed,
                                        origin:'transactions'
                                      });
                                    }}
                                  >
                                    <EditIcon sx={{ fontSize: 12, color:"#667085"  }} />
                                  </Button>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    {[...Array(10)].map((_) => {
                      return (
                        <tr className="loan-tr loan-body-tr">
                          <td data-label="Settlement Date">
                            <Skeleton />
                          </td>

                          <td data-label="Outstanding / Advance">
                            <Skeleton />
                          </td>
                          <td data-label="Day-Status">
                            <Skeleton />
                          </td>
                          <td data-label="Current-Status">
                            <Skeleton />
                          </td>
                          <td data-label="pay-mode">
                            <Skeleton />
                          </td>
                          <td data-label="tnx-id">
                            <Skeleton />
                          </td>
                          <td data-label="actions"></td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </>
          )}
        </div>
      </div>
      <Fab
        variant="extended"
        className="scroll-top-btn2"
        onClick={() => {
          if (scroll === "Top") {
            scrollRef.current.scrollTop = 0;
          } else if (scroll === "Bottom") {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
          }
        }}
      >
        {scroll === "Top" && <ArrowDropUpIcon />}
        {scroll === "Bottom" && <ArrowDropDownIcon />}
        {/* {scroll} */}
      </Fab>
      <FileViewer
        application_id={docViewer.application_id}
        open={docViewer?.open}
        url={docViewer?.url}
        title={docViewer?.title}
        data={{ ...docViewer?.data }}
        onClose={() => {
          setDocViewer(docViewInitial);
        }}
        reload={(status) => {
          setDocViewer({
            ...docViewer,
            data: {
              ...docViewer?.data,
              isApproved: status,
            },
          });
        }}
        mandate_id={""}
        isMulti={false}
        onDownload={()=>{}}
        type="transactions"
        index="none"
      />
      {overLoading && <LoadingOver text={"Generating Reciept"} />}
    </>
  );
};

export default TransationHistoryV2;
