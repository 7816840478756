import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  Typography,
  IconButton,
  Box,
  Grid,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { useContext } from "react";
import { Table } from "react-bootstrap";
import { UserContext } from "../../Context/UserContext";
import { useEffect } from "react";
import noDataIco from "../../assets/nodata.gif";
import { GlobalContext } from "../../Context/GlobalContext";
// import { DatePicker } from "@material-ui/pickers";
import "./style.css";
import useApplication from "../../hooks/useApplication";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

function capitalizeFirstLetter(string) {
  if (string?.length > 0) {
    return (string?.charAt(0)?.toUpperCase() + string?.slice(1))?.replaceAll(
      "_",
      " "
    );
  }
}

const LandMarkDialog = ({ handleClose, open }) => {
  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <Grid
          container
          style={{ background: "#EFF5FF" }}
          sx={{ padding: 1, paddingBottom: 5 }}
        >
          <Grid xs={6} className="p-3">
            <p className="contact-heading">
              Landmark locations - {updateDetails?.customer_name}
            </p>
          </Grid>
          <Grid xs={6}>
            <div className="d-flex justify-content-end p-3 pe-5"></div>
          </Grid>
        </Grid>
        <div
          style={{
            overflow: "auto",
            height: "100%",
            padding: 10,
            paddingTop: 0,
            marginTop:
              (updateDetails?.landmark_data || [])?.length === 0 ? 0 : -35,
          }}
        >
          <div
            style={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>

          {(updateDetails?.landmark_data || [])?.length === 0 ? (
            <div className="content-center w-100">
              <img src={noDataIco} width={200} />
            </div>
          ) : (
            <Table
              className="contacts-table"
              style={{ fontSize: 13, marginBottom: 0 }}
            >
              <thead>
                <tr style={{ background: "#EFF5FF" }}>
                  <th style={{ width: "10%" }}>SL No.</th>
                  <th style={{ width: "20%" }}>Type</th>
                  <th style={{ width: "30%" }}>Name</th>
                  <th style={{ width: "20%", textAlign: "center" }}>
                    Map Link
                  </th>
                  <th style={{ width: "20%" }}>Contact Number</th>
                </tr>
              </thead>
              {(updateDetails?.landmark_data || [])?.map((loc, index) => {
                return (
                  <tbody>
                    <tr
                      style={{
                        background: index % 2 === 0 ? "#FFFFFF" : "#F2F2F2",
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{capitalizeFirstLetter(loc?.types?.[0] || "")}</td>
                      <td>
                        <b>{loc?.name}</b>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Button>
                          <InsertLinkIcon
                            style={{ transform: "rotate(30deg)" }}
                            onClick={() => {
                              window.open(loc?.map_link, "_blank");
                            }}
                          />
                        </Button>
                      </td>
                      <td>{loc?.contact_number}</td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default LandMarkDialog;
