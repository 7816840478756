import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Alert,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { GlobalContext } from "../Context/GlobalContext";
import { f } from "../utility/Formater";
import { updateFIDate, viewFile } from "../api";
import { PDFDocument } from "pdf-lib";
import moment from "moment/moment";
import { useEffect } from "react";
import { DatePicker } from "@material-ui/pickers";
import { useState } from "react";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FIReportComponent({
  open,
  handleClose,
  fiDoc,
  getAllDocuments,
}) {
  const { updateDetailState } = React.useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const [openDate, setOpenDate] = useState(false);
  const [isAgent, setIsAgent] = useState(true);
  const [fiDateState, setFiDate] = useState("");
  const [loadingSave, setLoadingSave] = useState(false);

  const downloadFIReport = async () => {
    try {
      const { data } = await viewFile(`assets/fi.pdf`);
      const existingPdfBytes = await data?.arrayBuffer();

      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const form = pdfDoc.getForm();

      const fiDate = form.getTextField("fiDate");
      const borrowerName = form.getTextField("borrower_name");
      const borrowerAddress = form.getTextField("borrower_address");
      const premise_own = form.getTextField("premise_ownership");
      const family_member = form.getTextField("family_members");
      const earning_member = form.getTextField("earning_members");
      const family_income = form.getTextField("family_income");
      const accebility_premise = form.getTextField("accessibility_premise");

      const refName1 = form.getTextField("ref_one_name");
      const refMob1 = form.getTextField("ref_one_mobile");
      const refRelation1 = form.getTextField("ref_one_relation");

      const refName2 = form.getTextField("ref_two_name");
      const refMob2 = form.getTextField("ref_two_mobile");
      const refRelation2 = form.getTextField("ref_two_relation");

      const dealerName = form.getTextField("dealer_name");
      const investigatorName = form.getTextField("investigatior_name");
      const investigatorMobile = form.getTextField("investigatior_mobile");

      fiDate.setText(
        `${fiDateState ? moment(fiDateState).format("DD/MM/YYYY") : ""}`
      );
      borrowerName.setText(`${updateDetails?.customer_name || "N/A"}`);
      borrowerAddress.setText(
        `${
          (updateDetails?.residential_address || "").replace(
            /[^\x00-\x7F]/g,
            ""
          ) || "N/A"
        }`
      );
      premise_own.setText(`${updateDetails?.is_property_owned ? "Yes" : "No"}`);
      family_member.setText(
        `${
          updateDetails?.other_details?.family?.family_members ||
          updateDetails?.other_details_customer?.family?.family_members ||
          updateDetails?.video_kyc_data?.audio_kyc?.QPCOUNT?.slice(-1)?.[0]
            ?.answer ||
          "N/A"
        }`
      );
      earning_member.setText(
        updateDetails?.other_details?.family?.earning_members ||
          updateDetails?.other_details_customer?.family?.earning_members ||
          updateDetails?.video_kyc_data?.audio_kyc?.QEMCOUNT?.slice(-1)?.[0]
            ?.answer ||
          "N/A"
      );
      family_income.setText(
        `${
          updateDetails?.other_details?.family?.amount_earned ||
          updateDetails?.other_details_customer?.family?.amount_earned ||
          updateDetails?.video_kyc_data?.audio_kyc?.QFMICOME?.slice(-1)?.[0]
            ?.answer ||
          "N/A"
        }`
      );
      accebility_premise.setText(
        `${updateDetails?.other_details?.accessbility_till_premise || "N/A"}`
      );

      refName1.setText(
        `${updateDetails?.other_details?.references?.[0]?.name || "N/A"}`
      );
      refMob1.setText(
        `${
          updateDetails?.other_details?.references?.[0]?.mobile_number || "N/A"
        }`
      );
      refRelation1.setText(
        `${
          updateDetails?.other_details?.references?.[0]
            ?.relation_with_borrower || "N/A"
        }`
      );

      refName2.setText(
        `${updateDetails?.other_details?.references?.[1]?.name || "N/A"}`
      );
      refMob2.setText(
        `${
          updateDetails?.other_details?.references?.[1]?.mobile_number || "N/A"
        }`
      );
      refRelation2.setText(
        `${
          updateDetails?.other_details?.references?.[1]
            ?.relation_with_borrower || "N/A"
        }`
      );

      dealerName.setText(`${updateDetails?.anchor_name || "N/A"}`);
      investigatorName.setText(`${updateDetails?.investigator?.name || "N/A"}`);
      investigatorMobile.setText(
        `${updateDetails?.investigator?.mobile_number || "N/A"}`
      );

      form?.flatten();
      const pdfBytes = await pdfDoc.save();

      let blob = new Blob([pdfBytes], { type: "application/pdf" });
      let url = URL.createObjectURL(blob);
      window.open(url, "_blank");

      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async function () {
        let base64data = reader.result;

        // setLoading(false);
      };
    } catch (error) {
      console.log(error);
      toast.error("Error while generating FI report");
      // setLoading(false);
    }
  };

  const handleSaveDate = async () => {
    try {
      setLoadingSave(true);
      let { data } = await updateFIDate({
        application_id: updateDetails?.application_id,
        new_date: Number(fiDateState),
      });
      await downloadFIReport();
      setOpenDate(false);
      handleClose();
      getAllDocuments();
    } catch (error) {
      toast?.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
      );
    }
    setLoadingSave(false);
  };

  const checkAgentUploaded = () => {
    let status = true;
    fiDoc?.forEach((val) => {
      if (val?.uploadedFrom !== "LOAN_JOURNEY") {
        status = false;
      }
    });
    setIsAgent(status);
    if (fiDoc?.[0]?.dateUpdated) {
      setFiDate(fiDoc?.[0]?.dateUpdated);
    } else {
      setFiDate(fiDoc?.[0]?.uploadedDateMS);
    }
  };

  useEffect(() => {
    if (open) {
      checkAgentUploaded();
    }
  }, [fiDoc, open]);

  useEffect(() => {
    console.log("FI Date", fiDateState);
  }, [fiDateState]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <div style={{ position: "relative" }}>
            FI Report
            <Button
              style={{ position: "absolute", right: 5 }}
              onClick={() => {
                if (!isAgent && !fiDoc?.[0]?.dateUpdated) {
                  setOpenDate(true);
                } else {
                  downloadFIReport();
                }
              }}
            >
              Download
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <p className="card-heading ps-0" style={{ fontWeight: 500 }}>
            Borrower Details
          </p>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ListItemComp
                heading={"Name:"}
                text={f(updateDetails?.customer_name)}
              />
              <ListItemComp
                heading={"Address:"}
                text={f(updateDetails?.residential_address)}
              />
              <ListItemComp
                heading={"Premises Ownership:"}
                text={updateDetails?.is_property_owned ? "Yes" : "No"}
              />
              <ListItemComp
                heading={"Total no. of family members:"}
                text={f(
                  updateDetails?.other_details?.family?.family_members ||
                    updateDetails?.other_details_customer?.family
                      ?.family_members ||
                    updateDetails?.other_details_customer?.family
                      ?.family_members ||
                    updateDetails?.video_kyc_data?.audio_kyc?.QPCOUNT?.slice(
                      -1
                    )?.[0]?.answer
                )}
              />
              <ListItemComp
                heading={"Earning members in family:"}
                text={f(
                  updateDetails?.other_details?.family?.earning_members ||
                    updateDetails?.other_details_customer?.family
                      ?.earning_members ||
                    updateDetails?.video_kyc_data?.audio_kyc?.QEMCOUNT?.slice(
                      -1
                    )?.[0]?.answer
                )}
              />
              <ListItemComp
                heading={"Total family income (₹):"}
                text={
                  updateDetails?.other_details?.family?.amount_earned ||
                  updateDetails?.other_details_customer?.family?.amount_earned
                    ? f(
                        updateDetails?.other_details?.family?.amount_earned ||
                          updateDetails?.other_details_customer?.family
                            ?.amount_earned,
                        "cur"
                      )
                    : updateDetails?.video_kyc_data?.audio_kyc?.QFMICOME?.slice(
                        -1
                      )?.[0]?.answer
                }
              />
              <ListItemComp
                heading={"Accessibility till Premises:"}
                text={updateDetails?.other_details?.accessbility_till_premise}
              />
            </Grid>
          </Grid>
          <p className="card-heading ps-0 mt-4" style={{ fontWeight: 500 }}>
            Dealer
          </p>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ListItemComp
                heading={"Name:"}
                text={f(updateDetails?.anchor_name)}
              />
            </Grid>
          </Grid>
          <p className="card-heading ps-0 mt-4" style={{ fontWeight: 500 }}>
            Investigator
          </p>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ListItemComp
                heading={"Name:"}
                text={f(updateDetails?.investigator?.name)}
              />
              <ListItemComp
                heading={"Mobile:"}
                text={f(updateDetails?.investigator?.mobile_number)}
              />
            </Grid>
          </Grid>
          <p
            className="card-heading ps-0 mt-4 mb-3"
            style={{ fontWeight: 500 }}
          >
            References
          </p>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ListItemComp
                heading={"Name:"}
                text={f(updateDetails?.other_details?.references?.[0]?.name)}
                big={false}
              />
              <ListItemComp
                heading={"Mobile:"}
                text={f(
                  updateDetails?.other_details?.references?.[0]?.mobile_number
                )}
                big={false}
              />
              <ListItemComp
                heading={"Relationship:"}
                text={f(
                  updateDetails?.other_details?.references?.[0]
                    ?.relation_with_borrower
                )}
                big={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ListItemComp
                heading={"Name:"}
                text={f(updateDetails?.other_details?.references?.[1]?.name)}
                big={false}
              />
              <ListItemComp
                heading={"Mobile:"}
                text={f(
                  updateDetails?.other_details?.references?.[1]?.mobile_number
                )}
                big={false}
              />
              <ListItemComp
                heading={"Relationship:"}
                text={f(
                  updateDetails?.other_details?.references?.[1]
                    ?.relation_with_borrower
                )}
                big={false}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Done
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDate}
        onClose={() => {
          setOpenDate(false);
        }}
      >
        <div>
          <Alert severity="warning">Confirm the FI Report Date</Alert>
          <p className="px-5 py-3">
            <DatePicker
              className="w-100 mt-2"
              format="dd/MM/yyyy"
              // maxDate={new Date()}
              // disableFuture
              value={new Date(fiDateState)}
              onChange={(date) => {
                console.log(date);
                setFiDate(date);
              }}
              animateYearScrolling
            />
          </p>
        </div>

        <DialogActions>
          <Button
            disabled={loadingSave}
            onClick={() => {
              handleSaveDate();
            }}
            color="secondary"
          >
            {loadingSave ? "Loading...." : "Done & Download"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ListItemComp = ({ icon, heading, text, big = true }) => {
  return (
    <ListItem disablePadding>
      <ListItemIcon className={`list-key ${big && "list-key-big"}`}>
        {
          <span>
            {icon && icon}
            {heading}
          </span>
        }
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ fontSize: "15px" }}
        className="list-value"
        primary={text}
      />
    </ListItem>
  );
};
