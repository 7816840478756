import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AudioPlayer from "./AudioPlayer";
import ReactAudioPlayer from "react-audio-player";
import noDataIco from "../assets/nodata.gif";

import { getTwilioLogsData } from "../api";
import TwilioAudioPlayer from "./AudioPlayer";
import moment from "moment";

const CallingHistoryModal = ({ control, handleClose, totalCount }) => {
  const [recordingData, setRecordingData] = useState([]);
  const handleRecordingModal = async (application_id) => {
    if (application_id) {
      const { data } = await getTwilioLogsData(application_id);
      setRecordingData(data);
      console.log(data);
    }
  };

  useEffect(() => {
    handleRecordingModal(control.application_id);
  }, [control.application_id]);
  return (
    <>
      <Dialog
        open={control.open}
        onClose={handleClose}
        style={{ width: "100%" }}
      >
        <DialogContent>
          {recordingData.length != 0 ? (
            <div
              style={{
                border: "0.5px",
                width: "500px",
                height: "300px",
                marginLeft: "5px",
                marginRight: "5px",
                // backgroundColor: "cyan",
                display: "flex",
                padding: "5px",
                flexDirection: "column",
                overflow: "scroll",
                // justifyContent: "space-between",
                // borderRadius: "10px",
              }}
            >
              Total Calls: {control.total_call_count}
              {recordingData.map((ele) => {
                let text =
                  typeof ele?.transcript === "object"
                    ? `AWS : ${ele?.transcript?.aws} or Google: ${ele?.transcript?.google}`
                    : ele?.transcript;
                return (
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "column",
                      justifyContent: "space-between",
                      margin: "10px",
                      backgroundColor: "#D6DAC8",
                      padding: "15px",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <p>
                        {ele?.purpose || ele?.campaign || ele?.template_name}
                      </p>
                      <p>
                        {moment(parseInt(ele?.template_id)).format(
                          "DD-MM-YYYY HH:MM:SS"
                        )}
                      </p>
                    </div>
                    <TwilioAudioPlayer
                      recordingUrl={ele?.recording_url}
                      accountSid={"AC35851a626ed58b00a28e9e7aca33c0ac"}
                      authToken={"6ad8d3ab6d37ef54f9056a1013c4ad44"}
                    />
                    <TextField
                      value={text || "Empty Transcript"}
                      id="outlined-multiline-static"
                      label="Multiline"
                      multiline
                      rows={4}
                      style={{
                        width: "100%",
                        marginTop: "10px",
                        // marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: "60vh" }}
            >
              <div>
                <img src={noDataIco} style={{ width: "300px" }} />
                <p className="w-100 text-center">Nothing to display</p>
              </div>
            </div>
          )}

          <div style={{ display: "flex" }}>
            {/* <Button outlined>Submit</Button>- */}
            <Button outlined onClick={handleClose}>
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CallingHistoryModal;
