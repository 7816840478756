import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import { statgeMap } from "../../../utility/Stages";
import {
  checkNachStatus,
  fetchCollectionData,
  fetchSchedule,
  getCollectionHistory,
  getCollectionHistoryConvert,
  updateCollectionData,
} from "../../../api";
import { Stack } from "@mui/system";
import UpdateCollection from "./UpdateCollection";
import { toast } from "react-toastify";
import Loader from "../../../Components/Loader/Loader";
import ScheduleViewer from "./ScheduleViewer";
import noDataIco from "../../../assets/nodata.gif";
import { GlobalContext } from "../../../Context/GlobalContext";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Fab from "@mui/material/Fab";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SimpleCollectionView from "./SimpleCollectionView";
import AdvancedCollectionView from "./AdvancedCollectionView";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { f } from "../../../utility/Formater";
import TransationHistory from "./TransactionHistory";
import CollectionScheduleExport from "../../../Components/Download/CollectionScheduleExport";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TransactionScheduleExport from "../../../Components/Download/TransactionHistoryExport";
import DownloadIcon from "@mui/icons-material/Download";
import generateTablePDF from "../../../utility/pdfGenerate";
import NachStatusUpdate from "../../../Components/NachStatusUpdate";
import MMIupdateDialog from "../../../Components/MMIupdate";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  margin: 0,
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: 1,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
}));

const initialExpandData = {
  isPayment: false,
  array: [],
};

const ViewCollections = ({
  applicationId,
  payment_basis,
  isDisbursed,
  nachStatus,
}) => {
  const { updateDetailState } = useContext(GlobalContext);
  const [collectionUpdate, setCollectionUpdate] = useState({
    open: false,
    is_colletion: false,
    payment_amount: "",
    transaction_date: moment().format("DD/MM/YYYY"),
    payment_mode: "Cash",
  });
  const [isLoader, setLoader] = useState(false);
  // const [digioNachStatus, setDigioNachStatus] = useState(`${nachStatus}`);
  const [digioNachStatus, setDigioNachStatus] = useState(nachStatus);
  const [isOverLoading, setOverLoading] = useState(false);
  const [simpleView, setSimpleView] = useState(true);
  const [value, setValue] = useState(0);
  const [collectionDetails, setCollectionDetails] = useState({});
  const [collectionData, setCollectionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [loanSchedule, setSchedule] = useState({});
  const [isNewSchedule, setNewSchedule] = useState(false);
  const [nachUpdate, setNachUpdate] = useState({
    open: false,
  });

  const [showCollectionHistory, setShowCollectionHistory] = useState(false);
  const [collectionScheduleType, setCollectionSchemeType] = useState("");
  const [collectionDefaultScheduleType, setCollectionDefaultSchemeType] =
    useState("");

  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const [openMMIdialog, setOpenMMIdialog] = useState(false);

  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const [scroll, setScroll] = useState("Bottom");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickDownload = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDownload = () => {
    setAnchorEl(null);
  };

  const getCollectionData = async () => {
    setLoader(true);
    try {
      let { data } = await getCollectionHistory(applicationId);
      // console.log("collections data:=> ", data);
      setCollectionData(data?.collectionSchedule);
    } catch (error) {
      if (value === 1) {
        toast.info(error?.response?.data?.message);
      }
    }
    setLoader(false);
  };

  const getCollectionEdiToEmi = async () => {
    try {
      setLoader(true);
      let { data } = await getCollectionHistoryConvert(applicationId);
      // console.log("edi to emi: ", data);
      setCollectionData(data);
    } catch (error) {
      // toast.error(error?.response?.data?.message);
    }
    setLoader(false);
  };

  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  };

  const [alignment, setAlignment] = React.useState("left");
  const [devices, setDevices] = React.useState(() => ["phone"]);
  const [expandData, setExpandData] = useState([]);

  const handleChangeType = (e) => {
    if (e.target.value !== null) {
      setCollectionSchemeType(e.target.value);
      setExpandedRows([]);
      setExpandState({});
      if (showCollectionHistory) {
        if (e.target.value === collectionDefaultScheduleType) {
          getCollectionData();
        } else {
          getCollectionEdiToEmi();
        }
      }
    }
  };

  const handleDevices = (event, newDevices) => {
    if (newDevices.length) {
      setDevices(newDevices);
    }
  };
  const handleNachClick = async () => {
    try {
      const response = await checkNachStatus({ application_id: applicationId });
      const { state = null } = response?.data;
      if (state) {
        setDigioNachStatus(state);
      }
      toast.info(response?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Server Error");
    }
  };
  const getNachStatusJsx = (digioNachStatus) => {
    const NachMapping = {
      NA: "Not Available",
      register_success: "Nach is Registered",
      register_failed: "Nach registration failed",
      partial: "Nach is Pending",
    };
    const showButton = !["register_success", "register_failed", "NA"].includes(
      digioNachStatus
    );
    return (
      <p className="amount">
        {NachMapping[digioNachStatus] ?? digioNachStatus}
        {showButton && <Button onClick={handleNachClick}>Refresh</Button>}
      </p>
    );
  };

  const handleUpdateCollection = async (data) => {
    try {
      const { status } = await updateCollectionData(data, applicationId);
      if (status) {
        toast.success("Collection updated successfully");
        getCollectionData();
      }
      setCollectionUpdate({
        open: false,
        is_colletion: false,
        payment_amount: "",
        transaction_date: moment().format("DD/MM/YYYY"),
        payment_mode: "Cash",
      });
    } catch (err) {}
  };

  useEffect(() => {
    getCollectionData();
    getCollectionDetails();
    getSchedule();
  }, [applicationId]);

  useEffect(() => {
    // console.log(payment_basis);
    setCollectionSchemeType(payment_basis === "daily" ? "edi" : "emi");
    setCollectionDefaultSchemeType(payment_basis === "daily" ? "edi" : "emi");
  }, [payment_basis]);

  useEffect(() => {
    if (isDisbursed) {
      setShowCollectionHistory(isDisbursed);
      setValue(1);
    }
  }, [isDisbursed]);

  useEffect(() => {
    if (collectionData?.length > 0) {
      let arr = [];
      collectionData?.forEach((item) => {
        arr.push({
          isPayment: null,
          array: [],
        });
      });
      setExpandData([...arr]);
    }
  }, [collectionData]);

  // useEffect(() => {
  //   console.log(expandData);
  // }, [expandData]);

  const handleScroll = (event) => {
    // console.log("scrollTop: ", event.currentTarget.scrollTop);
    // console.log("offsetHeight: ", event.currentTarget.offsetHeight);
    if (
      event.currentTarget.scrollTop >
      event.currentTarget.offsetHeight - event.currentTarget.offsetHeight / 2
    ) {
      setScroll("Top");
    }
    if (event.currentTarget.scrollTop < event.currentTarget.scrollHeight / 2) {
      setScroll("Bottom");
    }
  };

  const getCollectionDetails = async (id) => {
    try {
      setOverLoading(true);
      let { data } = await fetchCollectionData(applicationId);
      const { customerData, loanData } = data;
      setCollectionDetails({
        nextDueAmount: data?.nextDueAmount,
        nextDueDate: data?.nextDueMS
          ? moment(new Date(data?.nextDueMS)).format("DD/MM/YYYY")
          : "NA",
        totalCollected: data?.totalCollected,
      });
    } catch (error) {
      setCollectionDetails({
        totalCollected: "",
        nextDueAmount: "",
      });
    }
    setOverLoading(false);
  };

  const getSchedule = async (id) => {
    try {
      setLoader(true);
      let { data } = await fetchSchedule(applicationId);
      setSchedule(data?.data);
      setNewSchedule(data?.new_schedule || false);
    } catch (error) {}

    setLoader(false);
  };

  const handleDownload = (e) => {
    // Headers for each column
    let headers =
      value === 1
        ? [
            `SL NO,Installment Amount,Due Date,Payment Date,Status,Paid via, Transaction Id, UTR`,
          ]
        : [
            `SL NO,Transaction Amount,Transaction Date,Payment Mode, Transaction UTR, Transaction Number`,
          ];

    // Convert users data to a csv
    let count = 1;
    let usersCsv = (
      filteredData?.length > 0
        ? filteredData
        : value === 1
        ? collectionData
        : transactionData
    ).reduce((acc, row) => {
      acc.push(
        value === 1
          ? [
              row["installment_number"],
              row["installment_amount"],
              moment(row?.due_date).format("DD/MM/YYYY"),
              row?.settlement_data?.length == 0 || row?.settlement_data == null
                ? "-"
                : moment(
                    new Date(
                      row?.settlement_data[
                        row?.settlement_data?.length - 1
                      ]?.payment_date
                    )
                  ).format("DD/MM/YYYY"),
              statgeMap.COL_STATUS?.[row?.current_status] !== "NOT_UPDATED"
                ? statgeMap.COL_STATUS?.[row?.current_status]
                : "-",
              row?.settlement_data?.slice(-1)[0]?.payment_mode,
              row?.settlement_data?.slice(-1)[0]?.txn_number,
              row?.settlement_data?.slice(-1)[0]?.utr_number,
            ].join(",")
          : [
              count++,
              row?.txn_amount,
              moment(row?.trxn_dateMS).format("DD/MM/YYYY"),
              row?.payment_mode,
              row?.txn_utr,
              row?.txn_number,
            ]
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName:
        value === 1
          ? `Collection Schedule - ${applicationId}`
          : `Transaction History - ${applicationId}`,
      fileType: "text/csv",
    });
  };

  const downloadSchedule = () => {
    let headers = [
      `"SL NO","Due Date","${collectionScheduleType}","Principal","Interest","O/s Principal"`,
    ];

    let data = {
      repayment_amount: 0,
      interest_amount: 0,
    };

    // Convert users data to a csv
    let usersCsv = loanSchedule?.[collectionScheduleType + "Sheet"].reduce(
      (acc, row) => {
        data.repayment_amount += Number(
          row[collectionScheduleType.toUpperCase()]
        );
        data.interest_amount += Number(row["Interest"]);

        acc.push(
          [
            row["SL_NO"],
            row["Due_Date"],
            row[collectionScheduleType.toUpperCase()],
            row["Principal"],
            row["Interest"],
            row["O/s_Principal"],
          ].join(",")
        );
        return acc;
      },
      []
    );

    let info = [
      `Customer Name,${updateDetails?.customer_name}`,
      `Application ID,${applicationId}`,
      `Loan Amount,${updateDetails?.loan_amount}`,
      `ROI,${updateDetails?.interest_rate}%`,
      `Tenure,${updateDetails?.tenure_value}`,
      `Interest,${(data?.interest_amount).toFixed(2)}`,
      `Repayment,${(data?.repayment_amount).toFixed(2)}`,
      `Disbursal Date,${updateDetails?.disbursal_date}`,
      ``,
      ``,
    ];

    downloadFile({
      data: [...info, ...headers, ...usersCsv].join("\n"),
      fileName: `${applicationId}-Schedule(${collectionScheduleType})`,
      fileType: "text/csv",
    });
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handlePdfDownload = (e) => {
    let data = {
      repayment_amount: 0,
      interest_amount: 0,
    };

    loanSchedule?.[collectionScheduleType + "Sheet"].forEach((val) => {
      data.repayment_amount += Number(
        val[collectionScheduleType.toUpperCase()]
      );
      data.interest_amount += Number(val["Interest"]);
    });

    generateTablePDF(
      loanSchedule?.[collectionScheduleType + "Sheet"],
      {
        applicationId,
        name: updateDetails?.customer_name,
        nbfc: updateDetails?.nbfc_name,
        loanAmount: updateDetails?.loan_amount + "",
        interest: updateDetails?.interest_rate,
        interest_amount: data?.interest_amount + "",
        repayment_amount: data?.repayment_amount + "",
        tenure: updateDetails?.tenure_value + "",
        disbursalDate: updateDetails?.disbursal_date,
      },
      `${applicationId}-Schedule(${collectionScheduleType}).pdf`,
      collectionScheduleType
    );
    handleCloseDownload();
  };

  useEffect(() => {
    // console.log("hello nach : ", digioNachStatus);
    setDigioNachStatus(nachStatus);
  }, [nachStatus]);

  // useEffect(() => {
  //   let data = {
  //     repayment_amount: 0,
  //     interest_amount: 0,
  //   };
  //   loanSchedule?.emiSheet?.forEach((val) => {
  //     data.repayment_amount += Number(val?.EMI);
  //     data.interest_amount += Number(val?.Interest);
  //   });
  //   setSchedule({
  //     ...loanSchedule,
  //     ...data,
  //   });
  // }, [loanSchedule?.emiSheet]);

  useEffect(() => {
    if (value === 0) {
      setCollectionSchemeType("emi");
    }
  }, [value]);

  return (
    <>
      {collectionData?.length > 0 && (
        <div style={{ marginTop: "-25px" }}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <p style={{ color: "#716E6E", marginBottom: 0, fontWeight: 400 }}>
                Overview
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} className="mb-3">
                <Grid item xs={12} sm={6} md={3}>
                  <div className="over-view-card">
                    <p className="head">Loan Amount</p>
                    <p className="amount">
                      {f(updateDetails?.loan_amount, "cur")}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div className="over-view-card">
                    <p className="head">Repayment Amount</p>
                    <p className="amount">
                      {f(updateDetails?.repayment_amount, "cur")}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div className="over-view-card">
                    <p className="head">Collected Amount</p>
                    <p className="amount">
                      {f(collectionDetails?.totalCollected, "cur")}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div className="over-view-card">
                    <p className="head">Total Due till today</p>
                    <p className="amount">
                      {f(collectionDetails?.nextDueAmount, "cur")}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div className="over-view-card flex-column">
                    <p className="head">Nach Status</p>
                    {getNachStatusJsx(digioNachStatus)}
                    {digioNachStatus === "register_failed" && (
                      <Button
                        style={{ padding: 0 }}
                        onClick={() => {
                          setNachUpdate({
                            ...nachUpdate,
                            open: true,
                          });
                        }}
                      >
                        Update
                      </Button>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div className="over-view-card">
                    <p className="head">GPS Status</p>
                    <p className="amount">
                      {updateDetails?.gps_mmi_number ? (
                        <>
                          Installed{" "}
                          <span style={{ fontSize: 10 }}>
                            (MMI: {updateDetails?.gps_mmi_number})
                          </span>
                        </>
                      ) : (
                        <>
                          Not Installed <Button onClick={()=>{
                            setOpenMMIdialog(true)
                          }}>Add</Button>
                        </>
                      )}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {value !== 2 && (
              <FormControl className="">
                <Stack direction="row" spacing={4} className="mb-2">
                  <ToggleButtonGroup
                    value={collectionScheduleType}
                    exclusive
                    onChange={handleChangeType}
                    aria-label="text alignment"
                    // disabled={collectionData?.length === 0}
                    className="toggle-btn-holder"
                    size="small"
                  >
                    {updateDetails?.tenure_type?.toUpperCase() !== "DAILY" &&
                      updateDetails?.tenure_type?.toUpperCase() !== "DAYS" && (
                        <ToggleButton value="emi" className="toggle-btn">
                          EMI
                        </ToggleButton>
                      )}
                    <ToggleButton
                      value={isNewSchedule && value === 0 ? "efi" : "edi"}
                      className="toggle-btn"
                    >
                      {isNewSchedule && value === 0 ? "EFI" : "EDI"}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </FormControl>
            )}
            <FormControl sx={{ ml: 1, minWidth: 120 }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                style={{ height: 30 }}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  setShowCollectionHistory(e.target.value === 1 ? true : false);
                  setFilterApplied(false);
                  setFilteredData([]);
                  setOpenFilter(false);
                }}
              >
                <MenuItem value={0}>Schedule</MenuItem>
                <MenuItem value={1}>Collection History</MenuItem>
                <MenuItem value={2}>Transaction History</MenuItem>
              </Select>
            </FormControl>

            {filteredData.length <= 0 ? (
              <Button
                sx={{ color: "gray", fontSize: 12 }}
                onClick={() => {
                  setFilterApplied(true);
                  setOpenFilter(true);
                }}
              >
                <FilterAltIcon sx={{ fontSize: 20 }} /> filter
              </Button>
            ) : (
              <>
                <Button
                  sx={{ fontSize: 12 }}
                  onClick={() => {
                    setOpenFilter(true);
                  }}
                >
                  <FilterAltIcon sx={{ fontSize: 20 }} /> filters
                </Button>
                <span
                  className="pointer"
                  onClick={() => {
                    setFilterApplied(false);
                    setFilteredData([]);
                  }}
                >
                  <HighlightOffIcon style={{ fontSize: 20 }} />
                </span>
              </>
            )}
          </div>

          <div>
            {value === 1 && (
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={simpleView}
                    onChange={(e) => {
                      setSimpleView(e.target.checked);
                    }}
                  />
                }
                label={
                  <span style={{ color: "gray", fontSize: 13 }}>
                    <RemoveRedEyeIcon sx={{ mr: 0.2 }} />
                    {simpleView ? "Simple" : "Advanced"}
                  </span>
                }
              />
            )}
            {collectionData?.length > 0 &&
              (value === 0 ? (
                <>
                  <>
                    <Button
                      id="basic-button"
                      color="inherit"
                      aria-controls={openMenu ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? "true" : undefined}
                      onClick={handleClickDownload}
                    >
                      Export <DownloadIcon />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleCloseDownload}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={downloadSchedule}>CSV</MenuItem>
                      <MenuItem onClick={handlePdfDownload}>PDF</MenuItem>
                    </Menu>
                  </>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      handleDownload();
                    }}
                  >
                    {" "}
                    <FileDownloadIcon /> Export
                  </Button>
                </>
              ))}
            {value === 1 && isDisbursed && (
              <>
                <Button
                  onClick={() => {
                    navigate(
                      `/update-collection/${updateDetails?.application_id}`,
                      {
                        state: {
                          path: `/view-application/${updateDetails?.application_id}/4`,
                        },
                      }
                    );
                  }}
                >
                  <AddIcon /> Add Transaction
                </Button>
              </>
            )}
          </div>
        </div>

        {value === 1 &&
          (!simpleView ? (
            <AdvancedCollectionView
              collectionData={
                filteredData.length > 0 ? filteredData : collectionData
              }
              collectionScheduleType={collectionScheduleType}
              collectionDefaultScheduleType={collectionDefaultScheduleType}
              expandData={expandData}
              setExpandData={setExpandData}
              setCollectionUpdate={setCollectionUpdate}
              isLoader={isLoader}
              isDisbursed={isDisbursed}
              expandedRows={expandedRows}
              handleEpandRow={handleEpandRow}
            />
          ) : (
            <SimpleCollectionView
              collectionData={
                filteredData.length > 0 ? filteredData : collectionData
              }
              collectionScheduleType={collectionScheduleType}
              collectionDefaultScheduleType={collectionDefaultScheduleType}
              expandData={expandData}
              setExpandData={setExpandData}
              setCollectionUpdate={setCollectionUpdate}
              isLoader={isLoader}
              isDisbursed={isDisbursed}
              expandedRows={expandedRows}
              handleEpandRow={handleEpandRow}
            />
          ))}
      </div>
      {value === 0 && (
        <div>
          <ScheduleViewer
            applicationId={applicationId}
            scheduleType={collectionScheduleType}
            loanSchedule={loanSchedule}
            setSchedule={setSchedule}
          />
        </div>
      )}

      {value === 2 && (
        <div>
          <TransationHistory
            applicationId={applicationId}
            transactionData={
              filteredData?.length > 0 ? filteredData : transactionData
            }
            setTransactionData={setTransactionData}
            customerName={updateDetails?.customer_name || "NO-NAME"}
          />
        </div>
      )}

      <UpdateCollection
        applicationId={applicationId}
        open={collectionUpdate?.open}
        initalState={collectionUpdate}
        handleClose={(reload) => {
          setCollectionUpdate({
            open: false,
            is_colletion: false,
            payment_amount: "",
            transaction_date: moment().format("DD/MM/YYYY"),
            payment_mode: "Cash",
          });
          if (reload) {
            getCollectionData();
          }
        }}
        onSubmit={async (data) => {
          await handleUpdateCollection(data);
          // console.log(data);
        }}
      />

      <TransactionScheduleExport
        applicationId={applicationId}
        open={openFilter && value === 2}
        collectionData={transactionData}
        handleClose={() => {
          setOpenFilter(false);
        }}
        onApply={async (data) => {
          setFilteredData(data);
        }}
      />

      <CollectionScheduleExport
        applicationId={applicationId}
        open={openFilter && value === 1}
        collectionData={collectionData}
        handleClose={() => {
          setOpenFilter(false);
        }}
        onApply={async (data) => {
          setFilteredData(data);
        }}
      />

      <NachStatusUpdate
        open={nachUpdate?.open}
        handleClose={() => {
          setNachUpdate({
            ...nachUpdate,
            open: false,
          });
        }}
        applicationId={applicationId}
      />

      <MMIupdateDialog
        open={openMMIdialog}
        application_id={applicationId}
        handleClose={() => {
          setOpenMMIdialog(false);
        }}
      />
    </>
  );
};

export default ViewCollections;
