import AddIcon from "@mui/icons-material/Add";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../Context/GlobalContext";
import {
  EsignGenerate,
  EsignWebhook,
  addOtherDocuments,
  checkNachStatus,
  replaceDocPage,
  updateRefId,
  uploadDocumentV2,
  verifyAuth,
} from "../../../api";

import { toast } from "react-toastify";
import folder from "../../../assets/folder.svg";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DownloadIcon from "@mui/icons-material/Download";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RuleFolderIcon from "@mui/icons-material/RuleFolder";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import axios from "axios";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import AddOtherDocs from "../../../Components/AddOtherDocs";
import StatusDialog from "../../../Components/CheckDocStatus/CheckStatusV2";
import CropScreen from "../../../Components/CropScreen";
import DOUpload from "../../../Components/Dialogs/DOUpload";
import LandMarkDialog from "../../../Components/Dialogs/LandMarkTable";
import LoanEsingDialog from "../../../Components/Dialogs/LoanEsingDialog";
import TelePDModal from "../../../Components/Dialogs/TelepdDialog";
import FileViewer from "../../../Components/FileViewer/FileViewer";
import LoadingOver from "../../../Components/Loading";
import MMIupdateDialog from "../../../Components/MMIupdate";
import NachStatusUpdate from "../../../Components/NachStatusUpdate";
import { icons } from "../../../Constants/icons";
import { FileMimeType } from "../../../Constants/memeTypes";
import { UserContext } from "../../../Context/UserContext";
import otherdoc from "../../../assets/otherdoc.svg";
import useImageWithLocation, {
  RenderCanvas,
} from "../../../hooks/useImageWithLocation";
import { blobToBase64, capitalize, getDocKey } from "../../../utility/Formater";
import cobminePDF from "../../../utility/combinePDF";
import { compressImage, getBase64 } from "../../../utility/convertFileBase";
import "./Documents.css";

const docViewInitial = {
  open: false,
};

const vehiclePhotosArr = [
  "vehicle_back_photo_with_customer",
  "vehicle_side_photo_with_customer",
  "vehicle_front_photo_with_customer",
  "chassis_number_photo",
  "odometer_chassis_video",
  "odometer_photo"
];

const Documents = ({
  AllDocuments,
  application_id,
  setLoading,
  // setProgress,
  getAllDocuments,
  docStatusList,
  getApplicationDetails,
  nachData,
}) => {
  const [isCocustomer, setCocustomer] = useState(false);
  const { updateDetailState } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const [updateDetails, setUpdateDetails] = updateDetailState;

  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState({});
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);
  const [totalCount, setTotalDocumentCount] = useState(0);
  const [docViewer, setDocViewer] = useState(docViewInitial);
  const [progress, setProgress] = useState(null);
  const [multiDocLength, setMultiLength] = useState(null);
  const [statusDialogData, setStatusDialogData] = useState({
    open: false,
  });
  const [openAddOtherDocs, setOpenAddOtherDocs] = useState(false);
  const [isOtherDocsLoading, setIsOtherDocsLoading] = useState(false);
  const [swipeDocs, setswipeDocs] = useState([]);
  const [input, setInput] = useState(false);
  const [image, setImage] = useState("");
  const [croppedImg, setCropImg] = useState("");
  const [pdfFile, setPdf] = useState("");
  const [isInput, setIsInput] = useState(false);
  const [uploadDocDetails, setUploadDocDetails] = useState({});
  const [digioNachData, setDigioNachData] = useState(nachData);
  const [nachUpdate, setNachUpdate] = useState({
    open: false,
  });
  const [openTelePd, setOpenTelePd] = useState(false);
  const [isEsignLoading, setIsEsignLoading] = useState(false);
  const [openEsignPopup, setOpenEsignPopup] = useState(false);
  const [openMMIdialog, setOpenMMIdialog] = useState(false);
  const [openLandmark, setOpenLandmark] = useState(false);
  const [openDOUpload, setOpenDOUpload] = useState({ open: false, val: null });
  const [nachType, setNachType] = useState("latest");
  const [warnPopOnVehiclePhoto, setWarnPopOnVehiclePhoto] = useState(null);

  const searchParams = new URLSearchParams(document.location.search);

  const fileRef = useRef(null);

  const { getImageWithLocation } = useImageWithLocation();
  const isUserRoleMatching = Number(user?.role) === 2; // Replace 'admin' with the role you want to check against
  const inlineStyles = isUserRoleMatching
    ? { pointerEvents: "none", opacity: 0.7 }
    : {};
  const handleClickOpen = () => {
    setOpen(true);
  };

  async function handleDocUpload({
    type,
    fieldName,
    override,
    fileName,
    index,
    doc,
  }) {
    setIsOtherDocsLoading(true);
    let newName = [
      "co_customer_pan",
      "co_customer_aadhaar_front",
      "co_customer_aadhaar_back",
    ]?.includes(fileName)
      ? fileName?.slice(12)
      : fieldName
      ? fileName
      : fileName?.replace(/_\d+$/, "");
    try {
      const payload = {
        docs: [
          {
            file: croppedImg?.length ? croppedImg : pdfFile,
            name: fieldName ? fieldName : newName,
          },
        ],
        document_included_g: true,
      };

      let params = "";
      if (type) {
        params += `type=${type}&`;
      }
      if (
        [
          "driving_license_1",
          "customer_photo_1",
          "other_ownership_proof_1",
          "co_customer_photo_1",
          "co_customer_ownership_proof_1",
          "co_customer_other_documents_1",
        ].includes(fileName) ||
        doc?.meta_page_document
      ) {
        params += `override=${true}&`;
      } else if (
        fieldName === "customer" ||
        newName === "cheques" ||
        newName === "cibil_report"
      ) {
        if (override) {
          params += `override=${true}&`;
        }
        params += `overrideFileName=${fileName}&`;
      } else if (override && !type) {
        params += `override=${true}&overrideFileName=${fileName}&`;
      } else if (vehiclePhotosArr?.includes(fileName)) {
        params += `overrideFileName=${fileName}`;
      }

      const { data } = await uploadDocumentV2(
        updateDetails?.application_id,
        params,
        payload
      );
      if (data.status) {
        if (fileName?.includes("delivery_order")) {
          await handleDORef();
        }
        await getApplicationDetails();
        await getAllDocuments();
        handleSwipe("", docViewer?.["index"], true);
        toast("document uploaded successfully !!!");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setPdf("");
      setCropImg("");
      setInput(false);
      setIsOtherDocsLoading(false);
    }
  }

  async function handleDocPageReplace({
    type,
    fieldName,
    override,
    fileName,
    index,
    doc,
  }) {
    try {
      let newFileName = fileName?.replace(/_\d+$/, "");

      let payload_1 = {
        applicationId: updateDetails?.application_id,
        category: doc?.category,
        collection: doc?.collection,
        file: {
          data: croppedImg?.length ? croppedImg : pdfFile,
          name: newFileName,
        },
        page: doc?.page,
      };

      const { data } = await replaceDocPage(payload_1);

      if (data?.status) {
        await getApplicationDetails();
        await getAllDocuments();
        handleSwipe("", docViewer?.["index"], true);
        toast("document uploaded successfully !!!");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setPdf("");
      setCropImg("");
      setInput(false);
      setIsOtherDocsLoading(false);
    }
  }

  const handleRecapture = () => {
    fileRef.current.value = "";
    // setConfirmFilename("");
    setInput(false);
    setPdf("");
    setImage("");
    handleFileUpload();
    // setFile && setFile("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const docCount = (count) => {
    return (
      <span
        style={{
          backgroundColor: count > 0 ? "#003f80" : "#bab9b6",
          color: "white",
          padding: "2px 10px",
          fontSize: "12px",
          borderRadius: "30px",
        }}
      >
        {count}
      </span>
    );
  };

  function capitalizeFirstLetter(string) {
    if (string?.length > 0) {
      return (string?.charAt(0)?.toUpperCase() + string?.slice(1))?.replaceAll(
        "_",
        " "
      );
    }
  }

  const handleDownload = async (
    key,
    fileName,
    elem = null,
    isDownload = true,
    type = null
  ) => {
    setIsDownloading(elem);
    let token = user?.token;
    if (!token) {
      let { data } = await verifyAuth();
      token = data?.token;
      setUser({
        ...data.data,
        token,
      });
    }
    let { data } = await axios.get(
      `https://sen319hxy8.execute-api.ap-south-1.amazonaws.com/dev/view?environment=development&signedUrl=true&key=${key}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      }
    );
    let { signedUrl } = data;

    if (isDownload) {
      fetch(signedUrl, {
        method: "get",
      })
        .then((res) => res.blob())
        .then((res) => {
          const aElement = document.createElement("a");
          aElement.setAttribute("download", fileName);
          const href = URL.createObjectURL(res);
          aElement.href = href;
          // aElement.setAttribute('href', href);
          aElement.setAttribute("target", "_blank");
          aElement.click();
          URL.revokeObjectURL(href);
          setIsDownloading(null);
          handleCloseMenu();
        });
    } else {
      const response = await axios.get(signedUrl, {
        responseType: "blob",
      });
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          const base64Data = reader.result.split(",")[1]; // Extract the base64 data portion
          resolve(`data:${type};base64,${base64Data}`);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(blob);
      });
    }
  };

  const download = async (
    item,
    multiData,
    zip,
    stopCount = false,
    returnFile = false
  ) => {
    let token = user?.token;
    if (!token) {
      let { data } = await verifyAuth();
      token = data?.token;
      setUser({
        ...data.data,
        token,
      });
    }

    let key = item?.key ? item?.key : getDocKey(item?.fileLink)?.key;

    let { data } = await axios.get(
      `https://sen319hxy8.execute-api.ap-south-1.amazonaws.com/dev/view?environment=development&signedUrl=true&key=${key}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          application: "EMBEDDED-TOOLS",
          token,
        },
      }
    );
    let { signedUrl } = data;

    return axios
      .get(signedUrl, {
        responseType: "blob",
      })
      .then(async (resp) => {
        if (returnFile) {
          return resp?.data;
        }

        if (!stopCount) {
          setDownloadProgress((prev) => prev + 1);
        }

        let name = item?.name
          ?.replaceAll(/[0-9]/g, "")
          ?.replaceAll("_", " ")
          ?.trim()
          ?.replaceAll(" ", "_");

        if (
          [
            "customer_house_inside",
            "customer_house_outside",
            "customer_photo_with_electricity_meter",
            "field_investigation",
          ].includes(name) &&
          (item?.location?.lat || item?.location?.latitude) &&
          (item?.location?.long || item?.location?.longitude) &&
          !item?.location?.imageLocation
        ) {
          let file = await getImageWithLocation(
            {
              lat: item?.location?.lat || item?.location?.latitude,
              long: item?.location?.long || item?.location?.longitude,
            },
            URL.createObjectURL(resp.data)
          );

          zip.file(`${item?.name}.png`, file.split(",")[1], { base64: true });
        } else {
          zip.file(
            !multiData
              ? `${item?.name}.${
                  item?.dataType
                    ? item?.dataType
                    : FileMimeType[getDocKey(item?.fileLink)?.dataType]
                }`
              : `${item?.page}.${
                  item?.dataType
                    ? item?.dataType
                    : FileMimeType[getDocKey(item?.fileLink)?.dataType]
                }`,
            resp.data
          );
        }
      })
      .catch((err) => {
        console.log(err);
        if (!stopCount) {
          setDownloadProgress((prev) => prev + 1);
        }
        toast.warn(`Error with file ${item?.name}, will be skipped`);
      });
  };

  async function handleOtherDocsUpload(payload) {
    try {
      setIsOtherDocsLoading(true);
      const { data } = await addOtherDocuments(updateDetails?.application_id, {
        documentNames: payload,
      });
      // await getApplicationDetails(updateDetails?.application_id, true);
      await getAllDocuments();
      await getApplicationDetails();

      if (data?.status) {
        toast(data?.message, {
          style: {
            width: 380,
          },
        });
      }
    } catch (error) {
      toast.error("upload docs is failed");
    }
    setIsOtherDocsLoading(false);
  }

  // const downloadAll = (documents, filename, multiData = false) => {
  //   setIsDownloadingAll(true);
  //   const arrOfFiles = documents?.map((item) => download(item, multiData)); //create array of promises
  //   Promise.all(arrOfFiles)
  //     .then(() => {
  //       zip.generateAsync({ type: "blob" }).then(function (blob) {
  //         saveAs(blob, `${filename}.zip`);
  //       });
  //       setIsDownloadingAll(false);
  //     })
  //     .catch((err) => {
  //       setIsDownloadingAll(false);
  //       console.log(err);
  //     });
  // };

  // const downloadAll = async (documents, filename, multiData = false) => {
  //   if (multiData) setMultiLength(documents?.length);
  //   setIsDownloadingAll(true);
  //   let promises = [];
  //   for (const item of documents) {
  //     if (item?.isApproved || multiData) {
  //       promises.push(download(item, multiData));
  //       if (promises.length == 5) {
  //         await Promise.allSettled(promises);
  //         promises = [];
  //       }
  //     }
  //   }

  //   await Promise.allSettled(promises);
  //   zip.generateAsync({ type: "blob" }).then(function (blob) {
  //     saveAs(blob, `${filename}.zip`);
  //   });
  //   if (multiData) setMultiLength(null);
  //   setDownloadProgress(0);
  //   setIsDownloadingAll(false);
  // };

  const downloadAll = async (documents, filename, multiData = false) => {
    const zip = new JSZip();
    let docArray = [];
    let multiDocs = {};
    function flatten(data) {
      Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) {
          if (!data[key]?.[0]?.meta_page_document) {
            data[key]?.forEach((doc) => {
              if (doc?.isApproved) {
                docArray.push(doc);
              }
            });
          } else {
            multiDocs = {
              ...multiDocs,
              [key]: data[key],
            };
          }
        } else {
          if (data[key]?.isApproved) {
            docArray.push(data[key]);
          }
        }
      });
    }

    if (!multiData) {
      if (updateDetails?.loanDocs) flatten(updateDetails?.loanDocs);
      if (updateDetails?.customerDoc) flatten(updateDetails?.customerDoc);
      if (updateDetails?.coCustomerDoc) flatten(updateDetails?.coCustomerDoc);
    } else {
      docArray = documents;
    }

    // console.log(docArray);
    // console.log(multiDocs);
    if (multiData) setMultiLength(documents?.length);
    setIsDownloadingAll(true);

    let promises = [];
    for (const item of docArray) {
      if (item?.fileLink) {
        promises.push(await download(item, multiData, zip));
        if (promises.length == 5) {
          await Promise.allSettled(promises);
          promises = [];
        }
      }
    }

    if (!multiData) {
      for (const key of Object.keys(multiDocs)) {
        if (multiDocs[key]?.[0]?.isApproved) {
          let multiZip = new JSZip();
          setDownloadProgress((prev) => prev + 1);
          for (const item of multiDocs[key]) {
            promises.push(download(item, true, multiZip, true));
            if (promises.length == 5) {
              await Promise.allSettled(promises);
              promises = [];
            }
          }

          await Promise.allSettled(promises);
          const combined = await multiZip.generateAsync({
            type: "arraybuffer",
          });
          await zip.file(`${key}.zip`, combined);
        }
      }
    }

    await Promise.allSettled(promises);
    zip.generateAsync({ type: "blob" }).then(function (blob) {
      saveAs(blob, `${filename}.zip`);
    });

    setDownloadProgress(0);
    setIsDownloadingAll(false);
    setProgress("");
  };

  //code :100
  function handleSwipe(direction, index, isDeleted) {
    if (isDeleted) {
      setDocViewer({
        open: false,
        url: swipeDocs?.[index]?.fileLink,
        title: capitalizeFirstLetter(swipeDocs?.[index]?.name),
        data: swipeDocs[index],
        isMulti: swipeDocs?.[index]?.meta_page_document,
        index: index,
      });
      return;
    }
    if (direction === "prev" && index === -1) {
      index = swipeDocs.length - 1;
    } else if (direction === "next" && index === swipeDocs.length) {
      index = 0;
    }
    setDocViewer({
      open: true,
      url: swipeDocs?.[index]?.fileLink,
      title: capitalizeFirstLetter(swipeDocs?.[index]?.name),
      data: swipeDocs[index],
      isMulti: swipeDocs?.[index]?.meta_page_document,
      index: index,
    });
  }

  function handleFileOpen(val, i) {
    setDocViewer({
      open: true,
      url: val?.fileLink,
      title: capitalizeFirstLetter(val?.name),
      data: val,
      isMulti: val?.meta_page_document,
      index: i,
    });
  }

  const [isDownloading, setIsDownloading] = useState(null);
  const [openElem, setOpenElem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickMenu = (elem) => (event) => {
    if (!elem?.key) return;
    setAnchorEl(event.currentTarget);
    setOpenElem(elem);
    event.stopPropagation();
    event.preventDefault();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenElem(null);
  };

  async function handleFileUpload(val, reupload = false) {
    await setUploadDocDetails({
      type:
        val?.category === "co_customer"
          ? "co-customer"
          : val?.category === "customer"
          ? val?.category
          : null,
      fileName: val?.name,
      override: val?.key || val?.category === "other_documents" ? true : false,
      fieldName:
        "other_documents" === val?.category
          ? val?.category
          : val?.category === "field_investigation" && val?.name !== "fi_video"
          ? "customer"
          : vehiclePhotosArr.includes(val?.name)
          ? "vehicle_delivery_photos"
          : null,
      index: val?.index ? val?.index : null,
      doc: val,
    });

    if (val?.name?.includes("delivery_order")) {
      setOpenDOUpload({
        open: true,
        val,
      });
    } else {
      fileRef.current.value = "";
      fileRef.current.click();
      setIsInput(true);
    }
  }

  function handleSwipeDocuments(doc, currDoc, i) {
    // console.log(doc);
    let multiDocKey = false;
    let key = "";
    let swipeList = doc?.filter((val, i) => {
      if (!val?.key) {
        return false;
      }
      let fieldName;
      if (
        ["form_22", "form_21", "form_20"].includes(val?.name?.substring(0, 7))
      ) {
        fieldName = val?.name?.substring(0, 7)?.trim();
      } else {
        fieldName = val?.name
          ?.replaceAll(/[0-9]/g, "")
          ?.replaceAll("_", " ")
          ?.trim()
          ?.replaceAll(" ", "_");
      }

      if (multiDocKey && fieldName === key) {
        return false;
      } else {
        multiDocKey = false;
        if (val?.meta_page_document) {
          key = fieldName;
          multiDocKey = true;
        }
        return true;
      }
    });
    setswipeDocs(swipeList);
    let ind = swipeList?.findIndex((itm) => itm?.name === currDoc?.name);
    handleFileOpen(currDoc, ind);
  }

  async function handlePOASigning() {
    try {
      let redirect_url = `${window.location.href}`;
      let payload = {
        application_id: application_id,
        redirectUrl: redirect_url,
        poa: true,
      };
      const { data } = await EsignGenerate(payload);
      window.open(data?.webUrl, "_self");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  async function callPOAsignAPI() {
    try {
      setIsEsignLoading(`DIGIO email verification E- Signing...`);
      let payload = {
        application_id,
        poa: true,
        app_version: updateDetails?.app_version,
      };
      const { data } = await EsignWebhook(payload);
      if (data?.status) {
        await getApplicationDetails();
        toast(searchParams.get("message"));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsEsignLoading(null);
      navigate(`/view-application/${application_id}/2`);
    }
  }

  const documentCard = (doc) => {
    let multiDocKey = false;
    let key = "";
    return doc.length > 0 ? (
      doc?.map((val, i) => {
        let fieldName;
        if (
          ["form_22", "form_21", "form_20"].includes(val?.name?.substring(0, 7))
        ) {
          fieldName = val?.name?.substring(0, 7)?.trim();
        } else {
          fieldName = val?.name
            ?.replaceAll(/[0-9]/g, "")
            ?.replaceAll("_", " ")
            ?.trim()
            ?.replaceAll(" ", "_");
        }

        if (multiDocKey && fieldName === key) {
          return null;
        } else {
          multiDocKey = false;
          if (val?.meta_page_document) {
            key = fieldName;
            multiDocKey = true;
          }

          return (
            <>
              <Grid item xs={2} onContextMenu={handleClickMenu(val)}>
                <div
                  style={
                    Number(user?.role) == 2 &&
                    val &&
                    val?.name?.includes("cibil")
                      ? {
                          width: 120,
                          height: 140,
                          position: "relative",
                          cursor: "pointer",
                          // border:'2px solid black',
                          display: "grid",
                          placeItems: "center",
                          paddingTop: 14,
                          pointerEvents: "none",
                          opacity: 0.7,
                        }
                      : {
                          width: 120,
                          height: 140,
                          position: "relative",
                          cursor: "pointer",
                          // border:'2px solid black',
                          display: "grid",
                          placeItems: "center",
                          paddingTop: 14,
                        }
                  }
                  onClick={() => {
                    if (val?.key) {
                      handleSwipeDocuments(doc, val, i);
                    } else {
                      if ([1, 5].includes(Number(user?.role))) {
                        if(vehiclePhotosArr?.includes(fieldName)){
                          setWarnPopOnVehiclePhoto({doc,val,i});
                        }else handleFileUpload(val);
                      } else {
                        toast.error(
                          "Only admin and manager can add documents !!!"
                        );
                      }
                    }
                  }}
                >
                  <Paper
                    elevation={3}
                    sx={{
                      width: 100,
                      height: 80,
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "8px",
                    }}
                  >
                    {(() => {
                      if (
                        val?.key &&
                        [null, undefined]?.includes(val?.isApproved)
                      ) {
                        return (
                          <span
                            className="icons"
                            style={{ backgroundColor: "#AE1AC7" }}
                          >
                            <CheckIcon sx={{ fontSize: 15 }} />
                          </span>
                        );
                      } else if (val?.isApproved) {
                        return (
                          <span className="icons">
                            <DoneAllIcon sx={{ fontSize: 15 }} />
                          </span>
                        );
                      } else if (val?.isApproved === false) {
                        return (
                          <span
                            className="icons"
                            style={{ background: "rgba(255, 34, 34, 1)" }}
                          >
                            <ClearIcon sx={{ fontSize: 15 }} />
                          </span>
                        );
                      }
                    })()}
                    {val?.key ? (
                      <div
                        style={{
                          width: 70,
                          height: 60,
                          position: "relative",
                          overflow: "hidden",
                        }}
                        className="hide-scroll-bar"
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "60px",
                            zIndex: 1,
                            position: "absolute",
                            cursor: "pointer",
                          }}
                        ></div>
                        <img
                          width={"100%"}
                          height={"60px"}
                          src={(() => {
                            if (icons?.[fieldName]) {
                              return icons?.[fieldName];
                            }
                            return otherdoc;
                          })()}
                          alt="file"
                        />
                        {/* {val?.dataType === "pdf" ? (
                          <embed
                            width={"200%"}
                            height={"60px"}
                            src={val?.fileLink}
                            className="hide-scroll-bar"
                          ></embed>
                        ) : (
                          <embed
                            width={"100%"}
                            height={"60px"}
                            src={val?.fileLink}
                            className="hide-scroll-bar"
                          ></embed>
                        )} */}
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                          overflow: "hidden",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "60px",
                            zIndex: 1,
                            position: "absolute",
                            cursor: "pointer",
                          }}
                        ></div>

                        <img
                          src={(() => {
                            return folder;
                          })()}
                          width={60}
                        />
                      </div>
                    )}
                  </Paper>
                  <div
                    style={{
                      width: 100,
                      height: 80,
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <p
                      className="text-center font-rocknroll "
                      style={{ fontSize: "13px" }}
                    >
                      {capitalizeFirstLetter(
                        val?.name === "cheques_1" ||
                          val?.category === "other_documents" ||
                          val?.name === "cibil_report_1"
                          ? val?.name
                          : val?.name?.replace("_1", "")
                      )}
                    </p>
                  </div>
                </div>
              </Grid>
              <Menu
                id={"menu" + val?.name}
                anchorEl={anchorEl}
                open={openElem === val}
                disableAutoFocusItem
                PaperProps={{
                  style: {
                    left: "50%",
                    transform: "translateX(77%) translateY(-50%)",
                  },
                }}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    // window.open(
                    //   `${baseURL}/common/view?key=${val?.key}`,
                    //   "_blank"
                    // );
                    // window.open(val?.fileLink, "_blank");
                    setswipeDocs(doc);
                    setDocViewer({
                      open: true,
                      url: val?.fileLink,
                      title: capitalizeFirstLetter(val?.name),
                      data: val,
                      isMulti: val?.meta_page_document,
                      index: i,
                    });
                  }}
                >
                  Open
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDownload(
                      val?.key,
                      `${application_id}-${val?.name}.${val?.dataType}`,
                      val
                    );
                  }}
                >
                  {isDownloading === val ? (
                    <span>
                      <CircularProgress
                        color="success"
                        size={15}
                        className="mt-2 me-2"
                      />
                      Downloading{" "}
                    </span>
                  ) : (
                    "Download"
                  )}
                </MenuItem>
              </Menu>
            </>
          );
        }
      })
    ) : (
      <div className="w-100">
        <p className="text-center mb-0" style={{ color: "#a7a8a7" }}>
          <DoNotDisturbAltIcon className="me-1 mb-1" /> No documents
        </p>
      </div>
    );
  };

  const handleDORef = async () => {
    try {
      if (openDOUpload?.refId) {
        await updateRefId({
          application_id: updateDetails?.application_id,
          nbfc_reference_id: openDOUpload?.refId,
        });
        setUpdateDetails({
          ...updateDetails,
          nbfc_reference_id: openDOUpload?.refId,
        });
        setOpenDOUpload({
          open: false,
          val: null,
          refId: null,
        });
      }
    } catch (error) {
      toast?.error("Error updating ref ID");
    }
  };

  const handleNachClick = async () => {
    try {
      const response = await checkNachStatus({
        application_id: updateDetails?.application_id,
      });
      await getApplicationDetails();
      toast.info(response?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Server Error");
    }
  };

  const getNachStatus = (digioNachStatus) => {
    const NachMapping = {
      NA: "Not Available",
      // register_success: "Nach is Registered",
      // register_failed: "Nach registration failed",
      // nack_received: "Nach registration failed",
      // partial: "Nach is Pending",
    };
    return NachMapping[digioNachStatus] ?? digioNachStatus ?? "Not Available";
  };

  const getNachMode = (mode) => {
    if (!mode) return null;
    return capitalize(mode);
  };

  const getRejectionReason = () => {
    let res_report = digioNachData?.latest?.bank_details?.res_report;
    res_report = res_report?.filter(
      (item) => item?.enach_id === digioNachData?.latest?.id
    )?.[0];
    if (res_report?.reject_reason) {
      return <p>Rejection Reason :{` ${res_report?.reject_reason || "NA"}`}</p>;
    }
  };

  useEffect(() => {
    setDigioNachData(nachData);
  }, [nachData]);

  useEffect(() => {
    setProgress(
      `${downloadProgress}/${
        multiDocLength === null
          ? docStatusList?.approved?.length
          : multiDocLength
      }`
    );
  }, [downloadProgress]);

  useEffect(() => {
    if (croppedImg?.length || pdfFile?.length) {
      if (uploadDocDetails?.doc?.pageUpload)
        handleDocPageReplace(uploadDocDetails);
      else handleDocUpload(uploadDocDetails);
    }
  }, [croppedImg, pdfFile]);

  const handleMakePdf = async (doc, fileName) => {
    let promises = [];
    let files = [];
    for (const item of doc) {
      promises.push(download(item, false, null, false, true));
    }

    let results = await Promise.allSettled(promises);
    for (const item of results) {
      files.push(await blobToBase64(item?.value));
    }
    cobminePDF(files, fileName || "document");
  };

  useEffect(() => {
    if (
      searchParams.get("status") === "success" &&
      updateDetails?.application_id
    ) {
      callPOAsignAPI();
    }
  }, [updateDetails]);

  return (
    <>
      <Grid container spacing={2} className="mb-4">
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              marginBottom: "10px",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <StatusDialog
              open={statusDialogData?.open}
              handleClose={() => {
                setStatusDialogData({ open: false });
              }}
              openFile={(item, index) => {
                let file = AllDocuments?.all_documents?.[item];
                if (file) {
                  setDocViewer({
                    open: true,
                    url: file?.fileLink,
                    title: capitalizeFirstLetter(file?.name),
                    data: file,
                    isMulti: file?.meta_page_document,
                  });
                }
              }}
              docStatusList={docStatusList}
              application_id={application_id}
            />

            {user?.role != 3 && (
              <div
                className="doc-btns me-2"
                style={{
                  color: updateDetails?.gps_unique_number ? "#16980A" : "",
                }}
                onClick={() => {
                  setOpenMMIdialog(true);
                }}
              >
                <LocationOnIcon sx={{ fontSize: 17, mr: 0.5 }} /> GPS
              </div>
            )}

            <div
              className="doc-btns me-2"
              onClick={() => {
                setOpenLandmark(true);
              }}
            >
              <LocationCityIcon sx={{ fontSize: 17, mr: 0.5 }} /> Landmark
              locations
            </div>

            {updateDetails?.nbfcData?.nbfc_id === "FI00005" &&
              updateDetails?.enablePOAEsign &&
              (updateDetails?.poa_e_signed ? (
                <div
                  className="doc-btns me-2"
                  style={{
                    justifyContent: "left",
                    color: !updateDetails?.prefilled_after_esign
                      ? "#1976D2"
                      : "#18A900",
                    cursor: updateDetails?.prefilled_after_esign
                      ? "pointer"
                      : "none !important",
                  }}
                  onClick={() => {
                    if (updateDetails?.prefilled_after_esign) {
                      return;
                    }
                    setOpenEsignPopup(true);
                  }}
                >
                  <NoteAltIcon sx={{ fontSize: 17, mr: 0.5 }} /> Prefill Loan
                  Agreement
                </div>
              ) : (
                <div
                  className="doc-btns me-2"
                  style={{
                    justifyContent: "left",
                  }}
                  onClick={() => {
                    handlePOASigning();
                  }}
                >
                  <NoteAltIcon sx={{ fontSize: 17, mr: 0.5 }} /> POA E-Signing
                </div>
              ))}
            <div
              className="doc-btns me-2"
              style={{
                justifyContent: "left",
                ...(() => {
                  if (updateDetails?.coApplicantExist) {
                    if (
                      updateDetails?.coCustomerTelePdDone &&
                      updateDetails?.customerTelePdDone
                    ) {
                      return { fontWeight: "500", color: "#16980A" };
                    }
                  } else {
                    if (updateDetails?.customerTelePdDone) {
                      return { fontWeight: "500", color: "#16980A" };
                    }
                  }
                  return {};
                })(),
              }}
              onClick={async () => {
                setOpenTelePd(true);
              }}
            >
              <SupportAgentIcon sx={{ fontSize: 17, mr: 0.5 }} /> TELE PD
            </div>

            <div
              className="doc-btns me-3"
              style={{ justifyContent: "left" }}
              onClick={async () => {
                setStatusDialogData({ open: true });
              }}
            >
              <RuleFolderIcon sx={{ fontSize: 17, mr: 0.5 }} /> Check Status
            </div>

            <div
              className="doc-btns ms-0"
              disabled={Number(user?.role) !== 2}
              style={{ justifyContent: "left", ...inlineStyles }}
              onClick={async () => {
                let combined = (() => {
                  let docs = [];
                  for (let key in AllDocuments) {
                    if (key !== "total_count" && key !== "all_documents") {
                      docs = [...docs, ...AllDocuments[key]];
                    }
                  }
                  return docs;
                })();
                setDownloadProgress(0);
                setTotalDocumentCount(combined?.length);
                downloadAll(combined, `${application_id}`);
              }}
            >
              <DownloadIcon sx={{ fontSize: 17, mr: 0.5 }} /> Download all
            </div>
            {[1, 5].includes(Number(user?.role)) && (
              <div
                className="doc-btns"
                onClick={() => {
                  setOpenAddOtherDocs(true);
                }}
              >
                <AddIcon sx={{ fontSize: "20px", mr: "5px" }} />
                Add Other Documents
              </div>
            )}
          </div>
          <Grid xs={12}>
            <div
              style={{
                float: "left",
                display: "flex",
                flexDirection: "column",
              }}
              className="me-2"
            >
              {(Number(user?.role) !== 3 || user?.email?.includes("offshoot")) && (
                <Box item sx={{width:400}}>
                  <div className="over-view-card flex-column">
                    <div className="d-flex flex-column align-items-start w-100">
                      <ToggleButtonGroup
                        value={nachType}
                        onChange={(_,value)=>{setNachType(value || "latest")}}
                        aria-label="device"
                        size="small"
                        fullWidth
                        exclusive
                        className="w-100 mb-2"
                        color="primary"
                        sx={{
                          borderRadius: '4px', // Optional: add border radius
                          '& .MuiToggleButton-root': {
                            borderColor: '#1976d2', // Customize border color for each button
                            '&.Mui-selected': {
                              borderColor: '#1976d2', // Customize border color when selected
                            },
                          },
                        }}
                      >
                        <ToggleButton value="latest" size="small" className="py-0">
                          latest
                        </ToggleButton>
                        <ToggleButton value="old" size="small" className="py-0">
                          Old
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <h5
                        className="pb-2"
                        style={{
                          fontStretch: "extra-expanded",
                          fontWeight: 500,
                        }}
                      >
                        Nach Status :{" "}
                        {getNachStatus(
                          digioNachData?.[nachType]?.bank_details?.state
                        )}
                      </h5>
                    </div>
                    <div
                      style={{
                        lineHeight: 0.5,
                        fontSize: 13,
                        color: "rgba(96, 89, 89, 1)",
                        fontWeight: 500,
                      }}
                      className="font-inter"
                    >
                      <p>
                        NACH Id : {` ${digioNachData?.[nachType]?.id || "NA"}`}
                      </p>
                      <p>
                        NACH Type :
                        {` ${
                          getNachMode(digioNachData?.[nachType]?.mode) || "NA"
                        }`}
                      </p>
                      <p>
                        Bank name :
                        {` ${
                          digioNachData?.[nachType]?.mandate_details
                            ?.destination_bank_name || "NA"
                        }`}
                      </p>
                      <p>
                        Account No. :
                        {` ${
                          digioNachData?.[nachType]?.mandate_details
                            ?.customer_account_number || "NA"
                        }`}
                      </p>
                      {getRejectionReason()}
                    </div>
                    <div
                      style={{
                        height: 12,
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        {![
                          "register_success",
                          "register_failed",
                          "NA",
                        ].includes(
                          digioNachData?.["latest"]?.bank_details?.state
                        ) && (
                          <Button
                            onClick={handleNachClick}
                            style={{
                              paddingLeft: 0,
                            }}
                          >
                            Refresh
                          </Button>
                        )}
                        {digioNachData?.["latest"]?.bank_details?.state !==
                          "register_success" && (
                          <Button
                            style={{ padding: 0 }}
                            onClick={() => {
                              setNachUpdate({
                                ...nachUpdate,
                                open: true,
                              });
                            }}
                          >
                            Update
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </Box>
              )}
            </div>
          </Grid>
          <Grid xs={12}>
            <div
              style={{
                float: "left",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {Number(user?.role) !== 3 && (
                <Box item>
                  <div
                    className="over-view-card flex-column"
                    style={{ minHeight: "170px" }}
                  >
                    <h5
                      className="pb-2"
                      style={{ fontStretch: "extra-expanded", fontWeight: 500 }}
                    >
                      Certificate number
                    </h5>
                    <div
                      style={{
                        lineHeight: 0.5,
                        fontSize: 13,
                        color: "rgba(96, 89, 89, 1)",
                        fontWeight: 500,
                      }}
                      className="font-inter"
                    >
                      {(updateDetails?.stampData || []).length > 0 ? (
                        (updateDetails?.stampData || [])?.map((val) => (
                          <p>{val?.certificate_number || "--"}</p>
                        ))
                      ) : (
                        <p>No Certificate</p>
                      )}
                    </div>
                  </div>
                </Box>
              )}
            </div>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className={"mt-3"}>
              <div>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      Customer{" "}
                      {docCount(
                        AllDocuments?.customer?.filter((itm) => !!itm?.key)
                          ?.length
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} className="mb-3">
                      {documentCard(AllDocuments?.customer)}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {updateDetails?.coApplicantExist && (
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        Co-customer{" "}
                        {docCount(
                          AllDocuments?.co_customer?.filter((itm) => !!itm?.key)
                            ?.length
                        )}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2} className="mb-3">
                        {documentCard(AllDocuments?.co_customer)}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      Field Investigation{" "}
                      {docCount(
                        AllDocuments?.field_investigation?.filter(
                          (itm) => !!itm?.key
                        )?.length
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} className="mb-3">
                      {documentCard(AllDocuments?.field_investigation)}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      Pre-disbursement{" "}
                      {docCount(
                        AllDocuments?.pre_disbursement?.filter(
                          (itm) => !!itm?.key
                        )?.length
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} className="mb-3">
                      {documentCard(AllDocuments?.pre_disbursement)}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      Post-disbursement{" "}
                      {docCount(
                        AllDocuments?.post_disbursement?.filter(
                          (itm) => !!itm?.key
                        )?.length
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} className="mb-3">
                      {documentCard(AllDocuments?.post_disbursement)}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel6"}
                  onChange={handleChange("panel6")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      Other Documents{" "}
                      {docCount(
                        AllDocuments?.other_documents?.filter(
                          (itm) => !!itm?.key
                        )?.length
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} className="mb-3">
                      {documentCard(AllDocuments?.other_documents)}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <input
        ref={fileRef}
        type="file"
        class="custom-file-input"
        accept={
          uploadDocDetails?.fileName === "fi_video"
            ? "video/*"
            : `${
                !uploadDocDetails?.doc?.pageUpload ? "application/pdf ," : ""
              } image/png, image/jpg, image/jpeg`
        }
        onChange={async (e) => {
          setCropImg("");
          if (e.target.files[0]["type"].split("/")[0] == "image") {
            if (e.target.files[0].type.match("image/svg")) {
              toast.error("Not supported file");
              return false;
            }
            setInput(false);
            setImage(await getBase64(await compressImage(e.target.files[0])));
          } else if (e.target.files[0].type.match("application/pdf")) {
            setInput(true);
            // setPdf(await getBase64(e.target.files[0]));
            setPdf(await getBase64(e.target.files[0]));
            // setConfirmFilename(e.target.files[0].name);
          } else if (e.target.files[0].type.match("video")) {
            setCropImg(await getBase64(e.target.files[0]));
          } else {
            alert("Pdf/Image only");
            handleRecapture();
          }
        }}
        hidden
      />
      <CropScreen
        // open={image !== "" && !isInput}
        open={image !== ""}
        image={image}
        onClose={() => {
          setImage("");
        }}
        onCrop={(file) => {
          setCropImg(file);
          // setConfirmFilename("capture.jpg");
          setImage("");
        }}
        onRecapture={() => {
          handleRecapture();
          fileRef.current.click();
        }}
      />

      <FileViewer
        application_id={application_id}
        open={docViewer?.open}
        url={docViewer?.url}
        title={docViewer?.title}
        data={{ ...docViewer?.data }}
        index={docViewer.index}
        onClose={() => {
          setDocViewer(docViewInitial);
        }}
        reload={(status) => {
          getAllDocuments();
          setDocViewer({
            ...docViewer,
            data: {
              ...docViewer?.data,
              isApproved: status,
            },
          });
          getApplicationDetails();
        }}
        handleSwipe={handleSwipe}
        setswipeDocs={setswipeDocs}
        getApplicationDetails={getApplicationDetails}
        getAllDocuments={getAllDocuments}
        type={"documents"}
        mandate_id={updateDetails?.mandate_id}
        isMulti={docViewer?.isMulti}
        onDownload={(doc, isMulti, name, pdf = false) => {
          // return false;
          if (isMulti) {
            if (pdf) {
              handleMakePdf(doc, `${name}_${application_id}`);
            } else {
              downloadAll(doc, name, true);
            }
          } else {
            handleDownload(
              doc?.key,
              `${application_id}-${doc?.name}.${doc?.dataType}`,
              doc
            );
          }
        }}
        handleReupload={(data) => {
          if(vehiclePhotosArr?.includes(data?.name)){
            setWarnPopOnVehiclePhoto({ val:data, reupload:true });
          }else handleFileUpload(data, true);
          // handleRecapture();
        }}
        handleDownloadFn={handleDownload}
      />

      {isDownloadingAll && <LoadingOver text={`Downloading ${progress}`} />}
      {isOtherDocsLoading && <LoadingOver text={`Updating Documents...`} />}
      {isEsignLoading && <LoadingOver text={isEsignLoading} />}
      <RenderCanvas />
      <AddOtherDocs
        open={openAddOtherDocs}
        handleClose={() => setOpenAddOtherDocs(false)}
        handleOtherDocsUpload={handleOtherDocsUpload}
        AllDocuments={AllDocuments}
        loanDocs={updateDetails?.loanDocs?.other_documents}
      />
      <NachStatusUpdate
        open={nachUpdate?.open}
        handleClose={() => {
          setNachUpdate({
            ...nachUpdate,
            open: false,
          });
        }}
        applicationId={updateDetails?.application_id}
      />

      <TelePDModal
        open={openTelePd}
        handleClose={() => {
          setOpenTelePd(false);
        }}
        getApplicationDetails={getApplicationDetails}
      />
      <LoanEsingDialog
        open={openEsignPopup}
        handleClose={() => {
          setOpenEsignPopup(false);
        }}
        getApplicationDetails={getApplicationDetails}
      />

      <MMIupdateDialog
        open={openMMIdialog}
        application_id={updateDetails?.application_id}
        handleClose={() => {
          setOpenMMIdialog(false);
        }}
        onUpdate={(updatedData) => {
          setUpdateDetails({
            ...updateDetails,
            ...updatedData,  // Merge updated fields into the current state
          });
        }}
      />

      <DOUpload
        open={openDOUpload?.open}
        item={openDOUpload?.val}
        application_id={updateDetails?.application_id}
        handleClose={() => {
          setOpenDOUpload({
            open: false,
            val: null,
            refId: null,
          });
        }}
        handleUpload={(val, refId) => {
          setOpenDOUpload({
            ...openDOUpload,
            refId,
          });
          setCropImg(val);
          setImage("");
        }}
      />

      {openLandmark && (
        <LandMarkDialog
          open={openLandmark}
          handleClose={() => {
            setOpenLandmark(false);
          }}
        />
      )}
      <WarnDialog 
        open={Boolean(warnPopOnVehiclePhoto)} 
        handleClose={()=>{setWarnPopOnVehiclePhoto(null)}} 
        handleSubmit={()=>{
          const {val, reupload} = (warnPopOnVehiclePhoto || {});
          if ([1, 5].includes(Number(user?.role))) {
            handleFileUpload(val,reupload);
          } else {
            toast.error(
              "Only admin and manager can add documents !!!"
            );
          }
          setWarnPopOnVehiclePhoto(null);
        }}
        text={    <div style={{ textAlign: 'center' }}>
        <p style={{ margin: 0 }}>Please note that the case will be <span className="fw-semibold text-danger">canceled!</span></p>
        <p style={{ margin: 0 }}>If the location is not provided.</p>
      </div>} />
    </>
  );
};

export default Documents;

const ListItemComp = ({ icon, heading, text }) => {
  return (
    <ListItem disablePadding>
      <ListItemIcon className="list-key">
        {
          <span>
            {icon && icon}
            {heading}
          </span>
        }
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ fontSize: "13px" }}
        className="list-value"
        primary={text}
      />
    </ListItem>
  );
};


const WarnDialog =({ open, text, handleClose, handleSubmit }) => {
  return (
    <Dialog open={open} onClose={handleClose} >
      <DialogTitle>{text}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Upload</Button>
      </DialogActions>
    </Dialog>
  )
}