import React, { useContext, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
} from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FileDownloadSharpIcon from "@mui/icons-material/FileDownloadSharp";
import DownloadingSharpIcon from "@mui/icons-material/DownloadingSharp";
import "./style.css";
import { getBase64 } from "../../utility/convertFileBase";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalContext";
import folder from "../../assets/folder.svg";
import { UserContext } from "../../Context/UserContext";
import { verifyAuth } from "../../api";
import { getDocKey } from "../../utility/Formater";
import axios from "axios";
import DocView from "./DocView";
import { handleDownload } from "../../utility/download";
import moment from "moment";

const tempState = {
  open: false,
  index: null,
  front: null,
  back: null,
};

const AadhaarUpload = ({
  docs = [],
  heading,
  mt,
  onChange,
  onDelete,
  aadhaar_front = "aadhaar_front",
  aadhaar_back = "aadhaar_back",
  category = "aadhar_image",
  error,
  onAddMore,
  isEditable = true,
  isDownloadAble = false,
  anchor_id = null,
  swipeArr,
  getSwipeArr,
  handleSingleDocReupload,
  handleSingleDocDelete,
}) => {
  const fileInputFront = useRef(null);
  const fileInputBack = useRef(null);
  const [currentUpload, setCurrent] = useState(tempState);

  const { dealerDetailState, updateDocsState } = useContext(GlobalContext);
  const { user, setUser } = useContext(UserContext);
  const [isDownloading, setIsDownloading] = useState(null);
  const [doc, setDoc] = useState({});
  const [dealerDetails, setDealerDetails] = dealerDetailState;
  const [updateDocs, setUpdateDocs] = updateDocsState;

  const handleClose = () => {
    setCurrent(tempState);
  };

  return (
    <>
      <div style={{ marginTop: mt ? mt : 0 }}>
        <Grid container spacing={2} style={{ marginTop: 1 }}>
          {heading && (
            <Grid item xs={12} style={{ marginBottom: -10 }}>
              <span className="upload-section-heading">{heading}</span>
            </Grid>
          )}
          {docs?.length === 0 && !isEditable && (
            <Grid item xs={12}>
              <div
                style={{
                  // background:'blue',
                  width: 220,
                  display: "grid",
                  gridTemplateColumns: "45% 10% 45%",
                }}
              >
                <div>
                  <div
                    // className="anchor-doc-preview"
                    style={{
                      width: 95,
                    }}
                  >
                    <img
                      src={folder}
                      height={"70px"}
                      // height={"100%"}
                      // width={"100%"}
                      alt="document"
                    ></img>
                  </div>
                  <p
                    style={{
                      width: 95,
                      marginTop: 10,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#61677A",
                      wordWrap: "unset",
                    }}
                  >
                    {"Aadhaar Front"}
                  </p>
                </div>
                <div />
                <div
                  item
                  xs={1.15}
                  sx={{
                    margin: "0px 30px",
                  }}
                >
                  <div
                    // className="anchor-doc-preview"
                    style={{
                      width: 95,
                    }}
                  >
                    <img
                      src={folder}
                      height={"70px"}
                      // height={"100%"}
                      // width={"100%"}
                      alt="document"
                    ></img>
                  </div>
                  <p
                    style={{
                      marginTop: 10,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#61677A",
                      wordWrap: "unset",
                      width: 95,
                    }}
                  >
                    {"Aadhaar Back"}
                  </p>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
        {docs?.length === 0 && isEditable && (
          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs={3}>
              <Button
                variant={"contained"}
                className="upload-btn-anchor"
                onClick={(e) => {
                  setCurrent({
                    ...currentUpload,
                    open: true,
                    index: 0,
                  });
                }}
              >
                <CloudUploadOutlinedIcon sx={{ mr: 1, fontSize: 18 }} /> Aadhaar
                Upload
              </Button>
            </Grid>
          </Grid>
        )}

        {docs?.map((doc, i) => {
          return (
            <Grid
              container
              spacing={2}
              key={
                (doc?.[aadhaar_front]?.uploadedDateMS || "") +
                (doc?.[aadhaar_back]?.uploadedDateMS || "")
              }
              sx={{ mt: 0.2 }}
            >
              <Grid item xs={2.5} sx={{ position: "relative" }}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    console.log("clicked !!!")
                    setDoc({
                      ...(doc?.[aadhaar_front] ?? {}),
                      aadharData: doc,
                      open: true,
                    });
                  }}
                ></div>
                {/* } */}
                {isDownloadAble && doc?.[aadhaar_front]?.name && (
                  <Button
                    className="file-download-btn"
                    onClick={() => {
                      handleDownload(
                        doc?.[aadhaar_front]?.fileLink,
                        `${anchor_id}-${doc?.[aadhaar_front]?.name}`,
                        doc?.[aadhaar_front],
                        setIsDownloading,
                        {
                          user,
                          setUser,
                        }
                      );
                    }}
                    disabled={
                      isDownloading?.name === doc?.[aadhaar_front]?.name
                    }
                  >
                    {isDownloading?.name === doc?.[aadhaar_front]?.name ? (
                      <DownloadingSharpIcon style={{ fontSize: 16 }} />
                    ) : (
                      <FileDownloadSharpIcon style={{ fontSize: 16 }} />
                    )}
                  </Button>
                )}

                <div className="anchor-doc-preview">
                  {doc?.[aadhaar_front]?.key  ? (
                    <embed
                      key={doc?.[aadhaar_front]?.uploadedDateMS}
                      src={doc?.[aadhaar_front]?.fileLink}
                      width={"100%"}
                      height={"100%"}
                    ></embed>
                  ) : doc?.[aadhaar_front]?.dataType !== "pdf" ? (
                    <img
                      src={doc?.[aadhaar_front]?.fileLink}
                      width={"100%"}
                      height={"100%"}
                      style={{
                        objectFit: "contain",
                      }}
                      alt="uploaded document"
                    />
                  ) : (
                    <iframe
                      src={doc?.[aadhaar_front]?.fileLink}
                      width={"100%"}
                      height={"100%"}
                      style={{
                        objectFit: "contain",
                      }}
                      alt="uploaded document"
                    ></iframe>
                  )}
                  {/* <embed src={doc?.[aadhaar_front]?.fileLink}></embed> */}
                </div>
                <span className="anchor-doc-error">{doc?.error}</span>
              </Grid>

              <Grid item xs={2.5} sx={{ position: "relative" }}>
                {/* {!isEditable && doc?.[aadhaar_front]?.key &&  */}
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setDoc({
                      ...(doc?.[aadhaar_back] ?? {}),
                      aadharData: doc,
                      open: true,
                    });
                  }}
                ></div>
                {/* } */}
                {isDownloadAble && doc?.[aadhaar_back]?.name && (
                  <Button
                    className="file-download-btn"
                    onClick={() => {
                      handleDownload(
                        doc?.[aadhaar_back]?.fileLink,
                        `${anchor_id}-${doc?.[aadhaar_back]?.name}`,
                        doc?.[aadhaar_back],
                        setIsDownloading,
                        {
                          user,
                          setUser,
                        }
                      );
                    }}
                    disabled={isDownloading?.name === doc?.[aadhaar_back]?.name}
                  >
                    {isDownloading?.name === doc?.[aadhaar_back]?.name ? (
                      <DownloadingSharpIcon style={{ fontSize: 16 }} />
                    ) : (
                      <FileDownloadSharpIcon style={{ fontSize: 16 }} />
                    )}
                  </Button>
                )}
                <div className="anchor-doc-preview">
                  {(doc?.[aadhaar_back]?.key) ? (
                    <embed
                      key={doc?.[aadhaar_back]?.uploadedDateMS}
                      src={doc?.[aadhaar_back]?.fileLink}
                      width={"100%"}
                      height={"100%"}
                    ></embed>
                  ) : doc?.[aadhaar_back]?.dataType !== "pdf" ? (
                    <img
                      src={doc?.[aadhaar_back]?.fileLink}
                      width={"100%"}
                      height={"100%"}
                      style={{
                        objectFit: "contain",
                      }}
                      alt="uploaded document"
                    />
                  ) : (
                    <iframe
                      src={doc?.[aadhaar_back]?.fileLink}
                      width={"100%"}
                      height={"100%"}
                      style={{
                        objectFit: "contain",
                      }}
                      alt="uploaded document"
                    ></iframe>
                  )}
                </div>
                <span className="anchor-doc-error">{doc?.error}</span>
              </Grid>

              {isEditable && (
                <Grid item xs={2.5} sx={{ position: "relative" }}>
                  <Button
                    className="file-remove-btn remove-aadhaar"
                    onClick={() => {
                      if (onDelete) {
                        onDelete(doc, i);
                        if (dealerDetails?.update) {
                          if (doc?.isUploaded) {
                            setUpdateDocs([
                              ...updateDocs?.filter(
                                (item) => item?.id === doc?.id
                              ),
                            ]);
                          } else {
                            setUpdateDocs([
                              ...updateDocs,
                              {
                                new: false,
                                category: "aadhar_image",
                                ...doc,
                              },
                            ]);
                          }
                        }
                      }
                    }}
                  >
                    <CloseIcon />
                  </Button>

                  {i === docs?.length - 1 && (
                    <Button
                      variant={"outlined"}
                      className={`upload-btn-anchor add-more-aadhaar ${
                        docs?.length === 0 ? "add-more-disabled" : ""
                      }`}
                      disabled={docs?.length === 0}
                      onClick={(e) => {
                        setCurrent({
                          ...currentUpload,
                          open: true,
                          index: i,
                        });
                      }}
                    >
                      <AddCircleOutlineOutlinedIcon
                        sx={{ mr: 1, fontSize: 18 }}
                      />{" "}
                      Add more
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          );
        })}
      </div>

      <input
        ref={fileInputFront}
        type="file"
        class="custom-file-input"
        accept="application/pdf , image/png, image/jpg, image/jpeg"
        onChange={async (e) => {
          setCurrent({
            ...currentUpload,
            front: await getBase64(e.target.files[0]),
            frontDocType:e.target.files[0].type.includes("image") ? "image":"pdf"
          });
          e.target.value = "";
        }}
        onClick={(e) => {
          e.target.value = "";
        }}
        hidden
      />
      <input
        ref={fileInputBack}
        type="file"
        class="custom-file-input"
        accept="application/pdf , image/png, image/jpg, image/jpeg"
        onChange={async (e) => {
          setCurrent({
            ...currentUpload,
            back: await getBase64(e.target.files[0]),
            backDocType:e.target.files[0].type.includes("image") ? "image":"pdf"
          });
          e.target.value = "";
        }}
        onClick={(e) => {
          e.target.value = "";
        }}
        hidden
      />

      <Dialog
        open={currentUpload?.open}
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {typeof currentUpload?.front === "string" ? (
                <>
                  <div className="anchor-doc-preview">
                    <embed src={currentUpload?.front}></embed>
                  </div>
                  {/* <span className="anchor-doc-error">{doc?.error}</span> */}
                </>
              ) : (
                <Button
                  variant={"contained"}
                  className="upload-btn-anchor"
                  onClick={(e) => {
                    fileInputFront.current.click();
                  }}
                >
                  <CloudUploadOutlinedIcon sx={{ mr: 1, fontSize: 18 }} />{" "}
                  Aadhaar Front
                </Button>
              )}
            </Grid>

            <Grid item xs={6}>
              {typeof currentUpload?.back === "string" ? (
                <>
                  <div className="anchor-doc-preview">
                    <embed src={currentUpload?.back}></embed>
                  </div>
                  {/* <span className="anchor-doc-error">{doc?.error}</span> */}
                </>
              ) : (
                <Button
                  variant={"contained"}
                  className="upload-btn-anchor"
                  onClick={(e) => {
                    fileInputBack.current.click();
                  }}
                >
                  <CloudUploadOutlinedIcon sx={{ mr: 1, fontSize: 18 }} />{" "}
                  Aadhaar Back
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={
              !(
                typeof currentUpload?.front === "string" &&
                typeof currentUpload?.back === "string"
              )
            }
            onClick={(e) => {
              onChange({
                id:moment().valueOf(),
                isUpload: true,
                [aadhaar_front]: {
                  fileLink: currentUpload?.front,
                  dataType:currentUpload?.frontDocType
                },
                [aadhaar_back]: {
                  fileLink: currentUpload?.back,
                  dataType:currentUpload?.backDocType
                },
              });

              if (dealerDetails?.update) {
                setUpdateDocs([
                  ...updateDocs,
                  {
                    id: Number(new Date()),
                    new: true,
                    category: "aadhar_image",
                    [aadhaar_front]: currentUpload?.front,
                    [aadhaar_back]: currentUpload?.back,
                  },
                ]);
              }

              handleClose();
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
      {doc?.open && (
        <DocView
          docs={doc}
          handleClose={() => {
            setDoc((prev) => ({ ...prev, open: false }));
          }}
          handleDownload={(docs, setLoading) => {
            handleDownload(
              docs?.fileLink,
              `${anchor_id}-${docs?.name}`,
              docs,
              setLoading,
              {
                user,
                setUser,
              }
            );
          }}
          isAadhaar={true}
          category={category}
          swipeDocs={swipeArr}
          getSwipeDocs={getSwipeArr}
          reuploadDoc={handleSingleDocReupload}
          deleteDocFun={handleSingleDocDelete}
        />
      )}
    </>
  );
};

export default AadhaarUpload;
