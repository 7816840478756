import { CircularProgress, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect } from "react";
import { useState } from "react";
import {
  fetchDPDschedule,
  fetchSchedule,
  getCollectionHistoryV2,
} from "../../api";
import { toast } from "react-toastify";
import { statgeMap } from "../../utility/Stages";

export default function EMIDPDModal({ data, handleClose, getStylePer }) {
  const getStyle = (val) => {
    let color = "";
    console.log(val);
    if(val=== "-"){
      return;
    }
    let dpd = Number(val);
    if (dpd <= 0) {
      color = "#16980A";
    } else if (dpd <= 15) {
      color = "#e9bc1b";
    } else if (dpd <= 30) {
      color = "#FF6B00";
    } else {
      color = "#FF2222";
    }
    return color;
  };

  const [schedule, setSchedule] = useState([]);
  const [loading, setLoader] = useState(false);

  const handleFetchSchedule = async (applicationId) => {
    setLoader(true);
    try {
      // let { data: response } = await fetchSchedule(applicationId);
      let { data: dpdArray } = await fetchDPDschedule({
        application_id: applicationId,
      });

      let { data: response } = await getCollectionHistoryV2(
        applicationId,
        "EMI"
      );
      // console.log(statgeMap);
      let sheet = response || [];
      if (sheet.length !== 0 && (dpdArray?.showDPD || []).length !== 0) {
        (dpdArray?.showDPD || [])?.forEach((val, i) => {
          if (sheet?.[i]) {
            sheet[i].dpd = val + "";
          }
        });
      }
      setSchedule(sheet);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
      );
    }
    setLoader(false);
  };

  useEffect(() => {
    console.log(data);
    if (data?.open) {
      handleFetchSchedule(data?.application_id);
    }
  }, [data?.open]);

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="mt-2">
              <span
                style={{ color: "black", fontWeight: 700, marginRight: 10 }}
              >
                Overall Status -
              </span>
              <span
                style={{
                  color: "black",
                }}
              >
                {data?.message}
              </span>
            </div>
            <div className="mt-0">
              <span
                style={{ color: "black", fontWeight: 700, marginRight: 10 }}
              >
                Profile % -
              </span>
              <span
                style={{
                  color: getStylePer(data?.customer_profile_percentage),
                }}
              >
                {data?.customer_profile_percentage}%
              </span>
            </div>
            <div className="mb-5">
              <span
                style={{ color: "black", fontWeight: 700, marginRight: 10 }}
              >
                Aggregated Profile% -
              </span>
              <span
                style={{
                  color: 'black'
                }}
              >
                {data?.anchorData?.anchor_aggregate_percentage ? `${data?.anchorData?.anchor_aggregate_percentage}%` :'NA' }
              </span>
            </div>
            {!loading && (
              <Grid container>
                {schedule?.map((val, i) => {
                  return (
                    <Grid item xs={3}>
                      <div className="content-center">
                        <div>
                          <p
                            className="text-center"
                            style={{
                              color: val?.dpd ? "black" : "#c9c9c9",
                            }}
                          >
                            EMI {i + 1}
                          </p>
                          <p className="text-center">
                            <span
                              style={{
                                background: val?.dpd ? getStyle(val?.dpd) : "",
                                color: Number(val?.dpd) || Number(val?.dpd) === 0
                                  ? "white"
                                  : val?.current_status !== -1
                                  ? "black"
                                  : "#c9c9c9",
                                padding: `10px 30px`,
                                borderRadius: 20,
                              }}
                            >
                              {val?.dpd ? val?.dpd : "--"}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            {loading && (
              <Grid container>
                {[...Array(15)]?.map((val, i) => {
                  return (
                    <Grid item xs={3}>
                      <div className="content-center">
                        <div>
                          <p className="text-center">EMI</p>
                          <p className="text-center">
                            <span
                              style={{
                                background: "",
                                color: "black",
                                padding: `10px 30px`,
                                borderRadius: 20,
                              }}
                            >
                              <CircularProgress />
                            </span>
                          </p>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
          }}
        >
          <Button
            variant={"outlined"}
            onClick={handleClose}
            style={{ color: "black", borderColor: "black" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
