import React from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import moment from "moment";

const colMap = {
  lessThanFiveDays: "<=5 days",
  fiveToTenDays: "6-10 days",
  elevenToFifteenDays: "11-15 days",
  sixteenToThirtyDays: "16-30 days",
  greaterThanThirtyDays: ">30 days",
};

const RCDashboard = ({ rcData, loader }) => {
  return (
    <div style={{marginTop:'-5px'}}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#D8E0FF" }}>
            <TableRow>
              <TableCell>Month</TableCell>
              <TableCell align="center">Target</TableCell>
              <TableCell align="center">Disbursed marked count</TableCell>
              <TableCell align="center">Uploaded</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rcData || [])?.map((row, i) => {
              let length = rcData?.length;
              return (
                <TableRow
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      fontWeight: 600,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {i !== length - 1
                      ? row?.monthYear
                        ? moment(row?.monthYear, "MM-YYYY").format("MMMM YYYY")
                        : "--"
                      : "TOTAL"}
                  </TableCell>
                  <TableCell align="center">
                    {i !== length - 1 ? row?.target : row?.total_count}
                  </TableCell>
                  <TableCell align="center">
                    {i !== length - 1
                      ? row?.disbursement_count
                      : row?.total_disbursement_count}
                  </TableCell>
                  <TableCell align="center">
                    {i !== length - 1
                      ? row?.rc_uploaded
                      : row?.total_rc_uploaded}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {loader === "RC" && (
        <p
          className="text-center w-100 mt-5"
          style={{
            fontSize: "15px",
            color: "black",
          }}
        >
          <CircularProgress size={50} />
        </p>
      )}
      {loader === null && rcData === null && (
        <p
          className="text-center w-100 mt-5"
          style={{
            fontSize: "15px",
            color: "black",
          }}
        >
          <DoNotDisturbIcon sx={{ fontSize: 15, mr: 0.3 }} /> No Data to display
        </p>
      )}
    </div>
  );
};

export default RCDashboard;
