import React from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

const colMap = {
  lessThanFiveDays: "<=5 days",
  fiveToTenDays: "6-10 days",
  elevenToFifteenDays: "11-15 days",
  sixteenToThirtyDays: "16-30 days",
  greaterThanThirtyDays: ">30 days",
};

const SanctionLoanTable = ({ tableData, loader, active }) => {
  return (
    <>
      {tableData !== null &&
        !["SANCTION", "LOAN_AGREEMENT"]?.includes(loader) && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#D8E0FF" }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">Eligible Application</TableCell>
                  <TableCell align="center">
                    {active === "SANCTION" ? "Sanction letter" : "Loan Agreement"} Sent count
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    TOTAL
                  </TableCell>
                  <TableCell align="center">
                    {tableData?.eligible_applications}
                  </TableCell>
                  <TableCell align="center">{tableData?.sent_count}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      {loader === "SANCTION" ||
        (loader === "LOAN_AGREEMENT" && (
          <p
            className="text-center w-100 mt-5"
            style={{
              fontSize: "15px",
              color: "black",
            }}
          >
            <CircularProgress size={50} />
          </p>
        ))}
      {loader === null && tableData === null && (
        <p
          className="text-center w-100 mt-5"
          style={{
            fontSize: "15px",
            color: "black",
          }}
        >
          <DoNotDisturbIcon sx={{ fontSize: 15, mr: 0.3 }} /> No Data to display
        </p>
      )}
    </>
  );
};

export default SanctionLoanTable;
