import { Skeleton } from "@mui/material";
import moment from "moment";
import React, { useRef, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import { statgeMap } from "../../../utility/Stages";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Fab from "@mui/material/Fab";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import noDataIco from "../../../assets/nodata.gif";

const AdvancedCollectionView = ({
  collectionData,
  collectionScheduleType,
  collectionDefaultScheduleType,
  expandData,
  setExpandData,
  setCollectionUpdate,
  isLoader,
  isDisbursed,
  expandedRows,
  handleEpandRow,
}) => {
  const scrollRef = useRef(null);
  const [scroll, setScroll] = useState("Bottom");

  const handleScroll = (event) => {
    // console.log("scrollTop: ", event.currentTarget.scrollTop);
    // console.log("offsetHeight: ", event.currentTarget.offsetHeight);
    if (
      event.currentTarget.scrollTop >
      event.currentTarget.offsetHeight - event.currentTarget.offsetHeight / 2
    ) {
      setScroll("Top");
    }

    if (event.currentTarget.scrollTop < event.currentTarget.scrollHeight / 2) {
      setScroll("Bottom");
    }
  };

  return (
    <div>
      <div
        className="loan-table-container"
        ref={scrollRef}
        onScroll={handleScroll}
      >
        {!isLoader && collectionData?.length === 0 ? (
          <>
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: "60vh" }}
            >
              <div>
                <img src={noDataIco} style={{ width: "300px", opacity: 0.3 }} />
                <p
                  className="w-100 text-center mb-1"
                  style={{ fontSize: 25, fontWeight: 500 }}
                >
                  No Collect Data
                </p>
                {!isDisbursed ||
                  (isDisbursed === null && (
                    <p className="w-100 text-center" style={{ color: "gray" }}>
                      Note : The application is not yet disbursed
                    </p>
                  ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <table className="loan-table">
              <thead>
                <tr className="loan-tr">
                  <th>SL No.</th>
                  <th>Due Date</th>
                  <th>Installment Amount</th>
                  <th>Amount Collected</th>
                  <th>Actual Amount Collected</th>
                  <th>Settlement Date</th>
                  <th>Outstanding / Advance</th>
                  <th>Day-wise Status</th>
                  <th>Current Status</th>
                  <th>Paid via</th>
                  <th>Transaction Id</th>
                  <th></th>
                </tr>
              </thead>
              {!isLoader ? (
                <tbody>
                  {collectionScheduleType === collectionDefaultScheduleType
                    ? // collectionScheduleToogled != true ?
                      collectionData?.map((val, index) => {
                        return (
                          <>
                            <tr className="loan-tr loan-body-tr" key={val?._id}>
                              <td data-label="Installment No">
                                {val?.edi_number || val?.installment_number}
                                {/* {index + 1} */}
                              </td>
                              <td data-label="Date">
                                {moment(val?.due_date).format("DD/MM/YYYY")}
                              </td>
                              <td data-label="Installment Amount">
                                {val?.edi_amount || val?.installment_amount}
                              </td>
                              <td
                                style={{
                                  cursor:
                                    val?.settlement_data?.length > 0
                                      ? "pointer"
                                      : "default",
                                }}
                                data-label="Collected Amount"
                                onClick={(event) => {
                                  let arr = expandData;
                                  arr[index].isPayment = false;
                                  arr[index].array = val?.settlement_data;
                                  setExpandData([...arr]);
                                  handleEpandRow(event, index);
                                }}
                              >
                                <div className="d-flex">
                                  {val?.collected_amount}{" "}
                                  {val?.settlement_data?.length > 0 && (
                                    <ArrowDropDownIcon
                                      sx={{ color: "gray", fontSize: 12 }}
                                    />
                                  )}
                                </div>
                              </td>
                              <td
                                data-label="Account Collected Amount"
                                style={{
                                  cursor:
                                    val?.payment_details?.length > 0
                                      ? "pointer"
                                      : "default",
                                }}
                                onClick={(event) => {
                                  let arr = expandData;
                                  arr[index].isPayment = true;
                                  arr[index].array = val?.payment_details;
                                  setExpandData([...arr]);
                                  handleEpandRow(event, index);
                                }}
                              >
                                <div className="d-flex">
                                  {val?.actual_paid_amount}
                                  {val?.payment_details?.length > 0 && (
                                    <ArrowDropDownIcon
                                      sx={{ color: "gray", fontSize: 12 }}
                                    />
                                  )}
                                </div>
                              </td>

                              <td data-label="Settlement Date">
                                {/* {val?.collection_dateMS === 0
                            ? "-"
                            : moment(
                              new Date(val?.collection_dateMS)
                            ).format("DD/MM/YYYY")} */}
                                {val?.settlement_data?.length == 0 ||
                                val?.settlement_data == null
                                  ? "-"
                                  : moment(
                                      new Date(
                                        val?.settlement_data[
                                          val?.settlement_data?.length - 1
                                        ]?.payment_date
                                      )
                                    ).format("DD/MM/YYYY")}
                              </td>

                              <td data-label="Outstanding / Advance">
                                {val?.advance_amount}
                              </td>
                              <td data-label="Day-Status">
                                {statgeMap.COL_STATUS?.[
                                  val?.collection_status
                                ] !== "NOT_UPDATED"
                                  ? statgeMap.COL_STATUS?.[
                                      val?.collection_status
                                    ]
                                  : "-"}
                              </td>
                              <td data-label="Current-Status">
                                {statgeMap.COL_STATUS?.[val?.current_status] !==
                                "NOT_UPDATED"
                                  ? statgeMap.COL_STATUS?.[val?.current_status]
                                  : "-"}
                              </td>
                              <td data-label="pay-mode">
                                {
                                  val?.payment_details?.slice(-1)[0]
                                    ?.payment_mode
                                }
                              </td>
                              <td data-label="tnx-id">
                                {val?.payment_details?.slice(-1)[0]
                                  ?.txn_number ||
                                  val?.payment_details?.slice(-1)[0]
                                    ?.transaction_id}
                              </td>
                              <td data-label="tnx-id">
                                {val?.payment_details?.length > 0 && (
                                  <div>
                                    <EditIcon sx={{ fontSize: 12 }} />
                                  </div>
                                )}
                              </td>
                            </tr>
                            {(val?.payment_details?.length > 0 ||
                              val?.settlement_data?.length > 0) && (
                              <>
                                {expandedRows.includes(index) &&
                                  expandData?.[index]?.array?.map(
                                    (obj, pay_index) => {
                                      return (
                                        <tr
                                          className="expanded-tr"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            if (
                                              expandData?.[index]?.isPayment
                                            ) {
                                              setCollectionUpdate({
                                                open: true,
                                                is_colletion: false,
                                                payment_amount:
                                                  obj?.txn_amount ||
                                                  obj?.transaction_amount,
                                                transaction_id:
                                                  obj?.txn_number ||
                                                  obj?.transaction_id,
                                                transaction_utr:
                                                  obj?.txn_utr ||
                                                  obj?.transaction_utr,
                                                transaction_date: moment(
                                                  new Date(
                                                    Number(
                                                      obj?.trxn_dateMS ||
                                                        obj?.transaction_dateMS
                                                    )
                                                  ).toString()
                                                ).format("DD/MM/YYYY"),
                                                payment_mode: obj?.payment_mode,
                                                array: [
                                                  ...val?.payment_details,
                                                ],
                                                index: pay_index,
                                                id: val?._id,
                                                defaultDate: moment(
                                                  new Date(
                                                    Number(
                                                      obj?.trxn_dateMS ||
                                                        obj?.transaction_dateMS
                                                    )
                                                  ).toString()
                                                ).format("DD/MM/YYYY"),
                                                isEditabled: obj?.editAllowed,
                                                transactionId: obj?._id,
                                                amount_required_to_settle: obj?.other_details?.amount_required_to_settle
                                              });
                                            }
                                          }}
                                        >
                                          <td colSpan={3}></td>
                                          <td colSpan={1}>
                                            {!expandData?.[index]?.isPayment &&
                                              `₹ ${obj?.payment_amount}`}
                                          </td>
                                          <td>
                                            {expandData?.[index]?.isPayment &&
                                              `₹ ${
                                                obj?.txn_amount ||
                                                obj?.transaction_amount
                                              }`}
                                          </td>
                                          <td colSpan={4}>
                                            {moment(
                                              new Date(
                                                Number(
                                                  expandData?.[index]?.isPayment
                                                    ? obj?.trxn_dateMS ||
                                                        obj?.transaction_dateMS
                                                    : obj?.payment_date
                                                )
                                              ).toString()
                                            ).format("DD/MM/YYYY")}
                                          </td>
                                          <td>{obj?.payment_mode}</td>
                                          <td>
                                            {obj?.txn_number ||
                                            obj?.transaction_id
                                              ? obj?.txn_number ||
                                                obj?.transaction_id
                                              : "----"}
                                          </td>
                                          <td
                                            data-label="tnx-id"
                                            className="collection-arrow"
                                          >
                                            {expandData?.[index]?.isPayment && (
                                              <ArrowForwardIosIcon
                                                sx={{
                                                  color: "gray",
                                                  fontSize: 13,
                                                }}
                                              />
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </>
                            )}
                          </>
                        );
                      })
                    : collectionData?.map((val, index) => {
                        return (
                          <>
                            <tr
                              className="loan-tr loan-body-tr p-2"
                              key={val?._id}
                              // onClick={(event) => {
                              //   handleEpandRow(event, val?._id);
                              // }}
                            >
                              <td data-label="Installment No">
                                {/* {val?.edi_number} */}
                                {index + 1}
                              </td>
                              <td data-label="Date">
                                {moment(val?.due_date).format("DD/MM/YYYY")}
                              </td>
                              <td data-label="Installment Amount">
                                {val?.edi_amount || val?.installment_amount}
                              </td>
                              <td
                                data-label="Collected Amount"
                                style={{
                                  cursor:
                                    val?.settlement_data?.length > 0
                                      ? "pointer"
                                      : "default",
                                }}
                                onClick={(event) => {
                                  let arr = expandData;
                                  arr[index].isPayment = false;
                                  arr[index].array = val?.settlement_data;
                                  setExpandData([...arr]);
                                  handleEpandRow(event, index);
                                }}
                              >
                                <div className="d-flex">
                                  {val?.collected_amount}
                                  {val?.settlement_data?.length > 0 && (
                                    <ArrowDropDownIcon
                                      sx={{ color: "gray", fontSize: 12 }}
                                    />
                                  )}
                                </div>
                              </td>
                              <td
                                data-label="Account Collected Amount"
                                style={{
                                  cursor:
                                    val?.payment_details?.length > 0
                                      ? "pointer"
                                      : "default",
                                }}
                                onClick={(event) => {
                                  let arr = expandData;
                                  arr[index].isPayment = true;
                                  arr[index].array = val?.payment_details;
                                  setExpandData([...arr]);
                                  handleEpandRow(event, index);
                                }}
                              >
                                <div className="d-flex">
                                  {val?.actual_paid_amount}
                                  {val?.payment_details?.length > 0 && (
                                    <ArrowDropDownIcon
                                      sx={{ color: "gray", fontSize: 12 }}
                                    />
                                  )}
                                </div>
                              </td>

                              <td data-label="Settlement Date">
                                {val?.settlement_data?.length == 0 ||
                                val?.settlement_data == null
                                  ? "-"
                                  : moment(
                                      new Date(
                                        val?.settlement_data[
                                          val?.settlement_data?.length - 1
                                        ]?.payment_date
                                      )
                                    ).format("DD/MM/YYYY")}
                                {/* {val?.collection_dateMS === 0
                            ? "-"
                            : moment(
                              new Date(val?.collection_dateMS)
                            ).format("DD/MM/YYYY")} */}
                              </td>

                              <td data-label="Outstanding / Advance">
                                {val?.advance_amount}
                              </td>
                              <td data-label="Day-Status">
                                {statgeMap.COL_STATUS?.[
                                  val?.collection_status
                                ] !== "NOT_UPDATED"
                                  ? statgeMap.COL_STATUS?.[
                                      val?.collection_status
                                    ]
                                  : "-"}
                              </td>
                              <td data-label="Current-Status">
                                {/* {
                              statgeMap.COL_STATUS?.[
                                val?.current_status
                              ]
                            } */}
                                {statgeMap.COL_STATUS?.[val?.current_status] !==
                                "NOT_UPDATED"
                                  ? statgeMap.COL_STATUS?.[val?.current_status]
                                  : "-"}
                              </td>
                              <td data-label="pay-mode">
                                {
                                  val?.payment_details?.slice(-1)[0]
                                    ?.payment_mode
                                }
                              </td>
                              <td data-label="tnx-id">
                                {val?.payment_details?.slice(-1)[0]
                                  ?.txn_number ||
                                  val?.payment_details?.slice(-1)[0]
                                    ?.transaction_id}
                              </td>
                            </tr>
                            {(val?.payment_details?.length > 0 ||
                              val?.settlement_data?.length > 0) &&
                              expandedRows?.includes(index) && (
                                <>
                                  {expandedRows?.includes(index) &&
                                    expandData?.[index]?.array?.map((obj) => {
                                      return (
                                        <tr>
                                          <td colSpan={3}></td>
                                          <td colSpan={1}>
                                            {!expandData?.[index]?.isPayment &&
                                              `₹ ${obj?.payment_amount}`}
                                          </td>
                                          <td>
                                            {expandData?.[index]?.isPayment &&
                                              `₹ ${
                                                obj?.txn_amount ||
                                                obj?.transaction_amount
                                              }`}
                                          </td>
                                          <td colSpan={4}>
                                            {moment(
                                              new Date(
                                                Number(
                                                  expandData?.[index]?.isPayment
                                                    ? obj?.trxn_dateMS ||
                                                        obj?.transaction_dateMS
                                                    : obj?.payment_date
                                                )
                                              ).toString()
                                            ).format("DD/MM/YYYY")}
                                          </td>
                                          <td>{obj?.payment_mode}</td>
                                          <td>
                                            {obj?.txn_number ||
                                            obj?.transaction_id
                                              ? obj?.txn_number ||
                                                obj?.transaction_id
                                              : "----"}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </>
                              )}
                          </>
                        );
                      })}
                </tbody>
              ) : (
                <tbody>
                  {[...Array(10)].map((_) => {
                    return (
                      <tr className="loan-tr loan-body-tr">
                        <td data-label="Installment No">
                          <Skeleton />
                        </td>
                        <td data-label="Date">
                          <Skeleton />
                        </td>
                        <td data-label="Installment Amount">
                          <Skeleton />
                        </td>
                        <td data-label="Collected Amount">
                          <Skeleton />
                        </td>
                        <td data-label="Account Collected Amount">
                          <Skeleton />
                        </td>

                        <td data-label="Settlement Date">
                          <Skeleton />
                        </td>

                        <td data-label="Outstanding / Advance">
                          <Skeleton />
                        </td>
                        <td data-label="Day-Status">
                          <Skeleton />
                        </td>
                        <td data-label="Current-Status">
                          <Skeleton />
                        </td>
                        <td data-label="pay-mode">
                          <Skeleton />
                        </td>
                        <td data-label="tnx-id">
                          <Skeleton />
                        </td>
                        <td data-label="tnx-id"></td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </>
        )}
      </div>

      <Fab
        variant="extended"
        className="scroll-top-btn2"
        onClick={() => {
          if (scroll === "Top") {
            scrollRef.current.scrollTop = 0;
          } else if (scroll === "Bottom") {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
          }
        }}
      >
        {scroll === "Top" && <ArrowDropUpIcon />}
        {scroll === "Bottom" && <ArrowDropDownIcon />}
        {/* {scroll} */}
      </Fab>
    </div>
  );
};

export default AdvancedCollectionView;
