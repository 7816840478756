import { Add, Close, Edit } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Zoom,
  styled,
  tableCellClasses,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./GPStable.css";
import React, { useEffect, useState } from "react";
import GPSPopup from "./GPSPopup";
import { toast } from "react-toastify";
import { fetchAnchorGPSInventory } from "../../api";
import CountUp from "react-countup";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E6EDFF",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function GPStable({ open, handleClose, partnerData }) {
  const [openGPSPopup, setOpenGPSPopup] = useState({
    open: false,
    data: {},
  });
  const [loading, setLoading] = useState(false);
  const [titlesLoading, setTitlesLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([])
  const [inventoryDetails, setInventoryDetails] = useState({});

  async function getGPSData(type, value) {
    try {
      setLoading(true);
      setTitlesLoading(true);
      let params = `anchor_id=${partnerData?.partner_id}`;
      if (type === "search") {
        params += `&search=${value}`;
      }
      const { data } = await fetchAnchorGPSInventory(params);
      if (!Boolean(type))
        setInventoryDetails({
          gps_installed: data?.gps_installed || 0,
          gps_remaining: data?.gps_remaining || 0,
          installation_target: data?.installation_target || 0,
        });
      setTableData(data?.data || []);
      setAllTableData(data?.data || []);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
      setTitlesLoading(false);
    }
  }

  async function handleSearch(value) {
    try {
      setLoading(true);
      let filteredData = allTableData?.filter(item=>item?.gps_unique_number?.toLowerCase()?.includes(value?.toLowerCase()));
      setTableData(filteredData);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open) {
      getGPSData();
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
        <DialogTitle sx={{ backgroundColor: "#EFF5FF" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>{partnerData?.firm_name}</span>
            <span>
              <Button
                variant={"contained"}
                onClick={() => {
                  setOpenGPSPopup({
                    open: true,
                    data: { anchor_id: partnerData?.partner_id },
                  });
                }}
              >
                <Add /> Add GPS Details
              </Button>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </span>
          </div>
          <div
            className="my-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <TextField
              className="search-bar-table me-2"
              id="input-with-icon-textfield"
              placeholder="Search GPS Number"
              variant="standard"
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: "gray",mr:1 }} />,
                disableUnderline: true,
                style: { color: "black", fontWeight: 400, height: 25 },
              }}
              // onKeyDown={(e) => {
              //   if (e.key === "Enter") {
              //     handleSearch(e.target.value);
              //   }
              // }}
              onChange={(e)=>{
                handleSearch(e.target.value);
              }}
            />
          </div>
          <div style={{ marginTop: 5 }}>
            <Grid item container spacing={2}>
              <OverViewCard
                isLoading={titlesLoading}
                heading={"Installation Target"}
                number={inventoryDetails?.installation_target}
              />
              <OverViewCard
                isLoading={titlesLoading}
                heading={"Received by Dealer"}
                number={
                  Number(inventoryDetails?.gps_installed) +
                  Number(inventoryDetails?.gps_remaining)
                }
              />
              <OverViewCard
                isLoading={titlesLoading}
                heading={"No. Of GPS Installed"}
                number={inventoryDetails?.gps_installed}
              />
              <OverViewCard
                isLoading={titlesLoading}
                heading={"Inventory Remaining"}
                number={inventoryDetails?.gps_remaining}
              />
            </Grid>
          </div>
        </DialogTitle>
        <DialogContent sx={{ width: "100%", px: 0 }}>
          <Zoom in={true}>
            <TableContainer
              // component={Paper}
              sx={{
                height: 400,
                "::-webkit-progress-bar": { display: "none" },
              }}
            >
              <Table
                stickyHeader
                sx={{ width: "100%" }}
                aria-label="customized table"
              >
                <TableHead>
                  <Grow
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...{ timeout: 1000 }}
                  >
                    <TableRow>
                    <StyledTableCell align="left">
                        SL.&nbsp;No.
                      </StyledTableCell>
                      <StyledTableCell align="left" width={150}>
                        Procurer&nbsp;Name
                      </StyledTableCell>
                      <StyledTableCell align="left" width={100}>
                        Lot
                      </StyledTableCell>
                      <StyledTableCell align="left" width={150}>
                        GPS&nbsp;Number
                      </StyledTableCell>
                      <StyledTableCell align="left" width={150}>
                        Sim&nbsp;Number
                      </StyledTableCell>
                      <StyledTableCell align="left" width={200}>
                        Money&nbsp;Received
                      </StyledTableCell>
                      <StyledTableCell align="left" width={150}>
                        Status
                      </StyledTableCell>
                      <StyledTableCell align="left" width={50}>
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </Grow>
                </TableHead>
                <TableBody>
                  {loading
                    ? new Array(6).fill(null).map((item, index) => {
                        return (
                          <Grow
                            in={true}
                            style={{ transformOrigin: "0 0 0" }}
                            {...{ timeout: 1000 }}
                          >
                            <StyledTableRow>
                              <StyledTableCell align="left">
                                <Skeleton />
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Skeleton />
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Skeleton />
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Skeleton />
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Skeleton />
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Skeleton />
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Skeleton />
                              </StyledTableCell>
                            </StyledTableRow>
                          </Grow>
                        );
                      })
                    : tableData?.map((item,index) => (
                        <Grow
                          in={true}
                          style={{ transformOrigin: "0 0 0" }}
                          {...{ timeout: 1000 }}
                        >
                          <StyledTableRow>
                          <StyledTableCell align="left">
                              {index+1}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item?.gps_procurer}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item?.gps_lot}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item?.gps_unique_number}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item?.gps_sim_number}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item?.money_received}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item?.gps_activated
                                ? "Installed" 
                                : "Not Installed"}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Button
                                size="small"
                                color="primary"
                                onClick={() => {
                                  setOpenGPSPopup({
                                    open: true,
                                    isEdit: true,
                                    data: item,
                                  });
                                }}
                              >
                                edit <Edit sx={{ fontSize: 15, mx: 1 }} />
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        </Grow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Zoom>
        </DialogContent>
      </Dialog>
      <GPSPopup
        open={openGPSPopup.open}
        isEdit={openGPSPopup?.isEdit}
        gpsData={openGPSPopup?.data}
        getGPSData={getGPSData}
        firmName={partnerData?.firm_name}
        handleClose={() => {
          setOpenGPSPopup({ open: false, data: {} });
        }}
      />
    </>
  );
}

const OverViewCard = ({ heading, number, isLoading }) => {
  return (
    <Grid item xs={12} sm={2.5}>
      <TextField
        disabled
        id="filled-basic"
        label={heading}
        variant="filled"
        // value={isLoading ? "Loading..." :[null, undefined].includes(number) ? "NA" : number}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                backgroundColor: "#fff",
                height: 56,
                width: 50,
                marginLeft: -12,
                padding: "auto",
                border: "0px solid green",
              }}
            >
              <InputAdornment
                sx={{
                  // backgroundColor: (theme) => theme.palette.divider,
                  marginLeft: 1.5,
                  color:"#535353"
                }}
                position="start"
              >
                <>
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : [null, undefined].includes(number) ? (
                    "NA"
                  ) : (
                    <>
                      {" "}
                      <CountUp
                        style={{
                          flex: 1,
                          // backgroundColor: "pink",
                          padding: 0,
                        }}
                        end={number}
                      />
                    </>
                  )}
                </>
              </InputAdornment>
            </div>
          ),
        }}
        sx={{
          border: "1px solid rgba(68, 68, 68, 0.31)",
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
            backgroundColor: "#fff",
          },
        }}
      />
    </Grid>
  );
};

export default GPStable;
