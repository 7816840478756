import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Modal,
  IconButton,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useState } from "react";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { useContext } from "react";
import { Table } from "react-bootstrap";
import { UserContext } from "../../Context/UserContext";
import { useEffect } from "react";
import noDataIco from "../../assets/nodata.gif";
import { EditOutlined, Sell } from "@mui/icons-material";
import UpdateIcon from "@mui/icons-material/Update";
import AddIcon from "@mui/icons-material/Add";
import { useRef } from "react";
import { GlobalContext } from "../../Context/GlobalContext";
// import { DatePicker } from "@material-ui/pickers";
import "./style.css";
import { isBlank } from "../../utility/validations";
import { addContactInfo, fetchTelePDdata, updateContactInfo } from "../../api";
import useApplication from "../../hooks/useApplication";
import CustomerCocustomerEdit from "./CustomerCocustomerEdit";
import CustomerContactHistory from "./ContactsHistory";
import AddContact from "./AddContact";
import { WhatsappIco } from "../../Constants/icons";

const initHistory = {
  open: false,
  contact: null,
  data: [],
};

const ContactInfoDialog = ({ handleClose, open, user }) => {
  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const [contactsData, setContactsData] = useState([]);
  const [openHistory, setOpenHistory] = useState(initHistory);
  const [telepdSame, setTeleSame] = useState({});

  const [editData, setEditData] = useState({
    open: false,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    height: "632px",
    bgcolor: "background.paper",
    borderRadius: "0.5rem",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const Relations = [
    "Customer",
    "Co-customer",
    "Guarantor",
    "Neighbour",
    "Relative",
    "Friend",
    "Other",
  ];

  const handleGetContacts = async () => {
    let contacts = [];
    let videokyc = {};
    try {
      const { data } = await fetchTelePDdata(updateDetails?.application_id);
      setUpdateDetails({
        ...updateDetails,
        video_kyc_data: data?.data || {},
      });
      videokyc = data?.data || {};
    } catch (error) {
      videokyc = updateDetails?.video_kyc_data || {};
    }

    let coCustomerObject = null;
    if (videokyc?.co_customer_trigger) {
      coCustomerObject = videokyc?.co_customer_trigger.reduce((acc, item) => {
        const { mobile, ...rest } = item;
        if (!acc[mobile]) {
          acc[mobile] = {};
        }
        acc[mobile] = rest;
        return acc;
      }, {});
    }

    let customerObject = null;
    if (videokyc?.customer_trigger) {
      customerObject = videokyc?.customer_trigger.reduce((acc, item) => {
        const { mobile, ...rest } = item;
        if (!acc[mobile]) {
          acc[mobile] = {};
        }
        acc[mobile] = rest;
        return acc;
      }, {});
    }

    contacts.push({
      name: updateDetails?.customer_name,
      number: updateDetails?.mobile_number,
      relation: "Customer",
      collection: "customer",
      name_disabled: true,
      is_delivered:
        customerObject?.[updateDetails?.mobile_number]?.is_delivered,
      ...(updateDetails?.customerTelePdDone
        ? {
            is_telepd:
              (!isNaN(videokyc?.other_details?.telepd_number) &&
                Number(videokyc?.other_details?.telepd_number) ===
                  updateDetails?.mobile_number) ||
              !videokyc?.other_details?.telepd_number,
          }
        : {}),
    });

    if (updateDetails?.coApplicantExist) {
      contacts.push({
        name: updateDetails?.co_app_customer_name,
        number: updateDetails?.co_app_mobile_number,
        relation:
          updateDetails?.co_app_type === "GUARANTOR"
            ? "Guarantor"
            : "Co-customer",
        collection: "coCustomer",
        co_relation: updateDetails?.co_app_relation_borrower || "",
        name_disabled: true,
        is_delivered:
          coCustomerObject?.[updateDetails?.co_app_mobile_number]?.is_delivered,

        ...(updateDetails?.coCustomerTelePdDone
          ? {
              is_telepd:
                (!isNaN(videokyc?.co_customer_data?.telepd_number) &&
                  Number(videokyc?.co_customer_data?.telepd_number) ===
                    updateDetails?.co_app_mobile_number) ||
                !videokyc?.co_customer_data?.telepd_number,
            }
          : {}),
      });
    }

    if (Array.isArray(updateDetails?.other_details?.references)) {
      contacts.push(
        ...(updateDetails?.other_details?.references || [])?.map((refer) => {
          return {
            name: refer?.name,
            number: refer?.mobile_number,
            relation: refer?.relation_with_borrower,
            collection: "reference",
          };
        })
      );
    }

    if (Array.isArray(updateDetails?.other_contact_numbers)) {
      contacts.push(
        ...(updateDetails?.other_contact_numbers || [])?.map((other_co) => {
          return {
            name: other_co?.name,
            number: other_co?.mobile_number,
            relation: other_co?.relation,
            collection: "additional",
          };
        })
      );
    }

    // Telepd number set

    if (
      !isNaN(videokyc?.other_details?.telepd_number) &&
      Number(videokyc?.other_details?.telepd_number) !==
        updateDetails?.mobile_number
    ) {
      contacts.push({
        name: "Customer telepd number",
        number: videokyc?.other_details?.telepd_number,
        edit_disabled: true,
        is_delivered:
          customerObject?.[videokyc?.other_details?.telepd_number]
            ?.is_delivered,

        is_telepd:
          !isNaN(videokyc?.other_details?.telepd_number) &&
          Number(videokyc?.other_details?.telepd_number) !==
            updateDetails?.mobile_number,
      });
    }

    if (
      !isNaN(videokyc?.other_details?.whatsapp_number) &&
      Number(videokyc?.other_details?.whatsapp_number) !==
        updateDetails?.mobile_number
    ) {
      contacts.push({
        name: "Customer whatsapp number",
        number: videokyc?.other_details?.whatsapp_number,
        edit_disabled: true,
        is_delivered:
          customerObject?.[videokyc?.other_details?.whatsapp_number]
            ?.is_delivered,
      });
    }

    if (
      !isNaN(videokyc?.co_customer_data?.telepd_number) &&
      Number(videokyc?.co_customer_data?.telepd_number) !==
        updateDetails?.co_app_mobile_number
    ) {
      contacts.push({
        name: "co customer telepd number",
        number: videokyc?.co_customer_data?.telepd_number,
        edit_disabled: true,
        is_delivered:
          coCustomerObject?.[videokyc?.co_customer_data?.telepd_number]
            ?.is_delivered,

        is_telepd:
          !isNaN(videokyc?.co_customer_data?.telepd_number) &&
          Number(videokyc?.co_customer_data?.telepd_number) !==
            updateDetails?.co_app_mobile_number,
      });
    }

    if (
      !isNaN(videokyc?.co_customer_data?.alternative_number) &&
      Number(videokyc?.co_customer_data?.alternative_number) !==
        updateDetails?.co_app_mobile_number
    ) {
      contacts.push({
        name: "Co customer alternative number",
        number: videokyc?.co_customer_data?.alternative_number,
        edit_disabled: true,
        is_delivered:
          coCustomerObject?.[videokyc?.co_customer_data?.alternative_number]
            ?.is_delivered,
      });
    }
    setContactsData(contacts);
  };

  const handleCloseEdit = () => {
    setEditData({
      open: false,
    });
  };

  function isMarkedCallFirst(customerData) {
    return updateDetails?.call_first == customerData?.number;
  }

  function isEditDisabled(contact) {
    if (Number(user?.role) === 2) {
      return true;
    }

    if (
      ["Customer", "Co-customer", "Guarantor"].includes(contact?.relation) &&
      (updateDetails?.send_to_nbfc || updateDetails?.is__sent_to_nbfc)
    )
      return true;

    return false;
  }

  function getContactsHistory(contact) {
    // co_app_contact_history
    // customer_contact_history
    if (
      ["Guarantor", "Co-customer"].includes(contact?.relation) &&
      updateDetails?.co_app_contact_history?.length
    ) {
      return (
        <IconButton
          color="primary"
          size="small"
          onClick={() => {
            setOpenHistory({
              open: true,
              contact,
              data: updateDetails?.co_app_contact_history,
            });
          }}
        >
          <UpdateIcon fontSize="small" />
        </IconButton>
      );
    }

    if (
      contact?.relation === "Customer" &&
      updateDetails?.customer_contact_history?.length
    ) {
      return (
        <IconButton
          color="primary"
          size="small"
          onClick={() => {
            setOpenHistory({
              open: true,
              contact,
              data: updateDetails?.customer_contact_history,
            });
          }}
        >
          <UpdateIcon fontSize="small" />
        </IconButton>
      );
    }
    return "";
  }

  useEffect(() => {
    if (open) {
      handleGetContacts();
    }
  }, [open]);

  useEffect(() => {
    console.log(telepdSame);
  }, [telepdSame]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <Grid
          container
          style={{ background: "#EFF5FF" }}
          sx={{ padding: 1, paddingBottom: 5 }}
        >
          <Grid xs={6} className="p-3">
            <p className="contact-heading">Contact Information</p>
          </Grid>
          <Grid xs={6}>
            <div className="d-flex justify-content-end p-3 pe-5">
              {Number(user?.role) !== 3 && (
                <Button
                  variant="contained"
                  sx={{ mr: 1, fontSize: 12, borderRadius: 10 }}
                  onClick={() => {
                    setEditData({
                      open: true,
                    });
                  }}
                >
                  <AddIcon sx={{ fontSize: 18, mr: 0.5 }} /> Add more contact
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            overflow: "auto",
            height: "100%",
            padding: 10,
            paddingTop: 0,
            marginTop: -35,
          }}
        >
          <div
            style={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {false ? (
            <CircularProgress
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                translate: "-50%",
                // color: (theme) => theme.palette.grey[500],
              }}
            />
          ) : (
            <>
              {false ? (
                <div className="content-center w-100">
                  <img src={noDataIco} width={300} />
                </div>
              ) : (
                <Table
                  className="contacts-table"
                  style={{ fontSize: 13, marginBottom: 0 }}
                >
                  <thead>
                    <tr style={{ background: "#EFF5FF" }}>
                      <th style={{ width: "10%" }}></th>
                      <th style={{ width: "30%" }}>Name</th>
                      <th style={{ width: "30%", textAlign: "left" }}>
                        NUMBER
                      </th>
                      <th style={{ width: "30%", textAlign: "center" }}>
                        RELATION
                      </th>
                      <th style={{ width: "10%" }}></th>
                    </tr>
                  </thead>
                  {(contactsData || []).map((contact, index) => {
                    const callFirst = isMarkedCallFirst(contact);
                    return (
                      <tbody>
                        <tr>
                          <td>
                            <div style={{ paddingLeft: 15 }}>
                              <b>{index + 1}</b>
                            </div>
                          </td>
                          <td>
                            <b>{contact?.name}</b>
                          </td>
                          <td style={{ textAlign: "left" }}>
                            {contact?.number}
                            {getContactsHistory(contact)}
                            {contact?.is_delivered && (
                              <WhatsappIco size={18} className={"ms-1"} />
                            )}
                            {contact?.is_delivered === false && (
                              <WhatsappIco
                                size={18}
                                className={"ms-1"}
                                color={"red"}
                              />
                            )}
                            {contact?.is_delivered === null && (
                              <WhatsappIco
                                size={18}
                                className={"ms-1"}
                                color={"gray"}
                              />
                            )}
                            {contact?.is_telepd && (
                              <span
                                style={{
                                  border: "2px solid #4D19D2",
                                  borderRadius: 30,
                                  color: "#4D19D2",
                                  height: "40px",
                                  width: "40px",
                                  fontSize: 10,
                                  padding: "0 2px",
                                  marginLeft: "10px",
                                }}
                              >
                                TP
                              </span>
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              display: "grid",
                              gridTemplateColumns: "20% 60%",
                            }}
                          >
                            <span
                              style={{
                                marginRight: "10px",
                              }}
                            >
                              {callFirst && (
                                <Sell
                                  color="secondary"
                                  style={{ transform: "scaleX(-1)" }}
                                />
                              )}
                            </span>
                            {contact?.relation && (
                              <span
                                className="relation-span"
                                style={{ textAlign: "center", width: "100%" }}
                              >
                                {contact?.relation}
                                {contact?.co_relation
                                  ? ` (${contact?.co_relation})`
                                  : ""}
                              </span>
                            )}
                          </td>
                          <td>
                            {!contact?.edit_disabled &&
                              Number(user?.role) !== 3 && (
                                <Button
                                  sx={{ fontSize: 12 }}
                                  onClick={() => {
                                    setEditData({
                                      open: true,
                                      isEdit: true,
                                      name: contact?.name || "",
                                      number: contact?.number || "",
                                      old_number: contact?.number || "",
                                      relation: contact?.relation || "",
                                      collection: contact?.collection,
                                      isNameDisabled: contact?.name_disabled,
                                      call_first: callFirst || false,
                                    });
                                  }}
                                  disabled={isEditDisabled(contact)}
                                >
                                  <EditOutlined
                                    sx={{ fontSize: 12, mr: 0.5 }}
                                  />
                                  Edit
                                </Button>
                              )}
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </Table>
              )}
            </>
          )}
        </div>
      </Dialog>

      <CustomerContactHistory
        open={openHistory?.open}
        handleClose={() => {
          setOpenHistory(initHistory);
        }}
        data={openHistory}
      />

      {editData.open &&
        (["Customer", "Co-customer", "Guarantor"].includes(editData.relation) &&
        editData?.name ? (
          <CustomerCocustomerEdit
            open={editData?.open}
            handleClose={handleCloseEdit}
            Relations={Relations}
            editData={editData}
            application_id={updateDetails?.application_id}
          />
        ) : (
          <AddContact
            editData={editData}
            handleClose={handleCloseEdit}
            Relations={Relations}
            application_id={updateDetails?.application_id}
          />
        ))}
    </>
  );
};

export default ContactInfoDialog;
