import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import whatsappBg from "../../assets/whatsappBg.jpg";
import moment from "moment/moment";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import BlockIcon from "@mui/icons-material/Block";
import { toast } from "react-toastify";
import { getAllWhatsappMessages } from "../../api";
import { useEffect } from "react";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import ReplayIcon from "@mui/icons-material/Replay";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ChatHistory({
  mobile_number,
  customer_name,
  handleClose,
  open,
}) {
  const dialogRef = React.useRef(null); 
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const isDateChanged = (prevDate, currentDate) => {
    return !moment(prevDate).isSame(currentDate, "day");
  };

  // Initialize previous date to compare with the current message's date
  let prevDate = null;

  const handleGetMessages = async (mobile_number) => {
    try {
      setLoading(true);
      let { data } = await getAllWhatsappMessages(mobile_number);
      setMessages(data?.data || []);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Error loading messages"
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      handleGetMessages(mobile_number);
    }else{
      setMessages([])
    }
  }, [open]);

  useEffect(()=>{
    if (dialogRef.current) {
      dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  },[messages])

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth={"md"}
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {customer_name} ({mobile_number})
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          style={{
            backgroundImage: `url(${whatsappBg})`, // Set background image using template literals
            backgroundSize: "cover",
          }}
        >
          <div className="chat-holder">
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <CircularProgress />{" "}
              </div>
            )}
            {!loading && messages?.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                <div className="chat-empty-div">
                  <SpeakerNotesOffIcon className="me-2" /> No messages found on
                  this number
                </div>
              </div>
            )}
            {!loading &&
              messages?.length !== 0 &&
              messages?.map((msg, index) => {
                const dateChanged =
                  prevDate && isDateChanged(prevDate, msg.message_date);

                // Update previous date with the current message's date
                prevDate = msg.message_date;

                return (
                  <>
                    {dateChanged && (
                      <div
                        className="chat-date-divider"
                        key={`divider-${index}`}
                      >
                        <span>
                          {moment(msg.message_date).format("DD/MM/YYYY")}
                        </span>
                      </div>
                    )}
                    {(msg?.type === "text" || msg?.type === "button") && (
                      <div
                        className={`chat-bubble-holder chat-${msg?.doc_type}`}
                      >
                        <div
                          className={`chat-bubble chat-bubble-${msg?.doc_type}`}
                        >
                          {msg?.context && (
                            <div className="context-holder">
                              <div className="context-header">Embifi</div>
                              {msg?.contextData}
                            </div>
                          )}
                          <div className="p-3">{msg?.message}</div>
                          <div className="chat-meta mb-1">
                            <div>
                              {moment(msg?.message_date).format(
                                "DD/MM/YYYY h:mm a"
                              )}
                              {msg?.doc_type === "sent" && (
                                <span className="ms-1">
                                  {msg?.status === "READ" ? (
                                    <DoneAllIcon
                                      sx={{ fontSize: 15, color: "blue" }}
                                    />
                                  ) : msg?.status === "DELIVERED" ? (
                                    <DoneAllIcon
                                      sx={{ fontSize: 15, color: "gray" }}
                                    />
                                  ) : msg?.status === "UNDELIVERED" ? (
                                    <BlockIcon
                                      sx={{ fontSize: 15, color: "red" }}
                                    />
                                  ) : (
                                    <DoneIcon
                                      sx={{ fontSize: 15, color: "gray" }}
                                    />
                                  )}
                                </span>
                              )}
                            </div>
                          </div>
                          {msg?.buttons && (
                            <>
                              <div className="chat-btn-holder">
                                {msg?.buttons?.map((btn) => {
                                  return (
                                    <div className="chat-btn">{btn?.title}</div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {!["text", "button"]?.includes(msg?.type) && (
                      <div
                        className={`chat-bubble-holder chat-${msg?.doc_type}`}
                      >
                        <div
                          className={`chat-bubble chat-bubble-${msg?.doc_type}`}
                        >
                          <div
                            className="p-3"
                            style={{
                              color: "gray",
                              textDecoration: "italic",
                            }}
                          >
                            <BlockIcon /> Unsupported type : {msg?.type}
                          </div>
                          <div className="chat-meta mb-1">
                            <div>
                              {moment(msg?.message_date).format(
                                "DD/MM/YYYY h:mm a"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
              <div ref={dialogRef}></div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleGetMessages(mobile_number);
            }}
          >
            <ReplayIcon sx={{fontSize:'18px', marginRight:'5px'}}/> Reload
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
