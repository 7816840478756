import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  CssBaseline,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  TextField,
  FormControl,
  MenuItem,
  Typography,
  Select,
  Grid,
  InputLabel,
  Box,
  Container,
} from "@mui/material";
import { shareDealersData, fetchAllNbfc, fetchAllOems } from "../api";
import { toast } from "react-toastify";
import Paper from "@mui/material/Paper";
import AddIco from "../assets/add.svg";
import Avatar from "../../src/assets/Avatar.svg";

export default function CsvJson() {
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [dataSharedSuccessfully, setDataSharedSuccessfully] = useState(false);
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [oemId, setOemID] = useState({ oem_id: "" });
  const [isLoading, setLoading] = useState(false);
  const [finalData, setFinalData] = useState({});
  const [dealerData, setDealerData] = useState({
    dealership_name: "",
    oem_id: "",
    contact_person_name: "",
    mobile_number: "",
    city: "",
    state: "",
  });

  // --------------------------------------
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (
      e.dataTransfer.files &&
      e.dataTransfer.files[0] &&
      e.dataTransfer.files[0].type === "text/csv"
    ) {
      setFileName(e.dataTransfer.files[0]?.name);
      setFile(e.dataTransfer.files[0]);
    }
  };
  //-------------------------------------

  useEffect(() => {
    setFinalData({ data: [dealerData] });
  }, [dealerData]);

  // useEffect(() => {
  //   console.log(oemId)
  // }, [oemId])

  const [oemList, setOemList] = useState([]);
  const fileReader = new FileReader();

  const handleDataShare = async (e) => {
    e.preventDefault();
    setLoading(true);
    let payload = finalData;
    let response = await shareDealersData(payload);
    // console.log(response);
    if (!response.status) {
      toast.error(response?.response?.data?.message || "Something went wrong");
      return setLoading(false);
    }
    toast.success(response?.data?.message || "Updated Password Successfully");
    setDataSharedSuccessfully(true);
    return setLoading(false);
  };

  const fetchOem = async () => {
    const { data } = await fetchAllOems();
    if (data?.status) {
      setOemList(data?.data);
    }
  };

  const drop = (e) => {
    // e.preventDefault();
    var data = e.dataTransfer.getData("text");
    setFileName(e.target.value);
    setFile(data);
    // e.target.appendChild(document.getElementById(data));
  };

  useEffect(() => {
    fetchOem();
  }, []);

  const handleOnChange = (e) => {
    setFileName(e.target.value);
    setFile(e.target.files[0]);
    // console.log(e.target.files[0]);
  };

  const handleFormatDownload = async () => {
    let headers = [
      `Dealership name, Contact person name, Mobile number, City, Anchor Id, State`,
    ];

    downloadFile({
      data: [...headers].join("\n"),
      fileName: `Dealer Data Format`,
      fileType: "text/csv",
    });
  };
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const csvFileToArray = (string) => {
    // var array = string.toString().split("\n")[0].split(",");
    // console.log(array); // here we are getting the first rows which is our header rows to convert it into keys we are logging it here
    var data = [];
    // const data1 = string.toString().replaceAll(", ",",").split(/\r\n/);
    const data1 = string.toString().replaceAll(", ",",").split("\n");

    // data1.forEach(x=> {
    //   console.log(x)
    //   x.split("\r")
    // }
    // data1.forEach((x) => {
    //   x.replaceAll("\r","")
    // });

    for (const r of data1) {
      // console.log(r);
      let row = r.toString().split(",");
      data.push(row);
    }
    var heading = data[0];
    // console.log(heading); to get the column headers which will act as key
    var ans_array = [];
    for (var i = 1; i < data.length-1; i++) {
      var row = data[i];
      var obj = {};
      for (var j = 0; j < heading.length; j++) {
        if (!row[j]) {
          row[j] = null;
        }
        // console.log(row[j].toString())
        obj[heading[j].replaceAll(" ", "_")?.toLowerCase()] = row[j]
          ? row[j]?.toString()
          : //   ?.toreplaceAll(" ", "_")
            null;
      }

      ans_array.push(obj);
    }
    const dataObj = {
      data: ans_array,
    };
    dataObj?.data?.forEach((x) => {
      x.oem_id = oemId?.oem_id;
    });
    setFinalData(dataObj);
    // setDataSharedSuccessfully(true);
    // console.log({ data: ans_array });
  };

  const handleOnSubmit = () => {
    // e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        //  console.log(event);
        //  console.log(event.target.result);
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };
  useEffect(() => {
    handleOnSubmit();
  }, [file]);

  // useEffect(() => {
  //   console.log(finalData);
  // }, [finalData]);

  return (
    <>
      <p className="go-back-btn-fixed" onClick={() => navigate("/")}>
        <ArrowBackIcon /> Go back Home
      </p>
      <Container
        sx={{
          padding: "43px",
        }}
        component="main"
        maxWidth="sm"
      >
        <div
          style={{
            marginTop: "20px",
          }}
          className="page-head"
        >
          <img src={AddIco} width={"30px"} />
          <span style={{ marginLeft: "10px", color: "gray" }}>
            Onboard Shared Dealers
          </span>
        </div>
        <Paper
          variant="outlined"
          sx={{
            my: { xs: 3, md: 3 },
            p: { xs: 2, md: 3 },
            filter:
              "drop-shadow(6px 6px 30px rgba(125, 153, 180, 0.2)) drop-shadow(2px 2px 4px rgba(86, 144, 198, 0.11))",
          }}
        >
          <CssBaseline />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {dataSharedSuccessfully ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Data Shared Successfully.
                </Typography>
                <Button
                  className="mt-3 px-5"
                  variant="outlined"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  DONE
                </Button>
              </React.Fragment>
            ) : (
              <>
                <FormControl
                  sx={{
                    mb: 4,
                  }}
                >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={fileType}
                    onChange={(e) => {
                      setFileType(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="csv"
                      control={<Radio />}
                      label="CSV Upload"
                    />
                    <FormControlLabel
                      value="form"
                      control={<Radio />}
                      label="Onboard a dealer"
                    />
                  </RadioGroup>
                </FormControl>

                {fileType === "csv" ? (
                  <React.Fragment>
                    <Grid container>
                      <Grid
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "right",
                        }}
                      >
                        <Button
                          sx={{
                            mr: 2,
                          }}
                          onClick={handleFormatDownload}
                        >
                          Download CSV Format
                        </Button>
                      </Grid>

                      <FormControl
                        size="small"
                        sx={{
                          m: 3,
                          width: "100%",
                        }}
                        //  variant="standard"
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Select OEM
                        </InputLabel>
                        <Select
                          size="small"
                          label="Select OEM"
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={oemId?.oem_id}
                          onChange={(e) => {
                            setOemID({
                              oem_id: e.target.value,
                            });
                          }}
                        >
                          {oemList?.map((value, index) => {
                            return (
                              <MenuItem value={value?.oem_id} key={index}>
                                {value?.company_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    {oemId?.oem_id !== "" && (
                      <Box
                        sx={{
                          width: "90%",
                        }}
                      >
                        <form
                          sx={{
                            width: "100%",
                          }}
                          onDragEnter={handleDrag}
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <label for="images" className="drop-container">
                            <img
                              style={{
                                marginTop: "5px",
                                width: "50px",
                                cursor: "pointer",
                              }}
                              src={Avatar}
                              onClick={() =>
                                document.getElementById("getFile").click()
                              }
                            ></img>
                            <div
                              style={{
                                marginTop: "10px",
                              }}
                            >
                              <span className="drop-title">
                                <span
                                  style={{
                                    color: "#1976d2",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    height: "30px",
                                  }}
                                  onClick={() =>
                                    document.getElementById("getFile").click()
                                  }
                                >
                                  Click to upload{" "}
                                </span>
                                or Drag and Drop
                              </span>
                            </div>

                            <input
                              ref={inputRef}
                              id="getFile"
                              style={{ display: "none" }}
                              onChange={handleOnChange}
                              type="file"
                              accept=".csv"
                              required
                            />
                            <span
                              style={{
                                color: "rgb(183, 181, 181)",
                              }}
                            >
                              CSV File only
                            </span>
                          </label>
                          {dragActive && (
                            <div
                              id="drag-file-element"
                              onDragEnter={handleDrag}
                              onDragLeave={handleDrag}
                              onDragOver={handleDrag}
                              onDrop={handleDrop}
                            ></div>
                          )}
                        </form>
                        {fileName !== "" && (
                          <Paper
                            variant="outlined"
                            sx={{
                              my: { xs: 3, md: 3 },
                              p: { xs: 1, md: 1 },
                            }}
                          >
                            {fileName}
                          </Paper>
                        )}
                      </Box>
                    )}
                  </React.Fragment>
                ) : (
                  fileType === "form" && (
                    <Box component="form" noValidate>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            // required
                            id="name"
                            name="name"
                            label="Dealer Name"
                            fullWidth
                            autoComplete="name"
                            variant="standard"
                            value={dealerData?.dealership_name}
                            onChange={(e) => {
                              setDealerData({
                                ...dealerData,
                                dealership_name: e.target.value,
                              });
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl
                            sx={{
                              width: "100%",
                            }}
                            variant="standard"
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              OEM List
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={dealerData?.oem_id}
                              onChange={(e) => {
                                setDealerData({
                                  ...dealerData,
                                  oem_id: e.target.value,
                                });
                              }}
                            >
                              {oemList?.map((value, index) => {
                                return (
                                  <MenuItem value={value?.oem_id} key={index}>
                                    {value?.company_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            // required
                            id="name"
                            name="name"
                            label="Contact Name"
                            fullWidth
                            autoComplete="name"
                            variant="standard"
                            value={dealerData?.contact_person_name}
                            onChange={(e) => {
                              setDealerData({
                                ...dealerData,
                                contact_person_name: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            // required
                            id="number"
                            name="number"
                            type="number"
                            label="Mobile Number
              "
                            fullWidth
                            // autoComplete="email"
                            variant="standard"
                            value={dealerData?.mobile_number}
                            onChange={(e) => {
                              if (e.target.value.length > 10) {
                                return;
                              } else {
                                setDealerData({
                                  ...dealerData,
                                  mobile_number: e.target.value,
                                });
                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            // required
                            id="city"
                            name="city"
                            label="City"
                            fullWidth
                            autoComplete="city"
                            variant="standard"
                            value={dealerData?.city}
                            onChange={(e) => {
                              setDealerData({
                                ...dealerData,
                                city: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            // required
                            id="state"
                            name="state"
                            label="State"
                            fullWidth
                            autoComplete="state"
                            variant="standard"
                            value={dealerData?.state}
                            onChange={(e) => {
                              setDealerData({
                                ...dealerData,
                                state: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )
                )}

                <React.Fragment>
                  <div style={{ width: "100%" }}>
                    <Box
                      sx={{
                        margin: "24px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleDataShare}
                        // disabled={
                        // Object.keys(finalData).length !== 0
                        //     ? false
                        //     : true
                        //   // !anchorDetails?.email_type && anchorDetails?.anchor_name
                        // }
                        sx={{ ml: 1 }}
                      >
                        {isLoading ? "Loading" : "Upload"}
                      </Button>
                    </Box>
                  </div>
                </React.Fragment>
              </>
            )}
          </Box>
        </Paper>
      </Container>
    </>
  );
}
