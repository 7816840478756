import React from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

import "../Scheduler.css";
import DatePickerComponent from "../../../Components/DatePicker";
import { useState } from "react";
import useCalculate from "../../../utility/Calculate";
import { useEffect } from "react";
import ScheduleViewer from "./ScheduleViewer";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { generateEmiAndEdiScheme } from "../../../utility/schedule/useCalculateV1";
import { generateEmiAndEdiSchemeV2 } from "../../../utility/schedule/useCalculate";
import moment from "moment";
import { isAfter, parse } from "date-fns";
import { DatePicker } from "@material-ui/pickers";

const Scheduler = () => {
  const [values, setValues] = useState({
    loanAmt: "",
    interest: "",
    tenure: "",
    tenureType: "month",
    date: new Date(),
  });
  // const [values, setValues] = useState({
  //   loanAmt: "100000",
  //   interest: "18",
  //   tenure: "12",
  //   tenureType: "month",
  //   date: "10/07/2022",
  // });

  const navigate = useNavigate();
  const { generateSchedule } = useCalculate();
  const [schedules, setSchedule] = useState({
    EMI: [],
    EDI: [],
    EFI: [],
    open: false,
  });
  const [showEMI, setShowEMI] = useState({
    open: false,
    emi: "",
  });

  const handleChange = (prop) => (event) => {
    setShowEMI({
      open: false,
      emi: "",
    });
    if (event.target.value !== "" && !/^\d+$/.test(event.target.value)) {
      return false;
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCalculate = async () => {
    if (isValid()) {
      const { loanAmt, interest, tenure, tenureType, date } = values;
      if (isAfter(new Date(date), new Date(`2023/05/01`))) {
        let { emiSheet, efiSheet } = await generateEmiAndEdiSchemeV2(
          moment(date).format("DD/MM/YYYY"),
          tenure,
          loanAmt,
          interest,
          "MONTHS"
        );
        setSchedule({
          ...schedules,
          // open: true,
          v2: true,
          EMI: emiSheet,
          EFI: efiSheet,
          EDI: [],
        });
        setShowEMI({
          open: true,
          emi: emiSheet?.[0]?.EMI,
        });
      } else {
        let { emiSheet, ediSheet } = await generateEmiAndEdiScheme(
          date,
          Number(tenure),
          Number(loanAmt),
          Number(interest),
          "MONTHS"
        );
        setSchedule({
          ...schedules,
          // open: true,
          v2: false,
          EMI: emiSheet,
          EDI: ediSheet,
          EFI: [],
        });
        setShowEMI({
          open: true,
          emi: emiSheet?.[0]?.EMI,
        });
      }
    } else {
      toast.error("All fields are Mandatory");
    }
  };

  const handleGnerateSchedule = () => {
    setSchedule({ ...schedules, open: true });
  };

  const formatDate = (dateString) => {
    var dateParts = dateString.split("/");
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  };

  const isValid = () => {
    for (let key in values) {
      if (values[key].length <= 0) return false;
    }

    return true;
  };

  return (
    <>
      <div className="wrapper-center">
        <div className="calculate-form">
          <p
            className="go-back-btn"
            onClick={() => navigate("/")}
            style={{ marginTop: 0, marginLeft: 0 }}
          >
            <ArrowBackIcon /> Go back Home
          </p>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                width: "50%",
                gap: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >

              <TextField
                sx={{ width: "60%" }}
                required
                id="loan-amount"
                label="Loan Amount"
                value={values.loanAmt}
                onChange={handleChange("loanAmt")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                className="mt-2"
                sx={{ width: "60%" }}
                required
                id="loan-amount"
                label="Interest Rate"
                value={values.interest}
                onChange={(e) => {
                  setShowEMI({
                    open: false,
                    emi: "",
                  });
                  setValues({
                    ...values,
                    interest: e.target.value,
                  });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                variant="standard"
              />

              <TextField
                sx={{ width: "60%" }}
                required
                id="loan-amount"
                className="mt-2"
                label="Tenure"
                value={values.tenure}
                onChange={handleChange("tenure")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <QueryBuilderIcon sx={{ fontSize: 16 }} />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </div>
            <div>
              <DatePicker
                sx={{ width: "50%" }}
                className="w-100"
                label="Disbursal Date"
                format="dd/MM/yyyy"
                // maxDate={new Date()}
                //   value={ new Date()}
                value={values?.date}
                onChange={(date) => {
                  setShowEMI({
                    open: false,
                    emi: "",
                  });
                  setValues({
                    ...values,
                    date: date,
                  });
                }}
                animateYearScrolling
              />
            </div>
          </div>
          {/* 
          <DatePickerComponent
            text={"Disbursal Date"}
            defaultValue={
              values?.date !== "" && moment(values?.date).format("DD/MM/YYYY")
            }
            onChange={(date) => {
              setValues({ ...values, date });
            }}
          /> */}

          {showEMI?.open && (
            <div className="emi-btn mt-4">
              <span className="emi-text" style={{ color: "#6C6C6C" }}>
                EMI
              </span>
              <span className="emi-text" style={{ color: "#10B800" }}>
                 ₹ {showEMI?.emi}
              </span>
            </div>
          )}

          <Button
            disabled={
              values?.loanAmt === "" ||
              values?.interest === "" ||
              values?.tenure === ""
            }
            variant="contained"
            className={"mt-4 calculate-btn"}
            onClick={() => {
              showEMI?.open ? handleGnerateSchedule() : handleCalculate();
            }}
          >
            {showEMI?.open ? "Generate Schedule" : "Calculate EMI"}
          </Button>
        </div>
      </div>
      <ScheduleViewer
        open={schedules?.open}
        schedules={schedules}
        basicData={{
          ...values,
          name: "NA",
          nbfc: "NA",
        }}
        handleClose={() => {
          setSchedule({
            open: false,
          });
        }}
      />
    </>
  );
};

export default Scheduler;
