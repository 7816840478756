import {
  AppBar,
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAgentFilesStatus } from "../../api";
import CustomTableV2 from "../CustomTable/CustomTableV2";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import PendingIcon from "@mui/icons-material/Pending";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { capitalize, f } from "../../utility/Formater";
import MouseOverPopover from "../Popover";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Close, Comment } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import AgentFilesStatusFilter from "../Sort/AgentFilesStatusFilter";
import AgentFilesStatusComments from "./AgentFilesStatusComments";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getStatusIcon(isApproved) {
  switch (isApproved) {
    case true:
      return (
        <Avatar sx={{ backgroundColor: "#76ff03" }}>
          <CheckIcon color="success" fontSize="small" />
        </Avatar>
      );
    case false:
      return (
        <Avatar sx={{ backgroundColor: "#ff1744" }}>
          <ClearIcon fontSize="small" />
        </Avatar>
      );
    case null:
      return (
        <Avatar sx={{ backgroundColor: "#2196f3" }}>
          <PendingIcon fontSize="small" />
        </Avatar>
      );
    default:
      return (
        <Avatar>
          <RemoveCircleOutlineIcon color="disabled" fontSize="small" />
        </Avatar>
      );
  }
}

function getOverallStatusChip(status) {
  switch (status) {
    case "approved":
      return (
        <Chip
          label="A"
          className="mx-1"
          variant="outlined"
          color="secondary"
          size="small"
        />
      );
    case "pending":
      return (
        <Chip
          label="P"
          className="mx-1"
          variant="outlined"
          color="info"
          size="small"
        />
      );
    default:
      return "";
  }
}

const ViewAgentFilesStatus = ({ open, handleClose, agentDetails }) => {
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [overallStatus, setOverallStatus] = useState(["approved", "pending"]);
  const [totalCount, setTotalCount] = useState(0);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [allColumns, setAllColumns] = useState({});
  const [search, setSearch] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [openComments, setOpenComments] = useState(false);
  const [filteredData, setFilteredData] = useState(null);

  const { user } = useContext(UserContext);
  const navigator = useNavigate();

  const columns = [
    {
      label: (
        <span className="text-wrap" style={{ width: 150 }}>
          Customer Name
        </span>
      ),
      identifier: "customer_name",
      render: (i) => {
        return (
          <div
            className="d-flex flex-column align-content-center"
            style={{ width: 200 }}
          >
            <span className="text-wrap" style={{ width: 150 }}>
              {getOverallStatusChip(tableData[i]?.status)}
              {tableData[i]?.customerData?.pan_details?.name}
            </span>
            <span className="text-nowrap" style={{ fontSize: 10 }}>
              Login At:{" "}
              {moment(tableData[i]?.createdAt).format("DD/MM/yyyy hh:MM:SS A")}
            </span>
          </div>
        );
      },
      freeze: true,
      freezeWidth: tableDataLoading || !tableData?.length ? 0 : 70,
      bgcolor: "#fff",
    },
    {
      label: <span className="text-nowrap">Dealer/NBFC</span>,
      identifier: "dealer_name",
      render: (i) => {
        return (
          <div
            className="d-flex flex-row align-content-center justify-content-center"
            style={{ width: 150 }}
          >
            <span className="text-wrap" style={{ width: 100 }}>
              {tableData[i]?.dealerData?.dealership_name}
            </span>
            <div
              className="w-100 h-100 d-flex flex-column align-content-center justify-content-center"
              style={{ width: 60 }}
            >
              <embed
                src={tableData[i]?.nbfcData?.logo}
                height={"100%"}
                width={50}
              ></embed>
            </div>
          </div>
        );
      },
      freeze: true,
      freezeWidth: tableDataLoading || !tableData?.length ? 0 : 250,
      bgcolor: "#fff",
    },
    {
      label: <span className="text-nowrap">Approval %</span>,
      identifier: "status",
      render: (i) => {
        return (
          <div
            className="d-flex flex-column align-content-center"
            style={{ width: 50 }}
          >
            {tableData[i]?.completion_percentage?.toFixed(2)}
          </div>
        );
      },
      freeze: true,
      freezeWidth: tableDataLoading || !tableData?.length ? 0 : 400,
      bgcolor: "#fff",
    },
    ...(tableDataLoading || !tableData?.length
      ? new Array(10).fill(0).map((data, index) => ({
          label: <span className="text-nowrap">{`Document ${index + 1}`}</span>,
          render: (i) => {},
          bgcolor: "#fff",
        }))
      : []),
    ...(allColumns?.customer || []).map((col) => {
      return {
        label: <span className="text-nowrap">{capitalize(col.name, "_")}</span>,
        render: (i) => {
          const file = tableData[i]?.documents?.customer?.[col?.name];
          return (
            <MouseOverPopover
              className="ms-1"
              vertical="top"
              horizontal="right"
              renderUI={<DocumentDetails file={file} />}
              head={<div>{getStatusIcon(file?.isApproved)}</div>}
            />
          );
        },
        bgcolor: "#fff",
      };
    }),
    ...(allColumns?.["co_customer"] || allColumns?.["co-customer"] || []).map(
      (col) => {
        return {
          label: (
            <span className="text-nowrap">{capitalize(col.name, "_")}</span>
          ),
          render: (i) => {
            let file =
              tableData[i]?.documents?.co_customer?.[col?.name] ||
              tableData[i]?.documents?.["co-customer"]?.[col?.name];
            return (
              <MouseOverPopover
                className="ms-1"
                vertical="top"
                horizontal="right"
                renderUI={<DocumentDetails file={file} />}
                head={<div>{getStatusIcon(file?.isApproved)}</div>}
              />
            );
          },
          bgcolor: "#fff",
        };
      }
    ),
    ...(allColumns?.["pre_disbursement"] || []).map((col) => {
      return {
        label: <span className="text-nowrap">{capitalize(col.name, "_")}</span>,
        render: (i) => {
          const file = tableData[i]?.documents?.pre_disbursement?.[col?.name];
          return (
            <MouseOverPopover
              className="ms-1"
              vertical="top"
              horizontal="right"
              renderUI={<DocumentDetails file={file} />}
              head={<div>{getStatusIcon(file?.isApproved)}</div>}
            />
          );
        },
        bgcolor: "#fff",
      };
    }),
    ...(allColumns?.["post_disbursement"] || []).map((col) => {
      return {
        label: <span className="text-nowrap">{capitalize(col.name, "_")}</span>,
        render: (i) => {
          const file = tableData[i]?.documents?.post_disbursement?.[col?.name];
          return (
            <MouseOverPopover
              className="ms-1"
              vertical="top"
              horizontal="right"
              renderUI={<DocumentDetails file={file} />}
              head={<div>{getStatusIcon(file?.isApproved)}</div>}
            />
          );
        },
        bgcolor: "#fff",
      };
    }),
    ...(allColumns?.["other_documents"] || []).map((col) => {
      return {
        label: <span className="text-nowrap">{capitalize(col.name, "_")}</span>,
        render: (i) => {
          const file = tableData[i]?.documents?.other_documents?.[col?.name];
          return (
            <MouseOverPopover
              className="ms-1"
              vertical="top"
              horizontal="right"
              renderUI={<DocumentDetails file={file} />}
              head={<div>{getStatusIcon(file?.isApproved)}</div>}
            />
          );
        },
        bgcolor: "#fff",
      };
    }),
  ].filter(Boolean);

  function assignCategory(document, category, categorizedDocs = {}) {
    if (Array.isArray(document)) {
      document.map((doc) => {
        if (!doc?.category) {
          doc["category"] = category;
        }
        if (!categorizedDocs?.[doc?.category])
          categorizedDocs[doc?.category] = [];
        categorizedDocs[doc?.category].push(doc);
        return doc;
      });
    } else {
      if (!document?.category) {
        document.category = category;
      }
      if (!categorizedDocs?.[document?.category])
        categorizedDocs[document?.category] = [];
      categorizedDocs[document?.category].push(document);
    }

    return categorizedDocs;
  }

  async function getAgentFileStatusDetails(pg, search, filter) {
    try {
      setTableDataLoading(true);
      let params = `overall_status=${JSON.stringify(
        overallStatus
      )}&limit=${limit}&page=${pg ?? page}`;

      let filters = {
        overall_status:["pending","approved"]
      }

      if (search?.length) params += `&search=${search}`;
      if(filteredData) filters={ ...filters, ...filteredData };
      if (filter) filters={ ...filters, ...filter };
      
      params += `&filters=${JSON.stringify(filters)}`;

      const { data } = await getAgentFilesStatus(
        agentDetails?.agent_code,
        params
      );

      setTotalCount(data?.totalCount || 0);
      let allDocs = {};
      const modifiedData = data?.data?.map((item) => {
        let categorizedDocs = assignCategory(
          item?.files_percentage?.files,
          "customer",
          {}
        );

        Object.keys(categorizedDocs || {}).map((key) => {
          categorizedDocs[key]?.sort((a, b) => a?.name?.localeCompare(b?.name));
          if (!allDocs?.[key]) allDocs[key] = [];
          if (allDocs?.[key]?.length < categorizedDocs[key]?.length) {
            allDocs[key] = categorizedDocs[key];
          }
          for (let doc of categorizedDocs[key] || []) {
            if (!item?.["documents"]) item["documents"] = {};
            if (!item?.["documents"]?.[key]) item["documents"][key] = {};
            if (doc?.name && !item["documents"][key]?.[doc?.name])
              item["documents"][key][doc?.name] = doc;
            if (doc?.name) item["documents"][key][doc?.name] = doc;
          }
        });

        return item;
      });

      setTableData(modifiedData);
      setAllColumns(allDocs);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setTableDataLoading(false);
    }
  }
  
  function handleDoubleClickOnCell(columnData, rowIdx) {
    if (columnData?.identifier === "customer_name") {
      navigator(`/view-application/${tableData[rowIdx]?.application_id}`);
    }

    if (Number(user?.role) !== 3 && columnData?.identifier === "dealer_name") {
      navigator(`/update-anchor-v2/${tableData[rowIdx]?.anchor_id}`);
    }
  }

  function handleSearch(e) {
    setSearch(e.target.value);
    getAgentFileStatusDetails(1, e.target.value);
  }

  useEffect(() => {
    if (open) {
      getAgentFileStatusDetails();
    }
  }, [open]);

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography variant="h6" component="div">
              {agentDetails?.name}
            </Typography>
            <Typography className="mx-4" variant="h6" component="div">
              <TextField
                size="small"
                className="rounded-pill"
                id="outlined-basic"
                placeholder="Search..."
                variant="outlined"
                InputProps={{
                  style: {
                    borderRadius: "50px", // Makes the border round like a pill
                    backgroundColor: "#fff",
                    width: 300,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSearch(e);
                }}
              />
            </Typography>
            <Typography className="mx-4 d-flex flex-row align-items-center" variant="h6" component="div">
              <Button
                title="filters"
                sx={{
                  bgcolor: "#fff",
                  ":hover": { color: "InfoText", bgcolor: "#fff" }
                }}
                variant="text"
                onClick={() => {
                  setOpenFilter(true);
                }}
              >
                 <FilterAltIcon /> Filter
              </Button>
            { filteredData && <IconButton title="close" color="inherit" onClick={()=>{setFilteredData(null);getAgentFileStatusDetails(1,search,null);}} ><Close/></IconButton> }
            </Typography>
            <div className="w-100 h-100 d-flex flex-row align-content-center justify-content-end">
              <Typography
                className="mx-4 d-flex flex-column align-items-center justify-content-center"
                variant="h6"
                component="div"
              >
                <Button
                  title="comments"
                  sx={{
                    bgcolor: "#fff",
                    ":hover": {
                      color: "InfoText",
                      bgcolor: "#fff",
                      borderColor: "InfoText",
                    },
                  }}
                  variant="text"
                  onClick={() => {
                    setOpenComments(true);
                  }}
                >
                  <Comment className="mx-1" /> Comments
                </Button>
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
        <DialogContent className="m-0 p-0">
          <CustomTableV2
            loadingRows={50}
            isLoading={tableDataLoading}
            tableData={tableData}
            columns={columns}
            borderBottom={true}
            page={page}
            totalCount={totalCount}
            limit={limit}
            serialNo={true}
            slBgColor={"#fff"}
            stickSLNO={true}
            isPaginated={true}
            onPageChange={(page, limit) => {
              setPage(page);
              getAgentFileStatusDetails(page, search);
            }}
            tableHeight={"84vh"}
            onDoubleClickOnCell={handleDoubleClickOnCell}
            // onDoubleClickOnRow={handleDoubleClickOnRow}
          />
        </DialogContent>
      </Dialog>
      <AgentFilesStatusFilter
        open={openFilter}
        handleClose={() => {
          setOpenFilter(false);
        }}
        onApply={(filterData,fltrData) => {
          getAgentFileStatusDetails(1, "", filterData);
          setOpenFilter(false);
          setFilteredData(fltrData);
        }}
        options={[
          "application_id",
          "customer_name",
          "dealer_name",
          "nbfc",
          "overall_status",
        ]}
        agent_code={agentDetails?.agent_code}
        filteredData={filteredData}
      />
      <AgentFilesStatusComments
        open={openComments}
        handleClose={() => {
          setOpenComments(false);
        }}
        agent_code={agentDetails?.agent_code}
        assignCategory={assignCategory}
      />
    </>
  );
};

 const DocumentDetails = ({ file = {} }) => {
  let uploadedFrom = file?.uploadedFrom;
  if (uploadedFrom) uploadedFrom = capitalize(uploadedFrom, "_");

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-between"
      style={{ width: 200, height: 200 }}
    >
      <div className="w-100 border" style={{ height: "70%" }}>
        {file?.fileLink ? (
          <embed
            src={file?.fileLink}
            width="100%"
            height={"100%"}
            className="object-fit-contain"
          />
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
            <span>Not Uploaded</span>
          </div>
        )}
      </div>
      <div
        className="w-100 d-flex flex-column align-content-center justify-content-around"
        style={{ height: "30%", fontSize: 10 }}
      >
        <div className="w-100 d-flex flex-row align-content-center justify-content-between">
          <span className="fw-bold">Uploaded From: </span>
          <span>{f(uploadedFrom)}</span>
        </div>
        <div className="w-100 d-flex flex-row align-content-center justify-content-between">
          <span className="fw-bold">Uploaded At: </span>
          <span>{f(file?.uploadedDateMS, "date/time")}</span>
        </div>
      </div>
    </div>
  );
};

export default ViewAgentFilesStatus;