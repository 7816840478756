import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useEffect, useState } from "react";
import noData from "../../assets/nodata.gif";
import { capitalize, f } from "../../utility/Formater";
import AgentCalendar from "../AgentCalender";
import AgentLocations from "./AgentLocations";
import CustomTableV2 from "../CustomTable/CustomTableV2";
import { calculateDistanceBtnLanLng } from "../../utility/Calculate";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AgentVisitDialog({ open, handleClose, agentData }) {
  const [selectedDate, setSelectDate] = useState(new Date());
  const [visitDetails, setVisitDetails] = useState([]);
  const onChangeDate = (val) => {
    setSelectDate(new Date(val));
  };
  const [toggleValue, setToggleValue] = useState("visits");
  const [toggleDisabled, setToggleDisabled] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [locationsData, setLocationsData] = useState([]);
  const [nearByLocations, setNearByLocations] = useState([]);
  const [customerLocations, setCustomerLocations] = useState([]);
  const [distanceTraveled, setDistanceTraveled] = useState(0);

  const handleAccordianExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "panel1");
  };
  // count={visitData?.visit_count}
  // name={visitData?.name}
  // visitArray={visitData?.daily_data || []}
  const {
    visitsData: { daily_data = [], visit_count = 0 },
    name,
    agent_code,
  } = agentData || {};
  const [LocateCntr,setLocateCntr]=useState(null)

  let columns = [
    {
      label: <span className="text-nowrap">Stop No.</span>,
      render: (index) => {
        const location = locationsData?.[index];
        const indexToFetch = locationsData?.length - index - 1;
        return (
          <Chip
            label={String(locationsData?.length - index)}
            variant="contained"
            size="small"
            clickable
            color="secondary"
            onClick={() => {
              // console.log("location => ",(locationsData?.length - index) );              
              setLocateCntr({
                lat: locationsData?.[indexToFetch]?.lat,
                lng: locationsData?.[indexToFetch]?.long,
              });
            }}
          />
        );
      },
      width: 30,
    },
    {
      label: "Time",
      render: (index) => {
        // return moment(locationsData?.[index]?.dateMS).format("hh:mm:ss A");
        const indexToFetch1 = locationsData?.length - index - 1;
        const time = moment(locationsData?.[index]?.dateMS).format("hh:mm:ss A");
        const link = `https://www.google.com/maps/search/${locationsData?.[indexToFetch1]?.lat},${locationsData?.[indexToFetch1]?.long}` 

       return (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {time}
          </a>
        );
      },
      width: 200,
    },
  ];

  const nearByLocationsColumn = [
    {
      label:<span className="text-nowrap">Customer Name</span>,
      render:(index) => {
        return f(nearByLocations?.[index]?.customer_name);
      }
    },
    {
      label:<span>app id</span>,
      render:(index)=>{
        return f(nearByLocations?.[index]?.application_id);
      }
    }
  ]

  const handleDataforDate = (date) => {
    const dateToFind = moment(date).format("DD/MM/YYYY");
    const foundData = (daily_data || [])?.find(
      (item) => item.date === dateToFind
    );
    return foundData;
  };

  const handleToggleChange = (event, value) => {
    setToggleValue(value || event.target.value);
    setExpanded("panel1");
  };

  useEffect(() => {
    if (selectedDate) {
      setVisitDetails(handleDataforDate(selectedDate)?.visit_details || []);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (customerLocations?.length) {
      const filtered_locations = customerLocations?.filter(
        (itm) =>{
          const distance = calculateDistanceBtnLanLng(locationsData?.[0]?.long,locationsData?.[0]?.lat,itm?.long,itm?.lat);
          return distance >=10;
        });
      setNearByLocations(filtered_locations);
    }
  }, [customerLocations]);

  useEffect(() => {
    if (open) {
      setToggleValue("visits");
      setExpanded("panel1");
    }
  }, [open]);

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="d-flex flex-row"
        >
          <Grid container spacing={1}>
            <Grid
              lg={4}
              alignItems={"center"}
              className="d-flex flex-row justify-content-start agent-left-content"
            >
              <span className="ms-2">
                {name} - ({visit_count})
              </span>
            </Grid>
            <Grid lg={8}>
              <div className="d-flex flex-row justify-content-start align-items-end">
                <div style={{ width: 400 }} className="ms-1">
                  <ToggleButtonGroup
                    color="primary"
                    value={toggleValue}
                    fullWidth
                    exclusive
                    size="small"
                    onChange={handleToggleChange}
                    aria-label="Platform"
                    disabled={toggleDisabled}
                  >
                    <ToggleButton size="small" value="visits">
                      Visits
                    </ToggleButton>
                    <ToggleButton size="small" value="locations">
                      Locations
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <div className="ms-2  " style={{fontSize:"0.9rem"}}>
                  {moment(selectedDate).format("ddd ,MMMM DD yyyy")} | { toggleValue === "visits" ? `${visitDetails?.length} visits` : ` ${distanceTraveled}km | ${locationsData?.length} locations`}
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{
            height: "70vh",
          }}
        >
          <Grid container spacing={1}>
            <Grid lg={4} className="agent-left-content">
              <div sx={{ height: "100%", backgroundColor: "blue" }}>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleAccordianExpand("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography sx={{ flexShrink: 0 }}>Calender</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AgentCalendar
                      data={daily_data}
                      onDateChange={onChangeDate}
                    />
                  </AccordionDetails>
                </Accordion>
                {toggleValue === "locations" && <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleAccordianExpand("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography sx={{ flexShrink: 0, textWrap: "nowrap" }}>
                      Near By Customers ( ~ 10km )
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomTableV2
                      columns={nearByLocationsColumn}
                      tableData={nearByLocations}
                      tableHeight={430}
                      isLoading={toggleDisabled}
                    />
                  </AccordionDetails>
                </Accordion>}
                {toggleValue === "locations" && <Accordion
                  expanded={expanded === "panel3"}
                  className="20vh"
                  onChange={handleAccordianExpand("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography sx={{ flexShrink: 0, textWrap: "nowrap" }}>
                      Agent Locations
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className="w-100 overflow-auto"
                    sx={{ height: 460 }}
                  >
                    <CustomTableV2
                      columns={columns}
                      tableData={locationsData}
                      tableHeight={430}
                      isLoading={toggleDisabled}
                    />
                  </AccordionDetails>
                </Accordion>}
              </div>
            </Grid>
            <Grid lg={8}>
              {toggleValue === "visits" && (
                <AgentVisits
                  key={selectedDate}
                  visitDetails={visitDetails}
                  selectedDate={selectedDate}
                />
              )}
              {toggleValue === "locations" && (
                <AgentLocations
                  agent_name={name}
                  LocateCntr={LocateCntr}
                  date={selectedDate}
                  agent_code={agentData?.agent_code}
                  setLocationsLoader={setToggleDisabled}
                  setDistanceTraveled={setDistanceTraveled}
                  locationsLoader={toggleDisabled}
                  locationsState={[locationsData, setLocationsData]}
                  setCustomerLocations={setCustomerLocations}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}

const AgentVisits = ({ visitDetails, selectedDate }) => {
  return (
    <div>
      <div style={{ height: "62vh" }} className="overflow-auto">
        <Grid container className="overflow-auto">
          {visitDetails?.visit_images?.length !== 0 &&
            visitDetails?.map((visitData, index) => (
              <VisitImageBox key={visitData?._id} visitData={visitData} />
            ))}
          {visitDetails?.length === 0 && (
            <>
              <div className="content-center mt-4 w-100">
                <img src={noData} width={"250px"} />
              </div>
              <p
                className="text-center w-100"
                style={{
                  marginTop: "-20px",
                  color: "gray",
                }}
              >
                No visit Data
              </p>
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};

const VisitImageBox = ({ visitData }) => {
  const [imagesArray, setImagesArray] = useState(visitData?.visit_images || []);
  let [imageIdx, setImageIdx] = useState(0);
  const [nextImg, setNextImg] = useState(false);
  function handleNextImage() {
    if (imageIdx === imagesArray?.length - 1) {
      imageIdx = -1;
    }
    imageIdx++;
    setImageIdx(imageIdx);
    setNextImg(true);
  }

  function handlePrevImage() {
    if (imageIdx === 0) {
      imageIdx = imagesArray?.length;
    }
    imageIdx--;
    setImageIdx(imageIdx);
    setNextImg(false);
  }

  useEffect(() => {
    setImageIdx(0);
    setImagesArray(visitData?.visit_images);
  }, []);

  return (
    <Grid md={6}>
      <div className="agent-visit-card">
        <div
          className="d-flex flex-column justify-content-between overflow-hidden"
          style={{ height: 50 }}
        >
          <span className="mb-1" style={{ fontSize: "14px", fontWeight: 500 }}>
            {visitData?.name ?? visitData?.borrower_name}
          </span>
          <div className="d-flex flex-row align-items-center justify-content-between mb-1">
            <span style={{ fontSize: "12px" }}>
              {visitData?.mobile_no ??
                capitalize(imagesArray?.[imageIdx]?.file_name || "", "_")}
            </span>
            <span className="fs-6 ">
              {imageIdx + 1}/{imagesArray?.length}
            </span>
          </div>
        </div>
        <div className="frame-holder position-relative overflow-hidden">
          {imagesArray?.length !== 1 && (
            <button
              className="position-absolute z-1 d-flex flex-column align-items-center justify-content-center start-0 top-50 ms-2 btn btn-light opacity-25 p-1"
              onClick={() => {
                handlePrevImage();
              }}
            >
              <ArrowBackIosNewIcon fontSize="small" color="action" />
            </button>
          )}
          {imagesArray?.length !== 1 && (
            <button
              className="position-absolute z-1 d-flex flex-column align-items-center justify-content-center end-0 top-50 me-2 btn btn-light opacity-25 p-1"
              onClick={() => {
                handleNextImage();
              }}
            >
              <ArrowForwardIosIcon fontSize="small" color="action" />
            </button>
          )}
          <div key={imageIdx} className={nextImg ? "slide-out" : "slide-in"}>
            <iframe height={"100%"} src={imagesArray?.[imageIdx]?.fileLink} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            {visitData?.dateMS
              ? moment(visitData?.dateMS).format("hh:mm a")
              : ""}
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: "16px",
            }}
          >
            {capitalize(
              (visitData?.purpose_of_visit || "")?.replaceAll("_", " ")
            )}
          </div>
        </div>
        <div
          className="text-wrap text-break overflow-auto"
          style={{ maxHeight: 50 }}
        >
          <span style={{ fontSize: "12px", lineHeight: "16px" }}>
            {visitData?.comment || ""}
          </span>
        </div>
        <div style={{ color: "#777777" }}></div>
        {imagesArray?.[imageIdx]?.location?.lat &&
          imagesArray?.[imageIdx]?.location?.long && (
            <a
              target="_blank"
              href={`https://www.google.com/maps/search/${imagesArray?.[imageIdx]?.location?.lat},${imagesArray?.[imageIdx]?.location?.long}`}
              style={{
                fontSize: "12px",
                marginTop: 6,
              }}
            >
              <FmdGoodIcon sx={{ fontSize: 13 }} />{" "}
              {imagesArray?.[imageIdx]?.location?.lat},{" "}
              {imagesArray?.[imageIdx]?.location?.long}
            </a>
          )}
      </div>
    </Grid>
  );
};
