import React, { useState } from "react";
import { bulkCalling } from "../api";
import { toast } from "react-toastify";
import uploadAnimation from "../assets/cloud-upload.gif";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DragDrop from "./DocumentUploader";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import moment from "moment";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ProgressBar, Table } from "react-bootstrap";

const BulkCall = ({
  templates,
  open,
  close,
  assign,
  Transition,
  downloadFile,
}) => {
  console.log(open);
  let [customMessage, setCustomMessage] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [template, setTemplate] = useState({
    text: "",
    template_name: "",
  });
  const [assignCSV, setAssignCSV] = useState(null);
  const [upload, setUpload] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setUploading] = useState(false);

  let [select, setSelect] = useState(false);

  const handleBulkCallingData = async () => {
    setUploading(true);
    try {
      let payload = {
        type: "base64",
        file: assignCSV,
        custom_message: customMessage,
        campaign_type: campaignName,
      };
      console.log(payload);
      //

      let { data } = await bulkCalling(payload);
      upload ? setUpload(false) : setUpload(true);
      setAssignCSV(null);
      close();
      toast(data?.message);
    } catch (error) {
      setAssignCSV(null);
      toast.error(error?.response?.data?.message);
    }
    setUploading(false);
  };
  const handleSampleCsv = () => {
    let headers = [
      "Application ID,Customer Name,Contact number,EMI Amount,EMI Date,Overall Status,Dealer's name",
    ];

    downloadFile({
      data: [...headers, []].join("\n"),
      fileName: `Bulk-Call-sample-(${moment(new Date()).format("DD-MM-YYYY")})`,
      fileType: "text/csv",
    });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      // onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
      {!isUploading ? (
        <DialogContent>
          {assignCSV === null ? (
            <div className="mb-5">
              <div className="d-flex justify-content-end mb-4">
                <Button
                  onClick={() => {
                    handleSampleCsv();
                  }}
                >
                  <FileDownloadIcon /> Sample
                </Button>
              </div>
              <Box>
                <FormControl
                  fullWidth
                  style={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Template
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={template.template_name}
                    label="Template"
                    fullWidth
                    // onChange={(e) => {
                    // }}
                  >
                    {templates.map((ele, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          onClick={() => {
                            console.log({
                              text: ele.text,
                              template_name: ele.template_name || ele?.campaign,
                            });
                            setTemplate({
                              text: ele.text,
                              template_name: ele.template_name || ele?.campaign,
                            });
                            setCampaignName(
                              ele?.template_name || ele?.campaign
                            );
                            setSelect(true);
                            setCustomMessage(ele.text);
                          }}
                          value={ele.template_name || ele.campaign}
                        >
                          {ele.template_name || ele.campaign}
                        </MenuItem>
                      );
                    })}
                    {/* {templates.map(ele => (
                                <MenuItem value={ele.text}>{ele.template_id}</MenuItem>
                            ))} */}
                  </Select>
                </FormControl>
              </Box>
              {select ? (
                template.template_name != "Other" ? (
                  <TextField
                    style={{
                      marginBottom: "25px",
                      marginTop: "25px",
                      width: "100%",
                    }}
                    id="outlined-multiline-flexible"
                    label="Custom Message"
                    multiline
                    outlined
                    value={customMessage}
                    maxRows={4}
                    disabled
                    onChange={(e) => {
                      setCustomMessage(e.target.value);
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "column",
                      marginBottom: "15px",
                    }}
                  >
                    <TextField
                      style={{ marginBottom: "25px", marginTop: "25px" }}
                      id="outlined-multiline-flexible"
                      label="Custom Message"
                      multiline
                      outlined
                      value={customMessage}
                      maxRows={4}
                      onChange={(e) => {
                        setCustomMessage(e.target.value);
                      }}
                    />
                    <TextField
                      id="outlined-multiline-flexible"
                      label="Campaign Name"
                      outlined
                      value={campaignName}
                      maxRows={4}
                      onChange={(e) => {
                        setCampaignName(e.target.value);
                      }}
                    />
                  </div>
                )
              ) : (
                <></>
              )}
              <DragDrop
                customType={["CSV"]}
                setFile={(val, name) => {
                  setAssignCSV(val);
                }}
              />
            </div>
          ) : (
            <>
              <div
                className="mt-2 d-flex"
                style={{ width: "300px", justifyContent: "space-between" }}
              >
                <div>
                  <InsertDriveFileIcon /> Bulk Call Data
                </div>
                <CloseIcon
                  className="pointer"
                  onClick={() => {
                    setAssignCSV(null);
                    setSelect(false);
                    setCampaignName("");
                    close();
                  }}
                />
              </div>
            </>
          )}
        </DialogContent>
      ) : (
        <DialogContent>
          <div>
            <p className="text-center">
              <img
                className="mt-5"
                src={uploadAnimation}
                width={200}
                alt="uploading..."
              />
            </p>
            <ProgressBar
              className="mt-4"
              striped
              variant="success"
              label={`${progress.toFixed(2)}%`}
              now={progress}
            />
          </div>
        </DialogContent>
      )}
      {!isUploading && (
        <DialogActions>
          <Button
            onClick={() => {
              setAssignCSV(null);
              setSelect(false);
              setCampaignName("");
              close();
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleBulkCallingData}>Submit</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default BulkCall;
