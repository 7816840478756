import React from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import moment from "moment";
import { fetchOSdashData } from "../../api";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import { cf } from "../../utility/Formater";
import TableCsvDownload from "../../Components/Download/TableCsvDownload";

const colMap = {
  lessThanFiveDays: "<=5 days",
  fiveToTenDays: "6-10 days",
  elevenToFifteenDays: "11-15 days",
  sixteenToThirtyDays: "16-30 days",
  greaterThanThirtyDays: ">30 days",
};

const OSDashboard = ({ loader, setLoader, loanType, sourceBy, osDate }) => {
  const [osData, setOSData] = useState(null);
  const [total, setTotal] = useState({});

  const fetchOSData = async () => {
    try {
      setLoader("OS");
      let params = `?sourced_by=${
        sourceBy === "all" ? "ALL" : sourceBy
      }&loan_type=${loanType}&os_date=${new Date(osDate).getTime()}`;

      const { data } = await fetchOSdashData(params);

      let finalData = Object.values(data?.data || {}) || [];
      setOSData(finalData);
      let totalData = {
        total_principal: 0,
        total_interest: 0,
        total_outstanding_amount: 0,
      };

      finalData?.forEach((val) => {
        totalData.total_principal += val?.total_outstanding_principal || 0;
        totalData.total_interest += val?.total_outstanding_interest || 0;
        totalData.total_outstanding_amount +=
          val?.total_outstanding_amount || 0;
      });

      setTotal(totalData);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "OS Data API Failed");
    }
    setLoader(null);
  };

  useEffect(() => {
    fetchOSData();
  }, [loanType, sourceBy, osDate]);

  return (
    <>
      {loader === null && osData?.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              id={"os-table"}
            >
              <TableHead sx={{ backgroundColor: "#D8E0FF" }}>
                <TableRow>
                  <TableCell>NBFC</TableCell>
                  <TableCell align="center">Principle Outstanding</TableCell>
                  <TableCell align="center">Outstanding Interest</TableCell>
                  <TableCell align="center">Total Outstanding</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(osData || [])?.map((row, i) => {
                  let length = osData?.length;
                  return (
                    <TableRow
                      key={i}
                      // sx={{
                      //   "&:last-child td, &:last-child th": {
                      //     border: 0,
                      //     fontWeight: 600,
                      //   },
                      // }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.nbfc_name}
                      </TableCell>
                      <TableCell align="center">
                        {cf(Math.ceil(row?.total_outstanding_principal || 0))}
                      </TableCell>
                      <TableCell align="center">
                        {cf(Math.ceil(row?.total_outstanding_interest || 0))}
                      </TableCell>
                      <TableCell align="center">
                        {cf(Math.ceil(row?.total_outstanding_amount || 0))}
                      </TableCell>
                    </TableRow>
                  );
                })}

                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      fontWeight: 600,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    TOTAL
                  </TableCell>
                  <TableCell align="center">
                    {cf(Math.ceil(total?.total_principal || 0))}
                  </TableCell>
                  <TableCell align="center">
                    {cf(Math.ceil(total?.total_interest || 0))}
                  </TableCell>
                  <TableCell align="center">
                    {cf(Math.ceil(total?.total_outstanding_amount || 0))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableCsvDownload
            tableId={"os-table"}
            filename={"OS-Dash-data"}
            topRow={
              osDate
                ? `As of,${moment(new Date(osDate)).format("DD/MM/YYYY")}\n\n`
                : ""
            }
          />
        </>
      )}
      {loader === "OS" && (
        <p
          className="text-center w-100 mt-5"
          style={{
            fontSize: "15px",
            color: "black",
          }}
        >
          <CircularProgress size={50} />
        </p>
      )}
      {loader === null && (osData === null || osData?.length === 0) && (
        <p
          className="text-center w-100 mt-5"
          style={{
            fontSize: "15px",
            color: "black",
          }}
        >
          <DoNotDisturbIcon sx={{ fontSize: 15, mr: 0.3 }} /> No Data to display
        </p>
      )}
    </>
  );
};

export default OSDashboard;
