import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import DragDrop from "../DocumentUploader";
import { Close, FileDownload, InsertDriveFile } from "@mui/icons-material";
import {
  getAllFynoEvents,
  getEventPaceholders,
  uploadBulkMessage,
} from "../../api";
import { toast } from "react-toastify";
import uploadAnimation from "../../assets/cloud-upload.gif";
import { ProgressBar } from "react-bootstrap";

const options = [
  { id: 1, label: "Option 1" },
  { id: 2, label: "Option 2" },
  { id: 3, label: "Option 3" },
];

export default function BulkMessaging({ open, handleClose }) {
  const [selectedOption, setSelectedOption] = useState("");
  const [collectionCSV, setCollectionCSV] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setUploading] = useState(false);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    setCollectionCSV(null);
    setFileName(null);
  };

  const fetchAllTemplates = async () => {
    try {
      setLoading("ALL_TEMPLATES");
      const { data } = await getAllFynoEvents();
      setTemplates(data?.data || []);
    } catch (error) {
      toast.error("Error loading templates");
    }
    setLoading(null);
  };

  const handleSampleSheet = async () => {
    try {
      setLoading("SAMPLE");
      const { data } = await getEventPaceholders(selectedOption);
      let placeholders = Object.keys(data?.data || {});
      downloadFile({
        data: [
          ...[`${placeholders?.join(",")},application_id,mobile_number`],
          [],
        ].join("\n"),
        fileName: `bulk-message_sample_sheet`,
        fileType: "text/csv",
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setLoading(null);
  };

  const handleSubmit = async () => {
    try {
      setLoading("SUBMIT");
      setUploading(true);
      let payload = {
        csv: collectionCSV,
        event_id: selectedOption,
      };
      let { data } = await uploadBulkMessage(payload, setProgress);
      toast.success("Bulk messaging completed");
      if (data?.failedCases && (data?.failedCases || []).length > 0) {
        toast.error("Some cases got failed. Check the sheet");
        let array = data?.failedCases;
        const csvRows = [];
        const headers = Object.keys(array[0]);
        csvRows.push(headers.join(","));

        // Loop through the rows
        for (const row of array) {
          const values = headers.map((header) => {
            const escaped = ("" + row[header]).replace(/"/g, '\\"');
            return `"${escaped}"`;
          });
          csvRows.push(values.join(","));
        }
        downloadFile({
          data: csvRows.join("\n"),
          fileName: `failed_bulk_message_cases`,
          fileType: "text/csv",
        });
      }
      handleClose()
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
    setLoading(null);
    setUploading(false);
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  useEffect(() => {
    if (open) {
      fetchAllTemplates();
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <div className="d-flex justify-content-between mb-3 w-100 p-3">
          Bulk Messaging
          <Button
            onClick={() => {
              handleSampleSheet();
            }}
            disabled={
              !selectedOption || loading === "SAMPLE" || loading === "SUBMIT"
            }
          >
            <FileDownload /> Sample
          </Button>
        </div>

        {!isUploading ? (
          <DialogContent>
            {collectionCSV === null ? (
              <div className="mb-5">
                <FormControl fullWidth className="mb-2">
                  <InputLabel id="demo-simple-select-label">
                    Template
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedOption}
                    label="Template"
                    onChange={handleChange}
                  >
                    {templates.map((option) => (
                      <MenuItem key={option.event_id} value={option.event_id}>
                        {option.event_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <DragDrop
                  customType={["CSV"]}
                  setFile={(val, name) => {
                    setCollectionCSV(val);
                    setFileName(name);
                  }}
                />
              </div>
            ) : (
              <>
                <div
                  className="mt-2 d-flex"
                  style={{ width: "300px", justifyContent: "space-between" }}
                >
                  <div>
                    <InsertDriveFile /> {fileName}
                  </div>
                  <Close
                    className="pointer"
                    onClick={() => {
                      setCollectionCSV(null);
                      setFileName(null);
                    }}
                  />
                </div>
              </>
            )}
          </DialogContent>
        ) : (
          <DialogContent>
            <div>
              <p className="text-center">
                <img
                  className="mt-5"
                  src={uploadAnimation}
                  width={200}
                  alt="uploading..."
                />
              </p>
              <ProgressBar
                className="mt-4"
                striped
                variant="success"
                label={`${progress.toFixed(2)}%`}
                now={progress}
              />
            </div>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            disabled={!selectedOption || !collectionCSV || isUploading}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
