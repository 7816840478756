import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useContext, useState } from "react";
import { GlobalContext } from "../../Context/GlobalContext";
import { useEffect } from "react";
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import { baseURL, generateMufinPDF, verifyAuth } from "../../api";
import { toast } from "react-toastify";
import axios from "axios";
import combineGif from "../../assets/combine.gif";
import { UserContext } from "../../Context/UserContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const groupDocs = [
  "customer_kyc",
  "co_customer_kyc",
  "other_documents",
  "cheques",
  "vehicle_delivery_photos",
];

const formatDocs = (
  applicationDocs,
  customerDocs,
  coCustomerDocs,
  fiStatus,
  coCustomerExist
) => {
  let fi_docs = [
    ...(Array.isArray(applicationDocs?.customer)
      ? applicationDocs?.customer
      : []),
    ...(Array.isArray(applicationDocs?.field_investigation)
      ? applicationDocs?.field_investigation
      : []),
  ];

  let other_documents = (applicationDocs?.other_documents || [])?.filter(
    (doc) => doc?.isApproved
  );

  let mergeDocs = {
    delivery_order: applicationDocs?.delivery_order || [],
    fi_report: [
      {
        name: "fi_report",
        fileLink: true,
        isApproved: fiStatus,
      },
    ],
    fi_docs,
    loan_agreement: applicationDocs?.loan_agreement || [],
    rto_form: applicationDocs?.form_26_35 || [],
    form_20: applicationDocs?.form_20 || [],
    customer_kyc: [
      customerDocs?.aadhaar_front,
      customerDocs?.aadhaar_back,
      customerDocs?.pan,
    ],
    ownership_proof: applicationDocs?.other_ownership_proof || [],
    ...(coCustomerExist
      ? {
          co_customer_kyc: [
            coCustomerDocs?.aadhaar_front,
            coCustomerDocs?.aadhaar_back,
            coCustomerDocs?.pan,
          ],
        }
      : {}),
    bank_passbook: applicationDocs?.bank_passbook || [],
    bsv: applicationDocs?.bsv || [],
    cheques: applicationDocs?.cheques || [],
    sales_invoice: applicationDocs?.sales_invoice || [],
    quotation: applicationDocs?.quotation || [],
    insurance: applicationDocs?.insurance || [],
    trc: applicationDocs?.trc || [],
    stamp_paper: applicationDocs?.stamp_paper || [],
    vehicle_delivery_photos: applicationDocs?.vehicle_delivery_photos || [],
    nach_screenshot: applicationDocs?.nach_screenshot || [],
    other_documents,
  };

  for (let key in mergeDocs) {
    if (key === "other_documents") {
      continue;
    }

    let multi_doc = false;
    if (mergeDocs[key]?.[0]?.meta_page_document) {
      multi_doc = true;
      if (!mergeDocs[key]?.[0]?.isApproved) {
        mergeDocs[key] = [];
        continue;
      }
    }

    mergeDocs[key] = mergeDocs[key]?.map((doc) => {
      if (multi_doc) {
        return {
          ...doc,
          include: true,
        };
      } else {
        if (doc?.isApproved) {
          return {
            ...doc,
            include: true,
          };
        } else {
          return null;
        }
      }
    });
    mergeDocs[key] = mergeDocs[key]?.filter((doc) => doc !== null);
  }

  console.log(mergeDocs);
  return mergeDocs || [];
};

export default function MufinDailog() {
  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const { user, setUser } = useContext(UserContext);

  const [docs, setDocs] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setDocs(
        formatDocs(
          updateDetails?.loanDocs,
          updateDetails?.customerDoc,
          updateDetails?.coCustomerDoc,
          updateDetails?.is_field_investigation_done,
          updateDetails?.coApplicantExist
        )
      );
    }
  }, [open]);

  // const handleDownload = async (key, fileName) => {
  //   try {

  //     const response = await axios.get(`${baseURL}/common/view?key=${key}`, {
  //       responseType: "blob", // Set response type to "blob"
  //       headers: {
  //         "Content-Type": "application/json",
  //         application: "EMBEDDED-TOOLS",
  //         // Add any other headers if needed
  //       },
  //       withCredentials: true,
  //       onDownloadProgress: (data) => {
  //         console.log(data)
  //         if (data.total !== 0) {
  //           const percentage = Math.round((100 * data.loaded) / data.total);
  //           setDownloading(percentage);
  //           // You can update a progress bar or any UI element here
  //         } else {
  //           console.warn("Total size is zero, unable to calculate progress.");
  //         }
  //       },
  //     });

  //     const aElement = document.createElement("a");
  //     aElement.setAttribute("download", fileName);
  //     const href = URL.createObjectURL(response.data);
  //     aElement.href = href;
  //     aElement.setAttribute("target", "_blank");
  //     aElement.click();
  //     URL.revokeObjectURL(href);
  //     setDownloading(null);
  //   } catch (error) {
  //     console.error("Download failed:", error);
  //     setDownloading(null);
  //   }
  // };

  const handleDownload = async (key, fileName) => {
    let token = user?.token;

    if (!token) {
      let { data } = await verifyAuth();
      token = data?.token;
      setUser({
        ...data.data,
        token,
      });
    }

    let { data: s3Data } = await axios.get(
      `https://sen319hxy8.execute-api.ap-south-1.amazonaws.com/dev/view?environment=development&signedUrl=true&key=${key}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      }
    );
    let { signedUrl } = s3Data;

    let { data } = await axios.get(signedUrl, {
      responseType: "blob",
      onDownloadProgress: (data) => {
        setDownloading(data?.progress * 100);
      },
    });

    const blob = new Blob([data], { type: data.type });

    const aElement = document.createElement("a");
    aElement.setAttribute("download", fileName);
    const href = URL.createObjectURL(blob);
    aElement.href = href;
    aElement.setAttribute("target", "_blank");

    // Add the 'await' keyword here to ensure the download is completed
    await aElement.click();
    URL.revokeObjectURL(href);
    setDownloading(null);
  };

  const handleGenerate = async () => {
    let finalArray = [];
    setLoading(true);
    try {
      for (let key in docs) {
        let items = docs[key];
        items?.map((file) => {
          if (file?.include) {
            finalArray.push(file);
          }
        });
      }

      if (finalArray?.length === 0) {
        toast.warn("NO FILE SELECTED");
        setLoading(false);
        return;
      }
      let { data } = await generateMufinPDF({
        application_id: updateDetails?.application_id,
        docs: finalArray,
      });

      // Check if the response status is true and there is base64 data
      if (data.status && data.key) {
        await handleDownload(
          data.key,
          `Combined-pdf-${updateDetails?.application_id}.pdf`
        );
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          error?.message ||
          "Something went wrong!"
      );
    }
    setLoading(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen}>Generate PDF</Button>
      <BootstrapDialog
        onClose={handleClose}
        fullWidth
        maxWidth={"md"}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Generate Mufin
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="px-3">
            {Object.keys(docs).map((key, docIndex, arr) => {
              let item = docs?.[key];
              let skip = false;
              const selectedCount =
                item?.[0]?.meta_page_document && item?.[0]?.include
                  ? 1
                  : item.filter((file) => file.include).length;

              // if()

              return (
                <Grid
                  container
                  spacing={1}
                  sx={
                    docIndex < arr.length - 1
                      ? { borderBottom: "1px solid #cfcfcf" }
                      : {}
                  }
                  className="py-3"
                >
                  {(groupDocs?.includes(key) ||
                    (!item?.[0]?.meta_page_document && item?.length > 1)) && (
                    <Grid item xs={12} className="pb-0">
                      <Typography
                        sx={{ color: "#1976d2", fontWeight: 500, fontSize: 12 }}
                      >
                        {(key || "")?.toUpperCase()?.replaceAll("_", " ")}
                      </Typography>
                    </Grid>
                  )}
                  {(item || [])?.map((file, i) => {
                    if (!skip) {
                      if (file?.meta_page_document) {
                        skip = true;
                      }
                      return (
                        <Grid item xs={4} className="pt-0">
                          <FormGroup>
                            <FormControlLabel
                              onChange={(e) => {
                                let stateArray = [...item];
                                if (file?.meta_page_document) {
                                  stateArray?.forEach((_, ind) => {
                                    stateArray[ind].include = e.target.checked;
                                  });
                                } else {
                                  stateArray[i].include = e.target.checked;
                                }
                                setDocs({
                                  ...docs,
                                  [key]: stateArray,
                                });
                              }}
                              control={<Checkbox checked={file?.include} />}
                              label={<div style={{ wordBreak: 'break-all' }}>{file?.name}</div>}
                            />
                          </FormGroup>
                        </Grid>
                      );
                    }
                  })}

                  {item?.length === 0 && (
                    <Grid item xs={12} className="pt-0">
                      {!groupDocs?.includes(key) ? (
                        <>
                          <FormGroup fullWidth>
                            <FormControlLabel
                              control={<Checkbox checked={false} disabled />}
                              label={
                                <>
                                  {key}
                                  <span
                                    style={{
                                      backgroundColor: "#cfcfcf",
                                      color: "white",
                                      fontSize: 10,
                                      padding: "5px 5px",
                                      borderRadius: "10px",
                                      marginLeft: 20,
                                    }}
                                  >
                                    No file
                                  </span>{" "}
                                </>
                              }
                            />
                          </FormGroup>
                        </>
                      ) : (
                        <>
                          <span
                            style={{
                              color: "#cfcfcf",
                              fontStyle: "italic",
                            }}
                          >
                            No documents
                          </span>
                        </>
                      )}
                    </Grid>
                  )}
                </Grid>
              );

              return (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ bgcolor: selectedCount === 0 ? "#fcd8d8" : "" }}
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={8}>
                        <Typography>
                          {(key || "")?.toUpperCase()?.replaceAll("_", " ")}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className="d-flex justify-content-end">
                        <Chip label={`Selected (${selectedCount})`} />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {(item || [])?.map((file, i) => {
                        if (!skip) {
                          if (file?.meta_page_document) {
                            skip = true;
                          }
                          return (
                            <Grid item xs={4}>
                              <FormGroup>
                                <FormControlLabel
                                  onChange={(e) => {
                                    let stateArray = [...item];
                                    if (file?.meta_page_document) {
                                      stateArray?.forEach((_, ind) => {
                                        stateArray[ind].include =
                                          e.target.checked;
                                      });
                                    } else {
                                      stateArray[i].include = e.target.checked;
                                    }
                                    setDocs({
                                      ...docs,
                                      [key]: stateArray,
                                    });
                                  }}
                                  control={<Checkbox checked={file?.include} />}
                                  label={file?.name}
                                />
                              </FormGroup>
                            </Grid>
                          );
                        }
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleGenerate}>
            {loading ? <>Generating...</> : "Generate"}
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <Dialog open={loading}>
        <div
          style={{
            padding: "50px",
            minWidth: 200,
            overflow: "hidden",
          }}
        >
          {downloading === null ? (
            <img src={combineGif} width={300} style={{ marginTop: -40 }} />
          ) : (
            <div className="content-center mb-3">
              <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress
                  variant="determinate"
                  value={downloading}
                  size={80}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    fontSize={25}
                  >
                    {`${Math.round(downloading)}%`}
                  </Typography>
                </Box>
              </Box>
            </div>
          )}
          <p
            style={{
              textAlign: "center",
              fontWeight: 500,
              fontSize: 20,
              margin: 0,
            }}
          >
            {downloading === null
              ? "Combining your files.."
              : "Downloading Combined PDF.."}
          </p>
        </div>
      </Dialog>
    </>
  );
}
