import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  Autocomplete,
  RadioGroup,
  Select,
  OutlinedInput,
  ListItemText,
} from "@mui/material";
import DatePickerComponent from "../../Components/DatePicker";
import { FormLabel } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalContext";
import style from "../CreateAnchorSteps/AnchorDetails.css";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { fetchAllNbfc, fetchAllOems, fetchSharedDealers } from "../../api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function BasicDetails({ setActiveStep }) {
  const { anchorState,selectedDealerState } = React.useContext(GlobalContext);
  const [anchorDetails, setAnchorDetails] = anchorState;
  const [selectedDealer, setSelectedDealer] = selectedDealerState;
  const [falseEmail, setFalseEmail] = useState(false);
  const [emailValidated, setEmailValidated] = useState(false);
  const [oemList, setOemList] = useState([]);
  const [nbfcList, setNbfcList] = useState([]);
  const [dealersList, setDealersList] = useState([]);
  const [oemID, setOemID] = useState("");

  // useEffect(() => {
  //   console.log(selectedDealer);
  // }, [selectedDealer]);

  const handleNbfcList = (event) => {
    const {
      target: { value },
    } = event;
    // console.log(value);

    // let obj = {}
    let temp_arr = [];
    setAnchorDetails({
      ...anchorDetails,
      // nbfc_ids: typeof value === 'string' ? value.split(',') : value,
      nbfc_ids: value,
    });
  };

  const handleOEMList = (event) => {
    const {
      target: { value },
    } = event;
    // console.log(value);

    // let obj = {}
    let temp_arr = [];
    setAnchorDetails({
      ...anchorDetails,
      // nbfc_ids: typeof value === 'string' ? value.split(',') : value,
      oem_ids: value,
    });
  };
//   useEffect(()=> {
//     console.log("anchorDetails.nbfc_ids",anchorDetails.nbfc_ids)
//       },[anchorDetails.nbfc_ids])

//   useEffect(()=> {
// console.log("anchorDetails.oem_ids",anchorDetails.oem_ids)
//   },[anchorDetails.oem_ids])

  const handleNumberChange = (prop) => (event) => {
    if (event.target.value !== "" && !/^\d+$/.test(event.target.value)) {
      return false;
    }
    setAnchorDetails({
      ...anchorDetails,
      [prop]: event.target.value,
    });
  };
  const emailValidation = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (regex.test(anchorDetails?.email) === false) {
      setFalseEmail(true);
      setAnchorDetails({
        ...anchorDetails,
        email_type: false,
      });
      // return false;
    } else {
      setEmailValidated(true);
      setFalseEmail(false);
      setAnchorDetails({
        ...anchorDetails,
        email_type: true,
      });
      // return true;
    }
  };

  const handleEmailChange = (event) => {
    // const value = event.target.value;
    const { value } = event.target;

    setAnchorDetails({ ...anchorDetails, email: value });
    // emailValidation(value);
  };

  useEffect(() => {
    if (anchorDetails?.email !== "") {
      emailValidation();
    }
  }, [anchorDetails?.email]);

  const handleChange = (prop) => (event) => {
    if (prop === "pan_number") {
      setAnchorDetails({
        ...anchorDetails,
        [prop]: event.target.value?.toUpperCase(),
        customer_name: "",
      });
    } else {
      setAnchorDetails({
        ...anchorDetails,
        [prop]: event.target.value,
      });
    }
  };

  const fetchOem = async () => {
    const { data } = await fetchAllOems();
    if (data?.status) {
      // console.log("fetch oem data: ", data?.data);
      setOemList(data?.data);
    }

    // let { data } = await fetchAllApplications();
    // let list = data?.data?.map((val) => {
    //   return {
    //     application_id: val?.oem_id,
    //     name: val?.company_name,
    //     //  !val?.customerData?.is_corporate
    //     //   ? val?.customerData?.pan_details?.name
    //     //   : val?.customerData?.corporate_pan_details?.name,
    //   };
    // });
    // setApplications(list);
  };

  const fetchNbfc = async () => {
    const { data } = await fetchAllNbfc();
    if (data?.status) {
      // console.log("fetch nbfc data: ", data?.data);
      setNbfcList(data?.data);
    }
  };

  const fetchDealers = async (oem_id) => {
    const { data } = await fetchSharedDealers(oem_id);
    // console.log(data);
    if (data?.status) {
      // console.log(data?.dealer_shared_data?.docs);
      let list = data?.dealer_shared_data?.docs?.map((val) => {
        return {
          _id: val?._id,
          dealership_name: val?.dealership_name,
          //  !val?.customerData?.is_corporate
          //   ? val?.customerData?.pan_details?.name
          //   : val?.customerData?.corporate_pan_details?.name,
        };
      });

      setDealersList(list);
    }
  };

  // useEffect(() => {
  //   console.log(dealersList);
  // }, [dealersList]);
  // useEffect(() => {
  //   console.log(selectedDealer);
  // }, [selectedDealer]);

  useEffect(() => {
    // setAnchorDetails({...anchorDetails, oem_ids: oemID})
    if (oemID !== "") {
      fetchDealers(oemID);
    }
  }, [oemID]);

//   useEffect(()=> {
// console.log('anchorDetails.oem_ids',anchorDetails.oem_ids)
//   },[anchorDetails.oem_ids])

  useEffect(() => {
    fetchOem();
    fetchNbfc();
  }, []);

  useEffect(() => {
    // console.log(anchorDetails?.oem_ids);
  }, [anchorDetails?.oem_ids]);

  return (
    <React.Fragment>
      {/* <Typography variant="h6" gutterBottom>
        Shipping address
      </Typography> */}
      {falseEmail ? (
        <InputLabel
          sx={{
            color: "red",
          }}
          required
          id="demo-simple-select-label"
        >
          Email is not valid
        </InputLabel>
      ) : (
        ""
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel required id="demo-simple-select-standard-label">
              Anchor Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              defaultValue={`${anchorDetails?.anchor_type}`}
              onChange={(e) => {
                setAnchorDetails({
                  ...anchorDetails,
                  anchor_type: e.target?.value,
                });
              }}
            >
              <MenuItem value={"OEM"}>OEM</MenuItem>
              <MenuItem value={"DEALER"}>Dealer</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {anchorDetails?.anchor_type === "OEM" ? (
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-standard-label">
                {anchorDetails?.anchor_type === "OEM" ? "OEM List" : null}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={anchorDetails?.oem_ids.oem_id}
                // defaultValue={`${anchorDetails?.anchor_type}`}
                onChange={(e) => {
                  // console.log(e.target.value)
                  let arr = anchorDetails?.oem_ids;
                  arr[0] = e.target.value
                  // console.log(arr)
                  setAnchorDetails({
                    ...anchorDetails,
                    oem_ids: arr,
                  });
                }}
              >
                {anchorDetails?.anchor_type === "OEM"
                  ? oemList?.map((value, index) => {
                      return (
                        <MenuItem value={value} key={index}>
                          {value?.company_name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
          </Grid>
        ) : anchorDetails?.anchor_type === "DEALER" ? (
          <Grid item xs={12} sm={6}>
            <FormControl
              size="small"
              sx={{
                mt: 1,
                width: "100%",
              }}
              //  variant="standard"
            >
              <InputLabel id="demo-simple-select-standard-label">
                Select OEM's
              </InputLabel>
              <Select
                  labelId="Select OEM's"
                  id="nbfc_list"
                  multiple
                  value={anchorDetails?.oem_ids}
                  // value={tempSelectedNbfc}
                  onChange={handleOEMList}
                  input={<OutlinedInput label="Select OEM's" />}
                  // renderValue={(selected) => selected.join(', ')}
                  renderValue={(selected) =>
                    selected?.map((edx, ind) => edx?.company_name).join(", ")
                  }
                  // MenuProps={MenuProps}
                >
                  {oemList?.map((value, index) => (
                    <MenuItem key={index} value={value}>
                      {/* <Checkbox checked={anchorDetails?.nbfc_ids?.indexOf(name?.company_name) > -1} /> */}
                      <ListItemText primary={value?.company_name} />
                    </MenuItem>
                  ))}
                </Select>

              {/* <Select
                size="small"
                label="Select OEM"
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={oemID}
                onChange={(e) => {
                  setOemID(e.target.value);
                }}
              >
                <MenuItem value={""}>None</MenuItem>
                {oemList?.map((value, index) => {
                  return (
                    <MenuItem value={value?.oem_id} key={index}>
                      {value?.company_name}
                    </MenuItem>
                  );
                })}
              </Select> */}
            </FormControl>
          </Grid>
        ) : null}

        {anchorDetails?.anchor_type ? (
          <>
            <Grid item xs={12} sm={6}>
              <FormControl size="small" fullWidth>
                <InputLabel id="nbfc_list">Select NBFC</InputLabel>
                <Select
                  labelId="nbfc_list"
                  id="nbfc_list"
                  multiple
                  value={anchorDetails?.nbfc_ids}
                  // value={tempSelectedNbfc}
                  onChange={handleNbfcList}
                  input={<OutlinedInput label="Select NBFC" />}
                  // renderValue={(selected) => selected.join(', ')}
                  renderValue={(selected) =>
                    selected?.map((edx, ind) => edx?.name).join(", ")
                  }
                  // MenuProps={MenuProps}
                >
                  {nbfcList?.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      {/* <Checkbox checked={anchorDetails?.nbfc_ids?.indexOf(name?.company_name) > -1} /> */}
                      <ListItemText primary={name?.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {dealersList.length !== 0 ? (
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  options={dealersList}
                  getOptionLabel={(option) =>
                    `${option?.dealership_name} - ${option?._id}`
                  }
                  id="ApplicationId"
                  clearOnBlur
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Dealer Id"
                      variant="standard"
                    />
                  )}
                  onChange={(event, value) => {
                    setSelectedDealer(value?._id);
                    setAnchorDetails({
                      ...anchorDetails,
                      anchor_name: value?.dealership_name,
                    });
                    // navigate(`/view-application/${value?.application_id}`);
                  }}
                />
              </Grid>
            ) : null}
          </>
        ) : null}

        <>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="name"
              name="name"
              label="Anchor Name"
              fullWidth
              disabled={selectedDealer !== "" ? true : false}
              autoComplete="family-name"
              variant="standard"
              value={anchorDetails?.anchor_name}
              onChange={handleChange("anchor_name")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              // required
              id="email"
              name="email"
              label="Email"
              fullWidth
              autoComplete="email"
              variant="standard"
              value={anchorDetails?.email}
              onChange={handleEmailChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              // required
              id="gstin"
              name="gstin"
              // type={"number"}
              label="Anchor GSTIN"
              fullWidth
              // autoComplete="email"
              variant="standard"
              value={anchorDetails?.anchor_gstin}
              onChange={handleChange("anchor_gstin")}
            />
          </Grid>
          <Grid sx={{ display: "flex" }} item xs={12} sm={12}>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Anchor Fee Share as (% / number) of processing Fees
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  // defaultValue={anchorDetails?.processing_fees_type}
                  onChange={(e) => {
                    let value = e.target.value;
                    setAnchorDetails({
                      ...anchorDetails,
                      processing_fees_type: value,
                    });
                  }}
                >
                  <FormControlLabel value="%" control={<Radio />} label="%" />
                  <FormControlLabel
                    value="number"
                    control={<Radio />}
                    label="Number"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {anchorDetails?.processing_fees_type !== "" && (
              <Grid
                sx={{
                  marginTop: "40px",
                }}
                item
                xs={12}
                sm={6}
              >
                <TextField
                  // required
                  id="processing_fees"
                  name="processing_fees"
                  type="number"
                  label={anchorDetails?.processing_fees_type}
                  fullWidth
                  // autoComplete="email"
                  variant="standard"
                  value={anchorDetails?.processing_fees}
                  onChange={(e) => {
                    if (
                      anchorDetails?.processing_fees_type === "%" &&
                      e.target.value.length >= 3
                    ) {
                      return;
                    } else if (
                      anchorDetails?.processing_fees_type === "number" &&
                      e.target.value.length > 10
                    ) {
                      return;
                    } else {
                      setAnchorDetails({
                        ...anchorDetails,
                        processing_fees: e.target.value?.toUpperCase(),
                      });
                      // handleChange("processing_fees");
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid sx={{ display: "flex" }} item xs={12} sm={12}>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Anchor Fee Share as (% / number) of Interest Rate
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  // defaultValue={anchorDetails?.processing_fees_type}
                  onChange={(e) => {
                    let value = e.target.value;
                    // console.log(anchorDetails.processing_fees_type);
                    setAnchorDetails({
                      ...anchorDetails,
                      interest_rate_type: value,
                    });
                  }}
                >
                  <FormControlLabel value="%" control={<Radio />} label="%" />
                  <FormControlLabel
                    value="number"
                    control={<Radio />}
                    label="Number"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {anchorDetails?.interest_rate_type !== "" && (
              <Grid
                sx={{
                  marginTop: "40px",
                }}
                item
                xs={12}
                sm={6}
              >
                <TextField
                  // required
                  id="interest_rate"
                  name="interest_rate"
                  type="number"
                  label={anchorDetails?.interest_rate_type}
                  fullWidth
                  // autoComplete="email"
                  variant="standard"
                  value={anchorDetails?.interest_rate}
                  onChange={(e) => {
                    if (
                      anchorDetails?.interest_rate_type === "%" &&
                      e.target.value.length >= 3
                    ) {
                      return;
                    } else if (
                      anchorDetails?.interest_rate_type === "number" &&
                      e.target.value.length > 10
                    ) {
                      return;
                    } else {
                      setAnchorDetails({
                        ...anchorDetails,
                        interest_rate: e.target.value?.toUpperCase(),
                      });
                      // handleChange("processing_fees");
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid sx={{ display: "flex" }} item xs={12} sm={12}>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Anchor Fee Share as (% / number) of Penalty Fees
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  // defaultValue={anchorDetails?.processing_fees_type}
                  onChange={(e) => {
                    let value = e.target.value;
                    // console.log(anchorDetails.processing_fees_type);
                    setAnchorDetails({
                      ...anchorDetails,
                      penalty_fees_type: value,
                    });
                  }}
                >
                  <FormControlLabel value="%" control={<Radio />} label="%" />
                  <FormControlLabel
                    value="number"
                    control={<Radio />}
                    label="Number"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {anchorDetails?.penalty_fees_type !== "" && (
              <Grid
                sx={{
                  marginTop: "40px",
                }}
                item
                xs={12}
                sm={6}
              >
                <TextField
                  // required
                  id="penalty_fees"
                  name="penalty_fees"
                  type="number"
                  label={anchorDetails?.penalty_fees_type}
                  fullWidth
                  // autoComplete="email"
                  variant="standard"
                  value={anchorDetails?.penalty_fees}
                  onChange={(e) => {
                    if (
                      anchorDetails?.penalty_fees_type === "%" &&
                      e.target.value.length >= 3
                    ) {
                      return;
                    } else if (
                      anchorDetails?.penalty_fees_type === "number" &&
                      e.target.value.length > 10
                    ) {
                      return;
                    } else {
                      setAnchorDetails({
                        ...anchorDetails,
                        penalty_fees: e.target.value?.toUpperCase(),
                      });
                      // handleChange("processing_fees");
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid sx={{ display: "flex" }} item xs={12} sm={12}>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Anchor Fee Share as (% / number) of Penalty rate
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  // defaultValue={anchorDetails?.processing_fees_type}
                  onChange={(e) => {
                    let value = e.target.value;
                    // console.log(anchorDetails.processing_fees_type);
                    setAnchorDetails({
                      ...anchorDetails,
                      penalty_rate_type: value,
                    });
                  }}
                >
                  <FormControlLabel value="%" control={<Radio />} label="%" />
                  <FormControlLabel
                    value="number"
                    control={<Radio />}
                    label="Number"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {anchorDetails?.penalty_rate_type !== "" && (
              <Grid
                sx={{
                  marginTop: "40px",
                }}
                item
                xs={12}
                sm={6}
              >
                <TextField
                  // required
                  id="penalty_rate"
                  name="penalty_rate"
                  type="number"
                  label={anchorDetails?.penalty_rate_type}
                  fullWidth
                  // autoComplete="email"
                  variant="standard"
                  value={anchorDetails?.penalty_rate}
                  onChange={(e) => {
                    if (
                      anchorDetails?.penalty_rate_type === "%" &&
                      e.target.value.length >= 3
                    ) {
                      return;
                    } else if (
                      anchorDetails?.penalty_rate_type === "number" &&
                      e.target.value.length > 10
                    ) {
                      return;
                    } else {
                      setAnchorDetails({
                        ...anchorDetails,
                        penalty_rate: e.target.value?.toUpperCase(),
                      });
                      // handleChange("processing_fees");
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputLabel id="demo-simple-select-standard-label">
              Manager Details
            </InputLabel>
            <TextField
              // required
              sx={{
                marginTop: "12px",
              }}
              id="manager_name"
              name="manager_name"
              label="Anchor Relationship Manager Name"
              fullWidth
              autoComplete="family-name"
              variant="standard"
              value={anchorDetails?.manager_name}
              onChange={handleChange("manager_name")}
            />
            <TextField
              sx={{
                marginTop: "12px",
              }}
              // required
              id="manager_mail_id"
              name="manager_mail_id"
              label="Anchor Relationship Manager EMail_ID
              "
              fullWidth
              autoComplete="email"
              variant="standard"
              value={anchorDetails?.manager_mail_id}
              onChange={handleChange("manager_mail_id")}
            />
            <TextField
              sx={{
                marginTop: "12px",
              }}
              // required
              id="manager_mobile_number"
              name="manager_mobile_number"
              type="number"
              label="Anchor Relationship Manager Mobile Number
              "
              fullWidth
              // autoComplete="email"
              variant="standard"
              value={anchorDetails?.manager_mobile_number}
              onChange={(e) => {
                if (e.target.value.length > 10) {
                  return;
                } else {
                  setAnchorDetails({
                    ...anchorDetails,
                    manager_mobile_number: e.target.value?.toUpperCase(),
                  });
                }
              }}
              // onChange={handleChange("manager_mobile_number")}
            />
          </Grid>
        </>
      </Grid>
    </React.Fragment>
  );
}
