import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  CssBaseline,
  TextField,
  FormControl,
  MenuItem,
  Typography,
  Select,
  Grid,
  InputLabel,
  Box,
  Container,
} from "@mui/material";
import { createUser, fetchAllNbfc, fetchAllOems } from "../api";
import { toast } from "react-toastify";
import CsvJson from "./CsvJson.js";
import Paper from "@mui/material/Paper";
import AddIco from "../assets/add.svg";

function CreateUser() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [cred, setCred] = useState({
    user_type: "",
    email: "",
    name: "",
    dob: "",
    user_role: 2,
    // sub_user_type: "",
  });
  const [falseEmail, setFalseEmail] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [oemList, setOemList] = useState([]);
  const [nbfcList, setNbfcList] = useState([]);

  useEffect(() => {
    if (cred?.user_type === "EMBIFI") {
      setCred({
        ...cred,
        role: "2",
      });
    } else if (cred?.user_type === "NBFC") {
      setCred({
        ...cred,
        role: "3",
      });
    } else if (cred?.user_type === "OEM") {
      setCred({
        ...cred,
        role: "4",
      });
    } else if (cred?.user_type === "MANAGER") {
      setCred({
        ...cred,
        role: "5",
      });
    }
  }, [cred?.user_type]);

  const emailValidation = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(cred.email) === false) {
      setFalseEmail(true);
      return false;
    } else {
      setFalseEmail(false);
      return true;
    }
  };

  useEffect(() => {
    if (cred?.email !== "") {
      emailValidation();
    }
  }, [cred?.email]);

  const handleCreateUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    let payload = {
      ...cred,
    };
    let response = await createUser(payload);
    if (!response.status) {
      toast.error(response?.response?.data?.message || "Something went wrong");
      return setLoading(false);
    }
    toast.success(response?.data?.message || "Updated Password Successfully");
    setUserCreated(true);
    return setLoading(false);
  };

  const fetchOem = async () => {
    const { data } = await fetchAllOems();
    if (data?.status) {
      setOemList(data?.data);
    }
  };

  const fetchNbfc = async () => {
    const { data } = await fetchAllNbfc();
    if (data?.status) {
      setNbfcList(data?.data);
    }
  };

  useEffect(() => {
    fetchOem();
    fetchNbfc();
  }, []);

  return (
    <>
      <p
        className="go-back-btn-fixed"
        onClick={() => {
          if (state?.path) {
            navigate(state?.path);
          } else {
            navigate("/tools");
          }
        }}
      >
        <ArrowBackIcon /> Go back Home
      </p>
      <Container
        sx={{
          padding: "43px",
        }}
        component="main"
        maxWidth="sm"
      >
        <div
          style={{
            marginTop: "20px",
          }}
          className="page-head"
        >
          <img src={AddIco} width={"30px"} />
          <span style={{ marginLeft: "10px", color: "gray" }}>Create User</span>
        </div>
        <Paper
          variant="outlined"
          sx={{
            my: { xs: 3, md: 3 },
            p: { xs: 2, md: 3 },
            filter:
              "drop-shadow(6px 6px 30px rgba(125, 153, 180, 0.2)) drop-shadow(2px 2px 4px rgba(86, 144, 198, 0.11))",
          }}
        >
          <CssBaseline />

          <Box
            sx={{
              // marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {falseEmail && (
              <InputLabel
                sx={{
                  color: "red",
                }}
                required
                id="demo-simple-select-label"
              >
                Email is not valid
              </InputLabel>
            )}
            {userCreated ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  User Created Successfully.
                </Typography>
                <Button
                  className="mt-3 px-5"
                  variant="outlined"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  DONE
                </Button>
              </React.Fragment>
            ) : (
              <Box
                component="form"
                noValidate
                // onSubmit={handleSubmit}
                sx={{
                  mt: 5,
                  pb: 3,
                  padding: "40px",
                  pt: 2,
                  //   px: 3,
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        User Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={cred?.user_type}
                        // defaultValue={`${anchorDetails?.anchor_type}`}
                        onChange={(e) => {
                          setCred({
                            ...cred,
                            user_type: e.target?.value,
                          });
                        }}
                      >
                        <MenuItem value={"EMBIFI"}>EMBIFI</MenuItem>
                        <MenuItem value={"NBFC"}>NBFC</MenuItem>
                        <MenuItem value={"OEM"}>OEM</MenuItem>
                        <MenuItem value={"MANAGER"}>MANAGER</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {cred?.user_type && !["EMBIFI","MANAGER"].includes(cred?.user_type) ? (
                    <Grid item xs={12} sm={12}>
                      <FormControl variant="standard" sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          {cred?.user_type === "NBFC"
                            ? "NBFC List"
                            : cred?.user_type === "OEM"
                            ? "OEM List"
                            : "Type"}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={cred?.[cred?.user_type.toLowerCase()]}
                          // defaultValue={`${anchorDetails?.anchor_type}`}
                          onChange={(e) => {
                            setCred({
                              ...cred,
                              [cred?.user_type.toLowerCase()]: e.target?.value,
                            });
                          }}
                        >
                          {cred?.user_type === "NBFC"
                            ? nbfcList?.map((value, index) => {
                                return (
                                  <MenuItem value={value?._id} key={index}>
                                    {value?.name}
                                  </MenuItem>
                                );
                              })
                            : cred?.user_type === "OEM"
                            ? oemList?.map((value, index) => {
                                return (
                                  <MenuItem value={value?.oem_id} key={index}>
                                    {value?.company_name}
                                  </MenuItem>
                                );
                              })
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}

                  {/* {cred.user_type === "NBFC" && (
                  <Grid item xs={12} sm={12}>
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        NBFC Name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        // defaultValue={`${anchorDetails?.anchor_type}`}
                        onChange={(e) => {
                          setCred({
                            ...cred,
                            user_type: e.target?.value,
                          });
                        }}
                      >
                        <MenuItem value={"EMBIFI"}>EMBIFI</MenuItem>
                        <MenuItem value={"NBFC"}>NBFC</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )} */}

                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: {
                        sm: "row",
                        xs: "column",
                      },
                      gap: {
                        sm: "35px",
                        xs: "0px",
                      },
                    }}
                  >
                    <TextField
                      size="small"
                      required
                      id="text"
                      label="Name"
                      name="name"
                      value={cred?.name}
                      onChange={(e) => {
                        setCred({ ...cred, name: e.target.value });
                      }}
                      sx={{
                        mt: 2,
                      }}
                    />

                    <TextField
                      size="small"
                      required
                      sx={{ mt: 2 }}
                      id="email"
                      label="Email Id"
                      name="email"
                      autoComplete="email"
                      value={cred?.email}
                      onChange={(e) => {
                        setCred({ ...cred, email: e.target.value });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      size="small"
                      id="date"
                      label="Birthday"
                      type="date"
                      value={cred?.dob}
                      onChange={(e) => {
                        setCred({ ...cred, dob: e.target.value });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                {!isLoading ? (
                  <Button
                    type="submit"
                    disabled={
                      cred?.email && cred?.name && cred?.dob && cred?.user_type
                        ? false
                        : true
                    }
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 5,
                      mb: 2,
                      py: 2,

                      borderRadius: "10px",
                      backgroundColor: "#6C757D",
                      ":hover": {
                        bgcolor: "#6F32BE",
                        color: "white",
                      },
                    }}
                    onClick={handleCreateUser}
                  >
                    Create user
                    {/* {user?.loading ? "Loading" : "Create user"} */}
                  </Button>
                ) : (
                  <p className="w-100 text-center" style={{ color: "purple" }}>
                    Creating user....
                  </p>
                )}
              </Box>
            )}
          </Box>
        </Paper>
      </Container>
      {/* <CsvJson /> */}
    </>
  );
}

export default CreateUser;
