import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DateRangePicker from '../DateRangePicker';

const CibilDashboardFilter = ({ open, handleClose, onApply, setFilter, initialFilters, clearFilter, setClearFilter }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [filters, setFilters] = useState([{ type: '', value: '' }]);

  const handleFilterTypeChange = (index, event) => {
    const updatedFilters = [...filters];
    updatedFilters[index].type = event.target.value;
    updatedFilters[index].value = '';
    setFilters(updatedFilters);
  };

  const handleFilterValueChange = (index, value) => {
    const updatedFilters = [...filters];
    if (filters[index].type === 'requestDate' && value) {      
      const startTimestamp = value.start ? Math.floor(new Date(value.start).getTime()/1000) : null;
      const endTimestamp = value.end ? Math.floor(new Date(value.end).getTime()/1000) : null;
      updatedFilters[index].value = { start: startTimestamp, end: endTimestamp };
    } else {
      updatedFilters[index].value = value;
    }
    setFilters(updatedFilters);
  };

  const addFilter = () => {
    setFilters([...filters, { type: '', value: '' }]);
  };

  const handleApply = () => {
    const payload = filters.reduce((acc, filter) => {
      if (filter.type) {
        if (filter.type === 'requestDate') {
          // Convert timestamps back to milliseconds if needed
          acc[filter.type] = filter.value ? { 
            start: filter.value.start ? filter.value.start * 1000 : null, 
            end: filter.value.end ? filter.value.end * 1000 : null 
          } : null;
        } else {
          acc[filter.type] = filter.value === 'awaiting' ? 'awaiting' : filter.value;
        }
      }
      return acc;
    }, {});
    console.log("payload",payload);
    onApply(payload);
    setFilter(true);
    handleClose();
  };
  
  // useEffect(() => {
  //   if (Array.isArray(initialFilters) && initialFilters.length > 0) {
  //     setFilters(initialFilters);
  //   } else {
  //     setFilters([{ type: '', value: '' }]); 
  //   }
  // }, [initialFilters]);

  useEffect(() => {
    if (clearFilter) {
      setFilters([{ type: '', value: '' }]);
    }
  }, [clearFilter]);

  
  const dialogPosition = filters.some(filter => filter.type === 'requestDate') ? { right: '100px', bottom: '150px' } : {};

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '& .MuiDialog-paper': {
            width: '750px',
            maxWidth: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            zIndex: 100,
            overflow: "visible",
            ...dialogPosition,
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">Filter Options</DialogTitle>
        <DialogContent style={{
          overflow: "visible"
        }}>
          {filters.map((filter, index) => (
            <Box
              key={index}
              sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 2 }}
            >
              <FormControl fullWidth sx={{ flex: 1 }}>
                <InputLabel>Filter Type</InputLabel>
                <Select
                  value={filter.type}
                  onChange={(e) => handleFilterTypeChange(index, e)}
                  label="Filter Type"
                >
                  <MenuItem value="customerStatus">Customer Status</MenuItem>
                  <MenuItem value="coCustomerStatus">Co-Customer Status</MenuItem>
                  <MenuItem value="requestDate">Request Date</MenuItem>
                </Select>
              </FormControl>

              {filter.type === 'customerStatus' || filter.type === 'coCustomerStatus' ? (
                <FormControl fullWidth sx={{ flex: 1 }}>
                  <InputLabel>Filter Value</InputLabel>
                  <Select
                    value={filter.value}
                    onChange={(e) => handleFilterValueChange(index, e.target.value)}
                    label="Filter Value"
                  >
                    <MenuItem value="true">Approved</MenuItem>
                    <MenuItem value="false">Rejected</MenuItem>
                    <MenuItem value="awaiting">Awaiting</MenuItem>
                  </Select>
                </FormControl>
              ) : filter.type === 'requestDate' ? (
                <Box sx={{ flex: 1, width: '300px' }}>
                  <DateRangePicker
                    className="custom-date-picker-popper"
                    onChange={(start, end) => handleFilterValueChange(index, { start, end })}
                    startDate={filter.value?.start ? new Date(filter.value.start * 1000) : null}
                    endDate={filter.value?.end ? new Date(filter.value.end * 1000) : null}
                  />
                </Box>
              ) : null}
            </Box>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Button variant="outlined" onClick={addFilter}>
              Add More
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
          <Button onClick={handleApply} variant="contained">
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CibilDashboardFilter;
