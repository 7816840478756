import { Button, Dialog, FormControl, MenuItem, Select } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { fCollectionStatus } from "../../utility/Formater";
import { statgeMap } from "../../utility/Stages";
import DateRangePicker from "../DateRangePicker";

const TransactionScheduleExport = ({
  open,
  handleClose,
  collectionData: initalState,
  onApply,
  applicationId,
}) => {

  const [collectionData, setCollectionData] = useState(initalState);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [option, setOption] = useState("transaction_date");

  

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleRangeChange = () => {
    let Filtered = initalState.filter((item) => {
      return (
        moment(item?.trxn_dateMS).isBetween(startDate, endDate) ||
        moment(item?.trxn_dateMS).isSame(startDate) ||
        moment(item?.trxn_dateMS).isSame(endDate)
      );
    });
    setCollectionData(Filtered);
  };

  useEffect(() => {
    // console.log(new Date(initalState?.[0]?.due_date));
    // console.log(new Date(initalState?.slice(-1)[0]?.due_date));
    setStartDate(new Date(initalState?.[0]?.trxn_dateMS));
    setEndDate(new Date(initalState?.[0]?.trxn_dateMS));
    setCollectionData(initalState);
  }, [initalState]);

  useEffect(() => {
    handleRangeChange();
  }, [startDate, endDate]);

  useEffect(() => {
    if (!open) {
      setCollectionData(initalState);
      setOption("transaction_date");
    }
  }, [open]);

  return (
    // <Dialog
    //   open={open || true}
    //   onClose={handleClose}
    //   PaperProps={{
    //     style: {
    //       maxHeight: 'none',
    //       maxWidth: 'none',
    //     },
    //   }}
    // >
    //   <div style={{width:'auto'}}>

    //   <DateRangePicker />
    //   </div>
    // </Dialog>

    <Modal
      show={open}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <FormControl fullWidth className="mb-2">
          <Select
            value={option}
            // onChange={handleCh
            onChange={(e) => {
              setOption(e.target.value);
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {/* <MenuItem value={1}>Full Data</MenuItem> */}
            <MenuItem value={"transaction_date"}>Transaction Date</MenuItem>
          </Select>
        </FormControl>

        {option === "transaction_date" && (
          <DateRangePicker
            // minDate={new Date(initalState?.[0]?.trxn_dateMS)}
            // maxDate={new Date(initalState?.slice(-1)[0]?.trxn_dateMS)}
            startDate={startDate}
            endDate={endDate}
            onChange={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={()=>{
          onApply(collectionData)
          handleClose()
        }}>Apply</Button>
        {/* <Button
          onClick={handleDownload}
          disabled={
            option === 2
              ? [null, "", undefined].includes(startDate) ||
                [null, "", undefined].includes(endDate)
              : false
          }
        >
          Export
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default TransactionScheduleExport;
