// import { getCode, getName } from "country-list";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { useEffect, useState } from "react";

export default function SelectCity({ c, index, address, setAddress }) {

    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between"
        }}>
            <div
                style={{
                    width: "30%"
                }}>
                <Select
                    placeholder="country"
                    options={Country.getAllCountries()}
                    getOptionLabel={(options) => {
                        return options["name"];
                    }}
                    getOptionValue={(options) => {
                        return options["name"];
                    }}
                    value={c?.country}
                    onChange={(item) => {
                        let temp = [...address];
                        temp[index].country = item;
                        temp[index].state = "";
                        temp[index].city = "";

                        setAddress(temp);
                    }
                    }
                />
            </div>
            <div
                style={{
                    width: "30%"
                }}>
                <Select
                    placeholder="state"
                    options={State?.getStatesOfCountry(c?.country?.isoCode)}
                    getOptionLabel={(options) => {
                        return options["name"];
                    }}
                    getOptionValue={(options) => {
                        return options["name"];
                    }}
                    value={c?.state}
                    onChange={(item) => {
                        let temp = [...address];
                        temp[index].state = item;
                        temp[index].city = ""; 
                        setAddress(temp);
                    }}
                />
            </div>
            <div
                style={{
                    width: "30%"
                }}>
                <Select
                    placeholder="city"
                    options={City.getCitiesOfState(
                        c?.state?.countryCode,
                        c?.state?.isoCode
                    )}
                    getOptionLabel={(options) => {
                        return options["name"];
                    }}
                    getOptionValue={(options) => {
                        return options["name"];
                    }}
                    value={c?.city}
                    onChange={(item) => {
                        let temp = [...address];
                        temp[index].city = item;
                        setAddress(temp);
                    }}
                />
            </div>
        </div>
    );
}
