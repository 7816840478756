import React from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

const colMap = {
  lessThanFiveDays: "<=5 days",
  fiveToTenDays: "6-10 days",
  elevenToFifteenDays: "11-15 days",
  sixteenToThirtyDays: "16-30 days",
  greaterThanThirtyDays: ">30 days",
};

const FileStatusTable = ({ fileData, loader }) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#D8E0FF" }}>
            <TableRow>
              <TableCell>Age</TableCell>
              <TableCell align="center">Credit Check done</TableCell>
              <TableCell align="center">{">50% document received"}</TableCell>
              <TableCell align="center">send to NBFC</TableCell>
              <TableCell align="right">
                <b>Total</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(fileData?.data || {}).map((key) => {
              let row = fileData?.data?.[key];
              return (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {colMap?.[key]}
                  </TableCell>
                  <TableCell align="center">{row?.creditCheckDone}</TableCell>
                  <TableCell align="center">{row?.documentsReceived}</TableCell>
                  <TableCell align="center">{row?.sendToNbfc}</TableCell>
                  <TableCell align="right">
                    <b>{row?.total}</b>
                  </TableCell>
                </TableRow>
              );
            })}
            {fileData !== null && (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                    fontWeight: 600,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  Total
                </TableCell>
                <TableCell align="center">
                  {fileData?.total_data?.TotalCreditCheckDoneApplications}
                </TableCell>
                <TableCell align="center">
                  {fileData?.total_data?.TotalDocumentsReceivedApplications}
                </TableCell>
                <TableCell align="center">
                  {fileData?.total_data?.TotalSendToNbfcApplications}
                </TableCell>
                <TableCell align="right">
                  <b>{fileData?.total_data?.TotalApplications}</b>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {loader === "FILE" && (
        <p
          className="text-center w-100 mt-5"
          style={{
            fontSize: "15px",
            color: "black",
          }}
        >
          <CircularProgress size={50} />
        </p>
      )}
      {loader === null && fileData === null && (
        <p
          className="text-center w-100 mt-5"
          style={{
            fontSize: "15px",
            color: "black",
          }}
        >
          <DoNotDisturbIcon sx={{ fontSize: 15, mr: 0.3 }} /> No Data to display
        </p>
      )}
    </>
  );
};

export default FileStatusTable;
