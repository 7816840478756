import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { fetchExportData } from "../../api";
import { toast } from "react-toastify";
import { CircularProgress, TextField } from "@material-ui/core";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton } from "@mui/material";
import { UserContext } from "../../Context/UserContext";
import { useContext } from "react";
import moment from "moment";
import { sampleCollectionApplications } from "../../utility/sampleData"

const buttonStyle = {
  fontSize: "14px",
  color: "1px solid skyblue",
  padding: "15px",
  borderRadius: 2,
};

export default function DownloadTable({
  open,
  handleClose,
  type,
  filterData,
  isOutstanding,
}) {
  const [fileType, setFileType] = useState("csv");
  const [finalData, setFinalData] = useState([]);
  const [isLoading, setLoading] = useState(null);
  const [osDate, setOsDate] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const { user } = useContext(UserContext);

  const handleDownload = async (filter) => {
    setLoading(filter);
    let timer,
      timeLeft = 15;

    if (isOutstanding) {
      timer = setInterval(() => {
        if (timeLeft > 0) {
          timeLeft = timeLeft - 1;
        } else {
          clearInterval(timer);
          setShowAlert(true);
        }
      }, 1000);
    }

    if(type === "loan-applications" && user?.isUserPresenter){
      filterData = JSON.stringify({
        ...(filterData || {}),
        application_start_date:{"from":moment().subtract(7,"days").valueOf(),"to":Date.now()}
      })
    }

    if(type === "collections" && user?.isUserPresenter){
      filterData = JSON.stringify({
        application_id:sampleCollectionApplications,
        ...(filterData || {}),
      })
    }

    try {
      let query = `/${isOutstanding ? "outstanding" : type}?${
        fileType === "json" ? "json=true" : ""
      }`;
      if (filter === "download_all" && type === "loan-applications") {
        query = query + "&doc=true" + (user?.isUserPresenter ? 
          `&filters=${encodeURIComponent(JSON.stringify(JSON.parse(filterData)).toString())}` 
          :"");
      }else if(filter === "download_all" && type === "collections" && user?.isUserPresenter){
        query = query + `&filters=${encodeURIComponent(JSON.stringify(JSON.parse(filterData)).toString())}` ;
      }else if (filter === "download_with_current_filter") {
        if (filterData) {
          query =
            query +
            `&filters=${encodeURIComponent(
              JSON.stringify(JSON.parse(filterData)).toString()
            )}`;
        } else {
          toast.warn("No filter applied");
          setLoading(null);
          return false;
        }
      }

      if (isOutstanding && osDate) {
        query = query + `&os_date=${new Date(osDate).getTime()}`;
      }

      let { data } = await fetchExportData(query);

      if (isOutstanding) {
        clearInterval(timer);
        setShowAlert(false);
      }

      const blob =
        fileType === "csv"
          ? new Blob([data], { type: "text/csv" })
          : new Blob([JSON.stringify(data?.data)], {
              type: "application/json",
            });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${isOutstanding ? "outstanding" : type}${osDate ? `_${moment(osDate).format("DD/MM/yyyy")}` : ""}.${fileType}`;
      a.click();
      window.URL.revokeObjectURL(url);

      
    } catch (error) {
      if (isOutstanding) {
        clearInterval(timer);
      }
      if (error.code === "ECONNABORTED" && isOutstanding) {
        toast.warn(
          `Operation taking too much time. Please check your mail (${user?.email}) after some time`
        );
      } else {
        toast.error(
          error?.response?.data?.message ||
            error?.message ||
            "Operation failed!"
        );
      }
    }
    setLoading(null);
  };

  return (
    <div>
      <Dialog
        sx={{
          filter:
            "drop-shadow(6px 6px 30px rgba(125, 153, 180, 0.2)) drop-shadow(2px 2px 4px rgba(86, 144, 198, 0.11))",
          border: "none",
          outline: "none",
          borderRadius: "10px",
        }}
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <p className="text-center mb-0" style={{ fontSize: 15 }}>
            Export {type?.replace("-", " ")}
          </p>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box
          // sx={style}
          >
            <Grid container spacing={2}>
              {showAlert && (
                <Grid xs={12} item className="px-4">
                  <Alert severity="info" onClose={() => setShowAlert(false)}>
                    This operation can take some time. You can wait here or
                    check your mail ({user?.email}) after 2-5 minutes
                  </Alert>
                </Grid>
              )}
              {isOutstanding && (
                <Grid xs={12} item className="px-4">
                  <p style={{ fontSize: 13, color: "gray" }}>
                    Outstanding amount date
                  </p>
                  <TextField
                    fullWidth
                    type="date"
                    value={osDate}
                    variant="outlined"
                    onChange={(e) => {
                      setOsDate(e.target.value);
                    }}
                  />
                  <hr />
                </Grid>
              )}
              <Grid xs={12} item className="px-4">
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ ...buttonStyle }}
                  onClick={() => handleDownload("download_all")}
                  color="inherit"
                  disabled={isLoading !== null || (isOutstanding && !osDate)}
                >
                  {isLoading === "download_all" ? (
                    <>
                      <CircularProgress size={18} />{" "}
                      <span className="ms-2">Downloading...</span>
                    </>
                  ) : (
                    <>
                      <FileDownloadOutlinedIcon />{" "}
                      <span className="ms-2">Download All</span>
                    </>
                  )}
                </Button>
              </Grid>
              {!isOutstanding && (
                <Grid xs={12} item className="px-4">
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{ ...buttonStyle }}
                    onClick={() =>
                      handleDownload("download_with_current_filter")
                    }
                    color="inherit"
                    disabled={isLoading !== null || (isOutstanding && !osDate)}
                  >
                    {isLoading === "download_with_current_filter" ? (
                      <>
                        <CircularProgress size={18} />{" "}
                        <span className="ms-2">Downloading...</span>
                      </>
                    ) : (
                      <>
                        <FilterAltOutlinedIcon />{" "}
                        <span className="ms-2">Download with filter</span>
                      </>
                    )}
                  </Button>
                </Grid>
              )}
            </Grid>

            {/* <>
              <p>Select download format</p>
              <Button
                sx={{
                  ...buttonStyle,
                  border:
                    fileType === "csv"
                      ? "3px solid #1976d2"
                      : "1px solid #1976d2",
                }}
                color="inherit"
                onClick={() => {
                  setFileType("csv");
                }}
              >
                CSV
              </Button>
              <Button
                sx={{
                  ...buttonStyle,
                  border:
                    fileType === "json"
                      ? "3px solid #1976d2"
                      : "1px solid #1976d2",
                }}
                color="inherit"
                onClick={() => {
                  setFileType("json");
                }}
              >
                Json
              </Button>
            </> */}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
