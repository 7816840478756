import CloseIcon from "@mui/icons-material/Close";
import { FormControl, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import React, { forwardRef, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { fetchOtherCollections } from "../../api";
import DateRangePicker from "../DateRangePicker";
import AutocompleteSelect from "./AutoCompleteSelect";
import { LoadingSkeleton } from "./LoadingSkeleton";

const OtherCollectionsFilter = forwardRef(
  ({ open, handleClose, onApply, options, name, filterApplied }, ref) => {
    const columns = {
      field: "",
      value: [],
      options,
    };

    const initialState = [{ ...columns }];

    const [filterDataValue, setFilterDataValue] = useState([]);
    const [filterData, setFilterData] = useState(initialState);
    const [selectData, setSelectData] = useState([]);
    const [reduceData, setReduceData] = useState([]);
    const [doDate, setDoDate] = useState(null);
    const [dates, setDates] = useState({
      disbursed_date: null,
      do_uploaded_date: null,
    });
    const [loading, setLoading] = useState(false);

    function giveSpace(item) {
      if (!item) return null;
      return item
        ?.split("_")
        ?.map((itm) => itm?.[0]?.toUpperCase() + itm?.slice(1))
        ?.join(" ");
    }

    function removeSpace(item) {
      if (!item) return null;
      return item
        ?.split(" ")
        ?.map((itm) => itm?.[0]?.toLowerCase() + itm?.slice(1))
        ?.join("_");
    }

    const getFilterValue = async () => {
      try {
        setLoading(true);
        let { data } = await fetchOtherCollections("");
        let filteredTData = data?.result?.map((item) => {
          item.collection_type =
            item?.collection_type === "subvention"
              ? "Dealer Fees"
              : giveSpace(item?.collection_type);
          return item;
        });
        setFilterDataValue(filteredTData || []);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }finally{
        setLoading(false);
      }
    };

    useEffect(() => {
      if (filterApplied) {
        getFilterValue();
        localStorage.getItem(`selectData${name}`) &&
          setSelectData(JSON.parse(localStorage.getItem(`selectData${name}`)));
        if (localStorage.getItem(`filterData${name}`)) {
          setFilterData([
            ...JSON.parse(localStorage.getItem(`filterData${name}`)),
          ]);
        } else {
          setFilterData([...initialState]);
        }
        localStorage.getItem(`reduceData${name}`) &&
          setReduceData(JSON.parse(localStorage.getItem(`reduceData${name}`)));
      } else {
        setFilterDataValue([]);
        setFilterData(initialState);
        setSelectData([]);
        setReduceData([]);
      }
    }, [filterApplied]);

    const isPrevCompleted = () => {
      let finalObj = filterData[filterData?.length - 1];
      for (let value of Object.values(finalObj)) {
        if (["", null, undefined]?.includes(value)) {
          return false;
        }
        if (Array?.isArray(value) && !value?.length) {
          return false;
        }
      }
      return true;
    };

    const addMore = () => {
      if (!isPrevCompleted()) {
        return;
      }

      setFilterData([
        ...filterData,
        {
          ...columns,
          options: options.filter((item) => {
            return !filterData.some((e) => e.field === item);
          }),
        },
      ]);
    };

    const handleFormatArray = () => {
      setSelectData(
        filterDataValue?.map((item) => {
          return {
            application_id: {
              name: item.application_id,
              value: item.application_id,
            },
            dealer: {
              name: item?.dealer_name,
              value: item?.dealer_name,
            },
            status: {
              name: item?.status,
              value: item?.status,
            },
            stage: {
              name: item?.file_stage,
              value: item?.file_stage,
            },
            other_collection_type: {
              name: item?.collection_type,
              value: item?.collection_type,
            },
            nbfc: {
              name: item?.nbfc_name,
              value: item?.nbfc_name,
            },
            do_uploaded_date: {
              name: item?.do_uploaded_date,
              value: item?.do_uploaded_date,
              isDate: null,
            },
            disbursed_date: {
              name: item?.disbursed_date,
              value: item?.disbursed_date,
              isDate: null,
            },
          };
        })
      );
    };

    useEffect(() => {
      if (filterApplied) {
        handleFormatArray();
      }
    }, [filterDataValue, filterApplied]);

    const handleApply = (data) => {
      let finalFilter = {};
      let current_stage_value = {};
      (data ? data : filterData)?.map((item, index) => {
        if (["disbursed_date", "do_uploaded_date"].includes(item.field)) {
          if (item?.field === "disbursed_date") {
            if (Array.isArray(item?.value))
              finalFilter[`disbursed_date_exists`] = Boolean(item?.value?.[0]);
            if (item?.value?.from)
              finalFilter[`disbursed_start_date`] = item.value.from;
            if (item?.value?.to)
              finalFilter[`disbursed_end_date`] = item.value.to;
          } else {
            if (Array.isArray(item?.value))
              finalFilter[`do_upload_exists`] = Boolean(item?.value?.[0]);
            if (item?.value?.from)
              finalFilter[`do_uploaded_start_date`] = item.value.from;
            if (item?.value?.to)
              finalFilter[`do_uploaded_end_date`] = item.value.no;
          }
        } else if (item?.field === "other_collection_type") {
          finalFilter[item.field] = item.value?.map((val) => {
            return  val[item.field]?.value === "Dealer Fees" ? "subvention" : removeSpace(val[item.field]?.value) ;
          });
        } else {
          finalFilter[item.field] = item.value?.map(
            (val) => val[item.field]?.value
          );
        }
      });
      onApply(finalFilter);
    };

    const removeItem = (i) => {
      if (dates[filterData?.[i]?.field]) {
        dates[filterData?.[i]?.field] = null;
        setDates({ ...dates });
      }
      setFilterData([...filterData.filter((item, index) => index !== i)]);
    };

    const handleChange = (key, value, i) => {
      let obj = { ...filterData[i] };
      obj = {
        ...obj,
        [key]: value,
      };

      if (key === "field") {
        obj = {
          ...obj,
          value: ["application_date"].includes(value)
            ? {
                from: Number(new Date()),
                to: Number(new Date()),
              }
            : [],
        };
      }

      filterData[i] = obj;
      if (key === "field") {
        if (dates?.[obj.field]) {
          dates[obj.field] = null;
          setDates({ ...dates });
        }
        setFilterData([...filterData.splice(0, i + 1)]);
        return;
      }
      setFilterData([...filterData]);
    };

    const handleReduceFilterValue = () => {
      let dropData = {};
      let finalArray = [...selectData];
      filterData?.map((item, index) => {
        if (item.field !== "" && item.value.length > 0) {
          finalArray = finalArray?.filter((obj1) => {
            if (
              item?.field === "dealer" ||
              item?.field === "stage" ||
              item?.field === "status" ||
              item?.field === "other_collection_type" ||
              item?.field === "nbfc"
            ) {
              return item?.value?.some((obj2) => {
                return (
                  obj2?.[`${item?.field}`]?.value ===
                  obj1?.[`${item?.field}`]?.value
                );
              });
            }
            return true;
          });
        }
        dropData = {
          ...dropData,
          [index + 1]: [...finalArray],
        };
      });
      setReduceData(dropData);
    };

    useEffect(() => {
      if (filterApplied) {
        handleReduceFilterValue();
      }
    }, [filterData, filterApplied]);

    useEffect(() => {
      if (filterApplied) {
        localStorage.setItem(`selectData${name}`, JSON.stringify(selectData));
        localStorage.setItem(`filterData${name}`, JSON.stringify(filterData));
        localStorage.setItem(`reduceData${name}`, JSON.stringify(reduceData));
      }
    }, [selectData, filterData, reduceData, filterApplied]);

    // useImperativeHandle(ref, () => ({
    //   handeToggleFilter(filter) {
    //     filter = [filter];
    //     const index = filterData.findIndex(
    //       (item) => item.field === "overall_status"
    //     );
    //     if (index !== -1) {
    //       handleChange("value", filter, index);
    //       handleApply();
    //     } else {
    //       let data = [
    //         {
    //           field: "overall_status",
    //           value: filter,
    //           options: options,
    //         },
    //       ];
    //       setFilterData(data);
    //       handleApply(data);
    //     }
    //   },
    // }));

    return (
      <>
        <div>
          <Modal
            show={open}
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
            centered
          >
            <Modal.Body>
              {loading && <LoadingSkeleton/>}
              {!loading && <div className="sort-dialog">
                <div className="sort-table-wrapper">
                  <table
                    className="sort-table app-sort-table"
                    id="app-sort-table"
                  >
                    <thead className="cf">
                      <tr>
                        <th style={{ width: 500 }}>Column</th>
                        <th style={{ width: "70%" }}>Value</th>
                      </tr>
                    </thead>

                    <tbody>
                      {filterData?.map((col, i) => {
                        return (
                          <>
                            <tr>
                              <td data-title="Data Point">
                                <Select
                                  className="w-100"
                                  value={col.field}
                                  sx={{
                                    boxShadow: "none",
                                    ".MuiOutlinedInput-notchedOutline": {
                                      border: 0,
                                    },
                                  }}
                                  onChange={(e) => {
                                    handleChange("field", e.target.value, i);
                                  }}
                                >
                                  {col?.options?.map((item) => {
                                    return (
                                      <MenuItem value={item}>
                                        {(item || "")
                                          ?.replaceAll("_", " ")
                                          ?.toUpperCase()}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </td>
                              <td data-title="Operator">
                                {col?.field !== "" &&
                                  (() => {
                                    if (dates?.[col?.field] === i) {
                                      return (
                                        <DateRangePicker
                                          localeText={{
                                            start: "From",
                                            end: "To",
                                          }}
                                          startDate={
                                            col?.value?.from
                                              ? new Date(col?.value?.from)
                                              : null
                                          }
                                          endDate={
                                            col?.value?.to
                                              ? new Date(col?.value?.to)
                                              : null
                                          }
                                          onChange={(start, end) => {
                                            handleChange(
                                              "value",
                                              {
                                                from: Number(start),
                                                to: Number(end),
                                              },
                                              i
                                            );
                                          }}
                                        />
                                      );
                                    } else if (
                                      [
                                        "do_uploaded_date",
                                        "disbursed_date",
                                      ].includes(col?.field)
                                    ) {
                                      return (
                                        <FormControl sx={{ minWidth: "100%" }}>
                                          <Select
                                            labelId="demo-simple-loan-standard-label"
                                            id="demo-simple-loan-standard"
                                            value={col?.value?.[0]}
                                            onChange={(e) => {
                                              handleChange(
                                                "value",
                                                [e.target.value],
                                                i
                                              );
                                              if (
                                                e.target.value === "between"
                                              ) {
                                                dates[col?.field] = i;
                                                setDates({ ...dates });
                                              }
                                            }}
                                          >
                                            <MenuItem value={"between"}>
                                              Between
                                            </MenuItem>
                                            <MenuItem value={"exists"}>
                                              Exists
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      );
                                    } else {
                                      return (
                                        <AutocompleteSelect
                                          selectData={
                                            i === 0 &&
                                            !["application_date"].includes(
                                              col.field
                                            )
                                              ? selectData
                                              : reduceData[i]
                                          }
                                          field={col.field}
                                          values={col.value}
                                          handleChange={(value) => {
                                            handleChange(
                                              "value",
                                              [...value],
                                              i
                                            );
                                          }}
                                        />
                                      );
                                    }
                                  })()}
                              </td>
                              <td
                                className="sort-close-td ps-1"
                                style={{ width: "30px" }}
                              >
                                {i !== 0 && (
                                  <CloseIcon
                                    onClick={() => removeItem(i)}
                                    sx={{ fontSize: 18 }}
                                  />
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>

                    <Button className="mb-3" onClick={addMore}>
                      Add More
                    </Button>
                  </table>
                </div>

                <div className="sort-bottom-holder">
                  <Button
                    className="me-2 clear-btn"
                    onClick={() => handleClose()}
                  >
                    Close
                  </Button>

                  <Button
                    variant="contained"
                    className="apply-btn"
                    onClick={() => handleApply()}
                  >
                    Apply Filters
                  </Button>
                </div>
              </div>}
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
);

export default OtherCollectionsFilter;
