import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment/moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  deleteCashReceipt,
  getDisbursedAppList,
  updateCashReciept,
} from "../../api";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";

const LabelComp = ({ title }) => {
  return (
    <p className="mb-0 me-4" style={{ width: 250 }}>
      {title}
    </p>
  );
};

const AddCashReceipt = ({ data, setData, handleClose, onSubmit }) => {
  const [isEdit, setEdit] = useState(false);
  const [appList, setAppList] = useState([]);
  const [loading, setLoading] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  const handleFetchAppList = async () => {
    try {
      let { data } = await getDisbursedAppList();
      setAppList((data?.data || [])?.map((item) => item?.application_id));
    } catch (error) {}
  };

  const handleUpdate = async () => {
    try {
      setLoading("SAVE");
      let isValid = data?.received_nbfc
        ? (data?.nbfc_received_amount>0 && data?.nbfc_received_amount + data?.amount_paid <= data?.amount)
        : true;
      if (!isValid) {
        return toast.error(
          "amount paid is more than total amount (Amount - Total Paid) "
        );
      }
      let payload = {
        amount: Number(data?.amount),
        transaction_date: data?.date
          ? data?.date
          : new Date().setHours(0, 0, 0, 0),
        application_id: data?.application_id,
        objectId: data?.receipt_id,
        money_received_by: data?.partner_type,
        // money_received_by_nbfc: data?.received_nbfc || false,
        ...(data?.nbfc_received_amount && data?.received_nbfc
          ? { nbfc_received_amount: data?.nbfc_received_amount }
          : {}),
        ...(data?.received_nbfc_date && data?.received_nbfc
          ? { nbfc_received_date: data?.received_nbfc_date }
          : {}),
      };
      let { data: response } = await updateCashReciept(payload);
      if (response?.status) {
        toast.success(response?.message || "Operation successfull!");
        onSubmit();
        handleClose();
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Operation failed!"
      );
    } finally {
      setLoading(null);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading("DELETE");
      let { data: response } = await deleteCashReceipt(data?.receipt_id);
      if (response?.status) {
        toast.success(response?.message || "Deletion successfull!");
        onSubmit();
        handleClose();
        setOpenDelete(false);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Operation failed!"
      );
    }
    setLoading(null);
  };

  useEffect(() => {
    let edit = typeof data?.receipt_id === "string";
    setEdit(edit);
    if (!edit) {
      handleFetchAppList();
    }
  }, [data?.receipt_id]);

  return (
    <>
      <div>
        <Dialog
          open={data?.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{isEdit ? "Edit" : "Add"} Transaction</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              {!isEdit && (
                <Grid item xs={12}>
                  <div className="d-flex align-items-center">
                    <LabelComp title={"Application ID"} />
                    <Autocomplete
                      size="small"
                      fullWidth
                      id="combo-box-demo"
                      value={data?.application_id || ""}
                      options={appList}
                      onChange={(e, val) => {
                        console.log(val);
                        setData({
                          ...data,
                          application_id: val,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      disabled={
                        data?.created_user !== "EMBEDDED_TOOLS" && isEdit
                      }
                    />
                  </div>
                </Grid>
              )}
              <Grid item xs={12}>
                <div className="d-flex align-items-center">
                  <LabelComp title={"Amount"} />
                  <TextField
                    fullWidth
                    value={data?.amount || ""}
                    size="small"
                    onChange={(e) => {
                      const isValidInput = /^[0-9\b]*(\.\d{0,3})?$/.test(
                        e.target.value
                      );

                      if (isValidInput) {
                        setData({
                          ...data,
                          amount: e.target.value,
                        });
                      }
                    }}
                    onKeyPress={(e) => {
                      const isValidKeyPress = /^[0-9.]$/.test(e.key);
                      if (!isValidKeyPress) {
                        e.preventDefault();
                      }
                    }}
                    disabled={data?.created_user !== "EMBEDDED_TOOLS" && isEdit}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="d-flex align-items-center">
                  <LabelComp title={"Date"} />
                  <TextField
                    type="date"
                    fullWidth
                    value={
                      data?.date
                        ? moment(data?.date).format("YYYY-MM-DD")
                        : null
                    }
                    size="small"
                    onChange={(e) => {
                      setData({
                        ...data,
                        date: ["", null]?.includes(e.target.value)
                          ? null
                          : new Date(e.target.value).getTime(),
                      });
                    }}
                    disabled={data?.created_user !== "EMBEDDED_TOOLS" && isEdit}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="d-flex align-items-center">
                  <LabelComp title={"Partner Type"} />
                  <FormControl fullWidth>
                    <Select
                      value={data?.partner_type || ""}
                      size="small"
                      onChange={(e) => {
                        setData({
                          ...data,
                          partner_type: e.target.value,
                        });
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      disabled={
                        data?.created_user !== "EMBEDDED_TOOLS" && isEdit
                      }
                    >
                      <MenuItem value={"DEALER"}>Dealer</MenuItem>
                      <MenuItem value={"AGENT"}>Agent</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="d-flex align-items-center">
                  <p className="mb-0 me-4" style={{ width: 300 }}>
                    <FormControlLabel
                      onChange={(e) => {
                        setData({
                          ...data,
                          received_nbfc: e.target.checked,
                        });
                      }}
                      control={
                        <Checkbox
                          checked={Boolean(data?.received_nbfc) || false}
                        />
                      }
                      label="Received by NBFC"
                    />
                  </p>
                </div>
              </Grid>
              {Boolean(data?.received_nbfc) && (
                <>
                  <Grid item xs={12}>
                    <div className="d-flex align-items-center">
                      <LabelComp title={"Dealer Payment date"} />
                      <TextField
                        type="date"
                        fullWidth
                        value={
                          data?.received_nbfc_date
                            ? moment(data?.received_nbfc_date).format(
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        size="small"
                        onChange={(e) => {
                          setData({
                            ...data,
                            received_nbfc_date: ["", null]?.includes(
                              e.target.value
                            )
                              ? null
                              : new Date(e.target.value).getTime(),
                          });
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="d-flex align-items-center">
                      <LabelComp title={"Amount Paid"} />
                      <TextField
                        fullWidth
                        value={data?.nbfc_received_amount || 0}
                        size="small"
                        onChange={(e) => {
                          setData({
                            ...data,
                            nbfc_received_amount: +e.target.value,
                          });
                        }}
                        onKeyPress={(e) => {
                          const isValidKeyPress = /^[0-9.]$/.test(e.key);
                          if (!isValidKeyPress) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <div className="d-flex align-items-center">
                  <LabelComp title={"Total Paid"} />
                  <TextField
                    fullWidth
                    value={Number(data?.amount_paid || 0)}
                    size="small"
                    disabled
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            {isEdit ? (
              <>
                {loading === "DELETE" ? (
                  <Button>
                    <CircularProgress size={18} sx={{ mr: 1 }} />
                    Deleting...
                  </Button>
                ) : (
                  <IconButton
                    color="error"
                    onClick={() => {
                      setOpenDelete(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </>
            ) : (
              <div></div>
            )}
            <div>
              <Button
                onClick={handleClose}
                disabled={loading !== null}
                sx={{ color: "black" }}
              >
                Cancel
              </Button>
              {loading === "SAVE" ? (
                <Button>
                  <CircularProgress size={18} sx={{ mr: 1 }} />{" "}
                  {isEdit ? "Updating..." : "Saving..."}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    boxShadow: "none",
                    px: 3,
                    ml: 2,
                  }}
                  onClick={() => {
                    handleUpdate();
                  }}
                  disabled={
                    !["DEALER", "AGENT"]?.includes(data?.partner_type) ||
                    typeof data?.application_id !== "string" ||
                    typeof Number(data?.amount) === "string" ||
                    Number(data?.amount) === 0 ||
                    new Date(data?.date) == "Invalid Date" ||
                    data?.date === null ||
                    loading !== null ||
                    (data?.received_nbfc &&
                      (new Date(data?.received_nbfc_date) == "Invalid Date" ||
                        data?.received_nbfc_date === null ||
                        ["", 0, null, undefined].includes(
                          data?.nbfc_received_amount
                        ) ||
                        isNaN(data?.nbfc_received_amount)))
                  }
                >
                  Save
                </Button>
              )}
            </div>
          </DialogActions>
        </Dialog>
      </div>

      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this transaction?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDelete(false)}
            disabled={loading === "DELETE"}
          >
            Cancel
          </Button>
          {loading === "DELETE" ? (
            <Button>
              <CircularProgress size={18} sx={{ mr: 1 }} />
              Deleting...
            </Button>
          ) : (
            <Button onClick={handleDelete}>Confirm</Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddCashReceipt;
