export const toolsList = [
  {
    name: "Collection Update",
    link: "/update-collection",
  },
  {
    name: "Edit Collection",
    link: "/update-collection",
  },
  {
    name: "Collection Table",
    link: "/collections",
  },
  {
    name: "View Documents",
    link: "/view-documents",
  },
  {
    name: "Documents",
    link: "/view-documents",
  },
  {
    name: "Upload Document",
    link: "/upload-documents",
  },
  {
    name: "Delete Document",
    link: "/upload-documents",
  },
  {
    name: "Customer Details",
    link: "/loan-applications",
  },
  {
    name: "Loan Applications",
    link: "/loan-applications",
  },
  {
    name: "View Application",
    link: "/loan-applications",
  },
  {
    name: "Schedule Generator",
    link: "/generate-schedule",
  },
  {
    name: "Tools",
    link: "/loan-applications",
  },
  {
    name: "Create User",
    link: "/create-user",
  },
  {
    name: "Create Agent",
    link: "/create-agent",
  },
  {
    name: "Create Anchor",
    link: "/create-anchor",
  },
  {
    name: "Shared Dealers Data",
    link: "/shared-dealer-data",
  },
  {
    name: "Update Stage",
    link: "/update-stage",
  },
  {
    name: "Update Status",
    link: "/update-stage",
  },
  {
    name: "Edit Status",
    link: "/update-stage",
  },
];
