import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { getGpsInactiveVehicles, getGpsInstallationChase } from "../../../api";
import { useEffect } from "react";
import moment from "moment/moment";
import CustomTable from "../../../Components/CustomTable/CustomTable";
import LoadingDiv from "../../../Components/gps/LoadingDiv";
// import CommentsModal from "../../../Components/gps/Comments";
import CommentIcon from "@mui/icons-material/Comment";
import { toast } from "react-toastify";
import CommentsModal from "../../../Components/Comments/Comments";

const InactiveVehicle = ({ inactiveState, type }) => {
  const [inactiveData, setInactiveData] = inactiveState;
  const [loadingTable, setLoadingTable] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);

  const [openView, setopenView] = useState(false);
  const [applicationData, setApplicationData] = useState({});

  const handleCloseView = (val) => {
    setopenView(false);
  };

  const handleLoadPieData = async (page, limit, pageChange) => {
    try {
      if (pageChange) setPageLoading(true);
      else setLoadingTable(true);
      let data = null;
      if (type === "inactive") {
        const { data: response } = await getGpsInactiveVehicles(
          `?page=${page}&limit=${limit}`
        );
        data = response;
      } else {
        const { data: response } = await getGpsInstallationChase(
          `?page=${page}&limit=${limit}`
        );
        data = response;
      }
      setInactiveData({
        loaded: true,
        data: data?.data || [],
        totalCount: data?.count,
      });
    } catch (error) {
      toast.error("Failed to load the data");
    }
    if (pageChange) setPageLoading(false);
    else setLoadingTable(false);
  };

  const columns = [
    {
      label: "Application ID",
      render: (index) => {
        return inactiveData?.data?.[index]?.application_id;
      },
      width: 200,
    },
    {
      label: "Customer Name",
      render: (index) => {
        return inactiveData?.data?.[index]?.customer_name;
      },
      width: 200,
    },
    {
      label: "Customer Number",
      render: (index) => {
        return inactiveData?.data?.[index]?.customer_number;
      },
      width: 150,
    },
    {
      label: "Follow Up Date",
      render: (index) => {
        let date = inactiveData?.data?.[index]?.follow_up_date;
        return date ? moment(new Date(date)).format("DD/MM/YYYY") : "NA";
      },
      width: 150,
    },
    {
      label: "Comments",
      render: (index) => {
        return (
          <Button
            onClick={() => {
              setApplicationData({
                index,
                application_id: inactiveData?.data?.[index]?.application_id,
                customer_name: inactiveData?.data?.[index]?.customer_name,
              });
              setopenView(true);
            }}
          >
            <CommentIcon />
          </Button>
        );
      },
      width: 100,
    },
    ...(type === "inactive"
      ? [
          {
            label: "Last travelled date",
            render: (index) => {
              let date = inactiveData?.data?.[index]?.last_travelled_date;
              return date ? date : "NA";
            },
            width: 150,
          },
          {
            label: "Inactive",
            render: (index) => {
              return inactiveData?.data?.[index]?.inactive_days;
            },
            width: 150,
          },
        ]
      : []),
    {
      label: "NBFC",
      render: (index) => {
        return inactiveData?.data?.[index]?.nbfc_name;
      },
      width: 300,
    },
    {
      label: "DPD",
      render: (index) => {
        return inactiveData?.data?.[index]?.dpd_days;
      },
      width: 50,
    },
    {
      label: "Dealer Name",
      render: (index) => {
        return inactiveData?.data?.[index]?.dealer_name;
      },
      width: 250,
    },
    {
      label: "Dealer Number",
      render: (index) => {
        return inactiveData?.data?.[index]?.dealer_number;
      },
      width: 150,
    },
  ];

  useEffect(() => {
    handleLoadPieData(1, 100);
  }, []);

  return (
    <>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {loadingTable ? (
              <LoadingDiv />
            ) : (
              <CustomTable
                tableData={inactiveData?.data || []}
                columns={columns}
                serialNo={true}
                tableHeight={"80vh"}
                defaultLimit={100}
                totalCount={inactiveData?.totalCount}
                isLoading={loadingTable}
                pageLoading={pageLoading}
                blueTheme={true}
                onPageChange={(page, limit) => {
                  setLimit(limit || 100);
                  setPage(page || 1);
                  handleLoadPieData(page, limit, true);
                }}
                onLimitChange={(page, limit) => {
                  setLimit(limit || 100);
                  setPage(1);
                  handleLoadPieData(page, limit);
                }}
              />
            )}
          </Grid>
        </Grid>
      </div>

      {/* <CommentsModal
        openView={commentsData?.open}
        applicationData={{ ...commentsData }}
        handleCloseView={() => {
          setCommentData({
            open: false,
            application_id: "",
            customer_name: "",
            comments: [],
          });
        }}
      /> */}

      <CommentsModal
        openView={openView}
        applicationData={applicationData}
        handleCloseView={handleCloseView}
        // handleTableUpdate={handleTableUpdate}
        // loadComments={handleCommentStateChange}
        defaultType={type === "inactive" ? "INACTIVE VEHICLE": "GPS INSTALLATION"}
      />
    </>
  );
};

export default InactiveVehicle;
