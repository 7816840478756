import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import { Close, GetApp } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  exportNachPresents,
  getAllNachPresents,
  refreshAllNachPresents,
} from "../../api";
import { f } from "../../utility/Formater";
import CustomTableV2 from "../CustomTable/CustomTableV2";
import LoadingOver from "../Loading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const nachPresentColors = {
  active: "#00F",
  cancelled: "#00F",
  paused: "#C7A700",
  completed: "#10B800",
  scheduled: "#9C27B0",
  failed: "#FF0500",
  success: "#10B800",
  [undefined]: "#71717A",
};

function NachPresentsTable({ open, handleClose }) {
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [quickFilter, setQuickFilter] = useState("all");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [totalCount, setTotalCount] = useState(0);
  const [isExporting, setIsExporting] = useState(false);
  const [confirmRefetchData, setConfirmRefetchData] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [openResults, setOpenResults] = useState(false);
  const [refetchNachResponse, setRefetchNachResponse] = useState({
    success: [],
    failed: [],
  });

  const ToggleButtons = () => {
    return (
      <div>
        <ToggleButtonGroup
          value={quickFilter}
          exclusive
          onChange={handleQuickFilter}
          aria-label="text alignment"
          size="small"
          className="toggle-btn-holder"
        >
          <ToggleButton
            value="all"
            aria-label="left aligned"
            className="toggle-btn"
          >
            All
          </ToggleButton>
          <ToggleButton
            value="scheduled"
            aria-label="left aligned"
            className="toggle-btn"
            sx={{
              color: nachPresentColors.scheduled + " !important",
            }}
          >
            Scheduled
          </ToggleButton>
          <ToggleButton
            value="success"
            aria-label="centered"
            className="toggle-btn"
            sx={{
              color: nachPresentColors.success + " !important",
            }}
          >
            Success
          </ToggleButton>
          <ToggleButton
            value="failed"
            aria-label="centered"
            className="toggle-btn"
            sx={{
              color: nachPresentColors.failed + " !important",
            }}
          >
            Failed
          </ToggleButton>
          <ToggleButton
            value="cancelled"
            aria-label="centered"
            className="toggle-btn"
            sx={{
              color: nachPresentColors.cancelled + " !important",
            }}
          >
            Cancelled
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    );
  };

  let columns = [
    {
      label: "Application Id",
      render: (index) => {
        return (
          <span style={{ fontSize: "13px" }}>
            {tableData?.[index]?.application_id}
          </span>
        );
      },
      width: 160,
    },
    {
      label: "Customer Name",
      render: (index) => {
        return f(tableData?.[index]?.customer_name);
      },
      width: 200,
    },
    {
      label: "Presentation Date",
      render: (index) => {
        const date = tableData?.[index]?.created_at;
        return date
          ? moment(date, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY hh:mm:ss A")
          : f(null);
      },
      width: 250,
    },
    {
      label: "Settlement Date",
      render: (index) => {
        const date =
          tableData?.[index]?.nach_txn_details?.scheduled_settlement_date;
        return date
          ? moment(date, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY hh:mm:ss A")
          : f(null);
      },
      width: 220,
    },
    {
      label: "Amount",
      render: (index) => {
        return f(tableData?.[index]?.amount);
      },
    },
    {
      label: "NBFC",
      render: (index) => {
        return f(tableData?.[index]?.nbfc_name?.split(" ")?.[0]);
      },
      width: 100,
    },
    {
      label: "State",
      render: (index) => {
        let state = tableData?.[index]?.nach_txn_details?.state;
        return (
          <span
            style={{
              color: nachPresentColors?.[state],
              textTransform: "capitalize",
            }}
            className="d-flex flex-row align-items-center"
          >
            {f(state)}
          </span>
        );
      },
    },
    {
      label: "Txn UTR",
      render: (index) => {
        return f(tableData?.[index]?.nach_txn_details?.details?.utr);
      },
      width: 180,
    },
    {
      label: "Presented By",
      render: (index) => {
        let presented_by = tableData?.[index]?.presented_by;
        const replacedEmail = presented_by?.replace(/\./g, " ");
        const sanitizedEmail = replacedEmail?.replace(/@.*$/, "");
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
            className="d-flex flex-row align-items-center"
          >
            {f(sanitizedEmail)}
          </span>
        );
      },
      width: 150,
    },
    {
      label: "Failed Reason",
      render: (index) => {
        let details =
          tableData?.[index]?.nach_txn_details?.details?.failure_reason;
        return (
          <span
            className="d-flex flex-row align-items-center"
            style={{ fontSize: "11px" }}
          >
            {f(details)}
          </span>
        );
      },
      width: 300,
    },
  ];

  async function fetchAllNachPresents(state, page, limit) {
    try {
      setTableLoading(true);
      let query = ``;
      if (page) query += `page=${page}`;
      if (limit) query += `&limit=${limit}`;
      if (state) query += `&state=${state}`;

      const { data } = await getAllNachPresents(query);
      setTableData(data?.data);
      setTotalCount(data?.count);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error Loading Table");
    } finally {
      setTableLoading(false);
    }
  }

  function handleQuickFilter(_, value) {
    value = value ?? "all";
    setQuickFilter(value);
    setPage(1);
    fetchAllNachPresents(value, 1, limit);
  }

  async function handleExport() {
    try {
      setIsExporting(true);
      const { data } = await exportNachPresents("all");
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `nach_Presentations.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast?.error(error?.response?.data?.message);
    } finally {
      setIsExporting(false);
    }
  }

  async function handleRefreshSchedules() {
    try {
      setRefreshLoading(true);
      const { data } = await refreshAllNachPresents();
      const { success = [], failed = [] } = data;
      setRefetchNachResponse({ success, failed });
      setOpenResults(true);
    } catch (error) {
      toast?.error(
        error?.response?.data?.message || error?.message || "refetch failed!"
      );
    } finally {
      setRefreshLoading(false);
    }
  }

  useEffect(() => {
    if (open) {
      setPage(1);
      setLimit(100);
      fetchAllNachPresents("all", 1, 100);
    }
  }, [open]);

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        open={open}
        maxWidth={false}
        sx={{
          maxWidth: 2000,
        }}
        onClose={handleClose}
      >
        <IconButton
          aria-label="close"
          sx={{ position: "absolute", top: 5, right: 5 }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
        <DialogTitle
          flexDirection={"column"}
          sx={{ backgroundColor: "#EFF5FF", mb: 0 }}
        >
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div>
              <span>Nach Presents</span>
              <span>
                <ToggleButtons />
              </span>
            </div>
            <span className="me-4 d-flex flex-row-reverse">
              <Button
                variant="contained"
                disableElevation
                className="m-2"
                onClick={handleExport}
                disabled={isExporting}
              >
                <GetApp fontSize="small" className="me-1" />
                Export
              </Button>
              <Button
                variant="contained"
                disableElevation
                className={`m-2`}
                onClick={() => {
                  setConfirmRefetchData(true);
                }}
                disabled={refreshLoading}
              >
                <CachedIcon
                  fontSize="small"
                  className={"me-2 " + (refreshLoading ? "rotate-class" : "")}
                />{" "}
                Refresh Nach Presents
              </Button>
            </span>
          </div>
        </DialogTitle>

        <DialogContent sx={{ width: "100%", px: 0, pb: 0 }}>
          <CustomTableV2
            tableData={tableData}
            tableHeight={600}
            columns={columns}
            limit={limit}
            page={page}
            totalCount={totalCount}
            isLoading={tableLoading}
            serialNo={true}
            isPaginated={true}
            loadingRows={10}
            onPageChange={(pgNo) => {
              setPage(pgNo);
              fetchAllNachPresents(quickFilter, pgNo, limit);
            }}
            onLimitChange={(rows) => {
              setLimit(rows);
              fetchAllNachPresents(quickFilter, page, rows);
            }}
          />
        </DialogContent>
      </Dialog>
      {confirmRefetchData && <NachPresentRefetch
        open={confirmRefetchData}
        onClose={() => {
          setConfirmRefetchData(false);
        }}
        handleSubmit={handleRefreshSchedules}
      />}
      <RefetchResults
        open={openResults}
        handleClose={() => {
          setOpenResults(false);
        }}
        {...(refetchNachResponse || {})}
      />
      {refreshLoading && (
        <LoadingOver extraBlur={true} text={"updating Nach Presents"} />
      )}
    </>
  );
}

const NachPresentRefetch = ({ open, onClose ,handleSubmit }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  async function getTotalCount(){
    try{
    setLoading(true);
    let query = `count=${true}`;
    const { data } = await refreshAllNachPresents(query);
    setCount(data?.totalCount || 0);
    }catch(error){
      toast?.error(error?.response?.data?.message);
    }finally{
      setLoading(false);
    }
  }

  useEffect(()=>{
    getTotalCount();
  },[])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Are You Sure ?</DialogTitle>
      <DialogContent>
        This might take about {loading ? "calculating..." :  Math.ceil(count/60)} minutes to fetch nach presents
        (scheduled and processing) details from Digio.These presentations have a settlement date that falls within this month or earlier.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleSubmit();
            onClose();
          }}
        >
          Proceed
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

const RefetchResults = ({ open, handleClose, success = [], failed = [] }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const successColumn = [
    {
      label: "Application Id",
      render: (i) => {
        return f(success?.[i]?.application_id);
      },
    },
    {
      label: "Status",
      render: (i) => {
        return (
          <span
            style={{
              color: nachPresentColors?.[success?.[i]?.status],
              textTransform: "capitalize",
            }}
            className="d-flex flex-row align-items-center"
          >
            {f(success?.[i]?.status)}
          </span>
        );
      },
    },
  ];

  const failedColumn = [
    {
      label: "Application Id",
      render: (i) => {
        return f(failed?.[i]?.application_id);
      },
    },
  ];

  function downloadFile() {
    const jsonData = value === 0 ? success : failed;
    let csvContent = "";
    if (jsonData.length > 0) {
      const header = Object.keys(jsonData[0]).join(",") + "\n";
      const rows = jsonData
        .map(
          (obj) =>
            Object.values(obj)
              .join(",") + "\n"
        )
        .join("");
      csvContent = header + rows;
    }
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "refreshedNachPresents.csv");
    link.click();
  }

  return (
    <Dialog open={open} maxWidth={"sm"} onClose={handleClose} className="m-0">
      <DialogContent className="w-100 p-0">
        <Box sx={{ width: 600 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" , width:"100%"}}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={`refetch success ${success.length}`} {...a11yProps(0)} />
              <Tab label={`error ${failed.length}`} {...a11yProps(0)} />
            </Tabs>
          </Box>
          <div className="w-100">
            <CustomTabPanel value={value} index={0}>
              <CustomTableV2
                tableHeight={500}
                tableData={success}
                columns={successColumn}
                serialNo={true}
                loadingRows={6}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <CustomTableV2
                tableHeight={500}
                tableData={failed}
                columns={failedColumn}
                serialNo={true}
                loadingRows={6}
              />
            </CustomTabPanel>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={downloadFile} disabled={value===0 ? success?.length === 0 : failed?.length === 0} >Download</Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NachPresentsTable;