import React, { useState, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { GlobalContext } from "../../Context/GlobalContext";
import { IconButton, Tooltip, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomTableV2 from "../CustomTable/CustomTableV2";
import { f } from "../../utility/Formater";
import { getHoldBackApplicants } from "../../api";
import { toast } from "react-toastify";
import GetAppIcon from "@mui/icons-material/GetApp";

function HoldBackApplicantsPopup({ isOpen, onClose, data, isLoading }) {
  const { dealerDetailState } = useContext(GlobalContext);
  const [dealerDetails, setDealerDetails] = dealerDetailState;

  async function handleExport() {
    try {
      const { data } = await getHoldBackApplicants(
        dealerDetails?.anchor_id,
        true
      );
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `hold-back-applications.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      toast?.error("Failed to export Hold Back Applications");
    }
  }

  let columns_filing_status = [
    {
      label: "Application ID",
      render: (index) => {
        return f(data?.[index]?.application_id);
      },
      width: 150,
    },
    {
      label: "Customer Name",
      render: (index) => {
        return f(data?.[index]?.customer_name);
      },
      width: 150,
    },
    {
      label: "NBFC Name",
      render: (index) => {
        return f(data?.[index]?.nbfc_name);
      },
      width: 150,
    },
    {
      label: "Amount",
      render: (index) => {
        return f(data?.[index]?.hold_back_amount);
      },
    },
    {
      label: "Mode",
      render: (index) => {
        return f(data?.[index]?.hold_back_mode);
      },
    },
    {
      label: "Disbursed Date",
      render: (index) => {
        return f(data?.[index]?.disbursed_date);
      },
      width: 150,
    },
  ];

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth
      sx={{ width: "100%", px: 0, pb: 1 }}
    >
      <DialogTitle>
        Holdback Applications - {dealerDetails?.dealership_name}
      </DialogTitle>
      <IconButton
        aria-label="export"
        sx={{
          position: "absolute",
          right: 30,
          top: 8
        }}
      >
        <Button
          className="me-3 pointer"
          variant="outlined"
          onClick={handleExport}
          disabled={isLoading}
        >
          <GetAppIcon className="me-1" />
          Export
        </Button>
      </IconButton>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "gray",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <CustomTableV2
          tableData={data}
          tableHeight={500}
          columns={columns_filing_status}
          limit={0}
          page={1}
          serialNo={true}
          isLoading={isLoading}
        />
      </DialogContent>
    </Dialog>
  );
}

export default HoldBackApplicantsPopup;
