import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { getBankNames } from "../api";
import { toast } from "react-toastify";

function AutoFillBankNames({ value, onChange, label, disabled }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [val, setVal] = React.useState(null);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (options && value) {
      setVal(
        options?.filter((item) =>
          item?.nachable_bank_name?.includes(value)
        )?.[0]
      );
    }
  }, [options, value]);

  const getOptions = async () => {
    try {
      setOpen(true);
      const { data } = await getBankNames();
      if (data?.status) {
        setOptions(data?.nachableBankList || []);
        if (value) {
          setVal(
            options?.filter((item) =>
              item?.nachable_bank_name?.includes(value)
            )?.[0]
          );
        }
      }
    } catch (error) {
      toast?.error(error?.response?.data?.message || "cant fetch bank names");
    } finally {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    getOptions();

    return () => {
    //   setOptions([]);
      setOpen(false);
      setVal(null);
    };
  }, []);

  return (
    <Autocomplete
      id="asynchronous-demo"
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={val}
      inputValue={value ?? ""}
      disabled={disabled}
      isOptionEqualToValue={(option, value) =>
        option?.nachable_bank_name === value?.nachable_bank_name
      }
      getOptionLabel={(option) => option?.nachable_bank_name ?? ""}
      onChange={(event, newValue) => {
        onChange(newValue?.nachable_bank_name);
        setVal(newValue);
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          fullWidth
          margin="dense"
          value={value ?? ""}
          label={label ?? "Bank Names"}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default AutoFillBankNames;
