import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { editTransaction, editTransactionV2 } from "../../../api";

const UpdateCollection = ({
  open,
  handleClose,
  initalState,
  onSubmit,
  isTransaction,
  applicationId,
  isNew,
}) => {
  const errorsIntial = {
    amount: "",
    date: "",
  };

  const [openDelete, setOpenDelete] = useState(false);
  const [collectionData, setCollectionData] = useState(initalState);
  const [errors, setError] = useState(errorsIntial);
  const [isLoading, setLoading] = useState(false);
  const [isOverLoading, setOverLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const dateConvertor = (date) => {
    let splitDate = date.split("/");
    let formatted = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`;
    return Number(new Date(formatted));
  };

  const handleChange = (prop) => (event) => {
    setCollectionData({
      ...collectionData,
      [prop]: event.target.value,
    });
  };

  const formatDate = (dateString) => {
    var dateParts = dateString.split("/");
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  };

  useEffect(() => {
    // console.log(initalState);
    setCollectionData(initalState);
  }, [initalState]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let dateChanged = false;

      // console.log(collectionData?.defaultDate);
      // console.log(collectionData?.transaction_date);

      if (!(collectionData?.defaultDate === collectionData?.transaction_date)) {
        dateChanged = true;
      }

      let updations = {
        txn_number: collectionData?.transaction_id,
        payment_mode: collectionData?.payment_mode,
        txn_amount: Number(collectionData?.payment_amount),
        trxn_dateMS: dateConvertor(collectionData?.transaction_date),
        txn_utr: collectionData?.transaction_utr,
      };

      if (isTransaction) {
        await onSubmit({ ...updations, dateChanged });
      } else {
        collectionData.array[collectionData?.index] = updations;

        let payload = {
          index: collectionData?.index,
          schedule_id: collectionData?.id,
          payment_details: collectionData?.array,
          dateChanged,
        };

        await onSubmit(payload);
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleDelete = async (data, type) => {
    setOpenDelete(false);
    try {
      let payload = {
        scheduleId: collectionData?.scheduleId,
        transactionId: collectionData?.transactionId,
        action: "DELETE",
        data: {},
      };

      const { data: response } = isNew
        ? await editTransactionV2(payload, applicationId)
        : await editTransaction(payload, applicationId);

      if (response?.status) {
        toast.success("Transaction Deleted successfully");
        handleClose(true);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (!open) {
      setEdit(false);
    }
  }, [open]);

  return (
    <>
      <div>
        <Drawer
          anchor={"right"}
          open={open}
          onClose={() => {
            handleClose();
            setEdit(false);
          }}
        >
          <div
            style={{
              width: "500px",
              padding: "25px",
              height: "100%",
              position: "relative",
            }}
          >
            {initalState?.isEditabled && (
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
              >
                {!isEdit && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEdit(true);
                    }}
                  >
                    <EditIcon sx={{ fontSize: 15, mr: 1 }} />
                    Edit
                  </div>
                )}
                <DeleteIcon
                  sx={{ fontSize: 20, ml: 1, mt: 0.2, cursor: "pointer" }}
                  onClick={() => setOpenDelete(true)}
                />
              </div>
            )}
            <div className="w-100">
              <p className="more-tools-heading mb-4">Collection Details</p>
              <Grid container spacing={3} className="mt-3">
                <Grid item xs={12} sm={12}>
                  <div className="paycard">
                    {isEdit ? (
                      <TextField
                        required
                        id="amount"
                        name="amount"
                        label="Payment Amount"
                        fullWidth
                        autoComplete="given-name"
                        variant="standard"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        // inputProps={{ maxLength: 10 }}
                        value={collectionData?.payment_amount || ""}
                        onChange={handleChange("payment_amount")}
                        error={errors?.amount}
                      />
                    ) : (
                      <p className="paycard-mode">
                        <span>Transaction Amount: </span>₹{" "}
                        {collectionData?.payment_amount || "---"}
                      </p>
                    )}
                  </div>
                  {isEdit ? (
                    <FormControl
                      variant="standard"
                      sx={{ width: "100%", marginTop: "15px" }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Payment mode
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        defaultValue={collectionData?.payment_mode}
                        onChange={handleChange("payment_mode")}
                      >
                        <MenuItem value={"Cash"}>Cash</MenuItem>
                        <MenuItem value={"UPI"}>UPI</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <p className="paycard-mode">
                      <span>Transaction Amount: </span>{" "}
                      {collectionData?.payment_mode || "---"}
                    </p>
                  )}
                  {isEdit ? (
                    <TextField
                      className="mt-2"
                      id="id_ref"
                      name="id_ref"
                      label="Transaction ID/ Ref Number"
                      fullWidth
                      autoComplete="given-name"
                      variant="standard"
                      value={collectionData?.transaction_id || ""}
                      onChange={handleChange("transaction_id")}
                    />
                  ) : (
                    <p className="paycard-mode">
                      <span>Transaction ID: </span>{" "}
                      {collectionData?.transaction_id || "---"}
                    </p>
                  )}
                  {isEdit ? (
                    <TextField
                      // require
                      className="mt-2"
                      id="utr"
                      name="utr"
                      label="Transaction UTR"
                      fullWidth
                      autoComplete="given-name"
                      variant="standard"
                      value={collectionData?.transaction_utr || ""}
                      onChange={handleChange("transaction_utr")}
                    />
                  ) : (
                    <p className="paycard-mode">
                      <span>UTR: </span>{" "}
                      {collectionData?.transaction_utr || "---"}
                    </p>
                  )}

                  {isEdit ? (
                    <DatePicker
                      className="w-100 mt-2"
                      label="Transaction date"
                      format="dd/MM/yyyy"
                      // maxDate={new Date()}
                      //   value={ new Date()}
                      value={formatDate(collectionData?.transaction_date)}
                      onChange={(date) => {
                        // console.log(moment(date).format("DD/MM/YYYY"));
                        setCollectionData({
                          ...collectionData,
                          transaction_date: moment(date).format("DD/MM/YYYY"),
                        });
                      }}
                      animateYearScrolling
                    />
                  ) : (
                    <p className="paycard-mode">
                      <span>Transaction Date: </span>{" "}
                      {collectionData?.transaction_date || "---"}
                    </p>
                  )}

                  {isEdit && (
                    <div
                      className="mt-2"
                      style={{
                        position: "absolute",
                        bottom: "20px",
                        right: "20px",
                      }}
                    >
                      <Button
                        sx={{ color: "white" }}
                        className="pay-btn mt-4"
                        onClick={handleSubmit}
                        disabled={
                          isLoading || collectionData?.payment_amount == 0
                        }
                      >
                        {isLoading ? "Loading..." : `SUBMIT`}
                      </Button>
                      <Button
                        sx={{ color: "purple" }}
                        className="mt-4 me-2"
                        onClick={() => {
                          setEdit(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </Drawer>
      </div>

      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>Are you sure to delete this collection?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
          <Button onClick={() => handleDelete()} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateCollection;
