import React from "react";
import SideNavBar from "../../Components/SideNavBar/SideNavBar";

const HomeScreen = ({ children, active, isClosed }) => {
  return (
    <div>
      <SideNavBar children={children} active={active} isClosed={isClosed} />
    </div>
  );
};

export default HomeScreen;
