import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import TuneIcon from "@mui/icons-material/Tune";

const buttonStyle = {
  fontSize: 10,
};

const RadioStyle = {
  fontSize: 10,
};

export default function AnchorSort({ onApply }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [sort, setSort] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = async () => {
    await localStorage.setItem("anchorSort", sort);
    onApply();
    handleClose();
  };

  useEffect(() => {
    if (open) {
      let savedSort = localStorage.getItem("anchorSort");
      setSort(savedSort ? savedSort : "");
    }
  }, [open]);

  return (
    <div>
      <Button
        id="basic-button"
        variant="outlined"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        color="inherit"
        style={{
          borderColor: "#bebebe",
          marginRight: 15,
          textTransform: "none",
          height:30
        }}
        onClick={handleClick}
      >
        Sort <TuneIcon sx={{ fontSize: 16, marginLeft: 0.5 }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div>
          <div className="ps-3 pe-1">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={sort}
                name="radio-buttons-group"
                onChange={(e) => {
                  setSort(e.target.value);
                }}
              >
                <FormControlLabel
                  value="score"
                  control={<Radio />}
                  label="Partner score high to low"
                  sx={{ ...RadioStyle }}
                />
                <FormControlLabel
                  value="new_to_old"
                  control={<Radio />}
                  label="Partner new to old "
                />
              </RadioGroup>
            </FormControl>
          </div>
          <hr className="mt-0" />
          <div className="px-2">
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ ...buttonStyle }}
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ ...buttonStyle }}
                  onClick={handleApply}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Menu>
    </div>
  );
}
