import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Modal,
  IconButton,
  Box,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useState } from "react";
import SelectionField from "../../Pages/Tables/SelectionField";
import {
  AddCommentCollection,
  GetAllCommentCollection,
  deleteComment,
  editComment,
  updateEmiReminder,
} from "../../api";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { useContext } from "react";
import { Table } from "react-bootstrap";
import { UserContext } from "../../Context/UserContext";
import { useEffect } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VehicleConfisication from "./VehicleConfiscation";
import noDataIco from "../../assets/nodata.gif";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  DisabledByDefaultOutlined,
  EditOutlined,
  ElectricRickshaw,
  ElectricRickshawOutlined,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker } from "@material-ui/pickers";
import { useRef } from "react";
import { environment } from "../../config";
// import { DatePicker } from "@material-ui/pickers";

const vehicleStatusConst = {
  CONFISCATED: "Vehicle Confiscated",
  RETURNED_TO_CUSTOMER: "Return to Customer",
  RESELL: "Resale",
  RENTED: "Running on rent",
};

const CommentsModal = ({
  applicationData,
  handleCloseView,
  openView,
  handleTableUpdate,
  loadComments,
  defaultType,
}) => {
  const { user, setUser } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(defaultType ?? null);
  const [comments, setComments] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [allComments, setAllComments] = useState({ loaded: false });
  const [commentsData, setCommentsData] = useState([]);
  const [isCommentsLoading, setIsCommentsLoading] = useState(false);
  const [collectionCSV, setCollectionCSV] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [enableEmiRemider, setEnableEmiReminder] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [followDate, setFollowDate] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [vehicleStatus, setVehicleStatus] = useState("");

  const [openConfisc, setOpenConsfic] = useState(false);
  const [openConfirmEmi, setOpenConfirmEmi] = useState(false);
  const dateInputRef = useRef(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    height: "632px",
    bgcolor: "background.paper",
    borderRadius: "0.5rem",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const fetchAllComments = async (app_id) => {
    try {
      setIsCommentsLoading(true);
      let { data } = await GetAllCommentCollection(app_id,null);
      setAllComments({
        loaded: true,
        collection: data?.data || [],
        gps_comments: data?.gps_comments || [],
      });
      setCommentsData(
        type === "COLLECTION" ? data?.data || [] : data?.gps_comments || []
      );
      setCustomerName(data?.customer_name);
      setEnableEmiReminder(data?.enable_emi_reminder);
      setVehicleStatus(data?.vehicle_status);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Failed to fetch comments"
      );
      console.log(error);
    }
    setIsCommentsLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
    setCollectionCSV(null);
    setSelectedField("");
    setComments("");
    setCommentId("");
    setEdit(false);
    // loadComments(commentsData);
  };

  const handleClickOpen = () => {
    setOpen(true);
    if (defaultType !== "COLLECTION") {
      setSelectedField(defaultType);
    }
  };

  const SendAllComments = async (index) => {
    setLoading(true);
    try {
      let payload = {
        user_name: user?.name,
        tag: selectedField[0],
        comment: comments,
        ...(followDate !== null ? { followup_date: followDate } : {}),
      };

      let { data } = await AddCommentCollection(
        `${applicationData?.application_id}`,
        payload
      );

      toast.success(data?.message);
      if (followDate && handleTableUpdate) {
        handleTableUpdate(applicationData?.index, followDate);
      }
      fetchAllComments(applicationData?.application_id);
      if (loadComments) {
        loadComments(commentsData);
      }
      handleClose();
    } catch (error) {
      console.log(error);
      toast?.error(
        error?.response?.data?.message || error?.message || "Some error"
      );
    }
    setLoading(false);
  };

  const handleEditComment = async () => {
    setLoading(true);
    try {
      let payload = {
        user_name: user?.name,
        comment: comments,
        tag: selectedField[0],
        application_id: applicationData?.application_id,
        comment_id: commentId || "",
      };

      let { data } = await editComment(payload);
      toast.success(data?.message);
      fetchAllComments(applicationData?.application_id);
      if (loadComments) {
        loadComments(commentsData);
      }
      handleClose();
    } catch (error) {
      console.log(error);
      toast?.error(
        error?.response?.data?.message || error?.message || "Some error"
      );
    }
    setLoading(false);
  };

  const handleDeleteComment = async () => {
    setLoading(true);
    try {
      let { data } = await deleteComment(
        applicationData?.application_id,
        commentId
      );
      toast.success(data?.message);
      fetchAllComments(applicationData?.application_id);
      if (loadComments) {
        loadComments(commentsData);
      }
      handleClose();
    } catch (error) {
      console.log(error);
      toast?.error(
        error?.response?.data?.message || error?.message || "Some error"
      );
    }
    setLoading(false);
  };

  const handleDisableReminder = async () => {
    setOpenConfirmEmi(false);
    setLoading(true);
    try {
      let { data } = await updateEmiReminder({
        application_id: applicationData?.application_id,
      });
      setEnableEmiReminder(!enableEmiRemider);
      toast.success(data?.message);
    } catch (error) {
      console.log(error);
      toast?.error(
        error?.response?.data?.message || error?.message || "Some error"
      );
    }
    setLoading(false);
  };

  const handleOpenConfisc = () => {
    setOpenConsfic(true);
  };

  const handleCloseConfisc = () => {
    setOpenConsfic(false);
  };

  const handleCloseComments = () => {
    setAllComments({ loaded: false });
    setType(defaultType);
    setCommentsData([]);
    handleCloseView();
  };

  useEffect(() => {
    if (openView) {
      setCustomerName(
        type === "GPS"
          ? applicationData?.customer_name
          : applicationData?.customerData?.pan_details?.name ||
              applicationData?.customerData?.corporate_pan_details?.name ||
              "--"
      );
      fetchAllComments(applicationData?.application_id);
    }
    setType(defaultType ?? "COLLECTION");
  }, [openView, applicationData]);

  useEffect(() => {
    if (allComments?.loaded) {
      if (type === "COLLECTION") {
        setCommentsData(allComments?.collection || []);
      } else {
        setCommentsData(allComments?.gps_comments || []);
      }
    }
  }, [type]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          className="close-btn"
          // spacing={2}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div
          style={{
            //  border: "2px solid red",
            padding: "1rem",
          }}
        >
          <Typography
            sx={{
              color: "#000",
              // fontFamily: "Inter",
              fontSize: "1.5rem",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            Add Comments
          </Typography>

          <Grid container className="mt-4">
            <Grid item>
              <SelectionField
                selectedField={setSelectedField}
                value={selectedField}
                commentData={commentsData}
                type={type}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="w-100 mt-2"
                id="outlined-multiline-static"
                multiline
                rows={4}
                placeholder="Write your Comments here."
                value={comments}
                onChange={(e) => {
                  setComments(e.target.value);
                }}
              />
            </Grid>
            {!isEdit && (
              <Grid item xs={6} className="mt-2">
                <p className="mb-0 mt-2">Follow-up Date</p>
                <TextField
                  type="date"
                  // value={followDate}
                  onChange={(e) => {
                    setFollowDate(new Date(e.target.value).getTime());
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
        <DialogActions>
          {isLoading ? (
            <Button className="mb-0" style={{ color: "#15659e" }}>
              <CircularProgress size={15} sx={{ mr: 1 }} /> Processing
            </Button>
          ) : (
            <>
              {isEdit && (
                <Button
                  className="comment-delete-btn"
                  onClick={handleDeleteComment}
                >
                  <DeleteOutlineIcon sx={{ fontSize: 15, mr: 0.2 }} /> Delete
                </Button>
              )}
              <Button
                style={{
                  padding: "0.5rem 1rem",
                  // justifyContent:"end",
                  borderRadius: "0.25rem",
                  background: "#72BDFF",
                  color: "#000",
                  fontSize: "1rem",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  width: "5rem",
                }}
                onClick={handleClose}
                onClick={() => {
                  if (isEdit) {
                    handleEditComment();
                  } else {
                    SendAllComments();
                  }
                }}
                disabled={selectedField === "" ? true : false}
              >
                {isEdit ? "Edit" : "add"}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      {/* ////////////  View Comment ////////////// */}

      <Dialog
        open={openView}
        onClose={handleCloseComments}
        fullWidth
        maxWidth={"lg"}
      >
        <Grid container style={{ borderBottom: "1px solid gray" }}>
          <Grid md={4} className="p-3">
            <p className="mb-0">
              Application ID: {applicationData?.application_id}
            </p>
            <p className="mb-0" style={{ fontWeight: 700 }}>
              {customerName}
            </p>
          </Grid>
          <Grid md={8}>
            <div className="d-flex justify-content-end p-3 pe-5">
              {!isCommentsLoading && defaultType === "COLLECTION" && Number(user?.role) !== 3 && (
                <Button
                  sx={{ mr: 1, fontSize: 12 }}
                  onClick={() => setOpenConfirmEmi(true)}
                  style={
                    !enableEmiRemider
                      ? {
                          background: "red",
                          color: "white",
                        }
                      : {}
                  }
                >
                  <DisabledByDefaultOutlined sx={{ fontSize: 18, mr: 0.5 }} />{" "}
                  {enableEmiRemider ? "Disable " : "Enable "}EMI Reminder
                </Button>
              )}
              <Button sx={{ mr: 1, fontSize: 12 }} onClick={handleClickOpen}>
                <AddIcon sx={{ fontSize: 18, mr: 0.5 }} /> Add {type} Comments
              </Button>
              {defaultType === "COLLECTION" && Number(user?.role) !== 3 && (
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => handleOpenConfisc()}
                  color={Boolean(vehicleStatus) ? "error" : "primary"}
                  sx={{
                    fontSize: 12,
                    // color: "white",
                    borderRadius: "8px",
                    paddingX: 2,
                  }}
                >
                  <ElectricRickshawOutlined sx={{ mr: 0.5, fontSize: 18 }} />{" "}
                  {Boolean(vehicleStatus)
                    ? vehicleStatusConst?.[vehicleStatus]
                    : "Vehicle Status"}
                </Button>
              )}
            </div>
          </Grid>
          <Grid xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                paddingRight: 20,
                marginBottom: 10
              }}
            >
              <ToggleButtonGroup
                color="primary"
                value={type === "COLLECTION" ? "COLLECTION" : "GPS"}
                exclusive
                size="small"
                onChange={(event, val) => {
                  if (val) {
                    setType(val);
                  }
                }}
                aria-label="Platform"
              >
                <ToggleButton value="COLLECTION">
                  Collection Comments
                </ToggleButton>
                <ToggleButton value="GPS">GPS Comments</ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Grid>
        </Grid>
        <div style={{ overflow: "auto", height: "100%", padding: 10 }}>
          <div
            style={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <IconButton aria-label="close" onClick={handleCloseComments}>
              <CloseIcon />
            </IconButton>
          </div>
          {isCommentsLoading ? (
            <CircularProgress
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                translate: "-50%",
                // color: (theme) => theme.palette.grey[500],
              }}
            />
          ) : (
            <>
              {!isLoading && commentsData?.length === 0 ? (
                <div className="content-center w-100">
                  <img src={noDataIco} width={300} />
                </div>
              ) : (
                <Table className="comments-table" style={{ fontSize: 14 }}>
                  <thead>
                    <tr>
                      <th style={{ width: "15%" }}>Date</th>
                      <th style={{ width: "15%" }}>Agent Name</th>
                      <th style={{ width: "15%" }}>Tags</th>
                      <th style={{ width: "45%" }}>Comments</th>
                      <th style={{ width: "10%" }}></th>
                    </tr>
                  </thead>
                  {commentsData.map((commentData) => {
                    return (
                      <tbody>
                        <tr>
                          <td style={{ fontSize: 12 }}>
                            {moment(commentData?.DateMS).format("lll")}
                          </td>
                          <td>{commentData?.user_name}</td>
                          <td>{commentData?.tag}</td>
                          <td style={{ position: "relative" }}>
                            {commentData?.isEdited && (
                              <p className="comments-edited-tag">Editted</p>
                            )}
                            <pre style={{ width: "100%", fontSize: 15 }}>
                              {commentData?.comment}
                            </pre>
                          </td>
                          <td>
                            {defaultType === "COLLECTION" && (
                              <Button
                                sx={{ fontSize: 12 }}
                                onClick={() => {
                                  setEdit(true);
                                  setSelectedField(commentData?.tag || "");
                                  setComments(commentData?.comment || "");
                                  setCommentId(commentData?.comment_id);
                                  handleClickOpen();
                                }}
                              >
                                <EditOutlined sx={{ fontSize: 12, mr: 0.5 }} />
                                Edit
                              </Button>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </Table>
              )}
            </>
          )}
        </div>
      </Dialog>

      <VehicleConfisication
        open={openConfisc}
        applicationData={applicationData}
        handleClose={handleCloseConfisc}
        getVehicleStatus={vehicleStatus}
        setGetVehicleStatus={setVehicleStatus}
      />

      <Dialog
        open={openConfirmEmi}
        onClose={() => setOpenConfirmEmi(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm your action"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to {enableEmiRemider ? "DISABLE" : "ENABLE"} EMI
            reminder for {applicationData?.application_id} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmEmi(false)}>No</Button>
          <Button onClick={handleDisableReminder}>
            {enableEmiRemider ? "DISABLE" : "ENABLE"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CommentsModal;
