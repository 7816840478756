import { Button } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CustomTableV2 from "../../Components/CustomTable/CustomTableV2";
import {
  exportCallingDashboard,
  getCallingDashboardData,
  getCallingDashboardDetailedData
} from "../../api";
import { capitalize } from "../../utility/Formater";
import CallingDashboardByEvent from "./CallingDashboardByEvent";

const CallingDashboard = ({ type, filter, startDate, dueDate }) => {
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [detailedView, setDetailedView] = useState(null);
  const fetchTableData = async () => {
    try {
      setLoading(true);
      const { data } = await getCallingDashboardData(filter, startDate,dueDate);
      setTableData(data?.data);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "failed to fetch data !"
      );
    } finally {
      setLoading(false);
    }
  };
  const exportData = async (template_name) => {
    let { data } = await exportCallingDashboard(
      template_name,
      type,
      filter
    );
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${type}-${moment().format("DD-MM-YYYY")}-${filter}-${template_name}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const createClickHandler = (templateName, columnName,) => async () => {
    try {
      setLoading(true);
      const response = await getCallingDashboardDetailedData(filter, templateName, columnName, startDate, dueDate);
      setDetailedView({ columnName, templateName, data: response.data });
    } catch (error) {
      toast.error("Failed to fetch detailed data!");
    } finally {
      setLoading(false);
    }
  };
  
  let columns_calling = [
    {
      label: <span className="text-nowrap" >Template Name</span>,
      render: (index) => {
        return capitalize(tableData?.call_data?.[index]?.template_name, /[ _]/g);
      },
      width: 200,
    },
    {
      label: "Total Calls",
      render: (index) => {
        return tableData?.call_data?.[index]?.total;
      },
      width: 200,
    },
    {
      label: "Answered",
      render: (index) => {
        return tableData?.call_data?.[index]?.answered;
      },
      width: 200,
    },
    {
      label:<span className="text-nowrap" >Not Answered</span>,
      render: (index) => {
        return tableData?.call_data?.[index]?.not_answered;
      },
      width: 100,
    },
    {
      label: "",
      render: (index) => {
        return (
          <Button
            onClick={() => {
              exportData(tableData?.call_data?.[index]?.template_name);
            }}
          >
            Download
          </Button>
        );
      },
    },
  ];

  const columns_messaging = [
    {
      label: <span className="text-nowrap">Template Name</span>,
      render: (index) => {
        let temp_name = tableData?.fyno_data?.[index]?.template_name;
        return <span key={temp_name}>{capitalize(temp_name, "_")}</span>;
      },
      width: 200,
    },
    {
      label: "Triggered",
      render: (index) => {
        let total = tableData?.fyno_data?.[index]?.total;
        return <span key={total}>{total}</span>;
      },
      width: 200,
    },
    {
      label: "Undelivered",
      render: (index) => {
        const value = tableData?.fyno_data?.[index]?.undelivered;
        return <span
        onClick={createClickHandler(tableData?.fyno_data?.[index]?.template_name, "UNDELIVERED")}
        style={{ cursor: "pointer", textDecoration: "underline" }}>
          {value}</span>
      },
      width: 200,
    },
    {
      label: "Sent",
      render: (index) => {
        const value = tableData?.fyno_data?.[index]?.sent;
        return <span
        onClick={createClickHandler(tableData?.fyno_data?.[index]?.template_name, "PROCESSED")}
        style={{ cursor: "pointer", textDecoration: "underline" }}
      >
        {value}
      </span>
      },
      width: 200,
    },
    {
      label: "Delivered",
      render: (index) => {
        const value = tableData?.fyno_data?.[index]?.delivered;
        return <span
        onClick={createClickHandler(tableData?.fyno_data?.[index]?.template_name, "DELIVERED")}
        style={{ cursor: "pointer", textDecoration: "underline" }}
      >
        {value}
      </span> 
      },
      width: 200,
    },
    {
      label: "Read",
      render: (index) => {
        const value = tableData?.fyno_data?.[index]?.read;
        return <span
        onClick={createClickHandler(tableData?.fyno_data?.[index]?.template_name, "READ")}
        style={{ cursor: "pointer", textDecoration: "underline" }}
      >
        {value}
      </span> 
      },
      width: 200,
    },
    {
      label: "Replies",
      render: (index) => {
        const value = tableData?.fyno_data?.[index]?.replies_count;
        return <span
        onClick={createClickHandler(tableData?.fyno_data?.[index]?.template_name, "REPLIED")}
        style={{ cursor: "pointer", textDecoration: "underline" }}
      >
        {value}
      </span>
      },
      width: 200,
    },
    {
      label: "Positive",
      render: (index) => {
        return tableData?.fyno_data?.[index]?.positive;
      },
      width: 200,
    },
    {
      label: "Negative",
      render: (index) => {
        return tableData?.fyno_data?.[index]?.negative;
      },
      width: 200,
    },
    {
      label: "Inconslusive",
      render: (index) => {
        return tableData?.fyno_data?.[index]?.inconslusive;
      },
      width: 200,
    },
    {
      label: "",
      render: (index) => {
        return (
          <Button
            onClick={() => {
              exportData(tableData?.fyno_data?.[index]?.template_name);
            }}
          >
            Download
          </Button>
        );
      },
    },
  ];


  useEffect(() => {
    fetchTableData();
  }, [filter]);

  const columns = type == "calling" ? columns_calling : columns_messaging;
  const data = type == "calling" ? tableData?.call_data : tableData?.fyno_data;

  // return (
  //   <>
  //     <div>
  //       <div style={{ width: "100%" }}>
  //         <CustomTableV2
  //           tableData={data}
  //           tableHeight={1000}
  //           columns={columns}
  //           loadingRows={8}
  //           isLoading={loading}
  //           borderBottom={true}
  //         />
  //       </div>
  //     </div>
  //   </>
  // );
  return (
    <>
      <div>
        {detailedView ? (
          <CallingDashboardByEvent
            messagingData={detailedView.data.data}
            onClose={() => setDetailedView(null)}
          />
        ) : (
          <div style={{ width: "100%", height: "100%" }}>
            <CustomTableV2
              tableData={data}
              maxHeight={"60dvh"}
              columns={columns}
              loadingRows={8}
              isLoading={loading}
              borderBottom={true}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CallingDashboard;
