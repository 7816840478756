import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import { Stack } from "@mui/system";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import { GlobalContext } from "../../Context/GlobalContext";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Fab from "@mui/material/Fab";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { f } from "../../utility/Formater";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { gpsData } from "./constants/gpsData";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  margin: 0,
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: 1,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
}));

const GPSTab = ({ applicationId }) => {
  const [isLoader, setLoader] = useState(false);
  const [gpsDetails, setGpsDetails] = useState({});

  useEffect(() => {
    setGpsDetails(
      gpsData?.find((val) => val?.application_id === applicationId) || {}
    );
  }, [applicationId]);

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        {Object.keys(gpsDetails).length === 0 && (
          <p>
            <WarningAmberIcon /> No data available
          </p>
        )}
        <Grid container spacing={2} className="mb-5">
          <Grid item xs={12} sm={6} md={3}>
            <div className="over-view-card">
              <p className="head">Total distance covered</p>
              <p className="amount mt-1" style={{ fontSize: 20 }}>
                {f(gpsDetails?.distance_covered, "km")}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="over-view-card">
              <p className="head" style={{ fontSize: 12 }}>
                Total running hours
              </p>
              <p className="amount mt-1" style={{ fontSize: 20 }}>
                {f(gpsDetails?.running_hour)}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="over-view-card">
              <p className="head">Avg distance covered per day</p>
              <p className="amount mt-1" style={{ fontSize: 20 }}>
                {f(gpsDetails?.avg_distance, "km")}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="over-view-card">
              <p className="head">Avg. hrs travelled per day</p>
              <p className="amount mt-1" style={{ fontSize: 20 }}>
                {f(gpsDetails?.avg_hours)}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="over-view-card">
              <p className="head">Avg. Speed</p>
              <p className="amount mt-1" style={{ fontSize: 20 }}>
                {f(gpsDetails?.avg_speed, "km")}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="over-view-card">
              <p className="head">Currently Running</p>
              <p className="amount mt-1" style={{ fontSize: 18 }}>
                {f(gpsDetails?.running_status?.toUpperCase())}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="over-view-card">
              <p className="head">Battery %</p>
              <p className="amount mt-1" style={{ fontSize: 20 }}>
                {f(gpsDetails?.battery)}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="over-view-card">
              <p className="head">KM left before charging</p>
              <p className="amount mt-1" style={{ fontSize: 20 }}>
                {f(gpsDetails?.km_before_charge, "km")}
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default GPSTab;
