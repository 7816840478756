import React from "react";
import { useEffect } from "react";
import { generateEmiAndEdiSchemeV2 } from "../utility/schedule/useCalculate";
import { useState } from "react";
import { Button, Dialog, Grid, TextField } from "@mui/material";
import generateTablePDF from "../utility/pdfGenerate";

const TestScreen = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [basiData, setBasicData] = useState({
    disbursalDate: "",
    tenure: "",
    loanAmount: "",
    interest: "",
    type: "MONTHS",
  });

  const handleChange =
    (prop, type = "text") =>
    (event) => {
      setBasicData({
        ...basiData,
        [prop]: event.target.value,
      });
    };

  useEffect(() => {
    // console.log("Test Screen");
    (async () => {})();
  }, []);

  const handleCalculate = async () => {
    const { disbursalDate, tenure, loanAmount, interest, type } = basiData;
    let cal = await generateEmiAndEdiSchemeV2(
      disbursalDate.trim(),
      Number(tenure.trim()),
      Number(loanAmount.replace(/,/g, '')),
      Number(interest.trim()),
      "MONTHS"
    );
    // console.log(cal);
    setData(cal);
    setOpen(false);
  };

  const handlePdf = () => {

    generateTablePDF(data?.efiSheet, {
      name: "Shameem Lukman",
      nbfc: "NY Leasing and Some other NBFC",
      loanAmount: basiData?.loanAmount,
      interest: basiData?.interest,
      tenure: basiData?.tenure,
      disbursalDate: basiData?.disbursalDate,
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            Change Values
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="p-4">
            <p>EMI</p>
            {data?.emiSheet?.map((val) => {
              return (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>{val?.SL_NO}</p>
                  <p>{val?.Due_Date}</p>
                  <p>{val?.EMI}</p>
                  <p>{val?.Principal}</p>
                  <p>{val?.Interest}</p>
                  <p>{val?.["O/s_Principal"]}</p>
                </div>
              );
            })}
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div
            className="p-4"
            style={{
              borderLeft: "2px solid black",
            }}
          >
            <p>EFI</p>
            {data?.efiSheet?.map((val) => {
              return (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>{val?.SL_NO}</p>
                  <p>{val?.Due_Date}</p>
                  <p>{val?.EFI}</p>
                  <p>{val?.Principal}</p>
                  <p>{val?.Interest}</p>
                  <p>{val?.["O/s_Principal"]}</p>
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className="p-4">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                id="name"
                name="name"
                label="Loan Amount"
                fullWidth
                required
                value={basiData?.loanAmount}
                onChange={handleChange("loanAmount")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="name"
                name="name"
                label="Interest Rate"
                fullWidth
                required
                value={basiData?.interest}
                onChange={handleChange("interest")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="name"
                name="name"
                label="Tenure"
                fullWidth
                required
                value={basiData?.tenure}
                onChange={handleChange("tenure")}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                id="name"
                name="name"
                label="Disbursal Date (DD/MM/YYYY)"
                fullWidth
                required
                value={basiData?.disbursalDate}
                onChange={handleChange("disbursalDate")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button variant="contained" onClick={handleCalculate}>
                Calculate
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  );
};

export default TestScreen;
