import jsPDF from "jspdf";
const tempDoc = new jsPDF();

export default function cobminePDF(data, fileName) {
  convertImagesToPDF(data, fileName);
}

function convertImagesToPDF(imagesArray, fileName) {
  const doc = new jsPDF();
  const imgPromises = [];
  // Iterate over the images array
  for (let i = 0; i < imagesArray.length; i++) {
    const img = new Image();
    const imgPromise = new Promise((resolve, reject) => {
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width, img.height);

        // Convert the image to a data URL
        const dataUrl = canvas.toDataURL("image/jpeg");

        // Calculate the page width and height based on the image dimensions
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const imgAspectRatio = img.width / img.height;
        let newWidth, newHeight;
        if (imgAspectRatio > 1) {
          newWidth = pageWidth;
          newHeight = newWidth / imgAspectRatio;
        } else {
          newHeight = pageHeight;
          newWidth = newHeight * imgAspectRatio;
        }

        // Add a new page to the PDF and insert the image
        if (i !== 0) {
          doc.addPage();
        }
        doc.addImage(dataUrl, "JPEG", 0, 0, newWidth, newHeight);

        resolve();
      };

      img.onerror = reject;
      img.src = imagesArray[i];
    });

    imgPromises.push(imgPromise);
  }

  // Wait for all images to load and create the PDF
  Promise.all(imgPromises)
    .then(() => {
      // Save the PDF
      doc.save(`${fileName}.pdf`);
    })
    .catch((error) => {
      console.error(error);
    });
}
