import { Button, Dialog, DialogActions, DialogTitle, Slide } from "@mui/material";
import React, { forwardRef } from "react";


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function DeletePopup({confDltPopup,setConfDltPopup,handleDeleteDoc , text}) {
  return (
    <Dialog
      open={confDltPopup}
      onClose={() => setConfDltPopup(false)}
      // fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      className="font-rocknroll"
    >
      <DialogTitle>
        <p
          className="font-rocknroll"
          style={{ fontSize: 19, color: "#605959", textAlign:'center' }}
        >
          { text ? text : "Are you sure you want to delete this document ?"}
        </p>
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleDeleteDoc}>
          <p
            className="font-rocknroll"
            style={{
              color: "#000",
              textTransform: "capitalize",
              fontSize: 18,
            }}
          >
            Yes
          </p>
        </Button>
        <Button onClick={() => setConfDltPopup(false)}>
          <p
            className="font-rocknroll"
            style={{
              color: "#000",
              textTransform: "capitalize",
              fontSize: 18,
            }}
          >
            No
          </p>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeletePopup;
