import { Button, Skeleton } from "@mui/material";

export const LoadingSkeleton = () => {
    return (
      <div className="sort-dialog">
        <div className="sort-table-wrapper">
          <table className="sort-table app-sort-table" id="app-sort-table">
            <thead className="cf">
              <tr>
                <th style={{ width: 500 }}>
                  <Skeleton animation="wave" width={"100%"} height={"100%"} />
                </th>
                <th style={{ width: "70%" }}>
                  <Skeleton animation="wave" width={"100%"} height={"100%"} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  data-title="Data Point"
                  className="py-3 d-flex flex-row align-items-center justify-content-center"
                >
                  <Skeleton animation="wave" width={"80%"} height={"100%"} />
                </td>
                <td data-title="Data Point" className="">
                  <div className="w-100 d-flex flex-row align-items-center justify-content-center">
                    <Skeleton animation="wave" width={"90%"} height={"100%"} />
                  </div>
                </td>
              </tr>
            </tbody>
            <Button className="mb-3">
              <Skeleton animation="wave" width={100} height={"100%"} />
            </Button>
          </table>
        </div>
        <div className="sort-bottom-holder">
          <Button className="me-2 clear-btn">
            <Skeleton animation="wave" width={"100%"} height={"100%"} />
          </Button>
  
          <Button variant="contained" className="apply-btn">
            <Skeleton animation="wave" width={100} height={"100%"} />
          </Button>
        </div>
      </div>
    );
  };