import React, { forwardRef, useImperativeHandle } from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { useState } from "react";
import { useEffect } from "react";
import { dpdDashboardMap } from "../../Constants/dpdDashboard";
import { fetchDpdDataMoment } from "../../api";
import { toast } from "react-toastify";
import { useContext } from "react";
import { GlobalContext } from "../../Context/GlobalContext";
import { useNavigate } from "react-router-dom";
import moment from "moment";
// import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DPDCollectionDialog from "../../Components/Dialogs/DPDCollections";
import TableCsvDownload from "../../Components/Download/TableCsvDownload";

const moveStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: "5px",
  paddingRight: "5px",
  height: 21,
  fontSize: 11,
};

const cf = (val) => {
  val = Number(val);
  if (!isNaN(val)) {
    val = Number(val?.toFixed(2));
    return `₹${val?.toLocaleString("en-IN")}`;
  } else {
    return 0;
  }
};

const DPDDashboardMoment = forwardRef((props, ref) => {
  const { loader, setLoader, threshold, tillDate, level } = props;
  const [type, setType] = useState("");
  const [nbfcData, setNbfcData] = useState({});
  const [movementData, setMovementData] = useState(null);
  const [totalMovements, setTotalMovements] = useState({});
  const [staticData, setStaticData] = useState(null);
  const [totalData, setTotalData] = useState({});
  const [nbfcKeys, setNbfcKeys] = useState([]);
  const [bucketKeys, setBucketKeys] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const navigate = useNavigate();

  const { dpdCollectionState } = useContext(GlobalContext);
  const [dpdCollections, setDPDCollection] = dpdCollectionState;
  const [openDPDDialog, setOpenDPDDialog] = useState(false);

  useImperativeHandle(ref, () => ({
    async handleSubmit(threshold, level) {
      setShowTable(true);
      setLoader("DPD");
      try {
        setType(level);
        setNbfcData({});
        setTotalData([]);

        // Use moment to check if the date is today
        // const momentTillDate = moment(tillDate);
        // const isToday = momentTillDate.isSame(moment(), "day");

        // // If it's today, add the current time
        // if (isToday) {
        //   momentTillDate.set({
        //     hour: moment().hours(),
        //     minute: moment().minutes(),
        //     second: moment().seconds(),
        //   });
        // } else {
        //   // If it's not today, set the time to 8:00 PM
        //   momentTillDate.set({
        //     hour: 20,
        //     minute: 0,
        //     second: 0,
        //   });
        // }

        let { data } = await fetchDpdDataMoment({
          minimum_due_amount: threshold,
          level,
        });

        const { movement, static_dpd, total_movement_data, static_data_total } =
          data;
        const { nbfc_wise_data, total } = data?.bucket_data;
        setNbfcData(nbfc_wise_data || {});
        setMovementData(movement || null);
        if (static_data_total && static_dpd) {
          setStaticData({
            data: static_dpd || {},
            total: static_data_total || {},
          });
        }
        setTotalMovements(total_movement_data);
        setTotalData(total || {});
        setNbfcKeys(Object.keys(nbfc_wise_data || {}));
        setBucketKeys(Object.keys(dpdDashboardMap?.[level] || {}));
      } catch (error) {
        toast.error("Error occurred while loading DPD data");
      }
      setLoader(null);
    },
  }));

  const handleRowClick = (array) => {
    setDPDCollection(array);
    setOpenDPDDialog(true);
  };

  return showTable ? (
    <>
      {loader === null && Object?.keys(nbfcData || {})?.length !== 0 && (
        <>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              id={"dpd-moment-table"}
            >
              <TableHead sx={{ backgroundColor: "#D8E0FF" }}>
                <TableRow>
                  <TableCell>DPD Bucket</TableCell>
                  {nbfcKeys.map((nbfcKey) => (
                    <TableCell key={nbfcKey} align="center">
                      <span
                        style={{
                          fontWeight: 200,
                        }}
                      >
                        {nbfcData?.[nbfcKey]?.name?.split(" ")?.[0]}{" "}
                      </span>
                      <b>({nbfcData?.[nbfcKey]?.count})</b>
                    </TableCell>
                  ))}
                  <TableCell align="center">
                    <b>Total</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bucketKeys?.map((bucketKey) => (
                  <TableRow key={bucketKey}>
                    <TableCell component="th" scope="row" sx={{ minWidth: 80 }}>
                      {dpdDashboardMap?.[type]?.[bucketKey]}
                    </TableCell>

                    {nbfcKeys.map((nbfcKey) => {
                      let value =
                        nbfcData?.[nbfcKey]?.raw_data_count?.[bucketKey] || 0;
                      let up =
                        movementData?.[nbfcKey]?.up?.[`${bucketKey}_array`];
                      let down =
                        movementData?.[nbfcKey]?.down?.[`${bucketKey}_array`];
                      return (
                        <TableCell key={nbfcKey} align="center">
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              gap: "4px",
                              justifyContent: "center",
                            }}
                          >
                            <div>{value}</div>
                            {down && (
                              <span
                                style={{
                                  ...moveStyle,
                                  border: "1px solid green",
                                  color: "green",
                                }}
                              >
                                -&nbsp;{down}
                              </span>
                            )}
                            {up && (
                              <span
                                style={{
                                  ...moveStyle,
                                  border: "1px solid red",
                                  color: "red",
                                }}
                              >
                                +&nbsp;{up}
                              </span>
                            )}
                          </div>
                        </TableCell>
                      );
                    })}
                    <TableCell align="center">
                      <b>{totalData?.[`data_${bucketKey}_total`]}</b>&nbsp;(
                      {totalData?.[`data_${bucketKey}_percentage`]})
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Threshold Total</b>
                  </TableCell>

                  {nbfcKeys.map((nbfcKey) => (
                    <TableCell key={"total_" + nbfcKey} align="center">
                      <b>{nbfcData?.[nbfcKey]?.threshold_total || 0}</b>
                      &nbsp;(
                      {nbfcData?.[nbfcKey]?.threshold_total_percentage || "0%"})
                    </TableCell>
                  ))}

                  <TableCell align="center">
                    <b>{totalData?.data_total}</b>
                    &nbsp;({totalData?.data_total_percentage})
                  </TableCell>
                </TableRow>
                {movementData && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <b>Movement</b>
                    </TableCell>

                    {nbfcKeys.map((nbfcKey) => (
                      <TableCell
                        key={"total_" + nbfcKey}
                        align="center"
                        style={
                          (movementData?.[nbfcKey]?.movement_count || 0) > 0
                            ? {
                                cursor: "pointer",
                              }
                            : {}
                        }
                        onClick={() => {
                          if (
                            (movementData?.[nbfcKey]?.movement_count || 0) > 0
                          ) {
                            handleRowClick(
                              movementData?.[nbfcKey]?.movement_data_array || []
                            );
                          }
                        }}
                      >
                        <b>{movementData?.[nbfcKey]?.movement_count || 0}</b>
                        &nbsp;(
                        {cf(movementData?.[nbfcKey]?.movement_total)})
                      </TableCell>
                    ))}

                    <TableCell align="center">
                      <b>
                        {totalMovements?.total_count} (
                        {cf(totalMovements?.total_amount)})
                      </b>
                    </TableCell>
                  </TableRow>
                )}

                {movementData && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <b>Static DPD</b>
                    </TableCell>

                    {nbfcKeys.map((nbfcKey) => (
                      <TableCell key={"total_" + nbfcKey} align="center">
                        <b>
                          {staticData?.data?.[nbfcKey]?.threshold_total || 0}
                        </b>
                        &nbsp;(
                        {staticData?.data?.[nbfcKey]
                          ?.threshold_total_percentage || "0%"}
                        )
                      </TableCell>
                    ))}

                    <TableCell align="center">
                      <b>
                        {staticData?.total?.total || 0} (
                        {staticData?.total?.total_percentage || "0%"})
                      </b>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TableCsvDownload
            tableId={"dpd-moment-table"}
            filename={"dpd-moment-data"}
            topRow={`As of,${moment(new Date()).format(
              "DD/MM/YYYY"
            )}\n\n`}
          />
        </>
      )}
      {loader === "DPD" && (
        <p
          className="text-center w-100 mt-5"
          style={{
            fontSize: "15px",
            color: "black",
          }}
        >
          <CircularProgress size={50} />
        </p>
      )}
      {loader === null && Object?.keys(nbfcData)?.length === 0 && (
        <p
          className="text-center w-100 mt-5"
          style={{
            fontSize: "15px",
            color: "black",
          }}
        >
          <DoNotDisturbIcon sx={{ fontSize: 15, mr: 0.3 }} /> No Data to display
        </p>
      )}

      <DPDCollectionDialog
        open={openDPDDialog}
        collectionData={dpdCollections || []}
        handleClose={() => {
          setDPDCollection([]);
          setOpenDPDDialog(false);
        }}
      />
    </>
  ) : null;
});

export default DPDDashboardMoment;
