import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getOemDetails, updateOemDetails } from "../api";
import { GlobalContext } from "../Context/GlobalContext";

function useOem() {
  const { oemState, emailState, panData } = useContext(GlobalContext);
  const [oemDetails, setOemDetails, uploadOemDocs, setUploadOemDocs] = oemState;

  function isDoc(docs) {
    return Array.isArray(docs) ? docs : [];
  }

  function formatCards(card, field) {
    if (card?.length) return card;

    if (field === "address") {
      return [{ address_type: "", pincode: "", address: "" }];
    }

    if (field === "vehicle_details") {
      return [{ ex_showroom_price: 0, vehicle_model: "" }];
    }

    if (field === "contact") {
      return [{ name: "", mobile_number: "", email: "", position: "" }];
    }
  }

  async function fetchOemDetails(oemId, setLoading, setInitOemDetails) {
    try {
      if (setLoading) setLoading(true);
      const { data } = await getOemDetails(oemId);
      const { oem } = data;

      if (data?.status) {
        let oemData = {
          ...oemDetails,
          ...oem,
          contact: formatCards(oem?.contact, "contact"),
          addresses: formatCards(oem?.addresses, "address"),
          vehicle_details: formatCards(oem?.vehicle_details, "vehicle_details"),
          documents: {
            oem_dealer_dump: isDoc(oem?.documents?.oem_dealer_dump),
            oem_aadhaar: isDoc(oem?.documents?.oem_aadhaar),
            oem_pan: isDoc(oem?.documents?.oem_pan),
            oem_brand_logo_picture: isDoc(
              oem?.documents?.oem_brand_logo_picture
            ),
            oem_icat_certificate: isDoc(oem?.documents?.oem_icat_certificate),
            oem_financial_statement: isDoc(
              oem?.documents?.oem_financial_statement
            ),
            oem_gstin: isDoc(oem?.documents?.oem_gstin),
            oem_bank_statement: isDoc(oem?.documents?.oem_bank_statement),
            oem_cheque: isDoc(oem?.documents?.oem_cheque),
            oem_brochure: isDoc(oem?.documents?.oem_brochure),
            oem_factory_picture: isDoc(oem?.documents?._factory_picture),
            oem_agreement: isDoc(oem?.documents?.oem_agreement),
            other_documents: isDoc(oem?.documents?.other_documents),
          },
        };
        setOemDetails(oemData);
        if(setInitOemDetails) setInitOemDetails(oemData);
        setUploadOemDocs([]);
      }
    } catch (error) {
      toast?.error(
        error?.response?.data?.message || "failed to get oem details"
      );
    } finally {
      if (setLoading) setLoading(false);
    }
  }

  function getDocObj(category, doc) {
    if (category === "oem_aadhaar") {
      return {
        oem_aadhaar_front: doc?.oem_aadhaar_front?.fileLink,
        oem_aadhaar_back: doc?.oem_aadhaar_back?.fileLink,
      };
    }
    if (category === "other_documents") {
      return {
        name: doc?.name,
        file: doc?.fileLink,
      };
    }
    return doc?.fileLink;
  }

  function buildCreateOemPayload(initOemDetails) {
    let payload = {};
    Object.keys(oemDetails).map((item) => {
      if (item === "documents") {
        let documents = [];
        Object.keys(oemDetails[item]).map((itm) => {
          let fileArray = [];
          oemDetails[item][itm]?.map((doc) => {
            fileArray.push(getDocObj(itm, doc));
            return;
          });
          documents.push({
            category: itm,
            fileArray,
          });
          return;
        });
        payload = {
          ...payload,
          documents,
        };
      } else if (Array.isArray(oemDetails[item])) {
        for (let itm in oemDetails[item][0]) {
          if (oemDetails?.[item]?.[0]?.[itm] !== "") {
            payload = {
              ...payload,
              [item]: oemDetails[item],
            };
            return;
          }
        }
        return;
      } else if (
        typeof oemDetails[item] === "string" &&
        oemDetails?.[item] !== ""
      ) {
        payload = {
          ...payload,
          [item]: oemDetails[item],
        };
      }
      return;
    });
    return payload;
  }

  function buildUpdateOemPayload(initOemDetails) {
    let oemUpdateQuery = {};
    let oemDocuments = [];
    Object.keys(oemDetails).map((item) => {
      if (item === "documents") {
        oemDocuments = uploadOemDocs;
        return;
      } else if (!initOemDetails[item] && Array.isArray(oemDetails[item])) {
        if (oemDetails[item].length > 1) {
          oemUpdateQuery = {
            ...oemUpdateQuery,
            [item]: oemDetails[item],
          };
        } else {
          for (let keys in oemDetails[item][0]) {
            if (oemDetails?.[item]?.[0]?.[keys] !== "") {
              oemUpdateQuery = {
                ...oemUpdateQuery,
                [item]: oemDetails[item],
              };
              return;
            }
          }
        }
        return;
      } else if (
        Array.isArray(initOemDetails[item]) &&
        Array.isArray(oemDetails[item])
      ) {
        oemUpdateQuery = {
          ...oemUpdateQuery,
          [item]: oemDetails[item],
        };
        return;
      } else if (initOemDetails[item] !== oemDetails[item]) {
        oemUpdateQuery = {
          ...oemUpdateQuery,
          [item]: oemDetails[item],
        };
        return;
      }
      return;
    });
    return {
      oemUpdateQuery,
      oemDocuments,
    };
  }

  function handleUpdateDocs(operation, category, doc, fileName) {
    if (operation === "add") {
      setUploadOemDocs((prev) => [
        ...prev,
        {
          id: new Date().getMilliseconds(),
          new: true,
          category,
          documentFile: doc, //base64
          fileName,
        },
      ]);
      return;
    }
    if (operation === "remove" && doc?.isUpload) {
      setUploadOemDocs((prev) => {
        return [
          ...prev?.filter((item) => {
            if (category === "oem_aadhaar") return item?.id !== doc?.id;
            return item?.fileName !== doc?.name;
          }),
        ];
      });
      return;
    }

    if (operation === "remove" && !doc?.isUpload) {
      setUploadOemDocs((prev) => [
        ...prev,
        (() => {
          if (category === "oem_aadhaar") {
            return {
              new: false,
              category,
              oem_aadhaar_front: {
                fileLink: doc?.oem_aadhaar_front?.fileLink,
                fileName: doc?.oem_aadhaar_front?.name,
              },
              oem_aadhaar_back: {
                fileLink: doc?.oem_aadhaar_back?.fileLink,
                fileName: doc?.oem_aadhaar_back?.name,
              },
            };
          }
          return {
            new: false,
            category,
            fileName: doc?.name,
            fileLink: doc?.fileLink,
          };
        })(),
      ]);
      return;
    }
  }

  function getDocName(arr, category) {
    if (arr?.length === 0) return `${category}_1`;
    let lastNum = "";
    if (category === "oem_aadhaar_front" || category === "oem_aadhaar_back") {
      let lastIndex =
        arr?.[arr?.length - 1]?.[category]?.name?.lastIndexOf("_");
      lastNum =
        +arr?.[arr?.length - 1]?.[category]?.name?.slice(lastIndex + 1) + 1;
    } else {
      let lastIndex = arr?.[arr?.length - 1]?.name?.lastIndexOf("_");
      lastNum = +arr?.[arr?.length - 1]?.name?.slice(lastIndex + 1) + 1;
    }
    return `${category}_${lastNum}`;
  }

  function addDocument(field, file, name) {
    setOemDetails((prev) => {
      const fileName = name
        ? name
        : getDocName(prev?.documents?.[field], field);
      handleUpdateDocs("add", field, file, fileName);

      return {
        ...prev,
        documents: {
          ...prev?.documents,
          [field]: [
            ...(prev?.documents?.[field] || []),
            {
              id: new Date().getMilliseconds(),
              name: fileName,
              isUpload: true,
              fileLink: file,
              dataType: file?.slice(5, 10) === "image" ? "image" : "pdf",
            },
          ],
        },
      };
    });
  }

  function removeDocument(field, i) {
    setOemDetails((prev) => {
      let doc = prev?.documents?.[field];
      handleUpdateDocs("remove", field, doc?.[i]);
      return {
        ...prev,
        documents: {
          ...prev.documents,
          [field]: doc?.filter((itm, idx) => idx !== i),
        },
      };
    });
  }

  function getDocArr(step) {
    let allDocs = oemDetails?.documents;
    if (step === 1) {
      let aadharArr = allDocs?.oem_aadhaar?.reduce((acc, itm) => {
        return [
          ...acc,
          { ...(itm?.oem_aadhaar_front || {}) },
          { ...(itm?.oem_aadhaar_back || {}) },
        ];
      }, []);
      return [
        ...isDoc(allDocs?.oem_gstin),
        ...isDoc(allDocs?.oem_icat_certificate),
        ...isDoc(allDocs?.oem_financial_statement),
        ...isDoc(allDocs?.oem_brand_logo_picture),
        ...isDoc(allDocs?.oem_bank_statement),
        ...isDoc(allDocs?.oem_cheque),
        ...isDoc(allDocs?.oem_brochure),
        ...isDoc(aadharArr),
        ...isDoc(allDocs?.oem_pan),
        ...isDoc(allDocs?.factory_picture),
        ...isDoc(allDocs?.oem_agreement),
        ...isDoc(allDocs?.other_documents),
      ];
    } else if (step === 3) {
      return [...(allDocs?.oem_dealer_dump || [])];
    }
  }

  async function handleSingleDocReupload(
    base64,
    dataType,
    currDoc,
    handleClose,
    setLoading
  ) {
    try {
      setLoading("reuploading " + currDoc?.name);
      let reuploadDoc = [];

      if (currDoc?.category === "oem_aadhaar") {
        let index = currDoc?.name?.lastIndexOf("_");
        let docName = currDoc?.name?.slice(0, index);
        reuploadDoc = [
          {
            new: false,
            reupload: true,
            category: currDoc?.category,
            [docName]: {
              fileLink: currDoc?.fileLink,
              fileName: currDoc?.name,
            },
          },
          {
            new: true,
            category: currDoc?.category,
            [docName]: base64,
          },
        ];
      } else {
        reuploadDoc = [
          {
            new: false,
            reupload: true,
            category: currDoc?.category,
            fileLink: currDoc?.fileLink,
            fileName: currDoc?.name,
          },
          {
            new: true,
            category: currDoc?.category,
            documentFile: base64,
          },
        ];
        if (currDoc?.category === "other_documents") {
          reuploadDoc[1]["fileName"] = currDoc?.name;
        }
      }

      let payload = {
        oem_id: oemDetails?.oem_id,
        oemUpdateQuery: {},
        oemDocuments: reuploadDoc,
      };
      let response = await updateOemDetails(payload);
      if (!response?.status) {
        toast.error(response?.response?.data?.message || "reupload failed !!!");
        return;
      }
      toast.success(
        response?.data?.message || "Document Reuploaded Successfully"
      );
      await fetchOemDetails(oemDetails?.oem_id);
    } catch (error) {
      toast?.error(error?.response?.data?.message || "reupload failed !!!");
    } finally {
      handleClose();
    }
  }

  async function handleSingleDocDelete(currDoc, handleClose, setLoading) {
    try {
      setLoading(`deleting ${currDoc?.name}...`);
      let deleteDocObj = {
        new: false,
        category: currDoc?.category,
      };

      if (currDoc?.category === "oem_aadhaar") {
        let aadhaar = currDoc?.aadharData;
        aadhaar.oem_aadhaar_front.fileName = aadhaar?.oem_aadhaar_front?.name;
        aadhaar.oem_aadhaar_back.fileName = aadhaar?.oem_aadhaar_back?.name;
        deleteDocObj = {
          ...deleteDocObj,
          ...aadhaar,
        };
      } else {
        deleteDocObj["fileLink"] = currDoc?.fileLink;
        deleteDocObj["fileName"] = currDoc?.name;
      }

      let payload = {
        oem_id: oemDetails?.oem_id,
        oemUpdateQuery: {},
        oemDocuments: [deleteDocObj],
      };
      let response = await updateOemDetails(payload);
      if (!response?.status) {
        toast.error(
          response?.response?.data?.message || "failed to delete document !!!"
        );
        return;
      }
      toast.success(response?.data?.message || "Document Deleted Successfully");
      await fetchOemDetails(oemDetails?.oem_id);
    } catch (error) {
      toast?.error(
        error?.response?.data?.message || "failed to delete document !!!"
      );
    } finally {
      handleClose();
    }
  }

  return {
    fetchOemDetails,
    addDocument,
    removeDocument,
    setUploadOemDocs,
    getDocName,
    buildCreateOemPayload,
    buildUpdateOemPayload,
    getDocArr,
    handleSingleDocDelete,
    handleSingleDocReupload,
  };
}

export default useOem;
