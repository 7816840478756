// import * as React from "react";
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { GlobalContext } from "../../Context/GlobalContext";

const initContact = [{ name: "", mobile_number: "", email: "", position: "" }];

export default function CoApplicant({ setActiveStep, isDisabled }) {
  const { oemState } = React.useContext(GlobalContext);
  const [oemDetails, setOemDetails] = oemState;

  const handleContactAddClick = (e) => {
    e.preventDefault();
    setOemDetails((prev) => {
      return {
        ...prev,
        contact: [...prev?.contact, { ...initContact }],
      };
    });
  };

  const handleContactDelClick = (index) => (e) => {
    setOemDetails((prev) => {
      return {
        ...prev,
        contact: prev?.contact?.toSpliced(index,1),
      };
    });
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid xs={12} sx={12}>
          {oemDetails?.contact?.map((c, index) => {
            return (
              <div
                style={{
                  backgroundColor: "#F7F7F7",
                  marginTop: 20,
                  padding: 15,
                  paddingBottom: 40,
                  position: "relative",
                  // width: "100%",
                }}
              >
                {index !== 0 && !isDisabled && (
                  <Button
                    className="remove-oem-btn"
                    onClick={handleContactDelClick(index)}
                  >
                    <CloseIcon sx={{ fontSize: 17, mr: 0.5 }} /> Remove
                  </Button>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      // required
                      id="name"
                      name="name"
                      label="Name"
                      fullWidth
                      disabled={isDisabled}
                      // autoComplete="email"
                      variant="standard"
                      value={c?.name}
                      onChange={(event) => {
                        let { value } = event.target;
                        setOemDetails((prev) => {
                          let temp = [...prev?.contact];
                          temp[index].name = value;
                          return { ...prev, contact: temp };
                        });
                      }}
                      // onChange={handleChange("brand_name")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      key={index}
                      // required
                      fullWidth
                      variant="standard"
                      disabled={isDisabled}
                      // sx={{ mt: 2 }}
                      size="small"
                      id="phone"
                      label="Mobile no."
                      type="number"
                      value={c.mobile_number === 0 ? "" : c.mobile_number}
                      onChange={(event) => {
                        if (event.target.value.length > 10) {
                          return;
                        }
                        let { value } = event.target;
                        setOemDetails((prev) => {
                          let temp = [...prev?.contact];
                          temp[index].mobile_number = value;
                          return { ...prev, contact: temp };
                        });
                      }}
                      // }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      // required
                      id="email"
                      name="email"
                      label="Email"
                      fullWidth
                      autoComplete="email"
                      disabled={isDisabled}
                      variant="standard"
                      value={c?.email}
                      onChange={(event) => {
                        let { value } = event.target;
                        setOemDetails((prev) => {
                          let temp = [...prev?.contact];
                          temp[index].email = value;
                          return {
                            ...prev,
                            contact: temp,
                          };
                        });
                      }}
                    />
                  </Grid>

                  <Grid
                    sx={{
                      mb: 3,
                    }}
                    item
                    xs={12}
                    sm={6}
                  >
                    <TextField
                      // required
                      id="position"
                      name="position"
                      // type={"number"}
                      label="Position"
                      disabled={isDisabled}
                      fullWidth
                      // autoComplete="email"
                      variant="standard"
                      value={c?.position}
                      onChange={(event) => {
                        let { value } = event.target;
                        setOemDetails((prev) => {
                          let temp = [...prev?.contact];
                          temp[index].position = value;
                          return {
                            ...prev,
                            contact: temp,
                          };
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          })}
          {!isDisabled && (
            <Grid item xs={12} sm={12}>
              <Button onClick={handleContactAddClick} sx={{ mt: 3, ml: 1 }}>
                <AddIcon />
                ADD CONTACT
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
