import React, { useState } from 'react';
// import { , Modal } from '@material-ui/core';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Dialog, DialogContent, Button } from '@mui/material';
import { createTemplate } from '../api';

const TemplateModal = ({ open, handleClose, valupdate }) => {
    const [campaignName, setCampaignName] = useState('');
    const [customMessage, setCustomMessage] = useState('');


    const handleChange = async () => {
        const data = {
            "data": {
                campaign: campaignName,
                type: "call",
                text: customMessage,
            }
        }

        const val = await createTemplate(data)
        console.log(val)
        valupdate()
        handleClose()
    };
    return (
        <Dialog open={open} onClose={handleClose} style={{ width: "100%" }}>
            <DialogContent >
                <div className="" style={{ width: "400px" }}>
                    <div className="d-flex mb-4" style={{ flexDirection: "column", height: "200px", width: "100%" }}>
                        Create Template
                        <>
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Campaign Name"
                                multiline
                                outlined
                                value={campaignName}
                                style={{ marginTop: "25px" }}
                                maxRows={4}
                                onChange={(e) => { setCampaignName(e.target.value) }}
                            />
                            <TextField
                                style={{ marginBottom: "25px", marginTop: "25px" }}
                                id="outlined-multiline-flexible"
                                label="Custom Message"
                                multiline
                                outlined
                                value={customMessage}
                                maxRows={4}
                                onChange={(e) => { setCustomMessage(e.target.value) }}
                            />
                        </>
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <Button outlined onClick={handleChange}>Submit</Button>
                    <Button outlined onClick={handleClose}>Close</Button>
                </div>

            </DialogContent>

        </Dialog >
    );
};

export default TemplateModal;

