export function rgbToHex(r, g, b) {
  const componentToHex = (c) => {
    // console.log(c);
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const hexR = componentToHex(r);
  const hexG = componentToHex(g);
  const hexB = componentToHex(b);

  return "#" + hexR + hexG + hexB;
}

export function hexToRgb(hex) {
  // Remove the '#' character if present
  hex = hex.replace("#", "");

  // Convert the hexadecimal value to decimal
  const bigint = parseInt(hex, 16);

  // Extract the RGB components
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Return the RGB values as an object
  return { r, g, b, a: 1 };
}
