// import * as React from "react";
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { GlobalContext } from "../../Context/GlobalContext";
import UploadSection from "../../Components/Anchor/UploadSection";
import AadhaarUpload from "../../Components/Anchor/AadhaarUpload";
import { useParams } from "react-router-dom";
import useOem from "../../hooks/useOem";
import UploadOtherDoc from "../../Components/PartnersTab/UploadOtherDoc";
import { toast } from "react-toastify";

export default function CoApplicant({
  setActiveStep,
  isDisabled,
  isDownloadAble,
}) {
  const { oemState } = React.useContext(GlobalContext);
  const [oemDetails, setOemDetails] = oemState;
  const {
    addDocument,
    removeDocument,
    setUploadOemDocs,
    fetchOemDetails,
    getDocArr,
    getDocName,
    handleSingleDocDelete,
    handleSingleDocReupload
  } = useOem();
  const [swipeArr, setSwipeArr] = useState(null);
  const { oemId } = useParams();

  const getDocumentsArray= () => getDocArr(1);

  return (
    <React.Fragment>
      <UploadSection
        // mt={20}
        heading={"GSTIN"}
        category={"oem_gstin"}
        docs={oemDetails?.["documents"]?.["oem_gstin"] || []}
        onChange={(file) => {
          addDocument("oem_gstin", file);
        }}
        onDelete={(file, i) => {
          removeDocument("oem_gstin", i);
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={oemId}
        swipeArr={swipeArr}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <UploadSection
        // mt={20}
        heading={"ICAT Certificate"}
        category={"oem_icat_certificate"}
        docs={oemDetails?.["documents"]?.["oem_icat_certificate"] || []}
        onChange={(file) => {
          addDocument("oem_icat_certificate", file);
        }}
        onDelete={(file, i) => {
          removeDocument("oem_icat_certificate", i);
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={oemId}
        swipeArr={swipeArr}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <UploadSection
        // mt={20}
        heading={"Financial Statement"}
        category={"oem_financial_statement"}
        docs={oemDetails?.["documents"]?.["oem_financial_statement"] || []}
        onChange={(file) => {
          addDocument("oem_financial_statement", file);
        }}
        onDelete={(file, i) => {
          removeDocument("oem_financial_statement", i);
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={oemId}
        swipeArr={swipeArr}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <UploadSection
        // mt={20}
        heading={"Brand logo picture"}
        category={"oem_brand_logo_picture"}
        docs={oemDetails?.["documents"]?.["oem_brand_logo_picture"] || []}
        onChange={(file) => {
          addDocument("oem_brand_logo_picture", file);
        }}
        onDelete={(file, i) => {
          removeDocument("oem_brand_logo_picture", i);
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={oemId}
        swipeArr={swipeArr}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <UploadSection
        // mt={20}
        heading={"Bank Statement"}
        category={"oem_bank_statement"}
        docs={oemDetails?.["documents"]?.["oem_bank_statement"] || []}
        onChange={(file) => {
          addDocument("oem_bank_statement", file);
        }}
        onDelete={(file, i) => {
          removeDocument("oem_bank_statement", i);
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={oemId}
        swipeArr={swipeArr}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <UploadSection
        // mt={20}
        heading={"Cheque"}
        category={"oem_cheque"}
        docs={oemDetails?.["documents"]?.["oem_cheque"] || []}
        onChange={(file) => {
          addDocument("oem_cheque", file);
        }}
        onDelete={(file, i) => {
          removeDocument("oem_cheque", i);
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={oemId}
        swipeArr={swipeArr}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <UploadSection
        // mt={20}
        heading={"Brochure"}
        category={"oem_brochure"}
        docs={oemDetails?.["documents"]?.["oem_brochure"] || []}
        onChange={(file) => {
          addDocument("oem_brochure", file);
        }}
        onDelete={(file, i) => {
          removeDocument("oem_brochure", i);
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={oemId}
        swipeArr={swipeArr}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <AadhaarUpload
        heading={"Aadhaar"}
        docs={oemDetails?.["documents"]?.["oem_aadhaar"] || []}
        aadhaar_back="oem_aadhaar_back"
        aadhaar_front="oem_aadhaar_front"
        category="oem_aadhaar"
        onChange={(file) => {
          file = {
            ...file,
            oem_aadhaar_front: {
              ...file?.oem_aadhaar_front,
              name: getDocName(
                oemDetails?.["documents"]?.["oem_aadhaar"],
                "oem_aadhaar_front"
              ),
            },
            oem_aadhaar_back: {
              ...file?.oem_aadhaar_back,
              name: getDocName(
                oemDetails?.["documents"]?.["oem_aadhaar"],
                "oem_aadhaar_back"
              ),
            },
          };
          setUploadOemDocs((prev) => {
            return [
              ...prev,
              {
                id: file?.id,
                new: true,
                isUpload: true,
                category: "oem_aadhaar",
                oem_aadhaar_front: file?.oem_aadhaar_front?.fileLink,
                oem_aadhaar_back: file?.oem_aadhaar_back?.fileLink,
              },
            ];
          });
          setOemDetails((prev) => {
            return {
              ...prev,
              documents: {
                ...prev.documents,
                ["oem_aadhaar"]: [
                  ...(prev?.documents?.["oem_aadhaar"] || []),
                  { ...file },
                ],
              },
            };
          });
        }}
        onDelete={(file, i) => {
          removeDocument("oem_aadhaar", i);
        }}
        onAddMore={(e) => {
          setOemDetails((prev) => {
            return {
              ...prev,
              documents: {
                ...prev?.documents,
                oem_aadhaar: [
                  ...prev?.documents?.oem_aadhaar,
                  {
                    oem_aadhaar_front: {},
                    oem_aadhaar_back: {},
                  },
                ],
              },
            };
          });
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={oemId}
        swipeArr={swipeArr}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <UploadSection
        // mt={20}
        heading={"Pan"}
        category={"pan"}
        docs={oemDetails["documents"]["oem_pan"] || []}
        onChange={(file) => {
          addDocument("oem_pan", file);
        }}
        onDelete={(file, i) => {
          removeDocument("oem_pan", i);
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={oemId}
        swipeArr={swipeArr}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <UploadSection
        // mt={20}
        heading={"Factory Picture"}
        category={"oem_factory_picture"}
        docs={oemDetails["documents"]["oem_factory_picture"]}
        onChange={(file) => {
          addDocument("oem_factory_picture", file);
        }}
        onDelete={(file, i) => {
          removeDocument("oem_factory_picture", i);
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={oemId}
        swipeArr={swipeArr}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <UploadSection
        // mt={20}
        heading={"OEM Agreement"}
        category={"oem_agreement"}
        docs={oemDetails?.["documents"]?.["oem_agreement"] || []}
        onChange={(file) => {
          addDocument("oem_agreement", file);
        }}
        onDelete={(file, i) => {
          removeDocument("oem_agreement", i);
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={oemId}
        swipeArr={swipeArr}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />

      <UploadOtherDoc
        // mt={20}
        heading={"Other Documents"}
        category={"other_documents"}
        docs={oemDetails?.["documents"]?.["other_documents"] || []}
        onChange={(file) => {
          addDocument("other_documents", file?.fileLink, file?.name);
        }}
        onDelete={(file, i) => {
          removeDocument("other_documents", i);
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={oemId}
        swipeArr={swipeArr}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
    </React.Fragment>
  );
}
