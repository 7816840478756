import { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DragDrop from "../../Components/DocumentUploader";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import TextField from "@mui/material/TextField";
import { GlobalContext } from "../../Context/GlobalContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DOUpload({
  open,
  handleClose,
  application_id,
  handleUpload,
}) {

  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [refId, setRefId] = useState(null);

  const handleUpdateReference = () => {
    if (file && refId) {
      handleUpload(file, refId);
    }
  };

  useEffect(() => {
    if (!open) {
      setFile(null);
      setFileName(null);
      setRefId(null);
    }else{
      if(updateDetails?.nbfc_reference_id){
        setRefId(updateDetails?.nbfc_reference_id)
      }
    }
  }, [open]);

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Delivery Order
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <p className="mb-1">
            Image <span style={{ color: "red" }}>*</span>
          </p>
          <div className="upload-section-do">
            {file === null ? (
              <div className="mb-4">
                <DragDrop
                  customType={["png", "jpg", "jpeg", "PDF"]}
                  setFile={(val, name) => {
                    setFile(val);
                    setFileName(name);
                  }}
                />
              </div>
            ) : (
              <>
                <div
                  className="mt-2 mb-3 d-flex"
                  style={{ width: "60%", justifyContent: "space-between" }}
                >
                  <div>
                    <InsertDriveFileIcon /> {fileName}
                  </div>
                  <CloseIcon
                    className="pointer"
                    onClick={() => {
                      setFile(null);
                      setFileName(null);
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <p className="mb-1">
            Reference Id <span style={{ color: "red" }}>*</span>
          </p>
          <TextField
            fullWidth
            sx={{ width: "80%" }}
            required
            value={refId}
            id="outlined-required"
            onChange={(e) => {
              setRefId(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateReference} disabled={!file && !refId}>
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
