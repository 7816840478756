import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DownloadingSharpIcon from "@mui/icons-material/DownloadingSharp";
import FileDownloadSharpIcon from "@mui/icons-material/FileDownloadSharp";
import { Button, Grid } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { anchorDocs } from "../../Constants/AnchorConstants";
import { GlobalContext } from "../../Context/GlobalContext";
import { UserContext } from "../../Context/UserContext";
import folder from "../../assets/folder.svg";
import { getBase64 } from "../../utility/convertFileBase";
import { handleDownload } from "../../utility/download";
import DocView from "./DocView";
import "./style.css";

const UploadSection = ({
  docs = [],
  heading,
  mt,
  onChange,
  onDelete,
  error,
  category,
  isEditable = true,
  isPan = false,
  isDownloadAble = false,
  anchor_id = null,
  swipeArr,
  getSwipeArr,
  handleSingleDocReupload,
  handleSingleDocDelete,
  supportingText,
  multiFileInput = false,
}) => {
  const { dealerDetailState, updateDocsState } = useContext(GlobalContext);
  const { user, setUser } = useContext(UserContext);
  const [isDownloading, setIsDownloading] = useState(null);
  const [dealerDetails, setDealerDetails] = dealerDetailState;
  const [updateDocs, setUpdateDocs] = updateDocsState;
  const fileInput = useRef(null);
  const multiFileInputRef = useRef(null);
  const [doc, setDoc] = useState({});

  return (
    <>
      <div style={{ marginTop: mt ? mt : 0 }}>
        <Grid container spacing={2} style={{ marginTop: 1 }}>
          {heading && (
            <Grid item xs={12} style={{ marginBottom: -10 }}>
              <span className="upload-section-heading">{heading}</span>
            </Grid>
          )}
          {docs?.length === 0 && isEditable && (
            <Grid item xs={2.5}>
              <Button
                variant={"contained"}
                className="upload-btn-anchor"
                onClick={(e) => {
                  if (multiFileInput) {
                    multiFileInputRef.current.click();
                  } else {
                    fileInput.current.click();
                  }
                }}
              >
                <CloudUploadOutlinedIcon sx={{ mr: 1, fontSize: 18 }} /> Upload
              </Button>
            </Grid>
          )}

          {docs?.length === 0 && !isEditable && (
            <Grid item>
              <div
                className="anchor-doc-preview"
                style={{
                  backgroundColor: "inherit",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img src={folder} height={"70px"} alt="document"></img>
              </div>
            </Grid>
          )}

          {docs?.length !== 0 &&
            docs?.map((doc, i) => (
              <Grid
                item
                xs={2.5}
                key={doc?.uploadedDateMS}
                sx={{ position: "relative", width: 300 }}
              >
                <div
                  className="w-100 h-100 position-absolute pointer z-1"
                  onClick={() => {
                    setDoc({ ...doc, open: true });
                  }}
                ></div>
                {isEditable && (
                  <Button
                    className="file-remove-btn z-2"
                    onClick={() => {
                      if (onDelete) {
                        onDelete(doc, i);
                        if (dealerDetails?.update) {
                          if (doc?.isUploaded) {
                            setUpdateDocs([
                              ...updateDocs?.filter(
                                (item) => item?.id === doc?.id
                              ),
                            ]);
                          } else {
                            setUpdateDocs([
                              ...updateDocs,
                              {
                                new: false,
                                fileLink: doc?.fileLink,
                                documentName: doc?.name,
                                category: doc?.category,
                                type: category,
                              },
                            ]);
                          }
                        }
                      }
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </Button>
                )}
                {isDownloadAble && (
                  <Button
                    className="file-download-btn z-2"
                    onClick={() => {
                      handleDownload(
                        doc?.fileLink,
                        `${anchor_id}_${doc?.name}`,
                        doc,
                        setIsDownloading,
                        {
                          user,
                          setUser,
                        }
                      );
                    }}
                    disabled={isDownloading?.name === doc?.name}
                  >
                    {isDownloading?.name === doc?.name ? (
                      <DownloadingSharpIcon style={{ fontSize: 16 }} />
                    ) : (
                      <FileDownloadSharpIcon style={{ fontSize: 16 }} />
                    )}
                  </Button>
                )}

                <div className="anchor-doc-preview">
                  {doc?.dataType?.includes("video") ? (
                    <video
                      src={doc?.fileLink + `${doc?.key ? "&file=true" : ""}`}
                      width="100%"
                      height="100%"
                      controls
                      itemType={doc?.dataType}
                    />
                  ) : (
                    <embed
                      key={doc?.key}
                      src={doc?.fileLink}
                      width={"100%"}
                      height={"100%"}
                    ></embed>
                  )}
                </div>
                {doc?.transcript && (
                  <span style={{ color: "green" }}>OCR done</span>
                )}
                <span className="anchor-doc-error">{doc?.error}</span>
              </Grid>
            ))}

          {isEditable && (
            <Grid item xs={2.5}>
              <Button
                variant={"outlined"}
                className={`upload-btn-anchor ${
                  docs?.length === 0 ? "add-more-disabled" : ""
                }`}
                disabled={docs?.length === 0}
                onClick={(e) => {
                  if (multiFileInput) {
                    multiFileInputRef.current.click();
                  } else {
                    fileInput.current.click();
                  }
                }}
              >
                <AddCircleOutlineOutlinedIcon sx={{ mr: 1, fontSize: 18 }} />{" "}
                Add more
              </Button>
            </Grid>
          )}
          {supportingText && (
            <Grid item className="supporting-text-cont-anchor">
              <span className="supporting-text-anchor">
                Expire on {supportingText}
              </span>
            </Grid>
          )}
        </Grid>
      </div>

      <input
        ref={fileInput}
        type="file"
        class="custom-file-input"
        accept="application/pdf , image/png, image/jpg, image/jpeg"
        onChange={async (e) => {
          let base64 = await getBase64(e.target.files[0]);

          onChange(base64, e.target.files[0].type);

          if (dealerDetails?.update && !isPan) {
            setUpdateDocs([
              ...updateDocs,
              {
                id: Number(new Date()),
                new: true,
                documentFile: base64,
                category,
                fileName: anchorDocs?.[category],
                type: category,
              },
            ]);
          }
          e.target.value = "";
        }}
        onClick={(e) => {
          e.target.value = "";
        }}
        hidden
      />
      <input
        ref={multiFileInputRef}
        multiple={false}
        type="file"
        class="custom-file-input"
        onChange={async (e) => {
          let base64 = await getBase64(e.target.files[0]);
          onChange(e.target.files, base64, e.target.files[0].type);
          e.target.value = "";
        }}
        onClick={(e) => {
          e.target.value = "";
        }}
        hidden
      />
      {doc?.open && (
        <DocView
          docs={doc}
          handleClose={() => {
            setDoc((prev) => ({ ...prev, open: false }));
          }}
          handleDownload={async (docs, setLoading, returnBlob) => {
            return await handleDownload(
              docs?.fileLink,
              `${anchor_id}_${docs?.name}`,
              docs,
              setLoading,
              {
                user,
                setUser,
              },
              returnBlob
            );
          }}
          category={category}
          isAadhaar={false}
          swipeDocs={swipeArr}
          getSwipeDocs={getSwipeArr}
          reuploadDoc={handleSingleDocReupload}
          deleteDocFun={handleSingleDocDelete}
        />
      )}
    </>
  );
};

export default UploadSection;
