import React, { useState, useEffect, useContext } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import _ from "lodash";
import {
  Button,
  CssBaseline,
  TextField,
  InputLabel,
  MenuItem,
  Grid,
  Box,
  Container,
  StepContent,
} from "@mui/material";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import useAuth from "../hooks/useAuth";
import Paper from "@mui/material/Paper";
import AddIco from "../../assets/add.svg";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import {
  createAnchor,
  updateDealerShared,
  fetchLatLng,
  createAnchorV2,
  anchorOCR,
  anchorPennyDrop,
  updateAnchorV2,
  deleteAnchor,
  getHoldBackApplicants,
} from "../../api";
import { GlobalContext } from "../../Context/GlobalContext";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BasicDetails from "./Steps/BasicDetails";
import BankDetails from "./Steps/BankDetails";
import ContactDetails from "./Steps/ContactDetails";
import LoadingOver from "../../Components/Loading";
import useAnchor from "../../hooks/useAnchor";
import { formatAnchorPayloadUpdate } from "../../utility/FormatAnchor";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { downloadAll } from "../../utility/download";
import { UserContext } from "../../Context/UserContext";
import DeletePopup from "../../Components/DeletePopup";
import HoldBackApplicantsPopup from "../../Components/Anchor/HoldBackApplicantsPopup";
import { f } from "../../utility/Formater";
import CommercialsHistoryPopUp from "../../Components/Anchor/CommercialsHistoryPopUp.js";
import HistoryIcon from "@mui/icons-material/History";

const theme = createTheme();
const steps = ["Basic Details", "Bank Details", "Contact Details"];
function getStepContent(
  step,
  setActiveStep,
  handleLoading,
  isDisabled,
  args = {}
) {
  switch (step) {
    case 0:
      return (
        <BasicDetails
          isUpdate={true}
          handleLoading={handleLoading}
          isDisabled={isDisabled}
          isDownloadAble={true}
        />
      );
    case 1:
      return (
        <BankDetails
          isDisabled={isDisabled}
          isDownloadAble={true}
          pennyDropLogs={args?.pennyDropLogs}
        />
      );
    case 2:
      return <ContactDetails isDisabled={isDisabled} isDownloadAble={true} />;
    default:
      throw new Error("Unknown step");
  }
}

function UpdateAnchor() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // const { createAnchor } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const { dealerDetailState, updateDocsState } = useContext(GlobalContext);
  const { user, setUser } = useContext(UserContext);
  const [updateDocs, setUpdateDocs] = updateDocsState;
  const [initialState, setInitialState] = useState({});
  const [dealerDetails, setDealerDetails] = dealerDetailState;
  const { getAnchorDetails, setActiveStep, activeStep, getDocArr, handleShopImagesUpload } =
    useAnchor();
  const [apiLoading, setApiLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [overLoading, setOverLoading] = useState({
    open: false,
    text: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [progress, setProgress] = useState(null);
  const [confDltPopup, setConfDltPopup] = useState(false);
  const { anchorId } = useParams();
  const [pennyDropLogs, setPennyDropLogs] = useState([]);
  const [HoldBackApplicantsPopupOpen, setHoldBackApplicantsPopupOpen] =
    useState(false);
  const [HoldBackApplicantsData, setHoldBackApplicantsData] = useState([]);
  const [HoldBackApplicantsLoading, setHoldBackApplicantsLoading] =
    useState(false);
  const [toBeCollected, setToBeCollected] = useState(0);
  const [commercialsHistoryPopUpOpen, setCommercialsHistoryPopUpOpen] =
    useState(false);
  const [commercialsHistoryData, setCommercialsHistoryData] = useState(
    dealerDetails?.commercials_history
  );
  const [commercialsHistoryCurrentData,setCommercialsHistoryCurrentData] = useState([]);


  const closeCommercialsHistoryPopUp = () => {
    setCommercialsHistoryPopUpOpen(false);
    setCommercialsHistoryData(dealerDetails?.commercials_history);
  };

  const handleLoading = (open, text = "") => {
    setOverLoading({
      open,
      text,
    });
  };

  const handleNext = () => {
    if (activeStep === 2) {
      handleUpdate();
    } else {
      if (activeStep === 0) {
        setActiveStep(activeStep + 1);
      } else if (activeStep === 1) {
        setActiveStep(activeStep + 1);
      } else {
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getDocName() {
    return (
      anchorId + `${activeStep === 0 ? "_Basic_Details" : "_Bank_Details"}`
    );
  }

  function compareObjects(obj1, obj2) {
    const diffObject = {};

    for (let key in obj1) {
      if (obj1.hasOwnProperty(key) && !obj2.hasOwnProperty(key)) {
        diffObject[key] = obj1[key];
      } else if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
        diffObject[key] = obj1[key];
      }
    }

    for (let key in obj2) {
      if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
        diffObject[key] = obj2[key];
      }
    }

    return diffObject;
  }

  const handleUpdate = async (e) => {
    let penny_drop_data;
    if (dealerDetails?.bank_details_changed) {
      try {
        handleLoading(true, "Processing Penny Drop");
        let { data } = await anchorPennyDrop({
          account_number: dealerDetails?.account_number,
          ifsc_code: dealerDetails?.ifsc_code,
        });

        if (data?.status) {
          toast.success("Penny Drop successfull");
          penny_drop_data = {
            verified: data?.data?.verified || data?.data?.bankTxnStatus,
            verified_at: data?.data?.verified ? "Digio" : "Karza",
            beneficiary_name_with_bank:
              data?.data?.beneficiary_name_with_bank || data?.data?.accountName,
          };
        }
      } catch (error) {
        toast.error(
          error?.response?.data?.message || error?.message || "Something wrong"
        );
        setPennyDropLogs(error?.response?.data?.pennyDropLogs);
        handleLoading(false);
        return;
      }
      handleLoading(false);
    }
    try {
      setLoading(true);
      let payload = {};

      payload = formatAnchorPayloadUpdate(
        compareObjects(dealerDetails, initialState),
        updateDocs,
        dealerDetails?.bank_id,
        penny_drop_data,
        dealerDetails
      );

      // console.log(payload);
      let { data } = await updateAnchorV2({
        anchor_id: dealerDetails?.anchor_id,
        document_included_g: true,
        ...payload,
      });
      await handleShopImagesUpload();
      
      toast.success("Updated Successful");
      await getAnchorDetails(anchorId, setApiLoading, setInitialState);
      setIsDisabled((prev) => !prev);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Something wrong"
      );
    }
    setLoading(false);
  };

  async function fetchHoldBackApplicants(anchor_id) {
    try {
      setHoldBackApplicantsLoading(true);
      setHoldBackApplicantsPopupOpen(true);
      const { data } = await getHoldBackApplicants(anchor_id, false);
      console.log(data);
      setHoldBackApplicantsData(data?.applications);
      setHoldBackApplicantsLoading(false);
    } catch (err) {
      toast?.error("Failed to fetch hold back applicants!!!");
      setHoldBackApplicantsLoading(false);
      setHoldBackApplicantsPopupOpen(false);
    }
  }

  function closeHoldBackApplicantsPopup() {
    setHoldBackApplicantsPopupOpen(false);
  }

  async function handleDelete() {
    try {
      setLoading(true);
      const { data } = await deleteAnchor(anchorId);
      if (data?.status) {
        toast(data?.message || "deleted anchor");
        navigate(`/partners`);
      }
    } catch (error) {
      toast?.error(
        error?.response?.data?.message || "failed to delete anchor!!!"
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (anchorId) {
      getAnchorDetails(anchorId, setApiLoading, setInitialState);
    }
  }, [anchorId]);

  // useEffect(() => {
  //   let temp = dealerDetails;
  //   console.log(compareObjects({ ...temp }, initialState));
  //   setIsUpdated(
  //     Object.keys(compareObjects({ ...temp }, initialState)).length > 0
  //   );
  // }, [dealerDetails]);

  useEffect(() => {
    setToBeCollected(
      dealerDetails?.hold_back_nbfc_limits?.reduce(
        (acc, ele) =>
          acc + ((ele?.utilized || 0) - (ele?.collected_amount || 0)),
        0
      )
    );
  }, [dealerDetails]);

  useEffect(() => {
    if (state?.stage) {
      setActiveStep(state?.stage - 1);
    }
    if (state?.refreshPage) {
      getAnchorDetails(anchorId, setApiLoading, setInitialState);
    }
    return () => {
      setDealerDetails({
        anchor_id: "",
        oems: [{}],
      });
    };
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="update-anchor-wrapper">
          <div className="update-anchor-sidebar">
            <div
              style={{
                cursor: "pointer",
                marginBottom: 30,
              }}
              onClick={() => {
                if (state?.path) {
                  navigate(state?.path);
                } else {
                  navigate("/partners");
                }
              }}
            >
              <ArrowBackIcon /> Go back Home
            </div>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step}>
                  <StepLabel>{step}</StepLabel>
                  <StepContent>
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                        {index !== steps.length - 1 && (
                          <Button
                            variant="outlined"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1, borderRadius: 30 }}
                          >
                            Next
                          </Button>
                        )}
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {!isDisabled && (
              <Button
                className="mb-5 anchor-update-btn"
                fullWidth
                variant="contained"
                onClick={handleUpdate}
                disabled={(() => {
                  if (isLoading) return true;
                  if ([null, undefined, ""].includes(dealerDetails?.name))
                    return true;
                  if (
                    [null, undefined, ""].includes(
                      dealerDetails?.dealership_name
                    )
                  )
                    return true;
                  if (typeof dealerDetails?.type !== "string") return true;
                  if (
                    typeof dealerDetails?.oems?.[
                      dealerDetails?.oems?.length - 1
                    ]?.oem_id !== "string"
                  )
                    return true;
                  if([undefined,null].includes(dealerDetails?.business_executive?.email))
                    return true;
                })()}
              >
                {isLoading ? "Loading" : "Update"}
              </Button>
            )}
          </div>

          <div className="section">
            {!apiLoading && (
              <div className="steps-holder mb-5">
                <div className="">
                  {
                    <Grid
                      container
                      spacing={2}
                      className="pb-3"
                      style={{
                        width: "100%",
                        backgroundColor: "#fff",
                        zIndex: 5,
                        position: "sticky",
                        top: -25,
                      }}
                    >
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h5
                            style={{
                              color: "#2C71C2",
                            }}
                          >
                            {steps[activeStep]}
                          </h5>
                          <div className="d-flex flex-row align-items-center">
                            <Button
                              className="zoom-animation"
                              variant="outlined"
                              sx={{
                                fontSize: 10,
                                textTransform: "capitalize",
                                my: 2,
                                mx: 1,
                                cursor: "default",
                              }}
                              size="small"
                              color="secondary"
                            >
                              To Be Collected{" "}
                              {toBeCollected ? f(toBeCollected, "cur") : 0}
                            </Button>
                            <div>
                              {dealerDetails?.commercials_history?.length >
                                0 && (
                                <>
                                  <Button
                                    style={{
                                      textTransform: "capitalize",
                                      margin: "10px 10px",
                                    }}
                                    onClick={() => {
                                      const currentCommercials =
                                        dealerDetails?.oems?.map((obj) => ({
                                          oem_id: obj?.oem_id,
                                          oem_name: obj?.oem_name,
                                          old_commercials: {
                                            interest_rate: obj?.interest_rate,
                                            processing_fees: obj?.processing_fees,
                                            processing_fees_percent: obj?.processing_fees_percent,
                                            subvention_amount: obj?.subvention_amount,
                                            subvention_percent: obj?.subvention_perc,
                                            gps_charges: obj?.gps_charges,
                                            total_hold_back_amount: dealerDetails?.hold_back_type == "DEALER"? 
                                            dealerDetails?.anchor_hold_back_total_amount : obj?.hold_back_total_amount,
                                            hold_back_per_file: dealerDetails?.hold_back_type == "DEALER"? 
                                            dealerDetails?.anchor_hold_back_per_file_amount : obj?.hold_back_per_file_amount,
                                          },
                                        }));
                                      const oldCommercials = [...dealerDetails?.commercials_history]?.reverse() || [];
                                      setCommercialsHistoryCurrentData(currentCommercials);
                                      setCommercialsHistoryData(oldCommercials);
                                      setCommercialsHistoryPopUpOpen(true);
                                    }}
                                  >
                                    <HistoryIcon
                                      style={{
                                        fontSize: 20,
                                        marginRight: 5,
                                      }}
                                    />{" "}
                                    Commercials History
                                  </Button>
                                  <CommercialsHistoryPopUp
                                    isOpen={commercialsHistoryPopUpOpen}
                                    onClose={closeCommercialsHistoryPopUp}
                                    data={commercialsHistoryData}
                                    currData={commercialsHistoryCurrentData}
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              {" "}
                              <Button
                                style={{
                                  textTransform: "capitalize",
                                  margin: "10px 10px",
                                }}
                                onClick={() => {
                                  fetchHoldBackApplicants(
                                    dealerDetails?.anchor_id
                                  );
                                }}
                              >
                                <PeopleAltIcon
                                  style={{
                                    fontSize: 20,
                                    marginRight: 5,
                                  }}
                                />{" "}
                                HB Applicants
                              </Button>
                            </div>
                            <HoldBackApplicantsPopup
                              isOpen={HoldBackApplicantsPopupOpen}
                              onClose={closeHoldBackApplicantsPopup}
                              isLoading={HoldBackApplicantsLoading}
                              data={HoldBackApplicantsData}
                            />
                            <div>
                              <Button
                                style={{
                                  textTransform: "capitalize",
                                  margin: "10px 10px",
                                }}
                                onClick={() => {
                                  let arr = getDocArr(activeStep);
                                  if (arr.length === 0) {
                                    toast.error("no documents exist !!!");
                                    return;
                                  }
                                  setTotalPages(arr?.length || 0);
                                  downloadAll(
                                    arr,
                                    getDocName(),
                                    {
                                      user,
                                      setUser,
                                    },
                                    setDownloadProgress,
                                    setIsDownloadingAll
                                  );
                                }}
                                disabled={
                                  !isDisabled ||
                                  Number(user?.role) === 2
                                }
                              >
                                <FileDownloadIcon
                                  style={{
                                    fontSize: 20,
                                    marginRight: 5,
                                  }}
                                />{" "}
                                Download All
                              </Button>
                            </div>
                            <Button
                              style={{
                                textTransform: "capitalize",
                                margin: "10px 10px",
                              }}
                              onClick={() => {
                                if (!isDisabled) {
                                  getAnchorDetails(
                                    anchorId,
                                    setApiLoading,
                                    setInitialState
                                  );
                                }
                                setIsDisabled((prev) => !prev);
                              }}
                              disabled={Number(user?.role) !== 1}
                              color={isDisabled ? "primary" : "error"}
                            >
                              {isDisabled ? (
                                <EditSharpIcon
                                  style={{
                                    fontSize: 18,
                                    marginRight: 5,
                                  }}
                                />
                              ) : (
                                <CloseSharpIcon
                                  style={{
                                    fontSize: 18,
                                    marginRight: 5,
                                  }}
                                />
                              )}{" "}
                              Edit Dealer
                            </Button>
                            <div>
                              <Button
                                style={{
                                  textTransform: "capitalize",
                                  margin: "10px 10px",
                                }}
                                onClick={() => {
                                  setConfDltPopup(true);
                                }}
                                color={"error"}
                                disabled={
                                  !isDisabled || Number(user?.role) !== 1
                                }
                              >
                                <DeleteIcon
                                  style={{
                                    fontSize: 18,
                                    marginRight: 5,
                                  }}
                                />
                                Delete Dealer
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  }
                  {getStepContent(
                    activeStep,
                    setActiveStep,
                    handleLoading,
                    isDisabled,
                    { pennyDropLogs }
                  )}
                </div>
                <div style={{ marginBottom: 50 }}>&nbsp;</div>
              </div>
            )}
          </div>
        </div>
      </ThemeProvider>

      {(overLoading?.open || apiLoading) && (
        <LoadingOver text={overLoading?.text} />
      )}

      {isDownloadingAll && (
        <LoadingOver
          text={`Downloading ${downloadProgress + 1}/${totalPages}`}
        />
      )}
      <DeletePopup
        confDltPopup={confDltPopup}
        setConfDltPopup={setConfDltPopup}
        handleDeleteDoc={handleDelete}
        text={"are you sure , you want to delete this Anchor ?"}
      />
    </>
  );
}

export default UpdateAnchor;
