import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  Slide,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useApplication from "../hooks/useApplication";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import dayjs from "dayjs";
import LoadingOver from "./Loading";
import { toast } from "react-toastify";
import { addNachPresent } from "../api";
import { f } from "../utility/Formater";
import CustomTableV2 from "./CustomTable/CustomTableV2";
import { GlobalContext } from "../Context/GlobalContext";
import { nachPresentColors, NachPresentFailure } from "../Pages/Tables/ViewCollection/ViewCollectionsV2";
import moment from "moment";
import MouseOverPopover from "./Popover";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initNachData = {
  settlement_dateMS: null,
  amount: 0,
};

const singleNachSuccessBanks=[
  "Utkarsh",
  "Ujjivan",
  "Kotak",
  "IDFC",
  "Indian",
  "Bank of India"
]

function AddNachPresent({ open, handleClose, tableData=[] }) {
  const { updateDetails, getApplicationDetails } = useApplication();
  const { collectionData = {} } = updateDetails;
  const { next_schedule } = collectionData;
  const [nachData, setNachData] = useState(initNachData);
  const [loading, setLoading] = useState(null);
  const [cnfmSbmt, setCnfmSbmt] = useState(false);
  const [currMonthNachPresents, setCurrMonthNachPresents] = useState([]);

  const { first_collection_date, last_collection_date } =
    updateDetails?.digio_nach_data?.latest?.mandate_details || {};

  function handleChange(key, value) {
    setNachData((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  async function handleSubmit() {
    try {
      setLoading("adding new nach present...");
      setCnfmSbmt(false);
      if (!updateDetails?.digio_nach_data?.latest?.umrn) {
        toast.error("umrn not found");
        return;
      }
      if (dayjs(nachData?.settlement_dateMS).isBefore(dayjs())) {
        toast.error(`Please select the future date.`);
        return;
      }
      if(dayjs(nachData?.settlement_dateMS).isBefore(dayjs(first_collection_date))){
        toast.error(`Please select a date that comes after the first collection date.`);
        return;
      }
      let payload = {
        application_id: updateDetails?.application_id,
        settlement_date: dayjs(nachData?.settlement_dateMS).format(
          "YYYY-MM-DD"
        ),
        amount: +nachData.amount,
        umrn: updateDetails?.digio_nach_data?.latest?.umrn,
        nbfc_id: updateDetails?.nbfc_id,
      };
      const { data } = await addNachPresent(payload);
      toast(data?.message);
      await getApplicationDetails(updateDetails?.application_id);
      handleClose();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(null);
    }
  }

  function handleNachSubmit(){
    let settlementYear = dayjs(nachData?.settlement_dateMS).year();
    let settlementMonth = dayjs(nachData?.settlement_dateMS).month();
    let currMonthNach = tableData.filter((nachData)=>{
      if(nachData?.nach_txn_details?.scheduled_settlement_date){
        let prevNachStlmntYear = dayjs(nachData?.nach_txn_details?.scheduled_settlement_date).year();
        let prevNachStlmntMonth = dayjs(nachData?.nach_txn_details?.scheduled_settlement_date).month();
        return prevNachStlmntYear === settlementYear && prevNachStlmntMonth === settlementMonth;
      };
      return false
    });
    if(currMonthNach?.length){
      setCurrMonthNachPresents(currMonthNach);
    }else setCnfmSbmt(true);
  }

  useEffect(() => {
    if (open) {
      setNachData({
        amount: next_schedule?.nextInstallmentAmount,
        settlement_dateMS: null,
      });
    } else {
      setNachData({ ...initNachData });
    }
  }, [open]);

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Nach Presentation - {updateDetails?.customer_name}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <div className="d-flex flex-row align-items-center w-100 justify-content-between mb-3">
                <span className="d-flex flex-column justify-content-start align-items-start">
                  <span style={{ fontSize: "10px" }}>First Collection Date</span>
                  <span className="fs-6">
                    {dayjs(first_collection_date, {
                      format: "DD-MM-YYYY HH:mm:ss",
                    }).format("DD-MM-YYYY")}
                  </span>
                </span>
                <span>
                  <EastOutlinedIcon />
                </span>
                <span className="d-flex flex-column justify-content-start align-items-start">
                  <span style={{ fontSize: "10px" }}>Last Collection Date</span>
                  <span className="fs-6">
                    {last_collection_date
                      ? dayjs(last_collection_date, {
                          format: "DD-MM-YYYY HH:mm:ss",
                        }).format("DD-MM-YYYY")
                      : f(last_collection_date)}
                  </span>
                </span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl>
                <FormLabel>
                  Amount<span className="text-danger">&nbsp;*</span>
                </FormLabel>
                <TextField
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={nachData?.amount}
                  onChange={(event) => {
                    const sanitizedValue = event.target.value.replace(
                      /[^0-9.]/g,
                      ""
                    );
                    handleChange("amount", sanitizedValue);
                  }}
                  InputProps={{
                    pattern: "[0-9]*",
                    endAdornment: (
                      <InputAdornment position="end">
                        <CurrencyRupeeIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={10} mt={2}>
              <FormControl>
                <FormLabel>
                  Settlement Date<span className="text-danger">&nbsp;*</span>
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box>
                    <DemoItem>
                      <DatePicker
                        format="DD/MM/YYYY"
                        disablePast
                        minDate={dayjs(first_collection_date,{format:"YYYY-MM-DD HH:mm:ss"})}
                        value={nachData?.settlement_dateMS}
                        inputVariant="outlined"
                        onChange={(date) => {
                          handleChange("settlement_dateMS", date);
                        }}
                        animateYearScrolling
                      />
                    </DemoItem>
                  </Box>
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={handleNachSubmit}
            disabled={!nachData?.amount || !nachData?.settlement_dateMS}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog 
        open={cnfmSbmt} 
        text={"Are you sure you want to present nach for this customer ?"}
        handleClose={()=>{
          setCnfmSbmt(false);
        }}
        handleSubmit={handleSubmit}
      />
      <ShowPrevNachPresents
        open={currMonthNachPresents?.length}
        nachData={currMonthNachPresents}
        handleClose={()=>{
          setCurrMonthNachPresents([]);
        }}
        handleSubmit={()=>{
          setCurrMonthNachPresents([]);
          setCnfmSbmt(true);
        }}
      />
      {Boolean(loading) && <LoadingOver text={loading} />}
    </>
  );
};


const ConfirmDialog = ({ open, text , handleClose , handleSubmit }) => {
  return (
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {text}
        </DialogTitle>
        <DialogActions>
          <Button
            variant="text"
            color="inherit"
            onClick={handleClose}
          >
            Go back
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
          >
            {"Present "}
            <ArrowForwardIosIcon fontSize="small" />
          </Button>
        </DialogActions>
      </Dialog>
  )
}
const ShowPrevNachPresents = ({ open, nachData=[] , handleClose , handleSubmit }) => {
  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails] = updateDetailState;

  const successNachArr = nachData?.filter(item=>["success","scheduled"].includes(item?.nach_txn_details?.state));
  const customerMandateBankName = updateDetails?.digio_nach_data?.latest?.mandate_details?.destination_bank_name;
  const isSingleNachSuccessBank = singleNachSuccessBanks.some(bank => {
    if(customerMandateBankName==="STATE BANK OF INDIA") return false;//"state bank of india" is also considered in "bank of india" regex, handling seperately
    const regex = new RegExp(bank, 'i'); 
    return regex.test(customerMandateBankName);
  });

  let columns = [
    {
      label:<span className="text-nowrap" >Schedule Id</span>,
      render: (index) => {
        return f(nachData?.[index]?.id);
      },
    },
    {
      label: <span className="text-nowrap" >Amount</span>,
      render: (index) => {
        return f(nachData?.[index]?.amount);
      },
    },
    {
      label: <span className="text-nowrap" >Presentation Date</span>,
      render: (index) => {
        const date = nachData?.[index]?.created_at
        return date ? moment(date,"YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") :f(null);
      },
      width: 150,
    },
    {
      label: <span className="text-nowrap" >Settlement Date</span>,
      render: (index) => {
        const date = nachData?.[index]?.nach_txn_details?.scheduled_settlement_date
        return date ? moment(date,"YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") :f(null);
      },
      width: 150,
    },
    {
      label: "State",
      render: (index) => {
        let state = nachData?.[index]?.nach_txn_details?.state;
        return (
          <span
            style={{ color: nachPresentColors?.[state],textTransform:"capitalize" }}
            className="d-flex flex-row align-items-center"
          >
            {f(state)}
            {Boolean(nachData?.[index]?.nach_txn_details?.details?.failure_reason) && <MouseOverPopover className="ms-1" renderUI={<NachPresentFailure noAmount={true} nachFailureDetails={nachData?.[index]?.nach_txn_details?.details} />} head={<InfoOutlinedIcon fontSize='small' sx={{fontSize:16}} />} />}
          </span>
        );
      },
    },
    {
      label: <span className="text-nowrap" >Presented By</span>,
      render: (index) => {
        let presented_by = nachData?.[index]?.presented_by;
        const replacedEmail = presented_by?.replace(/\./g, " ");
        const sanitizedEmail = replacedEmail?.replace(/@.*$/, "");
        return (
          <span
            className="d-flex flex-row align-items-center text-capitalize text-nowrap"
          >
            {f(sanitizedEmail)}
          </span>
        );
      },
    },
  ]

  return (
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <span className="text-danger">{"Nach is already presented for this month!"}</span>
        </DialogTitle>
        <DialogContent>
          <CustomTableV2
            columns={columns}
            tableData={nachData}
            maxHeight={"50vh"}
            loadingRows={1}
            serialNo={true}
          />
          {(successNachArr?.length && isSingleNachSuccessBank) &&<span style={{fontSize:12}}>
            The customer's bank, {customerMandateBankName}, permits only one successful NACH transaction per month.
            </span>}
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="inherit"
            onClick={handleClose}
          >
            Go back
          </Button>
          {!(successNachArr?.length && isSingleNachSuccessBank) && <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
          >
            {"Continue"}
            <ArrowForwardIosIcon fontSize="small" />
          </Button>}
        </DialogActions>
      </Dialog>
  )
}

export default AddNachPresent;
