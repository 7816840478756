import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Grid } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ graphData }) => {
  const data = {
    labels: graphData?.labels || [],
    datasets: [
      {
        label: "Vehicles",
        data: graphData?.values?.vehicle || [],
        fill: false,
        borderColor: "#962DFF",
        tension: 0.1,
      },
      {
        label: "Avg Distance",
        data: graphData?.values?.distance || [],
        fill: false,
        borderColor: "#FCB5C3",
        tension: 0.1,
      },
    ],
  };

  const calculateMinMax = (datasets) => {
    let maxY = -Infinity;
    datasets.forEach((dataset) => {
      dataset.data.forEach((value) => {
        maxY = Math.max(maxY, value);
      });
    });
    return { minY: 0, maxY };
  };

  const { minY, maxY } = calculateMinMax(data.datasets);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        position: "left",
        min: minY,
        max: maxY + 10,
        title: {
          display: true,
          text: "Distance (KM)",
          font: {
            size: 14,
          },
        },
      },
      y1: {
        beginAtZero: true,
        position: "right",
        min: minY,
        max: maxY + 10,
        grid: {
          display: false,
        },
      },
      x: {
        title: {
          display: true,
          text: "Date", 
          font: {
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="gps-daily-trend-card">
            <div className="header-data mb-2">
              <div>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "20px",
                    marginBottom: 5,
                    color: "#9291A5",
                  }}
                >
                  MMI GPS Daily Trend
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#9291A5",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      color: "black",
                      fontWeight: 500,
                    }}
                  >
                    {graphData?.totals?.vehicle}
                  </span>{" "}
                  Vehicles Travelled{" "}
                  <span
                    style={{
                      color: "black",
                      fontWeight: 500,
                    }}
                  >
                    {graphData?.totals?.distance}
                  </span>{" "}
                  km Total Till now
                </p>
              </div>
              <div>
                <div
                  className="d-flex"
                  style={{
                    alignItems: "center",
                    gap: "10px",
                    color: "#615E83",
                  }}
                >
                  <div
                    className="round-span"
                    style={{
                      background: "#962DFF",
                    }}
                  >
                    &nbsp;
                  </div>{" "}
                  <div>Vehicles</div>
                </div>
                <div
                  className="d-flex"
                  style={{
                    alignItems: "center",
                    gap: "10px",
                    color: "#615E83",
                  }}
                >
                  <div
                    className="round-span"
                    style={{
                      background: "#FCB5C3",
                    }}
                  >
                    &nbsp;
                  </div>{" "}
                  <div>Avg Distance</div>
                </div>
              </div>
            </div>
            <Line data={data} options={options} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LineChart;
