import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, CircularProgress, Grid, Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import CustomTableV2 from "../../../Components/CustomTable/CustomTableV2";
import InstallationGraph from "../../../Components/gps/InstallationGraph";
import InstallationPie from "../../../Components/gps/InstallationPie";
import {
  getGPSInstallationTableData,
  getGpsInstallationDisbursal,
  getInstallationPie,
} from "../../../api";

const filterValues = {
  weekly: "Weekly Trend",
  monthly: "MOM Installation",
  last_seven_days: "Last 7 days Trend",
};
const Installation = ({
  pieState,
  tableState,
  disbursalTableState,
  filterState,
}) => {
  const [loadingPie, setLoadingPie] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingTable2, setLoadingTable2] = useState(false);
  const [selectedFilterIns, setSelectedFilterIns] = filterState;
  const [InstTableData, setInstTableData] = tableState;
  const [disbursalTableData, setDisbursalTableData] = disbursalTableState;
  const [pieData, setPieData] = pieState;
  const [anchorEl, setAnchorEl] = useState(null);
  const openGraphMenu = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (val) => {
    setSelectedFilterIns(val);
    handleLoadTableData(val);
    setAnchorEl(null);
  };

  const handleLoadPieData = async () => {
    try {
      setLoadingPie(true);
      const { data } = await getInstallationPie();
      setPieData({
        loaded: true,
        data: {
          monthly: {
            mufin:
              (data?.data?.monthly || []).find((item) => item._id === "mufin")
                ?.count || 0,
            mmi:
              (data?.data?.monthly || []).find((item) => item._id === "mmi")
                ?.count || 0,
          },
          daily: {
            mufin:
              (data?.data?.daily || []).find((item) => item._id === "mufin")
                ?.count || 0,
            mmi:
              (data?.data?.daily || []).find((item) => item._id === "mmi")
                ?.count || 0,
          },
        },
      });
    } catch (error) {}
    setLoadingPie(false);
  };

  const handleLoadTableData = async (filter) => {
    try {
      setLoadingTable(true);
      const { data } = await getGPSInstallationTableData(`?type=${filter}`);
      const tableData = Object.keys(data?.groupedData || {})?.map((key) => {
        const row = data?.groupedData?.[key] || {};
        return {
          timeline: key,
          count: row?.count,
          embifi: row?.embifi,
          mufin: row?.mufin,
        };
      });
      setInstTableData({
        loaded: true,
        data: {
          tableData,
          graphData: {
            labels: Object.keys(data?.groupedData || {}).reverse(),
            values: Object.values(data?.groupedData || {})
              .map((weekData) => weekData.count)
              .reverse(),
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
    setLoadingTable(false);
  };

  const handleLoadDisTableData = async (filter) => {
    try {
      setLoadingTable2(true);
      const { data } = await getGpsInstallationDisbursal();
      const tableData = data?.data || [];
      const totals = tableData?.pop()
      console.log(totals);
      setDisbursalTableData({
        loaded: true,
        data: tableData,
        totals: {
          label:"TOTAL",
          ...totals
        }
      });
    } catch (error) {
      console.log(error);
    }
    setLoadingTable2(false);
  };

  let installationTableColumns = [
    {
      label: "Timeline",
      render: (index) => {
        return InstTableData?.data?.tableData?.[index]?.timeline;
      },
      width: 150,
    },
    {
      label: "Installed",
      render: (index) => {
        return InstTableData?.data?.tableData?.[index]?.count;
      },
      width: 150,
    },
    {
      label: "Embifi",
      render: (index) => {
        return InstTableData?.data?.tableData?.[index]?.embifi;
      },
      width: 150,
    },
    {
      label: "Mufin",
      render: (index) => {
        return InstTableData?.data?.tableData?.[index]?.mufin;
      },
      width: 150,
    },
  ];

  let disbursalsTableColumns = [
    {
      label: "Disbursed Month",
      render: (index) => {
        return disbursalTableData?.data?.[index]?.monthYear;
      },
      width: 150,
    },
    {
      label: "Disbursed",
      render: (index) => {
        return disbursalTableData?.data?.[index]?.disbursed;
      },
      width: 150,
    },
    {
      label: "Installed",
      render: (index) => {
        return disbursalTableData?.data?.[index]?.installed;
      },
      width: 150,
    },
    {
      label: "Install %",
      render: (index) => {
        return disbursalTableData?.data?.[index]?.install_perc;
      },
      width: 150,
    },
    {
      label: "Embifi",
      render: (index) => {
        return disbursalTableData?.data?.[index]?.embifi;
      },
      width: 150,
    },
    {
      label: "Mufin",
      render: (index) => {
        return disbursalTableData?.data?.[index]?.mufin;
      },
      width: 150,
    },
    {
      label: "Not Installed",
      render: (index) => {
        return disbursalTableData?.data?.[index]?.not_installed;
      },
      width: 150,
    },
  ];

  useEffect(() => {
    if (!pieData?.loaded) {
      handleLoadPieData();
    }
    if (!InstTableData?.loaded) {
      handleLoadTableData(selectedFilterIns);
    }
    if (!disbursalTableData?.loaded) {
      handleLoadDisTableData(selectedFilterIns);
    }
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {loadingPie ? (
            <LoaderDiv />
          ) : (
            <InstallationPie
              mufin={pieData?.data?.monthly?.mufin}
              mmi={pieData?.data?.monthly?.mmi}
              title={"MTD"}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {loadingPie ? (
            <LoaderDiv />
          ) : (
            <InstallationPie
              mufin={pieData?.data?.daily?.mufin}
              mmi={pieData?.data?.daily?.mmi}
              title={"Today"}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={12}>
          <div>
            <Button
              id="basic-button"
              variant="contained"
              disableElevation
              aria-controls={openGraphMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openGraphMenu ? "true" : undefined}
              onClick={handleClickMenu}
              style={{ background: "#6E36FF" }}
            >
              {filterValues?.[selectedFilterIns]} <ArrowDropDownIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openGraphMenu}
              onClose={() => {
                setAnchorEl(null);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {Object.keys(filterValues)?.map((filter) => (
                <MenuItem onClick={() => handleCloseMenu(filter)}>
                  {filterValues?.[filter]}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Grid>
        <Grid item xs={6}>
          {loadingTable ? (
            <LoaderDiv />
          ) : (
            // <GPSTable
            //   tableData={InstTableData?.data?.tableData || {}}
            //   title={filterValues?.[selectedFilterIns]}
            // />

            <CustomTableV2
              tableData={InstTableData?.data?.tableData || []}
              columns={installationTableColumns}
              tableHeight={"100%"}
              limit={0}
              page={1}
              isLoading={loadingTable}
              maxHeight={440}
              borderBottom
              headerComp={<div>{filterValues?.[selectedFilterIns]}</div>}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {loadingTable ? (
            <LoaderDiv />
          ) : (
            <InstallationGraph
              graphData={
                InstTableData?.data?.graphData || { labels: [], values: [] }
              }
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {loadingTable ? (
            <LoaderDiv />
          ) : (
            <CustomTableV2
              tableData={disbursalTableData?.data || []}
              columns={disbursalsTableColumns}
              tableHeight={"100%"}
              limit={0}
              page={1}
              isLoading={loadingTable2}
              maxHeight={440}
              borderBottom
              totalRow={disbursalTableData?.totals}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const LoaderDiv = () => {
  return (
    <div className="gps-loader-div">
      <div>
        <CircularProgress
          size={100}
          color="inherit"
          sx={{
            color: "#cccccc",
          }}
        />
      </div>
    </div>
  );
};
export default Installation;
