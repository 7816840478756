import { Skeleton } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import { statgeMap } from "../../../utility/Stages";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Fab from "@mui/material/Fab";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import noDataIco from "../../../assets/nodata.gif";
import { base64ToPdf, f, fCollectionStatus } from "../../../utility/Formater";
import {
  downloadTxnReciept,
  editTransaction,
  fetchTransactionHistory,
} from "../../../api";
import { toast } from "react-toastify";
import UpdateCollection from "./UpdateCollection";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LoadingOver from "../../../Components/Loading";

const TransationHistory = ({
  applicationId,
  transactionData,
  setTransactionData,
  customerName,
}) => {
  const scrollRef = useRef(null);
  const [scroll, setScroll] = useState("Bottom");
  const [isLoader, setLoader] = useState(false);
  const [overLoading, setOverLoading] = useState(false);

  const [collectionUpdate, setCollectionUpdate] = useState({
    open: false,
    is_colletion: false,
    payment_amount: "",
    transaction_date: moment().format("DD/MM/YYYY"),
    payment_mode: "Cash",
  });

  const handleScroll = (event) => {
    if (
      event.currentTarget.scrollTop >
      event.currentTarget.offsetHeight - event.currentTarget.offsetHeight / 2
    ) {
      setScroll("Top");
    }

    if (event.currentTarget.scrollTop < event.currentTarget.scrollHeight / 2) {
      setScroll("Bottom");
    }
  };

  const getTransactionHistory = async () => {
    try {
      setLoader(true);
      let { data } = await fetchTransactionHistory(applicationId);
      setTransactionData(data?.transactions);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setLoader(false);
  };

  useEffect(() => {
    getTransactionHistory();
  }, [applicationId]);

  const handleUpdateCollection = async (data, type) => {
    try {
      let payload = {
        scheduleId: collectionUpdate?.scheduleId,
        transactionId: collectionUpdate?.transactionId,
        action: type,
        data,
      };

      const { data: response } = await editTransaction(payload, applicationId);
      if (response?.status) {
        toast.success("Transaction updated successfully");
        getTransactionHistory();
      }
      setCollectionUpdate({
        open: false,
        is_colletion: false,
        payment_amount: "",
        transaction_date: moment().format("DD/MM/YYYY"),
        payment_mode: "Cash",
      });
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  const handleDownload = async (id, fileName) => {
    try {
      setOverLoading(true);
      let { data } = await downloadTxnReciept({
        payment_details_id: id,
      });

      await base64ToPdf(data?.base64, fileName);
      // console.log(data);
    } catch (error) {}
    setOverLoading(false);
  };

  return (
    <>
      <div>
        <div
          className="loan-table-container"
          ref={scrollRef}
          onScroll={handleScroll}
        >
          {!isLoader && transactionData?.length === 0 ? (
            <>
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "60vh" }}
              >
                <div>
                  <img
                    src={noDataIco}
                    style={{ width: "300px", opacity: 0.3 }}
                  />
                  <p
                    className="w-100 text-center mb-1"
                    style={{ fontSize: 25, fontWeight: 500 }}
                  >
                    No Transaction Data
                  </p>
                  {/* {!isDisbursed && (
                    <p className="w-100 text-center" style={{ color: "gray" }}>
                      Note : The application is not yet disbursed
                    </p>
                  )} */}
                </div>
              </div>
            </>
          ) : (
            <>
              <table className="loan-table-simple">
                <thead>
                  <tr className="loan-tr-simple">
                    <th>SL No.</th>
                    <th>Transaction Amount</th>
                    <th>Transaction Date</th>
                    <th>Payment Mode</th>
                    <th>Transaction UTR</th>
                    <th>Transaction number</th>
                    <th></th>
                  </tr>
                </thead>
                {!isLoader ? (
                  <tbody>
                    {transactionData?.map((val, index) => {
                      return (
                        <>
                          <tr
                            className="loan-tr-simple loan-body-tr-simple pointer"
                            key={val?._id}
                          >
                            <td data-label="Installment No">{index + 1}</td>
                            <td data-label="Installment Amount">
                              {f(val?.txn_amount, "cur")}
                            </td>

                            <td data-label="Settlement Date">
                              {moment(new Date(val?.trxn_dateMS)).format(
                                "DD/MM/YYYY"
                              )}
                            </td>

                            <td data-label="pay-mode">{val?.payment_mode}</td>
                            <td data-label="tnx-id">{val?.txn_utr}</td>
                            <td data-label="tnx-id">{val?.txn_number}</td>
                            <td data-label="tnx-id">
                              <div className="d-flex">
                                <div
                                  onClick={() => {
                                    handleDownload(
                                      val?._id,
                                      `${customerName}-${moment(
                                        new Date(val?.trxn_dateMS)
                                      ).format("DD/MM/YYYY")}-${val?.txn_amount}.pdf`
                                    );
                                  }}
                                >
                                  <FileDownloadIcon sx={{ fontSize: 20 }} />
                                </div>
                                {val?.editAllowed && (
                                  <div
                                    className="ms-3"
                                    onClick={() => {
                                      setCollectionUpdate({
                                        open: true,
                                        is_colletion: false,
                                        payment_amount: val?.txn_amount,
                                        transaction_id: val?.txn_number,
                                        transaction_utr: val?.txn_utr,
                                        transaction_date: moment(
                                          new Date(
                                            Number(val?.trxn_dateMS)
                                          ).toString()
                                        ).format("DD/MM/YYYY"),
                                        payment_mode: val?.payment_mode,
                                        isTransaction: true,
                                        defaultDate: moment(
                                          new Date(
                                            Number(val?.trxn_dateMS)
                                          ).toString()
                                        ).format("DD/MM/YYYY"),
                                        scheduleId: val?.schedule_id,
                                        transactionId: val?._id,
                                        isEditabled: val?.editAllowed,
                                      });
                                    }}
                                  >
                                    <EditIcon sx={{ fontSize: 12 }} />
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    {[...Array(10)].map((_) => {
                      return (
                        <tr className="loan-tr loan-body-tr">
                          <td data-label="Settlement Date">
                            <Skeleton />
                          </td>

                          <td data-label="Outstanding / Advance">
                            <Skeleton />
                          </td>
                          <td data-label="Day-Status">
                            <Skeleton />
                          </td>
                          <td data-label="Current-Status">
                            <Skeleton />
                          </td>
                          <td data-label="pay-mode">
                            <Skeleton />
                          </td>
                          <td data-label="tnx-id">
                            <Skeleton />
                          </td>
                          <td data-label="actions"></td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </>
          )}
        </div>
      </div>
      <Fab
        variant="extended"
        className="scroll-top-btn2"
        onClick={() => {
          if (scroll === "Top") {
            scrollRef.current.scrollTop = 0;
          } else if (scroll === "Bottom") {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
          }
        }}
      >
        {scroll === "Top" && <ArrowDropUpIcon />}
        {scroll === "Bottom" && <ArrowDropDownIcon />}
        {/* {scroll} */}
      </Fab>

      <UpdateCollection
        applicationId={applicationId}
        open={collectionUpdate?.open}
        initalState={collectionUpdate}
        isTransaction={true}
        handleClose={(reload) => {
          setCollectionUpdate({
            open: false,
            is_colletion: false,
            payment_amount: "",
            transaction_date: moment().format("DD/MM/YYYY"),
            payment_mode: "Cash",
          });
          if (reload) {
            getTransactionHistory();
          }
        }}
        onSubmit={async (data) => {
          await handleUpdateCollection(data, "UPDATE");
          // console.log(data);
        }}
      />

      {overLoading && <LoadingOver text={"Generating Reciept"} />}
    </>
  );
};

export default TransationHistory;
