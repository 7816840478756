import React, { useState, useRef, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import upload_icon from "../../assets/upload_icon.svg";
import { getBase64 } from "../../utility/convertFileBase";
import { dataURItoBlob, handleDownload } from "../../utility/download";
import { Button, CircularProgress } from "@mui/material";
import DownloadingSharpIcon from "@mui/icons-material/DownloadingSharp";
import FileDownloadSharpIcon from "@mui/icons-material/FileDownloadSharp";
import CloseIcon from "@mui/icons-material/Close";
import DocView from "../Anchor/DocView";
import { useParams } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";

function DragDropDoc({
  isDownloadAble = true,
  isCrossAble = true,
  viewPopup = true,
  onChange,
  handleDelete,
  handleReupload,
  noc,
  name,
  disabled,
}) {
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(false);
  const [base64, setBase64] = useState("");
  const [isDownloading, setIsDownloading] = useState(null);
  const fileInput = useRef(null);

  const { user, setUser } = useContext(UserContext);
  const { app_id: application_id } = useParams();

  function handleDragOver(e) {
    e.preventDefault();
  }

  async function handleDrop(e) {
    e.preventDefault();
    if(disabled){
      return;
    }
    const { files } = e.dataTransfer;
    if (files.length > 0) {
      if (files?.length > 1) {
        toast.error("please upload single document");
        return;
      }
      // setLoading(true);
      //   const reader = new FileReader();
      //   reader.readAsDataURL(files[0]);
      let base = await getBase64(files[0]);
      setBase64(base);
      setDocument({
        open: false,
        fileLink: dataURItoBlob(base),
        dataType: files[0].type.includes("image") ? "image" : "pdf",
        name: name ? name : "document",
      });
      // setLoading(false);
    }
  }

  function downloadDoc(currDoc, setLoading) {
    handleDownload(
      currDoc?.fileLink,
      `${application_id}-${currDoc?.name}`,
      currDoc,
      setLoading,
      {
        user,
        setUser,
      }
    );
  }

  function handleOpenPopup(e) {
    e.preventDefault();
    if (document?.key) {
      setDocument((prev) => ({ ...prev, open: true }));
    } else {
      setDocument((prev) => ({ ...prev, fileLink: base64, open: true }));
    }
  }

  // function handleReupload(base , dataType){
  //   setBase64(base);
  //   setDocument({
  //     open: false,
  //     fileLink: dataURItoBlob(base),
  //     dataType: dataType.includes("image") ? "image" : "pdf",
  //     name: name ? name : 'document',
  //   });
  // }

  useEffect(() => {
    if (base64 !== "" && onChange) {
      onChange(base64);
    }
  }, [base64]);

  useEffect(() => {
    if (noc?.fileLink) {
      setDocument({
        ...noc,
        open: false,
      });
    }
  }, [noc]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        {!document ? (
          <>
            {disabled && (
              <div
                style={{
                  borderRadius: "8px",
                  height: "150px",
                  width: "350px",
                  backgroundColor: "rgba(255, 255, 255, 0.4)",
                  position: "absolute",
                  zIndex: 1,
                }}
              ></div>
            )}
            <div
              className="file-drag-parent"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              style={{
                display: "grid",
                placeItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                fileInput?.current?.click();
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <img src={upload_icon} alt={"drag svg"} />
                <p>
                  Drag & drop files or{" "}
                  <span
                    style={{
                      color: "rgba(72, 62, 168, 1)",
                      borderBottom: "2px solid rgba(72, 62, 168, 1)",
                    }}
                  >
                    Browse
                  </span>
                </p>
              </div>
              <input
                ref={fileInput}
                type="file"
                class="custom-file-input"
                accept="application/pdf , image/png, image/jpg, image/jpeg"
                onChange={async (e) => {
                  let base = await getBase64(e.target.files[0]);
                  let { files } = e.target;
                  setBase64(base);
                  setDocument({
                    open: false,
                    fileLink: dataURItoBlob(base),
                    dataType: files?.[0]?.type?.includes("image")
                      ? "image"
                      : "pdf",
                    name: name ? name : "document",
                  });
                  e.target.value = "";
                }}
                onClick={(e) => {
                  e.target.value = "";
                }}
                hidden
              />
            </div>
          </>
        ) : (
          <div
            style={{
              borderRadius: "8px",
              height: "150px",
              width: "350px",
              backgroundColor: "rgba(35, 35, 35, 1)",
              position: "relative",
              cursor: "pointer",
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onClick={handleOpenPopup}
            ></div>
            <div style={{ width: "100%", height: "100%" }}>
              {loading ? (
                <div
                  style={{
                    borderRadius: "8px",
                    height: "150px",
                    width: "350px",
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <CircularProgress color="secondary" />
                </div>
              ) : document?.key ? (
                <embed
                  key={document?.key}
                  src={document?.fileLink}
                  width={"100%"}
                  height={"100%"}
                ></embed>
              ) : document?.dataType !== "pdf" ? (
                <img
                  src={document?.fileLink}
                  width={"100%"}
                  height={"100%"}
                  style={{
                    objectFit: "contain",
                  }}
                  alt="uploaded document"
                />
              ) : (
                <iframe
                  src={document?.fileLink}
                  width={"100%"}
                  height={"100%"}
                  style={{
                    objectFit: "contain",
                  }}
                  alt="uploaded document"
                ></iframe>
              )}
            </div>
            {!loading && !disabled && (
              <Button
                className="doc-remove-btn"
                onClick={() => {
                  if (onChange) onChange(null);
                  setDocument(null);
                  setBase64("");
                  onChange('delete');
                }}
              >
                <CloseIcon fontSize="small" />
              </Button>
            )}
            {isDownloadAble && !disabled && !loading && (
              <Button
                className="doc-download-btn"
                onClick={() => {
                  handleDownload(
                    document?.fileLink,
                    `${application_id}-${document?.name}`,
                    document,
                    setIsDownloading,
                    {
                      user,
                      setUser,
                    }
                  );
                }}
                disabled={isDownloading?.name === document?.name}
              >
                {isDownloading?.name === document?.name ? (
                  <DownloadingSharpIcon style={{ fontSize: 16 }} />
                ) : (
                  <FileDownloadSharpIcon style={{ fontSize: 16 }} />
                )}
              </Button>
            )}
          </div>
        )}
      </div>
      {document?.open && (
        <DocView
          docs={document}
          handleClose={() => {
            setDocument((prev) => ({
              ...prev,
              open: false,
              // fileLink: prev?.key ? prev?.fileLink : dataURItoBlob(base64),
            }));
          }}
          handleDownload={downloadDoc}
          swipeDocs={false}
          reuploadDoc={(base64, dataType) => {
            handleReupload(base64, dataType);
            setDocument((prev) => ({ ...prev, open: false }));
          }}
          deleteDocFun={(doc) => {
            handleDelete(setDocument);
            setDocument((prev) => ({ ...prev, open: false }));
          }}
        />
      )}
    </>
  );
}

export default DragDropDoc;
