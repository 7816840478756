import { forwardRef, useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { GlobalContext } from "../../Context/GlobalContext";
import runningGif from "../../assets/running.gif";
import { creditEngine } from "../../api";
import ReportIcon from "@mui/icons-material/Report";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreditEngineComp({ open, handleClose, type , handleReload}) {
  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const [status, setStatus] = useState("pending");
  const [creditResponse, setCreditResponse] = useState({});

  const handleRunEngine = async () => {
    setStatus("running");
    try {
      let payload = {
        applicant: type,
        application_id: updateDetails?.application_id,
      };
      let { data } = await creditEngine(payload);
      setCreditResponse(data?.data?.cre_response);
      setStatus("success");
      handleReload()
    } catch (error) {
      setStatus("failed");
    }
  };

  useEffect(() => {
    if (!open) {
      setStatus("pending");
      setCreditResponse({});
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
      >
        <DialogTitle>
          <p className="text-center mb-0">Credit Engine</p>
        </DialogTitle>
        {status === "pending" && (
          <>
            <DialogContent>
              Are you sure to Run credit engine for application id:{" "}
              {updateDetails?.application_id}?
              <br />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={() => {
                  handleRunEngine();
                }}
              >
                Run
              </Button>
            </DialogActions>
          </>
        )}
        {status === "running" && (
          <DialogContent>
            <div style={{ width: "300px", marginTop: 10 }}>
              <p className="text-center">
                <img src={runningGif} width="150px" alt="" />
              </p>
              <p className="text-center">Running Credit Engine</p>
            </div>
          </DialogContent>
        )}
        {status === "failed" && (
          <>
            <DialogContent>
              <div style={{ width: "300px", marginTop: 10 }}>
                <p className="text-center">
                  {/* <img src={runningGif} width="150px" alt="" /> */}
                  <ReportIcon style={{ fontSize: 100, color: "red" }} />
                </p>
                <p className="text-center">Credit Engine Failed</p>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleRunEngine}>Re-Run</Button>
            </DialogActions>
          </>
        )}
        {status === "success" && (
          <>
            <DialogContent>
              <div style={{ width: "300px", marginTop: 10 }}>
                <p className="text-center">
                  {creditResponse?.status === "ELIGIBLE" ? (
                    <CheckCircleIcon
                      style={{ fontSize: 100, color: "green" }}
                    />
                  ) : (
                    <CancelIcon style={{ fontSize: 100, color: "red" }} />
                  )}
                </p>
                <p className="text-center">{creditResponse?.message}</p>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
