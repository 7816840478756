import { useContext, useState } from "react";
import { toast } from "react-toastify";
import {
  anchorOCR,
  fetchAnchorDetails,
  updateAnchorV2,
  updateDealerShopImages
} from "../api";
import { GlobalContext } from "../Context/GlobalContext";

export default function useAnchor() {
  const { dealerDetailState, updateDocsState, dealerShopImageState } = useContext(GlobalContext);
  const [dealerDetails, setDealerDetails] = dealerDetailState;
  const [updateDocs, setUpdateDocs] = updateDocsState;
  const [dealerShopImages,setDealerShopImages] = dealerShopImageState;
  const [activeStep, setActiveStep] = useState(0);

  const getValue = (field, defaultValue) => {
    if (field) {
      return field;
    } else return defaultValue;
  };

  const isDoc = (doc) => {
    return Array.isArray(doc) ? doc : [];
  };

  const getAnchorDetails = async (anchor_id, setLoading, setInitialState) => {
    try {
      if (setLoading) {
        setLoading(true);
      }
      let { data } = await fetchAnchorDetails(anchor_id);
      const { data: anchorData } = data;

      const details = {
        anchor_id: anchor_id,
        update: true,
        name: anchorData?.name || "",
        type: anchorData?.firm_type || "",
        dealership_name: anchorData?.dealership_name || "",
        dob: anchorData?.dob || "",
        gstin: anchorData?.gst || "",
        gst_files: isDoc(anchorData?.documents?.anchor_gstin_pdf),
        dealer_images: isDoc(anchorData?.documents?.dealer_image),
        pan_images: isDoc(anchorData?.documents?.personal_pan),
        pan_details: isDoc(anchorData?.pan_details),
        company_pan_image: isDoc(anchorData?.documents?.company_pan_image),
        aadhaar_images: isDoc(anchorData?.documents?.aadhar_image),
        trade_license: isDoc(anchorData?.documents?.trade_license_pdf),
        shop_image: isDoc(anchorData?.documents?.shop_image),
        agreement: isDoc(anchorData?.documents?.anchor_agreement),
        other_image: isDoc(anchorData?.documents?.other_image),
        showroom_address_images:isDoc(anchorData?.documents?.showroom_address_docs),
        oems: anchorData?.oem_details?.map((oem) => {
          return {
            ...oem,
            sb_type: (() => {
              if (oem?.subvention?.amount) return "AMOUNT";
              else if (oem?.subvention?.percent) return "PERCENTAGE";
              else return "";
            })(),
            pf_type: (() => {
              if (oem?.processing_fees) return "AMOUNT";
              else if (oem?.processing_fees_percent) return "PERCENTAGE";
              else return "";
            })(),
            hold_back_per_file_amount: oem?.hold_back_amount?.per_file || "",
            hold_back_total_amount: oem?.hold_back_amount?.total || "",
            subvention_amount: oem?.subvention?.amount || "",
            subvention_perc: oem?.subvention?.percent || "",
            battery_type: (oem?.battery_info || [])?.reduce(
              (accumulator, item) => {
                accumulator[item.battery_type] = {
                  name: item.battery_type,
                  amount: item.max_loan_amount || "",
                  checked: true,
                };
                return accumulator;
              },
              {}
            ),
            gps_charges: oem?.gps_charges || "",
            gst_type: oem?.subvention?.gst_type || "",
            ...(() => {
              let obj = { df_exclusive_amount: "", df_gst_amount: "" };
              if (oem?.subvention?.amount) {
                if (oem?.subvention?.gst_type == "Inclusive") {
                  obj["df_exclusive_amount"] =
                    Number(oem?.subvention?.amount) -
                    Math.round((Number(oem?.subvention?.amount) * 18) / 100);
                  obj["df_gst_amount"] = Math.round(
                    (Number(oem?.subvention?.amount) * 18) / 100
                  );
                } else if (oem?.subvention?.gst_type == "Exclusive") {
                  obj["df_exclusive_amount"] = Number(oem?.subvention?.amount);
                  obj["df_gst_amount"] = Math.round(
                    (Number(oem?.subvention?.amount) * 18) / 100
                  );
                }
              } else if (oem?.subvention?.percent) {
                let sb_amount = Math.round((100000 * Number(oem?.subvention?.percent)) / 100);
                if (oem?.subvention?.gst_type == "Inclusive") {
                  obj["df_exclusive_amount"] =
                    Number(sb_amount) -
                    Math.round((Number(sb_amount) * 18) / 100);
                  obj["df_gst_amount"] = Math.round(
                    (Number(sb_amount) * 18) / 100
                  );
                } else if (oem?.subvention?.gst_type == "Exclusive") {
                  obj["df_exclusive_amount"] = Number(sb_amount);
                  obj["df_gst_amount"] = Math.round(
                    (Number(sb_amount) * 18) / 100
                  );
                }
              }
              return obj;
            })(), 
          };
        }),
        ...(anchorData?.bankData?.length > 0 ? anchorData?.bankData[0] : {}),
        security_cheques: isDoc(
          anchorData?.bankData?.[0]?.documents?.security_cheques
        ),
        bank_statements: isDoc(
          anchorData?.bankData?.[0]?.documents?.bank_statement
        ),
        outlet_details: isDoc(anchorData?.outlet_details),
        hold_back_type: anchorData?.hold_back_type || "N/A",
        ...(anchorData?.hold_back_type === "DEALER"
          ? {
              anchor_hold_back_per_file_amount:
                anchorData?.hold_back_amount?.per_file || null,
              anchor_hold_back_total_amount:
                anchorData?.hold_back_amount?.total || null,
              anchor_hold_back_utilized:
                anchorData?.hold_back_amount?.utilized
                  ?.toString()
                  ?.concat(" ₹") || null,
            }
          : {}),
        score_data: anchorData?.score_data || {},
        hold_back_nbfc_limits: anchorData?.hold_back_nbfc_limits || [],
        anchor_aggregate_percentage: anchorData?.anchor_aggregate_percentage,
        gst_details: anchorData?.AnchorSupportDocs?.gst_details || {},
        trade_license_details:
          anchorData?.AnchorSupportDocs?.trade_license_details || {},
        commercials_history: anchorData?.commercials_history || [],
        credit_pull: anchorData?.credit_pull || {},
        createdAt: new Date(anchorData?.createdAt),
        rto_name: anchorData?.rto_name,
        monthly_capacity: anchorData?.monthly_capacity,
        parent_anchor_id: anchorData?.parent_anchor_id || null,
        parent_anchor_name: anchorData?.parentAnchorData?.dealership_name || "",
        business_executive:anchorData?.business_executive?.[0] || null
      };
      if (setInitialState) {
        setInitialState(JSON.parse(JSON.stringify(details)));
      }
      setDealerDetails(details);
      setUpdateDocs([]);
      setDealerShopImages({documents:[],deleteDocs:[]});
    } catch (error) {
      toast?.error(error?.response?.data?.message);
    }
    if (setLoading) {
      setLoading(false);
    }
  };

  // async function handleReupload(payload){
  //   try{
  //     const { data } = updateAnchorData();
  //   }catch(error){

  //   }
  // }

  function getDocArr(step) {
    if (step === 0) {
      const {
        gst_files = [],
        dealer_images = [],
        pan_images = [],
        company_pan_image = [],
        aadhaar_images = [],
        trade_license = [],
        shop_image = [],
        agreement = [],
        other_image = [],
      } = dealerDetails;
      let aadhaarImg = aadhaar_images?.reduce((acc, itm) => {
        return [
          ...acc,
          { ...(itm?.aadhaar_front || {}), aadharData: itm },
          { ...(itm?.aadhaar_back || {}), aadharData: itm },
        ];
      }, []);
      const arr = [
        ...gst_files,
        ...dealer_images,
        ...pan_images,
        ...company_pan_image,
        ...aadhaarImg,
        ...trade_license,
        ...shop_image,
        ...agreement,
        ...other_image,
      ];
      return arr;
    } else if (step === 1) {
      const { security_cheques = [], bank_statements = [] } = dealerDetails;
      const arr = [...security_cheques, ...bank_statements];
      return arr;
    }else if(step === 2){
      const { showroom_address_images } = dealerDetails;

      return showroom_address_images?.reduce((acc,curr)=>{
        return [...acc,...(curr||[])];
      },[]) || [];

    }
  }
  async function handleSingleDocReupload(
    base64,
    dataType,
    currDoc,
    handleClose,
    setLoading
  ) {
    try {
      let anchorUpdateQuery = {};
      if (currDoc?.category === "personal_pan") {
        setLoading("Running OCR");
        let ocrPayload = {
          files: [base64],
        };
        let { data } = await anchorOCR(ocrPayload);
        if (data?.success?.length > 0) {  
          toast.success("OCR completed successfully");
          let panIndex = dealerDetails?.pan_images?.findIndex((item) => {
            return currDoc?.name === item?.name;
          });
          if (panIndex === -1) {
            toast?.error("pan not found");
            return;
          }
          dealerDetails.pan_images[panIndex] = {
            isUploaded: true,
            fileLink: base64,
          };

          let pan_details = [
            ...(dealerDetails?.pan_details || []),
            data?.success?.[0]?.response?.value,
          ];

          anchorUpdateQuery = {
            pan_details,
          };
        } else {
          toast.error("OCR Failed");
          return;
        }
      }
      setLoading("Reuploading document ...");
      let newFileName = currDoc?.name?.replace(/_\d+$/, "");
      let documents = [];
      if (currDoc?.category === "aadhar_image") {
        let name = currDoc?.name?.includes("aadhaar_front")
          ? "aadhaar_front"
          : "aadhaar_back";
        documents = [
          {
            new: false,
            category: currDoc?.category,
            reupload: true,
            [name]: currDoc,
          },
          {
            id: Number(new Date()),
            new: true,
            category: currDoc?.category,
            [name]: base64,
          },
        ];
      } else {
        documents = [
          {
            new: false,
            fileLink: currDoc?.fileLink,
            documentName: currDoc?.name,
            category: currDoc?.category,
            reupload: true,
          },
          {
            id: Number(new Date()),
            new: true,
            category: currDoc?.category,
            fileName: newFileName,
            documentFile: base64,
          },
        ];
      }
      let payload = {};
      if (
        [
          "security_cheques",
          "last_6_months_bank_statement",
          "bank_statement",
        ]?.includes(currDoc?.category)
      ) {
        payload = {
          anchor_id: dealerDetails?.anchor_id,
          document_included_g: true,
          anchorUpdateQuery: {},
          bankUpdateArray: [
            {
              bankDocuments: documents,
              bank_id: dealerDetails?.bank_id,
              new: false,
            },
          ],
        };
      } else {
        payload = {
          anchor_id: dealerDetails?.anchor_id,
          document_included_g: true,
          anchorUpdateQuery,
          anchorDocuments: documents,
        };
      }

      setLoading("Fetching Anchor Data ...");
      const { data } = await updateAnchorV2(payload);
      await getAnchorDetails(dealerDetails?.anchor_id);
      toast(data?.message || `document updated successfully`);
    } catch (error) {
      toast?.error(error?.response?.data?.message || "error reuploading file");
    } finally {
      setLoading(null);
      handleClose();
    }
  }

  async function handleSingleDocDelete(currDoc, handleClose, setLoading) {
    try {
      setLoading("deleting " + currDoc?.name);
      let documents = [];
      if (currDoc?.aadharData) {
        documents = [
          {
            new: false,
            category: currDoc?.category,
            ...(currDoc?.aadharData || []),
          },
        ];
      } else {
        documents = [
          {
            new: false,
            type: currDoc?.category,
            fileLink: currDoc?.fileLink,
            documentName: currDoc?.name,
            category: currDoc?.category,
          },
        ];
      }
      let payload = {};
      if (
        [
          "security_cheques",
          "last_6_months_bank_statement",
          "bank_statement",
        ]?.includes(currDoc?.category)
      ) {
        payload = {
          anchor_id: dealerDetails?.anchor_id,
          document_included_g: true,
          anchorUpdateQuery: {},
          bankUpdateArray: [
            {
              bankDocuments: documents,
              bank_id: dealerDetails?.bank_id,
              new: false,
            },
          ],
        };
      } else {
        payload = {
          anchor_id: dealerDetails?.anchor_id,
          document_included_g: true,
          anchorUpdateQuery: {},
          anchorDocuments: documents,
        };
      }
      const { data } = await updateAnchorV2(payload);
      await getAnchorDetails(dealerDetails?.anchor_id);
      toast(`document is deleted successfully`);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(null);
      handleClose();
    }
  }

  async function handleShopImagesUpload(setLoading,id){
    try{ 
      if(setLoading)
        setLoading("updating shop images");
      const formData = new FormData();

      if(!dealerShopImages?.deleteDocs?.length && !dealerShopImages?.documents?.length ){
        return;
      }

      if(dealerShopImages?.deleteDocs?.length)
        formData.append("delete_docs",JSON.stringify(dealerShopImages?.deleteDocs));

      for(let doc of (dealerShopImages?.documents||[])){
        formData.append(doc?.fieldname,doc);
      }

      const { data } = await updateDealerShopImages(id ?? dealerDetails?.anchor_id,formData);

      setDealerShopImages({documents:[],deleteDocs:[]});

      return data;
    }catch(error){
      toast.error(error?.response?.data?.message);
    } finally {
      if(setLoading)
        setLoading(null);
    }
  }

  async function handleSingleShopImage(document, delete_doc_name, setLoading, handleClose){
    try{ 
      if(setLoading)
        setLoading("Re-uploading document");

      const formData = new FormData();

      if(delete_doc_name?.length)
        formData.append("delete_docs",JSON.stringify([delete_doc_name]));

      if(document)
        formData.append(document?.fieldname,document);

      const { data } = await updateDealerShopImages(dealerDetails?.anchor_id,formData);
      await getAnchorDetails(dealerDetails?.anchor_id);
      setDealerShopImages({documents:[],deleteDocs:[]});

      return data;

    }catch(error){
      toast.error(error?.response?.data?.message);
    } finally {
      if(setLoading)
        setLoading(null);
      if(handleClose)
        handleClose();
    }
  }

  return {
    getAnchorDetails,
    activeStep,
    setActiveStep,
    getDocArr,
    handleSingleDocReupload,
    handleSingleDocDelete,
    handleShopImagesUpload,
    handleSingleShopImage
  };
}
