import { TextField } from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";

const DateRangePicker = ({
  onChange,
  startDate,
  endDate,
  maxDate,
  minDate,
}) => {
  const handleChange = (start, end) => {
    onChange(start, end);
  };

  return (
    <RangeDatePicker
      startDate={startDate}
      endDate={endDate}
      onChange={handleChange}
      minDate={minDate}
      maxDate={maxDate}
      dateFormat="DD/MM/YYYY"
      monthFormat="MMM YYYY"
      startDatePlaceholder="Start Date"
      endDatePlaceholder="End Date"
      disabled={false}
      className="custom-date-range-picker p-0"
      startWeekDay="monday"
      onClosed={() => {}}
    />
  );
};

export default DateRangePicker;
