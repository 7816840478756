

export const getAnchorCrifColor = (dealerCrifScore)=>{
    if(dealerCrifScore > 700){
        return {
            text:"green",
            background: "rgba(0, 255, 0, 0.3)"
        }
    }
    if(dealerCrifScore <=700 && dealerCrifScore >= 580){
        return {
            text:"orange",
            background: "rgba(255, 165, 0, 0.3)"
        }
    }
    if(dealerCrifScore < 580){
        return {
            text:"red",
            background: "rgba(255, 0, 0, 0.3)"
        }
    }
    return {
        text:"white",
        background: "#cfcccc"
    }
}