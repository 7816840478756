import React, { useEffect } from "react";
import GPSLineGraph from "../../../Components/gps/GPSLineGraph";
import { useState } from "react";
import { getGpsDailyTrend } from "../../../api";
import { toast } from "react-toastify";
import LoadingDiv from "../../../Components/gps/LoadingDiv";

const DailyTrend = ({ graphDataState }) => {
  const [dailyGraphData, setDailyGraphData] = graphDataState;
  const [loadingTrend, setLoadingTrend] = useState(false);

  const handleLoadGraphData = async (filter) => {
    try {
      setLoadingTrend(true);
      const { data } = await getGpsDailyTrend();
      const graphData = data?.data || [];
      const labels = [];
      const values = {
        vehicle: [],
        distance: [],
      };

      for (const item of graphData) {
        values?.vehicle?.push(item?.total_vehicles);
        values?.distance?.push(item?.average_kms);
        labels?.push(item?.record_date);
      }

      setDailyGraphData({
        loaded: true,
        data: {
          values,
          labels,
          totals: {
            vehicle: data?.total_vehicles || 0,
            distance: data?.total_kms || 0,
          },
        },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
    setLoadingTrend(false);
  };

  useEffect(() => {
    if (!dailyGraphData?.loaded) {
      handleLoadGraphData();
    }
  }, []);

  return (
    <div>
      {loadingTrend ? (
        <LoadingDiv />
      ) : dailyGraphData?.loaded ? (
        <GPSLineGraph graphData={dailyGraphData?.data} />
      ) : (
        <div>No Data</div>
      )}
    </div>
  );
};

export default DailyTrend;
