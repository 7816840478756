import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import DownloadingSharpIcon from "@mui/icons-material/DownloadingSharp";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Rotate90DegreesCwSharpIcon from "@mui/icons-material/Rotate90DegreesCwSharp";
import {
  AppBar,
  CircularProgress,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { toast } from "react-toastify";
import { UserContext } from "../../Context/UserContext";
import { getBase64 } from "../../utility/convertFileBase";
import { dataURItoBlob } from "../../utility/download";
import DeletePopup from "../DeletePopup";
import LoadingOver from "../Loading";
import { blobToBase64 } from "../../utility/Formater";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DocView({
  docs,
  handleClose,
  handleDownload,
  category,
  swipeDocs,
  getSwipeDocs,
  reuploadDoc = null,
  deleteDocFun = null,
}) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [allDocs, setAllDocs] = useState([]);
  const [currDoc, setCurrDoc] = useState(docs || {});
  const [index, setIndex] = useState(-1);
  const [rotate, setRotate] = useState(0);
  const [confDltPopup, setConfDltPopup] = useState(false);
  const [loading, setLoading] = useState(null);
  const [image, setImage] = useState("");
  const [documentLoading, setDocumentLoading] = useState(false);
  const [documentURL, setDocumentURL] = useState(null);
  const embedRef = useRef(null);
  const fileInput = useRef(null);
  let { open, fileLink, dataType, name } = currDoc;

  const { user } = useContext(UserContext);

  function capitalize(name) {
    return name
      ?.split("_")
      ?.map((itm) => itm?.[0]?.toUpperCase() + itm?.slice(1)?.toLowerCase())
      ?.join(" ");
  }

  const handleRotation = () => {
    let currentRotation = rotate;
    currentRotation += 90;
    if (currentRotation === 360) {
      currentRotation = 0;
    }
    setRotate(currentRotation);
  };

  function handleSwipe(direction, index) {
    if (direction === "prev" && index === -1) {
      index = allDocs.length - 1;
    } else if (direction === "next" && index === allDocs.length) {
      index = 0;
    }
    if (!allDocs?.[index]?.key) {
      setImage(dataURItoBlob(allDocs?.[index]?.fileLink));
    }
    setRotate(0);
    setCurrDoc({ ...(allDocs[index] || {}), open: true });
    setIndex(index);
  }

  const handleFetchDocument = async () => {
    try {
      setDocumentLoading(true);
      let blob = await handleDownload(currDoc, null,true);
      const base64 = await blobToBase64(blob);
      setDocumentURL(base64);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "failed to load document"
      );
    } finally {
      setDocumentLoading(false);
    }
  };

  function closePopUps(){
    setCurrDoc(prev=>({
      ...prev,
      open:false
    }))
    setLoading(null);
    setConfDltPopup(false);
    handleClose();
  }

  useEffect(() => {
    if (embedRef?.current) {
      embedRef.current.style.transform = "rotate(" + rotate + "deg)";

      if (currDoc?.key && (rotate === 90 || rotate === 270)) {
        embedRef.current.style.width = "50%";
      } else {
        embedRef.current.style.width = "100%";
      }
    }
  }, [rotate]);

  useEffect(() => {
    if (docs && !docs?.key) {
      setImage(dataURItoBlob(docs?.fileLink));
      setCurrDoc(docs);
    }
  }, [docs]);

  useEffect(() => {
    if (swipeDocs) {
      setAllDocs(swipeDocs);
    }else if(getSwipeDocs){
      setAllDocs(getSwipeDocs());
    }
  }, [swipeDocs]);

  useEffect(() => {
    if (allDocs) {
      let idx = allDocs?.findIndex((item) => item?.name === name);
      setIndex(idx);
    }
  }, [allDocs]);

  useEffect(()=>{
    if(currDoc?.key){
      handleFetchDocument();
    }
  },[currDoc])


  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={() => {
          setCurrDoc((prev) => ({ ...prev, open: false }));
          handleClose();
        }}
        TransitionComponent={Transition}
        onKeyDown={(e) => {
          if (e.key === "ArrowLeft") {
            handleSwipe("prev", index - 1);
          }
          if (e.key === "ArrowRight") {
            handleSwipe("next", index + 1);
          }
        }}
      >
        <AppBar
          sx={{ position: "relative" }}
          style={{ backgroundColor: "white", color: "black" }}
        >
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {capitalize(name)}
            </Typography>
            {currDoc?.key && (
              <div className="dropdown">
                <IconButton
                  className="btn btn-sm dropdown-toggle doc-status-dropdown"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                  sx={{
                    marginRight: 2,
                    fontSize: 16,
                    color: "#1976D2  !important",
                    borderRadius: 3,
                    "&:hover": {
                      color: "#1976D2 !important",
                    },
                    "&:active": {
                      color: "#1976D2 !important",
                    },
                  }}
                >
                  <EditIcon
                    sx={{
                      paddingRight: 1,
                    }}
                  />{" "}
                  Edit document
                </IconButton>
                <ul
                  className="dropdown-menu font-rocknroll"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li
                    className="dropdown-item user-select-none"
                    onClick={() => {
                      fileInput.current.click();
                    }}
                  >
                    {"Re-upload"}
                  </li>
                  <li
                    className="dropdown-item user-select-none"
                    onClick={() => {
                      setConfDltPopup(true);
                    }}
                  >
                    {"Delete"}
                  </li>
                </ul>
              </div>
            )}

            {dataType !== "pdf" && (
              <IconButton
                edge="start"
                color="primary"
                onClick={handleRotation}
                aria-label="close"
                title="rotate"
                // className="me-2"
              >
                <Rotate90DegreesCwSharpIcon />
              </IconButton>
            )}
            <IconButton
              color="primary"
              className="ms-2"
              onClick={() => {
                handleDownload(currDoc, setIsDownloading);
              }}
              disabled={isDownloading}
              title="download"
            >
              {isDownloading ? <DownloadingSharpIcon /> : <FileDownloadIcon />}
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                let newWindow = window.open(fileLink, `_blank`);
                if (!currDoc?.key) {
                  newWindow.document.write(
                    '<html><body><embed width="100%" height="100%" src="' +
                      fileLink +
                      '"></embed></body></html>'
                  );
                }
                // let panPayload = {
                //   pan_updated: true,
                //   pan_images: [
                //     ...(dealerDetails?.pan_images || []),
                //   ],
                // }
              }}
              title="full screen"
            >
              <FullscreenIcon />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => {
                setCurrDoc((prev) => ({ ...prev, open: false }));
                handleClose();
              }}
              title="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div
          className="w-100 bg-dark"
          style={{ height: "60vh", overflowY: "scroll" }}
          // ref={divRef}
        >
          <div
            style={{
              position: "relative",
              display: "grid",
              width: "100%",
              height: "100%",
              gridTemplateColumns: "10% 80% 10%",
              placeItems: "center",
            }}
          >
            {!allDocs?.length === 0 ? (
              <div />
            ) : (
              <IconButton
                onClick={() => {
                  handleSwipe("prev", index - 1);
                }}
                title="previous"
              >
                <ArrowBackIosIcon sx={{ color: "#fff", fontSize: "40px" }} />
              </IconButton>
            )}
            <div className="d-flex flex-row align-items-center justify-content-center">
              {currDoc?.key ? (
                documentLoading ? (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 6000,
                    }}
                  >
                    <div className="content-center">
                      <CircularProgress />
                    </div>
                    <p className="text-center mb-0 mt-2 text-white">
                      Loading Document...
                    </p>
                  </div>):
                currDoc?.dataType?.includes("video") ?
                <video
                  ref={embedRef}
                  src={documentURL}
                  width="100%"
                  height="100%"
                  controls
                />
                :<embed
                  ref={embedRef}
                  width="100%"
                  height={"100%"}
                  src={documentURL}
                ></embed>
              ) : currDoc?.dataType?.includes("image") ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    // overflowY: "scroll",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <img
                    ref={embedRef}
                    src={image}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    loading="lazy"
                  />
                </div>
              ) : currDoc?.dataType?.includes("video") ? (
                <video 
                  ref={embedRef} 
                  src={fileLink}
                  width="100%"
                  height="100%"
                  controls
                />
              ) :(
                <iframe
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  src={fileLink}
                  loading="lazy"
                ></iframe>
              )}
            </div>
            {!allDocs?.length === 0 ? (
              <div />
            ) : (
              <IconButton
                onClick={() => {
                  handleSwipe("next", index + 1);
                }}
                style={{ zIndex: 2 }}
                title="next"
              >
                <ArrowForwardIosIcon sx={{ color: "#fff", fontSize: "40px" }} />
              </IconButton>
            )}
          </div>
        </div>

        <input
          ref={fileInput}
          type="file"
          class="custom-file-input"
          accept="application/pdf , image/png, image/jpg, image/jpeg"
          onChange={async (e) => {
            let base64 = await getBase64(e.target.files[0]);
            reuploadDoc(base64, e.target.files[0].type,currDoc,closePopUps,setLoading,e.target.files);
            e.target.value = "";
          }}
          onClick={(e) => {
            e.target.value = "";
          }}
          hidden
        />
      </Dialog>
      <DeletePopup
        confDltPopup={confDltPopup}
        setConfDltPopup={setConfDltPopup}
        handleDeleteDoc={() => {
          deleteDocFun(currDoc,closePopUps,setLoading);
        }}
        text={
          currDoc?.aadharData
            ? "Are you sure you want to delete this aadhar document(front/back) ?"
            : false
        }
      />
      {loading !== null && <LoadingOver text={loading} />}
    </div>
  );
}

export default DocView;
