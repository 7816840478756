import React, { useState, useEffect } from "react";
import AudioPlayer from "react-audio-player";
import CircularProgress from "@mui/material/CircularProgress";

const TwilioAudioPlayer = ({ recordingUrl, accountSid, authToken }) => {
  const [loading, setLoading] = useState(false);
  const [playableUrl, setPlayableUrl] = useState(null);

  useEffect(() => {
    const credentials = btoa(`${accountSid}:${authToken}`); // Encode credentials
    setLoading(true);
    fetch(recordingUrl, {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setPlayableUrl(url);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching recording:", error);
        setLoading(false);
      });

    return () => URL.revokeObjectURL(playableUrl);
  }, [recordingUrl, accountSid, authToken]);

  return (
    <div>
      {!loading && playableUrl && (
        <AudioPlayer
          src={playableUrl}
          controls
          style={{ width: "100%" }}
          onWaiting={() => console.log("Loading audio...")}
        />
      )}
      {loading && <CircularProgress size={20}/>}
      {!loading && !playableUrl && <div>Failed  to load Audio</div>}
    </div>
  );
};

export default TwilioAudioPlayer;
