import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
} from "@mui/material";
import { useContext } from "react";
import { GlobalContext } from "../../Context/GlobalContext";
import { forwardRef } from "react";
import { DatePicker } from "@material-ui/pickers";
import { formatDate } from "../../utility/Formater";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { UserContext } from "../../Context/UserContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateDisbursalData = ({
  open,
  handleClose,
  onSubmit,
  isDisbursal,
  isLoading,
}) => {
  const { updateDetailState, clearUpdateState } = useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;

  const [state, setState] = useState({ stateSet: false });
  const [isDisabled, setDisabled] = useState(false);
  const [isAgent, setIsAgent] = useState(true);
  const [fiDateState, setFiDate] = useState("");
  const { user } = useContext(UserContext);

  function handleCalc(prop) {
    setState((prev) => {
      let calc = {};
      // console.log("hold back amount --> ",prev?.hold_back_amount);
      if (!isNaN(Number(prev?.[`loan_amount`]))) {
        calc = {
          ...calc,
          principal_amount: prev?.[`loan_amount`],
          loan_amount: prev?.[`loan_amount`],
          disbursal_amount: prev?.[`loan_amount`],
        };
        if (!isNaN(Number(prev?.[`interest_rate`]))) {
          calc = {
            ...calc,
            repayment_amount:
              Number(prev?.loan_amount) +
              +Number(
                prev?.loan_amount *
                  (prev?.interest_rate / 100) *
                  (prev?.tenure_value / 12)
              )?.toFixed(2),
            interest: (
              Number(prev?.loan_amount) *
              (Number(prev?.interest_rate) / 100) *
              (prev?.tenure_value / 12)
            )?.toFixed(2),
          };
          if (prev?.interest_collected === "UPFRONT_DEDUCTED") {
            calc = {
              ...calc,
              disbursal_amount: calc?.disbursal_amount - prev?.interest,
            };
          }
        }
        if (
          !isNaN(prev?.[`processing_fee`]) ||
          !isNaN(prev?.[`processing_rate`])
        ) {
          if (prop === "processing_rate") {
            calc = {
              ...calc,
              processing_fee: Number(
                (prev?.[`processing_rate`] * prev?.loan_amount) / 100
              )?.toFixed(2),
            };
          } else {
            calc = {
              ...calc,
              processing_rate: Number(
                (prev?.[`processing_fee`] / prev?.loan_amount) * 100
              )?.toFixed(2),
              processing_fee: prev?.processing_fee,
            };
          }
          if (prev?.pf_collected === "UPFRONT_DEDUCTED") {
            calc = {
              ...calc,
              disbursal_amount: calc?.disbursal_amount - calc?.processing_fee,
            };
          }
        }
        if (
          !isNaN(prev?.[`subvention_amount`]) ||
          !isNaN(prev?.[`subvention_rate`])
        ) {
          if (prop === "subvention_rate") {
            calc = {
              ...calc,
              subvention_amount: Number(
                (prev?.[`subvention_rate`] * prev?.loan_amount) / 100
              )?.toFixed(2),
            };
          } else {
            calc = {
              ...calc,
              subvention_rate: Number(
                (prev?.[`subvention_amount`] / prev?.loan_amount) * 100
              )?.toFixed(2),
            };
          }
          if (prev?.subvention_mode === "UPFRONT_DEDUCTED") {
            calc = {
              ...calc,
              disbursal_amount:
                calc?.disbursal_amount - prev?.[`subvention_amount`],
            };
          }
        }
        if (
          !isNaN(prev?.[`hold_back_amount`]) &&
          prev?.hold_back_mode === "UPFRONT_DEDUCTED"
        ) {
          calc = {
            ...calc,
            disbursal_amount:
              calc?.disbursal_amount - prev?.[`hold_back_amount`],
          };
        }
        if (!isNaN(prev?.[`gps_charges`])) {
          calc = {
            ...calc,
            disbursal_amount: calc?.disbursal_amount - prev?.[`gps_charges`],
          };
        }
        if (!isNaN(prev?.[`other_charges`])) {
          calc = {
            ...calc,
            disbursal_amount: calc?.disbursal_amount - prev?.[`other_charges`],
          };
        }
      }
      return {
        ...prev,
        ...calc,
      };
    });
  }

  const handleChange = (prop) => (event) => {
    setState({
      ...state,
      [prop]: event.target.value,
    });
    handleCalc(prop);
  };

  const checkAgentUploaded = (fiDoc) => {
    let status = true;
    fiDoc?.forEach((val) => {
      console.log(val);
      if (val?.uploadedFrom !== "LOAN_JOURNEY") {
        status = false;
      }
    });
    setIsAgent(status);
    setFiDate(fiDoc?.[0]?.uploadedDateMS);
  };

  function getMode(nbfc_id) {
    if (!nbfc_id?.length) return undefined;
    if (nbfc_id === "MF00006") {
      return {
        subvention_mode: "UPFRONT_COLLECTED_BY_EMBIFI",
        hold_back_mode: "UPFRONT_COLLECTED_BY_EMBIFI",
      };
    }
    return {
      subvention_mode: "UPFRONT_DEDUCTED",
      hold_back_mode: "UPFRONT_DEDUCTED",
    };
  }

  function changeMode(nbfcId, value) {
    return value
      ? value
      : ["FI00005", "MF00006"].includes(nbfcId)
      ? "UPFRONT_COLLECTED_BY_EMBIFI"
      : "UPFRONT_DEDUCTED";
  }

  useEffect(() => {
    if (open) {
      checkAgentUploaded(updateDetails?.loanDocs?.customer || []);
      setState({
        stateSet: true,
        ...updateDetails,
        pf_collected: updateDetails?.pf_collected || "UPFRONT_DEDUCTED",
        interest_collected: updateDetails?.interest_collected || "PAY_LATER",
        subvention_mode: changeMode(
          updateDetails?.nbfcId,
          updateDetails?.subvention_mode
        ),
        hold_back_mode: changeMode(
          updateDetails?.nbfcId,
          updateDetails?.hold_back_mode
        ),
      });
      if (isDisbursal) {
        setDisabled(
          !(
            emptyCheck(updateDetails?.loan_amount) ||
            emptyCheck(updateDetails?.disbursal_amount)
          )
        );
      }
      // console.log(
      //   updateDetails?.subvention_mode,
      //   updateDetails?.hold_back_mode
      // );
      // setState((prev) => ({ ...prev }));
      handleCalc();
    }
  }, [open]);

  const emptyCheck = (val) => {
    return [0, "0", null, undefined].includes(val);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ fontWeight: 600, marginBottom: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <h5 className="fw-bold">EDIT APPLICATION</h5>
            </Grid>
          </Grid>
        </DialogTitle>
        {state?.stateSet && (
          <DialogContent>
            <Grid container spacing={3}>
              <>
                {/* <Grid item xs={12} sm={12}>
                <p style={{ color: "gray" }}>Disbursal Details</p>
              </Grid> */}
                <Grid item xs={12} md={12}>
                  <h5 style={{ fontSize: "18px", fontWeight: 600 }}>
                    Loan Details
                  </h5>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="loanAmount"
                    required
                    name="loanAmount"
                    label="Loan Amount"
                    fullWidth
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    variant="standard"
                    value={state?.loan_amount || ""}
                    disabled={Number(user?.role) !== 1 && isDisabled}
                    onChange={handleChange("loan_amount")}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="principalAmount"
                    name="principalAmount"
                    label="Principal Amount"
                    fullWidth
                    variant="standard"
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    value={state?.principal_amount || ""}
                    onChange={handleChange("principal_amount")}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="repayAmount"
                    name="repayAmount"
                    label="Repayment Amount"
                    fullWidth
                    variant="standard"
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    value={state?.repayment_amount || ""}
                    onChange={handleChange("repayment_amount")}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="interest_rate"
                    name="interest_rate"
                    label="Interest Rate (P.A)"
                    type={"number"}
                    required
                    onWheel={(e) => e.target.blur()}
                    fullWidth
                    variant="standard"
                    value={state?.interest_rate || ""}
                    disabled={Number(user?.role) !== 1 && isDisabled}
                    onChange={handleChange("interest_rate")}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="interest"
                    name="interest"
                    label="Interest Amount"
                    fullWidth
                    variant="standard"
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    value={state?.interest || ""}
                    onChange={handleChange("interest")}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl variant="standard" sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Interest Collected
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={state?.interest_collected}
                      onChange={handleChange("interest_collected")}
                      //   label="Age"
                      defaultValue={"PAY_LATER"}
                      disabled={Number(user?.role) !== 1 && isDisabled}
                    >
                      <MenuItem value={"UPFRONT_DEDUCTED"}>
                        Upfront Deducted
                      </MenuItem>
                      <MenuItem value={"PAY_LATER"}>End</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  {/* <FormControl variant="standard" sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Payment Basic *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={state?.payment_basis}
                      defaultValue={"monthly"}
                      onChange={handleChange("payment_basis")}
                      disabled={isDisabled}
                    >
                      <MenuItem value={"monthly"}>Monthly</MenuItem>
                    </Select>
                  </FormControl> */}
                  <TextField
                    id="payment_basis"
                    name="payment_basis"
                    label="Payment Basis*"
                    fullWidth
                    variant="standard"
                    onWheel={(e) => e.target.blur()}
                    value={"monthly"}
                    onChange={handleChange("payment_basis")}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  {/* <FormControl variant="standard" sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Tenure Type *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={state?.tenure_type}
                      onChange={handleChange("tenure_type")}
                      disabled={isDisabled}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"months"}>months</MenuItem>
                      <MenuItem value={"weeks"}>weeks</MenuItem>
                      <MenuItem value={"days"}>days</MenuItem>
                      {/* <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl> */}
                  <TextField
                    id="payment_basis"
                    name="payment_basis"
                    label="Payment Basis*"
                    fullWidth
                    variant="standard"
                    onWheel={(e) => e.target.blur()}
                    value={"months"}
                    onChange={handleChange("tenure_type")}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="tenureValue"
                    name="tenureValue"
                    label="Tenure value *"
                    fullWidth
                    variant="standard"
                    value={state?.tenure_value || ""}
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    onChange={handleChange("tenure_value")}
                    disabled={Number(user?.role) !== 1 && isDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <h5 style={{ fontSize: "18px", fontWeight: 600 }}>
                    Commercials
                  </h5>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="processingFee"
                    name="processingFee"
                    label="PF Amount"
                    fullWidth
                    variant="standard"
                    type={"number"}
                    value={state?.processing_fee || ""}
                    onWheel={(e) => e.target.blur()}
                    onChange={handleChange("processing_fee")}
                    disabled={Number(user?.role) !== 1 && isDisabled}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="processingRate"
                    name="processingRate"
                    label="PF Rate"
                    fullWidth
                    variant="standard"
                    type={"number"}
                    // disabled={true}
                    onWheel={(e) => e.target.blur()}
                    value={state?.processing_rate || ""}
                    onChange={handleChange("processing_rate")}
                    disabled={Number(user?.role) !== 1 && isDisabled}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl variant="standard" sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      PF Collected
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={state?.pf_collected}
                      onChange={handleChange("pf_collected")}
                      //   label="Age"
                      defaultValue={"UPFRONT_DEDUCTED"}
                      // disabled={Number(user?.role)!==1 && isDisabled}
                      disabled
                    >
                      <MenuItem value={"UPFRONT_COLLECTED"}>
                        Upfront Collected
                      </MenuItem>
                      <MenuItem value={"UPFRONT_DEDUCTED"}>
                        Upfront Deducted
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="subAmount"
                    name="subAmount"
                    label="Dealer Fees Amount"
                    fullWidth
                    variant="standard"
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    value={state?.subvention_amount || ""}
                    onChange={handleChange("subvention_amount")}
                    disabled={Number(user?.role) !== 1 && isDisabled}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="subRate"
                    name="subRate"
                    label="Dealer Fees %"
                    fullWidth
                    variant="standard"
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    value={state?.subvention_rate || ""}
                    onChange={handleChange("subvention_rate")}
                    disabled={Number(user?.role) !== 1 && isDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  {!!state?.subvention_amount &&
                    state?.subvention_amount != 0 && (
                      <FormControl variant="standard" sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          Dealer Fees
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={state?.subvention_mode}
                          onChange={handleChange("subvention_mode")}
                          disabled={Number(user?.role) !== 1 && isDisabled}
                        >
                          <MenuItem value={"UPFRONT_COLLECTED_BY_EMBIFI"}>
                            Upfront Collected by Embifi
                          </MenuItem>
                          <MenuItem value={"UPFRONT_DEDUCTED"}>
                            Upfront Deducted
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="gpsCharges"
                    name="gpsCharges"
                    label="GPS Charges"
                    fullWidth
                    variant="standard"
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    value={state?.gps_charges || ""}
                    onChange={handleChange("gps_charges")}
                    disabled={Number(user?.role) !== 1 && isDisabled}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="holdBack"
                    name="holdBack"
                    label="Hold Back Amount"
                    fullWidth
                    variant="standard"
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    value={state?.hold_back_amount || ""}
                    onChange={handleChange("hold_back_amount")}
                    disabled={Number(user?.role) !== 1 && isDisabled}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  {!!state?.hold_back_amount &&
                    state?.hold_back_amount != 0 && (
                      <FormControl variant="standard" sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          Holdback
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={state?.hold_back_mode}
                          onChange={handleChange("hold_back_mode")}
                          disabled={Number(user?.role) !== 1 && isDisabled}
                        >
                          <MenuItem value={"UPFRONT_COLLECTED_BY_EMBIFI"}>
                            Upfront Collected by Embifi
                          </MenuItem>
                          <MenuItem value={"UPFRONT_DEDUCTED"}>
                            Upfront Deducted
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="otherCharges"
                    name="otherCharges"
                    label="Other Charges"
                    fullWidth
                    variant="standard"
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    value={state?.other_charges || ""}
                    onChange={handleChange("other_charges")}
                    disabled={Number(user?.role) !== 1 && isDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="advanceEmiAmount"
                    name="advanceEmiAmount"
                    label="Advance EMI Amount"
                    fullWidth
                    variant="standard"
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    value={state?.pre_deducted_emi_amount || ""}
                    onChange={handleChange("pre_deducted_emi_amount")}
                    disabled={Number(user?.role) !== 1 && isDisabled}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    id="installment"
                    name="installment"
                    label="Installment Amount"
                    fullWidth
                    variant="standard"
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    value={state?.installment}
                    onChange={handleChange("installment")}
                  />
                </Grid> */}
                {/* <h5>Disbursal Details</h5> */}
                <Grid item xs={12} sm={12}>
                  <h5 style={{ fontSize: "18px", fontWeight: 600 }}>
                    Disbursal Details
                  </h5>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="disbursalAmount"
                    name="disbursalAmount"
                    label="Disbursal Amount"
                    fullWidth
                    required
                    variant="standard"
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    value={state?.disbursal_amount || ""}
                    onChange={handleChange("disbursal_amount")}
                    disabled={Number(user?.role) !== 1 && isDisabled}
                  />
                </Grid>

                {isDisbursal && (
                  <Grid item xs={12} sm={4}>
                    {/* <DatePickerComponent
                    defaultValue={state?.disbursal_date}
                    text={"Disbursal Date"}
                    onChange={(date) => {
                      stateState({
                        ...state,
                        disbursal_date: moment(date).format("DD/MM/YYYY"),
                      });
                    }}
                  /> */}

                    <DatePicker
                      className="w-100 mt-2"
                      label="Disbursal date *"
                      format="dd/MM/yyyy"
                      // maxDate={new Date()}
                      // disableFuture
                      disabled={!isDisabled}
                      value={
                        state?.disbursal_date
                          ? formatDate(state?.disbursal_date)
                          : new Date()
                      }
                      onChange={(date) => {
                        setState({
                          ...state,
                          disbursal_date: moment(date).format("DD/MM/YYYY"),
                        });
                      }}
                      animateYearScrolling
                    />
                  </Grid>
                )}

                {isDisbursal && updateDetails?.nbfc_id === "NAM0007" && (
                  <Grid item xs={12} sm={4}>
                    <DatePicker
                      className="w-100 mt-2"
                      label="Embifi Disbursal date *"
                      format="dd/MM/yyyy"
                      // maxDate={new Date()}
                      // disableFuture
                      disabled={!isDisabled}
                      value={
                        state?.embifi_disbursed_date
                          ? formatDate(state?.embifi_disbursed_date)
                          : new Date()
                      }
                      onChange={(date) => {
                        setState({
                          ...state,
                          embifi_disbursed_date:
                            moment(date).format("DD/MM/YYYY"),
                        });
                      }}
                      animateYearScrolling
                    />
                  </Grid>
                )}

                {isDisbursal && (
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="utr"
                      required
                      name="utr"
                      label="Disbursal UTR"
                      fullWidth
                      variant="standard"
                      type={"text"}
                      value={state?.utr || ""}
                      onChange={handleChange("utr")}
                      disabled={!isDisabled}
                    />
                  </Grid>
                )}

                <>
                  {false && (
                    <Grid item xs={12} sm={12}>
                      <p className="mb-0">
                        <InfoIcon /> Any one of the following Nach ID is
                        mandatory
                      </p>
                    </Grid>
                  )}

                  {/* <Grid item xs={12} sm={4}>
                    <TextField
                      id="physicalNachId"
                      name="physicalNachId"
                      label="Physical Nach ID"
                      fullWidth
                      variant="standard"
                      value={state?.mandate_id}
                      type={"text"}
                      onWheel={(e) => e.target.blur()}
                      onChange={handleChange("mandate_id")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="enachId"
                      name="enachId"
                      label="Enach ID"
                      fullWidth
                      variant="standard"
                      value={state?.enach_mandate_id || ""}
                      type={"text"}
                      onWheel={(e) => e.target.blur()}
                      onChange={handleChange("enach_mandate_id")}
                    />
                  </Grid> */}
                  {!isAgent && (
                    <Grid item xs={12} sm={4}>
                      <DatePicker
                        className="w-100 mt-2"
                        required
                        label="FI Date"
                        format="dd/MM/yyyy"
                        // maxDate={new Date()}
                        // disableFuture
                        value={new Date(fiDateState)}
                        onChange={(date) => {
                          setFiDate(date);
                        }}
                        animateYearScrolling
                      />
                    </Grid>
                  )}
                </>
              </>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={isLoading}
            onClick={() => {
              onSubmit({ ...state, isAgent, fiDate: fiDateState });
            }}
          >
            {isLoading ? "Loading" : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpdateDisbursalData;
