import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import React,{ useState } from 'react'
import { toast } from 'react-toastify';
import { exportAgentFilesTrack } from '../../api';
import moment from 'moment';

const AgentTrackerExports = ({ open, handleClose }) => {

    const [filesTrackerLoading, setFilesTrackerLoading] = useState(false);

    async function handleFilesTrackerExport(){
        try{
            setFilesTrackerLoading(true);
            const { data } = await exportAgentFilesTrack();
            const blob = new Blob([data], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `agent_files_tracker_(${moment().format('DD-MM-YYYY hh:mm:ss A')}).csv`;
            a.click();
            window.URL.revokeObjectURL(url);
        }catch(error){
            toast.error(error?.response?.data?.message || error?.message || "Files Tracker Export Failed!");
        }finally{
            setFilesTrackerLoading(false);
        }
    }

  return (
    <Dialog maxWidth="lg" open={open} onClose={handleClose} >
        <DialogTitle>Agent Tracker Exports</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers >
            <div className='d-flex align-items-center flex-column' style={{width:400}}>
                <Button variant="outlined" className='w-100 py-2' disabled={filesTrackerLoading} onClick={()=>{handleFilesTrackerExport()}}>Agent Files Track Export</Button>
            </div>
        </DialogContent>
    </Dialog>
  )
}

export default AgentTrackerExports;