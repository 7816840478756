import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
  createFilterOptions,
} from "@mui/material";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { fetchFilterValue } from "../../api";
import DateRangePicker from "../DateRangePicker";
import AutocompleteSelect from "./AutoCompleteSelect";
import { LoadingSkeleton } from "./LoadingSkeleton";
import { UserContext } from "../../Context/UserContext";
import { sampleCollectionApplications } from "../../utility/sampleData";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AdvancedSort({
  open,
  handleClose,
  onApply,
  options,
  name,
  setOpenStatusOpts,
  setOpenNetPayableAmount,
  openStatusOpts,
  openNetPayableAmount,
  embifi_users,
  last_comments,
  tags,
}) {
  const columns = {
    field: "",
    values: [],
    options,
  };

  const initialState = [{ ...columns }];

  const [isMobile, setMobile] = useState(false);
  const [filterDataValue, setFilterDataValue] = useState([]);
  const [filterData, setFilterData] = useState(initialState);
  const [selectData, setSelectData] = useState([]);
  const [reduceData, setReduceData] = useState([]);
  const [collectionExecutive, setCollectionExecutive] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);

  const [overallStatusDays, setOverallStatusDays] = useState({
    from: 1,
    to: 1,
    type: "",
  });
  const [optionalIndexes, setOptionalIndexes] = useState({
    overall_status: -1,
    net_payable_amount: -1,
  });

  const isPrevCompleted = () => {
    let finalObj = filterData[filterData?.length - 1];
    for (let value of Object.values(finalObj)) {
      if (["", null, undefined]?.includes(value)) {
        return false;
      }
    }
    return true;
  };

  const addMore = () => {
    if (!isPrevCompleted()) {
      return;
    }
    setFilterData([
      ...filterData,
      {
        ...columns,
        options: options.filter((item) => {
          return !filterData.some((e) => e.field === item);
        }),
      },
    ]);
  };

  const removeItem = (i) => {
    if (filterData[i].field === "overall_status") {
      if (setOpenStatusOpts) setOpenStatusOpts(false);
      setFilterData([
        ...filterData.filter((item, index) => {
          if (index === i || item.field === "overall_status_days") return false;
          return true;
        }),
      ]);
    } else if (filterData[i].field === "net_payable_amount") {
      setOpenNetPayableAmount(false);
      setFilterData([
        ...filterData.filter((item, index) => {
          if (
            index === i ||
            item.field === "Greater than" ||
            item.field === "Less than" ||
            item.field === "Between"
          )
            return false;
          return true;
        }),
      ]);
    } else setFilterData([...filterData.filter((item, index) => index !== i)]);

    if (
      filterData[i].field === "Greater than" ||
      filterData[i].field === "Less than" ||
      filterData[i].field === "Between"
    ) {
      setOpenNetPayableAmount(false);
    }
  };

  const handleChange = (key, value, i) => {
    let obj = { ...filterData[i] };
    obj = {
      ...obj,
      [key]: value,
    };

    if (key === "field") {
      obj = {
        ...obj,
        values: (() => {
          if (
            [
              "transaction_date",
              "created_date",
              "due_date",
              "comments_date",
              "followup_date",
            ].includes(value)
          ) {
            return {
              from: Number(new Date()),
              to: Number(new Date()),
            };
          } else if (value === "old_dpd")
            return {
              date: new Date(
                moment().subtract(1, "days").format("YYYY-MM-DD")
              ).getTime(),
            };
          else {
            return [];
          }
        })(),
      };
    }

    let tmp_val = obj?.values;
    if (filterData?.[i]?.field === "last_comment") {
      tmp_val = tmp_val?.map((item) => (item === "No Comment" ? null : item));
    }
    obj = { ...obj, values: tmp_val };
    filterData[i] = obj;

    if (key === "field") {
      setFilterData([...filterData.splice(0, i + 1)]);
      return;
    }

    // if (key == 'is_approved') {
    //   setFilterData([...filterData.splice(0, i + 1)]);
    //   return;
    // }

    setFilterData([...filterData]);
  };

  const handleClear = () => {
    setFilterData([...initialState]);
  };

  useEffect(() => {
    getFilterValue();
    window.matchMedia("(min-width: 800px)").addEventListener("change", (e) => {
      setMobile(!e.matches);
    });

    // localStorage.getItem(`selectData${name}`) &&
    //   setSelectData(JSON.parse(localStorage.getItem(`selectData${name}`)));
    if (localStorage.getItem(`filterData${name}`)) {
      setFilterData(JSON.parse(localStorage.getItem(`filterData${name}`)));
    } else if (localStorage.getItem("overallStatusDays")) {
      setOverallStatusDays(true);
    } else {
      setFilterData([...initialState]);
    }
    localStorage.getItem(`reduceData${name}`) &&
      setReduceData(JSON.parse(localStorage.getItem(`reduceData${name}`)));
  }, []);

  const handleFormatArray = () => {
    setSelectData(
      filterDataValue.map((item) => {
        return {
          application_id: {
            name: item.application_id,
            value: item.application_id,
          },
          nbfc: {
            name: item?.nbfcData?.name,
            value: item?.nbfcData?.nbfc_id,
          },
          oem: {
            name: item?.oemData?.company_name,
            value: item?.oemData?.oem_id,
          },
          customer_name: {
            name: item?.customerData?.pan_details?.name,
            value: item?.customerData?.customer_id,
          },
          anchor: {
            name: item?.anchorData?.name,
            value: item?.anchorData?.anchor_id,
          },
          physical_nach_status: {
            name: item?.physical_nach_status,
            value: item?.physical_nach_status,
          },
          business_executive: {
            name: item?.loanData?.sales_agent?.email,
            value: item?.loanData?.sales_agent?.email,
          },
        };
      })
    );
  };

  const getFilterValue = async () => {
    try {
      setLoading(true);
      let { data } = await fetchFilterValue();
      
      if(user?.isUserPresenter){
        let sample_apps = new Set(sampleCollectionApplications);
        data = data?.filter(itm=>sample_apps.has(itm?.application_id));
      }

      if (embifi_users) {
        let tmp_arr = [];
        let coll_exec = embifi_users;
        for (const ele of coll_exec) {
          tmp_arr.push(ele.email);
        }
        setCollectionExecutive(tmp_arr);
      }
      setFilterDataValue(data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFormatArray();
  }, [filterDataValue]);

  useEffect(() => {
    // localStorage.setItem(`selectData${name}`, JSON.stringify(selectData));
    localStorage.setItem(`filterData${name}`, JSON.stringify(filterData));
    // localStorage.setItem(`reduceData${name}`, JSON.stringify(reduceData));
    localStorage.setItem(
      `overallStatusDays`,
      JSON.stringify({ isSelected: true })
    );
  }, [selectData, filterData, reduceData, overallStatusDays]);

  const handleReduceFilterValue = () => {
    let dropData = {};

    let finalArray = [...selectData];
    filterData.map((item, index) => {
      if (
        item.field !== "" &&
        item.field !== "transaction_date" &&
        item.field !== "created_date" &&
        item.field !== "payment_mode" &&
        item.field !== "due_date" &&
        item.field !== "followup_date" &&
        item.field !== "old_dpd" &&
        item.values.length > 0
      ) {
        finalArray = finalArray.filter((o1) => {
          return item.values.some((o2) => {
            return (
              o1?.[`${item?.field}`]?.value === o2?.[`${item?.field}`]?.value
            );
          });
        });
      }
      dropData = {
        ...dropData,
        [index + 1]: [...finalArray],
      };
    });

    setReduceData(dropData);
  };

  useEffect(() => {
    handleReduceFilterValue();
  }, [filterData]);

  const handleApply = () => {
    let finalFilter = {};
    if (
      overallStatusDays.type !== "" &&
      overallStatusDays.from > overallStatusDays.to
    ) {
      toast.error(`OVERALL STATUS DAYS : "FROM" should be < and = "TO"`);
      return;
    }
    filterData.map((item, index) => {
      if (
        item.field === "transaction_date" ||
        item.field === "created_date" ||
        item.field === "payment_mode" ||
        item.field === "due_date" ||
        item.field === "followup_date" ||
        item.field === "old_dpd"
      ) {
        finalFilter[item.field] = item.values;
      } else if (item?.field === "comments_date") {
        finalFilter[item.field] = item.values;
      } else if (item?.field === "comments") {
        finalFilter[item.field] = { filter: item.values[0] };
      } else if (item.field === "is_approved") {
        finalFilter[item.field] = item.values[0] == "null" ? false : true;
      } else if (item.field === "overall_status") {
        finalFilter[item.field] = item.values[0] == "due" ? 1 : 2;
      } else if (item.field === "overall_status_days") {
        finalFilter["day"] = {
          from: overallStatusDays.from,
          to: overallStatusDays.to,
        };
      } else if (item.field === "edited status") {
        finalFilter["is_edited"] = item.values[0];
      } else if (
        item.field === "Greater than" ||
        item.field === "Less than" ||
        item.field === "Between"
      ) {
        if (item.field === "Between") {
          finalFilter["totalPayable"] = {
            filter: `${item.field}`,
            amountFrom: item?.values?.[0],
            amountTo: item?.values?.[1],
          };
        } else {
          finalFilter["totalPayable"] = {
            filter: `${item.field}`,
            amount: item?.values?.[0],
          };
        }
      } else if (
        item.field === "customer_profile_percentage" ||
        item.field === "delayed_threshold" ||
        item.field === "collection_executive" ||
        item.field === "last_comment"
      ) {
        finalFilter[item.field] = item.values;
      } else {
        finalFilter[item.field] = item.values.map(
          (val) => val[item.field]?.value
        );
      }
    });

    onApply(finalFilter);
    if (setOpenStatusOpts) setOpenStatusOpts(false);
    // setOpenNetPayableAmount(false);
  };

  const profileValue = {
    0: {
      to: 0,
    },
    1: {
      from: 0,
      to: 100,
    },
    2: {
      from: 100,
      to: 200,
    },
    3: {
      from: 200,
    },
  };

  function getKeyByValue(obj, val) {
    for (const key in obj) {
      if (obj[key]?.from === val?.from && obj[key]?.to === val?.to) {
        return key;
      }
    }
    return null;
  }

  return (
    <>
      <div>
        <Modal
          show={open}
          // onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
        >
          <Modal.Body>
            {loading && <LoadingSkeleton />}
            {!loading && (
              <div className="sort-dialog">
                <div className="sort-table-wrapper">
                  <table className="sort-table" id="sort-table">
                    <thead class="cf">
                      <tr>
                        <th style={{ width: 500 }}>Column</th>
                        <th style={{ width: "70%" }}>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterData?.map((column, i) => {
                        return (
                          <>
                            <tr>
                              <td data-title="Data Point">
                                <Select
                                  className="w-100"
                                  value={column.field}
                                  sx={{
                                    boxShadow: "none",
                                    ".MuiOutlinedInput-notchedOutline": {
                                      border: 0,
                                    },
                                  }}
                                  onChange={(e) => {
                                    handleChange("field", e.target.value, i);
                                    if (
                                      (e.target.value !== "overall_status" &&
                                        column?.field === "overall_status") ||
                                      i < optionalIndexes.overall_status
                                    ) {
                                      setOpenStatusOpts(false);
                                    }
                                    if (
                                      (e.target.value !==
                                        "net_payable_amount" &&
                                        column?.field ===
                                          "net_payable_amount") ||
                                      i < optionalIndexes.net_payable_amount
                                    ) {
                                      setOpenNetPayableAmount(false);
                                    }
                                    if (e.target.value === "overall_status")
                                      setOptionalIndexes((prev) => ({
                                        ...prev,
                                        overall_status: i,
                                      }));
                                    if (e.target.value === "net_payable_amount")
                                      setOptionalIndexes((prev) => ({
                                        ...prev,
                                        net_payable_amount: i,
                                      }));
                                  }}
                                >
                                  {column?.options.map((item) => {
                                    return (
                                      <MenuItem value={item}>
                                        {(() => {
                                          if (
                                            item ===
                                            "customer_profile_percentage"
                                          )
                                            return "Profile %";
                                          else if (item === "is_approved")
                                            return "APPROVAL STATUS";
                                          else
                                            return (item || "")
                                              ?.replaceAll("_", " ")
                                              ?.toUpperCase();
                                        })()}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </td>
                              <td data-title="Operator">
                                {column?.field !== "" &&
                                  (() => {
                                    if (
                                      column?.field === "transaction_date" ||
                                      column?.field === "created_date" ||
                                      column?.field === "due_date" ||
                                      column?.field === "comments_date" ||
                                      column?.field === "followup_date"
                                    ) {
                                      return (
                                        <DateRangePicker
                                          // minDate={new Date(initalState?.[0]?.due_date)}
                                          // maxDate={
                                          //   new Date(initalState?.slice(-1)[0]?.due_date)
                                          // }
                                          startDate={
                                            new Date(
                                              column?.values?.from || new Date()
                                            )
                                          }
                                          endDate={
                                            new Date(
                                              column?.values?.to || new Date()
                                            )
                                          }
                                          onChange={(start, end) => {
                                            handleChange(
                                              "values",
                                              {
                                                from: Number(start),
                                                to: Number(end),
                                              },
                                              i
                                            );
                                          }}
                                        />
                                      );
                                    } else if (
                                      column?.field === "collection_executive"
                                    ) {
                                      return (
                                        <AutocompleteSelect
                                          selectData={collectionExecutive}
                                          isSimple={true}
                                          values={column.values}
                                          handleChange={(value) => {
                                            handleChange("values", value, i);
                                          }}
                                        />
                                      );
                                    } else if (
                                      column?.field === "last_comment"
                                    ) {
                                      return (
                                        <AutocompleteSelect
                                          selectData={tags}
                                          isSimple={true}
                                          values={column.values}
                                          handleChange={(value) => {
                                            handleChange("values", value, i);
                                          }}
                                        />
                                      );
                                    } else if (
                                      column?.field === "payment_mode"
                                    ) {
                                      return (
                                        <AutocompleteSelect
                                          selectData={[
                                            "CASH",
                                            "UPI",
                                            "NACH",
                                            "PAYMENT_LINK",
                                            "PDC/Cheques",
                                            "NEFT",
                                          ]}
                                          isSimple={true}
                                          values={column.values}
                                          handleChange={(value) => {
                                            handleChange(
                                              "values",
                                              [...value],
                                              i
                                            );
                                          }}
                                        />
                                      );
                                    } else if (
                                      column?.field === "is_approved"
                                    ) {
                                      return (
                                        <FormControl fullWidth>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label=""
                                            onChange={(x) => {
                                              handleChange(
                                                "values",
                                                [x.target.value],
                                                i
                                              );
                                            }}
                                          >
                                            <MenuItem value={true}>
                                              APPROVED
                                            </MenuItem>
                                            <MenuItem value={"null"}>
                                              NOT YET APPROVED
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      );
                                    } else if (
                                      column?.field === "overall_status"
                                    ) {
                                      return (
                                        <FormControl fullWidth>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label=""
                                            value={column.values}
                                            onChange={(x) => {
                                              handleChange(
                                                "values",
                                                [x.target.value],
                                                i
                                              );
                                              setOverallStatusDays((prev) => ({
                                                ...prev,
                                                type: x.target.value,
                                              }));
                                              /* conditional rendering of open status days */
                                              if (!openStatusOpts) {
                                                setOpenStatusOpts(true);
                                                setOverallStatusDays(
                                                  (prev) => ({
                                                    ...prev,
                                                    type: x.target.value,
                                                  })
                                                );
                                                setFilterData([
                                                  ...filterData.slice(0, i + 1),
                                                  {
                                                    ...columns,
                                                    field:
                                                      "overall_status_days",
                                                    options: [
                                                      "overall_status_days",
                                                    ],
                                                  },
                                                  ...filterData.slice(i + 1),
                                                ]);
                                              } else if (
                                                openNetPayableAmount &&
                                                (filterData[i + 1]?.field ===
                                                  "due" ||
                                                  filterData[i + 1]?.field ===
                                                    "delay")
                                              ) {
                                                filterData[i + 1] = {
                                                  ...columns,
                                                  field: x.target.value,
                                                  options: [x.target.value],
                                                };
                                                setFilterData([...filterData]);
                                              }
                                            }}
                                          >
                                            <MenuItem value={"due"}>
                                              DUE
                                            </MenuItem>
                                            <MenuItem value={"delay"}>
                                              DELAY
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      );
                                    } else if (
                                      column?.field === "net_payable_amount"
                                    ) {
                                      return (
                                        <FormControl fullWidth>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label=""
                                            value={column.values}
                                            onChange={(x) => {
                                              handleChange(
                                                "values",
                                                [x.target.value],
                                                i
                                              );
                                              /* conditional rendering of open status days */
                                              if (!openNetPayableAmount) {
                                                setOpenNetPayableAmount(true);
                                                setFilterData([
                                                  ...filterData?.slice(
                                                    0,
                                                    i + 1
                                                  ),
                                                  {
                                                    ...columns,
                                                    field: x.target.value,
                                                    options: [x.target.value],
                                                  },
                                                  ...filterData?.slice(i + 1),
                                                ]);
                                              } else if (
                                                openNetPayableAmount &&
                                                (filterData[i + 1]?.field ===
                                                  "Greater than" ||
                                                  filterData[i + 1]?.field ===
                                                    "Less than" ||
                                                  filterData[i + 1]?.field ===
                                                    "Between")
                                              ) {
                                                filterData[i + 1] = {
                                                  ...columns,
                                                  field: x.target.value,
                                                  options: [x.target.value],
                                                };
                                                setFilterData([...filterData]);
                                              }
                                            }}
                                          >
                                            <MenuItem value={"Greater than"}>
                                              GREATER THAN
                                            </MenuItem>
                                            <MenuItem value={"Less than"}>
                                              LESS THAN
                                            </MenuItem>
                                            <MenuItem value={"Between"}>
                                              BETWEEN
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      );
                                    } else if (
                                      column?.field === "edited status"
                                    ) {
                                      return (
                                        <FormControl fullWidth>
                                          <Select
                                            labelId="demo-simple-select-edited"
                                            id="demo-simple-select-edited"
                                            label=""
                                            onChange={(ev) => {
                                              handleChange(
                                                "values",
                                                [ev.target.value],
                                                i
                                              );
                                            }}
                                          >
                                            <MenuItem value={true}>
                                              Edited
                                            </MenuItem>
                                            <MenuItem value={false}>
                                              Not Edited
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      );
                                    } else if (
                                      column?.field === "Greater than" ||
                                      column?.field === "Less than" ||
                                      column?.field === "delayed_threshold"
                                    ) {
                                      return (
                                        <FormControl fullWidth>
                                          <TextField
                                            id="outlined-basic"
                                            type="number"
                                            variant="outlined"
                                            value={column?.values[0]}
                                            onChange={(x) => {
                                              let value = x.target.value;
                                              if (!isNaN(value)) {
                                                handleChange(
                                                  "values",
                                                  column?.field ===
                                                    "delayed_threshold"
                                                    ? { amount: Number(value) }
                                                    : [value],
                                                  i
                                                );
                                              }
                                            }}
                                          />
                                        </FormControl>
                                      );
                                    } else if (column?.field === "Between") {
                                      return (
                                        <FormControl
                                          fullWidth
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <TextField
                                            id="outlined-basic"
                                            type="number"
                                            variant="outlined"
                                            value={column?.values?.[0]}
                                            onChange={(x) => {
                                              let value = x.target.value;
                                              if (!isNaN(value)) {
                                                column.values[0] = value;
                                                handleChange(
                                                  "values",
                                                  [...column?.values],
                                                  i
                                                );
                                              }
                                            }}
                                          />
                                          <p
                                            style={{
                                              padding: "8px 10px",
                                              display: "grid",
                                              placeItems: "center",
                                            }}
                                          >
                                            To
                                          </p>
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            type="number"
                                            value={column?.values?.[1]}
                                            onChange={(x) => {
                                              let value = x.target.value;
                                              if (!isNaN(value)) {
                                                column.values[1] = value;
                                                handleChange(
                                                  "values",
                                                  [...column?.values],
                                                  i
                                                );
                                              }
                                            }}
                                          />
                                        </FormControl>
                                      );
                                    } else if (column?.field === "comments") {
                                      return (
                                        <FormControl fullWidth>
                                          <Select
                                            labelId="demo-simple-select-edited"
                                            id="demo-simple-select-edited"
                                            label=""
                                            value={column?.values?.[0]}
                                            onChange={(ev) => {
                                              handleChange(
                                                "values",
                                                [ev.target.value],
                                                i
                                              );
                                            }}
                                          >
                                            <MenuItem value={true}>
                                              TRUE
                                            </MenuItem>
                                            <MenuItem value={false}>
                                              FALSE
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      );
                                    } else if (
                                      column?.field === "overall_status_days"
                                    ) {
                                      return overallStatusDays.type ===
                                        "due" ? (
                                        <FormControl
                                          fullWidth
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <TextField
                                            id="overall-status-from-day"
                                            select
                                            defaultValue="FROM"
                                            value={overallStatusDays.from}
                                            onChange={(x) =>
                                              setOverallStatusDays({
                                                ...overallStatusDays,
                                                from: parseInt(x.target.value),
                                              })
                                            }
                                            style={{ flex: 1 }}
                                          >
                                            {[1, 2, 3, 4, 5].map((day) => (
                                              <MenuItem key={day} value={day}>
                                                {day}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                          <TextField
                                            id="overall-status-to-day"
                                            select
                                            defaultValue="TO"
                                            placeholder="To"
                                            value={
                                              overallStatusDays.from >
                                              overallStatusDays.to
                                                ? overallStatusDays.from
                                                : overallStatusDays.to
                                            }
                                            onChange={(x) => {
                                              setOverallStatusDays({
                                                ...overallStatusDays,
                                                to: parseInt(x.target.value),
                                              });
                                            }}
                                            style={{ flex: 1 }}
                                          >
                                            {[1, 2, 3, 4, 5].map((day, i) => {
                                              return (
                                                <MenuItem key={i} value={day}>
                                                  {day}
                                                </MenuItem>
                                              );
                                            })}
                                          </TextField>
                                        </FormControl>
                                      ) : (
                                        <FormControl
                                          fullWidth
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <TextField
                                            id="overall-status-from-day"
                                            InputProps={{
                                              inputProps: {
                                                min: 1, // Set the minimum value here
                                              },
                                            }}
                                            type="number"
                                            placeholder="FROM"
                                            value={
                                              overallStatusDays.from < 1
                                                ? 1
                                                : overallStatusDays.from
                                            }
                                            onChange={(x) =>
                                              setOverallStatusDays({
                                                ...overallStatusDays,
                                                from: parseInt(x.target.value),
                                              })
                                            }
                                            style={{ flex: 1 }}
                                          ></TextField>
                                          <TextField
                                            id="overall-status-to-day"
                                            placeholder="To"
                                            type="number"
                                            value={overallStatusDays.to}
                                            minValue="1"
                                            onChange={(x) => {
                                              setOverallStatusDays({
                                                ...overallStatusDays,
                                                to: parseInt(x.target.value),
                                              });
                                            }}
                                            style={{ flex: 1 }}
                                          ></TextField>
                                        </FormControl>
                                      );
                                    } else if (
                                      column?.field ===
                                      "customer_profile_percentage"
                                    ) {
                                      return (
                                        <FormControl fullWidth>
                                          <Select
                                            labelId="demo-simple-select-edited"
                                            id="demo-simple-select-edited"
                                            label=""
                                            value={getKeyByValue(
                                              profileValue,
                                              column?.values
                                            )}
                                            onChange={(e) => {
                                              handleChange(
                                                "values",
                                                profileValue?.[e.target.value],
                                                i
                                              );
                                            }}
                                          >
                                            <MenuItem value={0}>0%</MenuItem>
                                            <MenuItem value={1}>
                                              {"0% to < 100%"}
                                            </MenuItem>
                                            <MenuItem value={2}>
                                              {"100% to < 200%"}
                                            </MenuItem>
                                            <MenuItem value={3}>
                                              {">=200%"}
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      );
                                    } else if (column?.field === "old_dpd") {
                                      return (
                                        <TextField
                                          fullWidth
                                          type="date"
                                          value={moment(
                                            new Date(column?.values?.date)
                                          ).format("YYYY-MM-DD")}
                                          inputProps={{
                                            max: moment()
                                              .subtract(1, "days")
                                              .format("YYYY-MM-DD"),
                                          }}
                                          onChange={(e) => {
                                            handleChange(
                                              "values",
                                              {
                                                date: new Date(
                                                  e.target.value
                                                ).getTime(),
                                              },
                                              i
                                            );
                                          }}
                                        />
                                      );
                                    } else {
                                      console.log("JSX => ",column,selectData,reduceData);
                                      return (
                                        // <AutocompleteSelect
                                        //   selectData={
                                        //     i === 0 &&
                                        //     ![
                                        //       "payment_mode",
                                        //       "transaction_date",
                                        //       "created_date",
                                        //       "due_date",
                                        //       "followup_date",
                                        //     ].includes(column.field)
                                        //       ? selectData
                                        //       : reduceData[i]
                                        //   }
                                        //   field={column.field}
                                        //   values={column.values}
                                        //   handleChange={(value) => {
                                        //     handleChange(
                                        //       "values",
                                        //       [...value],
                                        //       i
                                        //     );
                                        //   }}
                                        // />
                                        <Autocomplete
                                          multiple
                                          id="checkboxes-tags-demo"
                                          options={(() => {
                                            const allOptions = [
                                              ...new Map(
                                                i === 0 &&
                                                ![
                                                  "payment_mode",
                                                  "transaction_date",
                                                  "created_date",
                                                  "due_date",
                                                  "followup_date",
                                                ].includes(column.field)
                                                  ? selectData?.map((item) => [
                                                      item?.[column?.field]
                                                        ?.name,
                                                      item,
                                                    ])
                                                  : reduceData?.[i]?.map(
                                                      (item) => [
                                                        item?.[column?.field]
                                                          ?.name,
                                                        item,
                                                      ]
                                                    )
                                              ).values(),
                                            ]
                                            // .filter((itm) =>
                                            //   Boolean(
                                            //     itm?.[column?.field]?.values
                                            //   )
                                            // );
                                            console.log("all options => ",allOptions);
                                            return allOptions;
                                          })()}
                                          value={column?.values}
                                          disableCloseOnSelect
                                          getOptionLabel={(option) =>
                                            option?.[column?.field]?.name
                                          }
                                          filterOptions={createFilterOptions({
                                            matchFrom: "any",
                                            stringify: (option) =>
                                              option?.[column?.field]?.name ||
                                              "",
                                          })}
                                          renderOption={(
                                            props,
                                            option,
                                            { selected }
                                          ) => (
                                            <li
                                              {...props}
                                              key={
                                                option?.[column?.field]?.values
                                              }
                                            >
                                              <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                              />
                                              {option?.[column?.field]?.name +
                                                (column?.field === "anchor"
                                                  ? " - " +
                                                    option?.[column?.field]
                                                      ?.value
                                                  : "")}
                                            </li>
                                          )}
                                          fullWidth
                                          onChange={(e, value) => {
                                            handleChange(
                                              "values",
                                              [...value],
                                              i
                                            );
                                          }}
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                        />
                                      );
                                    }
                                  })()}
                              </td>
                              <td
                                className="sort-close-td ps-1"
                                style={{ width: "30px" }}
                              >
                                {i !== 0 &&
                                  column.field !== "overall_status_days" && (
                                    <CloseIcon
                                      onClick={() => removeItem(i)}
                                      sx={{ fontSize: 18 }}
                                    />
                                  )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                    <Button className="mb-3" onClick={addMore}>
                      Add more
                    </Button>
                  </table>
                </div>
                <div class="sort-bottom-holder">
                  <Button
                    className="me-2 clear-btn"
                    onClick={() => {
                      // handleClear();
                      if (openStatusOpts) setOpenStatusOpts(false);
                      if (openNetPayableAmount) setOpenNetPayableAmount(false);
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    className="apply-btn"
                    onClick={() => {
                      // if (!isPrevCompleted()) {
                      //   return;
                      // }
                      // onApply(filterData);
                      handleApply();
                    }}
                  >
                    Apply Filters
                  </Button>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
