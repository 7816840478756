import { Close } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, Grow, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Zoom, styled, tableCellClasses } from '@mui/material'
import React from 'react'
import { f } from '../../utility/Formater';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#d8e0ff",
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));


function CustomerContactHistory({ open, handleClose , data }) {
    return (
        <Dialog
            maxWidth={"md"} open={open} onClose={handleClose}
            sx={{ "& .MuiDialog-paper": { maxHeight: 580 } }} fullWidth  >
            <DialogTitle style={{ backgroundColor: '#EFF5FF' }}>
                History
            </DialogTitle>
            <IconButton style={{
                position: 'absolute',
                top: 5,
                right: 5
            }} onClick={handleClose}>
                <Close />
            </IconButton>
            <DialogContent dividers sx={{m:0,p:0}}>
                <Zoom in={true}>
                    <TableContainer
                        // component={Paper}
                        fullWidth
                        sx={{
                            height: 400,
                            "::-webkit-progress-bar": { display: "none" },
                            margin:0,
                            padding:0
                        }}
                    >
                        <Table
                            stickyHeader
                            sx={{ width: "100%" , margin:0 , padding:0 }}
                            aria-label="customized table"
                        >
                            <TableHead>
                                <Grow
                                    in={true}
                                    style={{ transformOrigin: "0 0 0" }}
                                    {...({ timeout: 1000 })}
                                >
                                    <TableRow>
                                        <StyledTableCell align='left' width={50} >Date</StyledTableCell>
                                        <StyledTableCell align="left" width={50} >
                                            mobile number
                                        </StyledTableCell>
                                        <StyledTableCell align="left" >
                                            Reason for changing the number
                                        </StyledTableCell>
                                    </TableRow>
                                </Grow>
                            </TableHead>
                            <TableBody>
                                { (data?.data || []).map( item => <Grow
                                    in={true}
                                    style={{ transformOrigin: "0 0 0" }}
                                    {...({ timeout: 1000 })}
                                >
                                    <StyledTableRow>
                                        <StyledTableCell align="left">
                                            {f(item?.date,"date")}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {f(item?.old_number)}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" width={150}>
                                            {f(item?.reason)}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </Grow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Zoom>
            </DialogContent>
        </Dialog>
    )
}

export default CustomerContactHistory