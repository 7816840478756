import FilterAltIcon from "@mui/icons-material/FilterAlt";
import GetAppIcon from "@mui/icons-material/GetApp";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CountUp from "react-countup";
import { toast } from "react-toastify";
import GPSInventoryFilter from "../../Components/Sort/GPSInventoryFilter";
import { getGpsInventoryV2, getGpsStatics, gpsCallingDashboard } from "../../api";
import idleIco from "../../assets/idle.svg";
import movingIco from "../../assets/movingIco.svg";
import noData from "../../assets/noData.svg";
import noGps from "../../assets/noGps.svg";
import stopIco from "../../assets/stop.svg";
import towingIco from "../../assets/towing.svg";
import DailyTrend from "./Tabs/DailyTrend";
import GPSDistribution from "./Tabs/GPSDistribution";
import GPSInstallationChase from "./Tabs/GPSInstallationChase";
import InactiveVehicle from "./Tabs/InactiveVehicleDPD";
import Installation from "./Tabs/Installation";
import Movement from "./Tabs/Movement";
import "./gps.css";
import { UserContext } from "../../Context/UserContext";

const spanStyle = {
  fontSize: 15,
};

const inventoryFilters = ["procurer", "disbursed_date", "status","agents"];
const installationChaseFilters = ["gps_status", "application_status", "disbursed_date", "dealers"];

const GPSDashboard = () => {
  ///////////////////////////
  // Statics Card Data

  const [cardType, setCardType] = useState("mmi");
  const [cardData, setCardData] = useState({});
  const [lastUpdated, setLastUpdated] = useState(null);
  const [loadingCard, setLoadingCard] = useState(false);
  const [inventorySearch, setInventorySearch] = useState("");
  const [inventoryFilter, setInventoryFilter] = useState(null);
  const [gpsChaseSearch, setGpsChaseSearch] = useState("");
  const [gpsChaseFilter, setGpsChaseFilter] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [currTab, setCurrTab] = useState("tracker");
  const [currInventoryTab, setCurrInventoryTab] = useState("GpsDistribution");
  const [filterPopup, setFilterPopup] = useState(false);
  const { user } = useContext(UserContext);

  const inventoryTabsRef = useRef(null);
  
  const isGPSDistribution = currInventoryTab === "GpsDistribution";

  function handleFetchData(page){
    if(currInventoryTab === "GpsDistribution"){
      inventoryTabsRef?.current?.getFilterData(isGPSDistribution,inventorySearch,inventoryFilter,page ?? 1,setLoadingCard);
    }else if(currInventoryTab === "GpsInstallationChase"){
      inventoryTabsRef?.current?.getFilterData(isGPSDistribution,gpsChaseSearch,gpsChaseFilter,page ?? 1,setLoadingCard);
    }
  }

  useEffect(()=>{
      handleFetchData();
  },[inventoryFilter,inventorySearch,gpsChaseSearch,gpsChaseFilter,currInventoryTab]);

  useEffect(() => {
    setCurrTab(!user?.isUserPresenter ? "gps_inventory" : "gps_tracker");
    setCurrInventoryTab("GpsDistribution");
  },[]);

  const isFilterApplied = currInventoryTab && localStorage.getItem(`filterData${currInventoryTab}`);
  return (
    <>
      <div
        style={{
          position: "relative",
          padding: "10px",                                                                                                                                              
        }}
      >
        <div className="dash-stat-holder">
          <Grid container spacing={2}>
            {!user?.isUserPresenter && <Grid item xs={12}>
              <div className="d-flex justify-content-between w-100 align-items-center">
                <ToggleButtonGroup
                  value={currTab}
                  onChange={(_, val) => {
                    setCurrTab(val || "gps_inventory");
                  }}
                  aria-label="device"
                  size="small"
                  exclusive
                  className="fw-semibold"
                  sx={{
                    "& .MuiToggleButton-root": {
                      transition: "all 0.6s ease", // Add transition for smooth animation
                      "&.Mui-selected": {
                        color: "#fff", // Customize border color when selected
                        backgroundImage:
                          "linear-gradient(to right,#801AE6,#A21AE6)",
                      },
                    },
                  }}
                >
                  <ToggleButton
                    value="gps_inventory"
                    aria-label="gps_inventory"
                    size="small"
                  >
                    GPS Inventory
                  </ToggleButton>
                  <ToggleButton
                    value="gps_tracker"
                    aria-label="gps_tracker"
                    size="small"
                  >
                    GPS Tracker
                  </ToggleButton>
                </ToggleButtonGroup>
                {currTab === "gps_tracker" && (
                  <div>
                    <input
                      type="checkbox"
                      id="toggle"
                      class="toggleCheckbox"
                      onChange={(e) => {
                        setCardType(e.target.checked ? "mufin" : "mmi");
                      }}
                    />
                    <label for="toggle" class="toggleContainer">
                      <div>MMI</div>
                      <div>Mufin</div>
                    </label>
                  </div>
                )}
                {currTab === "gps_inventory" && (
                  <Button
                    variant="contained"
                    size="small"
                    disableElevation
                    disabled={exportLoading}
                    onClick={() => {
                      inventoryTabsRef?.current?.handleExport(
                        isGPSDistribution,
                        isGPSDistribution ? inventorySearch : gpsChaseSearch,
                        isGPSDistribution ? inventoryFilter : gpsChaseFilter,
                        setExportLoading
                      );
                    }}
                  >
                    <GetAppIcon fontSize="small" className="me-1" />
                    Export
                  </Button>
                )}
              </div>
            </Grid>}
            {currTab === "gps_tracker" && (
              <GPSTrackerSummery
                loadingCard={loadingCard}
                cardData={cardData}
              />
            )}
            {currTab === "gps_inventory" && (
              <SearchBar
                search={isGPSDistribution ? inventorySearch : gpsChaseSearch}
                handleEnter={(str)=>{
                  isGPSDistribution && setInventorySearch(str);
                  (!isGPSDistribution) && setGpsChaseSearch(str);
                }}
              />
            )}
            {currTab === "gps_inventory" && (
              <Grid
                item
                xs={12}
                sm={2}
                spacing={2}
                alignItems={"center"}
                justifyContent={"flex-start"}
                className="ms-1 d-flex flex-row align-items-center"
              >
                <div className={isFilterApplied ? "zoom-animation" : ""}>
                  <Button
                    size="small"
                    // sx={{ color: "gray" }}
                    onClick={() => {
                      setFilterPopup(true);
                    }}
                    color="primary"
                  >
                    <FilterAltIcon fontSize="small" /> Filters
                  </Button>
                  {isFilterApplied && (
                    <span
                      className="pointer mx-3"
                      onClick={() => {
                        localStorage.removeItem(
                          `filterData${currInventoryTab}`
                        );
                        if(isGPSDistribution){
                          setInventoryFilter(null);
                          setInventorySearch("");
                        }else{
                          setGpsChaseFilter(null);
                          setGpsChaseSearch("");
                        }
                        setFilterPopup(false);
                        inventoryTabsRef?.current?.getAllData(isGPSDistribution);
                      }}
                    >
                      <HighlightOffIcon style={{ fontSize: 20 }} />
                    </span>
                  )}
                </div>
              </Grid>
            )}
            {currTab === "gps_inventory" && (
              <GPSInventorySummery
                loadingCard={loadingCard}
                inventoryDetails={cardData}
              />
            )}
          </Grid>
          {currTab === "gps_tracker" && lastUpdated && !loadingCard && (
            <div className="gps-card-last-updated mt-2">
              Last Updated at:{" "}
              {moment(lastUpdated).format("DD MMMM YYYY, hh:mm a")}
            </div>
          )}
        </div>
        <div className="mt-2">
          {currTab === "gps_tracker" && (
            <GPSTrackerTabs
              cardType={cardType}
              setLoadingCard={setLoadingCard}
              setCardData={setCardData}
              setLastUpdated={setLastUpdated}
            />
          )}
          {currTab === "gps_inventory" && (
            <GPSInventoryTabs
              ref={inventoryTabsRef}
              setCardData={setCardData}
              onTabChange={(val)=>{setCurrInventoryTab(val)}}
              handleFetchData={handleFetchData}
            />
          )}
        </div>
      </div>
      <GPSInventoryFilter
        open={filterPopup}
        handleClose={() => {
          setFilterPopup(false);
        }}
        name={currInventoryTab}
        onApply={(filterData) => {
          isGPSDistribution && setInventoryFilter(filterData);
          (!isGPSDistribution) && setGpsChaseFilter(filterData);
          setFilterPopup(false);
        }}
        options={currInventoryTab==="GpsInstallationChase" ? installationChaseFilters : inventoryFilters}
      />
    </>
  );
};

const GPSTrackerTabs = ({
  cardType,
  setLoadingCard,
  setCardData,
  setLastUpdated
}) => {
  const [value, setValue] = React.useState(0);
  const { user } = useContext(UserContext);
  /////////////////////////////
  // Installation States
  const [pieData, setPieData] = useState({
    loaded: false,
    data: null,
  });

  const [InstTableData, setInstTableData] = useState({
    loaded: false,
    data: null,
  });

  const [disbursalTableData, setDisbursalTableData] = useState({
    loaded: false,
    data: null,
  });

  const [selectedFilterIns, setSelectedFilterIns] = useState("weekly");

  /////////////////////////////
  // Movement States

  const [trendData, setTrendData] = useState({
    loaded: false,
    data: null,
  });

  const [cityWiseData, setCityWiseData] = useState({
    loaded: false,
    data: null,
  });

  const [oemWiseData, setOemWiseData] = useState({
    loaded: false,
    data: null,
  });

  const [cityFilter, setCityFilter] = useState("VEHICLE_MAX");
  const [oemFilter, setOemFilter] = useState("VEHICLE_MAX");

  ///////////////////////////
  // Daily Trend State

  const [dailyGraphData, setDailyGraphData] = useState({
    loaded: false,
    data: null,
  });

  ///////////////////////////
  // Inactive vehicles //

  const [inactiveData, setInactiveData] = useState({
    loaded: false,
    data: null,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLoadCardData = async (filter) => {
    try {
      setLoadingCard(true);
      const { data } = await getGpsStatics(`?type=${filter}`);
      let countData = data?.data?.data || {};
      delete countData?.["null"];

      let total = Object.values(countData).reduce((acc, val) => acc + val, 0);
      let percentages = {};
      for (let key in countData) {
        percentages[key] = {
          count: countData[key],
          percentage: ((countData[key] / total) * 100).toFixed(2) + "%",
        };
      }

      setCardData(percentages || {});
      setLastUpdated(data?.data?.last_updated_date || null);
    } catch (error) {
      toast.error("Error loading GPS Statistics");
    }
    setLoadingCard(false);
  };

  useEffect(() => {
    handleLoadCardData("mmi");
  }, [cardType]);

  useEffect(()=>{
    setValue(!user?.isUserPresenter ? 0 : 2)
  },[])

  return (
    <Box sx={{ width: "100%" }}>
      {!user?.isUserPresenter  && <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          position: "sticky",
          top: 0,
          background: "white",
          zIndex: 6000,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Daily Trend" {...a11yProps(0)} />
          <Tab label="Installations" {...a11yProps(1)} />
          <Tab label="Movement" {...a11yProps(2)} />
          <Tab label="Inactive Vehicles" {...a11yProps(3)} />
          <Tab label="DPD Installation chase" {...a11yProps(4)} />
          {value !== 1 && (
            <div
              className="tab-menu-item-holder"
              style={{
                opacity: 0.5,
              }}
              onClick={() => {
                toast.info("Option coming soon");
              }}
            >
              <div>
                <input
                  type="checkbox"
                  id="toggle-tab"
                  class="toggleCheckbox"
                  disabled
                />
                <label for="toggle-tab" class="toggleContainer">
                  <div>MMI</div>
                  <div>Mufin</div>
                </label>
              </div>
            </div>
          )}
        </Tabs>
      </Box>}
      <CustomTabPanel value={value} index={0}>
        <DailyTrend graphDataState={[dailyGraphData, setDailyGraphData]} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Installation
          tableState={[InstTableData, setInstTableData]}
          pieState={[pieData, setPieData]}
          filterState={[selectedFilterIns, setSelectedFilterIns]}
          disbursalTableState={[disbursalTableData, setDisbursalTableData]}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Movement
          trendState={[trendData, setTrendData]}
          cityState={[cityWiseData, setCityWiseData]}
          oemState={[oemWiseData, setOemWiseData]}
          cityfilterState={[cityFilter, setCityFilter]}
          oemfilterState={[oemFilter, setOemFilter]}
        />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        <InactiveVehicle
          inactiveState={[inactiveData, setInactiveData]}
          type={"inactive"}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <InactiveVehicle
          inactiveState={[inactiveData, setInactiveData]}
          type={"dpd"}
        />
      </CustomTabPanel>
    </Box>
  );
};

const GPSInventoryTabs = forwardRef(({ setCardData, onTabChange, handleFetchData }, ref) => {
  const [value, setValue] = useState(0);

  const distributionRef = useRef(null);
  const installationRef = useRef(null);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    onTabChange(newValue === 0 ? "GpsDistribution" : "GpsInstallationChase");
  };

  useEffect(()=>{
    handleFetchData();
  },[])

  useImperativeHandle(ref, () => ({
    async handleExport(isGPSDistribution, search, filter, setLoader) {
      try {
        if (setLoader) setLoader(true);
        let params = `exportData=true`;
        if (Boolean(search)) params += `&search=${search}`;
        if (Boolean(filter)) params += `&filters=${JSON.stringify(filter)}`;
        const API = isGPSDistribution ? getGpsInventoryV2 : gpsCallingDashboard;
        const { data } = await API(params);

        const blob = new Blob([data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        let prefix =
          value === 0 ? "gps_distribution" : "gps_installation_chase";
        a.download = `${prefix}_${moment().format("DD/MM/yyyy")}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        toast.error(error?.response?.data?.message || error?.message);
      } finally {
        if (setLoader) setLoader(false);
      }
    },
    getCurrTab() {
      return value === 0 ? "GpsDistribution" : "GpsInstallationChase";
    },
    getAllData(){
      const current_ref = value === 0 ? distributionRef : installationRef;
      current_ref?.current?.handleFilterData(null, null, 1);
    },
    getFilterData(isGPSDistribution,search,filterData,page=1,setLoader){
      const current_ref = isGPSDistribution ? distributionRef : installationRef;
      current_ref?.current?.handleFilterData(search, filterData,page,setLoader);
    }
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          position: "sticky",
          top: 0,
          background: "white",
          zIndex: 6000,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="GPS Distribution" {...a11yProps(0)} />
          <Tab label="GPS Installation chase" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} p={0}>
        <GPSDistribution ref={distributionRef} setCardData={setCardData} fetchTableData={handleFetchData} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} p={0}>
        <GPSInstallationChase ref={installationRef} fetchTableData={handleFetchData} />
      </CustomTabPanel>
    </Box>
  );
});

const GPSTrackerSummery = ({ loadingCard, cardData }) => {
  const { user } = useContext(UserContext);
  
  let dummyTotalCount = 1015 + 413 + 219;
  if(user?.isUserPresenter){
    cardData={
      1:{
        count:1615
      },
      2:{
        count:413
      },
      3:{
        count:219
      }
    };
  }
  
  let getDumyPerc = (index) =>((cardData?.[index]?.count/dummyTotalCount)*100).toFixed(2)+ "%"

  return (
    <>
      <Grid item xs={3}>
        <div
          className="gps-dash-card"
          style={{
            background: "#e7f3ff",
          }}
        >
          <div className="card-ico">
            <img src={movingIco} />
          </div>
          <div>
            <p className="count">
              {loadingCard ? (
                <CircularProgress size={20} />
              ) : (
                <div>
                  <CountUp end={cardData?.[1]?.count} />{" "}
                  <span style={spanStyle}>({user?.isUserPresenter ? getDumyPerc(1) : cardData?.[1]?.percentage})</span>
                </div>
              )}
            </p>
            <p className="desc">Moving</p>
          </div>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div
          className="gps-dash-card"
          style={{
            background: "#FFF6E4",
          }}
        >
          <div className="card-ico">
            <img src={idleIco} />
          </div>
          <div>
            <p className="count">
              {loadingCard ? (
                <CircularProgress size={20} />
              ) : (
                <div>
                  <CountUp end={cardData?.[2]?.count} />{" "}
                  <span style={spanStyle}>({user?.isUserPresenter ? getDumyPerc(2)  : cardData?.[2]?.percentage})</span>
                </div>
              )}
            </p>
            <p className="desc">Idle</p>
          </div>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div
          className="gps-dash-card"
          style={{
            background: "#F6F2FF",
          }}
        >
          <div className="card-ico">
            <img src={stopIco} />
          </div>
          <div>
            <p className="count">
              {loadingCard ? (
                <CircularProgress size={20} />
              ) : (
                <div>
                  <CountUp end={cardData?.[3]?.count} />{" "}
                  <span style={spanStyle}>({user?.isUserPresenter ? getDumyPerc(3)  : cardData?.[3]?.percentage})</span>
                </div>
              )}
            </p>
            <p className="desc">Stopped</p>
          </div>
        </div>
      </Grid>
      {!user?.isUserPresenter && <Grid item xs={3}>
        <div
          className="gps-dash-card"
          style={{
            background: "#FFF4F4",
          }}
        >
          <div className="card-ico">
            <img src={towingIco} />
          </div>
          <div>
            <p className="count">
              {loadingCard ? (
                <CircularProgress size={20} />
              ) : (
                <div>
                  <CountUp end={cardData?.[4]?.count} />{" "}
                  <span style={spanStyle}>({cardData?.[4]?.percentage})</span>
                </div>
              )}
            </p>
            <p className="desc">Towing</p>
          </div>
        </div>
      </Grid>}
      {!user?.isUserPresenter && <Grid item xs={3}>
        <div
          className="gps-dash-card"
          style={{
            background: "#FAF1FF",
          }}
        >
          <div className="card-ico">
            <img src={noData} />
          </div>
          <div>
            <p className="count">
              {loadingCard ? (
                <CircularProgress size={20} />
              ) : (
                <div>
                  <CountUp end={cardData?.[5]?.count} />{" "}
                  <span style={spanStyle}>({cardData?.[5]?.percentage})</span>
                </div>
              )}
            </p>
            <p className="desc">No Data</p>
          </div>
        </div>
      </Grid>}
      {!user?.isUserPresenter && <Grid item xs={3}>
        <div
          className="gps-dash-card"
          style={{
            background: "#FBFFEB",
          }}
        >
          <div className="card-ico">
            <img src={noGps} />
          </div>
          <div>
            <p className="count">
              {loadingCard ? (
                <CircularProgress size={20} />
              ) : (
                <div>
                  <CountUp end={cardData?.[7]?.count} />{" "}
                  <span style={spanStyle}>({cardData?.[7]?.percentage})</span>
                </div>
              )}
            </p>
            <p className="desc">No GPS</p>
          </div>
        </div>
      </Grid>}
    </>
  );
};

const GPSInventorySummery = ({ loading, inventoryDetails }) => {
  return (
    <>
      <Grid item container spacing={2} className="mb-2">
        <OverViewCard
          isLoading={loading}
          heading={"Installation Target"}
          number={inventoryDetails?.installation_target}
          backgroundColor={"#E7F6FF"}
        />
        <OverViewCard
          isLoading={loading}
          heading={"Recieved by Dealer"}
          number={Number(inventoryDetails?.sent_to_dealer)}
          backgroundColor={"#FFF6E4"}
        />
        <OverViewCard
          isLoading={loading}
          heading={"No. Of GPS Installed"}
          number={inventoryDetails?.gps_installed}
          backgroundColor={"#FAF1FF"}
        />
        <OverViewCard
          isLoading={loading}
          heading={"Inventory Remaining"}
          number={inventoryDetails?.gps_remaining}
          backgroundColor={"#FFF4F4"}
        />
      </Grid>
    </>
  );
};

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px", // Rounded borders for pill shape
    backgroundColor: "#F3F2F2",
    // maxWidth: 350, // Set max-width for the search field
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F3F2F2", // Same color as background for seamless look
  },
  "& .MuiOutlinedInput-input": {
    padding: "10.5px 14px",
  },
  "& .MuiInputLabel-outlined": {
    transform: "translate(14px, 12px) scale(1)",
  },
});

const SearchBar = ({ search, handleEnter }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(search);
  }, [search]);

  return (
    <Grid item xs={12} sm={3} container spacing={2} className="my-2 ms-1">
      <CustomTextField
        fullWidth
        variant="outlined"
        placeholder="Search Dealer Name"
        value={value}
        onChange={(event) => {
          setValue(event?.target?.value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") handleEnter(value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, p = 2, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  p: PropTypes.number,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const OverViewCard = ({ heading, number, isLoading, backgroundColor }) => {
  return (
    <Grid item xs={12} sm={2.5}>
      <TextField
        disabled
        id="filled-basic"
        label={heading}
        variant="filled"
        // value={isLoading ? "Loading..." :[null, undefined].includes(number) ? "NA" : number}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                backgroundColor,
                height: 70,
                width: 50,
                marginLeft: -12,
                padding: "auto",
                border: "0px solid green",
              }}
            >
              <InputAdornment
                sx={{
                  // backgroundColor: (theme) => theme.palette.divider,
                  marginLeft: 1.5,
                  color: "#535353",
                }}
                position="start"
              >
                <>
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : [null, undefined].includes(number) ? (
                    "NA"
                  ) : (
                    <>
                      {" "}
                      <CountUp
                        style={{
                          flex: 1,
                          // backgroundColor: "pink",
                          padding: 0,
                        }}
                        end={number}
                      />
                    </>
                  )}
                </>
              </InputAdornment>
            </div>
          ),
        }}
        sx={{
          border: "1px solid rgba(68, 68, 68, 0.31)",
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
            backgroundColor,
          },
          "& .MuiFilledInput-root.Mui-disabled": {
            backgroundColor,
          },
        }}
      />
    </Grid>
  );
};

export default GPSDashboard;
