import jsPDF from "jspdf";
import logo from "../assets/logo-white2.png";
const tempDoc = new jsPDF();

const fm = (val) => {
  if (!val) {
    return "NA";
  }
  return Number(val)?.toLocaleString("en-IN");
};

export default function generateTablePDF(data, details, fineName, type) {
  const doc = new jsPDF();
  const {
    applicationId,
    name,
    nbfc,
    loanAmount,
    repayment_amount,
    interest_amount,
    interest,
    tenure,
    disbursalDate,
  } = details;
  const headers = [
    "SL.No.",
    "Date",
    type ? type : "EDI",
    "Principal",
    "Interest",
    "O/S Principle",
  ];
  const columnWidths = [15, 40, 30, 30, 30, 50];

  // doc.addImage(logo, "JPEG", 70, 10, 70, 17);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(18);
  doc.text(`Installment Schedule`, 75, 15);

  //   Values Section

  setLeftText("Application ID", applicationId, 30, 45, doc);
  setLeftText("Name", name, 35, 28, doc);
  setLeftText("Lender", nbfc, 40, 28, doc);
  setLeftText("Annual Interest Rate (Flat)", `${interest}%`, 45, 65, doc);

  setRightFont("Loan Amount", fm(loanAmount), 30, 30, doc);
  setRightFont("Repayment Amount", fm(repayment_amount), 35, 45, doc);
  setRightFont("Interest", fm(interest_amount), 40, 18, doc);
  setRightFont("Tenure", tenure, 45, 18, doc);
  setRightFont("Disbursal Date", disbursalDate, 50, 33, doc);

  // Set initial y position for the table
  doc.setFont("helvetica", "normal");
  let y = 60;
  let x = 8;

  // Loop through the data array
  data.forEach((row, rowIndex) => {
    if (rowIndex === 0) {
      headers.forEach((cell, index) => {
        doc.setFillColor(56, 55, 55);
        doc.setFontSize(12);
        doc.setTextColor(255, 255, 255);
        doc.rect(x, y, columnWidths[index], 10, "FD");
        doc.text(x + (index === 0 ? 1 : 4), y + 6, String(cell));
        x += columnWidths[index];
      });
      y += 10;
    }

    doc.setTextColor(0, 0, 0);
    x = 8;
    // Loop through the row values and draw cells
    Object.values(row).forEach((cell, index) => {
      doc.setFontSize(12);
      doc.rect(x, y, columnWidths[index], 10, "S");
      doc.text(x + 2, y + 8, String(cell));
      x += columnWidths[index];
    });

    y += 10;

    if (y === 290) {
      doc.addPage();
      y = 20;
    }
  });

  // Add table headers
  //   doc.setFont('helvetica', 'bold');
  //   doc.setTextColor(255, 255, 255);
  //   doc.setFillColor(0, 0, 0);
  //   doc.rect(10, 20, columnWidths.reduce((a, b) => a + b), 10, 'F');
  //   doc.text(12, 28, headers.join(''), { baseline: 'middle' });

  // Save the document
  doc.save(fineName ? fineName : "table.pdf");
}

const setLeftText = (key, value, y, keyspace, doc) => {
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text(`${key}:`, 10, y);
  doc.setFont("helvetica", "normal");
  doc.text(value, keyspace, y);
};

const setRightFont = (key, value, y, keyspace, doc) => {
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text(`${key}:`, 130, y);
  doc.setFont("helvetica", "normal");
  doc.text(value, 130 + keyspace, y);
};

function getTextWidth(text, fontSize) {
  const fontSizePt = fontSize;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  ctx.font = `${fontSizePt}px helvetica`;
  const width = ctx.measureText(text).width;
  return width;
}
