import { Button, CircularProgress, Grid } from "@mui/material";
// import { Box } from "@mui/system";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import loanIcon from "../../assets/shapeusers.svg";
import CountUp from "react-countup";
import SSImg from "../../assets/ss-table.png";
import AvTimerIcon from '@mui/icons-material/AvTimer';
import EvStationIcon from '@mui/icons-material/EvStation';
import ElectricRickshawIcon from '@mui/icons-material/ElectricRickshaw';
import SpeedIcon from '@mui/icons-material/Speed';
import PublicIcon from '@mui/icons-material/Public';

export const GPSOverview = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState({});
  const [loanType, setLoanType] = useState("");
  const [months, setMonths] = useState("");
  const [showDisbursedTable, setShowDisbursedTable] = useState(false);

  const style = {
    dashCardHolder1: {
      postion: "relative",
      boxShadow:
        "6px 6px 30px rgba(125, 153, 180, 0.2), 2px 2px 4px rgba(86, 144, 198, 0.11)",
      borderRadius: "10px",
      marginTop: "20px",
      padding: "20px",
      cursor: "pointer",
    },
    dashCardHolder2: {
      // width: {
      //   xs: 100,
      //   sm: 70,
      //   md: 70,
      // },
      postion: "relative",
      boxShadow:
        "6px 6px 30px rgba(125, 153, 180, 0.2), 2px 2px 4px rgba(86, 144, 198, 0.11)",
      borderRadius: "10px",
      marginTop: "20px",
      padding: "20px",
      cursor: "pointer",
      // width: "100%",
      maxWidth: "1000px",
    },
    card: {
      padding: "20px",
      postion: "relative !important",
      borderRight: {
        xs: "none",
        sm: "none",
        md: "2px solid #E6EDFF",
        lg: "2px solid #E6EDFF",
      },
      //  "2px solid #E6EDFF",
    },
    rightArrow: {
      textAlign: "center",
      backgroundColor: "#fff",
      borderRadius: "12px",
      height: "2em",
      width: "2em",
      boxShadow: "0px 2px 12px rgba(124, 141, 181, 0.12)",
      float: "right",
      top: 0,
    },
    arrowImg: {
      objectFit: "contain",
      width: "30%",
      height: "30%",
    },
    amountTxtsm: { fontSize: "2vh", fontWeight: "500" },
    amountTxt: {
      fontSize: "30px",
      fontWeight: "500",
      "@media(maxWidth: 450px)": {
        fontSize: "",
      },
    },
    desc: {
      color: "gray",
    },

    gridStyle: {
      padding: "20px",
      postion: "relative !important",
      marginLeft: "0px",
      borderRight: {
        xs: "none",
        sm: "2px solid #E6EDFF",
        md: "2px solid #E6EDFF",
        lg: "2px solid #E6EDFF",
      },
      borderBottom: {
        lg: "none",
        md: "none",
        xs: "2px solid #E6EDFF",
        sm: "2px solid #E6EDFF",
      },
    },

    gridStyleLast: {
      borderRight: "none",
      borderBottom: "none",
    },

    dashIco: {
      backgroundColor: "#E6EDFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
      borderRadius: "18px",
      color: "white",
      marginRight: 15,
      height: 50,
      width: 50,
    },
  };

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          marginBottom: "2em",
          padding: {
            xs: "75px 25px",
            sm: "75px 25px",
            md: "80px",
          },
        }}
        // style={{ width: "100%", marginBottom: "2em", padding: "80px" }}
      >
        <Grid
          container
          spacing={2}
          style={style.dashCardHolder1}
        >
          <Grid
            sx={style.gridStyle}
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            className="d-flex"
          >
            <div style={style?.dashIco}>
              <ElectricRickshawIcon sx={{ fontSize: 22, color: "#11101d" }} />
            </div>
            <div>
              <div className="amountTxtsm">
                {loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <CountUp end={18} />
                )}
              </div>
              <div style={style.desc}>Total Vehicles</div>
            </div>
          </Grid>

          <Grid
            sx={style.gridStyle}
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            className="d-flex"
          >
            <div style={style?.dashIco}>
              <PublicIcon sx={{ fontSize: 22, color: "#11101d" }}/>
            </div>
            <div>
              <div
                className="amountTxtsm"
                // style={style.amountTxt}
              >
                {loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <CountUp
                    end={19096}
                    formattingFn={(v) => Number(v) + " km"}
                  />
                )}
              </div>
              <div style={style.desc}>Total distance covered</div>
            </div>
          </Grid>

          <Grid
            sx={style.gridStyle}
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            className="d-flex"
          >
            <div style={style?.dashIco}>
              <AvTimerIcon sx={{ fontSize: 22, color: "#11101d" }} />
            </div>
            <div>
              <div
                className="amountTxtsm"
                // style={style.amountTxt}
              >
                {loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <CountUp end={2376} />
                )}
              </div>
              <div style={style.desc}>Total running hours</div>
            </div>
          </Grid>
          <Grid
            sx={{ ...style.gridStyle, ...style.gridStyleLast }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            className="d-flex"
          >
            <div style={style?.dashIco}>
              <LayersOutlinedIcon sx={{ fontSize: 22, color: "#11101d" }} />
            </div>

            <div>
              <div className="amountTxtsm">
                {loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <CountUp
                    end={Number(48)}
                    formattingFn={(v) => Number(v) + " km"}
                    decimals={2}
                  />
                )}
              </div>
              <div style={style.desc}>Avg distance covered per day</div>
            </div>
          </Grid>
        </Grid>

        <Grid
          // className="card"
          // className="dashCardHolder2"
          container
          spacing={2}
          style={style.dashCardHolder1}
        >
          <Grid
            item
            // xs={4}
            sx={style.gridStyle}
            xs={12}
            sm={6}
            md={6}
            lg={3}
            className="d-flex"
          >
            <div style={style?.dashIco}>
              <AvTimerIcon
                sx={{ fontSize: 22, color: "#11101d" }}
              />
            </div>
            <div>
              <div className="amountTxtsm">
                {loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <CountUp end={5.8} />
                )}
              </div>
              <div style={style.desc}>Avg. hrs travelled per day</div>
            </div>
          </Grid>
          <Grid
            sx={style.gridStyle}
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            className="d-flex"
          >
            <div style={style?.dashIco}>
              <SpeedIcon
                sx={{ fontSize: 22, color: "#11101d" }}
              />
            </div>
            <div>
              <div
                className="amountTxtsm"
                // style={style.amountTxt}
              >
                {loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <CountUp end={16.7} />
                )}
              </div>
              <div style={style.desc}>Avg. Speed</div>
            </div>
          </Grid>

          <Grid
            sx={{ ...style.gridStyle, ...style.gridStyleLast }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            className="d-flex"
          >
            <div style={style?.dashIco}>
              <EvStationIcon
                sx={{ fontSize: 22, color: "#11101d" }}
              />
            </div>
            <div>
              <div
                className="amountTxtsm"
                // style={style.amountTxt}
              >
                {loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <CountUp end={11} />
                )}
              </div>
              <div style={style.desc}># of vehicle Running</div>
            </div>
          </Grid>
          
        </Grid>
      </Box>
    </div>
  );
};
