import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GlobalContext } from "../Context/GlobalContext";
import { fetchAllDealers, getAgentData } from "../api";

function useAgent() {
  const { agentDetailState } = useContext(GlobalContext);
  const [agentDetails, setAgentDetails] = agentDetailState;
  const [initAgentDetails, setInitAgentDetails] = useState({
    name: "",
    email: "",
    mobile_number: [""],
    aadhaar_number: "",
    pan_number: "",
    account_number: "",
    beneficiary_name: "",
    ifsc_code: "",
    anchors: [
      // {
      //   anchor_id: "SAC0208230000000090",
      // },
    ],
    city: "",
    onground: false,
    department: [""]
  });
  const [selectedDealers, setSelectedDealers] = useState([]);
  const [dealersList, setDealerList] = useState([]);
  const [solved, setSolved] = useState(true);

  async function getAgentDetails(agent_id, setLoader) {
    try {
      setLoader(true);
      const { data } = await getAgentData(agent_id);
      if (data?.status) {
        setAgentDetails({ ...initAgentDetails, ...data?.agentData });
        setInitAgentDetails({ ...initAgentDetails, ...data?.agentData });
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "failed to fetch agent details"
      );
    } finally {
      setLoader(false);
    }
  }
  const emailValidation = () => {
    if (agentDetails?.email?.trim() === "") {
      return false;
    }
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(agentDetails?.email?.trim()) === false) {
      return true;
    } else {
      return false;
    }
  };

  function isValid() {
    let errorNames = "";
    if (emailValidation() ) {
      errorNames += "email, ";
    }
    if (agentDetails?.mobile_number?.[0]?.length !== 10) {
      errorNames += "mobile number";
    }
    return errorNames;
  }

  function buildPayload() {
    let payload = {};
   
    Object.keys(initAgentDetails).map((item) => {
       if (agentDetails?.[item] || item === "onground") {
        if (item === "anchors" && agentDetails[item].length) {
          payload[item] = agentDetails[item];
        } else if (
          item === "mobile_number" &&
          agentDetails[item][0]?.length === 10
        ) {
          payload[item] = agentDetails[item];
        } else if (agentDetails[item] !== null) {
          payload[item] = agentDetails[item];
        }
      }
    });
    return payload;
  }
  

  function getSelectedDelears(allDealers, selected){
    return selected?.map(item=>item?.anchor_id);
  }

  const fetchDealers = async () => {
    const { data } = await fetchAllDealers();
    if (data?.status) {
      setDealerList(data?.data);
    }
  };

  useEffect(()=>{
    if(agentDetails?.anchors?.length && dealersList?.length && solved){
      setSelectedDealers(getSelectedDelears( dealersList , agentDetails?.anchors));
      setSolved(false);
    }
  },[agentDetails,dealersList])

  useEffect(()=>{
    setAgentDetails(initAgentDetails);
    setSelectedDealers([]);
    setSolved(true);
  },[]);

  return {
    getAgentDetails,
    buildPayload,
    isValid,
    selectedDealers,
    dealersList,
    setSelectedDealers,
    fetchDealers
  };
}

export default useAgent;
