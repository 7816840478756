import { forwardRef, useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
// import CustomerCheckBox from "../../../../components/V3/CustomerCheckBox";
// import { documentStatus } from "../../../../api/agent";
import { useNavigate } from "react-router-dom";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { documentStatus, removeMandatoryDoc } from "../../api";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { UserContext } from "../../Context/UserContext";
import { toast } from "react-toastify";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(77, 25, 210, 1)" || theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 24,
    minWidth: 350,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    maxWidth: 800,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const initialDeleteState = {
  open: false,
  file: null,
};

export default function StatusDialog({
  open,
  handleClose,
  application_id,
  docStatusList,
  coExist,
  openFile,
}) {
  const [docStatus, setDocStatus] = useState({});
  const [active, setActive] = useState("pending");
  const [loading, setLoading] = useState(false);
  const [gloading, setGLoading] = useState(null);
  const [rows, setRows] = useState([]);
  const { user } = useContext(UserContext);

  const [deleteData, setDeleteData] = useState(initialDeleteState);

  function capitalizeFirstLetter(string) {
    if (string?.length > 0) {
      return (string?.charAt(0)?.toUpperCase() + string?.slice(1))?.replaceAll(
        "_",
        " "
      );
    }
  }

  function handleRows(currState) {
    if (active === "pending" || currState === "pending") {
      let rejArr = !docStatus?.rejected
        ? []
        : docStatus?.rejected?.map((item) =>
            createData(
              item?.name,
              item?.rejectionReason,
              item?.rejectedBy,
              item?.rejectionDate
            )
          );
      let pendArr = !docStatus?.pending
        ? []
        : docStatus?.pending?.map((item) =>
            createData(item, "PENDING", "", "", false)
          );
      setRows([...rejArr, ...pendArr]);
    } else {
      let isUploaded = new Set(docStatus?.pending);
      let list = [];
      docStatus?.approvalPending?.map((item) => {
        if (isUploaded.has(item)) {
          list.push(item);
        } else {
          list.unshift(item);
        }
      });
      let arr = list?.map((item) => {
        return createData(
          item,
          "APPROVAL PENDING",
          "",
          "",
          !isUploaded.has(item)
        );
      });
      setRows([...arr]);
    }
  }

  const handleGetList = async () => {
    try {
      setLoading(true);
      let { data } = await documentStatus(application_id);
      if (data?.status) {
        setDocStatus({
          pending: data?.Pending?.filter(
            (item) =>
              !data?.RejectedWithDetails?.some((itm) => itm?.name === item)
          ),
          rejected: data?.RejectedWithDetails,
          approvalPending: data?.ApprovalPending?.filter(
            (item) => !data?.Pending?.some((itm) => itm === item)
          ),
        });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleDeleteFromList = async () => {
    try {
      setGLoading("DELETE");
      let { data } = await removeMandatoryDoc(
        `${application_id}?name=${deleteData?.file}`
      );
      toast.success("File removed successfully from the list");
      handleGetList();
      setDeleteData(initialDeleteState);
    } catch (error) {
      toast.error(
        error?.reason?.data?.message || error?.message || "Operation Failed"
      );
    }
    setGLoading(null);
  };

  useEffect(() => {
    if (docStatus) {
      handleRows("pending");
    }
  }, [docStatus]);

  useEffect(() => {
    if (open) {
      setActive("pending");
      handleGetList();
    }
  }, [open]);

  // useEffect(() => {
  //   handleGetList();
  // }, [open, docStatusList]);

  const navigate = useNavigate();
  useEffect(() => {
    window.onpopstate = () => {
      handleClose();
    };
  });

  const handleNavigate = (tab) => {
    handleClose();
    navigate(
      `/create-loan/documents-upload/${application_id}/${
        tab > 3 && !coExist ? tab - 1 : tab
      }`
    );
  };

  function createData(doc, reason, rejected_by, date, isUploaded) {
    return { doc, reason, rejected_by, date, isUploaded };
  }

  useEffect(() => {
    handleRows();
  }, [active]);

  // const rows = [
  //   createData("Frozen yoghurt", 159, 6.0),
  //   createData("Ice cream sandwich", 237, 9.0),
  //   createData("Eclair", 262, 16.0),
  //   createData("Cupcake", 305, 3.7),
  //   createData("Gingerbread", 356, 16.0),
  // ];

  return (
    <>
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <div
            style={{
              background: "white",
              height: "80vh",
              width: "100%",
              position: "fixed",
              bottom: 0,
            }}
          >
            <div
              style={{
                clipPath: ` polygon(73% 0, 100% 0%, 100% 100%, 4% 100%)`,
                background: "rgba(73, 72, 94, 0.5)",
                width: "100%",
                height: "86px",
                position: "absolute",
              }}
            />
            <div
              style={{
                clipPath: `polygon(0 0, 44% 0, 62% 100%, 0 100%)`,
                background: "rgba(73, 72, 94, 0.3)",
                width: "100%",
                height: "86px",
                position: "absolute",
              }}
            />
            <div
              className="content-center pt-3"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px 30px",
                margin: 0,
                backgroundColor: "#11101D",
                color: "#fff",
              }}
            >
              <Box
                sx={{
                  width: 900,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Button
                  variant="text"
                  color="inherit"
                  sx={{
                    border: active === "pending" ? "1px solid #fff" : "none",
                    backgroundColor: "transparent",
                    marginRight: 5,
                  }}
                  onClick={() => {
                    setActive("pending");
                  }}
                >
                  Pending / Rejected Documents -{" "}
                  {docStatus?.pending?.length + docStatus?.rejected?.length}
                </Button>
                <Button
                  variant="text"
                  color="inherit"
                  sx={{
                    border: active === "approval" ? "1px solid #fff" : "none",
                    backgroundColor: "transparent",
                  }}
                  onClick={() => {
                    setActive("approval");
                  }}
                >
                  Pending Approval- {docStatus?.approvalPending?.length}
                </Button>
                {/* <ToggleButton
                value="rejected"
                aria-label="right aligned"
                className="toggle-btn"
              >
                Rejected Documents - {docStatus?.rejected?.length}
              </ToggleButton> */}
              </Box>

              <div
                style={{
                  background: "#fff",
                  alignSelf: "center",
                  display: "grid",
                  borderRadius: "2px",
                  zIndex: 1,
                }}
                className="content-center"
              >
                <CloseIcon
                  sx={{ fontSize: "30px", color: "#000", cursor: "pointer" }}
                  onClick={handleClose}
                />
              </div>
            </div>
            {/* <div style={{
            width:'100%',
            height:'20px',
            background:'red'
          }}>
          </div> */}
            <div
              style={
                {
                  // padding:10
                }
              }
            >
              <TableContainer
                sx={{
                  maxHeight: "70vh",
                  paddingBottom:'50px'
                }}
              >
                <Table
                  stickyHeader
                  sx={{ width: "100%" }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      {Number(user?.role) === 1 && (
                        <TableCell
                          sx={{ background: "rgba(77, 25, 210, 1)" }}
                        ></TableCell>
                      )}
                      <StyledTableCell
                        align="left"
                        style={{
                          paddingLeft: Number(user?.role) === 1 ? 20 : 100,
                          width: 100,
                        }}
                      >
                        Document Name
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{
                          paddingLeft: 100,
                        }}
                      >
                        Reason
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{
                          width: 80,
                        }}
                      >
                        {active !== "pending" ? "" : "Rejected By"}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <TableBody style={{ width: "100%" }}>
                      {rows?.map((item, i) => (
                        <StyledTableRow key={i}>
                          {[1,5].includes(Number(user?.role)) && (
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {" "}
                              <Skeleton
                                height={50}
                                width={"100%"}
                                animation="pulse"
                              />
                            </StyledTableCell>
                          )}

                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {" "}
                            <Skeleton
                              height={50}
                              width={"100%"}
                              animation="pulse"
                            />
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Skeleton
                              height={50}
                              width={"100%"}
                              animation="pulse"
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Skeleton
                              height={50}
                              width={"100%"}
                              animation="pulse"
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      {rows.map((row, i) => {
                        // if (loading) return ;
                        return (
                          <StyledTableRow key={row?.doc}>
                            {[1,5].includes(Number(user?.role)) && (
                              <TableCell
                                width={20}
                                style={{
                                  paddingLeft: 50,
                                }}
                              >
                                <IconButton
                                  sx={{ color: "red" }}
                                  onClick={() => {
                                    setDeleteData({
                                      open: true,
                                      file: row?.doc,
                                    });
                                  }}
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </IconButton>
                              </TableCell>
                            )}

                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                              style={{
                                paddingLeft:
                                  Number(user?.role) === 1 ? 20 : 100,
                              }}
                            >
                              {/* {rows.doc} */}
                              <p
                                style={{
                                  marginBottom: 0,
                                }}
                              >
                                {row?.reason !== "PENDING" &&
                                  row?.reason !== "APPROVAL PENDING" && (
                                    <>
                                      <CloseIcon
                                        sx={{
                                          background: "rgba(255, 34, 34, 1)",
                                          borderRadius: "50%",
                                          color: "#fff",
                                          fontSize: 22,
                                        }}
                                      />
                                      &nbsp;&nbsp;
                                    </>
                                  )}
                                {row?.isUploaded && (
                                  <>
                                    <CheckIcon
                                      sx={{
                                        background: "rgba(174, 26, 199, 1)",
                                        borderRadius: "50%",
                                        color: "#fff",
                                        fontSize: 22,
                                      }}
                                    />
                                    &nbsp;&nbsp;
                                  </>
                                )}
                                {row?.doc
                                  ?.split("_")
                                  ?.map((item) => capitalizeFirstLetter(item))
                                  ?.join(" ")}
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{
                                paddingLeft: 100,
                              }}
                            >
                              {/* {row.reason} */}
                              <p
                                style={{
                                  // background: "red",
                                  marginBottom: 0,
                                  wordWrap: "break-word",
                                  color:
                                    row?.reason !== "PENDING" &&
                                    row?.reason !== "APPROVAL PENDING"
                                      ? "rgba(255, 34, 34, 1)"
                                      : "#000",
                                }}
                              >
                                {row?.reason}
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{
                                width: 80,
                              }}
                            >
                              {/* {row.rejected_by} */}
                              <p
                                style={{
                                  fontWeight: 500,
                                  marginBottom: 0,
                                  // background: "red",
                                }}
                              >
                                {row?.rejected_by}
                              </p>
                              <p>
                                {row?.date
                                  ? moment(row?.date)?.format(
                                      "DD/MM/yyyy hh:mm:ss A"
                                    )
                                  : ""}
                              </p>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </div>
        </Dialog>
      </div>

      <Dialog
        open={deleteData?.open}
        onClose={() => setDeleteData(initialDeleteState)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <p id="alert-dialog-description">
            Are you sure you want to delete{" "}
            <b>
              {deleteData?.file
                ?.split("_")
                ?.map((item) => capitalizeFirstLetter(item))
                ?.join(" ")}
            </b>{" "}
            ?
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteData(initialDeleteState)}
            color="inherit"
            disabled={gloading === "DELETE"}
          >
            No
          </Button>
          <Button
            onClick={handleDeleteFromList}
            variant="contained"
            color="error"
            disabled={gloading === "DELETE"}
          >
            {gloading === "DELETE" ? (
              <>
                <CircularProgress size={15} className="me-1" /> Deleting...
              </>
            ) : (
              "Yes"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
