import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { isBlank } from "../../utility/validations";
import { toast } from "react-toastify";
import { addContactInfo, updateContactInfo } from "../../api";
import useApplication from "../../hooks/useApplication";

function AddContact({ editData, Relations, handleClose, application_id }) {
  const [updateData, setUpdateData] = useState({});
  const [errors, setErrors] = useState({});
  const { getApplicationDetails } = useApplication();
  const [isLoading, setLoading] = useState(false);

  const handleChange = (key, inputType) => (event, checked) => {
    if (key === "number") {
      if (isNaN(Number(event.target.value)) || event.target.value.length > 10) {
        return false;
      }
    }

    if (inputType === "checkbox") {
      setUpdateData({
        ...updateData,
        [key]: checked,
      });
      setErrors({});
      return;
    }

    setUpdateData({
      ...updateData,
      [key]: event.target.value,
    });
    setErrors({});
  };

  const handleUpdateContact = async () => {
    setLoading(true);
    try {
      let errs = {
        name: isBlank(updateData?.name || ""),
        number:
          !isNaN(Number(updateData?.number)) &&
          (updateData?.number + "").length === 10
            ? ""
            : "Invalid mobile number",
        relation: isBlank(updateData?.relation || ""),
      };

      let valid = true;
      for (let i in errs) {
        if (errs[i].length > 0) {
          valid = false;
        }
      }

      setErrors(errs);
      if (!valid) {
        return false;
      }

      let payload = {};
      if (editData?.isEdit) {
        payload = {
          ...payload,
          ...updateData,
          old_number: Number(updateData?.old_number),
        };
        delete payload.isNameDisabled;
      } else {
        payload = {
          ...payload,
          ...updateData,
        };
      }
      delete payload.isEdit;
      delete payload.open;
      payload.number = Number(payload.number);

      let apiFnc = editData?.isEdit ? updateContactInfo : addContactInfo;

      let { data } = await apiFnc({
        application_id,
        contact_details: payload,
      });
      await getApplicationDetails(application_id);
      handleClose();
      toast.success(data?.message || "Operation successfull");
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Something wrong"
      );
    }finally{
        setLoading(false);
    }
  };

  useEffect(() => {
    setUpdateData(editData);
  }, [editData]);

  useEffect(() => {
    if (editData?.open) {
    }
    setErrors({});
  }, [editData?.open]);

  return (
    <Dialog
      open={editData?.open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {isLoading ? "Loading" : editData?.isEdit ? "Edit" : "Add"} Contact
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs={12}>
            <TextField
              variant="standard"
              fullWidth
              label="Name"
              value={updateData?.name || ""}
              error={errors?.name}
              helperText={errors?.name}
              onChange={handleChange("name")}
              disabled={editData?.isEdit ? editData?.isNameDisabled : false}
            />
          </Grid>
          <Grid xs={12} className="mt-3">
            <TextField
              variant="standard"
              fullWidth
              label="Number"
              error={errors?.number}
              helperText={errors?.number}
              value={updateData?.number || ""}
              onChange={handleChange("number")}
            />
          </Grid>
          <Grid xs={12} className="mt-3">
            <FormControl
              variant="standard"
              fullWidth
              error={errors?.relation}
              disabled={editData?.isEdit ? editData?.isNameDisabled : false}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Relation
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={updateData?.relation || ""}
                onChange={handleChange("relation")}
                label="Relation"
              >
                {( !(["Customer","Co-customer","Guarantor"].includes(editData?.relation))
                  ? Relations.slice(3)
                  : Relations
                )?.map((reln) => (
                  <MenuItem value={reln}>{reln}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors?.relation}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              // sx={inputStyles}
              control={
                <Checkbox
                  error={errors?.call_first}
                  helperText={errors?.call_first}
                  checked={updateData?.call_first || false}
                  onChange={handleChange("call_first", "checkbox")}
                />
              }
              label="Mark Call First"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          Close
        </Button>
        <Button onClick={handleUpdateContact} disabled={isLoading}>
          {isLoading ? "Loading" : editData?.isEdit ? "Edit" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddContact;
