import React from "react";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect } from "react";
import moment from "moment/moment";

const InstallationPie = ({ mufin, mmi, title }) => {
  const data = {
    labels: ["Mufin", "MMI"],
    datasets: [
      {
        data: [mufin, mmi],
        backgroundColor: (ctx) => {
          if (mufin === 0 && mmi === 0) {
            return ["rgba(169, 169, 169, 0.6)"];
          } else {
            const colors = ["#14B8A6", "#EC4899"]; // Array of colors
            const data = ctx.chart.data.datasets[0].data;
            return data.map((value, index) =>
              value === 0
                ? "rgba(169, 169, 169, 0.6)"
                : colors[index % colors.length]
            );
          }
        },
        borderWidth: 0, // Set border width to 0 to hide borders
        hoverOffset: 4, // Increase hover offset to add space between segments
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.formattedValue}`;
          },
        },
      },
      datalabels: {
        color: "#ffffff",
        formatter: function (value, context) {
          return value ? value : "";
        },
      },

      doughnutBackground: {
        enabled: true,
        color: "#E4E6E6",
      },
    },
  };

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();

        // Define font size for text 1
        var fontSizeText1 = (height / 200).toFixed(2);
        ctx.font = fontSizeText1 + 10 + "em sans-serif";
        ctx.textBaseline = "top";

        var text = title;
        var textWidth = ctx.measureText(text).width;
        var textX = Math.round((width - textWidth) / 2);
        var textY = height / 2.3;

        // Define font size for text 2
        var fontSizeText2 = (height / 300).toFixed(2);
        ctx.font = fontSizeText2 + "em sans-serif";

        var text2 = `${mmi + mufin}`;
        var text2Width = ctx.measureText(text2).width;
        var text2X = Math.round((width - text2Width) / 2);

        // Draw text 1
        ctx.fillText(text, textX + 10, textY);

        // Draw text 2
        ctx.fillText(text2, text2X, textY + 20); // Adjust Y coordinate to position the second text below the first one

        ctx.save();

        if (mufin === 0 && mmi === 0) {
          const { chartArea, ctx } = chart;
          const { bottom, left, right, top } = chartArea;
          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 2;
          const r = Math.min(right - left, bottom - top) / 2.5;
          ctx.lineWidth = 20;
          ctx.strokeStyle = "#cccccc";
          ctx.arc(centerX, centerY, r, 0, 2 * Math.PI);
          ctx.stroke();
        }
      },
    },
  ];

  return (
    <div className="gps-donut-holder">
      <div style={{ width: "60%", marginLeft: 20 }}>
        <Doughnut
          data={data}
          options={options}
          plugins={[ChartDataLabels, ...plugins]}
        />
      </div>
      <div className="gps-donut-label">
        <div>
          <div
            className="d-flex"
            style={{
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div className="mufin-span">&nbsp;</div> <div>Mufin:</div>{" "}
            <div>{mufin}</div>
          </div>

          <div
            className="d-flex"
            style={{
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div className="mmi-span">&nbsp;</div> <div>MMI:</div>{" "}
            <div>{mmi}</div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            color: "#0000006B",
          }}
        >
          {moment().format("DD MMMM YYYY")}
        </div>
      </div>
    </div>
  );
};

export default InstallationPie;
