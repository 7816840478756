import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useContext } from "react";
import UploadSection from "../../../Components/Anchor/UploadSection";
import { GlobalContext } from "../../../Context/GlobalContext";
import { useEffect } from "react";
import { fetchAllOems } from "../../../api";
import { useState } from "react";
import useAnchor from "../../../hooks/useAnchor";
import AutoFillBankNames from "../../../Components/AutoFillBankNames.js";
import { disable } from "mixpanel-browser";
import { Box } from "@material-ui/core";
import { NotAvailable } from "../../../Components/StatusChip.js";

const BankDetails = ({ isDisabled = false, isDownloadAble = false, pennyDropLogs }) => {
  const { dealerDetailState } = useContext(GlobalContext);
  const [dealerDetails, setDealerDetails] = dealerDetailState;
  const { handleSingleDocReupload, handleSingleDocDelete, getDocArr } =
    useAnchor();
  const [swipeDocs, setSwipeDocs] = useState(null);

  const handleChange = (key) => (event) => {
    setDealerDetails({
      ...dealerDetails,
      [key]: event.target.value,
      bank_details_changed: true,
      penny_drop_data: {},
    });
  };

  const handleArrayDetailChange = (array_name, i, key, value) => {
    let array = [...dealerDetails?.[array_name]];
    array[i] = {
      ...array[i],
      [key]: value,
    };
    setDealerDetails({
      ...dealerDetails,
      [array_name]: array,
    });
  };

  const getDocumentsArray = () => getDocArr(1);

  function getDocNumber(docs, isAadhaar) {
    if (docs?.length === 0) return 1;
    let index = -1;
    let number = 0;
    if (isAadhaar) {
      index =
        docs?.[docs?.length - 1]?.[`aadhaar_front`]?.name?.lastIndexOf("_");
      number = docs?.[docs?.length - 1]?.[`aadhaar_front`]?.name?.slice(
        index + 1
      );
    } else {
      index = docs?.[docs?.length - 1]?.name?.lastIndexOf("_");
      number = docs?.[docs?.length - 1]?.name?.slice(index + 1);
    }
    return Number(number) + 1;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            //   required
            id="account_number"
            name="account_number"
            label="Dealer Account number"
            autoComplete="family-name"
            variant="standard"
            fullWidth
            value={dealerDetails?.account_number || ""}
            onChange={handleChange("account_number")}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            //   required
            id="ifsc"
            name="ifsc"
            label="IFSC Code"
            autoComplete="family-name"
            variant="standard"
            fullWidth
            value={dealerDetails?.ifsc_code || ""}
            onChange={handleChange("ifsc_code")}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          {/* <TextField
            //   required
            id="bank_name"
            name="bank_name"
            label="Bank Name"
            autoComplete="family-name"
            variant="standard"
            fullWidth
            value={dealerDetails?.bank_name || ""}
            onChange={handleChange("bank_name")}
            disabled={isDisabled}
          /> */}
          <AutoFillBankNames
            label={"Bank Name"}
            value={dealerDetails?.bank_name}
            onChange={(value) => {
              setDealerDetails({
                ...dealerDetails,
                bank_name: value,
                bank_details_changed: true,
                penny_drop_data: {},
              });
            }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            //   required
            id="beneficiary_name"
            name="beneficiary_name"
            label="Beneficiary Name"
            autoComplete="family-name"
            variant="standard"
            margin="dense"
            fullWidth
            value={dealerDetails?.beneficiary_name || ""}
            onChange={handleChange("beneficiary_name")}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <FormControl
            variant="standard"
            sx={{ width: "100%" }}
            disabled={isDisabled}
          >
            <InputLabel required id="demo-simple-select-standard-label">
              Account Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label-bank"
              id="demo-simple-select-standard-bank"
              value={dealerDetails?.account_type || ""}
              onChange={handleChange("account_type")}
            >
              <MenuItem value={"savings"}>Savings</MenuItem>
              <MenuItem value={"current"}>Current</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          {dealerDetails?.penny_drop_status && (
            <TextField
              //   required
              id="beneficiary_name_penny_drop"
              name="beneficiary_name_penny_drop"
              label="Beneficiary name (Penny Drop)"
              autoComplete="family-name"
              variant="standard"
              margin="dense"
              fullWidth
              value={
                dealerDetails?.penny_drop_data?.beneficiary_name_with_bank ||
                dealerDetails?.penny_drop_data?.accountName ||
                "N/A"
              }
              onChange={handleChange("beneficiary_name")}
              disabled={true}
            />
          )}
        </Grid>
      </Grid>
      <UploadSection
        mt={20}
        docs={dealerDetails?.security_cheques || []}
        heading={"Three Security Cheques "}
        category={"security_cheques"}
        onChange={(file) => {
          setDealerDetails({
            ...dealerDetails,
            security_cheques: [
              ...(dealerDetails?.security_cheques || []),
              {
                isUploaded: true,
                fileLink: file,
                name: `anchor_security_cheques_${getDocNumber(
                  dealerDetails?.security_cheques || []
                )}`,
                category: "security_cheques",
              },
            ],
          });
        }}
        onDelete={(file, i) => {
          setDealerDetails({
            ...dealerDetails,
            security_cheques: [...dealerDetails?.security_cheques].filter(
              (item, index) => index !== i
            ),
          });
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        swipeArr={swipeDocs}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <UploadSection
        mt={20}
        heading={"Last 6 Months Bank Statement  "}
        category={"bank_statement"}
        docs={dealerDetails?.bank_statements || []}
        onChange={(file) => {
          setDealerDetails({
            ...dealerDetails,
            bank_statements: [
              ...(dealerDetails?.bank_statements || []),
              {
                isUploaded: true,
                fileLink: file,
                name: `anchor_bank_statement_${getDocNumber(
                  dealerDetails?.bank_statements || []
                )}`,
                category: "bank_statement",
              },
            ],
          });
        }}
        onDelete={(file, i) => {
          setDealerDetails({
            ...dealerDetails,
            bank_statements: [...dealerDetails?.bank_statements].filter(
              (item, index) => index !== i
            ),
          });
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        swipeArr={swipeDocs}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <Grid container spacing={2} className="mb-4 mt-2">
        <Grid item xs={12}>
          <div className="overview-card">
            <p className="card-heading">Penny Drop Failure Logs</p>
            <div className="card-content">
              {(Array.isArray(pennyDropLogs) && pennyDropLogs?.length > 0) ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className="pl-container">
                      <div className="pl-box">
                        <p className="pl-label">Provider : </p>
                        <p className="pl-label-2">Digio</p>
                      </div>
                      <div className="pl-box">
                        <p className="pl-label">Message : </p>
                        <p className="pl-label-2">
                          {pennyDropLogs?.[0]?.error_msg || pennyDropLogs?.[0]?.message}
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="pl-container">
                      <div className="pl-box">
                        <p className="pl-label">Provider : </p>
                        <p className="pl-label-2">Karza</p>
                      </div>
                      <div className="pl-box">
                        <p className="pl-label">Message : </p>
                        <p className="pl-label-2">
                          {pennyDropLogs?.[1]?.bankResponse}
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ): (
                <NotAvailable />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default BankDetails;
