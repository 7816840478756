import React, { useState, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { GlobalContext } from "../../Context/GlobalContext";
import {
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomTableV2 from "../CustomTable/CustomTableV2";
import { f } from "../../utility/Formater";

function ReturnFilingStatusPopup({ isOpen, onClose }) {
  const { dealerDetailState, updateDocsState } = useContext(GlobalContext);
  const [dealerDetails, setDealerDetails] = dealerDetailState;
  
  let columns_return_filing_status = [
    {
      label: "Financial Year",
      render: (index) => {
        return f(dealerDetails?.gst_details?.returnFilingFrequency?.[index]?.financialYear);
      },
      width: 200,
    },
    {
      label: "GST Type",
      render: (index) => {
        return f(dealerDetails?.gst_details?.returnFilingFrequency?.[index]?.returnFilingType);
      },
      width: 200,
    },
    {
      label: "Frequency",
      render: (index) => {
        return f(dealerDetails?.gst_details?.returnFilingFrequency?.[index]?.frequency);
      },
      width: 200,
    },
    {
      label: "Quarter",
      render: (index) => {
        return f(dealerDetails?.gst_details?.returnFilingFrequency?.[index]?.quarter);
      },
      width: 200,
    },
  ];
  
  return (
    <Dialog open={isOpen} onClose={onClose} sx={{width: "100%", px: 0 , pb:1}}>
      <DialogTitle>Dealer GSTIN - {dealerDetails?.gst_details?.gstin}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "gray",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
      <CustomTableV2
            tableData={dealerDetails?.gst_details?.returnFilingFrequency}
            tableHeight={500}
            columns={columns_return_filing_status}
            limit={0}
            page={1}
            serialNo={false}
          />
      </DialogContent>
    </Dialog>
  );
}

export default ReturnFilingStatusPopup;
