import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import whatsappBg from "../../assets/whatsappBg.jpg";
import moment from "moment/moment";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import BlockIcon from "@mui/icons-material/Block";
import { toast } from "react-toastify";
import { getAllWhatsappMessages, sentWhatsappReply } from "../../api";
import { useEffect } from "react";
import { CircularProgress, TextField } from "@mui/material";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import noDataIco from "../../assets/nodata.gif";
import ReplayIcon from "@mui/icons-material/Replay";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CallMadeIcon from "@mui/icons-material/CallMade";

import { getTwilioLogsData } from "../../api";
import TwilioAudioPlayer from "../AudioPlayer";
import SendIcon from "@mui/icons-material/Send";

const CommunicationHistory = ({
  modal,
  handleClose,
  chatData,
  callData,
  tab,
  changeTab,
}) => {
  const dialogRef = React.useRef(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSent, setLoadingSent] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [msgTxt, setMsgTxt] = useState("");
  const [msgActive, setMsgActive] = useState(false);

  const isDateChanged = (prevDate, currentDate) => {
    return !moment(prevDate).isSame(currentDate, "day");
  };

  // Initialize previous date to compare with the current message's date
  let prevDate = null;

  const handleGetMessages = async (mobile_number) => {
    try {
      setLoading(true);
      let { data } = await getAllWhatsappMessages(mobile_number);
      setCustomerName(data?.customer_name);
      setMessages(data?.data || []);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Error loading messages"
      );
    }
    setLoading(false);
  };

  const [recordingData, setRecordingData] = useState([]);
  const handleRecordingModal = async (application_id) => {
    setLoading(true);
    try {
      if (application_id) {
        const { data } = await getTwilioLogsData(application_id);
        setRecordingData(data?.data || []);
        setCustomerName(data?.customer_name);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Error loading Call logs"
      );
    }
    setLoading(false);
  };

  const handleSendMessage = async () => {
    setLoadingSent(true);
    try {
      const { data } = await sentWhatsappReply({
        mobile: chatData?.mobile_number + "",
        text: msgTxt,
        application_id: callData.application_id,
      });
      setMessages([
        ...messages,
        {
          doc_type: "sent",
          message_date: new Date(),
          type: "text",
          message: msgTxt,
          attachment: null,
          buttons: [],
          placeholders: {
            reply: msgTxt,
          },
          user: "Embifi",
          status: null,
        },
      ]);
      setMsgTxt("");
      dialogRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Error sending message"
      );
    }
    setLoadingSent(false);
  };

  useEffect(() => {
    setCustomerName(chatData?.customer_name || "");
    if (tab == 1) {
      handleGetMessages(chatData?.mobile_number);
    } else {
      handleRecordingModal(callData.application_id);
    }
  }, [tab, callData.application_id]);

  function findLastReceiveDocument(data) {
    for (let i = data.length - 1; i >= 0; i--) {
      const document = data[i];
      if (document.doc_type === "receive") {
        return document;
      }
    }

    return null;
  }

  useEffect(() => {
    if (dialogRef.current) {
      dialogRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    const lastReceiveDocument = findLastReceiveDocument(messages);
    if (lastReceiveDocument?.message_date) {
      const messageDate = moment(lastReceiveDocument?.message_date);
      const currentDateTime = moment();
      setMsgActive(currentDateTime.diff(messageDate, "hours") < 24);
    }
  }, [messages]);

  return (
    <div>
      <Dialog
        open={modal}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle>
          {`${callData.application_id} - ${customerName}`}
        </DialogTitle>
        {tab === 1 ? (
          <DialogContent
            dividers
            style={{
              backgroundImage: `url(${whatsappBg})`, // Set background image using template literals
              backgroundSize: "cover",
            }}
          >
            {" "}
            <div style={{ height: "100%", width: "100%" }}>
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <CircularProgress />{" "}
                </div>
              )}
              {!loading && messages?.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                >
                  <div className="chat-empty-div">
                    <SpeakerNotesOffIcon className="me-2" /> No messages found
                    on this number
                  </div>
                </div>
              )}
              {!loading &&
                messages?.length !== 0 &&
                messages?.map((msg, index) => {
                  const dateChanged =
                    prevDate && isDateChanged(prevDate, msg.message_date);

                  // Update previous date with the current message's date
                  prevDate = msg.message_date;

                  return (
                    <>
                      {dateChanged && (
                        <div
                          className="chat-date-divider"
                          key={`divider-${index}`}
                        >
                          <span>
                            {moment(msg.message_date).format("DD/MM/YYYY")}
                          </span>
                        </div>
                      )}
                      {(msg?.type === "text" || msg?.type === "button") && (
                        <div
                          className={`chat-bubble-holder chat-${msg?.doc_type}`}
                        >
                          <div
                            className={`chat-bubble chat-bubble-${msg?.doc_type}`}
                          >
                            {msg?.context && (
                              <div className="context-holder">
                                <div className="context-header">Embifi</div>
                                {msg?.contextData}
                              </div>
                            )}
                            <div className="p-3">{msg?.message}</div>
                            <div className="chat-meta mb-1">
                              <div>
                                {moment(msg?.message_date).format(
                                  "DD/MM/YYYY h:mm a"
                                )}
                                {msg?.doc_type === "sent" && (
                                  <span className="ms-1">
                                    {msg?.status === "READ" ? (
                                      <DoneAllIcon
                                        sx={{ fontSize: 15, color: "blue" }}
                                      />
                                    ) : msg?.status === "DELIVERED" ? (
                                      <DoneAllIcon
                                        sx={{ fontSize: 15, color: "gray" }}
                                      />
                                    ) : msg?.status === "UNDELIVERED" ? (
                                      <BlockIcon
                                        sx={{ fontSize: 15, color: "red" }}
                                      />
                                    ) : (
                                      <DoneIcon
                                        sx={{ fontSize: 15, color: "gray" }}
                                      />
                                    )}
                                  </span>
                                )}
                              </div>
                            </div>
                            {msg?.buttons && (
                              <>
                                <div className="chat-btn-holder">
                                  {msg?.buttons?.map((btn) => {
                                    return (
                                      <div className="chat-btn">
                                        {btn?.title}
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {!["text", "button"]?.includes(msg?.type) && (
                        <div
                          className={`chat-bubble-holder chat-${msg?.doc_type}`}
                        >
                          <div
                            className={`chat-bubble chat-bubble-${msg?.doc_type}`}
                          >
                            {msg?.fileLink && msg?.type === "image" && (
                              <div
                                className="p-3 attach-doc-holder pointer"
                                style={{
                                  color: "gray",
                                  textDecoration: "italic",
                                  position: "relative",
                                }}
                              >
                                <embed
                                  className="pointer"
                                  src={msg?.fileLink}
                                ></embed>
                                {/* Overlay div */}
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    zIndex: 1,
                                  }}
                                  onClick={() => {
                                    window.open(`${msg?.fileLink}&file=true`);
                                  }}
                                ></div>
                              </div>
                            )}
                            {msg?.fileLink && msg?.type === "video" && (
                              <div
                                className="p-3 attach-doc-holder"
                                style={{
                                  color: "gray",
                                  textDecoration: "italic",
                                  backgroundColor: "transparent",
                                }}
                              >
                                <video
                                  src={msg?.fileLink + "&file=true"}
                                  controls={true}
                                ></video>
                              </div>
                            )}
                            {msg?.fileLink && msg?.type === "document" && (
                              <div
                                className="p-3 attach-doc-holder"
                                style={{
                                  color: "gray",
                                  textDecoration: "italic",
                                  backgroundColor: "transparent",
                                }}
                              >
                                <InsertDriveFileIcon /> Document({msg?.type}){" "}
                                <Button
                                  size="small"
                                  className="ms-1"
                                  onClick={() => {
                                    window.open(`${msg?.fileLink}&file=true`);
                                  }}
                                >
                                  Open
                                  <CallMadeIcon
                                    className="ms-1"
                                    style={{ fontSize: 13 }}
                                  />
                                </Button>
                              </div>
                            )}
                            {!msg?.fileLink && (
                              <div
                                className="p-3"
                                style={{
                                  color: "gray",
                                  textDecoration: "italic",
                                }}
                              >
                                <BlockIcon /> Unsupported type : {msg?.type}
                              </div>
                            )}
                            <div className="chat-meta mb-1">
                              <div>
                                {moment(msg?.message_date).format(
                                  "DD/MM/YYYY h:mm a"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              <div ref={dialogRef}></div>
            </div>
          </DialogContent>
        ) : (
          <DialogContent>
            {recordingData.length != 0 ? (
              <div
                style={{
                  border: "0.5px",
                  marginLeft: "5px",
                  marginRight: "5px",
                  display: "flex",
                  padding: "5px",
                  flexDirection: "column",
                  overflow: "scroll",
                }}
              >
                <div
                  style={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>Total Calls: {callData.total_count}</p>
                  <p>Customer Responded:{callData.picked_call_count}</p>
                  <p>
                    Not Responded:
                    {callData.declined_busy_count}
                  </p>
                </div>
                {recordingData.map((ele) => {
                  let text =
                    typeof ele?.transcript === "object"
                      ? `AWS : ${ele?.transcript?.aws}\nor\nGoogle: ${ele?.transcript?.google}`
                      : ele?.transcript;

                  return (
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "column",
                        justifyContent: "space-between",
                        margin: "10px",
                        backgroundColor: "#E4E6DD",
                        padding: "15px",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p>
                          {ele?.purpose || ele?.campaign || ele?.template_name}
                        </p>
                        <p>
                          {moment(parseInt(ele?.template_id)).format(
                            "DD-MM-YYYY HH:MM:SS"
                          )}
                        </p>
                      </div>
                      <TwilioAudioPlayer
                        recordingUrl={ele?.recording_url}
                        accountSid={"AC35851a626ed58b00a28e9e7aca33c0ac"}
                        authToken={"6ad8d3ab6d37ef54f9056a1013c4ad44"}
                      />
                      <TextField
                        value={text || "Empty Transcript"}
                        id="outlined-multiline-static"
                        label="Multiline"
                        multiline
                        rows={4}
                        style={{
                          width: "100%",
                          marginTop: "10px",
                          // marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "60vh" }}
              >
                <div>
                  <img src={noDataIco} style={{ width: "300px" }} />
                  <p className="w-100 text-center">Nothing to display</p>
                </div>
              </div>
            )}
          </DialogContent>
        )}
        <DialogActions style={{ justifyContent: "space-between" }}>
          <div
            style={
              msgActive
                ? {
                    width: "100%",
                    display: "flex",
                    border: "1px solid gray",
                    borderRadius: 10,
                    padding: 5,
                    paddingLeft: 10,
                  }
                : {}
            }
          >
            {msgActive && (
              <>
                <TextField
                  variant="standard"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={msgTxt}
                  onChange={(e) => {
                    setMsgTxt(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSendMessage();
                    }
                  }}
                />
                <Button onClick={handleSendMessage} disabled={loadingSent}>
                  <SendIcon />
                </Button>
              </>
            )}
          </div>
          <div className={"d-flex"} style={{ gap: 8 }}>
            <Button
              variant={tab === 1 ? "contained" : "outlined"}
              onClick={changeTab}
              color={"primary"}
            >
              chat
            </Button>
            <Button
              variant={tab === 2 ? "contained" : "outlined"}
              onClick={changeTab}
              color={"primary"}
            >
              calls
            </Button>
            {tab === 1 && (
              <Button
                onClick={() => {
                  handleGetMessages(chatData?.mobile_number);
                }}
              >
                <ReplayIcon sx={{ fontSize: "18px", marginRight: "5px" }} />{" "}
                Reload
              </Button>
            )}

            <Button onClick={handleClose}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CommunicationHistory;
