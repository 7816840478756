import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { getHoldBackDashData } from '../../api';
import { NativeSelect, FormControl, Pagination } from '@mui/material';



// const data = [
//     {
//         "anchor_id": "HOL2102242200000056",
//         "hold_back_type": "OEM",
//         "dealership_name": "hold back Vehicles 2",
//         "nbfc_name": "FINTREE FINANCE PRIVATE LIMITED",
//         "max_hold_back": 120000,
//         "collected_hold_back": 0,
//         "upfront_deducted_assigned_hold_back": 20000,
//         "upfront_collected_assigned_hold_back": 40000,
//         "settled_hold_back": 100000,
//         "settlement_target": 10000
//     }, {
//         "anchor_id": "HOL2102242200000056",
//         "hold_back_type": "OEM",
//         "dealership_name": "hold back Vehicles 2",
//         "nbfc_name": "FINTREE FINANCE PRIVATE LIMITED",
//         "max_hold_back": 120000,
//         "collected_hold_back": 0,
//         "upfront_deducted_assigned_hold_back": 20000,
//         "upfront_collected_assigned_hold_back": 40000,
//         "settled_hold_back": 100000,
//         "settlement_target": 10000
//     }, {
//         "anchor_id": "HOL2102242200000056",
//         "hold_back_type": "OEM",
//         "dealership_name": "hold back Vehicles 2",
//         "nbfc_name": "FINTREE FINANCE PRIVATE LIMITED",
//         "max_hold_back": 120000,
//         "collected_hold_back": 0,
//         "upfront_deducted_assigned_hold_back": 20000,
//         "upfront_collected_assigned_hold_back": 40000,
//         "settled_hold_back": 100000,
//         "settlement_target": 10000
//     }, {
//         "anchor_id": "HOL2102242200000056",
//         "hold_back_type": "OEM",
//         "dealership_name": "hold back Vehicles 2",
//         "nbfc_name": "FINTREE FINANCE PRIVATE LIMITED",
//         "max_hold_back": 120000,
//         "collected_hold_back": 0,
//         "upfront_deducted_assigned_hold_back": 20000,
//         "upfront_collected_assigned_hold_back": 40000,
//         "settled_hold_back": 100000,
//         "settlement_target": 10000
//     }, {
//         "anchor_id": "HOL2102242200000056",
//         "hold_back_type": "OEM",
//         "dealership_name": "hold back Vehicles 2",
//         "nbfc_name": "FINTREE FINANCE PRIVATE LIMITED",
//         "max_hold_back": 120000,
//         "collected_hold_back": 0,
//         "upfront_deducted_assigned_hold_back": 20000,
//         "upfront_collected_assigned_hold_back": 40000,
//         "settled_hold_back": 100000,
//         "settlement_target": 10000
//     }, {
//         "anchor_id": "HOL2102242200000056",
//         "hold_back_type": "OEM",
//         "dealership_name": "hold back Vehicles 2",
//         "nbfc_name": "FINTREE FINANCE PRIVATE LIMITED",
//         "max_hold_back": 120000,
//         "collected_hold_back": 0,
//         "upfront_deducted_assigned_hold_back": 20000,
//         "upfront_collected_assigned_hold_back": 40000,
//         "settled_hold_back": 100000,
//         "settlement_target": 10000
//     }, {
//         "anchor_id": "HOL2102242200000056",
//         "hold_back_type": "OEM",
//         "dealership_name": "hold back Vehicles 2",
//         "nbfc_name": "FINTREE FINANCE PRIVATE LIMITED",
//         "max_hold_back": 120000,
//         "collected_hold_back": 0,
//         "upfront_deducted_assigned_hold_back": 20000,
//         "upfront_collected_assigned_hold_back": 40000,
//         "settled_hold_back": 100000,
//         "settlement_target": 10000
//     }, {
//         "anchor_id": "HOL2102242200000056",
//         "hold_back_type": "OEM",
//         "dealership_name": "hold back Vehicles 2",
//         "nbfc_name": "FINTREE FINANCE PRIVATE LIMITED",
//         "max_hold_back": 120000,
//         "collected_hold_back": 0,
//         "upfront_deducted_assigned_hold_back": 20000,
//         "upfront_collected_assigned_hold_back": 40000,
//         "settled_hold_back": 100000,
//         "settlement_target": 10000
//     },
// ]

export default function HoldbackDashboard({ tableData, onPageChange, onLimitChange, total, defaultLimit, tablePage, tableLimit }) {
    // const [tableData, setTableData] = useState(null);
    const [limit, setLimit] = useState(defaultLimit);
    const [totalCount, setTotalCount] = useState(total)
    const [count, setCount] = useState(null);
    const [page, setPage] = useState(1);

    const TableRef = useRef(null);
    useEffect(() => {
        setCount(Math.ceil(totalCount / limit));
    }, [tableData]);

    useEffect(() => {
        if (tablePage && tableLimit) {
            setPage(tablePage);
            setLimit(tableLimit);
            console.log("custome table change ->", tablePage, tableLimit);
        }
    }, [tablePage, tableLimit]);


    return (
        <div >

            {tableData && (<div style={{ width: "100%" }}>
                <table style={styles?.table}>
                    <thead style={styles?.thead}>
                        <tr >
                            <th style={styles?.th_secondary}></th>
                            <th style={styles?.th_secondary}></th>
                            <th style={styles?.th_secondary}></th>
                            <th style={styles?.th_top_nbfcs}>Assign Hold Back</th>
                            <th style={styles?.th_top_nbfcs}>Settlement</th>
                            <th style={styles?.th_secondary}></th>
                        </tr>
                        <tr>
                            <th style={styles?.th_secondary}>Dealer Name</th>
                            <th style={styles?.th_secondary}>NBFC Name</th>
                            <th style={styles?.th_secondary}>Max Hold Back</th>
                            <th style={styles?.th_top_nbfcs}>
                                <th style={{
                                    width: "125px",
                                    textAlign: "center",
                                    fontSize: "14px",
                                    color: "#0000008c",
                                    borderRight: "1px solid",

                                }}>Upfront Deducted</th>
                                <th style={{
                                    width: "125px",
                                    textAlign: "center",
                                    fontSize: "14px",
                                    color: "#0000008c",
                                }}>Upfront Collected</th>
                            </th>
                            <th style={styles?.th_top_nbfcs}>
                                <th style={{
                                    width: "125px",
                                    textAlign: "center",
                                    fontSize: "14px",
                                    color: "#0000008c",
                                    borderRight: "1px solid",
                                }}>Target<br></br> Amount</th>
                                <th style={{
                                    width: "125px",
                                    textAlign: "center",
                                    fontSize: "14px",
                                    color: "#0000008c",
                                }}>Settled<br></br> Amount</th>
                            </th>
                            <th style={styles?.th_secondary}>Collected Hold Back Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map(ele => (
                            <tr >
                                <td align='center' style={{ maxWidth: "150px" }}>{ele.dealership_name}</td>
                                <td align='center' style={{ maxWidth: "200px" }}>{ele.nbfc_name}</td>
                                <td align='center' style={{ maxWidth: "100px" }}>{ele.max_hold_back != 0 ? ele.max_hold_back : ""}</td>
                                <td align="center" style={{
                                    width: "125px",

                                }}>
                                    <tr><td align='center' style={{ width: "15%", textAlign: "center" }}>{ele.upfront_deducted_assigned_hold_back != 0 ? Number(ele.upfront_deducted_assigned_hold_back) : ""}</td>
                                        <td align='center' style={{ width: "15%", textAlign: "center" }}>{ele.upfront_collected_assigned_hold_back != 0 ? Number(ele.upfront_collected_assigned_hold_back) : ""}</td>
                                    </tr>
                                </td>
                                <td align="center" style={{
                                    width: "125px",
                                }}>
                                    <tr><td align='center' style={{ width: "15%", textAlign: "center" }}>{ele.settlement_target != 0 ? ele.settlement_target : ""}</td>
                                        <td align='center' style={{ width: "15%", textAlign: "center" }}>{ele.settled_hold_back != 0 ? ele.settled_hold_back : ""}</td>
                                    </tr>
                                </td>
                                <td align='center' style={{ maxWidth: "100px" }}>{ele.collected_hold_back}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div >)}
            <div className="d-flex">
                <div className="w-100 d-flex justify-content-center pt-3">
                    <Pagination
                        count={count}
                        defaultPage={page}
                        page={page}
                        variant="outlined"
                        color="primary"
                        onChange={(e, page) => {
                            setPage(page);
                            onPageChange(page, limit);
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

const styles = {
    table: {
        borderCollapse: "collapse",
        border: "1px solid"
    },
    thead: {
        border: "1px solid",
        backgroundColor: "#D8E0FF"
    },

    th_top_months: {
        border: "1px solid",
        width: "125px",
    },
    th_top_nbfcs: {
        minWidth: "250px",
        textAlign: "center",
        border: "1px solid"
    },
    th_secondary: {
        width: "250px",
        textAlign: "center",
        fontSize: "14px",
        color: "#0000008c",
        borderRight: "1px solid",
        borderCollapse: "collapse",
    },
    loading_container: {
        height: "20vh",
        width: "70vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}
