export const tags = [
  "NACH Already Presented",
  "NACH Presentation Required",
  "Repayment Done",
  "Promise to Pay - by Dealer",
  "Promise to Pay - by Borrower",
  "Promise to Pay - by EMB Agent",
  "Call Back Required - Dealer",
  "Call Back Required - Borrower",
  "Promise to Pay Breached",
  "Partial Repayment Done",
  "Denied to Pay - Dealer",
  "Denied to Pay - Borrower",
  "Not Responding",
  "Number Not Reachable",
  "Number Temporarily Out of Reach",
  "Number Invalid",
  "Incoming Barred",
  "Rickshaw Pulled back by Dealer",
  "Repayment Link under Process",
  "Repayment with Dealer",
  "Repayment with Agent",
  "Co-Borrower/Co-Applicant will help for Repayment",
  "ForeClosure Procedure Initiated",
  "Others",
  "No Comment",
];
