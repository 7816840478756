import {
  Autocomplete,
  Button,
  Checkbox,
  createFilterOptions,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  TextField,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import React, { useContext, useEffect, useState } from "react";
import {
  addCommentOnAgentFilesStatus,
  deleteCommentOnAgentFilesStatus,
  getAgentFilesStatus,
  getCommentsOnAgentFilesStatus,
  getFile,
  updateCommentOnAgentFilesStatus,
} from "../../api";
import { toast } from "react-toastify";
import { f } from "../../utility/Formater";
import CustomTableV2 from "../CustomTable/CustomTableV2";
import { UserContext } from "../../Context/UserContext";
import { Close, Delete, Edit } from "@mui/icons-material";
import DeletePopup from "../DeletePopup";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initEditComment = {
  comment_id: null,
  documents: [],
  application: null,
  comment: "",
};
const initInputs = { application: null, documents: [], comment: "" };

const AgentFilesStatusComments = ({
  open,
  handleClose,
  agent_code,
  assignCategory,
}) => {
  const [autoFillData, setAutoFillData] = useState({
    documents: [],
    applications: [],
  });
  const [inputs, setInputs] = useState(initInputs);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [editComment, setEditComment] = useState(initEditComment);
  const [confDltPopup, setConfDltPopup] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(null);

  const { user } = useContext(UserContext);

  function getFileName(name) {
    if (["cheques_1", "cibil_report_1"].includes(name))
      return name?.replaceAll("_", " ");
    return name?.replaceAll("_1", "")?.replaceAll("_", " ");
  }

  function isDisabled() {
    if (editComment?.comment_id) {
      return (
        !editComment?.comment_id ||
        !editComment?.comment ||
        !editComment?.application
      );
    }
    return (
      !inputs?.comment || !inputs?.application
    );
  }

  let columns = [
    {
      label: "Application",
      render: (i) => {
        return (
          <span className="text-nowrap">
            {f(comments?.[i]?.application_id)}
          </span>
        );
      },
      width: 100,
    },
    {
      label: "Date",
      render: (i) => {
        return (
          <span className="text-nowrap">
            {f(comments?.[i]?.createdAt,"date/time")}
          </span>
        );
      },
      width: 100,
    },
    {
      label: "Documents",
      render: (i) => {
        return (
          <span className="text-wrap text-capitalize">
            {f(comments?.[i]?.documents?.map(getFileName)?.join(", "))}
          </span>
        );
      },
      width: 150,
    },
    {
      label: "Comments",
      render: (i) => {
        return <span className="text-wrap">{comments?.[i]?.comment}</span>;
      },
      width: 250,
    },
    {
      label: "User",
      render: (i) => {
        return (
          <span className="text-wrap">{comments?.[i]?.createdBy?.name}</span>
        );
      },
      width: 100,
    },
    {
      label: "Actions",
      render: (i) => {
        let isdeleting = deleteLoading === comments?.[i]?.comment_id;
        return (
          <div className="d-flex flex-row align-items-center justify-content-center">
            <IconButton
              size="small"
              color="primary"
              disabled={isdeleting}
              onClick={() => {
                let cmtData = {
                  application: {
                    application_id: comments?.[i]?.application_id,
                    customerName:undefined,
                  },
                  documents: comments?.[i]?.documents?.length ? comments?.[i]?.documents?.map((item) => ({
                    name: item,
                    fileName: getFileName(item),
                  })) : [],
                  comment: comments?.[i]?.comment,
                  comment_id: comments?.[i]?.comment_id,
                };
                setEditComment(
                  editComment?.comment_id ? initEditComment : cmtData
                );
              }}
            >
              {editComment?.comment_id === comments?.[i]?.comment_id ? (
                <Close fontSize="small" />
              ) : (
                <Edit fontSize="small" />
              )}
            </IconButton>
            <IconButton
              size="small"
              color="error"
              disabled={editComment?.comment_id === comments?.[i]?.comment_id || isdeleting}
              onClick={()=>{
                setConfDltPopup(comments?.[i]);
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  async function fetchAllAppications() {
    try {
      const { data } = await getAgentFilesStatus(agent_code, "");
      let applications = [];
      let allDocs = {};

      data?.data?.map((item) => {
        applications.push({
          application_id: item?.application_id,
          customerName: item?.customerData?.pan_details?.name,
        });

        let categorizedDocs = assignCategory(
          item?.files_percentage?.files,
          "customer",
          {}
        );
        Object.keys(categorizedDocs || {}).map((key) => {
          if (!allDocs?.[key]) allDocs[key] = [];
          if (allDocs?.[key]?.length < categorizedDocs[key]?.length) {
            allDocs[key] = categorizedDocs[key];
          }
        });
        return item;
      });

      let documents = Object.keys(allDocs).reduce(
        (acc, item) => [...acc, ...allDocs[item]],
        []
      );
      documents.sort((a, b) => a?.name?.localeCompare(b?.name));
      documents = documents?.map((item) => ({
        ...item,
        fileName: getFileName(item?.name),
      }));
      documents = [
        ...new Map(documents?.map((item) => [item?.fileName, item])).values(),
      ];
      setAutoFillData((prev) => ({
        ...prev,
        applications,
        documents: [...prev.documents, ...documents],
      }));
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
    }
  }

  async function fetchAllComments() {
    try {
      setCommentsLoading(true);
      const { data } = await getCommentsOnAgentFilesStatus(agent_code);
      data?.data?.sort((a,b)=>b?.createdAt-a?.createdAt)
      setComments(data?.data);
      let docs = data?.data?.reduce(
        (acc, cmt) => [
          ...acc,
          ...(cmt?.documents?.map((itm) => ({
            name: itm,
            fileName: getFileName(itm),
          })) ?? []),
        ],
        []
      );
      let docsArr = autoFillData?.documents?.concat(docs);
      setAutoFillData((prev) => ({
        ...prev,
        documents: [
          ...new Map(docsArr?.map((itm) => [itm?.name, itm])).values(),
        ],
      }));
    } catch (error) {
      toast.error(error?.respons?.data?.message ?? error?.message);
    } finally {
      setCommentsLoading(false);
    }
  }

  function handleChange(field, value) {
    if (editComment?.comment_id) {
      setEditComment((prev) => ({ ...prev, [field]: value }));
    } else {
      setInputs((prev) => ({ ...prev, [field]: value }));
    }
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      const API = editComment?.comment_id
        ? updateCommentOnAgentFilesStatus
        : addCommentOnAgentFilesStatus;
      let params = `${agent_code}`;
      let payObj = editComment.comment_id ? editComment : inputs;
      let payload = {
        application_id: payObj.application.application_id,
        comment: payObj.comment,
        documents: payObj.documents?.map((itm) => itm?.name),
        user_details: {
          name: user?.name,
          email: user?.email,
        },
        comment_id:editComment.comment_id
      };
      const { data } = await API(params, payload);
      toast(data?.message);
      setComments(data?.data);
      if (editComment.comment_id) setEditComment(initEditComment);
      else setInputs(initInputs);
      setTimeout(()=>{setLoading(false)},2000);
    } catch (error) {
      toast.error(error?.respons?.data?.message ?? error?.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteComment(){
    try{
      const { comment_id } = confDltPopup;
      setDeleteLoading(comment_id);
      const { data } = await deleteCommentOnAgentFilesStatus(agent_code,comment_id);
      toast(data?.message);
      setComments(data?.data);
    }catch(error){
      toast.error(error?.respons?.data?.message ?? error?.message);
    }finally{
      setDeleteLoading(null);
    }
  }

  useEffect(() => {
    if (open) {
      fetchAllAppications();
      fetchAllComments();
    }
  }, [open]);

  return (
    <>
      <Dialog maxWidth={"lg"} open={open} onClose={handleClose}>
        <DialogTitle bgcolor={"InfoBackground"}>Comments</DialogTitle>
        <IconButton
          className="position-absolute top-0 end-0 m-2"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <div style={{ width: 900, height: 550 }}>
            {!loading && <Grid container spacing={2} mb={1}>
              <Grid item xs={12} sm={6} className="my-2">
                <Autocomplete
                  size="small"
                  id="checkboxes-tags-demo"
                  options={autoFillData?.applications || []}
                  value={
                    editComment?.comment_id
                      ? editComment?.application
                      : inputs?.application || null
                  }
                  filterOptions={createFilterOptions({
                    matchFrom: "any",
                    stringify: (option) =>
                      option?.customerName ? option?.customerName + option?.application_id : option?.application_id,
                  })}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option}>
                      {option?.customerName ? option?.customerName + " - " + option?.application_id : option?.application_id}
                    </li>
                  )}
                  getOptionLabel={(option) =>
                    option?.customerName ? option?.customerName + " - " + option?.application_id : option?.application_id
                  }
                  fullWidth
                  onChange={(e, value) => {
                    handleChange("application", value);
                  }}
                  renderInput={(params) => (
                    <TextField label="Application" {...params} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="my-2">
                <Autocomplete
                  multiple
                  size="small"
                  id="checkboxes-tags-demo"
                  options={autoFillData?.documents || []}
                  value={(()=>{
                    return editComment?.comment_id
                      ? editComment.documents
                      : inputs?.documents ?? []
                  })()
                  }
                  disableCloseOnSelect
                  filterOptions={createFilterOptions({
                    matchFrom: "any",
                    stringify: (option) => option?.fileName,
                  })}
                  getOptionLabel={(option) => option?.fileName}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} className="text-capitalize" key={option}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.fileName}
                    </li>
                  )}
                  fullWidth
                  onChange={(e, value) => {
                    handleChange("documents", value);
                  }}
                  renderInput={(params) => (
                    <TextField disabled={true} label="Documents" {...params} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={4}
                  id="outlined-basic"
                  placeholder="Comment"
                  variant="outlined"
                  value={
                    editComment?.comment_id
                      ? editComment?.comment
                      : inputs.comment
                  }
                  onChange={(e) => {
                    handleChange("comment", e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className="d-flex flex-column align-content-center justify-content-end w-100 h-100">
                  {editComment.comment_id && (
                    <Button
                      variant="contained"
                      className="align-self-center mb-3"
                      color="info"
                      disableElevation={true}
                      sx={{
                        width: 150,
                      }}
                      onClick={() => {
                        handleChange("comment_id", null);
                      }}
                    >
                      New Comment
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    className="align-self-center"
                    disableElevation={true}
                    disabled={isDisabled()}
                    sx={{
                      width: 150,
                    }}
                    color="secondary"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {editComment?.comment_id ? "edit" : "Add"} Comment
                  </Button>
                </div>
              </Grid>
            </Grid>}
            {loading && <SubmitLoader/>}
            <Divider />
            <div>
              <CustomTableV2
                tableData={comments}
                columns={columns}
                isLoading={commentsLoading}
                loadingRows={6}
                tableHeight={"40vh"}
                borderBottom
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <DeletePopup
        confDltPopup={!!confDltPopup}
        setConfDltPopup={setConfDltPopup}
        handleDeleteDoc={() => {
          handleDeleteComment();
          setConfDltPopup(null);
        }}
        text={"are you sure ?"}
      />
    </>
  );
};

const SubmitLoader = ({}) => {
  return (
    <Grid container spacing={2} mb={1}>
      <Grid item xs={12} sm={6}>
        <Skeleton width={"100%"}  height={50} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Skeleton width={"100%"} height={50} />
      </Grid>
      <Grid item xs={12} sm={9} height={150} mt={0} pt={0}>
        <Skeleton width={"100%"} height={"100%"} />
      </Grid>
      <Grid item xs={12} sm={3} height={150}> 
        <div className="d-flex flex-column align-items-center justify-content-end w-100" style={{height:"80%"}} >
          <Button
            variant="contained"
            className="align-self-center"
            disableElevation={true}
            disabled={true}
            sx={{
              width: 200,
            }}
            color="secondary"
          >
            submiting...
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default AgentFilesStatusComments;
