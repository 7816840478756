import React, { useContext } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement, Filler } from "chart.js";
import { Pie ,Bar,Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CircularProgress, Grid } from "@mui/material";
import { Table } from "@mui/material";
import { UserContext } from "../../Context/UserContext";


ChartJS.register(ArcElement, Tooltip, Legend, BarElement,CategoryScale,LinearScale,LineElement,PointElement,Filler);

// const colors = [
//   "#357AF6",
//   "#5CC8BE",
//   "#AF52DE",
//   "#dea916",
//   "#EA3354",
//   "#abf227",
//   "#7D3C98",
//   "#7c8f59",
//   "#D35400",
//   "#2b2c54",
//   "#8E44AD",
// ];
// const PieChartComp = ({ pieData, title, breakWord ,chartType = "pie"}) => {
 

//   let validData = notEmpty(Object?.values(pieData));
//   const data = {
//     labels: Object?.keys(pieData) || [],
//     datasets: [
//       {
//         label: "Count",
//         data: Object?.values(pieData) || [],
//         backgroundColor: colors,
//         borderColor: ["#ffffff"],
//         borderWidth: 0.5,        
//       },
//     ],
//   };
//   // const pieValues = Object?.values(pieData) || [];
//   // const total = pieValues.reduce((acc, value) => acc + value, 0);  
//   // // Convert counts to percentages
//   // const percentageData = pieValues.map(value => ((value / total) * 100).toFixed(2));
  
//   // const data = {
//   //   labels: Object?.keys(pieData) || [],
//   //   datasets: [
//   //     {
//   //       label: "Percentage",
//   //       data: percentageData,
//   //       backgroundColor: colors,
//   //       borderColor: ["#ffffff"],
//   //       borderWidth: 0.5,        
//   //     },
//   //   ],
//   // };
  
//   // let validData = notEmpty(pieValues);
  

//   const ChartComponent = chartType === "doughnut" ? Doughnut : Pie;
//   return (
//     // <div className="pie-holder" style={{ width: "300px" }}>
//     <div className="home-pie-holder">
//       <p className="home-pie-heading">{title}</p>
//       <div className="d-flex w-100 p-5 pt-0 pb-4 ps-4">
//         <div style={{ flexGrow: 1, marginRight: 30 }}>
//           {validData ? (
//             <ChartComponent
//               width={100}
//               height={100}
//               data={data}
//               options={{
//                 plugins: {
//                   legend: {
//                     display: false,
//                   },
//                   datalabels: {
//                     color: "#ffffff",
//                     formatter: function (value, context) {
//                       return value ? `${value}%` : ""
//                     },
//                   },
//                 },
//               }}
//               plugins={[ChartDataLabels]}
//             />
//           ) : (
//             <div
//               style={{
//                 width: "150px",
//                 height: "150px",
//                 borderRadius: "50%",
//                 background: "gray",
//                 color: "white",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 opacity: 0.5,
//               }}
//             >
//               No Data
//             </div>
//           )}
//         </div>
//         <div style={{ flexGrow: 1, maxWidth: 100 }}>
//           {(Object?.keys(pieData) || [])?.map((item, i) => {
//             return (
//               <div className="pie-label">
//                 <div
//                   className="color-box"
//                   style={{
//                     backgroundColor: colors[i],
//                     marginRight: "5px",
//                     marginTop: 2.5,
//                     flexShrink: 0,
//                   }}
//                 ></div>
//                 <div
//                   style={
//                     breakWord
//                       ? {
//                           display: "flex",
//                           alignItems: "center",
//                         }
//                       : {
//                           whiteSpace: "nowrap",
//                         }
//                   }
//                 >
//                   {item}
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// };
const calculateTotalCount = (data) => {
  return Object.values(data).reduce((acc, value) => acc + value, 0);
};
const notEmpty = (data) => {
  if (
    (data || [])?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    ) !== 0
  ) {
    return true;
  } else return false;
};

const BarChartComp = ({ barData, title,axis,barwidth,barcolor }) => {
  const { user } = useContext(UserContext);
  const validData = notEmpty(Object.values(barData));
  const totalCount = calculateTotalCount(barData);
  const data = {
    labels: Object.keys(barData) || [],
    datasets: [
      {
        label: user?.isUserPresenter ? "percentage" : "count",
        data: Object.values(barData) || [],
        // backgroundColor: ["#357AF6"],
        backgroundColor: barcolor,
        borderColor: ["#ffffff"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="home-pie-holder1">
      {!user?.isUserPresenter && <p className="home-pie-heading">{title}</p>}
      <div className="d-flex w-100 p-5 pt-0 pb-4 ps-4">
        <div style={{ flexGrow: 2, marginRight: 5 }} className={user?.isUserPresenter ? "mt-5" : ""}>
          {validData ? (
            <Bar
              width={barwidth}
              height={79}
              data={data}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                indexAxis:axis,
                plugins: {
                  legend: {
                    display: false,
                  },
                  datalabels: {
                    color: "#ffffff",
                    formatter: (value) => (value ? value : ""),
                  },
                },
                scales: {
                  x: {
                    beginAtZero: true,
                    ticks: {
                      // Format the numbers to display percentages
                      callback: function (_,i) {
                        return data["labels"][i]
                      },
                    },
                  },
                  y: {
                    beginAtZero: true,
                    ticks: {
                      // Format the numbers to display percentages
                      callback: function (value) {
                        if(user?.isUserPresenter){
                          return value + "%";
                        }
                        return value;
                      },
                    },
                  },
                },
              }}
              plugins={[ChartDataLabels]}
            />
          ) : (
            <div
              style={{
                width: "150px",
                height: "150px",
                borderRadius:"50%",
                background: "gray",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: 0.5,
              }}
            >
              No Data
            </div>
          )}
        </div>
      </div>
      {!user?.isUserPresenter && <p className="total-count">Total Count: {totalCount}</p>}
      {user?.isUserPresenter && <p className="home-pie-heading total-count">{title}</p>}
    </div>
  );
};

const AreaChartComp = ({ areaData, title,shade ,barwidth,graphcolor,graphborder }) => {
  const { user } = useContext(UserContext);
  const validData = notEmpty(Object.values(areaData));
  const totalCount = calculateTotalCount(areaData);
  const data = {
    labels: Object.keys(areaData) || [],
    datasets: [
      {
        label: user?.isUserPresenter ? "percentage" : "count",
        data: Object.values(areaData) || [],
        backgroundColor: graphcolor,
        borderColor: graphborder,
        borderWidth: 1,
        fill: shade  , // This makes the chart an area chart  
      }
      ],
  };

  return (
    <div className="home-pie-holder1">
      {!user?.isUserPresenter && <p className="home-pie-heading">{title}</p>}
      <div className="d-flex w-100 p-5 pt-0 pb-4 ps-4">
        <div style={{ flexGrow: 2, marginRight: 5,  }} className={user?.isUserPresenter ? "mt-5" : ""} >
          {validData ? (
            <Line
              width={barwidth}
              height={75}
              data={data}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  datalabels: {
                    color: "#ffffff",
                    formatter: (value) => (value ? value : ""),
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      // Format the numbers to display percentages
                      callback: function (value) {
                        if(user?.isUserPresenter){
                          return value + "%";
                        }
                        return value;
                      },
                    },
                  },
                  x: {
                    beginAtZero: true,
                    ticks: {
                      // Format the numbers to display percentages
                      callback: function (_,i) {
                        return data.labels[i];
                      },
                    },
                  },
                },
              }}
              plugins={[ChartDataLabels]}
              />  
            ) : (
            <div
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                background: "gray",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: 0.5,
              }}
            >
              No Data
            </div>
          )}
        </div>
      </div>
      {!user?.isUserPresenter && <p className="total-count">Total Count: {totalCount}</p>}
      {user?.isUserPresenter && <p className="home-pie-heading total-count">{title}</p>}
    </div>
  );
};

const ProfilingDashboardTable = ({ DashboardTableData ,title, iconType}) => {
  
  const { user } = useContext(UserContext);

  const totalCount = calculateTotalCount(DashboardTableData);
     const tablestyle={
      borderCollapse: 'collapse',
      width: '90%',
     };
     const thStyle = {
      border: '1px solid black',
      padding: '8px',
      textAlign: 'center', 
    };
    const tdStyle = {
      border: '1px solid black',
      padding: '8px',
      textAlign:user?.isUserPresenter ? 'start' : "center",
      fontSize:"14px"
    };
     
  return (
    <div className={`home-pie-holder1`}>
      { !user?.isUserPresenter && <p className="home-pie-heading">{title}</p>}
      <Table style={tablestyle} className={`${user?.isUserPresenter && "m-3"}`}>
        <thead style={{alignContent:"center"}}>
          <tr>
            <th align="left" style={thStyle}>Type</th>
            { !user?.isUserPresenter && <th align="center" style={thStyle}>Count</th>}
            <th align="right" style={thStyle}>Percentage</th>
          </tr>
        </thead>
        <tbody >
          {Object.entries(DashboardTableData).map(([type, count]) => (
            <tr key={type}  >
              <td component="th" scope="row" style={tdStyle} className="text-nowrap" >
                {getIcon(type,iconType)} &nbsp; {type}
              </td>
              {!user?.isUserPresenter && <td  style={tdStyle}>&nbsp;{count}</td>}
              <td style={tdStyle} >&nbsp;{((count / totalCount) * 100).toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </Table>
      { !user?.isUserPresenter && <p className="total-count">Total Count: {totalCount}</p>}
      { user?.isUserPresenter && <p className="home-pie-heading total-count">{title}</p> }
    </div>
  );
  
};

const getIcon = (type,iconType) => {
  if (iconType === "phone") {
    return type === "Smart phone" ? <img width="20" height="20" src="https://img.icons8.com/ios/50/iphone13.png" alt="iphone13"/> : <img width="20" height="20" src="https://img.icons8.com/metro/26/blackberry.png" alt="blackberry"/>;
  } else if (iconType === "gender") {
    if (type === "Male") return <img width="20" height="20" src="https://img.icons8.com/ios-glyphs/30/user-male--v1.png" alt="user-male--v1"/>;
    if (type === "Female") return <img width="20" height="20" src="https://img.icons8.com/ios-glyphs/30/user-female--v1.png" alt="user-female--v1"/>;
    return <img width="20" height="20" src="https://img.icons8.com/ios-glyphs/30/lgbtq.png" alt="lgbtq"/>
  }else if (iconType === "whatsappuser"){
    if (type === "Yes, Same Number") return <img width="20" height="20" src="https://img.icons8.com/color/48/whatsapp--v1.png" alt="whatsapp--v1"/>
    if (type === "Yes, with other Number") return <img width="20" height="20" src="https://img.icons8.com/scribby/50/whatsapp.png" alt="whatsapp"/>
    if (type === "No") return <img width="20" height="20" src="https://img.icons8.com/skeuomorphism/32/cancel.png" alt="cancel"/>
    if (type === "NA") return <img width="20" height="20" src="https://img.icons8.com/tapes/40/cancel-2.png" alt="cancel-2"/>
    
  }
  return null;
};

const ProfilingDashboardTableWithIcons = ({ title, data, iconType }) => {
  const totalCount = calculateTotalCount(data);  

  return (
    <div className="home-pie-holder1">
      { false &&  <p className="home-pie-heading">{title}</p>}
      <Table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {Object.entries(data).map(([type, count]) => (
            <tr key={type}>
              <td component="th" scope="row" style={{  padding: "8px", textAlign: "" }}>
                {getIcon(type,iconType)} <span style={{ marginLeft: "8px" }}>{type}</span>
              </td>
              { false && <td align="center" style={{  padding: "8px" }}>{count}</td>}
              <td align="right" style={{ padding: "8px" }}>{((count / totalCount) * 100).toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {false && <p className="total-count">Total Count: {totalCount}</p>}
      { true &&  <p className="home-pie-heading total-count">{title}</p>}
    </div>
  );
};
const ProfilingDashboard = ({ loader, profileData }) => {
  return loader === "PROFILE" ? (
    <>
      <div className="content-center w-100">
        <CircularProgress size={70} />
      </div>
    </>
  ) : (
    <>
      <Grid spacing={2} container>       
        <Grid item lg={4} md={6} xs={12}>
        <BarChartComp title={"Age (in yrs)"} barData={profileData?.age} axis={"x"} barwidth={100} barcolor={["#228B22"]} />
      </Grid>
      <Grid item lg={8} md={12} xs={12}>
          <AreaChartComp
            title={"Monthly Income"}
            shade={true}
            areaData={profileData?.monthly_income}
            barwidth={200}
            graphcolor={"rgba(128, 0, 128, 0.5)"}
            graphborder={"rgba(128, 0, 128, 1)"}
          />
        </Grid>
        <Grid item lg={8} md={12} xs={12} >
          <AreaChartComp
            title={"Monthly household income"}
            shade={true}
            areaData={profileData?.monthly_household_income}
            barwidth={200}
            graphcolor={"rgba(135, 206, 235, 0.5)"}
            graphborder={"rgba(70, 130, 180, 1)"}
          />
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <ProfilingDashboardTable title={"Gender"} DashboardTableData={profileData?.gender}  iconType="gender"  />
          {/* <ProfilingDashboardTableWithIcons title={"Gender"} data={profileData?.gender} iconType="gender" /> */}
        </Grid>      
        <Grid item lg={8} md={12} xs={12}  >         
          <BarChartComp 
            title={"Number of family members"}
            barData={profileData?.no_of_family_members}
            // axis={"y"}
            barwidth={200}
            barcolor={["#357AF6"]}
          />
          
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <ProfilingDashboardTable title={"Phone Type"} DashboardTableData={profileData?.phone_type}  iconType="phone"  />
          {/* <ProfilingDashboardTableWithIcons title={"Phone Type"} data={profileData?.phone_type} iconType="phone"/> */}
        </Grid>
        <Grid item lg={8} md={12} xs={12}>
        <AreaChartComp title={"Education"} shade={false} areaData={profileData?.education}  barwidth={200} graphcolor={"rgba(0, 0, 0, 1)"}    graphborder={"rgba(0, 0, 0, 1)"}/>
      </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <ProfilingDashboardTable title={"Preferred repayment mode"} DashboardTableData={profileData?.prefered_repayment_mode}/>
        </Grid>
          <Grid item lg={4} md={6} xs={12}>
          <ProfilingDashboardTable title={"Whatsapp User"} DashboardTableData={profileData?.whatsapp_user}  iconType="whatsappuser"  />
          {/* <ProfilingDashboardTableWithIcons title={"Whatsapp User"} data={profileData?.whatsapp_user} iconType="whatsappuser" /> */}
        </Grid>
        <Grid item lg={8} md={12} xs={12}>
          <BarChartComp
            title={"Relation with co"}
            barData={profileData?.relation_with_co}
            axis={"x"}
            barwidth={200}
            barcolor={["#228B22"]}
          />
        </Grid>
        <Grid item lg={8} md={12} xs={12}>
          <BarChartComp
            title={"Source of downpayment"}
            barData={profileData?.source_downpayment}
            // breakWord={true}
            // axis={"y"}
            barwidth={200}
            barcolor={["#357AF6"]}
          />
        </Grid>
        <Grid item lg={4} md={6} xs={12}>          
          <ProfilingDashboardTable title={"Martial Status"} DashboardTableData={profileData?.martial_status}/>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfilingDashboard;
