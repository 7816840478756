import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { CircularProgress, TextField } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useRef } from "react";
import { sendSactionLetter } from "../../api";
import { GlobalContext } from "../../Context/GlobalContext";
import { useContext } from "react";
import { DownloadOutlined } from "@mui/icons-material";
import moment from "moment";

const buttonStyle = {
  fontSize: "14px",
  color: "1px solid skyblue",
  padding: "15px",
  borderRadius: 2,
};

export default function SanctionLetterSend({
  open,
  handleClose,
  application_id,
}) {
  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;

  const datePickerRef = useRef(null);
  const [isLoading, setLoading] = useState(null);
  const [customDate, setCustomDate] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  const [disableSend, setDisableSend] = useState(false);

  const handleSanctionSend = async (type) => {
    try {
      setLoading(type);
      let payload = {
        application_id,
        ...(type === "custom_date" && customDate
          ? {
              custom_date: Date.parse(customDate),
            }
          : {}),
      };
      let { data } = await sendSactionLetter(payload);
      if (data?.status) {
        setCustomDate(null);
        setUpdateDetails({
          ...updateDetails,
          sanction_letter_sent: true,
          sanction_letter_data: {
            sent: true,
            last_sanction_link: data?.shorten_link,
          },
        });
        toast.success("Sanction letter sent succesfully!");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Operation failed!"
      );
    }
    setLoading(null);
    handleClose();
  };

  useEffect(() => {
    let last_sanction_dateMS =
      updateDetails?.sanction_letter_data?.last_sent_dateMS;

    if (last_sanction_dateMS) {
      const momentTimestamp = moment(last_sanction_dateMS);
      const targetDateTime = moment("21/11/2023 23:00", "DD/MM/YYYY HH:mm");

      if (momentTimestamp.isBefore(targetDateTime)) {
        setDisableSend(true);
      }
    }
  }, [updateDetails?.sanction_letter_data]);

  return (
    <div>
      <Dialog
        sx={{
          filter:
            "drop-shadow(6px 6px 30px rgba(125, 153, 180, 0.2)) drop-shadow(2px 2px 4px rgba(86, 144, 198, 0.11))",
          border: "none",
          outline: "none",
          borderRadius: "10px",
        }}
        fullWidth
        maxWidth={"xs"}
        open={true}
        onClose={handleClose}
      >
        <DialogTitle>
          <p className="text-center mb-0" style={{ fontSize: 15 }}>
            Send Sanction Letter
          </p>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box>
            <Grid container>
              {!updateDetails?.sanction_letter_sent && (
                <Grid xs={12} item className="px-4 mb-3">
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{ ...buttonStyle }}
                    onClick={() => handleSanctionSend("default_date")}
                    color="inherit"
                    disabled={isLoading !== null}
                  >
                    {isLoading === "default_date" ? (
                      <>
                        <CircularProgress size={18} />{" "}
                        <span className="ms-2">Sending...</span>
                      </>
                    ) : (
                      <>
                        <span className="ms-2">Send as default Date</span>
                      </>
                    )}
                  </Button>
                </Grid>
              )}

              {!disableSend && (
                <Grid xs={12} item className="px-4">
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{ ...buttonStyle }}
                    onClick={() => {
                      setOpenDate(true);
                    }}
                    color="inherit"
                    disabled={isLoading !== null}
                  >
                    {isLoading === "custom_date" ? (
                      <>
                        <CircularProgress size={18} />{" "}
                        <span className="ms-2">Sending...</span>
                      </>
                    ) : (
                      <>
                        <span className="ms-2">Choose Custom Date</span>
                      </>
                    )}
                  </Button>
                </Grid>
              )}
              {updateDetails?.sanction_letter_sent && (
                <Grid xs={12} item className="px-4 mt-3">
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{ ...buttonStyle }}
                    onClick={() => {
                      if (
                        updateDetails?.sanction_letter_data?.last_sanction_link
                      ) {
                        window.open(
                          updateDetails?.sanction_letter_data
                            ?.last_sanction_link,
                          "_BLANK"
                        );
                      } else {
                        toast.warn("Document not available");
                      }
                    }}
                    color="inherit"
                    disabled={isLoading !== null}
                  >
                    <DownloadOutlined /> Download Last sent
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={openDate}
        onClose={() => {
          setOpenDate(false);
          setCustomDate(null);
        }}
      >
        <div className="p-4">
          <Grid container>
            <Grid xs={12} item className="px-4">
              <TextField
                fullWidth
                inputRef={datePickerRef}
                type="date"
                value={customDate}
                onChange={(e) => {
                  setCustomDate(e.target.value === "" ? null : e.target.value);
                }}
              />
            </Grid>
            <Grid xs={12} item className="px-4">
              <Button
                variant="contained"
                className="mt-3"
                fullWidth
                disabled={!customDate}
                onClick={() => {
                  setOpenDate(false);
                  handleSanctionSend("custom_date");
                }}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
