import { useState, useEffect } from "react";
import axios from "axios";
import { verifyAuth } from "../api";

export default function useFindUser() {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const demoUsers = ["anupam@embifi.in"];

  useEffect(() => {
    async function findUser() {
      setLoading(true);
      try {
        let { data } = await verifyAuth();
        setLoading(false);
        setUser(()=>{
          
          return {
            ...data.data,
            token: data?.token,
            isUserPresenter:"et@embifi.in" === data?.data?.email,
            isDemoUser: demoUsers?.includes(data?.data?.email),
          }
        });
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            name: data?.data?.name,
            email: data?.data?.email,
            user_id: data?.data?.user_id,
          })
        );
      } catch (err) {
        setLoading(false);
        setUser(null);
        localStorage.removeItem('userInfo');
      }
    }

    findUser();
  }, []);

  //   useEffect(()=>{
  //     if(user){
  //         localStorage.setItem("userData", JSON.stringify(user));
  //     }else{
  //         localStorage.removeItem("userData");
  //     }
  //   },[user])

  return {
    user,
    setUser,
    isLoading,
  };
}
