import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
// import { InputLabel } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "NACH Already Presented",
  "NACH Presentation Required",
  "Repayment Done",
  "Promise to Pay - by Dealer",
  "Promise to Pay - by Borrower",
  "Promise to Pay - by EMB Agent",
  "Call Back Required - Dealer",
  "Call Back Required - Borrower",
  "Promise to Pay Breached",
  "Partial Repayment Done",
  "Denied to Pay - Dealer",
  "Denied to Pay - Borrower",
  "Not Responding",
  "Number Not Reachable",
  "Number Temporarily Out of Reach",
  "Number Invalid",
  "Incoming Barred",
  "Rickshaw Pulled back by Dealer",
  "Repayment Link under Process",
  "Repayment with Dealer",
  "Repayment with Agent",
  "Co-Borrower/Co-Applicant will help for Repayment",
  "ForeClosure Procedure Initiated",
  "Others",
];

const gpsValues = ["INACTIVE VEHICLE", "GPS INSTALLATION"];

export default function SelectionField({
  selectedField,
  value,
  commentData,
  type,
}) {
  const [personName, setPersonName] = React.useState("");

  const handleChange = (event) => {
    const value = event?.target?.innerText;
    setPersonName(typeof value === "string" && value != "" ? value : "");
    selectedField(typeof value === "string" && value != "" ? value : "");
  };
  const handleInputChange = (event, newInputValue) => {
    setPersonName(newInputValue);
  };

  useEffect(() => {
    if (!["", null, undefined].includes(value)) {
      selectedField(typeof value === "string" ? value.split(",") : value);
      setPersonName(typeof value === "string" ? value.split(",") : value);
    }
  }, [value]);

  useEffect(()=>{
    console.log(value);
  },[value])

  return (
    <div>
      <FormControl sx={{ width: 300 }} size="small">
        {/* <InputLabel>Select the tag</InputLabel> */}
        <Autocomplete
          value={personName}
          onChange={handleChange}
          inputValue={personName}
          onInputChange={(event, newInputValue) => {
            handleInputChange(event, newInputValue);
          }}
          options={type === "COLLECTION" ? names : gpsValues}
          renderInput={(params) => <TextField {...params} label="Select Tag" />}
        />
      </FormControl>
    </div>
  );
}
