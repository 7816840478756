import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FilledInput,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import EditableAutoComplete from "../AutoComplete/EditableAutoComplete";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalContext";
import EditIcon from "@mui/icons-material/Edit";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { compareObjects } from "../../utility/validations";
import { saveLoanAgreementData, updateTelepd } from "../../api";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { buildDeprecatedPropsWarning } from "@mui/x-date-pickers/internals";
import dayjs from "dayjs";
import { daysInWeek } from "date-fns";

const labelStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: 13,
};

const initialState = {
  chassis_number: "",
  manufacturing_year: null,
  sales_invoice_number: "",
  sales_invoice_date: null,
  insurance_cost: "",
  insurance_company_name: "",
  policy_number: "",
  insurance_period: {
    start_date_ms: null,
    end_date_ms: null,
  },
};
function LoanEsingDialog({ open, handleClose, getApplicationDetails }) {
  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const [isLoading, setIsLoading] = useState(false);
  const [prifillLoanDetails, setPrifillLoanDetails] = useState(initialState);
  const [openCnfmSubmit, setOpenCnfmSubmit] = useState(false);

  function isSaveEnabled() {
    let disabled = true;
    for (let item of Object.keys(initialState)) {
      if (item === "insurance_period") {
        disabled = (prifillLoanDetails[item]["start_date_ms"] &&
          prifillLoanDetails[item]["end_date_ms"] ) ? false: true;
      } else {
        disabled = prifillLoanDetails[item] === initialState[item];
      }
      if (!disabled) {
        return disabled;
      }
    }
    return disabled;
  }

  function isSubmitEnabled() {
    let {
      chassis_number,
      manufacturing_year,
      insurance_cost,
      insurance_company_name,
      policy_number,
      insurance_period,
    } = updateDetails?.prefill_agreement_details || {};
    let { start_date_ms, end_date_ms } = insurance_period || {};
    if (
      !chassis_number ||
      !manufacturing_year ||
      !insurance_cost ||
      !insurance_company_name ||
      !policy_number ||
      !start_date_ms ||
      !end_date_ms
    ) {
      return true;
    }

    return false;
  }

  function buildPayload() {
    let payload = { application_id: updateDetails?.application_id };
    Object.keys(initialState).map((item) => {
      if (item === "insurance_period") {
        if (
          prifillLoanDetails?.[item]?.start_date_ms &&
          prifillLoanDetails?.[item]?.end_date_ms
        ) {
          payload[item] = {
            start_date_ms: dayjs(
              prifillLoanDetails?.[item]?.start_date_ms
            ).valueOf(),
            end_date_ms: dayjs(
              prifillLoanDetails?.[item]?.end_date_ms
            ).valueOf(),
          };
        }
      } else if (item === "manufacturing_year" && prifillLoanDetails?.[item]) {
        payload[item] = dayjs(prifillLoanDetails?.[item]).format("YYYY");
      } else if (item === "sales_invoice_date" && prifillLoanDetails?.[item]) {
        payload[item] = dayjs(prifillLoanDetails?.[item]).valueOf();
      } else if (prifillLoanDetails?.[item]?.length) {
        payload[item] = prifillLoanDetails?.[item];
      }
    });

    return payload;
  }

  async function handleSave() {
    try {
      setIsLoading(true);
      let params = `request_type=save`;
      const payload = buildPayload();
      const { data } = await saveLoanAgreementData(params, payload);
      if (data?.status) {
        toast("Data saved successfully !");
        await getApplicationDetails();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  }

  async function handleSubmit(){
    try{
      handlePopupClose();
      setIsLoading(true);
      let params = `request_type=submit`;
      const payload = {
        application_id:updateDetails?.application_id
      };
      const { data } = await saveLoanAgreementData(params, payload);
      if (data?.status) {
        toast( data?.message || "Submitted successfully !");
        await getApplicationDetails();
      }
    }catch(error){
      toast?.error(error?.response?.data?.message || "failed to submit !!");
    }finally{
      setIsLoading(false);
      handleClose();
    }
  }

  function fillDetails() {
    let inputs = {};
    let pad = updateDetails?.prefill_agreement_details;
    Object.keys(pad).map((item) => {
      if (item === "insurance_period") {
        if (pad?.[item]?.start_date_ms && pad?.[item]?.end_date_ms) {
          inputs[item] = {
            start_date_ms: dayjs(pad?.[item]?.start_date_ms),
            end_date_ms: dayjs(pad?.[item]?.end_date_ms),
          };
        }
      } else if (item === "manufacturing_year" && pad?.[item]) {
        inputs[item] = dayjs(pad?.[item]);
      } else if (item === "sales_invoice_date" && pad?.[item]) {
        inputs[item] = dayjs(pad?.[item]);
      } else if (pad?.[item]?.length) {
        inputs[item] = pad?.[item];
      }
    });

    let details = {
      ...initialState,
      ...inputs,
    };
    setPrifillLoanDetails(details);
  }

  function handlePopupClose(){
    setOpenCnfmSubmit(false);
  }

  useEffect(() => {
    if (open) {
      fillDetails();
    }
  }, [open, updateDetails]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <DialogTitle className="p-0">
          <Grid container style={{ background: "#EFF5FF" }} sx={{ padding: 0 }}>
            <Grid xs={6} className="p-3">
              <p style={{ fontSize: 15, marginBottom: 0 }}>
                PREFILL LOAN AGREEMENT
              </p>
            </Grid>
          </Grid>
          <div
            style={{
              overflow: "auto",
              height: "100%",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: 8,
                top: 8,
                zIndex: 5000,
              }}
            >
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="p-0 py-2">
          <div className="py-2 px-4" style={{ marginBottom: 100 }}>
            <Grid container spacing={2}>
              <Grid xs={7} item sx={labelStyle}>
                Chassis No. <span style={{ color: "red" }}>*</span>
              </Grid>
              <Grid xs={5} item>
                <FilledInput
                  variant="standard"
                  disableUnderline
                  fullWidth
                  // type="number"
                  onWheel={(e) => e.target.blur()}
                  className="editable-select-input input-style2"
                  placeholder=""
                  value={prifillLoanDetails?.chassis_number}
                  onChange={(e) => {
                    setPrifillLoanDetails((prev) => ({
                      ...prev,
                      chassis_number: e.target.value,
                    }));
                  }}
                />
              </Grid>

              <Grid xs={7} item sx={labelStyle}>
                Manufacturing Year <span style={{ color: "red" }}>*</span>
              </Grid>
              <Grid xs={5} item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DatePicker", "DatePicker", "DatePicker"]}
                  >
                    <DatePicker
                      className="editable-select-input input-style2"
                      sx={{ overflow: "hidden" }}
                      views={["year"]}
                      disableUnderline
                      value={prifillLoanDetails?.manufacturing_year}
                      onChange={(date) => {
                        setPrifillLoanDetails((prev) => ({
                          ...prev,
                          manufacturing_year: date,
                        }));
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid xs={7} item sx={labelStyle}>
                Sales Invoice Number
              </Grid>
              <Grid xs={5} item>
                <FilledInput
                  variant="standard"
                  disableUnderline
                  fullWidth
                  // type="number"
                  onWheel={(e) => e.target.blur()}
                  className="editable-select-input input-style2"
                  placeholder=""
                  value={prifillLoanDetails?.sales_invoice_number}
                  onChange={(e) => {
                    setPrifillLoanDetails((prev) => ({
                      ...prev,
                      sales_invoice_number: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid xs={7} item sx={labelStyle}>
                Sales Invoice Date
              </Grid>
              <Grid xs={5} item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DatePicker", "DatePicker", "DatePicker"]}
                  >
                    <DatePicker
                      className="editable-select-input input-style2"
                      sx={{ overflow: "hidden" }}
                      value={prifillLoanDetails?.sales_invoice_date}
                      //   views={["year"]}
                      format="DD/MM/YYYY"
                      disableUnderline
                      onChange={(date) => {
                        setPrifillLoanDetails((prev) => ({
                          ...prev,
                          sales_invoice_date: date,
                        }));
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid xs={7} item sx={labelStyle}>
                Insurance Cost <span style={{ color: "red" }}>*</span>
              </Grid>
              <Grid xs={5} item>
                <FilledInput
                  variant="standard"
                  disableUnderline
                  fullWidth
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  className="editable-select-input input-style2"
                  placeholder=""
                  value={prifillLoanDetails?.insurance_cost}
                  onChange={(e) => {
                    setPrifillLoanDetails((prev) => ({
                      ...prev,
                      insurance_cost: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid xs={7} item sx={labelStyle}>
                Insurance Company Name<span style={{ color: "red" }}>*</span>
              </Grid>
              <Grid xs={5} item>
                <FilledInput
                  variant="standard"
                  disableUnderline
                  fullWidth
                  // type="number"
                  onWheel={(e) => e.target.blur()}
                  className="editable-select-input input-style2"
                  placeholder=""
                  value={prifillLoanDetails?.insurance_company_name}
                  onChange={(e) => {
                    setPrifillLoanDetails((prev) => ({
                      ...prev,
                      insurance_company_name: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid xs={7} item sx={labelStyle}>
                Policy Number<span style={{ color: "red" }}>*</span>
              </Grid>
              <Grid xs={5} item>
                <FilledInput
                  variant="standard"
                  disableUnderline
                  fullWidth
                  // type="number"
                  onWheel={(e) => e.target.blur()}
                  className="editable-select-input input-style2"
                  placeholder=""
                  value={prifillLoanDetails?.policy_number}
                  onChange={(e) => {
                    setPrifillLoanDetails((prev) => ({
                      ...prev,
                      policy_number: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid xs={3.9} item sx={labelStyle}>
                Insurance Period<span style={{ color: "red" }}>*</span>
              </Grid>
              <Grid xs={7} item className="d-flex flex-row align-items-center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      width: "300",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <DemoItem sx={{ width: 200 }}>
                      <DatePicker
                        sx={{ width: 165 }}
                        format="DD/MM/YYYY"
                        className="editable-select-input input-style2"
                        value={
                          prifillLoanDetails?.insurance_period?.start_date_ms
                        }
                        disabled={false}
                        inputVariant="outlined"
                        onChange={(date) => {
                          setPrifillLoanDetails((prev) => ({
                            ...prev,
                            insurance_period: {
                              ...prev?.insurance_period,
                              start_date_ms: date,
                            },
                          }));
                        }}
                        animateYearScrolling
                      />
                    </DemoItem>
                  </Box>
                </LocalizationProvider>
                <span style={{ padding: "0px 10px" }}>To</span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      width: "300",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <DemoItem sx={{ width: 200 }}>
                      <DatePicker
                        sx={{ width: 165 }}
                        format="DD/MM/YYYY"
                        minDate={
                          prifillLoanDetails?.insurance_period?.start_date_ms
                        }
                        className="editable-select-input input-style2"
                        value={
                          prifillLoanDetails?.insurance_period?.end_date_ms
                        }
                        disabled={false}
                        inputVariant="outlined"
                        onChange={(date) => {
                          setPrifillLoanDetails((prev) => ({
                            ...prev,
                            insurance_period: {
                              ...prev?.insurance_period,
                              end_date_ms: date,
                            },
                          }));
                        }}
                        animateYearScrolling
                      />
                    </DemoItem>
                  </Box>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center">
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "40% 60%",
              paddingBottom: 20,
            }}
          >
            <div className="g-col-6"></div>
            <div
              className="g-col-6"
              style={{ display: "grid", gridTemplateColumns: "30% 20% 30%" }}
            >
              <Button
                variant="contained"
                sx={{ fontSize: 12, padding: "10px" }}
                disabled={isSaveEnabled()}
                onClick={handleSave}
              >
                Save
                <SaveIcon sx={{ fontSize: 14, ml: 1 }} />
              </Button>
              <div></div>
              <Button
                variant="contained"
                sx={{ fontSize: 12, padding: "10px" }}
                disabled={isSubmitEnabled()}
                onClick={() => {
                  setOpenCnfmSubmit(true);
                }}
              >
                Submit
                <ArrowForwardIosIcon sx={{ fontSize: 13, ml: 1 }} />
              </Button>
            </div>
          </div>
        </DialogActions>

        {isLoading && (
          <div className="tele-over-loader">
            <CircularProgress />
          </div>
        )}
      </Dialog>
      <Dialog 
        open={openCnfmSubmit}
        onClose={handlePopupClose}
        >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" width={400}>
            Are you sure? Once submitted the data in the loan agreement cannot be changed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePopupClose}>No</Button>
          <Button variant="contained" onClick={handleSubmit}>Submit <ArrowForwardIosIcon sx={{ fontSize: 13, ml: 1 }} /></Button>
        </DialogActions>
      </Dialog>
      <></>
    </>
  );
}

export default LoanEsingDialog;
