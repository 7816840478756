import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  FilledInput,
  Grid,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { updateOtherTransactions } from "../../api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import LoadingOver from "../Loading";
import "./OtherTransactionsPopup.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const labelStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  color: "rgba(0, 0, 0, 0.54)",
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const initTransaction = {
  application_id: null,
  transaction_type: null,
  transaction_utr: "",
  transaction_amount: null,
  transaction_date_ms: null,
};

function OtherTransactionsPopup({ open, collectionData, options, handleClose , setOpenPrevTrans, handlePageChange }) {
  const [transaction, setTransaction] = useState({
    ...initTransaction,
    application_id: collectionData?.application_id,
    transaction_type: collectionData?.collection_type,
  });
  const [loading, setLoading] = useState(null);

  function enableSubmit() {
    if (
      transaction.transaction_utr === "" ||
      !transaction.transaction_amount ||
      !transaction.transaction_date_ms ||
      !transaction.application_id ||
      !transaction.transaction_type
    ) {
      return true;
    }
    return false;
  }

  async function submitTransaction() {
    try {
      if(transaction?.transaction_amount > collectionData?.amount_pending){
        toast?.error("transaction amount should be less than pending amount");
        return;
      }
      setLoading("adding transaction");
      let params = `type=new`;
      let payload = {
        ...transaction,
        transaction_date_ms: dayjs(transaction?.transaction_date_ms).valueOf(),
      };
      const { data } = await updateOtherTransactions(params, payload);
      toast(data?.message || "transaction added successfully!!!");
      handlePageChange();
      handleClose();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(null);
    }
  }

  useEffect(()=>{
    setTransaction({
      ...initTransaction,
      application_id: collectionData?.application_id,
      transaction_type: collectionData?.collection_type,
    });
  },[collectionData])

  useEffect(() => {
    return () => {
      setTransaction({ ...initTransaction });
    };
  }, [open]);

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add transaction
          <p key={collectionData?.customer_name} style={{ fontSize: "16px", fontWeight: "normal" }}>
            {collectionData?.customer_name}
          </p>
          {options?.length!==0 && <Button
            variant="contained"
            sx={{
              background: "#F7F7F7",
              color: "#1F1F1F",
              ":hover": { backgroundColor: "#D0D0D0" },
            }}
            onClick={() => {
              setOpenPrevTrans({
                open: true,
                data: collectionData,
              });
            }}
          >
            View past transaction
          </Button>}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ minWidth: 200 }}>
          <div className="px-4">
            <Grid container spacing={2}>
              <Grid xs={4} sx={labelStyle}>
                Amount pending
              </Grid>
              <Grid xs={4} key={collectionData?.amount_pending} item>
                <FilledInput
                  variant="standard"
                  disableUnderline
                  disabled
                  fullWidth
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  className="editable-select-input input-style2"
                  placeholder=""
                  value={collectionData?.amount_pending}
                />
              </Grid>
              <Grid xs={4}></Grid>
              <Grid xs={4} sx={labelStyle}>
                Transaction date
              </Grid>
              <Grid xs={4} item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="editable-select-input input-style2 z-1"
                    sx={{ overflow: "hidden", width: 168 }}
                    format="DD/MM/YYYY"
                    disableUnderline
                    value={dayjs(transaction?.transaction_date_ms,{format:'DD/MM/yyyy'})}
                    onChange={(date) => {
                      setTransaction((prev) => ({
                        ...prev,
                        transaction_date_ms: dayjs(date).valueOf(),
                      }));
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid xs={4}></Grid>
              <Grid xs={4} sx={labelStyle}>
                Amount received
              </Grid>
              <Grid xs={4} item>
                <FilledInput
                  variant="standard"
                  disableUnderline
                  fullWidth
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  className="editable-select-input input-style2 z-1"
                  placeholder=""
                  value={transaction.transaction_amount}
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "E") {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  onChange={(e) => {
                    setTransaction((prev) => ({
                      ...prev,
                      transaction_amount: +e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid xs={4}></Grid>
              <Grid xs={4} sx={labelStyle}>
                Transaction Id
              </Grid>
              <Grid xs={4} item>
                <FilledInput
                  variant="standard"
                  disableUnderline
                  fullWidth
                  type="text"
                  onWheel={(e) => e.target.blur()}
                  className="editable-select-input input-style2 z-1"
                  placeholder=""
                  value={transaction?.transaction_utr}
                  onChange={(e) => {
                    setTransaction((prev) => ({
                      ...prev,
                      transaction_utr: e.target.value,
                    }));
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", flexDirection: "row-reverse", margin: 1 }}
        >
          <Button
            variant="contained"
            sx={{ borderRadius: "40px" }}
            disableElevation
            disabled={enableSubmit()}
            size="large"
            onClick={submitTransaction}
          >
            Add
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {loading && <LoadingOver text={loading} />}
    </React.Fragment>
  );
}

export default OtherTransactionsPopup;
