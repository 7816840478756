import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import emptyImg from "../../assets/empty.png";

const styles = {
  heading: {
    fontSize: "12px",
    marginRight: "10px",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function GPSBulkUploadStatus({
  successArray,
  failedArray,
  open,
  successCount,
  failedCount,
  handleClose,
  failedCsv,
}) {
  const [value, setValue] = React.useState(0);
  const [isDownloading, setIsDownloading] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDownload = (file, fileName) => {
    setIsDownloading(true);
    const aElement = document.createElement("a");
    aElement.setAttribute("download", fileName);
    aElement.href = file;    // aElement.setAttribute('href', href);
    aElement.setAttribute("target", "_blank");
    aElement.click();
    URL.revokeObjectURL(file);
    setIsDownloading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={`Success (${successCount})`} {...a11yProps(0)} />
              <Tab label={`Failed (${failedCount})`} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {successArray?.length === 0 ? (
              <div
                style={{
                  height: 400,
                  overflowY: "scroll",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src={emptyImg} width="150px" alt="empty" />
                  <p className="text-center" style={{ color: "gray" }}>
                    No Success cases
                  </p>
                </div>
              </div>
            ) : (
              <div style={{ height: 400, overflowY: "scroll" }}>
                {successArray?.map((obj, i) => {
                  return (
                    <div
                      className="d-flex justify-content-between"
                      style={{
                        borderBottom: "1px solid #ccc",
                        padding: "10px 0",
                      }}
                    >
                      <div>
                        <p className="mb-0">
                          <span style={styles.heading}>Dealer Id:</span>
                          {obj?.anchor_id}
                        </p>
                        <p className="mb-0">
                          <span style={styles.heading}>Row number:</span>
                          {obj?.row_number}
                        </p>
                      </div>
                      <div
                        style={{
                          width: "40%",
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{ fontSize: "12px", color: "gray" }}
                        >
                          message:
                        </p>
                        <p
                          className="mb-0"
                          style={{
                            fontSize: "10px",
                            color: "green",
                          }}
                        >
                          {obj?.message}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {failedArray?.length === 0 ? (
              <div
                style={{
                  height: 400,
                  overflowY: "scroll",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src={emptyImg} width="150px" alt="empty" />
                  <p className="text-center" style={{ color: "gray" }}>
                    No failed cases
                  </p>
                </div>
              </div>
            ) : (
              <div style={{ height: 400, overflowY: "scroll" }}>
                {failedArray?.map((obj, i) => {
                  return (
                    <div
                      className="d-flex justify-content-between"
                      style={{
                        borderBottom: "1px solid #ccc",
                        padding: "10px 0",
                      }}
                    >
                      <div>
                        <p className="mb-0">
                          <span style={styles.heading}>Dealer Id:</span>
                          {obj?.anchor_id}
                        </p>
                        <p className="mb-0">
                          <span style={styles.heading}>Row number:</span>
                          {obj?.row_number}
                        </p>
                      </div>
                      <div
                        style={{
                          width: "40%",
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{ fontSize: "12px", color: "gray" }}
                        >
                          Reason:
                        </p>
                        <p
                          className="mb-0"
                          style={{
                            fontSize: "10px",
                            color: "red",
                          }}
                        >
                          {obj?.message}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        {value === 1 && failedCount != 0 && (
          <Button
            onClick={() => {
              handleDownload(failedCsv, "FailedRows.csv");
            }}
            autoFocus
          >
            <FileDownloadIcon /> Download
          </Button>
        )}
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
