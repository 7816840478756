import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  MarkerClusterer,
  Polyline,
} from "@react-google-maps/api";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { mapMarkers } from "../../Constants/icons";
import { getAgentLocations } from "../../api";
import { capitalize } from "../../utility/Formater";
import DirectionSummery from "./DirectionSummery";

const MAX_WAYPOINTS = 23;

function AgentLocations({
  agent_name,
  agent_code,
  date,
  setLocationsLoader,
  locationsLoader,
  locationsState,
  setDistanceTraveled,
  setCustomerLocations,
  LocateCntr
}) {
  const [locateCenter, setLocateCenter] = useState({});
  const [staticLocations, setStaticLocations] = useState([]);
  const [directionData, setDirectionData] = useState({ A: null, B: null });
  const [locationsData, setLocationsData] = locationsState;
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [route, setRoute] = useState(null);
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);
  const [directionSummery, setDirectionSummery] = useState(null);

  const navigate = useNavigate();

  const paricularLocation = LocateCntr
  
  async function fetchAgentLocations(date) {
    try {
      setLocationsLoader(true);
      let params = `${agent_code}/${moment(date).format("DD-MM-YYYY")}`;
      const { data: locationsData } = await getAgentLocations(params);
      const { data } = locationsData || {};
      data?.sort((a, b) => b?.dateMS - a?.dateMS);
      setLocationsData(data);
      const formated_data = formatLocations(locationsData);
      setStaticLocations(formated_data);
      setCustomerLocations(
        formated_data?.filter(
          (itm) =>
            itm?.user_type === "customer" &&
            itm?.location_type === "fi_location" &&
            itm?.lat &&
            itm?.long
        )
      );
      setLocateCenter({
        lat: data?.[0]?.lat,
        lng: data?.[0]?.long,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setLocationsLoader(false);
    }
  }
  
  function handleRedirect(user_type, id) {
    if (user_type === "customer") {
      navigate(`/view-application/${id}`);
      return;
    }
    if (user_type === "dealer") {
      navigate(`/update-anchor-v2/${id}`);
      return;
    }

    if (user_type === "agent") {
      navigate(`/update-agent/${id}`);
      return;
    }
  }

  async function handleMapPath() {
    const batches = [];
    for (let i = 0; i < locationsData.length; i += MAX_WAYPOINTS) {
      batches.push(
        locationsData.slice(
          i,
          Math.min(i + MAX_WAYPOINTS, locationsData.length)
        )
      );
    }
    const allDirections = [];
    for (let i = 0; i < batches?.length; i++) {
      const batch = batches[i];
      const prevBatch = batches[i - 1];
      let start_location =
        i === 0 ? batch[0] : prevBatch[prevBatch?.length - 1];
      const directionsService = new window.google.maps.DirectionsService();
      const origin = new window.google.maps.LatLng(
        start_location.lat,
        start_location.long
      );
      const destination = new window.google.maps.LatLng(
        batch[batch.length - 1].lat,
        batch[batch.length - 1].long
      );
      const travelMode = window.google.maps.TravelMode.DRIVING; // Adjust travel mode as needed

      const waypoints = batch
        .slice(i === 0 ? 1 : 0, batch.length - 1)
        .map((location) => {
          return {
            location: new window.google.maps.LatLng(
              location.lat,
              location.long
            ),
            stopover: true,
          };
        });

      const request = {
        origin: origin,
        destination: destination,
        travelMode: travelMode,
        waypoints: waypoints,
        // optimizeWaypoints: true,
      };

      try {
        const response = await directionsService.route(request);
        if (!response.routes) {
          console.error("API response missing routes:", response);
          continue; // Skip to next batch
        }
        allDirections.push(response);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
    let sum = allDirections?.reduce((curr, items) => {
      return (
        curr +
        items?.routes?.[0]?.legs?.reduce((acc, itm) => {
          return acc + itm?.distance?.value;
        }, 0)
      );
    }, 0);
    setDistanceTraveled((sum || 0) / 1000);
    setDirections(allDirections);
  }

  const calculateDirections = async ({ from, to }) => {
    if (!from || !to) {
      toast.error("Missing origin or destination for directions");
      return;
    }
    const directionsService = new window.google.maps.DirectionsService();
    const travelMode = window.google.maps.TravelMode.DRIVING;
    const directionsRequest = {
      origin: new window.google.maps.LatLng(from.lat, from.long),
      destination: new window.google.maps.LatLng(to.lat, to.long),
      travelMode, // Adjust travel mode as needed (driving, walking, bicycling)
    };
    const response = await directionsService.route(directionsRequest);
    if (response?.routes) {
      setRoute(response);
      let distance = response.routes[0].legs[0].distance.text;
      let duration = response.routes[0].legs[0].duration.text;
      setDirectionSummery({ distance, duration });
    }
    setSelectedMarker(null);
  };

  const handleMarkerClick = (marker) => {
    if (
      selectedMarker?.lat === marker?.lat &&
      selectedMarker?.long === marker?.long
    ) {
      setSelectedMarker(null);
    } else {
      setSelectedMarker(marker);
      if (map) {
        map.panTo(new window.google.maps.LatLng(marker.lat, marker.long));
      }
    }
  };

  const InfoBox = ({ markerData }) => {
    const id =
      markerData?.application_id ?? markerData?.dealer_id ?? agent_code;
    let name =
      markerData?.customer_name ?? markerData?.dealership_name ?? agent_name;
    if (name) {
      name = capitalize(name);
      markerData.name = name;
    }
    const type = markerData?.location_type ?? null;
    const user_type = markerData?.user_type ?? "agent";

    let markerASelected =
      markerData?.lat === directionData?.A?.lat &&
      markerData?.long === directionData?.A?.long;
    let markerBSelected =
      markerData?.lat === directionData?.B?.lat &&
      markerData?.long === directionData?.B?.long;

    return (
      <div className="p-2 d-flex flex-column align-items-start justify-content-center">
        <div className="d-flex flex-column">
          <span className="fw-normal">{capitalize(user_type)}'s Location</span>
          <hr className="m-0 p-0" />
          <span className="fw-semibold mt-1 d-flex flex-row align-items-center justify-content-between">
            <span>Name:</span>
            <span>{name}</span>
          </span>
          <span className="fw-medium d-flex flex-row align-items-center justify-content-between">
            <span>ID:</span>
            <span>{id}</span>
          </span>
          {type && (
            <span className="fw-medium d-flex flex-row align-items-center justify-content-between">
              <span>Location Type:</span>
              <span>{capitalize(type, "_")}</span>
            </span>
          )}
          {markerData?.dateMS && (
            <span className="fw-medium d-flex flex-row align-items-center justify-content-between">
              <span>Updated At:</span>
              <span>{moment(markerData?.dateMS).format("hh:mm:ss A")}</span>
            </span>
          )}
        </div>
        <div
          className="mt-3 d-flex flex-row align-items-center"
          style={{ width: 100 }}
        >
          <IconButton
            color="secondary"
            title="view details"
            className="border me-2"
            onClick={() => {
              handleRedirect(user_type, id);
            }}
          >
            <VisibilityIcon sx={{ fontSize: 12 }} />
          </IconButton>
          <IconButton
            color="secondary"
            sx={{ fontSize: 10, width: 30, height: 30 }}
            title="show direction"
            className={`border me-2 ${
              markerASelected && "bg-secondary text-white"
            }`}
            onClick={() => {
              if (directionSummery && route && markerASelected) {
                setSelectedMarker(null);
              }
              setDirectionData((prev) => ({
                ...prev,
                A: markerASelected ? null : markerData,
              }));
            }}
          >
            A
          </IconButton>
          <IconButton
            color="secondary"
            sx={{ fontSize: 10, width: 30, height: 30 }}
            title="show direction"
            className={`border me-2 ${
              markerBSelected && "bg-secondary text-white"
            }`}
            onClick={() => {
              if (directionSummery && route && markerBSelected) {
                setSelectedMarker(null);
              }
              setDirectionData((prev) => ({
                ...prev,
                B: markerBSelected ? null : markerData,
              }));
            }}
          >
            B
          </IconButton>
          <IconButton
            color="primary"
            title="copy location link"
            className="border"
            onClick={() => {
              navigator.clipboard.writeText(
                `https://www.google.com/maps/search/${markerData?.lat},${markerData?.long}`
              );
              toast("Location Link Copied!");
            }}
          >
            <ContentCopyIcon
              sx={{ transform: "rotateY(180deg)", fontSize: 12 }}
            />
          </IconButton>
        </div>
      </div>
    );
  };

  function handleFullScreenDistance(){
    if (route && directionSummery && document.fullscreenElement) {
      document.fullscreenElement.id = "full-screen-element";
      const div1 = document.createElement("div");
      div1.className =
        "position-absolute bottom-0 left-0 bg-white text-dark border rounded m-2 px-2 full-screen-child";
      div1.style.zIndex = 1000003;
      div1.style.width = "40%";
      const div2 = document.createElement("div");
      div2.className = "d-flex flex-column fs-6 fw-semibold m-2";
      const div3 = document.createElement("div");
      div3.className =
        "d-flex flex-row align-items-center justify-content-between";
      const div4 = document.createElement("div");
      div4.className = "d-flex flex-column align-items-start";

      const span1 = document.createElement("span");
      span1.className = "fs-6 fw-normal text-capitalize";
      span1.innerText = `Distance: ${directionSummery?.distance}  |  Duration: ${directionSummery?.duration}  |  Mode : Driving`;
      const span2 = document.createElement("span");
      span2.className = "py-1";
      span2.innerText = `Location A: ${
        directionData?.A?.name +
        " ( " +
        (directionData?.A?.user_type || "agent") +
        " ) "
      }`;
      const span3 = document.createElement("span");
      span3.innerText = `Location B: ${
        directionData?.B?.name +
        " ( " +
        (directionData?.B?.user_type || "agent") +
        " ) "
      }`;

      const button = document.createElement("button");
      button.className =
        "mt-2 rounded-pill btn btn-primary bg-primary shadow-lg fw-semibold";
      button.innerText = "Reset";
      button.addEventListener("click", (e) => {
        let child = document.getElementsByClassName("full-screen-child");
        if (child) {
          Array.from(child).map((itm) => itm.remove());
        }
        setRoute(null);
        setDirectionData({ A: null, B: null });
        setDirectionSummery(null);
      });

      div4.appendChild(span2);
      div4.appendChild(span3);
      div3.appendChild(div4);
      div3.appendChild(button);
      div2.appendChild(div3);
      div2.appendChild(span1);
      div1.appendChild(div2);
      document.fullscreenElement.appendChild(div1);
    }
  }

  useEffect(() => {
    if (
      map &&
      directionData?.A?.lat &&
      directionData?.A?.long &&
      directionData?.B?.lat &&
      directionData?.B?.long
    ) {
      calculateDirections({
        from: directionData?.A,
        to: directionData?.B,
      });
    } else {
      setRoute(null);
    }
  }, [directionData]);

  useEffect(() => {
    if (map && locationsData?.length) {
      handleMapPath();
    }
  }, [locationsData, map]);
  
  useEffect(() => {
    fetchAgentLocations(new Date(date));
  }, [date]);

  const handleFullScreenChange = () => {
    if(!document.fullscreenElement){
      let child = document.getElementsByClassName("full-screen-child");
      if (child) {
        Array.from(child).map((itm) => itm.remove());
      }
      setRoute(null);
      setDirectionData({ A: null, B: null });
      setDirectionSummery(null);
    }else{
      handleFullScreenDistance();
    }
  };

  useEffect(() => {
    handleFullScreenDistance();
  }, [directionSummery]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
    };
  }, []);

  const { REACT_APP_MAP_KEY } = process.env;  
  return (
    <div>
      <Grid container className="overflow-auto ms-2">
        <Grid xs={12} md={12} sx={{ height: "70vh" }}>
          <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100 border">
            <div className="h-100 w-100 position-relative">
              <LoadScript
                googleMapsApiKey={REACT_APP_MAP_KEY}
                libraries={[
                  "routes",
                  "places",
                  "geocoding",
                  "marker",
                  "streetView", 
                  "core",
                ]}
              >
                {Boolean(locationsData?.length) &&
                locateCenter?.lat &&
                locateCenter?.lng ? (
                  <GoogleMap
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                    center={paricularLocation ? paricularLocation : locateCenter}
                    zoom={paricularLocation ? 30 : 20}
                    onLoad={(map) => setMap(map)}
                    streetView={true}
                  >
                    {!route && (
                      <MarkerClusterer>
                        {(clusterer) => [
                          ...locationsData?.map((location, index) => (
                            <Marker
                              position={{
                                lat: location.lat,
                                lng: location.long,
                              }}
                              label={{
                                text: String(index + 1),
                                color: "white",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                handleMarkerClick(location);
                              }}
                              icon={{ url: mapMarkers[location?.user_type] }}
                            >
                              {selectedMarker?.lat === location?.lat &&
                                selectedMarker?.long === location?.long && (
                                  <InfoWindow
                                    position={selectedMarker.position}
                                    onCloseClick={() => setSelectedMarker(null)}
                                    options={{ disableAutoPan: true }}
                                  >
                                    <InfoBox markerData={selectedMarker} />
                                  </InfoWindow>
                                )}
                            </Marker>
                          )),
                          ...staticLocations?.map((location, index) => (
                            <Marker
                              position={{
                                lat: location.lat,
                                lng: location.long,
                              }}
                              // label={String(index + 1)}
                              onClick={() => {
                                handleMarkerClick(location);
                              }}
                              icon={{ url: mapMarkers[location?.user_type] }}
                            >
                              {selectedMarker?.lat === location?.lat &&
                                selectedMarker?.long === location?.long && (
                                  <InfoWindow
                                    position={selectedMarker.position}
                                    onCloseClick={() => setSelectedMarker(null)}
                                    options={{ disableAutoPan: true }}
                                  >
                                    <InfoBox markerData={selectedMarker} />
                                  </InfoWindow>
                                )}
                            </Marker>
                          )),
                        ]}
                      </MarkerClusterer>
                    )}
                    {!route &&
                      directions?.length &&
                      directions.map((directionsObject, index) => (
                        <ColoredPolyline
                          path={directionsObject.routes[0].overview_path} // Assuming routes are available
                          color="#cc99ff" // Set desired color here
                        />
                      ))}
                    {route && (
                      <DirectionsRenderer
                        options={{
                          directions: route,
                          suppressMarkers: true,
                        }}
                      />
                    )}
                    {route &&
                      directionSummery &&
                      directionData?.A &&
                      directionData?.B &&
                      Object.keys(directionData).map((key, index) => (
                        <Marker
                          position={{
                            lat: directionData[key].lat,
                            lng: directionData[key].long,
                          }}
                          label={{
                            text: String(key),
                            color: "white",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            handleMarkerClick(directionData[key]);
                          }}
                          icon={{
                            url: mapMarkers[
                              directionData[key]?.user_type ?? "agent"
                            ],
                          }}
                        >
                          {selectedMarker?.lat === directionData[key]?.lat &&
                            selectedMarker?.long ===
                              directionData[key]?.long && (
                              <InfoWindow
                                position={selectedMarker.position}
                                onCloseClick={() => setSelectedMarker(null)}
                                options={{ disableAutoPan: true }}
                              >
                                <InfoBox markerData={selectedMarker} />
                              </InfoWindow>
                            )}
                        </Marker>
                      ))}
                    {route && directionSummery && (
                      <DirectionSummery
                        handleReset={() => {
                          setDirectionSummery(null);
                          setRoute(null);
                          setDirectionData({ A: null, B: null });
                        }}
                        directionSummery={directionSummery}
                        directionData={directionData}
                        width={450}
                      />
                    )}
                  </GoogleMap>
                ) : (
                  <div className="w-100 h-100 rounded bg-primary d-flex flex-column align-items-center justify-content-center">
                    <span className="text-center fw-semibold text-center text-light">
                      <div>
                        <LocationOffIcon fontSize="large" />
                      </div>
                      <span className="mt-5 fs-6 text">No Locations</span>
                    </span>
                  </div>
                )}
              </LoadScript>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const ColoredPolyline = ({ path, color }) => {
  return (
    <Polyline
      path={path}
      strokeColor={color}
      strokeWeight={2} // Adjust line thickness
      strokeOpacity={0.7} // Adjust opacity (optional)
    />
  );
};

function formatLocations(locations) {
  let customer_locations = [];

  locations?.customer_locations?.map((customer_data) => {
    const customer_aadhaar_location = customer_data.aadhaar_location;
    const customer_fi_location = customer_data.fi_location;
    const customer_residential_location = customer_data.residential_location;
    const application_id = customer_data.application_id;
    const customer_name = customer_data.customer_name;
    if (customer_aadhaar_location.lat && customer_aadhaar_location.lng) {
      customer_locations.push({
        application_id,
        customer_name,
        user_type: "customer",
        location_type: "aadhaar_location",
        lat: customer_aadhaar_location.lat,
        long: customer_aadhaar_location.lng,
      });
    }

    if (customer_fi_location.lat && customer_fi_location.lng) {
      customer_locations.push({
        application_id,
        customer_name,
        user_type: "customer",
        location_type: "fi_location",
        lat: customer_fi_location.lat,
        long: customer_fi_location.lng,
      });
    }

    if (
      customer_residential_location.lat &&
      customer_residential_location.lng
    ) {
      customer_locations.push({
        application_id,
        customer_name,
        user_type: "customer",
        location_type: "residential_location",
        lat: customer_residential_location.lat,
        long: customer_residential_location.lng,
      });
    }

    return;
  });

  let anchor_locations = [];

  locations?.anchor_locations?.map((anchor_data) => {
    let anchor_details = {
      user_type: "dealer",
      dealer_id: anchor_data.anchor_id,
      dealership_name: anchor_data.dealership_name,
    };

    anchor_data?.locations?.map((item) => {
      if (item.lat && item.lng) {
        anchor_locations.push({
          ...anchor_details,
          lat: item.lat,
          long: item.lng,
        });
      }
    });
  });

  return [...customer_locations, ...anchor_locations];
}

export default AgentLocations;
