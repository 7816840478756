export let anchorDocs = {
  anchor_gstin_pdf: "anchor_gstin_pdf",
  personal_pan: "anchor_personal_pan",
  company_pan_image: "anchor_company_pan",
  trade_license_pdf: "anchor_trade_license",
  shop_image: "anchor_shop_image",
  other_image: "anchor_other_image",
  security_cheques: "anchor_security_cheque",
  bank_statement: "anchor_bank_statement",
  dealer_image: "anchor_dealer_image",
  anchor_agreement: "anchor_agreement",
};
