import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getAllAnchorsIds } from "../../api";
import { toast } from "react-toastify";
import { DebounceInput } from "../AutocompleteCityNames";

function AutoCompleteDealerList({fullWidth, label, onChange, disabled, value}){
    const [inputValue, setInputValue] = useState(value);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectValue, setSelectValue] = useState(null);
  const loaded = useRef(false);

  async function fetchAllDealers() {
    try {
      const {
        data: { data },
      } = await getAllAnchorsIds(inputValue);
      setOptions(data || []);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  const handleDebounce = (value) => {
    setIsLoading(true);
    fetchAllDealers(value);
  };

  return (
    <Autocomplete
      id="dealer-list"
      fullWidth={fullWidth}
      getOptionLabel={(option) => {
        return `${option?.dealership_name || ""}`
      }}
      inputValue={inputValue}
      value={selectValue}
      options={options}
      disabled={disabled}
      autoComplete
      filterSelectedOptions
      loading={isLoading}
      freeSolo={true}
      noOptionsText="No Dealer"
      onChange={(event, newValue) => {
        onChange(event, newValue || {});
        setSelectValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <DebounceInput
          {...params}
          label={label}
          variant="standard"
          fullWidth={fullWidth}
          placeholder="Type in here…"
          debounceTimeout={1000}
          handleDebounce={handleDebounce}
        />
      )}
      renderOption={(props, { dealership_name, name }) => {
        return (
          <li key={dealership_name+name} {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <Typography variant="body2" color="text.secondary">
                  {dealership_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {name}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

export default AutoCompleteDealerList;