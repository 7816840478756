export const stageList = Object.freeze({
  PRE_CREDIT: {
    STATUS: {
      APPLIED: "APPLIED",
      NOT_APPLIED: "NOT_APPLIED",
    },
  },
  CUSTOMER_KYC: {
    STATUS: {
      APPROVED: "APPROVED",
      PENDING: "PENDING",
      REJECTED: "REJECTED",
    },
    SUB_STATUS: {
      APPROVED: {
        AUTO_APPROVED: "AUTO_APPROVED",
      },
      PENDING: {
        CUSTOMER_DETAILS_PENDING: "CUSTOMER_DETAILS_PENDING",
      },
      REJECTED: {
        MANUAL: "MANUAL",
        OTHERS: "OTHERS",
        CUSTOMER_NOT_INTERESTED: "CUSTOMER_NOT_INTERESTED",
        LOAN_AVAILED_FROM_ANOTHER_LENDER: "LOAN_AVAILED_FROM_ANOTHER_LENDER",
        REQUIRED_LOAN_AMOUNT_HIGHER: "REQUIRED_LOAN_AMOUNT_HIGHER",
        CHEQUE_NOT_AVAILABLE: "CHEQUE_NOT_AVAILABLE",
      },
    },
  },
  CREDIT_CHECK_1: {
    STATUS: {
      APPROVED: "APPROVED",
      PENDING: "PENDING",
      REJECTED: "REJECTED",
    },
    SUB_STATUS: {
      APPROVED: {
        AUTO_APPROVED: "AUTO_APPROVED",
        CO_APPLICANT_NEEDED: "CO_APPLICANT_NEEDED",
        MANUALLY_APPROVED: "MANUALLY_APPROVED",
      },
      PENDING: {
        AUTO: "AUTO",
        UNDER_REVIEW: "UNDER_REVIEW",
        SERVER_ERROR: "SERVER_ERROR",
      },
      REJECTED: {
        BAD_BUREAU: "BAD_BUREAU",
        OUTSIDE_GEO_LIMIT: "OUTSIDE_GEO_LIMIT",
        UNDER_AGE: "UNDER_AGE",
        OVER_AGE: "OVER_AGE",

        // MANUAL
        OTHERS: "OTHERS",
        CUSTOMER_NOT_INTERESTED: "CUSTOMER_NOT_INTERESTED",
        LOAN_AVAILED_FROM_ANOTHER_LENDER: "LOAN_AVAILED_FROM_ANOTHER_LENDER",
        REQUIRED_LOAN_AMOUNT_HIGHER: "REQUIRED_LOAN_AMOUNT_HIGHER",
        CHEQUE_NOT_AVAILABLE: "CHEQUE_NOT_AVAILABLE",
      },
    },
  },
  CO_CUSTOMER_KYC: {
    STATUS: {
      APPROVED: "APPROVED",
      REJECTED: "REJECTED",
      PENDING: "PENDING",
    },
    SUB_STATUS: {
      APPROVED: {
        AUTO_APPROVED: "AUTO_APPROVED",
      },
      PENDING: {
        CO_CUSTOMER_DETAILS_PENDING: "CO_CUSTOMER_DETAILS_PENDING",
        SERVER_ERROR: "SERVER_ERROR",
      },
      REJECTED: {
        OTHERS: "OTHERS",
        CO_CUSTOMER_NOT_INTERESTED: "CO_CUSTOMER_NOT_INTERESTED",
      },
    },
  },
  CREDIT_CHECK_2: {
    STATUS: {
      APPROVED: "APPROVED",
      PENDING: "PENDING",
      REJECTED: "REJECTED",
    },
    SUB_STATUS: {
      APPROVED: {
        AUTO_APPROVED: "AUTO_APPROVED",
        MANUALLY_APPROVED: "MANUALLY_APPROVED",
      },
      PENDING: {
        AUTO: "AUTO",
        UNDER_REVIEW: "UNDER_REVIEW",
        SERVER_ERROR: "SERVER_ERROR",
      },
      REJECTED: {
        BAD_BUREAU: "BAD_BUREAU",
        OUTSIDE_GEO_LIMIT: "OUTSIDE_GEO_LIMIT",
        UNDER_AGE: "UNDER_AGE",
        OVER_AGE: "OVER_AGE",

        // MANUAL
        OTHERS: "OTHERS",
        CUSTOMER_NOT_INTERESTED: "CUSTOMER_NOT_INTERESTED",
        LOAN_AVAILED_FROM_ANOTHER_LENDER: "LOAN_AVAILED_FROM_ANOTHER_LENDER",
        REQUIRED_LOAN_AMOUNT_HIGHER: "REQUIRED_LOAN_AMOUNT_HIGHER",
        CHEQUE_NOT_AVAILABLE: "CHEQUE_NOT_AVAILABLE",
      },
    },
  },
  LOAN_DETAILS: {
    STATUS: {
      PENDING: "PENDING",
      COMPLETED: "COMPLETED",
    },
    SUB_STATUS: {
      PENDING: {
        DETAILS_PENDING: "DETAILS_PENDING",
      },
      COMPLETED: {
        DETAILS_COMPLETED: "DETAILS_COMPLETED",
      },
    },
  },
  BANK_DETAILS: {
    STATUS: {
      PENDING: "PENDING",
      COMPLETED: "COMPLETED",
    },
    SUB_STATUS: {
      PENDING: {
        PENNY_DROP_FAILED: "PENNY_DROP_FAILED",
        DETAILS_PENDING: "DETAILS_PENDING",
      },
      COMPLETED: {
        DETAILS_COMPLETED: "DETAILS_COMPLETED",
      },
    },
  },
  NBFC_SELECTION: {
    STATUS: {
      PENDING: "PENDING",
      COMPLETED: "COMPLETED",
    },
    SUB_STATUS: {
      PENDING: {
        NBFC_SELECTION_PENDING: "NBFC_SELECTION_PENDING",
      },
      COMPLETED: {
        NBFC_ASSIGNED: "NBFC_ASSIGNED",
      },
    },
  },
  LOAN_OFFER: {
    STATUS: {
      PENDING: "PENDING",
      APPROVED: "APPROVED",
      REJECTED: "REJECTED",
    },
    SUB_STATUS: {
      PENDING: {
        LOAN_OFFER_PENDING: "LOAN_OFFER_PENDING",
      },
      APPROVED: {
        LOAN_OFFER_APPROVED: "LOAN_OFFER_APPROVED",
      },
      REJECTED: {
        MANUAL: "MANUAL",
        OTHERS: "OTHERS",
        CUSTOMER_NOT_INTERESTED: "CUSTOMER_NOT_INTERESTED",
        LOAN_AVAILED_FROM_ANOTHER_LENDER: "LOAN_AVAILED_FROM_ANOTHER_LENDER",
        REQUIRED_LOAN_AMOUNT_HIGHER: "REQUIRED_LOAN_AMOUNT_HIGHER",
        CHEQUE_NOT_AVAILABLE: "CHEQUE_NOT_AVAILABLE",
        BAD_BUREAU: "BAD_BUREAU",
        OUTSIDE_GEO_LIMIT: "OUTSIDE_GEO_LIMIT",
        UNDER_AGE: "UNDER_AGE",
        OVER_AGE: "OVER_AGE",
      },
    },
  },
  PRE_DISBURSEMENT: {
    STATUS: {
      PENDING: "PENDING",
      APPROVED: "APPROVED",
      REJECTED: "REJECTED",
    },
    SUB_STATUS: {
      PENDING: {
        DETAILS_PENDING: "DETAILS_PENDING",
        APPROVAL_PENDING: "APPROVAL_PENDING",
      },
      APPROVED: {
        MANUAL: "MANUAL",
      },
      REJECTED: {
        MANUAL: "MANUAL",
        MANDATORY_DOCUMENTS_NOT_AVAILABLE: "MANDATORY_DOCUMENTS_NOT_AVAILABLE",
      },
    },
  },
  SENT_TO_NBFC: {
    STATUS: {
      PENDING: "PENDING",
      COMPLETED: "COMPLETED",
    },
    SUB_STATUS: {
      PENDING: {
        SENT_TO_NBFC_PENDING: "SENT_TO_NBFC_PENDING",
      },
      COMPLETED: {
        MANUAL: "MANUAL",
      },
    },
  },
  NBFC_APPROVAL: {
    STATUS: {
      APPROVED: "APPROVED",
      PENDING: "PENDING",
      REJECTED: "REJECTED",
    },
    SUB_STATUS: {
      APPROVED: {
        AUTO: "AUTO",
      },
      PENDING: {
        NBFC_APPROVAL_PENDING: "NBFC_APPROVAL_PENDING",
      },
      REJECTED: {
        OTHERS: "OTHERS",
        RISK_POLICY_NOT_MET: "RISK_POLICY_NOT_MET",
        ISSUE_WITH_RESIDENCE_DOCUMENTS: "ISSUE_WITH_RESIDENCE_DOCUMENTS",
        SIGNATURE_MISMATCH_ISSUE: "SIGNATURE_MISMATCH_ISSUE",
      },
    },
  },
  DISBURSEMENT: {
    STATUS: {
      PENDING: "PENDING",
      APPROVED: "APPROVED",
      REJECTED: "REJECTED",
    },
    SUB_STATUS: {
      PENDING: {
        WAITING_FOR_DISBURSAL: "WAITING_FOR_DISBURSAL",
      },
      APPROVED: {
        DISBURSED: "DISBURSED",
      },
      REJECTED: {
        DISBURSAL_REJECTED_BY_LENDER: "DISBURSAL_REJECTED_BY_LENDER",
      },
    },
  },
  POST_DISBURSEMENT: {
    STATUS: {
      PENDING: "PENDING",
      APPROVED: "APPROVED",
    },
    SUB_STATUS: {
      PENDING: {
        NOT_UPLOADED: "NOT_UPLOADED",
        REJECTED: "REJECTED",
      },
      APPROVED: {
        UPLOADED: "UPLOADED",
      },
    },
  },
  COLLECTION: {
    STATUS: {
      ACTIVE: "ACTIVE",
      CLOSED: "CLOSED",
    },
    SUB_STATUS: {
      ACTIVE: {
        UPCOMING_DUE: "UPCOMING_DUE",
        PARTIALLY_PAID: "PARTIALLY_PAID",
        ADVANCE_PAYMENT: "ADVANCE_PAYMENT",
      },
      CLOSED: {
        FORE_CLOSED: "FORE_CLOSED",
        PAID_FULL: "PAID_FULL",
      },
    },
  },
});

export const stageBeforeCo = [
  "CO_CUSTOMER_CREATION",
  "CUSTOMER_ONBOARDING",
  "CUSTOMER_BASIC_DETAILS",
  "CUSTOMER_EMBIFI_REJECTED",
  "CUSTOMER_CREDIT_ENGINE_FAILED",
  "CUSTOMER_UNDER_REVIEW",
];

export const getCreationStage = (data) => {
  if (data?.creation_stage === "CUSTOMER_ONBOARDING") {
    return data?.customerData?.creation_stage || "";
  } else if (data?.creation_stage === "CO_CUSTOMER_ONBOARDING") {
    return data?.coCustomerData?.creation_stage || "";
  } else {
    return data?.creation_stage || "";
  }
};

export const usersMail = [
  "sanjay.rana@embifi.in",
  "shiraj.khan@embifi.in",
  "ashish.kumar@embifi.in",
];

export const salesAgents = [
  "akanksha.kumari@embifi.in",
  "anuradha.sharma@embifi.in",
  "preeti.jaiswal@embifi.in",
  "vivek.kumar@embifi.in",
  "siddharth.taparia@embifi.in",
];
