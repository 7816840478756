import Mixpanel from "mixpanel-browser";

Mixpanel.init("a7c8ddd19cd073483179b247f218cc4c", { debug: true });

// Export a function to track events
export const trackEvent = (eventName, properties = {}) => {
  Mixpanel.track(eventName, properties);
};

export const identifyUser = (distinctId) => {
  Mixpanel.identify(distinctId);
};
