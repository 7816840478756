import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { editTransaction, editTransactionV2 } from "../../../api";
import { UserContext } from "../../../Context/UserContext"; 
import { isBlank } from "../../../utility/validations";
import { f } from "../../../utility/Formater";

const UpdateCollectionV2 = ({
  open,
  handleClose,
  initalState,
  onSubmit,
  isTransaction,
  applicationId,
  isNew,
  isTransactionHistory,
  collectedHoldBackLeftToSettle
}) => {
  const errorsIntial = {
    amount: "",
    date: "",
  };

  const { user } = useContext(UserContext);
  const [buttonVisibility, setButtonVisibility] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  const [collectionData, setCollectionData] = useState(initalState);
  const [errors, setError] = useState(errorsIntial);
  const [isLoading, setLoading] = useState(false);
  const [isOverLoading, setOverLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [holdBackVisibility, setHoldBackVisibility] = useState(false);

  const dateConvertor = (date) => {
    let splitDate = date.split("/");
    let formatted = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`;
    return Number(new Date(formatted));
  };

  const handleChange = (prop) => (event) => {
    setCollectionData({
      ...collectionData,
      [prop]: event.target.value,
    });
    if (prop == "amount_required_to_settle") {
      setError({
        ...errors,
        settlement_target: Number(collectionData?.payment_amount) >
        Number(event.target.value) ? 
        "Payment amount cannot be greater than settlement target" : ""
      })
    }
    if (prop == "payment_amount") {
      if (holdBackVisibility) {
        setError({
          ...errors,
          amount: Number(event.target.value) >
          Number(collectionData?.collectedHoldBackLeftToSettle || 0) ?
          "Payment amount cannot be greater than collected hold back" : "",
          settlement_target: Number(event.target.value) >
          Number(collectionData?.amount_required_to_settle) ? 
          "Payment amount cannot be greater than settlement target" : ""
        })
      }
    }
    if (prop == "payment_mode" && event.target.value == "HOLD BACK") {
      setHoldBackVisibility(true);
      setError({
        ...errors,
        amount: Number(collectionData?.payment_amount) >
        Number(collectionData?.collectedHoldBackLeftToSettle || 0) ?
        "Payment amount cannot be greater than collected hold back" : "",
        settlement_target: Number(collectionData?.payment_amount) >
        Number(collectionData?.amount_required_to_settle) ? 
        "Payment amount cannot be greater than settlement target" : ""
      })
    } else if (prop == "payment_mode" && event.target.value != "HOLD BACK") {
      setHoldBackVisibility(false);
      setError({
        ...errors,
        amount: "",
        settlement_target: ""
      })
    }
  };

  const formatDate = (dateString) => {
    var dateParts = dateString.split("/");
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  };


  const isValid = () => {
    let valid = true;
    let errorObj = {
      amount: collectionData?.payment_amount === "" ?  "Required field" : "",
      date: isNaN(dateConvertor(collectionData?.transaction_date))
        ? "Required field"
        : "",
      txn_number: ["UPI", "PAYMENT_LINK", "NACH","PDC/Cheques","NEFT"].includes(
        collectionData?.payment_mode
      )
        ? isBlank(collectionData?.transaction_id)
          ? "Transaction number is mandatory"
          : ""
        : "",
    };

    // console.log(errorObj);

    setError({
      ...errors,
      ...errorObj
    });

    for (let key in errorObj) {
      if (errorObj[key].length > 0) {
        return false;
      }
    }

    for (let key in errors) {
      if (errors[key].length > 0) {
        return false;
      }
    }

    return valid;
  };

  useEffect(() => {
    setCollectionData({
      ...initalState,
      collectedHoldBackLeftToSettle: Number(collectedHoldBackLeftToSettle)+Number(initalState?.payment_amount)
    });
    if(initalState?.payment_mode == "HOLD BACK"){
      setHoldBackVisibility(true)
    }
    else{
      setHoldBackVisibility(false)
    }
  }, [initalState]);

  useEffect(() => {
    if (!(Number(user?.role) === 1) && collectionData?.transaction_date ) {
      // transaction details
      let transactionDateIST = formatDate(collectionData?.transaction_date)
      let transactionMonth = new Date(transactionDateIST).getMonth() + 1
      let transactionDate = new Date(transactionDateIST).getDate()

      // today details
      let currentMonth = new Date().getMonth() + 1
      let currentDate = new Date().getDate()

      // Remove delete and edit functionality in a transaction for the last month also, after 2nd day of current month for non admin users
      if (Number(currentMonth) === Number(transactionMonth)) {
        setButtonVisibility(true)
      } else if (currentDate > 2) {
        setButtonVisibility(false);
      } else {
        setButtonVisibility(false)
      }
    } else {
      // for admin
      setButtonVisibility(true);
    }
  }, [collectionData]);

  const handleSubmit = async () => {
    if(!isValid()) return;
    setLoading(true);
    try {
      let dateChanged = false;

      if (!(collectionData?.defaultDate === collectionData?.transaction_date)) {
        dateChanged = true;
      }

      let payload = {
        transactionId: collectionData?.transactionId,
        action: "UPDATE",
        data: {
          transaction_id: collectionData?.transaction_id,
          payment_mode: collectionData?.payment_mode,
          transaction_amount: Number(collectionData?.payment_amount),
          transaction_dateMS: dateConvertor(collectionData?.transaction_date),
          transaction_utr: collectionData?.transaction_utr,
          dateChanged,
          is_edited: true,
          amount_required_to_settle: collectionData?.amount_required_to_settle,
        },
      };

      await onSubmit(payload);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error while updating transactions"
      );
    }
    setLoading(false);
  };

  const handleDelete = async (data, type) => {
    setOpenDelete(false);
    try {
      let payload = {
        scheduleId: collectionData?.scheduleId,
        transactionId: collectionData?.transactionId,
        action: "DELETE",
        data: {},
      };

      const { data: response } = await editTransactionV2(
        payload,
        applicationId
      );

      if (response?.status) {
        toast.success("Transaction Deleted successfully");
        handleClose(true, collectionData?.origin === "transactions");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (!open) {
      setEdit(false);
    }
  }, [open]);

  return (
    <>
      <div>
        <Drawer
          anchor={"right"}
          open={open}
          onClose={() => {
            handleClose();
            setEdit(false);
          }}
        >
          <div
            style={{
              width: "500px",
              padding: "25px",
              height: "100%",
              position: "relative",
            }}
          >
            {initalState?.isEditabled && (
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
              >
                {(!isEdit && buttonVisibility) && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEdit(true);
                    }}
                  >
                    <EditIcon sx={{ fontSize: 15, mr: 1 }} />
                    Edit
                  </div>
                )}
                {buttonVisibility && (
                  <DeleteIcon
                    sx={{ fontSize: 20, ml: 1, mt: 0.2, cursor: "pointer" }}
                    onClick={() => setOpenDelete(true)}
                  />
                )}
              </div>
            )}
            <div className="w-100">
              <p className="more-tools-heading mb-4">Collection Details</p>
              <Grid container spacing={3} className="mt-3">
                <Grid item xs={12} sm={12}>
                  <div className="paycard">
                    {isEdit ? (
                      <TextField
                        required
                        id="amount"
                        name="amount"
                        label="Payment Amount"
                        fullWidth
                        autoComplete="given-name"
                        variant="standard"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        // inputProps={{ maxLength: 10 }}
                        value={collectionData?.payment_amount || ""}
                        onChange={handleChange("payment_amount")}
                        error={errors?.amount}
                        helperText={errors?.amount}
                      />
                    ) : (
                      <p className="paycard-mode">
                        <span>Transaction Amount: </span>₹{" "}
                        {collectionData?.payment_amount || "---"}
                      </p>
                    )}
                  </div>
                  {isEdit ? (
                    <FormControl
                      variant="standard"
                      sx={{ width: "100%", marginTop: "15px" }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Payment mode
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        defaultValue={collectionData?.payment_mode}
                        onChange={handleChange("payment_mode")}
                      >
                        <MenuItem value={"Cash"}>Cash</MenuItem>
                        <MenuItem value={"UPI"}>UPI</MenuItem>
                        <MenuItem value={"NACH"}>NACH</MenuItem>
                        <MenuItem value={"PAYMENT_LINK"}>Payment Link</MenuItem>
                        <MenuItem value={"PDC/Cheques"}>PDC/Cheques</MenuItem>
                        <MenuItem value={"NEFT"}>NEFT</MenuItem>
                        <MenuItem value={"HOLD BACK"}>HOLD BACK</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <p className="paycard-mode">
                      <span>Payment Mode: </span>{" "}
                      {collectionData?.payment_mode || "---"}
                    </p>
                  )}
                  {
                    holdBackVisibility && isEdit && (
                      <TextField
                        required
                        id="settlement_target"
                        name="settlement_target"
                        label="Settlement Target"
                        fullWidth
                        autoComplete="given-name"
                        variant="standard"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        // inputProps={{ maxLength: 10 }}
                        value={collectionData?.amount_required_to_settle || ""}
                        onChange={handleChange("amount_required_to_settle")}
                        error={errors?.settlement_target}
                        helperText={errors?.settlement_target}
                      />
                    )
                  }
                  {
                    holdBackVisibility && !isEdit && (
                      <p className="paycard-mode">
                        <span>Settlement Target: </span>{" "}
                        {collectionData?.amount_required_to_settle || "---"}
                      </p>
                    )
                  }
                  {isEdit ? (
                    <TextField
                      className="mt-2"
                      id="id_ref"
                      name="id_ref"
                      label={`Transaction ID/ Ref Number${["Cash"].includes(
                        collectionData?.payment_mode
                      ) ? "" : "*"}`}
                      fullWidth
                      autoComplete="given-name"
                      variant="standard"
                      value={collectionData?.transaction_id || ""}
                      onChange={handleChange("transaction_id")}
                      error={errors?.txn_number}
                      helperText={errors?.txn_number}
                    />
                  ) : (
                    <p className="paycard-mode">
                      <span>Transaction ID: </span>{" "}
                      {collectionData?.transaction_id || "---"}
                    </p>
                  )}
                  {isEdit ? (
                    <TextField
                      // require
                      className="mt-2"
                      id="utr"
                      name="utr"
                      label="Transaction UTR"
                      fullWidth
                      autoComplete="given-name"
                      variant="standard"
                      value={collectionData?.transaction_utr || ""}
                      onChange={handleChange("transaction_utr")}
                    />
                  ) : (
                    <p className="paycard-mode">
                      <span>UTR: </span>{" "}
                      {collectionData?.transaction_utr || "---"}
                    </p>
                  )}

                  {isEdit ? (
                    <DatePicker
                      className="w-100 mt-2"
                      label="Transaction date"
                      format="dd/MM/yyyy"
                      // maxDate={new Date()}
                      //   value={ new Date()}
                      value={formatDate(collectionData?.transaction_date)}
                      onChange={(date) => {
                        setCollectionData({
                          ...collectionData,
                          transaction_date: moment(date).format("DD/MM/YYYY"),
                        });
                      }}
                      animateYearScrolling
                    />
                  ) : (
                    <p className="paycard-mode">
                      <span>Transaction Date: </span>{" "}
                      {collectionData?.transaction_date || "---"}
                    </p>
                  )}
                  {holdBackVisibility && (
                    <p style={{
                      paddingTop : "15px"
                    }}>
                      <span className="list-key">
                        Total Collected Hold Back:
                      </span>{" "}
                      {f(collectionData?.collectedHoldBackLeftToSettle, "cur")
                      }
                    </p>
                  )}

                  {isEdit && (
                    <div
                      className="mt-2"
                      style={{
                        position: "absolute",
                        bottom: "20px",
                        right: "20px",
                      }}
                    >
                      <Button
                        sx={{ color: "white" }}
                        className="pay-btn mt-4"
                        onClick={handleSubmit}
                        disabled={
                          isLoading || collectionData?.payment_amount == 0
                        }
                      >
                        {isLoading ? "Loading..." : `SUBMIT`}
                      </Button>
                      <Button
                        sx={{ color: "purple" }}
                        className="mt-4 me-2"
                        onClick={() => {
                          setEdit(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </Drawer>
      </div>

      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>Are you sure to delete this collection?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
          <Button onClick={() => handleDelete()} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateCollectionV2;
