// import * as React from "react";
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { Autocomplete } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@mui/material";
import { GlobalContext } from "../../Context/GlobalContext";
import { fetchLatLng } from "../../api";
import SelectCity from "./SelectCity";

export default function CoApplicant({ setActiveStep }) {
  const { anchorState } = React.useContext(GlobalContext);
  const [anchorDetails, setAnchorDetails] = anchorState;
  const [mobile, setMobile] = useState([{ mobileNo: "" }]);
  const [address, setAddress] = useState([
    // { pincode: "", address: "", lat: "", lon: "", country: "", state: "", city: "" },
    { pincode: "", address: "",country: "", state: "", city: "" },
  ]);

  useEffect(() => {
    let mobileArray = [];
    mobile.forEach((x) => {
      mobileArray.push(x.mobileNo);
    });
    setAnchorDetails({
      ...anchorDetails,
      mobile: mobileArray,
    });
  }, [mobile]);

  useEffect(() => {
   const addressTemp =  address.map((addr)=> {
      let addrTemp = addr;
      // addrTemp = {...addrTemp, lat: addr?.city?.latitude || "", lon: addr?.city?.longitude || "", city: addr?.city?.name || "", country: addr?.country?.name || "", state: addr?.state?.name || ""}
      addrTemp = {...addrTemp, city: addr?.city?.name || "", country: addr?.country?.name || "", state: addr?.state?.name || ""}
      addr = addrTemp;
      // console.log(addr)
      return addr
    })
    setAnchorDetails({
      ...anchorDetails,
      address: addressTemp,
    });
  }, [address]);

  useEffect(() => {
    // console.log(anchorDetails?.address)
  }, [anchorDetails.address]);


  const handleMobileNumber = (event, index) => {
    let { value } = event.target;
    let temp = [...mobile];
    temp[index].mobileNo = value;
    setMobile(temp);
  };

  const handleAddress = (event, index) => {
    let { value } = event.target;
    let temp = [...address];
    temp[index].address = value;
    setAddress(temp);
  };

  // const handleSelect = async (e, index) => {
  //   console.log(e, index);
  //   let response = await geocodeByAddress(e).then((results) => { 
  //     console.log(results)
  //     return getLatLng(results[0])});
  //   console.log(response)
  //   let temp = [...address];
  //   temp[index].lat = response.lat
  //   temp[index].lon = response.lng
  //   setAddress(temp);
  // };

  const handleAddrAddClick = (e) => {
    e.preventDefault();
    // setAddress([...address, { pincode: "", address: "", lat: "", lon: "", country: "", state: "", city: ""  }]);
    setAddress([...address, { pincode: "", address: "", country: "", state: "", city: ""  }]);
  };

  const handleMobAddClick = (e) => {
    e.preventDefault();
    setMobile([...mobile, { mobileNo: "" }]);
  };

  const handleMobDelClick = (event, index) => {
    // e.preventDefault();
    if (mobile.length === 1) {
      return;
    }
    const value = mobile[index];
    setMobile((current) => current.filter((item) => item !== value));
  };

  const handleAddrDelClick = (event, index) => {
    // e.preventDefault();
    if (address.length === 1) {
      return;
    }
    const value = address[index];
    setAddress((current) => current.filter((item) => item !== value));
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <>
          {/* <Grid item xs={12} sm={6}> */}
          {mobile.map((c, index) => {
            return (
              <Grid item xs={12} sm={6}>
                <TextField
                  key={index}
                  // required
                  fullWidth
                  variant="standard"
                  sx={{ mt: 2 }}
                  size="small"
                  id="phone"
                  label="Mobile no."
                  type="number"
                  value={c?.mobileNo}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={(event) => {
                          handleMobDelClick(event, index);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                  onChange={(event) => {
                    if (event.target.value.length > 10) {
                      return;
                    } else {
                      handleMobileNumber(event, index);
                    }
                  }}
                />
              </Grid>
            );
          })}
          {/* </Grid> */}

          <Grid
            style={{
              marginTop: "36px",
            }}
            item
            xs={12}
            sm={6}
          >
            <Button
              style={{
                borderRadius: "10px",
                backgroundColor: "#6C757D",
                color: "white",
                ":hover": {
                  bgcolor: "#6F32BE",
                  color: "white",
                },
              }}
              onClick={handleMobAddClick}
            >
              + add
            </Button>
          </Grid>
          <Grid item xs={12}>
            {address.map((c, index) => {
              return (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={9}>
                    <TextField
                      id="address"
                      name="address"
                      label="Address"
                      fullWidth
                      variant="standard"
                      value={c?.address}
                      onChange={(event) => {
                        let { value } = event.target;
                        let temp = [...address];
                        temp[index].address = value;
                        setAddress(temp);
                      }}
                    />
                  </Grid>
                  <Grid
                    sty={{
                      paddingBottom: '0px !important',
                    }} item xs={12} sm={3}>

                    {/* <PlacesAutocomplete

                      value={c?.pincode}
                      onChange={(event) => {
                        console.log(event)
                        let temp = [...address];
                        temp[index].pincode = event;
                        setAddress(temp);
                      }}
                      onSelect={(e) => { handleSelect(e, index) }}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <TextField
                            id="pincode"
                            name="pincode"
                            type={"number"}
                            label="Pincode"
                            fullWidth
                            variant="standard"
                            {...getInputProps({
                              className: 'location-search-input',
                            })} />
                          <div style={{
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            maxHeight: "300px",
                            overflow: "auto"
                          }} className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete> */}

                    <TextField
                      id="pincode"
                      name="pincode"
                      label="Pincode"
                      fullWidth
                      variant="standard"
                      value={c?.pincode}
                      onChange={async (event) => {
                        let { value } = event.target;
                        let temp = [...address];
                        temp[index].pincode = value;
                        setAddress(temp);
                        // if (value.length > 5 && value.length <= 7) {
                        //   const { data } = await fetchLatLng(value);
                        //   if (data?.status) {
                        //     temp[index].lat =
                        //       data?.data[0]?.latitude?.toString();
                        //     temp[index].lon =
                        //       data?.data[0]?.longitude?.toString();
                        //   }
                        // }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SelectCity c={c} index={index} address={address} setAddress={setAddress}
                      // id="address"
                      // name="address"
                      // label="Address"
                      // fullWidth
                      // variant="standard"
                      // value={c?.address}
                      // onChange={(event) => {
                      //   let { value } = event.target;
                      //   let temp = [...address];
                      //   temp[index].address = value;
                      //   setAddress(temp);
                      // }}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{
                borderRadius: "10px",
                backgroundColor: "#6C757D",
                color: "white",
                ":hover": {
                  bgcolor: "#6F32BE",
                  color: "white",
                },
              }}
              onClick={handleAddrAddClick}
            >
              + add
            </Button>
          </Grid>
        </>
      </Grid>
    </React.Fragment>
  );
}
