import React,{ useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import Input from '@mui/material/Input';
import { styled } from '@mui/system';
import { toast } from "react-toastify";
import CheckIcon from '@mui/icons-material/Check';
import { isVisible } from "@testing-library/user-event/dist/utils";

const DdValMap = {
  1: { label: "Active", color: "#2D8925" },
  null: { label: "Active", color: "#2D8925" },
  2: { label: "Blacklisted", color: "#EC6E12" },
};

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  return (
    <Input
      slots={{ input: StyledInputElement, textarea: StyledTextareaElement }}
      {...props}
      ref={ref}
      minRows={3}
    />
  );
});



const blackList = ["Collection Issue","RC Issue","GPS Issue","OEM Issue","Not Getting Files","Other Issue"]


export default function AnchorStatusDropdown(props) {
  const [ddValue, setDdValue] = useState({ label: "", value: "" });
  const [isBlocked, setIsBlocked] = useState(false);
  const [isOtherReason, setIsOtherReason] = useState(false);
  const [inputText, setInputText] = useState('');
  const [isListVisible, setListVisible] = useState(false);

  const listRef = useRef(null);

  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      setListVisible(false);
      setIsBlocked(false);
      setIsOtherReason(false);
    }
  };

  const handleChange = (val,reason) => {
    setDdValue({ label: DdValMap[val]?.label, value: val });
    props?.onChange(val,reason);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!props?.isNormal) {
      setDdValue({ label: DdValMap[props?.value]?.label, value: props?.value });
    }
  }, [props]);

  useEffect(()=>{
    setDdValue({ label: DdValMap[props?.value]?.label, value: props?.value });
  },[])


  return (
    <div className="position-relative">
      {( !isListVisible && !isBlocked )? <div className="dropdown position-relative" >
        <button
          className="btn dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{
            backgroundColor: "#fff",
            border: "1px solid grey",
            fontSize: "0.8em",
            color: props?.value ? DdValMap[props?.value]?.color : DdValMap[1]?.color ,
          }}
        >
            {props?.value ? DdValMap[props?.value]?.label : DdValMap[1]?.label}
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          { props?.ddList?.map((val, ind) => {
            return (
              <li key={ind}>
                <spansetIs
                  className="dropdSubmitown-item pointer"
                  style={{
                    fontSize: "0.8em", 
                    color:  DdValMap[val]?.color ,
                    padding:'0px 10px',
                    display:'grid',
                    gridTemplateColumns:"80% 20%"
                  }}
                  onClick={() => {
                    if(val===2){
                        setIsBlocked(true);
                        setListVisible(true);
                    }else 
                        handleChange(val,'');
                  }}
                >
                    <p>{ val ? DdValMap[val]?.label : DdValMap[1]?.label}</p>
                    {(props?.value===val ) && <CheckIcon/> }
                </spansetIs>
              </li>
            );
          })
          }
        </ul>
        </div> :
        isBlocked && isOtherReason ?
          <div ref={listRef} className="position-absolute d-flex flex-column p-3 dropdown" style={{zIndex:2,background:"#fff",borderRadius:8,border:"1px solid black"}} >
            <label>Others</label>
            <CustomInput aria-label="Demo input" multiline placeholder="Type something…" onChange={(e)=>{
              setInputText(e.target.value);
            }} />
            <div className="d-flex flex-row-reverse p-1">
                <Button
                  onClick={()=>{
                    if(!!inputText){
                      setIsBlocked(false);
                      setIsOtherReason(false);
                      handleChange(2,inputText);
                    }else{
                      toast.info("Enter Some Reason");
                    }
                  }}
                >send</Button>
            </div>
          </div>
        :
        isListVisible &&  <div>
            <ul  ref={listRef}  class="list-group position-absolute" style={{zIndex:2}} >
                {blackList.map((item,i)=>{
                    return <li class="list-group-item" key={i}>
                        <span
                            className="dropdown-item-text"
                            style={{ fontSize: "0.8em", color: "#000", cursor:"pointer",textAlign:'center' }} 
                            onClick={() => {
                              if(item==='Other Issue'){
                                setIsOtherReason(true);
                              }else{
                                handleChange(2,item);
                                setIsBlocked(false);
                                setListVisible(false);
                              }
                            }}
                        >
                            {item}
                        </span>
                    </li>
                    })
                }
            </ul>
        </div>
        }
    </div>
  );
}



const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
};

const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const StyledInputElement = styled('input')(
  ({ theme }) => `
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const StyledTextareaElement = styled('textarea', {
  shouldForwardProp: (prop) =>
    !['ownerState', 'minRows', 'maxRows'].includes(prop.toString()),
})(
  ({ theme }) => `
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);