import {
  Autocomplete,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import React, { useContext, useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { fetchAllDealers } from "../../../api";
import useAgent from "../../../hooks/useAgent";
import { GlobalContext } from "../../../Context/GlobalContext";
import { useLocation } from "react-router";
import AutocompleteCityNames from "../../../Components/AutocompleteCityNames"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AgentDetails({ isDisabled }) {
  const dealerRef = useRef(null);
  const { state } = useLocation();
  const { agentDetailState } = useContext(GlobalContext);
  const [agentDetails, setAgentDetails] = agentDetailState;
  const [falseEmail, setFalseEmail] = useState(false);
  const { selectedDealers, setSelectedDealers, dealersList, fetchDealers } =
    useAgent();
  const [onGround, setOnGround] = useState(false);
  const [departments, setDepartments] = useState(agentDetails?.department || []);

  const emailValidation = () => {
    if (agentDetails?.email === "") {
      setFalseEmail(true);
      return true;
    }

    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(agentDetails?.email) === false) {
      setFalseEmail(true);
      return false;
    } else {
      setFalseEmail(false);
      return true;
    }
  };

  const handleDealerDelete = (index) => {
    let tempArr = selectedDealers;
    tempArr?.splice(index, 1);
    setSelectedDealers([...tempArr]);
  };

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setSelectedDealers(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };
  // const handleChange = (event, value) => {
  //   console.log("value -> ",value);
  //   // setSelectedDealers((prev) => [...prev, val]);
  // };

  useEffect(() => {
    let arr = [];
    selectedDealers.forEach((x) => {
      let obj = {
        anchor_id: x,
      };
      arr.push(obj);
    });
    setAgentDetails({
      ...agentDetails,
      anchors: arr,
    });
    if (selectedDealers?.length > 0 && state?.scroll === "dealer") {
      if (dealerRef.current) {
        dealerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  }, [selectedDealers]);

  useEffect(() => {
    if (agentDetails?.email !== "" && !isDisabled) {
      emailValidation();
    } else if (agentDetails?.email === "") {
      setFalseEmail(false);
    }
  }, [agentDetails?.email]);

  useEffect(() => {
    fetchDealers();
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: {
              sm: "row",
              xs: "column",
            },
            gap: {
              sm: "35px",
              xs: "0px",
            },
          }}
        >
          <TextField
            required
            id="text"
            fullWidth
            variant="standard"
            label="Name"
            name="name"
            value={agentDetails?.name}
            onChange={(e) => {
              setAgentDetails({
                ...agentDetails,
                name: e.target.value,
              });
            }}
            disabled={isDisabled}
            sx={{
              mt: 2,
            }}
          />

          <TextField
            // required
            variant="standard"
            fullWidth
            sx={{ mt: 2 }}
            id="email"
            label={falseEmail ? "Email id is not valid" : "Email Id"}
            error={falseEmail && agentDetails?.email !== "" ? true : false}
            name="email"
            autoComplete="email"
            value={agentDetails?.email}
            onChange={(e) => {
              setAgentDetails({
                ...agentDetails,
                email: e.target.value,
              });
            }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid xs={12} 
        item 
        className="mt-4"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            sm: "row",
            xs: "column",
          },
          gap: {
            sm: "35px",
            xs: "0px",
          },
        }}>
          <Autocomplete
            fullWidth
            autoHighlight
            id="combo-box-demo"
            options={dealersList}
            getOptionLabel={(option) => option.name ?? ""}
            value={selectedDealers[selectedDealers?.length - 1]}
            disabled={isDisabled}
            renderInput={(params) => (
              <TextField {...params} label="Dealers *" />
            )}
            renderOption={(props, option) => (
              <MenuItem
                key={option?._id}
                value={option?._id}
                onClick={() => {
                  if (selectedDealers?.includes(option?._id)) {
                    setSelectedDealers((prev) =>
                      prev?.filter((item) => item !== option?._id)
                    );
                  } else {
                    setSelectedDealers((prev) => [...prev, option?._id]);
                  }
                }}
              >
                <Checkbox checked={selectedDealers.indexOf(option?._id) > -1} />
                <ListItemText primary={option?.name} />
              </MenuItem>
            )}
          />
          {isDisabled ? (
            <TextField 
            disabled
            fullWidth={true}
            label="City"
            defaultValue={agentDetails?.city}
            
             />
          ) : (
          <AutocompleteCityNames
            // required
            fullWidth={true}
            label={agentDetails?.city ? `City - ${agentDetails.city}` : "City"}
            value={agentDetails?.city}
            name="city"
            onChange={(e, {city}) => {
              setAgentDetails({
                ...agentDetails,
                city: city,
              })
            }}
          />)}
          
        </Grid>
        <Grid xs={6}></Grid>

        <Grid item xs={8} ref={dealerRef}>
          {selectedDealers?.map((value, index) => {
            return (
              <div
                className="view-card-container mt-2 d-flex align-items-center"
                key={index}
              >
                <span
                  className="view-card"
                  style={{ color: isDisabled ? "#9E9E9E" : "#000" }}
                >
                  {dealersList?.filter((x) => x._id === value)?.[0]?.name}
                </span>
                <IconButton
                  disabled={isDisabled}
                  style={{
                    display: "grid",
                    placeItems: "center",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <CloseIcon onClick={() => handleDealerDelete(index)} />
                </IconButton>
              </div>
            );
          })}
        </Grid>

        <Grid xs={12} 
        item 
        className="mt-4"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            sm: "row",
            xs: "column",
          },
          gap: {
            sm: "35px",
            xs: "0px",
          },
        }}>
          <TextField
            // key={index}
            // required
            variant="standard"
            fullWidth
            sx={{ mt: 2 }}
            label={
              agentDetails?.mobile_number?.[0]?.length !== 10 &&
              agentDetails?.mobile_number?.[0]?.length > 1
                ? "Mobile no. is not of 10 digits"
                : "Mobile no."
            }
            error={
              agentDetails?.mobile_number?.[0]?.length !== 10 &&
              agentDetails?.mobile_number?.[0]?.length > 1
                ? true
                : false
            }
            id="phone"
            // label="Mobile no."
            type="number"
            required
            value={agentDetails?.mobile_number?.[0]}
            onChange={(e) => {
              if (e.target.value.length > 10) {
                return;
              }
              let a = [e.target.value];
              setAgentDetails({
                ...agentDetails,
                mobile_number: a,
              });
            }}
            disabled={isDisabled}
          />
         <FormControl fullWidth>
  <InputLabel sx={{ mt: 2 }} id="demo-multiple-checkbox-label">Department</InputLabel>
  <Select
    // required
    labelId="demo-multiple-checkbox-label"
    id="demo-multiple-checkbox"
    multiple
    value={agentDetails?.department || []}
    onChange={(event) => {
      let { value } = event.target;
      value = value.filter((item) => item !== "");
      // console.log("Selected Departments:", value);
      setDepartments(value);
      setAgentDetails({
        ...agentDetails,
        department: value,
      });
    }}
    disabled={isDisabled}
    input={<OutlinedInput label="Department" sx={{ mt: 2 }} />}
    renderValue={(selected) => 
      selected.map((item, index) => (index > 0 ? `, ${item}` : item)).join("") // Conditionally add commas
    }
    MenuProps={MenuProps}
  >
    <MenuItem value="SALES">
      <Checkbox checked={agentDetails?.department?.includes("SALES")} />
      <ListItemText primary="SALES" />
    </MenuItem>
    <MenuItem value="COLLECTION">
      <Checkbox checked={agentDetails?.department?.includes("COLLECTION")} />
      <ListItemText primary="COLLECTION" />
    </MenuItem>
  </Select>
</FormControl>

        </Grid>
        <Grid xs={6} sm={6}></Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: {
              sm: "row",
              xs: "column",
            },
            gap: {
              sm: "35px",
              xs: "0px",
            },
          }}
        >
          <TextField
            variant="standard"
            // required
            fullWidth
            id="aadhaar"
            type="number"
            label={
              agentDetails?.aadhaar_number?.length !== 12 &&
              agentDetails?.aadhaar_number?.length > 1
                ? "Aadhar no. is not of 12 digits"
                : "Aadhar number"
            }
            error={
              agentDetails?.aadhaar_number?.length !== 12 &&
              agentDetails?.aadhaar_number?.length > 1
                ? true
                : false
            }
            // label="Aadhar number"
            name="aadhaar"
            value={agentDetails?.aadhaar_number}
            onChange={(e) => {
              if (e.target.value.length > 12) {
                return;
              }
              setAgentDetails({
                ...agentDetails,
                aadhaar_number: e.target.value,
              });
            }}
            disabled={isDisabled}
            sx={{
              mt: 2,
            }}
          />

          <TextField
            // required
            variant="standard"
            fullWidth
            sx={{ mt: 2 }}
            id="pan"
            label={
              agentDetails?.pan_number?.length !== 10 &&
              agentDetails?.pan_number?.length > 1
                ? "Pan Number is not of 10 digits"
                : "Pan Number"
            }
            error={
              agentDetails?.pan_number?.length !== 10 &&
              agentDetails?.pan_number?.length > 1
                ? true
                : false
            }
            // label="Pan Number"
            name="pan"
            value={agentDetails?.pan_number}
            onChange={(e) => {
              if (e.target.value.length > 10) {
                return;
              }
              setAgentDetails({
                ...agentDetails,
                pan_number: e.target.value,
              });
            }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: {
              sm: "column",
              xs: "column",
            },
            gap: {
              // sm: "35px",
              xs: "0px",
            },
            marginTop: "10px",
          }}
        >
          <Grid item xs={6}>
            <TextField
              fullWidth
              // required
              variant="standard"
              type="number"
              sx={{ mt: 1 }}
              id="account"
              label="Account No."
              name="account"
              value={agentDetails?.account_number}
              onChange={(e) => {
                setAgentDetails({
                  ...agentDetails,
                  account_number: e.target.value,
                });
              }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              fullWidth
              // required
              variant="standard"
              sx={{ mt: 2 }}
              id="beneficiaryName"
              label="Beneficiary Name"
              name="beneficiaryName"
              value={agentDetails?.beneficiary_name}
              onChange={(e) => {
                setAgentDetails({
                  ...agentDetails,
                  beneficiary_name: e.target.value,
                });
              }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid xs={6}></Grid>
          <Grid xs={6}>
            <TextField
              fullWidth
              // required
              variant="standard"
              sx={{ mt: 2 }}
              id="ifsc"
              label="IFSC Code"
              name="ifsc"
              value={agentDetails?.ifsc_code}
              onChange={(e) => {
                setAgentDetails({
                  ...agentDetails,
                  ifsc_code: e.target.value.toUpperCase(),
                });
              }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid xs={6}></Grid>
        </Grid>
        <Grid xs={6}>
        <FormControlLabel
            // required
            disabled={isDisabled}
            control={
              <Checkbox
                checked={onGround || agentDetails?.onground}
                onChange={(e) => {
                  const updatedValue = e.target.checked;
                  setOnGround(updatedValue);
                  setAgentDetails({
                    ...agentDetails,
                    onground: updatedValue,
                  });
                }}
              />
            }
            label="On Ground"
            labelPlacement="end"
            sx={{ marginTop: 2, marginLeft: 0.5 }}
          />
         </Grid>
      </Grid>
    </div>
  );
}

export default AgentDetails;
