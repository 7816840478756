import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const CustomCalendar = ({ data, onDateChange }) => {
  const [highlightedDates] = useState(
    data.map((item) => moment(item.date, "DD/MM/YYYY").toDate())
  );

  const currentDate = new Date();
  const filter = localStorage.getItem("agentTrackerFilter");
  const firstDayOfMonth =
    filter === "last_month"
      ? new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
      : ["current_month","today","last_seven_days"].includes(filter) ? new Date(currentDate.getFullYear(), currentDate.getMonth(), 1) : null;
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const tileContent = ({ date, view }) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Get the day, month, and year from the date object
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    if (localStorage.getItem("agentTrackerFilter") === "last_month") {
      currentDate.setMonth(currentDate.getMonth() - 1);
    }

    // Check if the date is in the list of highlighted dates
    if (
      highlightedDates.some((highlightedDate) =>
        moment(highlightedDate).isSame(moment(date), "day")
      )
    ) {
      return "visited-dates";
    }

    // If the date is in the future, disable it
    if (moment(date).isAfter(moment(), "day")) {
      return "future-dates";
    }

    // If the view is not month, return null to disable content for other views
    if (view !== "month") {
      return null;
    }

    // If the date is in the current month and before or equal to today's date, return "non-visited-dates"
    if (localStorage.getItem("agentTrackerFilter") === "last_month") {
      const firstDayOfLastMonth = moment()
        .subtract(1, "months")
        .startOf("month");

      if (
        moment(date).isBetween(firstDayOfLastMonth, moment(), null, "[]")
      ) {
        return "non-visited-dates";
      }
    } else {
      if (
        month === currentMonth &&
        year === currentYear &&
        day <= currentDate.getDate()
      ) {
        return "non-visited-dates";
      }
    }

    return null;
  };

  return (
    <div className="agent-calender">
      <Calendar
        tileClassName={tileContent}
        minDate={firstDayOfMonth}
        maxDate={lastDayOfMonth}
        onChange={(val) => {
          onDateChange(val);
        }}
      />
    </div>
  );
};

export const styles = makeStyles(() => ({
  //define CSS for different date types
  notInThisMonthDayPaper: {
    width: "35px",
    height: "35px",
    backgroundColor: "#fff",
    margin: "3px",
    boxShadow: "none",
    borderRadius: 0,
    padding: "1px",
  },
  normalDayPaper: {
    width: "35px",
    height: "35px",
    backgroundColor: "#e8f5e9",
    borderRadius: "50%",
    margin: "3px",
    boxShadow: "none",
    padding: "1px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  selectedDayPaper: {
    width: "31px",
    height: "31px",
    backgroundColor: "#2979ff",
    margin: "3px",
    boxShadow: "none",
    borderRadius: "50%",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "#82b1ff",
    padding: "1px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  todayPaper: {
    width: "35px",
    height: "35px",
    backgroundColor: "lightGreen",
    margin: "3px",
    boxShadow: "none",
    borderRadius: "50%",
    padding: "1px",
    cursor: "pointer",
    color: " white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const CustomCalendarV2 = ({ data, onDateChange }) =>{
  const [selectedDate, handleDateChange] = useState(new Date());
  const classes = styles(); // import those CSS
  const today = new Date(); // just Date object of today
  const sunnyDays = [1, 6, 10, 24, 15]; // array of sunny days 1st,6th etc

  function getDayElement(day, selectedDate, isInCurrentMonth, dayComponent) {
    //generate boolean
    const isSunny = sunnyDays.includes(day.getDate());
    const isSelected = day.getDate() === selectedDate.getDate();
    const isToday =
      day.getDate() === today.getDate() && day.getMonth() === today.getMonth();

    let dateTile;
    if (isInCurrentMonth) {
      //conditionally return appropriate Element of date tile.
      if (isSunny) {
        dateTile = (
          <Paper
            className={
              isSelected
                ? classes.selectedDayPaper
                : isToday
                ? classes.todayPaper
                : classes.normalDayPaper
            }
          >
            <span>{day.getDate()}</span>
          </Paper>
        );
      } else {
        dateTile = (
          <Paper
            className={
              isSelected
                ? classes.selectedDayPaper
                : isToday
                ? classes.todayPaper
                : classes.normalDayPaper
            }
          >
            {day.getDate()}
          </Paper>
        );
      }
    } else {
      dateTile = (
        <Paper className={classes.notInThisMonthDayPaper}>
          <span style={{ color: "lightGrey" }}>{day.getDate()}</span>
        </Paper>
      );
    }
    return dateTile;
  }
  return (
    <div>
      <MuiPickersUtilsProvider>
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            variant="static"
            // using our function
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) =>
              getDayElement(day, selectedDate, isInCurrentMonth, dayComponent)
            }
            disableFuture={true}
          />
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default CustomCalendar;