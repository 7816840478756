import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Slide,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { Grid } from "react-virtualized";
import { GlobalContext } from "../../Context/GlobalContext";
import { resendToNBFC } from "../../api";
import { toast } from "react-toastify";
// import { Search } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  border: "1px solid black",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function ResendToNBFC({ open, handleClose, handleResendNBFCSubmit }) {
  const { updateDetailState, customersList, panData } =
    useContext(GlobalContext);
  const [documents, setDocuments] = useState({});
  const [allDocs, setAllDocs] = useState([]);
  const [confirmScreen, setConfirmScreen] = useState(false);
  const [loanDetailsUpdateChecked, setLoanDetailsUpdateChecked] =
    useState(false);

  const [updateDetails] = updateDetailState;

  function filterObject(originalObject, keys) {
    const filteredObject = {};
    Object.keys(originalObject)?.map((item) => {
      if (!keys?.includes(item)) {
        // filteredObject[item?.replace("_uploaded", "")] = originalObject[item];
        filteredObject[item?.replace("_uploaded", "")] = false;
      }
    });
    return filteredObject;
  }

  function handleSearch(event) {
    let filterData = {};
    let searchText = event?.target?.value?.replaceAll(" ", "_");
    allDocs?.map(([key, value]) => {
      if (key?.toLowerCase()?.includes(searchText?.toLowerCase())) {
        filterData[key] = documents[key] || value;
      }
    });
    setDocuments(filterData);
  }

  function toCamalCase(doc_name = "") {
    return doc_name
      ?.split(" ")
      ?.map((item) => item[0]?.toUpperCase() + item?.slice(1)?.toLowerCase())
      ?.join(" ");
  }

  useEffect(() => {
    // if (updateDetails?.nbfc_sent_documents) {
    const filteredObj = filterObject(updateDetails?.nbfc_sent_documents || {}, [
      "fintree_lead_id",
      "approval_data",
    ]);
    setAllDocs(
      Object.entries(filteredObj)?.map(([key, value]) => [key, false])
    );
    setDocuments(filteredObj || {});
    setLoanDetailsUpdateChecked(false);
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose(false);
        }}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={"lg"}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            margin: "40px",
            height: 600,
          }}
        >
          {/* <TextField
            id="outlined-basic"
            label="search"
            variant="outlined"
            style={{
              width: "30%",
            }}
          /> */}
          <div
            style={{
              width: "30%",
            }}
          >
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearch}
              />
            </Search>
          </div>
          <hr />
          <FormGroup>
            <div
              style={{
                display: "grid",
                gridTemplateRows: "repeat(10,1fr)",
                gridTemplateColumns: "repeat(4,1fr)",
                rowGap: 20,
                maxHeight: 500,
                // background:'red'
              }}
            >
              {Object.keys(documents)?.map((doc) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        // defaultChecked={documents?.[doc]}
                        checked={documents?.[doc]}
                        onChange={(e) => {
                          setDocuments((prev) => {
                            return {
                              ...prev,
                              [doc]: e.target.checked,
                            };
                          });
                          setAllDocs((prev) =>
                            prev?.map(([key, value]) => {
                              if (key === doc) {
                                return [key, e.target.checked];
                              }
                              return [key, value];
                            })
                          );
                        }}
                      />
                    }
                    label={toCamalCase(doc?.replaceAll("_", " "))}
                  />
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                {!updateDetails?.is_disbursed && <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={loanDetailsUpdateChecked}
                    onChange={(e) => {
                      setLoanDetailsUpdateChecked(e.target.checked);
                    }}
                    label="Update Loan Details"
                  />
                </FormGroup>}
              </div>
              <div style={{ margin: "0px 10px" }} className="d-flex flex-row justify-content-end align-items-center">
                <Button
                  onClick={() => {
                    setConfirmScreen(false);
                    handleClose(false);
                  }}
                  className="mx-2"
                >
                  Cancel
                </Button>
                <Button
                  variant="text"
                  size="medium"
                  onClick={() => {
                    setConfirmScreen(true);
                  }}
                >
                  proceed
                </Button>
              </div>
            </div>
          </FormGroup>
        </div>
      </Dialog>
      <Dialog
        open={confirmScreen}
        onClose={() => {
          setConfirmScreen(false);
          handleClose(false);
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to send the documents ?"}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              setConfirmScreen(false);
              handleClose(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              handleClose(false);
              setConfirmScreen(false);
              handleResendNBFCSubmit(documents, loanDetailsUpdateChecked);
            }}
            autoFocus
          >
            Re-send to NBFC
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ResendToNBFC;
