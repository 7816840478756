import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { useState, useEffect, useContext } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { toast } from "react-toastify";
import { getAgentTargetData } from "../../api";
import CircularProgress from "@mui/material/CircularProgress";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import dayjs from "dayjs";
import { GlobalContext } from "../../Context/GlobalContext";
import { f } from "../../utility/Formater";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const TARGETTYPECONST = {
  file_login: "File login",
  disbursement: "Disbursement",
  collection: "Collection",
  gps_installation: "GPS",
  confiscated: "Confiscated",
  resale: "Vehicle Resale",
};

export default function ViewTargetModal({ open, handleClose, viewTarget }) {
  const { agentsCurrentMonthTargetPercentageState } = useContext(GlobalContext);
  const [
    agentsCurrentMonthTargetPercentage,
    setAgentsCurrentMonthTargetPercentage,
  ] = agentsCurrentMonthTargetPercentageState;

  const [dateOpen, setDateOpen] = useState(false);
  const [date, setDate] = useState(dayjs());
  const [targetType, setTargetType] = useState("file_login");
  const [dashboardData, setDashboardData] = useState({});
  const [overviewData, setOverviewData] = useState({});
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  const handleCurrentComponentChange = (event, nextCurrentComponent) => {
    if (nextCurrentComponent !== null) {
      setTargetType(nextCurrentComponent);
    }
  };

  const calculateOverallPercentage = () => {
    if (date.isSame(dayjs(), "month")) {
      const agentDepartment = viewTarget?.department?.reduce((acc,curr)=>{
        if(acc != ""){
          acc = acc+","
        }
        acc = acc+curr;
        return acc;
      },"")
      const keys = Object?.keys(overviewData);
      const total = keys?.reduce(
        (sum, key) => {
          let percentage = overviewData?.[key]?.percentage;
          switch(key){
            case "file_login": {
              if(agentDepartment == "SALES"){
                percentage = percentage*2;
              }
              else if(agentDepartment == "COLLECTION"){
                percentage = percentage*0
              }
              else if (agentDepartment == "SALES,COLLECTION"){
                percentage = percentage*1.3
              }
              break;
            }
            case "disbursement": {
              if(agentDepartment == "SALES"){
                percentage = percentage*2;
              }
              else if(agentDepartment == "COLLECTION"){
                percentage = percentage*0
              }
              else if (agentDepartment == "SALES,COLLECTION"){
                percentage = percentage*1.3
              }
              break;
            }
            case "collection": {
              if(agentDepartment == "SALES"){
                percentage = percentage*0;
              }
              else if(agentDepartment == "COLLECTION"){
                percentage = percentage*4
              }
              else if (agentDepartment == "SALES,COLLECTION"){
                percentage = percentage*1.4
              }
              break;
            }
            case "confiscated": {
              percentage = percentage * 0.5
              break;
            }
            case "resale": {
              percentage = percentage * 0.5
              break;
            }
          }
          return sum + percentage
        },
        0
      );
      const overallPercentage =
        total / keys?.length ? (total / keys?.length)?.toFixed(2) : "0";
      setAgentsCurrentMonthTargetPercentage({
        ...agentsCurrentMonthTargetPercentage,
        [viewTarget?.agentCode]: overallPercentage || 0,
      });
    }
  };

  const overviewAccumulateFnc = (data, color, shouldAddRupee) => {
    let accumulated = {
      achieved: 0,
      total: 0,
      color: color,
      percentage: 0,
      targetData: `${shouldAddRupee ? "₹" : ""}0/${shouldAddRupee ? "₹" : ""}0`,
    };

    if (Array.isArray(data) && data.length > 0) {
      accumulated = data.reduce((acc, current) => {
        acc.achieved += current?.achieved || 0;
        acc.total += current?.total || 0;
        return acc;
      }, accumulated);

      if (accumulated.total > 0) {
        accumulated.percentage = Number(
          ((accumulated.achieved / accumulated.total) * 100)?.toFixed(2)
        );
      } else {
        accumulated.percentage = 0;
      }

      accumulated.targetData = `${shouldAddRupee ? "₹" : ""}${Number(
        accumulated.achieved
      )?.toLocaleString("en-IN")} / ${shouldAddRupee ? "₹" : ""}${Number(
        accumulated.total
      )?.toLocaleString("en-IN")}`;
    }

    return accumulated;
  };

  const handleOverviewData = () => {
    const fileLoginData = overviewAccumulateFnc(
      dashboardData?.file_login,
      "#55EB5C",
      false
    );
    const disbursementData = overviewAccumulateFnc(
      dashboardData?.disbursement,
      "#ECD500",
      false
    );
    const collectionData = overviewAccumulateFnc(
      dashboardData?.collection,
      "#B485FF",
      true
    );
    const gpsInstallationData = overviewAccumulateFnc(
      dashboardData?.gps_installation,
      "#85C5FF",
      false
    );
    const confiscatedData = overviewAccumulateFnc(
      dashboardData?.confiscated,
      "#FF906D",
      false
    );
    const resaleData = overviewAccumulateFnc(
      dashboardData?.resale,
      "#FF75D8",
      false
    );

    setOverviewData({
      file_login: fileLoginData,
      disbursement: disbursementData,
      collection: collectionData,
      gps_installation: gpsInstallationData,
      confiscated: confiscatedData,
      resale: resaleData,
    });
  };

  const fetchAgentTargetInfo = async () => {
    setLoading(true);
    try {
      const { data } = await getAgentTargetData(
        viewTarget?.agentCode,
        new Date(date).getTime()
      );
      // console.log(data);

      const fileLoginData = data?.data?.file_login?.map((val) => ({
        name: val?.anchor_name,
        achieved: val?.achieved,
        total: val?.target,
        targetData: `${val?.achieved}/${val?.target}`,
      }));

      const disbursementData = data?.data?.disbursement?.map((val) => ({
        name: val?.anchor_name,
        achieved: val?.achieved,
        total: val?.target,
        targetData: `${val?.achieved}/${val?.target}`,
      }));

      const collectionData = data?.data?.collection?.map((val) => {
        return {
          name: val?.customer_name,
          achieved: val?.collected_amount,
          total: val?.total_payable,
          targetData: `₹${Number(val?.collected_amount)?.toLocaleString(
            "en-IN"
          )} / ₹${Number(val?.total_payable)?.toLocaleString("en-IN")}`,
        };
      });

      const gpsInstallationData = (data?.data?.gps_installation?.earlier ?? [])
        ?.concat(data?.data?.gps_installation?.this_month ?? [])
        .map((val) => ({
          name: val?.customer_name,
          achieved: val?.gps_installed ? 1 : 0,
          total: 1,
          targetData: `${val?.gps_installed ? 1 : 0}/1`,
        }));

      const confiscatedData = data?.data?.confiscated?.map((val) => ({
        name: val?.customer_name,
        achieved: val?.confiscated ? 1 : 0,
        total: 1,
        targetData: `${val?.confiscated ? 1 : 0}/1`,
      }));

      const resaleData = data?.data?.resale?.map((val) => ({
        name: val?.customer_name,
        achieved: val?.resale ? 1 : 0,
        total: 1,
        targetData: `${val?.confiscated ? 1 : 0}/1`,
      }));

      setDashboardData({
        file_login: fileLoginData,
        disbursement: disbursementData,
        collection: collectionData,
        gps_installation: gpsInstallationData,
        confiscated: confiscatedData,
        resale: resaleData,
      });
    } catch (error) {
      // console.error("Error fetching target data:", error);
      toast.error("Error fetching target data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => dashboardData && handleOverviewData(), [dashboardData]);

  useEffect(() => calculateOverallPercentage(), [overviewData]);

  useEffect(() => {
    fetchAgentTargetInfo();
  }, [date]);

  return (
    <>
      <BootstrapDialog
        onClose={() => {
          handleClose(updated);
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          class="view-target-header-container"
        >
          <Grid
            container
            spacing={2}
            sx={{
              padding: "15px 10px",
              justifyContent: "space-between",
            }}
          >
            <Grid
              sx={{
                fontSize: "34px",
                fontStyle: "italic",
                fontWeight: "600",
                display: "flex",
                marginLeft: "10px",
                alignItems: "center",
                textTransform: "capitalize",
              }}
            >
              {viewTarget?.name + "  " + (agentsCurrentMonthTargetPercentage?.[viewTarget?.agentCode] || 0) + "%"}
            </Grid>
            <Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DatePicker
                    fullWidth
                    views={["month"]}
                    label="Select month *"
                    format="MM/YYYY"
                    value={date}
                    inputVariant="outlined"
                    onChange={(date) => {
                      setDate(date);
                    }}
                    onOpen={() => {
                      setDateOpen(true);
                    }}
                    onClose={() => {
                      setDateOpen(false);
                    }}
                    animateYearScrolling
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              padding: "15px 50px",
              justifyContent: "space-between",
            }}
            className="view-target-header-title"
          >
            {TARGETTYPECONST?.[targetType]}
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              padding: "15px 50px",
              justifyContent: "space-between",
            }}
            className="view-target-header-title"
          >
            <Grid xs={4}>
              <div class="view-target-header-info">
                {overviewData?.[targetType]?.targetData}
              </div>
              <Grid sm={10}>
                <div class="view-target-header-progress-bar-container">
                  <div class="view-target-header-progress-bar-bg"></div>
                  <div
                    class="view-target-header-progress-bar-fill"
                    style={{
                      width:
                        overviewData?.[targetType]?.percentage < 50
                          ? `calc(${overviewData?.[targetType]?.percentage}% + 25px)`
                          : `${overviewData?.[targetType]?.percentage}%`,
                      background: overviewData?.[targetType]?.color,
                    }}
                  ></div>
                  <div
                    class="view-target-header-progress-bar-label"
                    style={{
                      left: `${overviewData?.[targetType]?.percentage}%`,
                      transform: `translateX(-${overviewData?.[targetType]?.percentage}%)`,
                      background: overviewData?.[targetType]?.color,
                    }}
                  >
                    <div class="view-target-header-progress-bar-label-wrapper">
                      <div class="view-target-header-progress-bar-label-text">
                        {`${overviewData?.[targetType]?.percentage || 0}%`}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid xs={8}>
              <ToggleButtonGroup
                fullWidth
                color="primary"
                value={targetType}
                onChange={handleCurrentComponentChange}
                exclusive
              >
                <ToggleButton
                  sx={{
                    fontSize: "10px",
                    whiteSpace: "nowrap",
                  }}
                  value={"file_login"}
                >
                  File login
                </ToggleButton>
                <ToggleButton
                  sx={{
                    fontSize: "10px",
                  }}
                  value={"disbursement"}
                >
                  Disbursement
                </ToggleButton>
                <ToggleButton
                  sx={{
                    fontSize: "10px",
                  }}
                  value={"collection"}
                >
                  Collection
                </ToggleButton>
                <ToggleButton
                  sx={{
                    fontSize: "10px",
                  }}
                  value={"gps_installation"}
                >
                  GPS
                </ToggleButton>
                <ToggleButton
                  sx={{
                    fontSize: "10px",
                  }}
                  value={"confiscated"}
                >
                  Confiscated
                </ToggleButton>
                <ToggleButton
                  sx={{
                    fontSize: "10px",
                    whiteSpace: "nowrap",
                  }}
                  value={"resale"}
                >
                  Vehicle Resale
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers>
          {loading ? (
            <div
              className={"d-flex mt-3"}
              style={{
                alignItems: "center",
                height: "200px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress className={"me-2"} size={15} /> Loading Data
            </div>
          ) : dashboardData?.[targetType]?.length > 0 ? (
            <Grid
              container
              spacing={2}
              sx={{
                gap: "20px",
                padding: "20px",
              }}
            >
              {dashboardData?.[targetType]?.map((data, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={7}
                      className={"d-flex"}
                      style={{
                        alignItems: "center",
                        color: "#757575",
                        fontSize: "15px",
                        fontFamily: "Inter",
                        fontWeight: "500",
                        wordWrap: "break-word",
                      }}
                    >
                      {data?.name}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        padding: "10px",
                        borderRadius: "5px",
                        border: "2.07px #BDBDBD solid",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        display: "inline-flex",
                      }}
                    >
                      {data?.targetData}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          ) : (
            <div
              className={"d-flex mt-3"}
              style={{
                alignItems: "center",
                height: "200px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="content-center">No Data Available</div>
            </div>
          )}
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
