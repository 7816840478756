import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CustomerDetails from "../CreateSteps/CustomerDetails";
import LoanDetails from "../CreateSteps/LoanDetails";
import BankDetails from "../CreateSteps/BankDetails";
import CoApplicant from "../CreateSteps/CoApplicant";
import { createApplication } from "../../api";
import { GlobalContext } from "../../Context/GlobalContext";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIco from "../../assets/add.svg";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { getAllAnchors, updateAnchorData, fetchLatLng } from "../../api";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { Alert, Snackbar } from "@mui/material";

export default function UpdateAnchor() {
  const navigate = useNavigate();
  const { anchor_id } = useParams();
  const { state } = useLocation();

  const theme = createTheme();

  const [anchorsList, setAnchorsList] = React.useState([]);
  const [anchorData, setAnchorData] = React.useState([]);
  const [anchorId, setAnchorId] = React.useState("");
  const [anchorName, setAnchorName] = React.useState("");
  const [stateSnackbar, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    failed: true,
  });
  const { vertical, horizontal, open, failed } = stateSnackbar;
  const handleClick = async (newState) => {
    // console.log("hi");
    setState({ open: true, ...newState });
  };
  const handleClose = () => {
    setState({ ...state, open: false });
  };

  // const getAnchorList = async () => {
  //   let anchoridList = [];
  //   try {
  //     let { data } = await getAllAnchors();
  //     //   console.log("==>", data?.data);
  //     data?.data?.forEach((edx, ind) => {
  //       let temp = {
  //         anchor_id: edx?.anchor_id,
  //         label: `${edx?.anchor_id}:${edx?.name}`,
  //         anchorData: edx,
  //       };
  //       anchoridList = [...anchoridList, temp];
  //     });
  //     setAnchorsList(anchoridList);
  //     // setAnchorData()
  //     //   console.log('get anchor list data ',data.data)
  //     //   console.log("==>", anchoridList);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const getAnchorList = async () => {
    try {
      let { data } = await getAllAnchors();
      let anchoridList = data?.data?.map((edx) => ({
        anchor_id: edx?.anchor_id,
        name: edx?.name,
        label: `${edx?.anchor_id}:${edx?.name}`,
        anchorData: edx,
      }));
      setAnchorsList(anchoridList);
      // console.log(anchoridList);
    } catch (error) {
      console.error(error);
    }
  };

  const updateAnchorApi = async () => {
    let payload = { addresses: Object.values(anchorData) };

    try {
      let { data } = await updateAnchorData(anchorId, payload);
      await handleClick({
        vertical: "top",
        horizontal: "right",
        failed: false,
      });
      // let successMessage = `Record for ${anchorName} updated successfully.`;
      // setSnackMsg({ msg: successMessage, show: true });
      // setTimeout(() => setSnackMsg({ msg: successMessage, show: false }), 3000);
    } catch (error) {
      await handleClick({
        vertical: "top",
        horizontal: "right",
        failed: true,
      });
      // console.error("update anchor api error", error);
      // setSnackMsg({ msg: "Error occurred while updating record.", show: true });
      // setTimeout(
      //   () =>
      //     setSnackMsg({
      //       msg: "Error occurred while updating record.",
      //       show: false,
      //     }),
      //   3000
      // );
    }
  };

  React.useEffect(() => {
    getAnchorList();
  }, []);

  // React.useEffect(() => {
  //     console.log(anchorData)
  // }, [anchorId])

  // addresses : [
  // {
  //     address:"",
  //     pincode:number
  //     }
  // ]

  const handleChange = async(event, index) => {
    const { name, value } = event.target;
    console.log("name", name, "value", value, "index", index);
    const temp = [...anchorData];
    temp[index] = {
      ...temp[index],
      [name]: name === "pincode" ? Number(value) : value,
    };
    setAnchorData(temp);
    if(name === "pincode" && value.length === 6) {
      const { data } = await fetchLatLng(value);
      if (data?.status) {
        temp[index].lat =
        data?.data[0]?.latitude?.toString();
        temp[index].lon =
        data?.data[0]?.longitude?.toString();
      }
    }
  };

  // React.useEffect(() => {
  //     console.log('data change=> ', dataChange)
  // }, [dataChange])

  const findAnchorData = (anchor_id) => {
    let temp = anchorsList.find((edx) => edx.anchor_id === anchor_id);
    setAnchorData(temp?.anchorData?.address);
  };

  useEffect(() => {
    if (anchor_id) {
      setAnchorId(anchor_id);
      findAnchorData(anchor_id);
    }
  }, [anchorsList]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <p
        className="go-back-btn"
        onClick={() => {
          if (state?.path) {
            navigate(state?.path);
          } else {
            navigate("/tools");
          }
        }}
      >
        <ArrowBackIcon /> Go back Home
      </p>

      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert
            onClose={handleClose}
            severity={failed ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {failed
              ? "Error occurred while updating record."
              : anchorName + " Record updated successfully."}
          </Alert>
        </Snackbar>
        {/*{snackMsg?.show ? (*/}
        {/*  <div*/}
        {/*    className="d-flex justify-content-center align-items-center"*/}
        {/*    style={{*/}
        {/*      background: "grey",*/}
        {/*      color: "#fff",*/}
        {/*      padding: "1em 0.5em",*/}
        {/*      borderRadius: "10px",*/}
        {/*      marginBottom: "1em",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <h3>{snackMsg.msg}</h3>*/}
        {/*  </div>*/}
        {/*) : null}*/}

        <div className="page-head">
          <img src={AddIco} width={"30px"} />
          <span style={{ marginLeft: "10px", color: "gray" }}>
            Update Anchor
          </span>
        </div>

        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}
        >
          <Grid container spacing={2}>
            {!anchor_id ? (
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={anchorsList}
                  onChange={(e, newVal) => {
                    setAnchorId(newVal?.anchor_id);
                    setAnchorData(newVal?.anchorData?.address);
                    console.log("change ", newVal?.anchorData?.address);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Anchor List" />
                  )}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <p>
                  Anchor ID : {anchor_id}{" "}
                  <CloseIcon
                    className="pointer"
                    onClick={() => {
                      setAnchorId(null);
                      setAnchorData(null);

                      if (state?.path) {
                        navigate("/update-anchor", {
                          state,
                        });
                      } else {
                        navigate("/update-anchor");
                      }
                    }}
                  />
                </p>
              </Grid>
            )}
          </Grid>

          <Grid container spacing={2}>
            {/* mobile.map((c, index) => { */}
            {anchorData?.map((val, index) => {
              // console.log(anchorData[val])
              return (
                <>
                  <Grid item xs={6}>
                    <TextField
                      id="standard-search"
                      label="Address" 
                      name="address"
                      variant="standard"
                      value={val?.address}
                      defaultValue={val?.address}
                      sx={{ marginTop: 2, marginRight: 1 }}
                      onChange={(event) => handleChange(event, index)}
                    />

                    <TextField
                      id="standard-search"
                      type="number"
                      label="Pincode"
                      name="pincode"
                      variant="standard"
                      value={val?.pincode}
                      defaultValue={val?.pincode}
                      sx={{ marginTop: 2, marginRight: 1 }}
                      onChange={(event) => handleChange(event, index)}
                    />
                  </Grid>
                </>
              );
            })}

            <Grid item xs={6}>
              <Button
                variant="primary"
                sx={{ marginTop: 3 }}
                disabled={!anchorId}
                onClick={() => {
                  // setAddress([...address, { address_type: "", pincode: "", address: "" }]);
                  setAnchorData([
                    ...anchorData,
                    {
                      address: "",
                      pincode: Number(NaN),
                    },
                  ]);
                  //   anchorData.push();
                }}
              >
                Add Address
              </Button>
            </Grid>
          </Grid>

          <Button
            variant="outlined"
            sx={{ marginTop: 3 }}
            disabled={!anchorId}
            onClick={updateAnchorApi}
          >
            Update Anchor
          </Button>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
