import React, { useEffect, useState } from "react";
import ImageCropper from "./Crop";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CropIcon from "@mui/icons-material/Crop";
import { CircularProgress, DialogActions, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import Rotate90DegreesCwOutlinedIcon from '@mui/icons-material/Rotate90DegreesCwOutlined';
import { toast } from "react-toastify";

const CropScreen = ({
  onClose,
  onCrop,
  onRecapture,
  image,
  open,
  isLoading,
}) => {
  const [cropImgTemp, setTempCrop] = useState("");
  const [rotateImage, setRotateImage] = useState(image);
  const [rotateImageLoading, setRotateImageLoading] = useState(false);

  const navigate = useNavigate();

  function rotateImageFun(direction = "right") {
    return new Promise((resolve, reject) => {
              // Create an image element
              const img = new Image();
              img.src = rotateImage;

              img.onload = () => {
                  const canvas = document.createElement('canvas');
                  const ctx = canvas.getContext('2d');

                  // Set canvas dimensions for rotation
                  if (direction === 'left' || direction === 'right') {
                      canvas.width = img.height;
                      canvas.height = img.width;
                  }

                  ctx.save();

                  // Apply the transform based on direction
                  if (direction === 'right') {
                      ctx.transform(0, 1, -1, 0, img.height, 0); // 90 degrees
                  } else if (direction === 'left') {
                      ctx.transform(0, -1, 1, 0, 0, img.width); // -90 degrees
                  } else {
                      reject(new Error('Invalid direction'));
                  }

                  ctx.drawImage(img, 0, 0);
                  ctx.restore();

                  resolve(canvas.toDataURL());
              };

              img.onerror = (error) => reject(error);
    });
  }

  async function handleRotateImage(direction){
    try{
      setRotateImageLoading(true);
      const base64 = await rotateImageFun(direction);
      setRotateImage(base64);
    }catch(error){
      toast.error(error?.message);
    }finally{
      setRotateImageLoading(false);
    }
  }

  useEffect(()=>{
    setRotateImage(image);
  },[image]);

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            backdropFilter: "blur(10px)",
          },
        }}
        // TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", bgcolor: "transparent" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {/* <Button autoFocus color="inherit" onClick={onRecapture}>
              <ReplayIcon className="me-2" sx={{ fontSize: 16 }} /> Retake
            </Button> */}
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
            ></Typography>
            {/* <Button autoFocus color="inherit" onClick={() => onCrop(cropImgTemp)}>
              <CropIcon className="me-2" sx={{ fontSize: 16 }} /> Crop
            </Button> */}
          </Toolbar>
        </AppBar>
        {/* <List>
        <ListItem button>
          <ListItemText primary="Phone ringtone" secondary="Titania" />
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText
            primary="Default notification ringtone"
            secondary="Tethys"
          />
        </ListItem>
      </List> */}
        <div
          className="content-center align-items-center position-relative"
          style={{
            background: "transparent",
            backdropFilter: "blur(10px)",
            height: "100%",
          }}
        >
          {rotateImageLoading && <div className="position-absolute z-2 opacity-25 d-flex flex-column align-items-center justify-content-center w-100 h-100">
            <CircularProgress 
              variant="determinate"
              sx={(theme) => ({
                color: theme.palette.grey[200],
                ...theme.applyStyles('dark', {
                  color: theme.palette.grey[800],
                }),
              })}
              size={40}
              thickness={4}
            />
          </div>}
          <ImageCropper
            onImageCropped={(result) => {
              setTempCrop(result);
            }}
            imageToCrop={rotateImage}
          />
        </div>
        <DialogActions className="d-flex flex-column align-items-center" >
          <div className="w-100 content-center">
            <IconButton
              variant="outlined"
              autoFocus
              size="small"
              color="inherit"
              className="me-1"
              onClick={() =>{
                handleRotateImage("left")
              }}
            >
              <Rotate90DegreesCcwIcon
                fontSize="small"
                sx={{ color: "white" }}
              />
            </IconButton>
            <IconButton
              variant="outlined"
              autoFocus
              color="inherit"
              size="small"
              onClick={() =>{
                handleRotateImage("right")
              }}
            >
              <Rotate90DegreesCwOutlinedIcon
                fontSize="small"
                sx={{ color: "white" }}
              />
            </IconButton>
          </div>
          <div className="w-100 content-center">
            {!isLoading ? (
              <>
                <Button
                  variant="outlined"
                  autoFocus
                  color="inherit"
                  sx={{ color: "white", padding: "5px 25px", borderRadius: "20px" }}
                  onClick={() => onCrop(cropImgTemp)}
                  disabled={rotateImageLoading}
                >
                  <CropIcon
                    className="me-2"
                    sx={{ fontSize: 16, color: "white" }}
                  />{" "}
                  Crop
                </Button>
              </>
            ) : (
              <div
                style={{ color: "white", display: "flex", alignItems: "center" }}
              >
                {" "}
                <CircularProgress size={16} sx={{ color: "white", mr: 1 }} />{" "}
                Processing...
              </div>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CropScreen;
