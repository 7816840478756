import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import {
  Grow,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Zoom,
  tableCellClasses,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  getAllOtherTransactions,
  updateOtherTransactions,
  deleteOtherTransaction,
} from "../../api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import "./OtherTransactionsPopup.css";
import { f } from "../../utility/Formater";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeletePopup from "../DeletePopup";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import LoadingOver from "../Loading";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#d8e0ff",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function PastTransactions({
  data,
  handleClose,
  tableData,
  getAllTransactions,
  getCollections
}) {
  const [options, setOptions] = useState([]);
  const [active, setActive] = useState(false);
  const [selectedTrans, setSelectedTrans] = useState([]);
  const [confDltPopup, setConfDltPopup] = useState(false);
  const [loading, setLoading] = useState(null);

  function setToInit() {
    setActive(false);
    setSelectedTrans([]);
    setConfDltPopup(false);
    setLoading(null);
  }

  const handleEditClick = (data, index) => (event) => {
    let newEntry = {
      ...data,
      itemIdx: index
    }
    setSelectedTrans([...selectedTrans, { ...newEntry }]);
  };

  const handleCloseClick = (index) => (event) => {
    setSelectedTrans(selectedTrans.toSpliced(index, 1));
  };

  const handleDeleteTransaction = async (data, idx) => {
    setSelectedTrans(
      selectedTrans
        ?.map((itm) => {
          if (itm?.itemIdx === idx) {
            itm[`isDelete`] = true;
          }
          return itm;
        })
    );
  };

  async function handleSubmit() {
    try {
      setLoading(`upadating details ...`);
      let deleteArr = [];
      let updateArr = [];
      selectedTrans?.map(item => {
        if (item?.isDelete) {
          deleteArr.push(item?.transaction_id);
          return;
        }
        let obj = {
          "transaction_id": item?.transaction_id
        }
        if (item?.transaction_utr !== tableData?.[item?.itemIdx]?.transaction_utr) obj[`transaction_utr`] = item?.transaction_utr;
        if (item?.transaction_amount !== tableData?.[item?.itemIdx]?.transaction_amount) obj[`transaction_amount`] = item?.transaction_amount;
        if (item?.transaction_date_ms !== tableData?.[item?.itemIdx]?.transaction_date_ms) obj[`transaction_date_ms`] = item?.transaction_date_ms;
        updateArr.push(obj);
        return;
      })
      if (deleteArr?.length > 0) {
        const { data: res } = await deleteOtherTransaction(`transaction_ids=${JSON.stringify(deleteArr)}`);
      }

      let params = `type=old`;
      let payload = {
        "application_id": data?.data?.application_id,
        "transaction_type": data?.data?.collection_type,
        updateArray: updateArr
      }

      const { data: res } = await updateOtherTransactions(params, payload)
      if (res?.status) {
        toast(res?.message || "updated successfully !!!");
      }
      getAllTransactions(data?.data);
      getCollections();
    } catch (error) {
      toast?.error(error?.response?.data?.message || "failed to update");
    } finally {
      setOptions([]);
      setToInit();
      handleClose();
    }
  }

  useEffect(() => {
    if (tableData?.length) {
      setOptions(tableData);
    }
  }, [tableData]);

  useEffect(() => {
    setActive(data?.open);
  }, [data?.open]);

  return (
    <React.Fragment>
      <Dialog
        maxWidth={"md"}
        sx={{ "& .MuiDialog-paper": { maxHeight: 580 } }}
        fullWidth
        open={data?.open}
        onClose={() => {
          handleClose();
          setToInit();
        }}
      >
        <DialogTitle>Past Transactions</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
            setToInit();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Zoom in={active}>
            <TableContainer
              component={Paper}
              sx={{
                height: 400,
                "::-webkit-progress-bar": { display: "none" },
              }}
            >
              <Table
                stickyHeader
                sx={{ width: "100%" }}
                aria-label="customized table"
              >
                <TableHead>
                  <Grow
                    in={active}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(active ? { timeout: 1000 } : {})}
                  >
                    <TableRow>
                      <StyledTableCell>Transaction&nbsp;Amount</StyledTableCell>
                      <StyledTableCell align="left">
                        Transaction&nbsp;UTR
                      </StyledTableCell>
                      <StyledTableCell align="left" width={150}>
                        Transaction&nbsp;Date
                      </StyledTableCell>
                      <StyledTableCell align="left" width={100}>
                        Actions
                      </StyledTableCell>
                    </TableRow>
                  </Grow>
                </TableHead>
                <TableBody>
                  {options.map((item, index) => {
                    let idx = selectedTrans.findIndex(
                      (itm) => itm?.itemIdx == index
                    );
                    return (
                      <Grow
                        in={active}
                        style={{ transformOrigin: "0 0 0" }}
                        {...(active ? { timeout: 1000 } : {})}
                      >
                        <StyledTableRow style={{
                          backgroundColor: selectedTrans?.[idx]?.isDelete ? "#ff1744" : ""
                        }} key={index}>
                          <StyledTableCell align="left"> 
                            {idx !== -1 ? (
                              <TextField
                                size="small"
                                type="number"
                                onKeyDown={(e) => {
                                  if (e.key === "e" || e.key === "E") {
                                    e.preventDefault();
                                    return false;
                                  }
                                }}
                                value={selectedTrans[idx]?.transaction_amount}
                                onChange={(e) => {
                                  selectedTrans[idx].transaction_amount =
                                    +e.target.value;
                                  setSelectedTrans([...selectedTrans]);
                                }}
                              />
                            ) : (
                              f(item?.transaction_amount)
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {idx !== -1 ? (
                              <TextField
                                size="small"
                                value={selectedTrans[idx]?.transaction_utr}
                                onChange={(e) => {
                                  // selectedTrans[idx].transaction_utr =
                                  //   e.target.value;
                                  // setSelectedTrans([...selectedTrans]);
                                  setSelectedTrans(prev=>{
                                    prev[idx][`transaction_utr`] = e.target.value;
                                    return [...prev];
                                  })
                                }}
                              />
                            ) : (
                              f(item?.transaction_utr)
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left" width={150}>
                            {idx !== -1 ? (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  size="small"
                                  sx={{
                                    width: 150,
                                    "& input": {
                                      height: 7,
                                    },
                                  }}
                                  format="DD/MM/YYYY"
                                  disableUnderline
                                  value={dayjs(
                                    selectedTrans[idx]?.transaction_date_ms,
                                    {
                                      format: "DD/MM/yyyy",
                                    }
                                  )}
                                  onChange={(date) => {
                                      setSelectedTrans(prev=>{
                                        prev[idx][`transaction_date_ms`] = dayjs(date).valueOf();
                                        return [...prev];
                                      })
                                  }}
                                  disableFuture
                                />
                              </LocalizationProvider>
                            ) : (
                              f(item?.transaction_date_ms, "date")
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left" width={100}>
                            <div className="d-flex flex-row">
                              {idx === -1 && (
                                <IconButton
                                  color="primary"
                                  onClick={handleEditClick(item, index)}
                                >
                                  <EditOutlinedIcon />
                                </IconButton>
                              )}
                              {idx !== -1 && (
                                <IconButton
                                  color="error"
                                  onClick={() => {
                                    setConfDltPopup(index + 1);
                                  }}
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </IconButton>
                              )}
                              {idx !== -1 && (
                                <IconButton
                                  color="primary"
                                  onClick={handleCloseClick(idx)}
                                >
                                  <CloseOutlinedIcon />
                                </IconButton>
                              )}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      </Grow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Zoom>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disableElevation
            size="large"
            sx={{
              borderRadius: "25px",
            }}
            disabled={!selectedTrans?.length}
            onClick={handleSubmit}
          >
            <DoneIcon /> Save
          </Button>
        </DialogActions>
      </Dialog>
      <DeletePopup
        confDltPopup={!!confDltPopup}
        setConfDltPopup={setConfDltPopup}
        text={"Are you sure ?"}
        handleDeleteDoc={() => {
          setConfDltPopup(false);
          handleDeleteTransaction(
            options?.[confDltPopup - 1],
            confDltPopup - 1
          );
        }}
      />
      {loading && <LoadingOver text={loading} />}
    </React.Fragment>
  );
}

export default PastTransactions;
