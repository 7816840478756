import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getBase64 } from "../../utility/convertFileBase";
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useState } from "react";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";

const ImageComponent = ({ onChange }) => {
  const imageRef = React.useRef(null);
  const [type, setType] = useState("upload");
  const [link, setLink] = useState("");
  const [imageLoaded, setImageLoaded] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setType("upload");
    setLink("");
    setImageLoaded(null);
  };

  return (
    <>
      <div>
        <input
          ref={imageRef}
          type="file"
          id="img"
          name="img"
          accept="image/*"
          onChange={async (e) => {
            onChange({
              isUpload: true,
              file: await getBase64(e.target.files[0]),
            });
          }}
          hidden
        />
        <AddCircleOutlineIcon
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setOpen(true);
          }}
        />
      </div>

      <Dialog open={open} onClose={handleClose}>
        <div className="p-4">
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Select type
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => {
                setType(e.target.value);
              }}
              value={type}
            >
              <FormControlLabel
                value="upload"
                control={<Radio />}
                label="Upload Image"
              />
              <FormControlLabel
                value="link"
                control={<Radio />}
                label="Image Link"
              />
            </RadioGroup>

            {type === "link" && (
              <>
                <TextField
                  id="name"
                  name="name"
                  label="Link"
                  fullWidth
                  value={link}
                  variant="standard"
                  onChange={(e) => {
                    setImageLoaded(null);
                    setLink(e.target.value);
                  }}
                />

                {link?.length > 0 && (
                  <div className="mt-3">
                    <img
                      src={link}
                      style={{ display: imageLoaded ? "block" : "none" }}
                      alt="Failed to load Image"
                      width={"100%"}
                      onLoad={() => {
                        setImageLoaded(true);
                      }}
                      onError={() => {
                        setImageLoaded(false);
                      }}
                    />

                    {!imageLoaded && (
                      <p className="mb-0 text-center">
                        <BrokenImageIcon sx={{ fontSize: 50 }} />
                      </p>
                    )}
                  </div>
                )}
              </>
            )}

            <Button
              fullWidth
              variant="contained"
              className="mt-5"
              disabled={type === "link" && !imageLoaded}
              onClick={() => {
                if (type === "link") {
                  onChange({
                    isUpload: false,
                    link,
                  });
                  handleClose();
                } else {
                  imageRef.current.click();
                  handleClose();
                }
              }}
            >
              {type === "link" ? "Done" : "Select File"}
            </Button>
          </FormControl>
        </div>
      </Dialog>
    </>
  );
};

export default ImageComponent;
