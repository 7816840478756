export let gpsData = [
  {
    application_id: "EMAPL09052300000464",
    distance_covered: 472,
    running_hour: 52,
    avg_distance: 54,
    avg_hours: 6,
    avg_speed: 17,
    running_status: "Stopped",
    battery: 75,
    km_before_charge: 83,
  },
  {
    application_id: "EMAPL11042300000400",
    distance_covered: 784,
    running_hour: 98,
    avg_distance: 40,
    avg_hours: 5,
    avg_speed: 15,
    running_status: "Running",
    battery: 50,
    km_before_charge: 55,
  },
  {
    application_id: "EMAPL12042300000405",
    distance_covered: 784,
    running_hour: 98,
    avg_distance: 64,
    avg_hours: 8,
    avg_speed: 15,
    running_status: "Running",
    battery: 11,
    km_before_charge: 12,
  },
  {
    application_id: "EMAPL20042300000445",
    distance_covered: 912,
    running_hour: 152,
    avg_distance: 24,
    avg_hours: 4,
    avg_speed: 17,
    running_status: "Running",
    battery: 63,
    km_before_charge: 69,
  },
  {
    application_id: "EMAPL20042300000446",
    distance_covered: 952,
    running_hour: 119,
    avg_distance: 48,
    avg_hours: 6,
    avg_speed: 18,
    running_status: "Running",
    battery: 87,
    km_before_charge: 96,
  },
  {
    application_id: "EMAPL12042300000406",
    distance_covered: 984,
    running_hour: 123,
    avg_distance: 40,
    avg_hours: 5,
    avg_speed: 19,
    running_status: "Running",
    battery: 13,
    km_before_charge: 14,
  },
  {
    application_id: "EMAPL20042300000452",
    distance_covered: 1040,
    running_hour: 149,
    avg_distance: 35,
    avg_hours: 5,
    avg_speed: 15,
    running_status: "Running",
    battery: 56,
    km_before_charge: 62,
  },
  {
    application_id: "EMAPL20042300000448",
    distance_covered: 1040,
    running_hour: 149,
    avg_distance: 56,
    avg_hours: 8,
    avg_speed: 19,
    running_status: "Running",
    battery: 29,
    km_before_charge: 32,
  },
  {
    application_id: "EMAPL20042300000449",
    distance_covered: 1072,
    running_hour: 153,
    avg_distance: 49,
    avg_hours: 7,
    avg_speed: 20,
    running_status: "Running",
    battery: 71,
    km_before_charge: 78,
  },
  {
    application_id: "EMAPL20042300000442",
    distance_covered: 1112,
    running_hour: 159,
    avg_distance: 21,
    avg_hours: 3,
    avg_speed: 18,
    running_status: "Stopped",
    battery: 93,
    km_before_charge: 102,
  },
  {
    application_id: "EMAPL20042300000443",
    distance_covered: 1120,
    running_hour: 112,
    avg_distance: 60,
    avg_hours: 6,
    avg_speed: 15,
    running_status: "Running",
    battery: 24,
    km_before_charge: 26,
  },
  {
    application_id: "EMAPL20042300000444",
    distance_covered: 1176,
    running_hour: 147,
    avg_distance: 32,
    avg_hours: 4,
    avg_speed: 17,
    running_status: "Stopped",
    battery: 94,
    km_before_charge: 103,
  },
  {
    application_id: "EMAPL12042300000407",
    distance_covered: 1192,
    running_hour: 149,
    avg_distance: 64,
    avg_hours: 8,
    avg_speed: 16,
    running_status: "Running",
    battery: 50,
    km_before_charge: 55,
  },
  {
    application_id: "EMAPL12042300000408",
    distance_covered: 1240,
    running_hour: 138,
    avg_distance: 45,
    avg_hours: 5,
    avg_speed: 15,
    running_status: "Stopped",
    battery: 83,
    km_before_charge: 91,
  },
  {
    application_id: "EMAPL20042300000450",
    distance_covered: 1248,
    running_hour: 125,
    avg_distance: 50,
    avg_hours: 5,
    avg_speed: 19,
    running_status: "Stopped",
    battery: 82,
    km_before_charge: 90,
  },
  {
    application_id: "EMAPL12042300000410",
    distance_covered: 1312,
    running_hour: 131,
    avg_distance: 40,
    avg_hours: 4,
    avg_speed: 15,
    running_status: "Stopped",
    battery: 25,
    km_before_charge: 28,
  },
  {
    application_id: "EMAPL12042300000409",
    distance_covered: 1320,
    running_hour: 189,
    avg_distance: 49,
    avg_hours: 7,
    avg_speed: 16,
    running_status: "Running",
    battery: 92,
    km_before_charge: 101,
  },
  {
    application_id: "EMAPL20042300000451",
    distance_covered: 1336,
    running_hour: 134,
    avg_distance: 90,
    avg_hours: 9,
    avg_speed: 15,
    running_status: "Stopped",
    battery: 71,
    km_before_charge: 78,
  },
];
