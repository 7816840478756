export const dpdDashboardMap = Object.freeze({
  top: {
    "one_to_thirty": "1 to 30",
    "thirty_one_to_sixty": "31 to 60",
    "sixty_one_to_ninety": "61 to 90",
    "above_ninety_one": "91 onwards",
  },
  middle: {
    "one_to_twenty": "1 to 20",
    "twenty_one_to_thirty": "21 to 30",
    "thirty_one_to_forty_five": "31 to 45",
    "forty_six_to_sixty": "46 to 60",
    "sixty_one_to_seventy_five": "61 to 75",
    "seventy_six_to_ninety": "76 to 90",
    "ninety_one_to_hundred_five": "91 to 105",
    "hundred_six_to_hundred_twenty": "106 to 120",
    "above_hundred_twenty_one": "121+",
  },
  granular: {
    "equal_to_one": "D+1",
    "two_to_five": "2 to 5",
    "six_to_seven": "6 to 7",
    "eight_to_ten": "8 to 10",
    "eleven_to_fifteen": "11 to 15",
    "sixteen_to_twenty": "16 to 20",
    "twenty_one_to_twenty_five": "21 to 25",
    "twenty_six_to_thirty": "26 to 30",
    "thirty_one_to_thirty_five": "31 to 35",
    "thirty_six_to_forty_five": "36 to 45",
    "forty_six_to_fifty_five": "46 to 55",
    "fifty_six_to_sixty": "56 to 60",
    "sixty_one_to_seventy": "61 to 70",
    "seventy_one_to_seventy_five": "71 to 75",
    "seventy_six_to_eighty": "76 to 80",
    "eighty_one_to_ninety": "81 to 90",
    "ninety_one_to_hundred": "91 to 100",
    "hundred_one_to_hundred_five": "101 to 105",
    "hundred_six_to_hundred_ten": "106 to 110",
    "hundred_eleven_to_hundred_twenty": "111 to 120",
    "above_hundred_twenty_one": "121+",
  },
});
